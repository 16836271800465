/// <reference path="../../contract/code/partyRoleType.ts"/>

module RemeCare.Shared.Framework.Model {

    import PartyRoleType = Shared.Contract.Code.PartyRoleType;

    export class ActorRole {
        actorRoleId: Shared.Contract.Guid;
        name: string;
        partyType: Shared.Contract.Code.PartyType;
        internalRemark: string;
        minimalNumber: number;
        maximalNumber: number;
        actorRoleInclusions: Array<ActorRoleInclusion>;

        radioSelection: ActorRoleInclusionOption;

        constructor(serverObject?: Contract.Read.IActorRole) {
            if (serverObject != null) {
                this.actorRoleId = serverObject.ActorRoleId;
                this.name = serverObject.Name;
                this.partyType = serverObject.PartyType;
                this.internalRemark = serverObject.InternalRemark;
                this.minimalNumber = serverObject.MinimalNumber;
                this.maximalNumber = serverObject.MaximalNumber;
                this.actorRoleInclusions = _(serverObject.ActorRoleInclusions)
                    .map(a => new ActorRoleInclusion(a));
                this.setRadioSelection();
            } else {
                this.actorRoleInclusions = [];
            }
        }

        copy(): ActorRole {
            var result = new ActorRole();
            angular.copy(this, result);
            return result;
        }

        public toServerWrite(): Contract.Write.IActorRole {
            return <Contract.Write.IActorRole> {
                actorRoleId: this.actorRoleId,
                name: this.name,
                partyType: this.partyType,
                internalRemark: this.internalRemark,
                minimalNumber: this.minimalNumber,
                maximalNumber: this.maximalNumber,
                actorRoleInclusions: _(this.actorRoleInclusions).map(ari => ari.toServerWrite())
            };
        }

        private setRadioSelection(): void {
            if (this.actorRoleInclusions.length === 0) return;
            switch (this.actorRoleInclusions[0].partyRoleType) {
                case PartyRoleType.HealthCareNonProfessional:
                    this.radioSelection = ActorRoleInclusionOption.InformalCareProvider;
                    break;
                case PartyRoleType.Patient:
                    this.radioSelection = ActorRoleInclusionOption.Patient;
                    break;
                case PartyRoleType.HealthCareOrganisation:
                    if (this.actorRoleInclusions[0].actorRoleInclusionDetails.length > 0) {
                        if (this.actorRoleInclusions[0].actorRoleInclusionDetails[0].partyRoleId != null) {
                            this.radioSelection = ActorRoleInclusionOption.Organisations;
                        } else {
                            this.radioSelection = ActorRoleInclusionOption.OrganisationTypes;
                        }
                    }
                    break;
                case PartyRoleType.HealthCareProfessional:
                    if (this.actorRoleInclusions[0].actorRoleInclusionDetails.length > 0) {
                        if (this.actorRoleInclusions[0].actorRoleInclusionDetails[0].partyRoleId != null) {
                            this.radioSelection = ActorRoleInclusionOption.Individuals;
                        } else {
                            this.radioSelection = ActorRoleInclusionOption.Specialties;
                        }
                    }
                    break;
                case PartyRoleType.Employee:
                    this.radioSelection = ActorRoleInclusionOption.Individuals;
                    break;
                default:
                    this.radioSelection = null;
                    break;
            }
        }

        public getRadioSelectionString(): string {
            switch (this.radioSelection) {
                case ActorRoleInclusionOption.InformalCareProvider :
                    return 'Configuration.Therapies.General.InformalCareProvider';
                case ActorRoleInclusionOption.Patient :
                    return 'Configuration.Therapies.General.Patient';
                case ActorRoleInclusionOption.Individuals :
                    return 'Configuration.Therapies.General.Individuals';
                case ActorRoleInclusionOption.Specialties :
                    return 'Configuration.Therapies.General.Specialties';
                case ActorRoleInclusionOption.Organisations :
                    return 'Configuration.Therapies.General.Organisations';
                case ActorRoleInclusionOption.OrganisationTypes :
                    return 'Configuration.Therapies.General.OrganisationTypes';
                default:
                    return '';
            }
        }
    }

    export enum ActorRoleInclusionOption {
        Patient = 1,
        Individuals = 2,
        Specialties = 3,
        Organisations = 4,
        OrganisationTypes = 5,
        InformalCareProvider = 6
    }
} 