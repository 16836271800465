namespace RemeCare.Digest {
    export let remeCareDigestModule = angular.module('RemeCare.Digest', []);
}

namespace RemeCare.Shared {
    export let remeCareSharedModule: ng.IModule = angular
        .module('RemeCare.Shared', [
            'angularFileUpload',
            'checklist-model',
            'mgcrea.ngStrap.helpers.dateParser',
            'mgcrea.ngStrap.helpers.dimensions',
            'mgcrea.ngStrap.tooltip',
            'mgcrea.ngStrap.datepicker',
            'mgcrea.ngStrap.timepicker',
            'ngSanitize',
            'RemeCare.Shared.Templates',
            'ui.bootstrap.collapse',
            'ui.bootstrap.dropdown',
            'ui.bootstrap.modal',
            'ui.bootstrap.pagination',
            'ui.bootstrap.progressbar',
            'ui.bootstrap.tpls',
            'ui.grid',
            'ui.grid.resizeColumns',
            'ui.grid.autoResize',
            'ui.grid.expandable',
            'ui.grid.edit',
            'ui.grid.selection',
            'ui.router',
            'uuid4',
            Digest.remeCareDigestModule.name,
        ])
        .config(($provide) => {
            // Override the ScrollEvent to allow scrolling over grids
            $provide.decorator('ScrollEvent', ($delegate) => {
                $delegate.prototype.atTop = (scrollTop) => {
                    return true;
                };

                $delegate.prototype.atBottom = (scrollTop) => {
                    return true;
                };

                return $delegate;
            });
        });

    // TODO: To remove when new version of angular-guid available without bug
    // Added for Guid.newGuid(): throws NullException in IE since window.crypto is not defined, but window.Crypto is
    (window as any).crypto = window.crypto || (window as any).Crypto;
}
