var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var AutoLogOutCtrl = /** @class */ (function () {
                function AutoLogOutCtrl($interval, $log, $scope, authservice) {
                    var _this = this;
                    this.$interval = $interval;
                    this.$log = $log;
                    this.$scope = $scope;
                    this.authservice = authservice;
                    this.stop = function () {
                        _this.$interval.cancel(_this.countDownTimer);
                    };
                    $scope.stayLoggedIn = function () { return _this.stayLoggedIn(); };
                    $scope.logout = function () { return _this.logout(); };
                }
                AutoLogOutCtrl.prototype.$onInit = function () {
                    // Time before sign out 1min30
                    this.countDown = 90;
                    this.formatRemainingTime();
                    this.startCountDown();
                };
                AutoLogOutCtrl.prototype.logout = function () {
                    this.stop();
                    this.$log.debug('Logging out due to 15 minutes of inactivity');
                    this.authservice.signOut();
                    this.$scope.$close();
                };
                AutoLogOutCtrl.prototype.stayLoggedIn = function () {
                    this.stop();
                    this.$scope.$close();
                };
                AutoLogOutCtrl.prototype.startCountDown = function () {
                    var _this = this;
                    this.countDownTimer = this.$interval(function () {
                        _this.countDown--;
                        _this.formatRemainingTime();
                        if (_this.countDown === 0) {
                            _this.logout();
                        }
                    }, 1000);
                };
                AutoLogOutCtrl.prototype.formatRemainingTime = function () {
                    var minutes = Math.floor(this.countDown / 60);
                    var seconds = this.countDown % 60;
                    this.$scope.remainingTime = minutes + ":" + this.pad(seconds);
                };
                AutoLogOutCtrl.prototype.pad = function (number) {
                    return ("0" + number).slice(-2);
                };
                return AutoLogOutCtrl;
            }());
            Shared.remeCareSharedModule.controller('autoLogOutCtrl', AutoLogOutCtrl);
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
