module RemeCare.Shared.Framework.Model {

    export class TargetValue {
        public targetValueId: Shared.Contract.Guid;
        public therapyActionPartId: Shared.Contract.Guid;

        constructor(serverObject?: Contract.Read.ITargetValue) {
            if (serverObject != null) {
                this.targetValueId = serverObject.TargetValueId;
                this.therapyActionPartId = serverObject.TherapyActionPartId;
            }
        }

        public toWriteModel(): Contract.Write.ITargetValue {
            return <Contract.Write.ITargetValue>{
                Type: 0,
                Id: this.targetValueId,
                TherapyActionPartId: this.therapyActionPartId
            };
        }

        public copy(): TargetValue {
            const result = new TargetValue();
            this.setCommonParts(result);
            return result;
        }

        public setCommonParts(targetValue: TargetValue) {
            targetValue.targetValueId = this.targetValueId;
            targetValue.therapyActionPartId = this.therapyActionPartId;
        }
    }

    export class TargetDose extends TargetValue {
        public quantity: number;
        public unit: Shared.Contract.ICodedEntityTranslation;
        public contextSetting: Model.TherapyActionPartContextSetting;

        constructor(serverObject?: Contract.Read.ITargetDose) {
            super(serverObject);
            if (serverObject != null) {
                this.quantity = serverObject.Quantity;
                this.unit = serverObject.Unit;
            }
        }

        public useContextSetting(tapcs: Model.TherapyActionPartContextSetting) {
            this.contextSetting = tapcs;
            this.therapyActionPartId = tapcs
                ? tapcs.TherapyActionPart.TherapyActionPartId
                : null;
        }

        public toWriteModel(): Contract.Write.ITargetDose {
            const result = <Contract.Write.ITargetDose>super.toWriteModel();
            result.Quantity = this.quantity;
            result.UnitId = this.unit ? this.unit.Id : null;
            result.Type = 1;
            return result;
        }

        public copy(): TargetDose {
            const result = new TargetDose();
            super.setCommonParts(result);
            result.contextSetting = this.contextSetting;
            result.quantity = this.quantity;
            result.unit = this.unit;
            return result;
        }
    }

    export class TargetPhysicalQuantity extends TargetValue {
        public amount: number;
        public contextSetting: Model.TherapyActionPartContextSetting;

        constructor(serverObject?: Contract.Read.ITargetPhysicalQuantity) {
            super(serverObject);
            if (serverObject != null) {
                this.amount = serverObject.Amount;
            }
        }

        public useContextSetting(tapcs: Model.TherapyActionPartContextSetting) {
            this.contextSetting = tapcs;
            this.therapyActionPartId = tapcs
                ? tapcs.TherapyActionPart.TherapyActionPartId
                : null;
        }

        public getTarget(): string {
            return this.amount != null
                ? this.amount.toString()
                : '';
        }

        public toWriteModel(): Contract.Write.ITargetPhysicalQuantity {
            const result = <Contract.Write.ITargetPhysicalQuantity>super.toWriteModel();
            result.Amount = this.amount;
            result.Type = 2;
            return result;
        }

        public copy(): TargetPhysicalQuantity {
            const result = new TargetPhysicalQuantity();
            this.setCommonParts(result);
            return result;
        }

        public setCommonParts(targetPhysicalQuantity: TargetPhysicalQuantity): void {
            super.setCommonParts(targetPhysicalQuantity);
            targetPhysicalQuantity.contextSetting = this.contextSetting;
            targetPhysicalQuantity.amount = this.amount;
        }
    }

    export class TargetPhysicalQuantityRange extends TargetPhysicalQuantity {
        public upperAmount: number;

        constructor(serverObject?: Contract.Read.ITargetPhysicalQuantityRange) {
            super(serverObject);
            if (serverObject != null) {
                this.upperAmount = serverObject.UpperAmount;
            }
        }

        public getTarget(): string {
            if (this.amount == null || this.upperAmount == null) {
                return '';
            }
            return (this.amount != null ? this.amount : '') + ' - ' + (this.upperAmount != null ? this.upperAmount : '');
        }

        public toWriteModel(): Contract.Write.ITargetPhysicalQuantityRange {
            const result = <Contract.Write.ITargetPhysicalQuantityRange>super.toWriteModel();
            result.UpperAmount = this.upperAmount;
            result.Type = 3;
            return result;
        }

        public copy(): TargetPhysicalQuantityRange {
            const result = new TargetPhysicalQuantityRange();
            super.setCommonParts(result);
            result.upperAmount = this.upperAmount;
            return result;
        }
    }
}