module RemeCare.Shared.Framework.Directive {

    interface ISimplePeriodicityScope extends Framework.IBaseScope {
        periodicity: Framework.Model.Periodicity;
        beforeNoon: Array<Date>;
        afterNoon: Array<Date>;
        administrationTimings: Array<Shared.Contract.IEnumTranslation>;
        findIntakeMoment(hour: Date): void;
        toggleIntakeMoment(hour: Date): void;
        hourDisabled(hour: Date): boolean;
        medication: Shared.Contract.Read.IMedication;
        form: ng.IFormController;
        onlyIntakeMoments: boolean;
        readOnly: boolean;
    }

    class SimplePeriodicityController extends Framework.ControllerBase<ISimplePeriodicityScope> {

        private intakeMomentCache: Model.IntraDayTiming;

        static $inject = ['$scope', '$translate', 'toaster', 'masterdataSvc'];
        constructor(
            $scope: ISimplePeriodicityScope,
            $translate,
            toaster,
            private masterdataSvc: Framework.MasterdataService) {
            super($scope, $translate, toaster);
            $scope.findIntakeMoment = d => this.findIntakeMoment(d);
            $scope.toggleIntakeMoment = d => this.toggleIntakeMoment(d);
            $scope.hourDisabled = d => this.hourDisabled(d);
            this.$onInit();
        }

        public async $onInit(): Promise<void> {
            this.fillHours();
            try {
                this.$scope.administrationTimings = await this.masterdataSvc.getAdministrationTimings();
            } catch (e) {
                this.toaster.error(e);
            }
        }

        private fillHours(): void {
            this.$scope.beforeNoon = [];
            this.$scope.afterNoon = [];
            for (var i = 0; i < 12; i++) {
                this.$scope.beforeNoon.push(Shared.DateHelper.serverTimeToDate(`${i < 10 ? '0' + i : i}:00`));
                this.$scope.afterNoon.push(Shared.DateHelper.serverTimeToDate(`${i + 12}:00`));
            }
        }

        private hourDisabled(hour: Date): boolean {
            return this.$scope.readOnly ||
                this.$scope.onlyIntakeMoments && !this.findIntakeMoment(hour) && this.intakeMomentCache == null ||
                this.$scope.onlyIntakeMoments && this.findIntakeMoment(hour) && this.intakeMomentCache != null;
        }


        private findIntakeMoment(hour: Date): Framework.Model.IntraDayTiming {
            return _(this.$scope.periodicity.intraDayTimings)
                .find<Framework.Model.IntraDayTiming>(idt => {
                    return moment(idt.preferredTime).isSame(hour);
                });
        }

        private toggleIntakeMoment(hour: Date) {
            var intakeMoment = this.findIntakeMoment(hour);
            if (this.$scope.onlyIntakeMoments) {
                if (this.intakeMomentCache == null) {
                    this.intakeMomentCache = intakeMoment;
                    intakeMoment.preferredTime = null;
                } else {
                    this.intakeMomentCache.preferredTime = hour;
                    this.intakeMomentCache = null;
                }
            } else {
                if (intakeMoment != null) {
                    var index = this.$scope.periodicity.intraDayTimings.indexOf(intakeMoment);
                    this.$scope.periodicity.intraDayTimings.splice(index, 1);
                } else {
                    intakeMoment = new Framework.Model.IntraDayTimingAdministration();
                    intakeMoment.preferredTime = hour;
                    var targetDose = new Framework.Model.TargetDose();
                    targetDose.quantity = 1;
                    intakeMoment.targetValues.push(targetDose);
                    this.$scope.periodicity.intraDayTimings.push(intakeMoment);
                }
            }
        }
    }

    class SimplePeriodicityDirective implements ng.IDirective {
        restrict = 'E';
        controller = SimplePeriodicityController;
        templateUrl = 'views/periodicity/simplePeriodicity.html';
        scope: { [boundProperty: string]: string } = {
            periodicity: '=',
            medication: '=',
            readOnly: '=',
            onlyIntakeMoments: '='
        }
        require = '^form';
        link = (scope, element, attributes, formCtrl) => {
            scope.form = formCtrl;
        }
    }

    remeCareSharedModule.directive('rcSimplePeriodicity', () => new SimplePeriodicityDirective());
}