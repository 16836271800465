var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Directive;
            (function (Directive) {
                var InputRadioHorizontalController = /** @class */ (function () {
                    function InputRadioHorizontalController(idService, $timeout) {
                        this.idService = idService;
                        this.$timeout = $timeout;
                    }
                    InputRadioHorizontalController.prototype.$onInit = function () {
                        this.previousValue = this.model;
                        this.uniqueName = this.idService.generateId();
                        this.setOptionSize();
                    };
                    InputRadioHorizontalController.prototype.$onChanges = function (onChangesObj) {
                        if (onChangesObj.options) {
                            this.setOptionSize();
                        }
                    };
                    InputRadioHorizontalController.prototype.onChange = function () {
                        var _this = this;
                        if (this.change) {
                            this.$timeout(function () {
                                _this.change();
                            });
                        }
                    };
                    InputRadioHorizontalController.prototype.optionClicked = function () {
                        if (!this.required && this.model !== null && angular.equals(this.model, this.previousValue)) {
                            this.model = null;
                        }
                        this.previousValue = this.model;
                    };
                    InputRadioHorizontalController.prototype.setOptionSize = function () {
                        if (this.options) {
                            this.optionSize = Math.floor(12 / this.options.length);
                        }
                    };
                    return InputRadioHorizontalController;
                }());
                Shared.remeCareSharedModule.component('rcInputRadioHorizontal', {
                    bindings: {
                        label: '@',
                        model: '=ngModel',
                        options: '<',
                        displayText: '@',
                        value: '@',
                        required: '<ngRequired',
                        disabled: '<ngDisabled',
                        change: '&?ngChange',
                        guidanceIconLocation: '<',
                        guidanceIconFunc: '<',
                        uniqueId: '@',
                    },
                    controller: InputRadioHorizontalController,
                    require: {
                        formCtrl: '^form',
                    },
                    templateUrl: 'framework/directives/formElements/inputRadioHorizontal/inputRadioHorizontal.html',
                });
            })(Directive = Framework.Directive || (Framework.Directive = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
