'use strict';

namespace RemeCare.Shared.Framework {
    export interface IState extends ng.ui.IState {
        redirectTo?: string;
        params?: any;
        component?: string;
        views?: { [name: string]: IState };
    }

    export interface IGenericState<TParams> extends ng.ui.IState {
        redirectTo?: string;
        params?: TParams;
        component?: string;
        views?: { [name: string]: IState };
    }

    export interface IPermissions {
        context: string;
        right: AuthRight;
    }

    export abstract class RouteConfiguration {

        private snakeCaseRegexp = /[A-Z]/g;
        constructor(protected $stateProvider: ng.ui.IStateProvider) {
            this.config();
        }

        /** @deprecated */
        public addRoute(
            state: string,
            url: string,
            template: string | ((params: ng.ui.IStateParamsService) => string),
            controller: Function | string | Array<string | Function>,
            translationSets: string[],
            resolveFunctions?,
            reloadOnSearch?: boolean,
            permissions?: IPermissions,
            config?: IState,
            controllerAs?: string
        ): void {
            let resolve: any = {};
            if (resolveFunctions != null) {
                resolve = resolveFunctions;
            }
            resolve.trans = [
                'translationResolver',
                (translationResolver: TranslationResolver) => {
                    return translationResolver.setParts(translationSets);
                },
            ];
            reloadOnSearch = reloadOnSearch == null ? true : reloadOnSearch;

            config = config || {};

            config.url = url;
            if (_.isFunction(template)) {
                config.template = template;
            } else {
                config.templateUrl = template;
            }
            config.controller = controller;
            config.controllerAs = controllerAs;
            config.resolve = resolve;
            config.reloadOnSearch = reloadOnSearch;
            config.data = config.data || {};
            config.data.permissions = permissions;
            config.data.allowAnonymous = _.isEmpty(permissions);

            this.$stateProvider.state(state, config);
        }

        /** @deprecated */
        public addComponentRoute(
            state: string,
            url: string,
            template: string | ((params: any) => string),
            translationSets: string[],
            resolveFunctions?,
            reloadOnSearch?: boolean,
            permissions?: IPermissions,
            config?: IState
        ): void {
            let resolve: any = {};
            if (resolveFunctions != null) {
                resolve = resolveFunctions;
            }
            resolve.trans = [
                'translationResolver',
                (translationResolver: TranslationResolver) => {
                    return translationResolver.setParts(translationSets);
                },
            ];
            reloadOnSearch = reloadOnSearch == null ? true : reloadOnSearch;

            config = config || {};
            config.url = url;
            if (_.isFunction(template)) {
                config.template = template;
            } else {
                const component = this.snakeCase(template, '-');
                config.template = `<${component}><${component}/>`;
            }
            config.resolve = resolve;
            config.reloadOnSearch = reloadOnSearch;
            config.data = config.data || {};
            config.data.permissions = permissions;
            config.data.allowAnonymous = _.isEmpty(permissions);

            this.$stateProvider.state(state, config);
        }

        /** @deprecated */
        public add(state: string, config: IState, translationSets: string[], permissions?: IPermissions): void {
            config = config || {};
            config.reloadOnSearch = config.reloadOnSearch == null ? true : config.reloadOnSearch;
            config.resolve = config.resolve || {};
            config.resolve.trans = [
                'translationResolver',
                (translationResolver: TranslationResolver) => translationResolver.setParts(translationSets),
            ];
            config.data = config.data || {};
            config.data.permissions = permissions;
            config.data.allowAnonymous = _.isEmpty(permissions);

            this.$stateProvider.state(state, config);
        }

        public addGeneric<TParams>(
            state: string,
            config: IGenericState<TParams>,
            translationSets: string[],
            permissions?: IPermissions
        ): void {
            config = config || {};
            config.reloadOnSearch = config.reloadOnSearch == null ? true : config.reloadOnSearch;
            config.resolve = config.resolve || {};
            config.resolve.trans = [
                'translationResolver',
                (translationResolver: TranslationResolver) => translationResolver.setParts(translationSets),
            ];
            config.data = config.data || {};
            config.data.permissions = permissions;
            config.data.allowAnonymous = _.isEmpty(permissions);

            this.$stateProvider.state(state, config);
        }

        protected abstract config(): void;

        private snakeCase(name, separator): string {
            separator = separator || '_';
            return name.replace(this.snakeCaseRegexp, (letter, pos) => {
                return (pos ? separator : '') + letter.toLowerCase();
            });
        }
    }
}
