var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Contract;
        (function (Contract) {
            var Code;
            (function (Code) {
                var ActionType;
                (function (ActionType) {
                    ActionType[ActionType["QuantitativeObservation"] = 1] = "QuantitativeObservation";
                    ActionType[ActionType["QualitativeObservation"] = 2] = "QualitativeObservation";
                    ActionType[ActionType["Administration"] = 4] = "Administration";
                    ActionType[ActionType["CareAct"] = 5] = "CareAct";
                    ActionType[ActionType["FixDetails"] = 7] = "FixDetails";
                    ActionType[ActionType["ProvideInformationObject"] = 8] = "ProvideInformationObject";
                    ActionType[ActionType["RequestInformationObject"] = 9] = "RequestInformationObject";
                    ActionType[ActionType["Remark"] = 10] = "Remark";
                    ActionType[ActionType["PreviousRegistration"] = 11] = "PreviousRegistration";
                    ActionType[ActionType["TelephoneCallPlanning"] = 12] = "TelephoneCallPlanning";
                    ActionType[ActionType["Date"] = 14] = "Date";
                    ActionType[ActionType["MissingRegistrations"] = 18] = "MissingRegistrations";
                    ActionType[ActionType["MedicationSchema"] = 19] = "MedicationSchema";
                    ActionType[ActionType["AberrantRegistrations"] = 20] = "AberrantRegistrations";
                })(ActionType = Code.ActionType || (Code.ActionType = {}));
            })(Code = Contract.Code || (Contract.Code = {}));
        })(Contract = Shared.Contract || (Shared.Contract = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
