var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Model;
            (function (Model) {
                var ActorRoleInclusion = /** @class */ (function () {
                    function ActorRoleInclusion(serverObject) {
                        if (serverObject != null) {
                            this.id = serverObject.Id;
                            this.sequence = serverObject.Sequence;
                            this.partyRoleType = serverObject.PartyRoleType;
                            this.actorRoleInclusionDetails = _(serverObject.ActorRoleInclusionDetails)
                                .map(function (a) { return new Model.ActorRoleInclusionDetail(a); });
                        }
                        else {
                            this.actorRoleInclusionDetails = [];
                        }
                    }
                    ActorRoleInclusion.prototype.copy = function () {
                        var result = new ActorRoleInclusion();
                        angular.copy(this, result);
                        return result;
                    };
                    ActorRoleInclusion.prototype.toServerWrite = function () {
                        return {
                            id: this.id,
                            sequence: this.sequence,
                            partyRoleType: this.partyRoleType,
                            actorRoleInclusionDetails: _(this.actorRoleInclusionDetails).map(function (arid) { return arid.toServerWrite(); })
                        };
                    };
                    return ActorRoleInclusion;
                }());
                Model.ActorRoleInclusion = ActorRoleInclusion;
            })(Model = Framework.Model || (Framework.Model = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
