var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var VideoPlayerDirective = /** @class */ (function () {
                function VideoPlayerDirective($sce) {
                    var _this = this;
                    this.$sce = $sce;
                    this.restrict = 'E';
                    this.scope = {
                        url: '=url',
                        autoplay: '='
                    };
                    this.link = function (scope) {
                        var url = scope.url;
                        if (scope.autoplay) {
                            url += '?autoplay=1';
                        }
                        scope.trustedUrl = _this.$sce.trustAsResourceUrl(url);
                    };
                    this.template = '<div ng-if="trustedUrl"><iframe width="640" height="390" ng-src="{{ trustedUrl }}" webkitallowfullscreen mozallowfullscreen allowfullscreen frameborder="0" /></div>';
                }
                return VideoPlayerDirective;
            }());
            angular.module('RemeCare.Shared').directive('rcVideoPlayer', function ($sce) { return new VideoPlayerDirective($sce); });
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
