namespace RemeCare.Shared.Contract.Read {
    import Duration = Contract.IDuration;

    export interface ITherapyActionPartContextSetting {
        TherapyActionPartContextSettingId: Guid;
        IsMandatory: boolean;
        GuidanceCode: string;
        GuidanceEnabled: boolean;
        IsLeftOutForRemeCoach: boolean;
        RemeCoachDefaultValue?: number;
        TherapyActionPart: Read.ITherapyActionPart;
        HasLocation: boolean;
        HasExecutor: boolean;
        CodeSetType: Read.ICodeSetTypeNew;
        DateType: Contract.IEnumTranslation;
        HasTime: boolean;
        IsWindow: boolean;
        Name: string;
        NumberOfAttempts: number;
        IsTherapyMediaLibItem: boolean;
        SimpleInformationObject: Read.IInformationObject;
        AnswerConditions: Read.IAnswerCondition[];
        ShowObservableEntity: boolean;
        ShowCharacteristic: boolean;
        ExternalContextReference: string;
        ExternalParameterReference: string;
        Decimals: number;
        InputId?: number;
        IsTelemonitoringEnabled: boolean;
        IntendedValue: Contract.IEnumTranslation;
        HasStandardPhrases: boolean;
        AllowsAllFileTypes: boolean;
        HasReferenceDate: boolean;
        IsFollowUpDocument: boolean;
        IsExternalDocument: boolean;
        RequestInfoFileTypes: Read.IRequestInfoFileType[];
        DefaultDelayDuration: Duration;
        DefaultFromTime: string;
        DefaultUntilTime: string;
        IsBasedOnCarePlanStartDate: boolean;
        LocationSettings: Read.ILocationSetting[];
        IsEditable: boolean;
        CanPersonaliseTelemonitoringDeactivation: boolean;
        CanPersonaliseToHidePart: boolean;
        ChildRelatedStructuralTherapyAction: Read.IRelatedStructuralTherapyAction;
        DocumentCategories: Guid[];
        IsCrossProcessInstance: boolean;
        LinkedThresholdId: Guid;
        MinimumValue?: number;
        MaximumValue?: number;
        StructuralTherapyActionToPlanId?: Guid;
        IsMandatoryOverrides: Read.ITherapyActionPartContextSettingMandatoryOverride[];
        ExternalReference: string;
        TreatRegistrationAsRemark: boolean;
    }

    export class ITherapyActionPartContextSettingMandatoryOverride {
        public MandatoryTherapyActionPartId: Guid;
        public CareActCodeSetItemId: Guid;
    }
}
