/// <reference path="../../contract/code/evenOddDays.ts"/>

namespace RemeCare.Shared.Framework.Model {
    import EnumTranslation = Shared.Contract.IEnumTranslation;
    import EvenOddDays = Shared.Contract.Code.EvenOddDays;
    import DailyAlternationType = Shared.Contract.Code.DailyAlternationType;

    export class Periodicity {
        public type: Shared.Contract.Code.PeriodicityType;
        public sequence: number;
        public sequenceInRecurrenceCycle: number;
        public unstructuredPeriodicity: string;
        public unstructuredTargetQuantity: number;
        public isAlternating: boolean;

        public parentPeriodicity: Periodicity;
        public childPeriodicity: Periodicity;
        public intraDayTimings: IntraDayTiming[] = [];

        public duration: Shared.Contract.IDuration;
        public recurrence: Shared.Contract.IDuration;
        public simplifiedRecurrence: EnumTranslation;
        public unitTextKey: string;

        public hasDuration: boolean;

        constructor(serverObject?: Contract.Read.IPeriodicity) {
            this.type = Shared.Contract.Code.PeriodicityType.Periodicity;

            if (serverObject != null) {
                this.sequence = serverObject.Sequence;
                this.unstructuredPeriodicity = serverObject.UnstructuredPeriodicity;
                this.duration = serverObject.Duration;
                this.recurrence = serverObject.Recurrence;
                this.simplifiedRecurrence = serverObject.SimplifiedRecurrence;
                this.unstructuredTargetQuantity = serverObject.UnstructuredTargetQuantity;

                this.isAlternating = serverObject.SequenceInRecurrenceCycle ? true : false;
                this.sequenceInRecurrenceCycle = serverObject.SequenceInRecurrenceCycle
                    ? serverObject.SequenceInRecurrenceCycle
                    : 0;
                this.hasDuration = this.duration != null && this.duration !== ({} as Shared.Contract.IDuration);
            } else {
                this.recurrence = {} as Shared.Contract.IDuration;
                this.sequenceInRecurrenceCycle = 0;
                this.isAlternating = false;
                this.hasDuration = false;
            }
            this.intraDayTimings = [];
        }

        public setChild(child: Periodicity):void {
            this.childPeriodicity = child;
        }

        public copy(): Periodicity {
            const result = new Periodicity();
            this.setCommonParts(result);
            return result;
        }

        public setCommonParts(periodicity: Periodicity, ignoreIntraDayTimings = false): void {
            periodicity.type = this.type;
            periodicity.sequence = this.sequence;
            periodicity.sequenceInRecurrenceCycle = this.sequenceInRecurrenceCycle;
            periodicity.isAlternating = this.isAlternating;
            periodicity.unstructuredPeriodicity = this.unstructuredPeriodicity;
            periodicity.unstructuredTargetQuantity = this.unstructuredTargetQuantity;
            if (this.childPeriodicity) {
                periodicity.childPeriodicity = this.childPeriodicity.copy();
                periodicity.childPeriodicity.parentPeriodicity = periodicity;
            }
            if (!ignoreIntraDayTimings) {
                periodicity.intraDayTimings = _(this.intraDayTimings).map(i => i.copy());
            }
            periodicity.duration = angular.copy(this.duration);
            periodicity.recurrence = angular.copy(this.recurrence);
            periodicity.hasDuration = this.hasDuration;
        }

        public toWriteModel(): Contract.Write.IPeriodicity {
            return {
                Type: this.recurrence != null && this.recurrence.Unit != null ? this.recurrence.Unit : 0,
                Duration:
                    this.duration != null && this.duration.Unit != null
                        ? ({
                              Unit: this.duration.Unit,
                              Quantity: this.duration.Quantity,
                          } as Shared.Contract.IDuration)
                        : null,
                Recurrence:
                    this.recurrence != null && this.recurrence.Unit != null
                        ? ({
                              Unit: this.recurrence.Unit,
                              Quantity: this.recurrence.Quantity,
                          } as Shared.Contract.IDuration)
                        : null,
                Sequence: this.sequence,
                SequenceInRecurrenceCycle: this.sequenceInRecurrenceCycle > 0 ? this.sequenceInRecurrenceCycle : null,
                ChildPeriodicity: this.childPeriodicity != null ? this.childPeriodicity.toWriteModel() : null,
                IntraDayTimings: _(this.intraDayTimings).map(idt => idt.toWriteModel()),
                SimplifiedRecurrence: this.simplifiedRecurrence != null ? this.simplifiedRecurrence.Id : null,
                UnstructuredPeriodicity: this.unstructuredPeriodicity,
                UnstructuredTargetQuantity: this.unstructuredTargetQuantity,
            } as Contract.Write.IPeriodicity;
        }
    }

    export class PeriodicityDay extends Periodicity {
        public evenUnevenDays: EvenOddDays;
        public dailyAlternationType: DailyAlternationType;

        constructor(serverObject?: Contract.Read.IPeriodicityDay) {
            super(serverObject);
            this.unitTextKey = 'General.Day';
            this.type = Shared.Contract.Code.PeriodicityType.PeriodicityDay;
            if (serverObject != null) {
                this.evenUnevenDays = serverObject.EvenUnevenDays;
                if (this.isEvery2Days()) {
                    this.dailyAlternationType = this.evenUnevenDays
                        ? DailyAlternationType.EvenOdd
                        : DailyAlternationType.Day1Day2;
                }
            }
        }

        public copy(): PeriodicityDay {
            const result = new PeriodicityDay();
            super.setCommonParts(result);
            result.evenUnevenDays = this.evenUnevenDays;
            result.dailyAlternationType = this.dailyAlternationType;
            return result;
        }

        public toWriteModel(): Contract.Write.IPeriodicityDay {
            const result = super.toWriteModel() as Contract.Write.IPeriodicityDay;
            result.Type = Contract.Code.PeriodicityType.PeriodicityDay;
            result.EvenUnevenDays = this.evenUnevenDays;
            return result;
        }

        public isEvery2Days(): Boolean {
            return (
                this.isAlternating &&
                this.recurrence.Unit === Shared.Contract.Code.DurationUnit.Days &&
                this.recurrence.Quantity === 2
            );
        }
    }

    export class PeriodicityWeek extends Periodicity {
        public daysOfWeekDuration: number;
        public daysOfWeek: number;
        public weekRepetitionType: number;

        constructor(serverObject?: Contract.Read.IPeriodicityWeek) {
            super(serverObject);
            this.unitTextKey = 'General.Week';
            this.type = Shared.Contract.Code.PeriodicityType.PeriodicityWeek;
            this.daysOfWeek = 0;
            this.weekRepetitionType = 1;
            if (serverObject != null) {
                this.daysOfWeekDuration = serverObject.DaysOfWeekDuration;
                this.daysOfWeek = serverObject.DaysOfWeek;
                if (!this.daysOfWeek && this.daysOfWeekDuration == null && this.childPeriodicity == null) {
                    this.weekRepetitionType = 1;
                } else if (this.childPeriodicity != null) {
                    this.weekRepetitionType = 3;
                } else {
                    this.weekRepetitionType = 2;
                }
            }
        }

        public copy(): PeriodicityWeek {
            const result = new PeriodicityWeek();
            super.setCommonParts(result);
            result.daysOfWeekDuration = this.daysOfWeekDuration;
            result.daysOfWeek = this.daysOfWeek;
            result.weekRepetitionType = this.weekRepetitionType;
            return result;
        }

        public toWriteModel(): Contract.Write.IPeriodicityWeek {
            const result = super.toWriteModel() as Contract.Write.IPeriodicityWeek;
            result.Type = Contract.Code.PeriodicityType.PeriodicityWeek;
            result.DaysOfWeekDuration = this.daysOfWeekDuration;
            result.DaysOfWeek = this.daysOfWeek;
            return result;
        }

        public setChild(child: Periodicity) :void {
            super.setChild(child);
            this.weekRepetitionType = 3;
        }
    }

    export class PeriodicityMonth extends Periodicity {
        public dayOfMonth: number;
        public weekDayOccurence: number;
        public dayOfWeek: number;
        public monthRepetitionType: number;

        constructor(serverObject?: Contract.Read.IPeriodicityMonth) {
            super(serverObject);
            this.unitTextKey = 'General.Month';
            this.type = Shared.Contract.Code.PeriodicityType.PeriodicityMonth;
            if (serverObject != null) {
                this.dayOfMonth = serverObject.DayOfMonth;
                this.weekDayOccurence = serverObject.WeekDayOccurence;
                this.dayOfWeek = serverObject.DayOfWeek;
            }
            if (
                this.dayOfMonth == null &&
                this.weekDayOccurence == null &&
                this.dayOfWeek == null &&
                this.childPeriodicity == null
            ) {
                this.monthRepetitionType = 1;
            } else if (this.dayOfMonth != null) {
                this.monthRepetitionType = 2;
            } else if (this.childPeriodicity != null) {
                this.monthRepetitionType = 4;
            } else {
                this.monthRepetitionType = 3;
            }
        }

        public copy(): PeriodicityMonth {
            const result = new PeriodicityMonth();
            super.setCommonParts(result);
            result.dayOfMonth = this.dayOfMonth;
            result.weekDayOccurence = this.weekDayOccurence;
            result.dayOfWeek = this.dayOfWeek;
            result.monthRepetitionType = this.monthRepetitionType;
            return result;
        }

        public toWriteModel(): Contract.Write.IPeriodicityMonth {
            const result = super.toWriteModel() as Contract.Write.IPeriodicityMonth;
            result.Type = Contract.Code.PeriodicityType.PeriodicityMonth;
            result.DayOfMonth = this.dayOfMonth;
            result.WeekDayOccurence = this.weekDayOccurence;
            result.DayOfWeek = this.dayOfWeek;
            return result;
        }

        public setChild(child: Periodicity): void {
            super.setChild(child);
            this.monthRepetitionType = 4;
        }
    }

    export class PeriodicityYear extends Periodicity {
        public dayOfMonth: number;
        public monthOfYear: number;
        public weekDayOccurence: number;
        public dayOfWeek: number;
        public yearRepetitionType: number;

        constructor(serverObject?: Contract.Read.IPeriodicityYear) {
            super(serverObject);
            this.unitTextKey = 'General.Year';
            this.type = Shared.Contract.Code.PeriodicityType.PeriodicityYear;
            this.yearRepetitionType = Contract.Code.PeriodicityType.PeriodicityYear;
            if (serverObject != null) {
                this.dayOfMonth = serverObject.DayOfMonth;
                this.monthOfYear = serverObject.MonthOfYear;
                this.weekDayOccurence = serverObject.WeekDayOccurence;
                this.dayOfWeek = serverObject.DayOfWeek;
                if (
                    this.dayOfMonth == null &&
                    this.weekDayOccurence == null &&
                    this.dayOfWeek == null &&
                    this.monthOfYear == null &&
                    this.childPeriodicity == null
                ) {
                    this.yearRepetitionType = 1;
                } else if (
                    (this.weekDayOccurence != null || this.dayOfMonth != null) &&
                    this.dayOfWeek == null &&
                    this.childPeriodicity == null
                ) {
                    this.yearRepetitionType = 2;
                } else if (this.childPeriodicity != null) {
                    this.yearRepetitionType = 4;
                } else {
                    this.yearRepetitionType = 3;
                }
            }
        }

        public copy(): PeriodicityYear {
            const result = new PeriodicityYear();
            super.setCommonParts(result);
            result.dayOfMonth = this.dayOfMonth;
            result.monthOfYear = this.monthOfYear;
            result.weekDayOccurence = this.weekDayOccurence;
            result.dayOfWeek = this.dayOfWeek;
            result.yearRepetitionType = this.yearRepetitionType;
            return result;
        }

        public toWriteModel(): Contract.Write.IPeriodicityYear {
            const result = super.toWriteModel() as Contract.Write.IPeriodicityYear;
            result.Type = Contract.Code.PeriodicityType.PeriodicityYear;
            result.DayOfMonth = this.dayOfMonth;
            result.MonthOfYear = this.monthOfYear;
            result.WeekDayOccurence = this.weekDayOccurence;
            result.DayOfWeek = this.dayOfWeek;
            return result;
        }

        public setChild(child: Periodicity):void {
            super.setChild(child);
            this.yearRepetitionType = 4;
        }
    }

    export class RecurrentCycle {
        public periodicities: Periodicity[];

        constructor(periodicities: Periodicity[]) {
            this.periodicities = periodicities;
        }
    }
}
