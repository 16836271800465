var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Contract;
        (function (Contract) {
            var Code;
            (function (Code) {
                var ActionGroupType;
                (function (ActionGroupType) {
                    ActionGroupType[ActionGroupType["Default"] = 1] = "Default";
                    ActionGroupType[ActionGroupType["Hospitalisation"] = 2] = "Hospitalisation";
                    ActionGroupType[ActionGroupType["MedicationChangeNotification"] = 3] = "MedicationChangeNotification";
                })(ActionGroupType = Code.ActionGroupType || (Code.ActionGroupType = {}));
            })(Code = Contract.Code || (Contract.Code = {}));
        })(Contract = Shared.Contract || (Shared.Contract = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
