var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Directive;
            (function (Directive) {
                var ModalHeader = /** @class */ (function () {
                    function ModalHeader() {
                        this.restrict = 'C';
                        this.templateUrl = 'views/modal/modalHeader.html';
                        this.transclude = true;
                    }
                    return ModalHeader;
                }());
                Shared.remeCareSharedModule.directive('modalHeader', function () { return new ModalHeader(); });
            })(Directive = Framework.Directive || (Framework.Directive = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
