/// <reference path="../models/periodicity.ts"/>

namespace RemeCare.Shared.Framework.Directive {
    import Periodicity = Framework.Model.Periodicity;
    import RecurrentCycle = RemeCare.Shared.Framework.Model.RecurrentCycle;

    interface IPeriodicityScope extends ng.IScope {
        periodicity: Periodicity;
        possibleDurationUnits: Contract.IEnumTranslation[];
        form: ng.IFormController;
    }

    class PeriodicityDirective implements ng.IDirective {
        public scope: { [boundProperty: string]: string } = {
            periodicity: '=ngModel',
            possibleDurationUnits: '=durationUnits',
            newPeriodicity: '&?newPeriodicity',
            isFirstInRecurrence: '&?isFirst',
            isLastInRecurrence: '&?isLast',
            setRecurrentCycleMembers: '&?setMembers',
            setEvenOddDays: '&?setEvenOdd',
            form: '=',
            readOnly: '=?',
        };

        public templateUrl = 'views/periodicity/periodicity.html';

        public controller = 'periodicityCtrl';
    }

    remeCareSharedModule.directive('rcPeriodicity', () => new PeriodicityDirective());

    interface IPeriodicityOverviewScope extends ng.IScope {
        therapyActionPartContextSettings: Contract.Read.ITherapyActionPartContextSetting[];
        possibleProductsForAction: Framework.Model.PossibleProductForAction[];
        productId: Contract.Guid;
        readOnly: boolean;
        periodicities: Periodicity[];
        periodicitiesGrid: Framework.Grid.Grid<Periodicity>;
        createGrid: boolean;
        forMedication: boolean;
        limitedConfig: boolean;
        medication: Shared.Contract.Read.IMedication;
        disableAdd: boolean;
        allowMultiple: boolean;
        carePlanStartDate: Date;
        addPeriodicity(): void;
    }

    class PeriodicityOverviewController implements ng.IController {
        constructor(
            private readonly $scope: IPeriodicityOverviewScope,
            private readonly gridBuilderSvc: Framework.Grid.GridBuilderFactory,
            private readonly toaster: Framework.Toaster,
            private readonly modalBuilderFactory: Framework.Helper.ModalBuilderFactory
        ) {
            $scope.addPeriodicity = () => this.addPeriodicityAsync();
            this.$onInit();
        }

        public $onInit(): void {
            if (this.$scope.createGrid) {
                this.buildPeriodicitiesGrid();
            } else {
                const watch = this.$scope.$watch(
                    (s: IPeriodicityOverviewScope) => s.createGrid,
                    (newValue, oldValue) => {
                        if (newValue !== oldValue && newValue) {
                            this.buildPeriodicitiesGrid();
                            watch();
                        }
                    }
                );
            }

            this.checkAddDisable();
        }

        private checkAddDisable(): void {
            const lastPeriodicity = _(this.$scope.periodicities).last();
            this.$scope.disableAdd =
                this.$scope.readOnly ||
                (lastPeriodicity != null &&
                    (!this.$scope.allowMultiple ||
                        lastPeriodicity.duration == null ||
                        lastPeriodicity.duration.Quantity == null));
        }

        private buildPeriodicitiesGrid(): void {
            this.$scope.periodicities = _(this.$scope.periodicities).sortBy(p => p.sequence);
            const builder = this.gridBuilderSvc
                .createGridBuilder<Periodicity>()
                .addColumn('sequence', 'Periodicity.Sequence')
                .addColumn('recurrence', 'Periodicity.Repetition', { cellFilter: 'rcDurationDisplay' })
                .addColumn('duration', 'Periodicity.Duration', { cellFilter: 'rcDurationDisplay' })
                .addColumn('intraDayTimings.length', 'Periodicity.TimesPerDay');

            if (this.$scope.readOnly) {
                builder.addActionColumn(
                    'search',
                    p => {
                        this.editRecurrenceCycle(p);
                    },
                    true
                );
            } else {
                builder
                    .addActionColumn('pencil', p => this.editRecurrenceCycle(p), true)
                    .addDeleteButtonColumn(p => this.removeAllPeriodicitiesInSequence(p));
            }

            this.$scope.periodicitiesGrid = builder.build();
            this.$scope.periodicitiesGrid.setData(this.$scope.periodicities);

            const readOnlyWatch = this.$scope.$watch(
                (s: IPeriodicityOverviewScope) => s.readOnly,
                (newValue, oldValue) => {
                    if (newValue !== oldValue) {
                        readOnlyWatch();
                        collectionWatch();
                        this.buildPeriodicitiesGrid();
                    }
                }
            );
            const collectionWatch = this.$scope.$watchCollection(
                (s: IPeriodicityOverviewScope) => s.periodicities,
                (newValue, oldValue) => {
                    if (newValue !== oldValue) {
                        readOnlyWatch();
                        collectionWatch();
                        this.buildPeriodicitiesGrid();
                    }
                }
            );
        }

        private removeAllPeriodicitiesInSequence(periodicity: Periodicity): void {
            const toRemove = _(this.$scope.periodicities).filter(p => p != null && p.sequence === periodicity.sequence);
                angular.forEach(toRemove,
                    p => this.$scope.periodicities.splice(this.$scope.periodicities.indexOf(p), 1));
                this.correctSequence();
                this.checkAddDisable();
        }

        private correctSequence(): void {
            let currentSequence = 0;

            angular.forEach(this.$scope.periodicities, p => {
                if (p.sequence > currentSequence) {
                    currentSequence++;
                }
                p.sequence = currentSequence;
            });
        }

        private async addPeriodicityAsync(): Promise<void> {
            try {
                const r = await this.editPeriodicityAsync();
                let maxSequence = 0;
                if (this.$scope.periodicities.length > 0) {
                    maxSequence = _(this.$scope.periodicities).max(p => p.sequence).sequence;
                }
                maxSequence++;
                _(r).forEach(p => {
                    p.sequence = maxSequence;
                    this.$scope.periodicities.push(p);
                });
                this.checkAddDisable();
            } catch (e) {
                this.toaster.error(e);
            }
        }

        private editPeriodicityAsync(
            recurrenceCycle?: Periodicity[]
        ): Promise<Periodicity[]> {
            if (recurrenceCycle != null) {
                recurrenceCycle = recurrenceCycle.map(p => p.copy());
            }

            return new Promise<Periodicity[]>((resolve) => {
                this.modalBuilderFactory
                    .createModalBuilder<Periodicity[]>()
                    .setController('periodicityModalCtrl')
                    .setTemplateUrl('views/periodicity/periodicityModal.html')
                    .setResolve({
                        therapyActionPartContextSettings: () => this.$scope.therapyActionPartContextSettings,
                        productIds: () =>
                            this.$scope.possibleProductsForAction
                                ? _(this.$scope.possibleProductsForAction)
                                    .chain()
                                    .filter(p => p.ProductId != null)
                                    .map(p => p.ProductId)
                                    .value()
                                : this.$scope.productId
                                    ? [this.$scope.productId]
                                    : [],
                        productClassIds: () =>
                            _(this.$scope.possibleProductsForAction)
                                .chain()
                                .filter(p => p.ProductClassId != null)
                                .map(p => p.ProductClassId)
                                .value(),
                    })
                    .setScope({
                        readOnly: this.$scope.readOnly,
                        recurrentCycle: new RecurrentCycle(recurrenceCycle),
                        forMedication: this.$scope.forMedication,
                        limitedConfig: this.$scope.limitedConfig,
                        medication: this.$scope.medication,
                        carePlanStartDate: this.$scope.carePlanStartDate
                    })
                    .setSize(Framework.Helper.ModalSize.large)
                    .setResultCallBack(r => {
                        resolve(r);
                        this.checkAddDisable();
                    })
                    .build();
            });
        }

        private showPeriodicityReadOnly(recurrenceCycle?: Periodicity[], periodicity?: Periodicity): void {
            this.modalBuilderFactory
                .createComponentModalBuilder<void>('rcShowReadOnlyPeriodicity')
                .setBindings({
                    recurrentCycle: new RecurrentCycle(recurrenceCycle),
                    carePlanStartDate: this.$scope.carePlanStartDate,
                })
                .setLarge()
                .build();
        }

        private editRecurrenceCycle(periodicity: Periodicity): void {
            const scope = this.$scope;
            const recurrenceCycle = _(scope.periodicities)
                .chain()
                .filter(p => p.sequence === periodicity.sequence)
                .sortBy(p => p.sequenceInRecurrenceCycle)
                .value();

            if (!this.$scope.forMedication && this.$scope.readOnly) {
                this.showPeriodicityReadOnly(recurrenceCycle);
            } else {
                this.editPeriodicityAsync(recurrenceCycle).then(r => {
                    _(recurrenceCycle).forEach(p => scope.periodicities.splice(scope.periodicities.indexOf(p), 1));
                    _(r).forEach(p => scope.periodicities.push(p));
                    scope.periodicities.sort((x, y) => x.sequence * 100 + x.sequence - (y.sequence * 100 + y.sequence));
                });
            }
        }
    }

    class PeriodicityOverviewDirective implements ng.IDirective {
        public scope: { [boundProperty: string]: string } = {
            therapyActionPartContextSettings: '=',
            possibleProductsForAction: '=',
            productId: '=',
            readOnly: '=ngReadonly',
            periodicities: '=',
            createGrid: '=',
            forMedication: '=',
            limitedConfig: '=',
            medication: '=',
            allowMultiple: '=',
            carePlanStartDate: '<',
        };

        public controller = [
            '$scope',
            'gridBuilderSvc',
            'toaster',
            'modalBuilderFactory',
            ($scope, gridBuilderSvc, toaster, modalBuilderFactory) =>
                new PeriodicityOverviewController($scope, gridBuilderSvc, toaster, modalBuilderFactory),
        ];

        public restrict = 'E';

        public templateUrl = 'views/periodicity/periodicityOverview.html';
    }

    remeCareSharedModule.directive('rcPeriodicityOverview', () => new PeriodicityOverviewDirective());
}
