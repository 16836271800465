var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Directive;
            (function (Directive) {
                var CreateButton = /** @class */ (function () {
                    function CreateButton() {
                    }
                    CreateButton.prototype.$onInit = function () {
                        this.label = this.label || 'General.Add';
                    };
                    return CreateButton;
                }());
                Shared.remeCareSharedModule.component('rcCreateButton', {
                    controller: CreateButton,
                    controllerAs: 'createCtrl',
                    templateUrl: 'views/controls/createButton.html',
                    bindings: {
                        create: '&?',
                        link: '@?',
                        label: '@?',
                        hideLabel: '<?',
                        ngDisabled: '<?',
                        name: '@?'
                    }
                });
            })(Directive = Framework.Directive || (Framework.Directive = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
