namespace RemeCare.Shared.Framework {
    interface IAboutScope extends Framework.IBaseScope, ng.ui.bootstrap.IModalScope {
        applicationInformation: Contract.IApplicationInfo;
        languageSettings: {
            selectedLanguage: string;
            availableLanguages: string[];
        };
        manualsUrl: string;
    }

    class AboutController implements ng.IController {
        constructor(
            private $scope: IAboutScope,
            private readonly $cookies: ng.cookies.ICookiesService,
            private readonly $translate: ng.translate.ITranslateService,
            private toaster: Framework.Toaster,
            private appVersionSvc: Framework.AppVersionService,
            private appConfig: Shared.Contract.IAppConfig
        ) {
            this.$scope.languageSettings = {
                selectedLanguage: $translate.use(), // this.$cookies.get('applicationLanguage') || 'nl',
                availableLanguages: ['nl', 'fr', 'en', 'de'],
            };
            this.$scope.manualsUrl = this.appConfig.manualsUrl;
        }

        public $onInit(): void {
            this.appVersionSvc
                .getApplicationInformation()
                .success((r) => {
                    this.$scope.applicationInformation = r;
                })
                .error((e) => this.toaster.error(e));
        }
    }

    remeCareSharedModule.controller('aboutCtrl', AboutController);
}
