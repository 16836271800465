namespace RemeCare.Shared.Framework.Directive {
    remeCareSharedModule.component('rcInputZipCity', {
        controllerAs: 'inputCtrl',
        templateUrl: 'views/formElements/inputZipCity.html',
        bindings: {
            model: '=ngModel',
            required: '=ngRequired',
            disabled: '=ngDisabled',
            readOnly: '=ngReadonly',
            hasWarning: '=?ngWarning',
            blur: '&?ngBlur',
            guidanceIconLocation: '<',
            guidanceIconFunc: '<',
            uniqueId: '@',
        },
        require: {
            formCtrl: '^form',
        },
    });
}
