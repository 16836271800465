module RemeCare.Shared.Framework.Model {
    export class PatientInformedConsent {
        informedConsentId: Shared.Contract.Guid;
        versionMajor: string;
        versionMinor: string;
        status: Shared.Contract.Code.PatientInformedConsentStatusCode;
        title: string;
        content: string;
        questions: PatientInformedConsentQuestion[];

        constructor(serverObject: Shared.Contract.Read.IPatientInformedConsentReadModel) {
            if (serverObject != null) {
                this.informedConsentId = serverObject.InformedConsentId;
                this.title = serverObject.Title;
                this.versionMajor = serverObject.VersionMajor;
                this.versionMinor = serverObject.VersionMinor;
                this.status = serverObject.Status;
                this.content = serverObject.Content;
                this.questions = _.map(serverObject.Questions, q => new PatientInformedConsentQuestion(q, this.status));
            }
        }
    }
} 