module RemeCare.Shared.Framework {
    
    interface IRouteHighlightScope extends ng.IScope {
        route
    }

    class RouteHighlightDirective implements ng.IDirective {
        
        constructor(
            private $transitions,
            private $location: ng.ILocationService) {
            
        }

        restrict = 'A';

        scope: { [boundProperty: string]: string } = {
            route: '@rcRouteHighlight'
        }

        link: ng.IDirectiveLinkFn = (scope: IRouteHighlightScope, element: ng.IAugmentedJQuery) => {
            var setClass = () => {
                var reg = new RegExp(scope.route);
                if (this.$location.path().match(reg)) {
                    element.addClass('active');
                } else {
                    element.removeClass('active');
                }
            }
            setClass();

            this.$transitions.onSuccess({}, () => setClass());
        }
    }

    angular.module('RemeCare.Shared').directive('rcRouteHighlight', ($transitions, $location) => new RouteHighlightDirective($transitions, $location));
}