var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Model;
            (function (Model) {
                var TargetValue = /** @class */ (function () {
                    function TargetValue(serverObject) {
                        if (serverObject != null) {
                            this.targetValueId = serverObject.TargetValueId;
                            this.therapyActionPartId = serverObject.TherapyActionPartId;
                        }
                    }
                    TargetValue.prototype.toWriteModel = function () {
                        return {
                            Type: 0,
                            Id: this.targetValueId,
                            TherapyActionPartId: this.therapyActionPartId
                        };
                    };
                    TargetValue.prototype.copy = function () {
                        var result = new TargetValue();
                        this.setCommonParts(result);
                        return result;
                    };
                    TargetValue.prototype.setCommonParts = function (targetValue) {
                        targetValue.targetValueId = this.targetValueId;
                        targetValue.therapyActionPartId = this.therapyActionPartId;
                    };
                    return TargetValue;
                }());
                Model.TargetValue = TargetValue;
                var TargetDose = /** @class */ (function (_super) {
                    __extends(TargetDose, _super);
                    function TargetDose(serverObject) {
                        var _this = _super.call(this, serverObject) || this;
                        if (serverObject != null) {
                            _this.quantity = serverObject.Quantity;
                            _this.unit = serverObject.Unit;
                        }
                        return _this;
                    }
                    TargetDose.prototype.useContextSetting = function (tapcs) {
                        this.contextSetting = tapcs;
                        this.therapyActionPartId = tapcs
                            ? tapcs.TherapyActionPart.TherapyActionPartId
                            : null;
                    };
                    TargetDose.prototype.toWriteModel = function () {
                        var result = _super.prototype.toWriteModel.call(this);
                        result.Quantity = this.quantity;
                        result.UnitId = this.unit ? this.unit.Id : null;
                        result.Type = 1;
                        return result;
                    };
                    TargetDose.prototype.copy = function () {
                        var result = new TargetDose();
                        _super.prototype.setCommonParts.call(this, result);
                        result.contextSetting = this.contextSetting;
                        result.quantity = this.quantity;
                        result.unit = this.unit;
                        return result;
                    };
                    return TargetDose;
                }(TargetValue));
                Model.TargetDose = TargetDose;
                var TargetPhysicalQuantity = /** @class */ (function (_super) {
                    __extends(TargetPhysicalQuantity, _super);
                    function TargetPhysicalQuantity(serverObject) {
                        var _this = _super.call(this, serverObject) || this;
                        if (serverObject != null) {
                            _this.amount = serverObject.Amount;
                        }
                        return _this;
                    }
                    TargetPhysicalQuantity.prototype.useContextSetting = function (tapcs) {
                        this.contextSetting = tapcs;
                        this.therapyActionPartId = tapcs
                            ? tapcs.TherapyActionPart.TherapyActionPartId
                            : null;
                    };
                    TargetPhysicalQuantity.prototype.getTarget = function () {
                        return this.amount != null
                            ? this.amount.toString()
                            : '';
                    };
                    TargetPhysicalQuantity.prototype.toWriteModel = function () {
                        var result = _super.prototype.toWriteModel.call(this);
                        result.Amount = this.amount;
                        result.Type = 2;
                        return result;
                    };
                    TargetPhysicalQuantity.prototype.copy = function () {
                        var result = new TargetPhysicalQuantity();
                        this.setCommonParts(result);
                        return result;
                    };
                    TargetPhysicalQuantity.prototype.setCommonParts = function (targetPhysicalQuantity) {
                        _super.prototype.setCommonParts.call(this, targetPhysicalQuantity);
                        targetPhysicalQuantity.contextSetting = this.contextSetting;
                        targetPhysicalQuantity.amount = this.amount;
                    };
                    return TargetPhysicalQuantity;
                }(TargetValue));
                Model.TargetPhysicalQuantity = TargetPhysicalQuantity;
                var TargetPhysicalQuantityRange = /** @class */ (function (_super) {
                    __extends(TargetPhysicalQuantityRange, _super);
                    function TargetPhysicalQuantityRange(serverObject) {
                        var _this = _super.call(this, serverObject) || this;
                        if (serverObject != null) {
                            _this.upperAmount = serverObject.UpperAmount;
                        }
                        return _this;
                    }
                    TargetPhysicalQuantityRange.prototype.getTarget = function () {
                        if (this.amount == null || this.upperAmount == null) {
                            return '';
                        }
                        return (this.amount != null ? this.amount : '') + ' - ' + (this.upperAmount != null ? this.upperAmount : '');
                    };
                    TargetPhysicalQuantityRange.prototype.toWriteModel = function () {
                        var result = _super.prototype.toWriteModel.call(this);
                        result.UpperAmount = this.upperAmount;
                        result.Type = 3;
                        return result;
                    };
                    TargetPhysicalQuantityRange.prototype.copy = function () {
                        var result = new TargetPhysicalQuantityRange();
                        _super.prototype.setCommonParts.call(this, result);
                        result.upperAmount = this.upperAmount;
                        return result;
                    };
                    return TargetPhysicalQuantityRange;
                }(TargetPhysicalQuantity));
                Model.TargetPhysicalQuantityRange = TargetPhysicalQuantityRange;
            })(Model = Framework.Model || (Framework.Model = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
