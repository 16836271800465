var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Model;
            (function (Model) {
                var TimeSpan = /** @class */ (function () {
                    function TimeSpan(serverObject) {
                        if (serverObject != null) {
                            this.Days = serverObject.getDate();
                            this.Hours = serverObject.getHours();
                            this.Minutes = serverObject.getMinutes();
                        }
                    }
                    return TimeSpan;
                }());
                Model.TimeSpan = TimeSpan;
            })(Model = Framework.Model || (Framework.Model = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
