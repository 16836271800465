namespace RemeCare.Shared.Framework.Periodicity {
    import PeriodicityWeek = Framework.Model.PeriodicityWeek;
    import PeriodicityFactory = Framework.Factory.PeriodicityFactory;

    interface IPeriodicityWeekScope extends Framework.IBaseScope {
        periodicity: PeriodicityWeek;
        limitedDurationUnits: Shared.Contract.IEnumTranslation[];
        extraLimitedDurationUnits: Shared.Contract.IEnumTranslation[];
        repetitionTypeChange: () => void;
    }

    class PeriodicityWeekController extends Framework.ControllerBase<IPeriodicityWeekScope> {
        constructor(
            protected $scope: IPeriodicityWeekScope,
            protected $translate: ng.translate.ITranslateService,
            protected toaster: Framework.Toaster
        ) {
            super($scope, $translate, toaster);

            $scope.repetitionTypeChange = () => this.repetitionTypeChange();
        }

        public $onInit(): void {}

        public watchPeriodicityType(): void {
            this.$scope.$watch(
                (s: IPeriodicityWeekScope) => s.periodicity.type,
                (newValue: Shared.Contract.Code.PeriodicityType, oldValue: Shared.Contract.Code.PeriodicityType) => {
                    if (newValue !== oldValue) {
                        this.$scope.periodicity.weekRepetitionType = 1;
                    }
                }
            );
        }

        private repetitionTypeChange(): void {
            this.$scope.periodicity.daysOfWeek = 0;
            this.$scope.periodicity.daysOfWeekDuration = null;
            this.$scope.periodicity.childPeriodicity = null;
            if (this.$scope.periodicity.weekRepetitionType === 3 && this.$scope.periodicity.childPeriodicity == null) {
                this.$scope.periodicity.childPeriodicity = new PeriodicityFactory().createPeriodicityFromDurationUnit(
                    this.$scope.limitedDurationUnits[0].Id,
                    this.$scope.periodicity
                );
            }
        }
    }

    remeCareSharedModule.controller(
        'periodicityWeekCtrl',
        ($scope, $translate, toaster) =>
            new PeriodicityWeekController($scope, $translate, toaster)
    );
}
