var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var SearchProductCtrl = /** @class */ (function (_super) {
                __extends(SearchProductCtrl, _super);
                function SearchProductCtrl($scope, $translate, toaster, gridBuilderSvc, uiGridConstants, 
                //private title: string,
                medicationSearchSvc) {
                    var _this = _super.call(this, $scope, $translate, toaster) || this;
                    _this.gridBuilderSvc = gridBuilderSvc;
                    _this.uiGridConstants = uiGridConstants;
                    _this.medicationSearchSvc = medicationSearchSvc;
                    _this.$scope.medications = [];
                    _this.$scope.clearSearchParams = function () { return _this.clearSearchParams(); };
                    _this.$scope.toggleCriteria = function () { return _this.toggleCriteria(); };
                    _this.$scope.search = function () { return _this.search(); };
                    _this.buildGrid();
                    _this.buildSelectedGrid();
                    _this.$scope.searchMedicationGrid.sortOptions = {
                        field: 'MedicationName',
                        direction: uiGridConstants.ASC
                    };
                    _this.$scope.searchMedicationGrid.searchCriteria = {};
                    _this.$scope.select = function () { return _this.select(); };
                    return _this;
                }
                SearchProductCtrl.prototype.$onInit = function () {
                    this.$scope.criteriaCollapsed = false;
                };
                SearchProductCtrl.prototype.clearSearchParams = function () {
                    this.$scope.searchMedicationGrid.searchCriteria = {};
                };
                SearchProductCtrl.prototype.toggleCriteria = function () {
                    this.$scope.criteriaCollapsed = !this.$scope.criteriaCollapsed;
                };
                SearchProductCtrl.prototype.search = function () {
                    this.$scope.searchMedicationGrid.pagingOptions.currentPage = 1;
                    this.$scope.searchMedicationGrid.search();
                };
                SearchProductCtrl.prototype.executeSearch = function (page, pageSize, sortField, sortDirection, criteria) {
                    var _this = this;
                    var deferred = jQuery.Deferred();
                    this.$scope.isBusy = true;
                    var query = {
                        page: page,
                        pageSize: pageSize,
                        medicationName: criteria.medicationName,
                        activeSubstance: criteria.activeSubstance,
                        productClassIds: [],
                        sortField: sortField,
                        sortOrder: sortDirection
                    };
                    this.medicationSearchSvc.searchMedications(query)
                        .success(function (data) {
                        deferred.resolve(data);
                        _this.$scope.totalItems = data.Total;
                        _this.$scope.isBusy = false;
                        _this.$scope.criteriaCollapsed = true;
                    }).error(function (message) {
                        _super.prototype.errorCallback.call(_this, message);
                        deferred.reject();
                    });
                    return deferred.promise();
                };
                SearchProductCtrl.prototype.buildGrid = function () {
                    var _this = this;
                    this.$scope.searchMedicationGrid = this.gridBuilderSvc
                        .createGridBuilder(function (page, pageSize, sortField, sortDirection, criteria) {
                        return _this.executeSearch(page, pageSize, sortField, sortDirection, criteria);
                    })
                        .addColumn('MedicationName', 'General.MedicationName')
                        .addColumn('ActiveSubstance', 'General.ActiveSubstance')
                        .addColumn('GalenicForm', 'General.GalenicForm')
                        .addColumn('MedicationTypes', 'General.MedicationType', { cellFilter: 'delimitedDisplay', enableSorting: false })
                        .addSelectButtonColumn(function (selected) { return _this.resultSelected(selected); })
                        .build();
                };
                SearchProductCtrl.prototype.buildSelectedGrid = function () {
                    var _this = this;
                    this.$scope.selectedMedicationGrid = this.gridBuilderSvc
                        .createGridBuilder()
                        .addColumn('MedicationName', 'General.MedicationName')
                        .addColumn('ActiveSubstance', 'General.ActiveSubstance')
                        .addColumn('GalenicForm', 'General.GalenicForm')
                        .addColumn('MedicationTypes', 'General.MedicationType', { cellFilter: 'delimitedDisplay', enableSorting: false })
                        .addDeleteButtonColumn(function (selected) { return _this.resultRemoved(selected); })
                        .setExternalSorting(false)
                        .build();
                    this.$scope.selectedMedicationGrid.setData(this.$scope.medications);
                };
                SearchProductCtrl.prototype.resultSelected = function (result) {
                    if (!_(this.$scope.medications).any(function (m) { return m.ProductId === result.ProductId; })) {
                        this.$scope.medications.push(result);
                    }
                };
                SearchProductCtrl.prototype.resultRemoved = function (result) {
                    var index = this.$scope.medications.indexOf(result);
                    this.$scope.medications.splice(index, 1);
                };
                SearchProductCtrl.prototype.select = function () {
                    this.$scope.$close(this.$scope.medications);
                };
                return SearchProductCtrl;
            }(Framework.ControllerBase));
            Shared.remeCareSharedModule
                .controller('searchProductCtrl', function ($scope, $translate, toaster, gridBuilderSvc, uiGridConstants, medicationSearchSvc) { return new SearchProductCtrl($scope, $translate, toaster, gridBuilderSvc, uiGridConstants, medicationSearchSvc); });
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
