var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Service;
            (function (Service) {
                var UserTrackService = /** @class */ (function () {
                    function UserTrackService($timeout, modalBuilderFactory, $log, authservice) {
                        var _this = this;
                        this.$timeout = $timeout;
                        this.modalBuilderFactory = modalBuilderFactory;
                        this.$log = $log;
                        this.authservice = authservice;
                        this.allowedIdleMinutes = 15;
                        window.onunload = function () { return _this.deleteAuthCookies(); };
                    }
                    UserTrackService.prototype.startTracking = function () {
                        var _this = this;
                        this.$log.debug('Start tracking whether or not user is active');
                        this.lastActiveTime = Shared.DateHelper.now();
                        this.inactiveMinutes = 0;
                        this.setActivity();
                        riveted.init({
                            // reportInterval: 5,   // Default: 5
                            // idleTimeout: 15,      // Default: 30
                            // nonInteraction: true,  // Default: true
                            eventHandler: function () {
                                _this.lastActiveTime = Shared.DateHelper.now();
                                if (_this.inactiveMinutes > 0) {
                                    _this.$log.debug('User is active again');
                                    _this.inactiveMinutes = 0;
                                }
                            },
                        });
                    };
                    UserTrackService.prototype.userHasBeenActive = function () {
                        var currentInactiveMinutes = Math.floor((Shared.DateHelper.now().getTime() - this.lastActiveTime.getTime()) / 60000);
                        if (currentInactiveMinutes > this.inactiveMinutes) {
                            this.inactiveMinutes = currentInactiveMinutes;
                            this.$log.debug("User seems inactive for " + currentInactiveMinutes + " minutes.");
                        }
                        var userActive = Shared.DateHelper.addMinutes(this.lastActiveTime, this.allowedIdleMinutes) > Shared.DateHelper.now();
                        return userActive || !this.authservice.isAuthenticated();
                    };
                    UserTrackService.prototype.setActivity = function () {
                        var _this = this;
                        this.$timeout.cancel(this.inactiveTimer);
                        this.inactiveTimer = this.$timeout(function () {
                            if (_this.userHasBeenActive()) {
                                _this.setActivity();
                                return;
                            }
                            _this.loadTimeOutModalScreen();
                        }, 6000);
                    };
                    UserTrackService.prototype.deleteAuthCookies = function () {
                        this.authservice.signOut();
                    };
                    UserTrackService.prototype.loadTimeOutModalScreen = function () {
                        this.$log.debug('Opening auto logout window. Last active time: ', this.lastActiveTime);
                        this.modalBuilderFactory
                            .createModalBuilder()
                            .setTemplateUrl('views/autoLogOut.html')
                            .setController('autoLogOutCtrl')
                            .setSize(Framework.Helper.ModalSize.medium)
                            .build();
                    };
                    return UserTrackService;
                }());
                Service.UserTrackService = UserTrackService;
                Shared.remeCareSharedModule.service('userTrackSvc', UserTrackService);
            })(Service = Framework.Service || (Framework.Service = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
