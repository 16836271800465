var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Contract;
        (function (Contract) {
            var Code;
            (function (Code) {
                var SimplifiedRecurrence;
                (function (SimplifiedRecurrence) {
                    SimplifiedRecurrence[SimplifiedRecurrence["XTimesPerDay"] = 1] = "XTimesPerDay";
                    SimplifiedRecurrence[SimplifiedRecurrence["XTimesEveryYDays"] = 2] = "XTimesEveryYDays";
                    SimplifiedRecurrence[SimplifiedRecurrence["EveryOtherDay"] = 3] = "EveryOtherDay";
                    SimplifiedRecurrence[SimplifiedRecurrence["WeekSchedule"] = 4] = "WeekSchedule";
                    SimplifiedRecurrence[SimplifiedRecurrence["Other"] = 5] = "Other";
                    SimplifiedRecurrence[SimplifiedRecurrence["AdHoc"] = 6] = "AdHoc";
                })(SimplifiedRecurrence = Code.SimplifiedRecurrence || (Code.SimplifiedRecurrence = {}));
            })(Code = Contract.Code || (Contract.Code = {}));
        })(Contract = Shared.Contract || (Shared.Contract = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
