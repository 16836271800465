/// <reference path="../controllers/controllerBase.ts"/>
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Directive;
        (function (Directive) {
            var SearchController = /** @class */ (function () {
                function SearchController(toaster, gridBuilderSvc) {
                    this.toaster = toaster;
                    this.gridBuilderSvc = gridBuilderSvc;
                }
                SearchController.prototype.$onInit = function () {
                    this.buildGrid();
                };
                SearchController.prototype.search = function () {
                    this.grid.pagingOptions.currentPage = 1;
                    this.grid.search();
                };
                SearchController.prototype.executeSearch = function (page, pageSize, sortField, sortDirection, criteria) {
                    return __awaiter(this, void 0, void 0, function () {
                        var query, r, e_1;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    query = criteria || {};
                                    query.page = page;
                                    query.pageSize = pageSize;
                                    query.sortField = sortField;
                                    query.sortOrder = sortDirection;
                                    this.isBusy = true;
                                    _a.label = 1;
                                case 1:
                                    _a.trys.push([1, 3, , 4]);
                                    return [4 /*yield*/, this.searchFunction()(query)];
                                case 2:
                                    r = _a.sent();
                                    this.isBusy = false;
                                    return [2 /*return*/, r];
                                case 3:
                                    e_1 = _a.sent();
                                    this.toaster.error(e_1);
                                    this.isBusy = false;
                                    return [3 /*break*/, 4];
                                case 4: return [2 /*return*/];
                            }
                        });
                    });
                };
                SearchController.prototype.buildGrid = function () {
                    var _this = this;
                    var gridBuilder = this.gridBuilderSvc.createGridBuilder(function (page, pageSize, sortField, sortDirection, criteria) {
                        return _this.executeSearch(page, pageSize, sortField, sortDirection, criteria);
                    });
                    _(this.columns).each(function (c) {
                        gridBuilder = gridBuilder.addColumn(c.field, c.displayName, c.options);
                    });
                    if (this.searchDetails) {
                        gridBuilder = gridBuilder.setRowDetailsTemplate(this.searchDetails, this.searchDetailsHeight);
                    }
                    this.grid = gridBuilder.addSelectButtonColumn(function (r) { return _this.resultCallback()(r); }).build();
                };
                return SearchController;
            }());
            Directive.SearchController = SearchController;
            Shared.remeCareSharedModule.component('rcSearch', {
                controller: SearchController,
                bindings: {
                    columns: '=',
                    searchDetails: '=',
                    searchDetailsHeight: '=',
                    searchFunction: '&',
                    resultCallback: '&',
                    progressBar: '@?',
                    grid: '='
                },
                template: [
                    '$element',
                    '$attrs',
                    '$templateCache',
                    function ($element, $attrs, $templateCache) {
                        var template = $templateCache.get('views/search/search.html');
                        if (!('searchDetails' in $attrs)) {
                            template = template.replace('expandable', '');
                        }
                        return template;
                    }
                ],
                transclude: true
            });
        })(Directive = Shared.Directive || (Shared.Directive = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
