var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Service;
            (function (Service) {
                var ResponsiveParent = /** @class */ (function () {
                    function ResponsiveParent() {
                        var _this = this;
                        this.mutationObserver = window.MutationObserver || window.WebKitMutationObserver;
                        this.eventListenerSupported = window.addEventListener;
                        $(window)
                            .resize(function () {
                            _this.checkWidth();
                        });
                        this.observeDom();
                    }
                    ResponsiveParent.prototype.checkWidth = function () {
                        var _this = this;
                        var responsiveParents = $('.respond-parent');
                        responsiveParents.each(function (i, e) {
                            _this.applyResponsiveClass($(e));
                        });
                    };
                    ResponsiveParent.prototype.observeDom = function () {
                        var _this = this;
                        var element = document;
                        if (this.mutationObserver) {
                            var obs = new MutationObserver(function (mutations) {
                                if (mutations[0].addedNodes.length) {
                                    _this.checkWidth();
                                }
                            });
                            obs.observe(element, { childList: true, subtree: true });
                        }
                        else if (this.eventListenerSupported) {
                            document.addEventListener('DOMNodeInserted', this.checkWidth, false);
                        }
                    };
                    ResponsiveParent.prototype.applyResponsiveClass = function (responsiveParent) {
                        var parentWidth = responsiveParent.parent().outerWidth();
                        if (parentWidth >= 1200) {
                            responsiveParent.removeClass('col-xs-12 col-sm-6 col-md-8').addClass('col-lg-12');
                        }
                        else if (parentWidth >= 992) {
                            responsiveParent.removeClass('col-xs-12 col-sm-6 col-lg-12').addClass('col-md-8');
                        }
                        else if (parentWidth >= 768) {
                            responsiveParent.removeClass('col-xs-12 col-md-8 col-lg-12').addClass('col-sm-6');
                        }
                        else {
                            responsiveParent.removeClass('col-sm-6 col-md-8 col-lg-12').addClass('col-xs-12');
                        }
                    };
                    return ResponsiveParent;
                }());
                Shared.remeCareSharedModule.run(function () { return new ResponsiveParent(); });
            })(Service = Framework.Service || (Framework.Service = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
