var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Directive;
        (function (Directive) {
            var UiSrefIfDirective = /** @class */ (function () {
                function UiSrefIfDirective($compile) {
                    var _this = this;
                    this.$compile = $compile;
                    this.scope = {
                        val: '@uiSrefVal',
                        if: '=uiSrefIf'
                    };
                    this.link = function (scope, element, attrs) {
                        element.removeAttr('ui-sref-if');
                        _this.$compile(element)(scope);
                        scope.$watch('if', function (newVal) {
                            if (newVal) {
                                element.attr('ui-sref', scope.val);
                            }
                            else {
                                element.removeAttr('ui-sref');
                                element.removeAttr('href');
                            }
                            _this.$compile(element)(scope);
                        });
                    };
                }
                return UiSrefIfDirective;
            }());
            Shared.remeCareSharedModule.directive('uiSrefIf', function ($compile) { return new UiSrefIfDirective($compile); });
        })(Directive = Shared.Directive || (Shared.Directive = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
