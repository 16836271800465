var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Directive;
        (function (Directive) {
            var EnumFlagDirective = /** @class */ (function () {
                function EnumFlagDirective() {
                    this.restrict = 'A';
                    this.scope = {
                        value: '=rcEnumFlag',
                        model: '=rcEnumModel'
                    };
                    this.link = function (scope, element) {
                        var checkbox = element[0];
                        element.on('change', function () {
                            scope.$apply(function () {
                                scope.model = scope.model ? scope.model : 0;
                                if (checkbox.checked) {
                                    scope.model += scope.value;
                                }
                                else {
                                    scope.model -= scope.value;
                                }
                            });
                        });
                        scope.$watch('model', function () {
                            checkbox.checked = (scope.model & scope.value) === scope.value;
                        });
                    };
                }
                return EnumFlagDirective;
            }());
            angular.module('RemeCare.Shared').directive('rcEnumFlag', function () { return new EnumFlagDirective(); });
        })(Directive = Shared.Directive || (Shared.Directive = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
