module RemeCare.Shared.Framework.Directive {

    class TimeInputDirective {
        restrict: 'A';

        require = '?ngModel';

        link: ng.IDirectiveLinkFn = (scope: ng.IScope, element: ng.IAugmentedJQuery, attrs, ngModelCtrl: ng.INgModelController) => {
            if (!ngModelCtrl) return;

            element.keyup((e: JQueryKeyEventObject) => {
                let val = element.val();
                const toBePrepended = ['3', '4', '5', '6', '7', '8', '9'];
                if (val != null && val.length === 1 && _(toBePrepended).contains(val)) {
                    val = `0${val}`;
                }
                if (val != null && val.length === 2) {
                    event.preventDefault();
                    if (e.keyCode === 8) {
                        val = val[0];
                        element.val(val);
                    } else {
                        val = `${val}:`;
                        element.val(val);
                    }
                }
            });
        }
    }

    remeCareSharedModule
        .directive('rcTimeInput', () => new TimeInputDirective())
        .directive('bsTimepicker', () => new TimeInputDirective());
}