var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var MasterdataSearchService = /** @class */ (function (_super) {
                __extends(MasterdataSearchService, _super);
                function MasterdataSearchService() {
                    return _super !== null && _super.apply(this, arguments) || this;
                }
                MasterdataSearchService.prototype.searchDiseasesAsync = function (query) {
                    var url = 'Diseases';
                    return this.getPromise(url, query);
                };
                MasterdataSearchService.prototype.searchCarePlanStatusReasonTypes = function (query) {
                    var url = 'CarePlanStatusReasonTypes';
                    return this.get(url, query, 'careplanStatusReasonTypes');
                };
                MasterdataSearchService.prototype.searchCodeSetTypes = function (query) {
                    var url = 'CodeSetTypes';
                    return this.get(url, query, 'codesettypes');
                };
                MasterdataSearchService.prototype.searchObservableEntitiesAsync = function (query) {
                    return this.getPromise('ObservableEntities', query);
                };
                MasterdataSearchService.prototype.searchHospitalisationReasonsAsync = function (query) {
                    return this.getPromise('HospitalisationReasons', query);
                };
                MasterdataSearchService.$inject = Shared.Framework.ApiServiceBase.injectionParameters;
                return MasterdataSearchService;
            }(Shared.Framework.ApiServiceBase));
            Framework.MasterdataSearchService = MasterdataSearchService;
            Shared.remeCareSharedModule.service('masterdataSearchSvc', MasterdataSearchService);
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
