namespace RemeCare.Shared.Framework {
    import Version = Contract.Read.IVersion;
    import VersionValidityChange = Model.VersionValidityChange;

    export interface IChangeVersionValidityScope extends ng.IScope, ng.ui.bootstrap.IModalScope {
        change: VersionValidityChange;
        versions: Version[];
        minDate: Date;
        maxDate: Date;
        confirm(): void;
    }

    export class ChangeVersionValidityController implements ng.IController {
        constructor(private readonly $scope: IChangeVersionValidityScope) {}

        public $onInit(): void {
            const minDate = moment()
                .add(1, 'days')
                .startOf('day')
                .toDate();
            const previousVersion = _(
                _(this.$scope.versions).filter(
                    v => DateHelper.serverDateStringToDate(v.ValidFromDate) < this.$scope.change.pointInTime
                )
            )
                .sortBy(v => v.ValidFromDate)
                .reverse()[0];
            this.$scope.minDate =
                previousVersion &&
                moment(previousVersion.ValidFromDate, 'YYYY-MM-DD')
                    .add(1, 'day')
                    .toDate() > minDate
                    ? moment(previousVersion.ValidFromDate, 'YYYY-MM-DD')
                          .add(1, 'day')
                          .toDate()
                    : minDate;

            const maxDate = DateHelper.endOfTime();
            const nextVersion = _(
                _(this.$scope.versions).filter(
                    v => DateHelper.serverDateStringToDate(v.ValidFromDate) > this.$scope.change.pointInTime
                )
            ).sortBy(v => v.ValidFromDate)[0];
            this.$scope.maxDate =
                nextVersion &&
                moment(nextVersion.ValidFromDate, 'YYYY-MM-DD')
                    .add(-1, 'day')
                    .toDate() < maxDate
                    ? moment(nextVersion.ValidFromDate, 'YYYY-MM-DD')
                          .add(-1, 'day')
                          .toDate()
                    : maxDate;

            this.$scope.confirm = () => this.confirm();
        }

        public confirm(): void {
            this.$scope.$close(this.$scope.change);
        }
    }

    remeCareSharedModule.controller('changeVersionValidityCtrl', ChangeVersionValidityController);
}
