var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Helper;
            (function (Helper) {
                var ConfigLoader = /** @class */ (function () {
                    function ConfigLoader($http) {
                        this.$http = $http;
                    }
                    ConfigLoader.prototype.loadConfig = function () {
                        var url = './config.json';
                        this.windowLocationOriginInternetExplorer10Fix();
                        return this.$http.get(url).then(function (d) {
                            d.data.baseUrl = window.location.origin + '/api/';
                            return d;
                        });
                    };
                    ConfigLoader.prototype.windowLocationOriginInternetExplorer10Fix = function () {
                        if (!window.location.origin) {
                            window.location.origin = window.location.protocol + "//" + window.location.hostname;
                        }
                    };
                    return ConfigLoader;
                }());
                Helper.ConfigLoader = ConfigLoader;
            })(Helper = Framework.Helper || (Framework.Helper = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
