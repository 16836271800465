namespace RemeCare.Shared.Framework {

    export class ApplicationConfigurationService extends ApiServiceBase {

        public static $inject = Framework.ApiServiceBase.injectionParameters;

        public async getFeatureFlagIntegrationMonitor(): Promise<boolean> {
            const result = await this.getApplicationConfigurationValue('featureFlag.EnableIntegrationMonitor');
            if (result && result.Value) {
                return result.Value.toLowerCase() === 'true';
            }
            return false;
        }

        private  async getApplicationConfigurationValue(key: string): 
            Promise<Contract.IApplicationConfiguration> {
            return this.getPromise(`ApplicationConfiguration/${key}`);
        }
    }

    remeCareSharedModule.service('applicationConfigurationService', ApplicationConfigurationService);
}