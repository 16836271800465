module RemeCare.Shared.Directive {
    
    interface IForceDecimalsScope extends ng.IScope {
        rcForceDecimals: string;
    }

    class ForceDecimalsDirective implements ng.IDirective {
        
        restrict = 'A';

        require = '?ngModel';

        scope: { [boundProperty: string]: string } = {
            rcForceDecimals: '@'
        };
        
        link: ng.IDirectiveLinkFn = (scope: IForceDecimalsScope, element: ng.IAugmentedJQuery, attrs, ngModelCtrl: ng.INgModelController) => {
            if (!scope.rcForceDecimals || !ngModelCtrl) return;

            var nbOfDecimals = parseInt(scope.rcForceDecimals);
            ngModelCtrl.$parsers.push((val: number) => {
                if (val == null) return null;
                var fixed = val.toFixed(nbOfDecimals);
                var temp = parseFloat(fixed);
                if (val != temp) {
                    ngModelCtrl.$setViewValue(temp);
                    ngModelCtrl.$render();
                }
                return temp;
            });

            if (nbOfDecimals == 0) {
                element.bind('keypress', (event) => {
                    if (event.charCode == 44 || event.keyCode == 46) {
                        event.preventDefault();
                    }
                });
            }
        }
    }

    remeCareSharedModule.directive('rcForceDecimals', () => new ForceDecimalsDirective());
}