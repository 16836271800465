/**
 * Usage: Add 'ui.bootstrap.modal.dialog' to app dependencies, and then '$dialog' to module dependencies.
 * Use as:
 *   $dialog.messageBox('Title', 'Message', [{result:'cancel', label: 'Cancel'}, {result:'yes', label: 'Yes', cssClass: 'btn-primary'}])
 *     .open()
 *     .then(function(result) {
 *
 *     });
 *
 * Or just:
 *
 *  $dialog.prompt('Title', 'Message').then(function(result) { } );
 */
var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Service;
        (function (Service) {
            var uiModalDialog = angular.module('ui.bootstrap.modal.dialog', []);
            var DialogService = /** @class */ (function () {
                function DialogService($uibModal, $q) {
                    this.$uibModal = $uibModal;
                    this.$q = $q;
                }
                DialogService.prototype.prompt = function (title, message, buttons, options) {
                    if (typeof buttons === 'undefined') {
                        buttons = [
                            { result: 'cancel', label: 'Cancel' },
                            { result: 'yes', label: 'Yes', cssClass: 'btn-primary' }
                        ];
                    }
                    var modalCtrl = [
                        '$scope',
                        '$uibModalInstance',
                        function ($scope, $uibModalInstance) {
                            $scope.title = title;
                            $scope.message = message;
                            $scope.buttons = buttons;
                            $scope.options = options || {};
                            $scope.close = function (result) {
                                $uibModalInstance.close(result);
                            };
                        }
                    ];
                    if (options && options.isWarning) {
                        return this.$uibModal.open({
                            templateUrl: 'template/dialog/warningMessage.html',
                            controller: modalCtrl
                        }).result;
                    }
                    else {
                        return this.$uibModal.open({
                            templateUrl: 'template/dialog/message.html',
                            controller: modalCtrl
                        }).result;
                    }
                };
                DialogService.prototype.messageBox = function (title, message, buttons, options) {
                    return this.prompt(title, message, buttons, options);
                };
                DialogService.prototype.warningMessageBox = function (title, message, buttons, options) {
                    var warningOptions = angular.copy(options) || {};
                    warningOptions.isWarning = true;
                    return this.prompt(title, message, buttons, warningOptions);
                };
                DialogService.prototype.okBox = function (title, message, options) {
                    return this.prompt(title, message, [
                        {
                            result: 'ok',
                            label: 'General.Ok',
                            cssClass: 'btn-primary'
                        }
                    ], options);
                };
                DialogService.prototype.confirmBox = function (title, message, yes, no, options) {
                    return this.prompt(title, message, [
                        { result: 'cancel', label: 'General.No', cssClass: 'btn-default' },
                        { result: 'yes', label: 'General.Yes', cssClass: 'btn-primary' }
                    ], options).then(function (r) {
                        if (r === 'yes') {
                            if (yes) {
                                yes();
                            }
                        }
                        else {
                            if (no) {
                                no();
                            }
                        }
                    });
                };
                DialogService.prototype.confirm = function (title, message, options) {
                    var _this = this;
                    return this.$q(function (resolve, reject) {
                        _this.confirmBox(title, message, function () { return resolve(); }, function () { return reject(); }, options);
                    });
                };
                return DialogService;
            }());
            Service.DialogService = DialogService;
            uiModalDialog.service('$dialog', DialogService);
            uiModalDialog.run([
                '$templateCache',
                function (e) {
                    e.put('template/dialog/message.html', '<div class="modal-header" ng-show="title">' +
                        '<h2>{{ title | translate }}</h2>' +
                        '</div>' +
                        '<div class="modal-body" ng-show="message">' +
                        '<p>{{ message | translate:options.messageValues }}</p>' +
                        '</div>' +
                        '<div class="modal-footer">' +
                        '<button ng-repeat="btn in buttons" ng-click="close(btn.result)" class=btn ng-class="btn.cssClass">{{ btn.label | translate }}</button>' +
                        '</div>');
                    e.put('template/dialog/warningMessage.html', '<div class="modal-header" ng-show="title">' +
                        '<h2>{{ title | translate }}</h2>' +
                        '</div>' +
                        '<div class="modal-body" ng-show="message">' +
                        '<div class="row">' +
                        '<div class="col-sm-12">' +
                        '<span class="col-sm-1">' +
                        ' <i class="glyphicon glyphicon-exclamation-sign fa-2x"></i>' +
                        '</span>' +
                        ' <div class="col-sm-11">' +
                        '<p>{{ message | translate:options.messageValues }}</p>' +
                        '</div>' +
                        '</div>' +
                        '</div>' +
                        '</div>' +
                        '<div class="modal-footer">' +
                        '<button ng-repeat="btn in buttons" ng-click="close(btn.result)" class=btn ng-class="btn.cssClass">{{ btn.label | translate }}</button>' +
                        '</div>');
                }
            ]);
        })(Service = Shared.Service || (Shared.Service = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
