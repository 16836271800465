var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var ControllerBase = /** @class */ (function () {
                function ControllerBase($scope, $translate, toaster) {
                    var _this = this;
                    this.$scope = $scope;
                    this.$translate = $translate;
                    this.toaster = toaster;
                    $scope.isFieldInvalid = function (field, form) { return _this.isFieldInvalid(field, form); };
                    $scope.showErrors = false;
                    $scope.copy = function (t) { return _this.copy(t); };
                }
                ControllerBase.prototype.copy = function (t) {
                    return angular.copy(t);
                };
                ControllerBase.prototype.isFieldInvalid = function (field, form) {
                    return this.showPermErrors && form[field].$invalid;
                };
                ControllerBase.prototype.generalSuccess = function () {
                    this.toaster.success(this.$translate.instant('General.SaveSuccess'));
                };
                ControllerBase.prototype.errorCallback = function (message) {
                    this.toaster.error(message);
                };
                ControllerBase.prototype.showValidationErrorMessage = function () {
                    this.toaster.error(this.$translate.instant('General.FieldValidationError'));
                };
                return ControllerBase;
            }());
            Framework.ControllerBase = ControllerBase;
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
