var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Contract;
        (function (Contract) {
            var Code;
            (function (Code) {
                var GuidanceIconLocation;
                (function (GuidanceIconLocation) {
                    GuidanceIconLocation[GuidanceIconLocation["None"] = 0] = "None";
                    GuidanceIconLocation[GuidanceIconLocation["Label"] = 1] = "Label";
                    GuidanceIconLocation[GuidanceIconLocation["TextRegistration"] = 2] = "TextRegistration";
                    GuidanceIconLocation[GuidanceIconLocation["PreceedingTitle"] = 3] = "PreceedingTitle";
                    GuidanceIconLocation[GuidanceIconLocation["Custom"] = 4] = "Custom";
                })(GuidanceIconLocation = Code.GuidanceIconLocation || (Code.GuidanceIconLocation = {}));
            })(Code = Contract.Code || (Contract.Code = {}));
        })(Contract = Shared.Contract || (Shared.Contract = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
