var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Model;
            (function (Model) {
                var CodeSetColour = /** @class */ (function () {
                    function CodeSetColour(serverObject) {
                        if (serverObject != null) {
                            this.codeSetItemId = serverObject.CodeSetItemId;
                            this.colour = serverObject.Colour;
                        }
                    }
                    CodeSetColour.prototype.toServerWrite = function () {
                        return {
                            codeSetItemId: this.codeSetItemId,
                            colour: this.colour
                        };
                    };
                    return CodeSetColour;
                }());
                Model.CodeSetColour = CodeSetColour;
            })(Model = Framework.Model || (Framework.Model = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
