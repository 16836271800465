module RemeCare.Shared.Framework.Model {
    import InformedConsentHistoryType = Shared.Contract.Code.InformedConsentHistoryType;
    import IInformedConsentHistoryReadModel = Shared.Contract.Read.IInformedConsentHistoryReadModel;

    export class InformedConsentHistory {
        parts: InformedConsentHistoryPart[];

        constructor(serverObjects?: IInformedConsentHistoryReadModel[]) {
            this.parts = [];
            if (serverObjects != null) {
                this.parts = _(serverObjects).map(so => new InformedConsentHistoryPart(so));
            }
        }
    }
}
