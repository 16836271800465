var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Service;
            (function (Service) {
                var SecurityApiSvc = /** @class */ (function (_super) {
                    __extends(SecurityApiSvc, _super);
                    function SecurityApiSvc() {
                        return _super !== null && _super.apply(this, arguments) || this;
                    }
                    SecurityApiSvc.prototype.getSecurityContexts = function () {
                        return this.getPromise('Security');
                    };
                    SecurityApiSvc.prototype.getClaims = function () {
                        return this.getPromise('Security/Claims', null, 'claims');
                    };
                    SecurityApiSvc.prototype.findMyApplicationProfilesAsync = function () {
                        return this.getPromise('Security/MyApplicationProfiles');
                    };
                    /** @deprecated */
                    SecurityApiSvc.prototype.findAssignableApplicationProfiles = function () {
                        return this.get('Security/AssignableApplicationProfiles');
                    };
                    SecurityApiSvc.prototype.findAssignableApplicationProfilesAsync = function () {
                        return this.getPromise('Security/AssignableApplicationProfiles');
                    };
                    SecurityApiSvc.prototype.setApplicationProfile = function (applicationProfile) {
                        return this.postPromise('Security/MyApplicationProfiles', {
                            applicationProfile: applicationProfile,
                        });
                    };
                    SecurityApiSvc.prototype.isUserBlocked = function (partyId) {
                        return this.getPromise("Security/Users/" + partyId + "/Blocked");
                    };
                    SecurityApiSvc.prototype.getUserDetailsAsync = function (partyId) {
                        return this.getPromise("Security/Users/" + partyId, null, 'userDetails');
                    };
                    SecurityApiSvc.prototype.getApplicationProfileAssignmentsAsync = function (partyId) {
                        return this.getPromise("Security/Users/" + partyId + "/ApplicationProfileAssignments");
                    };
                    SecurityApiSvc.prototype.checkUserNameExists = function (userName) {
                        return this.get('Security/Users/CheckUserName', {
                            userName: userName,
                        }, 'user');
                    };
                    SecurityApiSvc.prototype.createUser = function (user) {
                        return this.post('Security/Users', user, 'user');
                    };
                    SecurityApiSvc.prototype.assignProfile = function (partyId, applicationProfile, reason) {
                        return this.post("Security/Users/" + partyId + "/ApplicationProfileAssignments/" + applicationProfile + "/Assign", {
                            statusChangeReason: reason,
                        });
                    };
                    SecurityApiSvc.prototype.revokeProfile = function (partyId, applicationProfile, reason) {
                        return this.post("Security/Users/" + partyId + "/ApplicationProfileAssignments/" + applicationProfile + "/Revoke", {
                            statusChangeReason: reason,
                        });
                    };
                    SecurityApiSvc.prototype.sendResetMail = function (partyId, sendToUser) {
                        return this.post("Security/Users/" + partyId + "/SendResetMail", {
                            sendToUser: sendToUser,
                        });
                    };
                    SecurityApiSvc.prototype.blockUser = function (partyId, reason) {
                        return this.post("Security/Users/" + partyId + "/Block", {
                            statusChangeReason: reason,
                        });
                    };
                    SecurityApiSvc.prototype.unblockUser = function (partyId, reason) {
                        return this.post("Security/Users/" + partyId + "/Unblock", {
                            statusChangeReason: reason,
                        });
                    };
                    SecurityApiSvc.prototype.searchUserAccountHistoryAsync = function (partyId, query) {
                        return this.getPromise("Security/Users/" + partyId + "/UserAccountHistory", query);
                    };
                    SecurityApiSvc.prototype.changeUsername = function (partyId, userName, sendMailToUser) {
                        return this.post("Security/Users/" + partyId + "/ChangeUserName", {
                            username: userName,
                            sendToUser: sendMailToUser
                        });
                    };
                    SecurityApiSvc.$inject = Shared.Framework.ApiServiceBase.injectionParameters;
                    return SecurityApiSvc;
                }(Framework.ApiServiceBase));
                Service.SecurityApiSvc = SecurityApiSvc;
                Shared.remeCareSharedModule.service('securityApiSvc', SecurityApiSvc);
            })(Service = Framework.Service || (Framework.Service = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
