/// <reference path="./grid.ts"/>

module RemeCare.Shared.Framework.Grid {

    export class FullyPagedGrid<T> extends Grid<T> {

        constructor(
            scope: IGridScope,
            searchFunction: PromiseSearchFunction<T>,
            gridOptions: IGridOptions<T>,
            $timeout: ng.ITimeoutService,
            uiGridConstants,
            $window: ng.IWindowService,
            loadMeasuringSvc: Service.LoadMeasuringService,
            urlBinderFactory?: Factory.UrlBinderFactory,
            propertyMappers?: Factory.PropertyMappers,
            searchCriteriaSetter?: (c) => void) {
            super(scope,
                searchFunction,
                gridOptions,
                $timeout,
                uiGridConstants,
                $window,
                loadMeasuringSvc,
                urlBinderFactory,
                propertyMappers,
                searchCriteriaSetter);
            this.pagingType = PagingType.FullyPaged;
        }

        protected async executeSearch(isPagingSearch: boolean): Promise<Contract.ISearchResult<T>> {
            if (!isPagingSearch) {
                this.totalItems = null;
            }
            const searchResult = await this.searchFunction(
                this.pagingOptions.currentPage,
                this.pagingOptions.pageSize,
                this.sortOptions.field,
                this.sortOptions.direction,
                this.searchCriteria
            );
            this.setData(searchResult.Items);
            this.totalItems = searchResult.Total;
            this.isSearchFiltered();
            return searchResult;
        }

        public isPaged(): boolean {
            return this.totalItems && this.totalItems > this.pagingOptions.pageSize;
        }
    }
}