namespace RemeCare.Shared.Framework.Component {
    class HeaderController implements ng.IComponentController {
        public isCollapsed = true;
        public name: string;
        public multipleProfiles: boolean;
        public showAuthenticatedOptions: boolean;
        public showInformedConsents: boolean;
        public isUnknownCareProvider: boolean = false;

        // @ngInject
        constructor(
            private readonly $location: ng.ILocationService,
            private readonly $rootScope: ng.IRootScopeService,
            private readonly $state: ng.ui.IStateService,
            private readonly authservice: Framework.AuthService,
            private readonly securityApiSvc: Framework.Service.SecurityApiSvc,
            private readonly toaster: Shared.Framework.Toaster,
            private readonly modalBuilderFactory: Framework.Helper.ModalBuilderFactory,
            private readonly patientInformedConsentService: Shared.Framework.Service.PatientInformedConsentService
        ) {}

        public $onInit(): void {
            this.closeMenuOnResize();
            this.setAuthenticatedOptions();
            this.isPatientCheck();
            this.setIsUnknownCareProvider();
            this.$rootScope.$on(AppEvents.claimsChanged, () => {
                this.setAuthenticatedOptions();
                this.isPatientCheck();
                this.setIsUnknownCareProvider();
            });
        }

        public isPatientCheck(): void {
            this.showInformedConsents =
                this.authservice.isPatient() && this.patientInformedConsentService.hasAcceptedPatientInformedConsents();
        }

        public setIsUnknownCareProvider(): void {
            this.isUnknownCareProvider = this.authservice.isUnknownCareProvider();
        }

        public highlight(prefix: string) {
            const reg = new RegExp('^' + prefix);
            if (this.$location.path().match(reg)) {
                return true;
            }
            return false;
        }

        public openSettings(): void {
            this.modalBuilderFactory.createComponentModalBuilder('rcSettings').build();
        }

        public showAbout(): void {
            this.modalBuilderFactory
                .createModalBuilder()
                .setController('aboutCtrl')
                .setSize(Framework.Helper.ModalSize.large)
                .setTemplateUrl('views/about.html')
                .build();
        }

        public signOut(): void {
            this.authservice.signOut();
        }

        public requestAccess(): void {
            this.$state.go('selfservice.requestUserAccount');
        }

        private async setAuthenticatedOptions(): Promise<void> {
            this.showAuthenticatedOptions = this.authservice.isAuthenticated();
            this.name = this.authservice.getNameOfUser();
            if (this.showAuthenticatedOptions) {
                try {
                    const applicationProfiles = await this.securityApiSvc.findMyApplicationProfilesAsync();
                    this.multipleProfiles = applicationProfiles.length > 1;
                } catch (e) {
                    this.toaster.error(e);
                }
            } else {
                this.multipleProfiles = false;
            }
        }

        private closeMenuOnResize(): void {
            angular.element(window).on('resize', () => {
                if (window.innerWidth > 768) {
                    (angular.element('#navmenu.in') as any).collapse('hide');
                }
            });
        }
    }

    remeCareSharedModule.component('rcHeader', {
        controller: HeaderController,
        templateUrl: 'framework/components/header/header.html',
        transclude: true,
    });
}
