module RemeCare.Shared.Framework.Directive {

    class RightCreateButtonDirective implements ng.IDirective {

        restrict = 'A';

        scope: { [boundProperty: string]: string } = {
            title: '@',
            create: '&?',
            link: '@?',
            ngDisabled: '<?',
            hide: '<?',
            ngRequired: '='
        }

        templateUrl = 'views/controls/rightCreateButton.html';
    }

    remeCareSharedModule.directive('rcRightCreateButton', () => new RightCreateButtonDirective());
}