namespace RemeCare.Shared {
    class RemeCareSharedConfig extends Framework.RouteConfiguration {
        protected config(): void {
            this.addComponentRoute(
                'applicationProfile',
                '/ApplicationProfile',
                'rcApplicationProfile',
                [],
                null,
                null,
                null,
                {
                    params: {
                        redirect: null,
                        params: null,
                    },
                }
            );

            this.add(
                'patientInformedConsentsOverview',
                {
                    component: 'rcPatientInformedConsentOverview',
                    url: '/MyInformedConsents',
                },
                ['Views_InformedConsent'],
                {
                    context: Shared.Framework.AuthContext.patientInformedConsents,
                    right: Shared.Framework.AuthRight.Write,
                }
            );

            this.add(
                'checkInformedConsent',
                {
                    component: 'rcPatientInformedConsents',
                    url: '/CheckInformedConsent',
                    params: {
                        redirect: null,
                        params: null,
                    },
                },
                ['Views_InformedConsent'],
                {
                    context: Shared.Framework.AuthContext.patientInformedConsents,
                    right: Shared.Framework.AuthRight.Read,
                }
            );

            this.$stateProvider
                .state('checkApplicationProfile', {
                    url: '/CheckApplicationProfile',
                    params: {
                        redirect: null,
                        params: null,
                    },
                    controller: 'checkApplicationProfileController',
                })
                .state('unauthorized', {
                    url: '/Unauthorized',
                    controller: () => {},
                    templateUrl: 'views/unauthorized.html',
                    data: {
                        allowAnonymous: true,
                    },
                })
                .state('userUnknown', {
                    url: '/UserUnknown',
                    component: 'rcUserUnknown',
                    data: {
                        allowAnonymous: true,
                    },
                } as any);
        }
    }

    remeCareSharedModule.config($stateProvider => new RemeCareSharedConfig($stateProvider));
}
