module RemeCare.Shared.Framework.Directive {

    class CreateButton {
        label: string;

        $onInit() {
            this.label = this.label || 'General.Add';
        }
    }

    remeCareSharedModule.component('rcCreateButton',
    {
        controller: CreateButton,
        controllerAs: 'createCtrl',
        templateUrl: 'views/controls/createButton.html',
        bindings: {
            create: '&?',
            link: '@?',
            label: '@?',
            hideLabel: '<?',
            ngDisabled: '<?',
            name: '@?'
        }
    });
}