var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Directive;
            (function (Directive) {
                var TimeDirective = /** @class */ (function () {
                    function TimeDirective($locale) {
                        var _this = this;
                        this.$locale = $locale;
                        this.restrict = 'E';
                        this.require = '^form';
                        this.scope = {
                            model: '=ngModel',
                            required: '=ngRequired',
                            readOnly: '=ngReadonly',
                            disabled: '=ngDisabled',
                            label: '@',
                            noStaticData: '=',
                            guidanceIconLocation: '<',
                            guidanceIconFunc: '<',
                            uniqueId: '@',
                        };
                        this.transclude = true;
                        this.templateUrl = 'views/formElements/time.html';
                        this.link = function (scope, element, attrs, formCtrl) {
                            scope.formCtrl = formCtrl;
                            scope.placeholder = _this.$locale.DATETIME_FORMATS.shortTime;
                            scope.dateModel = {
                                model: scope.model,
                            };
                            scope.$watch(function (s) { return s.model; }, function (newValue, oldValue) {
                                if (newValue !== oldValue && newValue !== scope.dateModel.model) {
                                    scope.dateModel.model = newValue;
                                }
                            });
                            scope.$watch(function (s) { return s.dateModel.model; }, function (newValue, oldValue) {
                                if (newValue !== oldValue && newValue !== scope.model) {
                                    scope.model = newValue;
                                }
                            });
                        };
                    }
                    return TimeDirective;
                }());
                Shared.remeCareSharedModule.directive('rcTime', function ($locale) { return new TimeDirective($locale); });
            })(Directive = Framework.Directive || (Framework.Directive = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
