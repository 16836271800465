namespace RemeCare.Shared.Framework.Directive {
    class InputDurationController implements ng.IComponentController {
        // bindings
        public uniqueName: string;
        public durationUnits: Shared.Contract.IEnumTranslation[];
        public model: Shared.Contract.IDuration;

        private change: () => void;

        constructor(
            private readonly idService: Shared.Framework.Service.IIdService,
            private readonly toaster: Shared.Framework.Toaster,
            private readonly masterdataSvc: Shared.Framework.MasterdataService,
            private readonly $timeout: ng.ITimeoutService
        ) {}

        public $onInit(): void {
            this.uniqueName = this.idService.generateId();
            this.loadDataAsync();
        }

        public quantityChanged(): void {
            if (this.model.Quantity == null) {
                this.model.Unit = null;
            }

            this.onChange();
        }

        public onChange(): void {
            if (this.change) {
                this.$timeout(() => {
                    this.change();
                });
            }
        }

        private async loadDataAsync(): Promise<void> {
            try {
                this.durationUnits = await this.masterdataSvc.getDurationUnitsAsync();
            } catch (e) {
                this.toaster.error(e);
            }
        }
    }

    remeCareSharedModule.component('rcInputDuration', {
        bindings: {
            label: '@',
            model: '=ngModel',
            required: '<ngRequired',
            disabled: '<ngDisabled',
            change: '&?ngChange',
            vertical: '<?',
            guidanceIconLocation: '<',
            guidanceIconFunc: '<',
            uniqueId: '@',
        },
        controller: InputDurationController,
        require: {
            formCtrl: '^form',
        },
        templateUrl: 'framework/directives/formElements/inputDuration/inputDuration.html',
    });
}
