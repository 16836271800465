var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Model;
            (function (Model) {
                var PatientInformedConsentStatusCode = Shared.Contract.Code.PatientInformedConsentStatusCode;
                var PatientInformedConsentQuestion = /** @class */ (function () {
                    function PatientInformedConsentQuestion(question, status) {
                        this.accepted = false;
                        this.text = question;
                        if (status === PatientInformedConsentStatusCode.Accepted) {
                            this.accepted = true;
                        }
                    }
                    return PatientInformedConsentQuestion;
                }());
                Model.PatientInformedConsentQuestion = PatientInformedConsentQuestion;
            })(Model = Framework.Model || (Framework.Model = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
