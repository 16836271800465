var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Factory;
            (function (Factory) {
                var TargetValueFactory = /** @class */ (function () {
                    function TargetValueFactory() {
                    }
                    TargetValueFactory.prototype.createTargetValue = function (serverObject) {
                        var result;
                        switch (serverObject.Type) {
                            case Shared.Contract.Code.TargetValueType.TargetDose:
                                result = new Framework.Model.TargetDose(serverObject);
                                break;
                            case Shared.Contract.Code.TargetValueType.TargetPhysicalQuantity:
                                result = new Framework.Model.TargetPhysicalQuantity(serverObject);
                                break;
                            case Shared.Contract.Code.TargetValueType.TargetPhysicalQuantityRange:
                                result = new Framework.Model.TargetPhysicalQuantityRange(serverObject);
                                break;
                            default:
                                result = new Framework.Model.TargetValue();
                        }
                        return result;
                    };
                    return TargetValueFactory;
                }());
                Factory.TargetValueFactory = TargetValueFactory;
            })(Factory = Framework.Factory || (Framework.Factory = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
