module RemeCare.Shared.Framework.InformedConsent {
    import InformedConsent = Shared.Framework.Model.InformedConsent;
    import InformedConsentVersion = Shared.Framework.Model.InformedConsentVersion;

    class InformedConsentVersionController implements ng.IComponentController {
        public informedConsent: Shared.Framework.Model.InformedConsent;
        public $transition$;
        public grid: Shared.Framework.Grid.Grid<Shared.Framework.Model.InformedConsentVersion>;
        public canCreateNewVersion: boolean;
        public readOnly: boolean;
        public routePrefix: string;
        public languages: Array<Shared.Contract.IEnumCodeTranslation>;

        constructor(
            private readonly informedConsentSvc: Shared.Framework.Service.InformedConsentService,
            private readonly toaster: Shared.Framework.Toaster,
            private readonly masterdataSvc: Shared.Framework.MasterdataService,
            private readonly gridBuilderSvc: Shared.Framework.Grid.GridBuilderFactory
        ) {}

        public async $onInit(): Promise<void> {
            this.routePrefix = this.$transition$.params().routePrefix;
            this.buildGrid();
            this.canCreateNewVersion = false;
            try {
                this.languages = await this.masterdataSvc.getInterfaceLanguagesAsync();
                const informedConsentId = this.$transition$.params().informedConsentId;
                const serverInformedConsent = await this.informedConsentSvc.getInformedConsent(informedConsentId);
                this.informedConsent = new InformedConsent(this.languages, serverInformedConsent);
                if (!_.some(this.informedConsent.versions, v => v.isDraft)) {
                    this.canCreateNewVersion = true;
                }

                this.readOnly = !serverInformedConsent.IsDraft;
                this.grid.setData(this.informedConsent.versions);
            } catch (e) {
                this.toaster.error(e);
            }
        }

        public createNewVersion(): void {
            const newVersion = new InformedConsentVersion();
            this.canCreateNewVersion = false;
            newVersion.isDraft = true;
            newVersion.setValidity(Shared.DateHelper.tomorrow(), null);
            this.informedConsent.version = null;
            this.informedConsent.versions.unshift(newVersion);
            this.readOnly = false;
        }

        public async showDetail(informedConsentVersion: InformedConsentVersion): Promise<void> {
            try {
                this.informedConsent = new InformedConsent(
                    this.languages,
                    await this.informedConsentSvc.getInformedConsent(
                        this.informedConsent.id,
                        informedConsentVersion.isDraft,
                        Shared.DateHelper.toServerDateString(informedConsentVersion.validFromDate)
                    )
                );
                this.readOnly = !informedConsentVersion.isDraft;
            } catch (e) {
                this.toaster.error(e);
            }
        }

        private buildGrid(): void {
            this.grid = this.gridBuilderSvc
                .createGridBuilder<InformedConsentVersion>()
                .addColumn('version', 'General.Version')
                .addBoolColumn('isDraft', 'Views.InformedConsent.Draft')
                .addDateColumn('validFromDisplay', 'General.StartDate', 'shortDate')
                .addDateColumn('validUntilDisplay', 'General.EndDate', 'shortDate')
                .addColumn('validityChangedRemark', 'General.Remark')
                .addActionColumn('folder-open', v => this.showDetail(v), true)
                .build();
        }
    }

    Shared.remeCareSharedModule.component('rcInformedConsentVersion', {
        bindings: {
            $transition$: '<'
        },
        controller: InformedConsentVersionController,
        templateUrl: 'framework/informedConsent/informedConsentVersion/informedConsentVersion.html'
    });
}
