module RemeCare.Shared.Framework.Service {
    export class PatientInformedConsentService {
        private patientInformedConsentsChecked: boolean;
        private acceptedInformedConsents: number;

        constructor(private readonly authservice: AuthService) {}

        public shouldCheckPatientInformedConsents(): boolean {
            if (!this.authservice.isPatient()) {
                return false;
            }

            if (this.patientInformedConsentsChecked) {
                return false;
            }

            return true;
        }

        public allPatientInformedConsentsChecked(acceptedInformedConsents: number): void {
            this.patientInformedConsentsChecked = true;
            this.acceptedInformedConsents = acceptedInformedConsents;
        }

        public hasAcceptedPatientInformedConsents(): boolean {
            return this.acceptedInformedConsents > 0;
        }
    }

    remeCareSharedModule.service('patientInformedConsentService', PatientInformedConsentService);
}
