var RemeCare;
(function (RemeCare) {
    var Digest;
    (function (Digest) {
        var DigestRunner = /** @class */ (function () {
            function DigestRunner($rootScope) {
                this.$rootScope = $rootScope;
                this.run();
            }
            DigestRunner.prototype.run = function () {
                var self = this;
                window['Promise'] = (function (Promise) {
                    var NewPromise = function (fn) {
                        var promise = new Promise(fn);
                        promise.then(function (value) {
                            self.triggerDigestIfNeeded();
                            return value;
                        }, function (reason) {
                            self.triggerDigestIfNeeded();
                            return reason;
                        });
                        return promise;
                    };
                    // Clone the prototype
                    NewPromise.prototype = Promise.prototype;
                    // Clone all writable instance properties
                    for (var _i = 0, _a = Object.getOwnPropertyNames(Promise); _i < _a.length; _i++) {
                        var propertyName = _a[_i];
                        var propertyDescription = Object.getOwnPropertyDescriptor(Promise, propertyName);
                        if (propertyDescription.writable) {
                            NewPromise[propertyName] = Promise[propertyName];
                        }
                    }
                    return NewPromise;
                })(Promise);
            };
            DigestRunner.prototype.triggerDigestIfNeeded = function () {
                this.$rootScope.$applyAsync();
            };
            return DigestRunner;
        }());
        Digest.remeCareDigestModule.run(function ($rootScope) { return new DigestRunner($rootScope); });
    })(Digest = RemeCare.Digest || (RemeCare.Digest = {}));
})(RemeCare || (RemeCare = {}));
