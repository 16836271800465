var RemeCare;
(function (RemeCare) {
    var Digest;
    (function (Digest) {
        Digest.remeCareDigestModule = angular.module('RemeCare.Digest', []);
    })(Digest = RemeCare.Digest || (RemeCare.Digest = {}));
})(RemeCare || (RemeCare = {}));
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        Shared.remeCareSharedModule = angular
            .module('RemeCare.Shared', [
            'angularFileUpload',
            'checklist-model',
            'mgcrea.ngStrap.helpers.dateParser',
            'mgcrea.ngStrap.helpers.dimensions',
            'mgcrea.ngStrap.tooltip',
            'mgcrea.ngStrap.datepicker',
            'mgcrea.ngStrap.timepicker',
            'ngSanitize',
            'RemeCare.Shared.Templates',
            'ui.bootstrap.collapse',
            'ui.bootstrap.dropdown',
            'ui.bootstrap.modal',
            'ui.bootstrap.pagination',
            'ui.bootstrap.progressbar',
            'ui.bootstrap.tpls',
            'ui.grid',
            'ui.grid.resizeColumns',
            'ui.grid.autoResize',
            'ui.grid.expandable',
            'ui.grid.edit',
            'ui.grid.selection',
            'ui.router',
            'uuid4',
            RemeCare.Digest.remeCareDigestModule.name,
        ])
            .config(function ($provide) {
            // Override the ScrollEvent to allow scrolling over grids
            $provide.decorator('ScrollEvent', function ($delegate) {
                $delegate.prototype.atTop = function (scrollTop) {
                    return true;
                };
                $delegate.prototype.atBottom = function (scrollTop) {
                    return true;
                };
                return $delegate;
            });
        });
        // TODO: To remove when new version of angular-guid available without bug
        // Added for Guid.newGuid(): throws NullException in IE since window.crypto is not defined, but window.Crypto is
        window.crypto = window.crypto || window.Crypto;
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
