var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Model;
            (function (Model) {
                var PossibleProductForAction = /** @class */ (function () {
                    function PossibleProductForAction() {
                    }
                    PossibleProductForAction.prototype.displayClasses = function () {
                        if (this.ProductClass && this.ProductClass.Id)
                            return [this.ProductClass];
                        return this.ProductDisplayClasses;
                    };
                    return PossibleProductForAction;
                }());
                Model.PossibleProductForAction = PossibleProductForAction;
            })(Model = Framework.Model || (Framework.Model = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
