var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Directive;
            (function (Directive) {
                var InputAddressController = /** @class */ (function () {
                    function InputAddressController(toaster) {
                        this.toaster = toaster;
                        this.warningDirty = false;
                    }
                    InputAddressController.prototype.$onInit = function () { };
                    InputAddressController.prototype.validate = function (address) {
                        this.hasWarning = false;
                        if (address.country === Shared.Contract.Code.Country.Belgium &&
                            address.zipCode &&
                            !this.containsOnlyFourNumbers(address.zipCode)) {
                            if (!this.warningDirty) {
                                this.toaster.warning('General.ZipCodeWarning');
                                this.warningDirty = true;
                            }
                            this.hasWarning = true;
                        }
                        else {
                            this.warningDirty = false;
                        }
                    };
                    InputAddressController.prototype.containsOnlyFourNumbers = function (string) {
                        return /^\d{4}$/.test(string);
                    };
                    return InputAddressController;
                }());
                Shared.remeCareSharedModule.component('rcInputAddress', {
                    templateUrl: 'views/formElements/inputAddress.html',
                    controller: InputAddressController,
                    bindings: {
                        model: '=ngModel',
                        required: '=ngRequired',
                        countryRequired: '=',
                        disabled: '=ngDisabled',
                        readOnly: '=ngReadonly',
                        countries: '=ngCountryOptions',
                        complete: '=',
                        guidanceIconLocation: '<',
                        guidanceIconFunc: '<',
                        uniqueId: '@',
                    },
                    require: {
                        formCtrl: '^form',
                    },
                });
            })(Directive = Framework.Directive || (Framework.Directive = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
