namespace RemeCare.Shared.Framework.Service {
    import SearchResult = Shared.Contract.ISearchResult;
    import IInformedConsentSummary = Shared.Contract.Read.IInformedConsentSummary;

    export class InformedConsentService extends Shared.Framework.ApiServiceBase {
        public static $inject = Shared.Framework.ApiServiceBase.injectionParameters;

        public getInformedConsents(therapyId?: Shared.Contract.Guid): Promise<SearchResult<IInformedConsentSummary>> {
            let params;
            if (therapyId != null) {
                params = {
                    therapyId: therapyId,
                };
            } else {
                params = null;
            }
            return this.getPromise('InformedConsents', params, 'informedConsents.overview');
        }

        public saveInformedConsentDraft(informedConsent: Shared.Contract.Write.IInformedConsent): Promise<{}> {
            return this.postPromise('InformedConsents', informedConsent, 'informedConsent.save');
        }

        public getInformedConsent(
            informedConsentId: Shared.Contract.Guid,
            draft?: boolean,
            perspectiveDate?: string
        ): Promise<Shared.Contract.Read.IInformedConsent> {
            return this.getPromise(
                `InformedConsents/${informedConsentId}`,
                {
                    draft: draft,
                    perspectiveDate: perspectiveDate,
                },
                'informedConsent'
            );
        }

        public approveInformedConsent(
            informedConsentId: Shared.Contract.Guid,
            validFrom: string,
            shouldReApprove: boolean
        ) {
            return this.putPromise(
                `InformedConsents/${informedConsentId}/Approve`,
                {
                    InformedConsentId: informedConsentId,
                    validFrom: validFrom,
                    shouldReApprove: shouldReApprove,
                },
                'informedConsent.save'
            );
        }

        public updateInformedConsentDraft(informedConsent: Shared.Contract.Write.IInformedConsent): Promise<{}> {
            return this.putPromise(`InformedConsents/${informedConsent.Id}`, informedConsent, 'informedConsent.save');
        }

        public getInformedConsentSummary(
            patientId: Shared.Contract.Guid
        ): Promise<SearchResult<Shared.Contract.Read.IPatientInformedConsentSummary>> {
            return this.getPromise<SearchResult<Shared.Contract.Read.IPatientInformedConsentSummary>>(
                `Patients/${patientId}/InformedConsents`,
                null,
                'patientInformedConsent.summary'
            );
        }

        public getPatientInformedConsent(
            patientId: Shared.Contract.Guid,
            informedConsentId
        ): Promise<Shared.Contract.Read.IPatientInformedConsentReadModel> {
            return this.getPromise<Shared.Contract.Read.IPatientInformedConsentReadModel>(
                `Patients/${patientId}/InformedConsents/${informedConsentId}`,
                null,
                'patientInformedConsent'
            );
        }

        public saveInformedConsent(
            patientId: Shared.Contract.Guid,
            informedConsentId: Shared.Contract.Guid,
            accepted: boolean,
            remark: string
        ): Promise<{}> {
            const params = {
                accepted: accepted,
                remark: remark,
            };
            return this.postPromise(
                `Patients/${patientId}/InformedConsents/${informedConsentId}`,
                params,
                'patientInformedConsent.save'
            );
        }

        public revokePatientInformedConsent(
            patientId: Shared.Contract.Guid,
            informedConsentId: Shared.Contract.Guid,
            remark: string
        ): Promise<{}> {
            const params = {
                remark: remark,
            };
            return this.putPromise(`Patients/${patientId}/InformedConsents/${informedConsentId}`, params);
        }

        public getInformedConsentsHistory(patientId: Shared.Contract.Guid) {
            return this.getPromise<Shared.Contract.Read.IInformedConsentHistoryReadModel[]>(
                `Patients/${patientId}/InformedConsents/History`,
                null,
                'patientInformedConsent.History'
            );
        }
    }

    remeCareSharedModule.service('informedConsentSvc', InformedConsentService);
}
