var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Contract;
        (function (Contract) {
            var Code;
            (function (Code) {
                var GuidanceStatus;
                (function (GuidanceStatus) {
                    GuidanceStatus[GuidanceStatus["InPreparation"] = 1] = "InPreparation";
                    GuidanceStatus[GuidanceStatus["InUse"] = 2] = "InUse";
                    GuidanceStatus[GuidanceStatus["Inactive"] = 3] = "Inactive";
                })(GuidanceStatus = Code.GuidanceStatus || (Code.GuidanceStatus = {}));
            })(Code = Contract.Code || (Contract.Code = {}));
        })(Contract = Shared.Contract || (Shared.Contract = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
