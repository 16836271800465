module RemeCare.Shared.Framework.InformedConsent {
    import PatientInformedConsent = Shared.Framework.Model.PatientInformedConsent;

    class PatientInformedConsentOverviewController implements ng.IComponentController {
        public grid: Shared.Framework.Grid.Grid<Shared.Contract.Read.IPatientInformedConsentSummary>;
        public informedConsents: Shared.Contract.Read.IPatientInformedConsentSummary[];
        public acceptedInformedConsents: Shared.Contract.Read.IPatientInformedConsentSummary[];
        public informedConsentsToAccept: Shared.Contract.Read.IPatientInformedConsentSummary[];
        public informedConsent: Framework.Model.PatientInformedConsent;
        public patientId: Shared.Contract.Guid;

        constructor(
            private readonly $dialog: Shared.Service.DialogService,
            private readonly $translate: ng.translate.ITranslateService,
            private readonly gridBuilderSvc: Framework.Grid.GridBuilderFactory,
            private readonly informedConsentSvc: Framework.Service.InformedConsentService,
            private readonly authservice: Framework.AuthService,
            private readonly toaster: Toaster
        ) {}

        public $onInit(): void {
            this.buildGrid();
            this.patientId = this.authservice.getClaim(Shared.Framework.ClaimTypes.partyRoleId);
            this.loadPatientInformedConsents();
            this.grid.setData(this.acceptedInformedConsents);
        }
        
        public revoke(): void {
            this.$dialog
                .warningMessageBox(
                    this.$translate.instant('Views.InformedConsent.RevokeTitle'),
                    this.$translate.instant('Views.InformedConsent.RevokeWarning'),
                    [
                        { result: 'cancel', label: this.$translate.instant('General.Cancel'), cssClass: 'btn-default' },
                        {
                            result: 'yes',
                            label: this.$translate.instant('Views.InformedConsent.AcceptRevoke'),
                            cssClass: 'btn-primary'
                        }
                    ]
                )
                .then(async result => {
                    if (result === 'yes') {
                        try {
                            await this.informedConsentSvc.revokePatientInformedConsent(
                                this.patientId,
                                this.informedConsent.informedConsentId,
                                null
                            );
                            this.loadPatientInformedConsents();
                        } catch (e) {
                            this.toaster.error(e);
                        }
                    }
                });
        }

        private async loadPatientInformedConsents(): Promise<void> {
            try {
                const informedConsentsSearchResult = await this.informedConsentSvc.getInformedConsentSummary(
                    this.patientId
                );
                this.informedConsents = informedConsentsSearchResult.Items;
                this.acceptedInformedConsents = _.filter(
                    this.informedConsents,
                    ic => ic.Status === Shared.Contract.Code.PatientInformedConsentStatusCode.Accepted
                );
                this.informedConsentsToAccept = _.filter(
                    this.informedConsents,
                    ic => ic.Status !== Shared.Contract.Code.PatientInformedConsentStatusCode.Accepted
                );
                this.grid.setData(this.acceptedInformedConsents);
                if (this.acceptedInformedConsents.length < 1 && this.informedConsentsToAccept.length > 0) {
                    this.authservice.signOut();
                }

                if (this.acceptedInformedConsents.length > 0) {
                    const patientInformedConsentReadModel = await this.informedConsentSvc.getPatientInformedConsent(
                        this.patientId,
                        this.acceptedInformedConsents[0].InformedConsentId
                    );
                    this.informedConsent = new PatientInformedConsent(patientInformedConsentReadModel);
                }
            } catch (e) {
                this.toaster.error(e);
            }
        }

        private async showDetail(informedConsent: Shared.Contract.Read.IPatientInformedConsentSummary): Promise<void> {
            const acceptedInformedConsentReadModel = await this.informedConsentSvc.getPatientInformedConsent(
                this.patientId,
                informedConsent.InformedConsentId
            );
            this.informedConsent = new PatientInformedConsent(acceptedInformedConsentReadModel);
        }

        private buildGrid(): void {
            this.grid = this.gridBuilderSvc
                .createGridBuilder<Shared.Contract.Read.IPatientInformedConsentSummary>()
                .addColumn('TherapyName', 'General.Therapy')
                .addColumn('Version', 'General.Version')
                .addColumn('Title', 'General.Name')
                .addActionColumn('folder-open', ic => this.showDetail(ic))
                .build();
        }
    }

    remeCareSharedModule.component('rcPatientInformedConsentOverview', {
        controller: PatientInformedConsentOverviewController,
        templateUrl: 'framework/informedConsent/patientInformedConsentOverview/patientInformedConsentOverview.html'
    });
}
