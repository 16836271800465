/// <reference path="../models/careRequestConfiguration.ts"/>
var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Factory;
            (function (Factory) {
                var CareRequestPart = Framework.Model.CareRequestPart;
                var CareRequestorDefinition = Framework.Model.CareRequestorDefinition;
                var CareRequestRoleDefinition = Framework.Model.CareRequestRoleDefinition;
                var QualitativeAnamnesisDefinition = Framework.Model.QualitativeAnamnesisDefinition;
                var QuantitativeAnamnesisDefinition = Framework.Model.QuantitativeAnamnesisDefinition;
                var OptionDefinition = Framework.Model.OptionDefinition;
                var DateDefinition = Framework.Model.DateDefinition;
                var StringDefinition = Framework.Model.StringDefinition;
                var CareRequestDocumentDefinition = Framework.Model.CareRequestDocumentDefinition;
                var MedicationSchemaDefinition = Framework.Model.MedicationSchemaDefinition;
                var DiseaseDefinition = Framework.Model.DiseaseDefinition;
                var CareRequestPartFactory = /** @class */ (function () {
                    function CareRequestPartFactory() {
                    }
                    CareRequestPartFactory.prototype.createCareRequestPart = function (serverObject) {
                        return this.create(serverObject.Type.Id, serverObject);
                    };
                    CareRequestPartFactory.prototype.createFromType = function (type) {
                        var part = this.create(type.Id);
                        part.type = type;
                        return part;
                    };
                    CareRequestPartFactory.prototype.create = function (type, serverObject) {
                        switch (type) {
                            case Shared.Contract.Code.CareRequestPartType.CareRequestor:
                                return new CareRequestorDefinition(serverObject);
                            case Shared.Contract.Code.CareRequestPartType.CareRequestRole:
                                return new CareRequestRoleDefinition(serverObject);
                            case Shared.Contract.Code.CareRequestPartType.QualitativeAnamnesis:
                                return new QualitativeAnamnesisDefinition(serverObject);
                            case Shared.Contract.Code.CareRequestPartType.QuantitativeAnamnesis:
                                return new QuantitativeAnamnesisDefinition(serverObject);
                            case Shared.Contract.Code.CareRequestPartType.TherapyOption:
                                return new OptionDefinition(serverObject);
                            case Shared.Contract.Code.CareRequestPartType.Date:
                                return new DateDefinition(serverObject);
                            case Shared.Contract.Code.CareRequestPartType.String:
                                return new StringDefinition(serverObject);
                            case Shared.Contract.Code.CareRequestPartType.RequestDocument:
                                return new CareRequestDocumentDefinition(serverObject);
                            case Shared.Contract.Code.CareRequestPartType.MedicationSchemaTherapyAction:
                            case Shared.Contract.Code.CareRequestPartType.MedicationSchemaOther:
                                return new MedicationSchemaDefinition(serverObject);
                            case Shared.Contract.Code.CareRequestPartType.Comorbidities:
                            case Shared.Contract.Code.CareRequestPartType.TreatedDiseases:
                                return new DiseaseDefinition(serverObject);
                            default:
                                return new CareRequestPart(serverObject);
                        }
                    };
                    return CareRequestPartFactory;
                }());
                Factory.CareRequestPartFactory = CareRequestPartFactory;
            })(Factory = Framework.Factory || (Framework.Factory = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
