var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Contract;
        (function (Contract) {
            var Code;
            (function (Code) {
                var Right;
                (function (Right) {
                    Right[Right["Nothing"] = 0] = "Nothing";
                    Right[Right["Read"] = 1] = "Read";
                    Right[Right["Write"] = 2] = "Write";
                    Right[Right["Create"] = 4] = "Create";
                    Right[Right["Delete"] = 8] = "Delete";
                    Right[Right["All"] = 15] = "All";
                })(Right = Code.Right || (Code.Right = {}));
            })(Code = Contract.Code || (Contract.Code = {}));
        })(Contract = Shared.Contract || (Shared.Contract = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
