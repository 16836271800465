/// <reference path="../services/authservice.ts"/>

module RemeCare.Shared.Directive {
    import ClaimTypes = RemeCare.Shared.Framework.ClaimTypes;
    import ApplicationProfileType = RemeCare.Shared.Contract.Code.ApplicationProfileType;

    interface IApplicationProfileScope extends ng.IScope {
        hideForProfile: ApplicationProfileType
    };

    abstract class ApplicationProfileDirective implements ng.IDirective {

        restrict = 'A';

        scope: { [boundProperty: string]: string } = {
            hideForProfile: '='
        }

        constructor(private authservice: Shared.Framework.AuthService) {
        }

        link: ng.IDirectiveLinkFn = (scope: IApplicationProfileScope, element: ng.IAugmentedJQuery, attrs) => {
            scope.$on('rightsChanged', (event: ng.IAngularEvent) => {
                this.checkProfile(scope.hideForProfile, element);
            });
            this.checkProfile(scope.hideForProfile, element);
        }

        private checkProfile(profile: ApplicationProfileType, element: ng.IAugmentedJQuery): void {
            var isProfile = this.authservice.getClaim(ClaimTypes.applicationProfile) === profile.toString();
            if (isProfile) {
                this.onIsProfile(element);
            } else {
                this.onNotIsProfile(element);
            }
        }

        protected abstract onIsProfile(element: ng.IAugmentedJQuery): void;

        protected abstract onNotIsProfile(element: ng.IAugmentedJQuery): void;
    }

    class HideForProfileDirective extends ApplicationProfileDirective {

        protected onIsProfile(element: ng.IAugmentedJQuery): void {
            element.css('display', 'none');
        }

        protected onNotIsProfile(element: ng.IAugmentedJQuery): void {
            element.css('display', '');
        }
    }

    remeCareSharedModule.directive('hideForProfile', (authservice) => new HideForProfileDirective(authservice));

    interface IShowForProfileScope extends ng.IScope {
        showForProfile: ApplicationProfileType
    };

    class ShowForProfileDirective implements ng.IDirective {
        restrict = 'A';

        scope: { [boundProperty: string]: string } = {
            showForProfile: '='
        }

        constructor(private authservice: Shared.Framework.AuthService) {
        }

        link: ng.IDirectiveLinkFn = (scope: IShowForProfileScope, element: ng.IAugmentedJQuery, attrs) => {
            scope.$on('rightsChanged', (event: ng.IAngularEvent) => {
                this.checkProfile(scope.showForProfile, element);
            });
            this.checkProfile(scope.showForProfile, element);
        }

        private checkProfile(profile: ApplicationProfileType, element: ng.IAugmentedJQuery): void {
            var isProfile = this.authservice.getClaim(ClaimTypes.applicationProfile) === profile.toString();
            if (isProfile) {
                this.onIsProfile(element);
            } else {
                this.onNotIsProfile(element);
            }
        }

        protected onIsProfile(element: ng.IAugmentedJQuery): void {
            element.css('display', '');
        }

        protected onNotIsProfile(element: ng.IAugmentedJQuery): void {
            element.css('display', 'none');
        }
    }

    remeCareSharedModule.directive('showForProfile', (authservice) => new ShowForProfileDirective(authservice));
}