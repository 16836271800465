var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Directive;
            (function (Directive) {
                var InputToggleController = /** @class */ (function () {
                    function InputToggleController() {
                    }
                    InputToggleController.prototype.$onInit = function () { };
                    InputToggleController.prototype.labelClicked = function () {
                        if (this.disabled) {
                            return;
                        }
                        this.model = !this.model;
                        this.changed();
                    };
                    return InputToggleController;
                }());
                Shared.remeCareSharedModule.component('rcInputToggleInline', {
                    bindings: {
                        changed: '&ngChange',
                        disabled: '=ngDisabled',
                        label: '@',
                        model: '=ngModel',
                        required: '=ngRequired',
                        guidanceIconLocation: '<',
                        guidanceIconFunc: '<',
                        uniqueId: '@',
                    },
                    controller: InputToggleController,
                    require: {
                        formCtrl: '^form',
                    },
                    templateUrl: 'views/formElements/inputToggleInline.html',
                });
            })(Directive = Framework.Directive || (Framework.Directive = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
