var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Directive;
            (function (Directive) {
                var TimeInputDirective = /** @class */ (function () {
                    function TimeInputDirective() {
                        this.require = '?ngModel';
                        this.link = function (scope, element, attrs, ngModelCtrl) {
                            if (!ngModelCtrl)
                                return;
                            element.keyup(function (e) {
                                var val = element.val();
                                var toBePrepended = ['3', '4', '5', '6', '7', '8', '9'];
                                if (val != null && val.length === 1 && _(toBePrepended).contains(val)) {
                                    val = "0" + val;
                                }
                                if (val != null && val.length === 2) {
                                    event.preventDefault();
                                    if (e.keyCode === 8) {
                                        val = val[0];
                                        element.val(val);
                                    }
                                    else {
                                        val = val + ":";
                                        element.val(val);
                                    }
                                }
                            });
                        };
                    }
                    return TimeInputDirective;
                }());
                Shared.remeCareSharedModule
                    .directive('rcTimeInput', function () { return new TimeInputDirective(); })
                    .directive('bsTimepicker', function () { return new TimeInputDirective(); });
            })(Directive = Framework.Directive || (Framework.Directive = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
