var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Directive;
            (function (Directive) {
                var InputQualitativeController = /** @class */ (function () {
                    function InputQualitativeController() {
                    }
                    InputQualitativeController.prototype.$onInit = function () { };
                    return InputQualitativeController;
                }());
                Shared.remeCareSharedModule.component('rcInputQualitative', {
                    bindings: {
                        singleModel: '=',
                        multiModel: '=',
                        label: '@',
                        uiControlType: '=',
                        options: '=',
                        displayText: '@',
                        value: '@',
                        required: '=ngRequired',
                        disabled: '=ngDisabled',
                        inputClass: '<',
                        iconField: '@',
                        showErrors: '=',
                        guidanceIconLocation: '<',
                        guidanceIconFunc: '<',
                        uniqueId: '@',
                    },
                    controller: InputQualitativeController,
                    require: {
                        formCtrl: '^form',
                    },
                    templateUrl: 'framework/directives/formElements/inputQualitative/inputQualitative.html',
                });
            })(Directive = Framework.Directive || (Framework.Directive = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
