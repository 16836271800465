namespace RemeCare.Shared.Framework.Directive {

    class VerticalScrollListenerDirective implements ng.IDirective {

        // @ngInject
        public static factory($rootScope: ng.IRootScopeService): VerticalScrollListenerDirective {
            return new VerticalScrollListenerDirective($rootScope);
        }

        constructor(
            private readonly $rootScope: ng.IRootScopeService
        ) {}

        public restrict = 'A';

        public link = (scope: ng.IScope, element: ng.IAugmentedJQuery) => {
            scope.$watch(() => element[0].clientWidth,
                () => {
                    this.$rootScope.$emit(AppEvents.verticalScrollBar);
                });
        }
    }

    remeCareSharedModule.directive('rcVerticalScrollListener', VerticalScrollListenerDirective.factory);
}