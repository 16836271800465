var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Directive;
        (function (Directive) {
            var NgMinDirective = /** @class */ (function () {
                function NgMinDirective() {
                    var _this = this;
                    this.restrict = 'A';
                    this.require = 'ngModel';
                    this.link = function (scope, elem, attr, ctrl) {
                        scope.$watch(attr.ngMin, function () {
                            ctrl.$setViewValue(ctrl.$viewValue);
                        });
                        var minValidator = function (value) {
                            var min = scope.$eval(attr.ngMin) || 0;
                            if (!_this.isEmpty(value) && value < min) {
                                ctrl.$setValidity('ngMin', false);
                                return undefined;
                            }
                            else {
                                ctrl.$setValidity('ngMin', true);
                                return value;
                            }
                        };
                        ctrl.$parsers.push(minValidator);
                        ctrl.$formatters.push(minValidator);
                    };
                }
                NgMinDirective.prototype.isEmpty = function (value) {
                    return angular.isUndefined(value) || value === '' || value === null || value !== value;
                };
                return NgMinDirective;
            }());
            var NgMaxDirective = /** @class */ (function () {
                function NgMaxDirective() {
                    var _this = this;
                    this.restrict = 'A';
                    this.require = 'ngModel';
                    this.link = function (scope, elem, attr, ctrl) {
                        scope.$watch(attr.ngMax, function () {
                            ctrl.$setViewValue(ctrl.$viewValue);
                        });
                        var maxValidator = function (value) {
                            var max = scope.$eval(attr.ngMax) || Infinity;
                            if (!_this.isEmpty(value) && value > max) {
                                ctrl.$setValidity('ngMax', false);
                                return undefined;
                            }
                            else {
                                ctrl.$setValidity('ngMax', true);
                                return value;
                            }
                        };
                        ctrl.$parsers.push(maxValidator);
                        ctrl.$formatters.push(maxValidator);
                    };
                }
                NgMaxDirective.prototype.isEmpty = function (value) {
                    return angular.isUndefined(value) || value === '' || value === null || value !== value;
                };
                return NgMaxDirective;
            }());
            angular.module('RemeCare.Shared')
                .directive('ngMin', function () { return new NgMinDirective(); })
                .directive('ngMax', function () { return new NgMaxDirective(); });
        })(Directive = Shared.Directive || (Shared.Directive = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
