var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Filter;
        (function (Filter) {
            var _this = this;
            var enumFilter = function ($rootScope, masterdataSvc) {
                var enumerations = {};
                var serviceInvoked = {};
                var filter = function (enumValue, enumType) {
                    if (enumValue == null || enumType == null) {
                        return '';
                    }
                    var enumeration = enumerations[enumType];
                    if (enumeration == null) {
                        return '';
                    }
                    return enumeration[enumValue];
                };
                // clean enumerations when language has changed so the new translations will be retrieved
                $rootScope.$on('applicationLanguageChanged', function () {
                    enumerations = {};
                    serviceInvoked = {};
                });
                var retrieveAsyncFunction = function (enumType) { return __awaiter(_this, void 0, void 0, function () {
                    var data;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                serviceInvoked[enumType] = true;
                                return [4 /*yield*/, masterdataSvc.getReferenceDataAsync(enumType)];
                            case 1:
                                data = _a.sent();
                                enumerations[enumType] = {};
                                _(data).forEach(function (d) {
                                    enumerations[enumType][d.Id] = d.Text;
                                });
                                return [2 /*return*/];
                        }
                    });
                }); };
                var filterFunction = function (enumValue, enumType) {
                    if (!enumerations[enumType]) {
                        if (!serviceInvoked[enumType]) {
                            retrieveAsyncFunction(enumType);
                        }
                        return '-';
                    }
                    else {
                        return filter(enumValue, enumType);
                    }
                };
                var x = filterFunction;
                x.$stateful = true;
                return x;
            };
            Shared.remeCareSharedModule.filter('enum', enumFilter);
        })(Filter = Shared.Filter || (Shared.Filter = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
