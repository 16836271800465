var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Contract;
        (function (Contract) {
            var Code;
            (function (Code) {
                var ApplicationProfileType;
                (function (ApplicationProfileType) {
                    ApplicationProfileType[ApplicationProfileType["Superuser"] = 1] = "Superuser";
                    ApplicationProfileType[ApplicationProfileType["CareManager"] = 2] = "CareManager";
                    ApplicationProfileType[ApplicationProfileType["AdministrativeManager"] = 3] = "AdministrativeManager";
                    ApplicationProfileType[ApplicationProfileType["CareProvider"] = 4] = "CareProvider";
                    ApplicationProfileType[ApplicationProfileType["TherapyProvider"] = 5] = "TherapyProvider";
                    ApplicationProfileType[ApplicationProfileType["Patient"] = 6] = "Patient";
                    ApplicationProfileType[ApplicationProfileType["InformalCareProvider"] = 7] = "InformalCareProvider";
                    ApplicationProfileType[ApplicationProfileType["Configurator"] = 8] = "Configurator";
                    ApplicationProfileType[ApplicationProfileType["UnknownCareProvider"] = 9] = "UnknownCareProvider";
                })(ApplicationProfileType = Code.ApplicationProfileType || (Code.ApplicationProfileType = {}));
            })(Code = Contract.Code || (Contract.Code = {}));
        })(Contract = Shared.Contract || (Shared.Contract = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
