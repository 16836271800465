var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Factory;
            (function (Factory) {
                var MonitoringPartSourceType = Shared.Contract.Code.MonitoringPartSourceType;
                var MonitoringPartType = Shared.Contract.Code.MonitoringPartType;
                var MonitoringPartSummaryFactory = /** @class */ (function () {
                    function MonitoringPartSummaryFactory(masterdataSvc, toaster) {
                        this.masterdataSvc = masterdataSvc;
                        this.toaster = toaster;
                    }
                    MonitoringPartSummaryFactory.prototype.createManyAsync = function (therapyId, serverObjects) {
                        return __awaiter(this, void 0, void 0, function () {
                            var sources, characteristicIds, observableEntityIds, ruleThesholdIds, objectiveIds, observableEntitiesPromise, characteristicPromise, ruleThresholdPromise, objectivePromise, monitoringPartTypesPromise, partWidthsPromise, _a, observableEntities_1, characteristics_1, ruleThresholdsResult_1, objectivesResult_1, monitoringPartTypes_1, partWidths_1, e_1;
                            var _this = this;
                            return __generator(this, function (_b) {
                                switch (_b.label) {
                                    case 0:
                                        if (!serverObjects || !serverObjects.length) {
                                            return [2 /*return*/, []];
                                        }
                                        sources = _(serverObjects)
                                            .chain()
                                            .map(function (so) { return so.MonitoringPartSources; })
                                            .flatten()
                                            .value();
                                        characteristicIds = _(sources)
                                            .chain()
                                            .map(function (s) { return s.CharacteristicId; })
                                            .filter(function (c) { return c != null; })
                                            .uniq()
                                            .value();
                                        observableEntityIds = _(sources)
                                            .chain()
                                            .map(function (s) { return s.ObservableEntityId; })
                                            .filter(function (c) { return c != null; })
                                            .uniq()
                                            .value();
                                        ruleThesholdIds = _(sources)
                                            .chain()
                                            .map(function (s) { return s.RuleThresholdId; })
                                            .filter(function (c) { return c != null; })
                                            .uniq()
                                            .value();
                                        objectiveIds = _(sources)
                                            .chain()
                                            .map(function (s) { return s.ObjectiveId; })
                                            .filter(function (c) { return c != null; })
                                            .uniq()
                                            .value();
                                        observableEntitiesPromise = observableEntityIds.length
                                            ? this.masterdataSvc.getObservableEntitiesAsync(observableEntityIds)
                                            : this.getEmptyPromise();
                                        characteristicPromise = characteristicIds.length
                                            ? this.masterdataSvc.getCharacteristicsAsync(characteristicIds, true)
                                            : this.getEmptyPromise();
                                        ruleThresholdPromise = ruleThesholdIds.length
                                            ? this.masterdataSvc.getRuleThresholdsAsync(therapyId, ruleThesholdIds)
                                            : this.getEmptyPromise();
                                        objectivePromise = objectiveIds.length
                                            ? this.masterdataSvc.getObjectivesAsync(therapyId, objectiveIds)
                                            : this.getEmptyPromise();
                                        monitoringPartTypesPromise = this.masterdataSvc.getMonitoringPartTypesAsync();
                                        partWidthsPromise = this.masterdataSvc.getPartWidthsAsync();
                                        _b.label = 1;
                                    case 1:
                                        _b.trys.push([1, 3, , 4]);
                                        return [4 /*yield*/, Promise.all([
                                                observableEntitiesPromise,
                                                characteristicPromise,
                                                ruleThresholdPromise,
                                                objectivePromise,
                                                monitoringPartTypesPromise,
                                                partWidthsPromise,
                                            ])];
                                    case 2:
                                        _a = _b.sent(), observableEntities_1 = _a[0], characteristics_1 = _a[1], ruleThresholdsResult_1 = _a[2], objectivesResult_1 = _a[3], monitoringPartTypes_1 = _a[4], partWidths_1 = _a[5];
                                        return [2 /*return*/, _(serverObjects).map(function (so) {
                                                return _this.create(so, characteristics_1, observableEntities_1, ruleThresholdsResult_1, objectivesResult_1, monitoringPartTypes_1, partWidths_1);
                                            })];
                                    case 3:
                                        e_1 = _b.sent();
                                        this.toaster.error(e_1);
                                        return [3 /*break*/, 4];
                                    case 4: return [2 /*return*/];
                                }
                            });
                        });
                    };
                    MonitoringPartSummaryFactory.prototype.getEmptyPromise = function () {
                        return new Promise(function (resolve) { return resolve([]); });
                    };
                    MonitoringPartSummaryFactory.prototype.create = function (serverObject, characteristics, observableEntities, ruleThresholds, objectives, monitoringPartTypes, partWidths) {
                        var result = new Framework.Model.MonitoringPartSummary();
                        result.id = serverObject.Id;
                        result.sequence = serverObject.Sequence;
                        result.type = _.find(monitoringPartTypes, function (m) { return m.Id === serverObject.Type; });
                        result.width = _.find(partWidths, function (p) { return p.Id === serverObject.Width; });
                        result.assignedGroup = serverObject.AssignedGroup;
                        result.isMobile = serverObject.IsMobile;
                        var characteristicIds = _(serverObject.MonitoringPartSources)
                            .chain()
                            .map(function (mps) { return mps.CharacteristicId; })
                            .filter(function (c) { return c != null; })
                            .value();
                        var observableEntityIds = _(serverObject.MonitoringPartSources)
                            .chain()
                            .map(function (mps) { return mps.ObservableEntityId; })
                            .filter(function (o) { return o != null; })
                            .value();
                        var ruleThresholdIds = _(serverObject.MonitoringPartSources)
                            .chain()
                            .map(function (mps) { return mps.RuleThresholdId; })
                            .filter(function (r) { return r != null; })
                            .value();
                        var objectiveIds = _(serverObject.MonitoringPartSources)
                            .chain()
                            .map(function (mps) { return mps.ObjectiveId; })
                            .filter(function (o) { return o != null; })
                            .value();
                        result.parameters = _([])
                            .chain()
                            .union(_(characteristics).filter(function (c) { return _(characteristicIds).contains(c.Id); }))
                            .union(_(observableEntities).filter(function (o) { return _(observableEntityIds).contains(o.Id); }))
                            .union(_(ruleThresholds)
                            .chain()
                            .filter(function (r) { return _(ruleThresholdIds).contains(r.Id); })
                            .map(function (r) {
                            return { Id: r.Id, Text: r.Name };
                        })
                            .filter(function (c) { return c != null; })
                            .value())
                            .union(_(objectives)
                            .chain()
                            .filter(function (r) { return _(objectiveIds).contains(r.Id); })
                            .map(function (r) {
                            return { Id: r.Id, Text: r.Name };
                        })
                            .filter(function (c) { return c != null; })
                            .value())
                            .value();
                        return result;
                    };
                    return MonitoringPartSummaryFactory;
                }());
                Factory.MonitoringPartSummaryFactory = MonitoringPartSummaryFactory;
                Shared.remeCareSharedModule.service('monitoringPartSummaryFactory', MonitoringPartSummaryFactory);
                var MonitoringPartFactory = /** @class */ (function () {
                    function MonitoringPartFactory(monitoringPartSourceFactory) {
                        this.monitoringPartSourceFactory = monitoringPartSourceFactory;
                    }
                    MonitoringPartFactory.prototype.createAsync = function (therapyId, serverObjects) {
                        return __awaiter(this, void 0, void 0, function () {
                            var mpmpss;
                            var _this = this;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        if (!serverObjects) {
                                            return [2 /*return*/, Promise.resolve([])];
                                        }
                                        return [4 /*yield*/, this.monitoringPartSourceFactory.createAllAsync(therapyId, serverObjects)];
                                    case 1:
                                        mpmpss = _a.sent();
                                        return [2 /*return*/, _.map(serverObjects, function (mp) {
                                                var monitoringPart = _this.createPart(mp.Type, mp);
                                                var monitoringPartMonitoringPartSources = _.find(mpmpss, function (mpmps) { return mpmps.monitoringPartId === mp.Id; });
                                                monitoringPart.monitoringPartSources = monitoringPartMonitoringPartSources
                                                    ? monitoringPartMonitoringPartSources.monitoringPartSources
                                                    : [];
                                                if (monitoringPart.type === MonitoringPartType.MedicationDoseChart) {
                                                    var actionSource = _.find(monitoringPart.monitoringPartSources, function (mps) { return mps.type === MonitoringPartSourceType.MonitoringPartSourceAction; });
                                                    actionSource.filter = _.find(monitoringPart.monitoringPartSources, function (mps) { return mps.type === MonitoringPartSourceType.MonitoringPartSourceFilter; });
                                                }
                                                return monitoringPart;
                                            })];
                                }
                            });
                        });
                    };
                    MonitoringPartFactory.prototype.createFromType = function (type) {
                        return this.createPart(type);
                    };
                    MonitoringPartFactory.prototype.createPart = function (type, serverObject) {
                        var result;
                        switch (type) {
                            case MonitoringPartType.LineChartNumeric:
                                result = new Framework.Model.MonitoringPartChart(serverObject);
                                break;
                            case MonitoringPartType.TableNumericQualitative:
                                result = new Framework.Model.MonitoringPartTable(serverObject);
                                break;
                            case MonitoringPartType.CumulativeBarChart:
                                result = new Framework.Model.MonitoringPartChart(serverObject);
                                break;
                            case MonitoringPartType.FloatingBarChart:
                                result = new Framework.Model.MonitoringPartChart(serverObject);
                                break;
                            case MonitoringPartType.ColourQualitativeTimeLine:
                                result = new Framework.Model.MonitoringPartTimeLine(serverObject);
                                break;
                            case MonitoringPartType.MedicationAdherenceChart:
                                result = new Framework.Model.MonitoringPartChart(serverObject);
                                break;
                            case MonitoringPartType.MedicationDoseChart:
                                result = new Framework.Model.MonitoringPartChart(serverObject);
                                break;
                            case MonitoringPartType.ActionTable:
                                result = new Framework.Model.MonitoringPartActionTable(serverObject);
                                break;
                            case MonitoringPartType.ActionTimeLine:
                                result = new Framework.Model.MonitoringPartActionTimeLine(serverObject);
                                break;
                            case MonitoringPartType.CumulativeObjectiveChart:
                                result = new Framework.Model.MonitoringPartChart(serverObject);
                                break;
                            case MonitoringPartType.ObjectiveScoreTable:
                                result = new Framework.Model.MonitoringPartTable(serverObject);
                                break;
                            case MonitoringPartType.LineChartNumericExternal:
                                result = new Framework.Model.MonitoringPartChart(serverObject);
                                break;
                            case MonitoringPartType.Boxplot:
                                result = new Framework.Model.MonitoringPartChart(serverObject);
                                break;
                            default:
                                return null;
                        }
                        result.type = type;
                        return result;
                    };
                    return MonitoringPartFactory;
                }());
                Shared.remeCareSharedModule.service('monitoringPartFactory', MonitoringPartFactory);
            })(Factory = Framework.Factory || (Framework.Factory = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
