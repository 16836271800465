var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var UserUnknownController = /** @class */ (function () {
                function UserUnknownController($state, appConfig) {
                    this.$state = $state;
                    this.appConfig = appConfig;
                }
                UserUnknownController.prototype.$onInit = function () {
                    this.remedusInfoMail = this.appConfig.remedusInfoMail;
                    this.remedusPhone = this.appConfig.remedusPhone;
                };
                UserUnknownController.prototype.requestAccess = function () {
                    this.$state.go('selfservice.requestUserAccount');
                };
                return UserUnknownController;
            }());
            Shared.remeCareSharedModule.component('rcUserUnknown', {
                controller: UserUnknownController,
                templateUrl: 'framework/components/userUnknown/userUnknown.html',
            });
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
