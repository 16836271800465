module RemeCare.Shared.Contract.Code {

    export enum ActionType {
        QuantitativeObservation = 1,
        QualitativeObservation = 2,
        Administration = 4,
        CareAct = 5,
        FixDetails = 7,
        ProvideInformationObject = 8,
        RequestInformationObject = 9,
        Remark = 10,
        PreviousRegistration = 11,
        TelephoneCallPlanning = 12,
        Date = 14,
        MissingRegistrations = 18,
        MedicationSchema = 19,
        AberrantRegistrations = 20
    }
}