var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Model;
            (function (Model) {
                var Change;
                (function (Change) {
                    Change[Change["None"] = 0] = "None";
                    Change[Change["Added"] = 1] = "Added";
                    Change[Change["Deleted"] = 2] = "Deleted";
                    Change[Change["Modified"] = 3] = "Modified";
                })(Change = Model.Change || (Model.Change = {}));
            })(Model = Framework.Model || (Framework.Model = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
