var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var MultiSelectSearchModalController = /** @class */ (function (_super) {
                __extends(MultiSelectSearchModalController, _super);
                function MultiSelectSearchModalController($scope, $translate, toaster, gridBuilderSvc) {
                    var _this = _super.call(this, $scope, $translate, toaster) || this;
                    _this.gridBuilderSvc = gridBuilderSvc;
                    $scope.select = function (r) { return _this.select(r); };
                    $scope.confirm = function () { return _this.confirm(); };
                    return _this;
                }
                MultiSelectSearchModalController.prototype.$onInit = function () {
                    this.buildResultsGrid();
                };
                MultiSelectSearchModalController.prototype.select = function (r) {
                    var _this = this;
                    if (_(this.$scope.resultsGrid.getData())
                        .any(function (t) { return t[_this.$scope.searchInfo.idField] === r[_this.$scope.searchInfo.idField]; })) {
                        this.toaster.info(this.$translate.instant('General.SelectionContains'));
                        return;
                    }
                    this.$scope.resultsGrid.addRow(r);
                };
                MultiSelectSearchModalController.prototype.confirm = function () {
                    this.$scope.$close(this.$scope.resultsGrid.getData());
                };
                MultiSelectSearchModalController.prototype.buildResultsGrid = function () {
                    var gridBuilder = this.gridBuilderSvc
                        .createGridBuilder();
                    _(this.$scope.searchInfo.columns).each(function (c) {
                        gridBuilder = gridBuilder.addColumn(c.field, c.displayName, c.options);
                    });
                    this.$scope.resultsGrid = gridBuilder
                        .addDeleteButtonColumn()
                        .build();
                    if (this.$scope.searchInfo.currentResults) {
                        this.$scope.resultsGrid.setData(this.$scope.searchInfo.currentResults);
                    }
                };
                return MultiSelectSearchModalController;
            }(Framework.ControllerBase));
            angular.module('RemeCare.Shared').controller('multiSelectSearchModalCtrl', MultiSelectSearchModalController);
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
