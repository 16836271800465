module RemeCare.Shared.Contract.Code {
    export enum ContactPointType {
        None = 0,
        Address = 1,
        Telephone = 2,
        MobilePhone = 3,
        Fax = 4,
        Email = 5,
        Website = 6
    }
}