var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Model;
            (function (Model) {
                var PatientInformedConsent = /** @class */ (function () {
                    function PatientInformedConsent(serverObject) {
                        var _this = this;
                        if (serverObject != null) {
                            this.informedConsentId = serverObject.InformedConsentId;
                            this.title = serverObject.Title;
                            this.versionMajor = serverObject.VersionMajor;
                            this.versionMinor = serverObject.VersionMinor;
                            this.status = serverObject.Status;
                            this.content = serverObject.Content;
                            this.questions = _.map(serverObject.Questions, function (q) { return new Model.PatientInformedConsentQuestion(q, _this.status); });
                        }
                    }
                    return PatientInformedConsent;
                }());
                Model.PatientInformedConsent = PatientInformedConsent;
            })(Model = Framework.Model || (Framework.Model = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
