var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var ClaimTypes = /** @class */ (function () {
                function ClaimTypes() {
                }
                ClaimTypes.applicationProfile = 'applicationprofile';
                ClaimTypes.firstname = 'firstname';
                ClaimTypes.lastname = 'lastname';
                ClaimTypes.partyId = 'partyid';
                ClaimTypes.partyRoleId = 'partyroleid';
                return ClaimTypes;
            }());
            Framework.ClaimTypes = ClaimTypes;
            var AuthContext = /** @class */ (function () {
                function AuthContext() {
                }
                AuthContext.action = 'Action';
                AuthContext.agenda = 'Agenda';
                AuthContext.agendaColleague = 'Agenda.Colleague';
                AuthContext.agendaOwn = 'Agenda.Own';
                AuthContext.agendaTeam = 'Agenda.Team';
                AuthContext.alarm = 'Alarm';
                AuthContext.careRequest = 'CareRequest';
                AuthContext.configuration = 'Configuration';
                AuthContext.configurationActionLibrary = 'Configuration.ActionLibrary';
                AuthContext.configurationMasterData = 'Configuration.MasterData';
                AuthContext.configurationTherapy = 'Configuration.Therapy';
                AuthContext.conversations = 'Conversations';
                AuthContext.inbox = 'Inbox';
                AuthContext.inboxAlarms = 'Inbox.Alarm';
                AuthContext.inboxConversations = 'Inbox.Conversations';
                AuthContext.integrationMonitor = 'IntegrationMonitor';
                AuthContext.management = 'Management';
                AuthContext.managementActorRoles = 'Management.ActorRoles';
                AuthContext.managementMaterials = 'Management.Materials';
                AuthContext.managementOrganisations = 'Management.Organisations';
                AuthContext.managementOrganisationsExternalIdentity = 'Management.Organisations.ExternalIdentity';
                AuthContext.managementPersons = 'Management.Persons';
                AuthContext.managementPersonsUser = 'Management.Persons.User';
                AuthContext.managementProducts = 'Management.Products';
                AuthContext.managementTeams = 'Management.Teams';
                AuthContext.managementPatient = 'Management.Patient';
                AuthContext.managementGroups = 'Management.Groups';
                AuthContext.managementInformedConsents = 'Management.InformedConsents';
                AuthContext.patient = 'Patient';
                AuthContext.patientCarePlan = 'Patient.CarePlan';
                AuthContext.patientCarePlanCareRequest = 'Patient.CarePlan.CareRequest';
                AuthContext.patientCarePlanCorrectParameterValue = 'Patient.CarePlan.CorrectParameterValue';
                AuthContext.patientCarePlanMedication = 'Patient.CarePlan.Medication';
                AuthContext.patientCarePlanMedicationPeriodicity = 'Patient.CarePlan.Medication.Periodicity';
                AuthContext.patientCareTeam = 'Patient.CareTeam';
                AuthContext.patientCareTeamDetail = 'Patient.CareTeam.Detail';
                AuthContext.patientDocuments = 'Patient.Documents';
                AuthContext.patientInternal = 'Patient.Internal';
                AuthContext.patientMedia = 'Patient.Media';
                AuthContext.patientMedical = 'Patient.Medical';
                AuthContext.patientMedicalHospitalisation = 'Patient.Medical.Hospitalisation';
                AuthContext.patientMedication = 'Patient.Medication';
                AuthContext.patientMonitor = 'Patient.Monitor';
                AuthContext.patientMonitorDashboard = 'Patient.Monitor.Dashboard';
                AuthContext.patientMonitorRegistrations = 'Patient.Monitor.Registrations';
                AuthContext.patientMonitorAlarms = 'Patient.Monitor.Alarms';
                AuthContext.patientSinglePatient = 'Patient.SinglePatient';
                AuthContext.searchCareProvider = 'Search.CareProvider';
                AuthContext.searchContact = 'Search.Contact';
                AuthContext.searchOrganisation = 'Search.Organisation';
                AuthContext.searchPatient = 'Search.Patient';
                AuthContext.patientInformedConsents = 'Patient.InformedConsents';
                return AuthContext;
            }());
            Framework.AuthContext = AuthContext;
            var AuthRight;
            (function (AuthRight) {
                AuthRight[AuthRight["Nothing"] = 0] = "Nothing";
                AuthRight[AuthRight["Read"] = 1] = "Read";
                AuthRight[AuthRight["Write"] = 2] = "Write";
                AuthRight[AuthRight["Create"] = 4] = "Create";
                AuthRight[AuthRight["Delete"] = 8] = "Delete";
            })(AuthRight = Framework.AuthRight || (Framework.AuthRight = {}));
            var AuthService = /** @class */ (function () {
                function AuthService($rootScope, baseUrl) {
                    this.$rootScope = $rootScope;
                    this.baseUrl = baseUrl;
                    this.claims = {};
                    this.rights = {};
                }
                AuthService.prototype.setRights = function (rights) {
                    if (!rights) {
                        this.rights = {};
                    }
                    for (var prop in this.rights) {
                        if (this.rights.hasOwnProperty(prop)) {
                            delete this.rights[prop];
                        }
                    }
                    for (var prop in rights) {
                        if (rights.hasOwnProperty(prop)) {
                            this.rights[prop] = rights[prop];
                        }
                    }
                    this.$rootScope.$broadcast(Shared.AppEvents.rightsChanged);
                };
                AuthService.prototype.hasRight = function (context, right) {
                    if (this.rights.hasOwnProperty(context)) {
                        // tslint changes to === which does not work
                        /* tslint:disable */
                        return (this.rights[context] & right) == right;
                        /* tslint:enable */
                    }
                    return false;
                };
                AuthService.prototype.setClaims = function (claims) {
                    for (var claim in claims) {
                        if (claims.hasOwnProperty(claim)) {
                            this.setClaim(claim, claims[claim], true);
                        }
                    }
                    this.$rootScope.$broadcast(Shared.AppEvents.claimsChanged);
                };
                AuthService.prototype.setClaim = function (key, value, preventEvent) {
                    this.claims[key] = value;
                    if (!preventEvent) {
                        this.$rootScope.$broadcast(Shared.AppEvents.claimsChanged);
                    }
                };
                AuthService.prototype.getClaim = function (key) {
                    return this.claims[key];
                };
                AuthService.prototype.getNameOfUser = function () {
                    return this.isAuthenticated()
                        ? this.getClaim(ClaimTypes.firstname) + " " + this.getClaim(ClaimTypes.lastname)
                        : '';
                };
                AuthService.prototype.signOut = function () {
                    window.location.href = this.baseUrl + "Security/Logout";
                };
                AuthService.prototype.getProfile = function () {
                    var profile = this.getClaim(ClaimTypes.applicationProfile);
                    if (profile == null) {
                        return 0;
                    }
                    return parseInt(profile);
                };
                AuthService.prototype.isPatient = function () {
                    return this.getProfile() === Shared.Contract.Code.ApplicationProfileType.Patient;
                };
                AuthService.prototype.isUnknownCareProvider = function () {
                    return this.getProfile() === Shared.Contract.Code.ApplicationProfileType.UnknownCareProvider;
                };
                AuthService.prototype.isAuthenticated = function () {
                    return !!this.getClaim(ClaimTypes.partyId);
                };
                AuthService.prototype.isInternalUser = function () {
                    var profile = this.getProfile();
                    return (profile === Shared.Contract.Code.ApplicationProfileType.CareManager ||
                        profile === Shared.Contract.Code.ApplicationProfileType.AdministrativeManager);
                };
                return AuthService;
            }());
            Framework.AuthService = AuthService;
            Shared.remeCareSharedModule.service('authservice', AuthService);
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
