var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Contract;
        (function (Contract) {
            var Write;
            (function (Write) {
                var ITherapyActionPartContextSettingMandatoryOverride = /** @class */ (function () {
                    function ITherapyActionPartContextSettingMandatoryOverride() {
                    }
                    return ITherapyActionPartContextSettingMandatoryOverride;
                }());
                Write.ITherapyActionPartContextSettingMandatoryOverride = ITherapyActionPartContextSettingMandatoryOverride;
            })(Write = Contract.Write || (Contract.Write = {}));
        })(Contract = Shared.Contract || (Shared.Contract = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
