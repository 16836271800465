module RemeCare.Shared.Framework.Component {
    remeCareSharedModule.component('rcNavItem',
        {
            bindings: {
                label: '@',
                state: '@',
            },
            require: {
                headerCtrl: '^rcHeader'
            },
            templateUrl: 'views/navigationItem.html'
        });
}