namespace RemeCare.Shared.Framework.Model {
    export class VersionValidityChange {
        public pointInTime: Date;
        public newValidFromDate: Date;

        constructor(version: Shared.Contract.Read.IVersion) {
            this.pointInTime = DateHelper.serverDateStringToDate(version.ValidFromDate);
            this.newValidFromDate = DateHelper.serverDateStringToDate(version.ValidFromDate);
        }

        public toServerWrite(): Shared.Contract.Write.IVersionValidityChange {
            return {
                PointInTime: Shared.DateHelper.toServerDateString(this.pointInTime),
                NewValidFromDate: Shared.DateHelper.toServerDateString(this.newValidFromDate),
            } as Shared.Contract.Write.IVersionValidityChange;
        }
    }

    export class VersionFromUntilChange extends VersionValidityChange {
        public newValidUntilDate: Date;

        constructor(version: Shared.Contract.Read.IVersion) {
            super(version);
            this.newValidUntilDate = DateHelper.serverDateStringToDate(version.ValidUntilDate);
        }

        public toServerWrite(): Shared.Contract.Write.IVersionFromUntilChange {
            return {
                PointInTime: Shared.DateHelper.toServerDateString(this.pointInTime),
                NewValidFromDate: Shared.DateHelper.toServerDateString(this.newValidFromDate),
                NewValidUntilDate: Shared.DateHelper.toServerDateString(this.newValidUntilDate),
            } as Shared.Contract.Write.IVersionFromUntilChange;
        }
    }
}
