var RemeCare;
(function (RemeCare) {
    var Bootstrap;
    (function (Bootstrap) {
        Bootstrap.remeBootModule = angular.module('RemeBoot', []);
        var Bootstrapper = /** @class */ (function () {
            function Bootstrapper(appName) {
                this.appName = appName;
            }
            Bootstrapper.prototype.bootstrap = function () {
                return this.getConfig();
            };
            Bootstrapper.prototype.getConfig = function () {
                var injector = angular.injector(['ng']);
                var $http = injector.get('$http');
                return new RemeCare.Shared.Framework.Helper.ConfigLoader($http)
                    .loadConfig()
                    .then(function (r) {
                    angular.module(RemeCare.Shared.remeCareSharedModule.name)
                        .constant('googleAnalyticsTracker', r.data.googleAnalyticsTracker)
                        .constant('baseUrl', r.data.baseUrl)
                        .constant('appConfig', r.data);
                });
            };
            Bootstrapper.$inject = ['appName'];
            return Bootstrapper;
        }());
        angular.module('RemeBoot').service('bootstrapper', Bootstrapper);
        angular.module('RemeBoot')
            .factory('httpRequestInterceptor', function ($location) {
            return {
                request: function (request) {
                    request.headers['X-AppStateUrl'] = $location.absUrl();
                    return request;
                }
            };
        });
        angular.module('RemeBoot')
            .config(function ($httpProvider) {
            $httpProvider.interceptors.push('httpRequestInterceptor');
        });
        // create a div which is used as the root of the bootstrap app
        var appContainer = document.createElement('div');
        // in run() function you can now use the bootstrapper service and shutdown the bootstrapping app after initialization of your actual app
        angular.module('RemeBoot').run(function (bootstrapper, appName) {
            bootstrapper.bootstrap().then(function () {
                angular.bootstrap(document.body, [appName], {
                    strictDi: true
                });
            });
        });
        // make sure the DOM is fully loaded before bootstrapping.
        angular.element(document).ready(function () {
            angular.bootstrap(appContainer, ['RemeBoot']);
        });
    })(Bootstrap = RemeCare.Bootstrap || (RemeCare.Bootstrap = {}));
})(RemeCare || (RemeCare = {}));
