namespace RemeCare.Shared.Framework.Factory {
    export interface IDataSourceTranslationFactory {
        createAll(serverObjects: Shared.Contract.IDataSourceTranslation[]): Promise<Model.DataSourceTranslation[]>;
        createFromMonitoringPartSource(
            monitoringPartSource: Model.MonitoringPartSource,
            dataSourceType: Shared.Contract.IEnumTranslation
        ): Model.DataSourceTranslation;
    }

    class DataSourceTranslationFactory implements IDataSourceTranslationFactory {
        constructor(private readonly masterdataSvc: Shared.Framework.MasterdataService) {}

        public async createAll(
            serverObjects: Shared.Contract.IDataSourceTranslation[]
        ): Promise<Model.DataSourceTranslation[]> {
            const r = await this.masterdataSvc.getMonitoringPartDataSourceTypesAsync();
            const result = _(serverObjects).map(dst => {
                const dataSourceType = {
                    Id: dst.DataSourceType,
                    Text: _(r).find(d => d.Id === dst.DataSourceType).Text,
                };
                const translation = new Model.DataSourceTranslation(dst, dataSourceType);
                return translation;
            });

            return result;
        }

        public createFromMonitoringPartSource(
            monitoringPartSource: Model.MonitoringPartSource,
            dataSourceType: Shared.Contract.IEnumTranslation
        ): Model.DataSourceTranslation {
            switch (monitoringPartSource.type) {
                case Shared.Contract.Code.MonitoringPartSourceType.QuantitativeMeasuringPointParameter:
                    return new Model.DataSourceTranslation(
                        {
                            DependantObject: (monitoringPartSource as Model.QuantitativeMeasuringPointParameter).unit,
                            IsNumeric: true,
                            Id: (monitoringPartSource as Model.QuantitativeMeasuringPointParameter).characteristic.Id,
                            Text: (monitoringPartSource as Model.QuantitativeMeasuringPointParameter).characteristic
                                .Text,
                        } as Shared.Contract.IDataSourceTranslation,
                        dataSourceType
                    );
                default:
                    return null;
            }
        }
    }

    remeCareSharedModule.factory(
        'dataSourceTranslationFactory',
        masterdataSvc => new DataSourceTranslationFactory(masterdataSvc)
    );
}
