module RemeCare.Shared.Framework.Directive {

    class SaveButton {
        public label: string;
        public save: () => any;
        public type: string;

        public $onInit() {
            this.label = this.label || 'General.Save';
            this.type = this.type || 'button';
        }

        public confirm(): void {
            this.save && this.save();
        }
    }

    remeCareSharedModule.component('rcSave',
    {
        controller: SaveButton,
        controllerAs: 'btnCtrl',
        templateUrl: 'views/controls/primaryButton.html',
        bindings: {
            save: '&?',
            label: '@?',
            ngDisabled: '<?',
            loader: '@?',
            type: '@?'
        }
    });
}