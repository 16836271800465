module RemeCare.Shared.Framework.Directive {
    class DropdownCreateButton {
        label: string;
        create: (params: {option}) => void;

        $onInit() {
            this.label = this.label || 'General.Add';
        }

        selected(option): void {
            this.create({ option: option });
        }
    }

    remeCareSharedModule.component('rcDropdownCreate',
    {
        controller: DropdownCreateButton,
        controllerAs: 'dropdownCtrl',
        templateUrl: 'views/controls/dropdownCreateButton.html',
        bindings: {
            buttonClasses: '@',
            create: '&',
            options: '<',
            label: '@?',
            displayField: '@',
            rightAlligned: '<?',
            ngDisabled: '<?'
        }
    });
}