var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Directive;
        (function (Directive) {
            var ForceDecimalsDirective = /** @class */ (function () {
                function ForceDecimalsDirective() {
                    this.restrict = 'A';
                    this.require = '?ngModel';
                    this.scope = {
                        rcForceDecimals: '@'
                    };
                    this.link = function (scope, element, attrs, ngModelCtrl) {
                        if (!scope.rcForceDecimals || !ngModelCtrl)
                            return;
                        var nbOfDecimals = parseInt(scope.rcForceDecimals);
                        ngModelCtrl.$parsers.push(function (val) {
                            if (val == null)
                                return null;
                            var fixed = val.toFixed(nbOfDecimals);
                            var temp = parseFloat(fixed);
                            if (val != temp) {
                                ngModelCtrl.$setViewValue(temp);
                                ngModelCtrl.$render();
                            }
                            return temp;
                        });
                        if (nbOfDecimals == 0) {
                            element.bind('keypress', function (event) {
                                if (event.charCode == 44 || event.keyCode == 46) {
                                    event.preventDefault();
                                }
                            });
                        }
                    };
                }
                return ForceDecimalsDirective;
            }());
            Shared.remeCareSharedModule.directive('rcForceDecimals', function () { return new ForceDecimalsDirective(); });
        })(Directive = Shared.Directive || (Shared.Directive = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
