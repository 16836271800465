namespace RemeCare.Shared.Contract.Read {
    import Duration = Contract.IDuration;
    import ItemTranslation = Contract.IEntityTranslation;
    import EnumTranslation = Contract.IEnumTranslation;

    export enum TherapyActionRelationType {
        PreviousRegistration = 3,
    }

    export interface IRelatedStructuralTherapyAction {
        Delay: Duration;
        TherapyActionRelationType: EnumTranslation;
        ChildStructuralTherapyAction: ItemTranslation;
    }
}
