var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Model;
            (function (Model) {
                var SourceParameterRepresentationType = Shared.Contract.Code.SourceParameterRepresentationType;
                var SourceParameterRepresentation = /** @class */ (function () {
                    function SourceParameterRepresentation(serverObject) {
                        if (serverObject != null) {
                            this.id = serverObject.Id;
                        }
                    }
                    SourceParameterRepresentation.prototype.toServerWrite = function () {
                        return {
                            id: this.id,
                        };
                    };
                    return SourceParameterRepresentation;
                }());
                Model.SourceParameterRepresentation = SourceParameterRepresentation;
                var ChartLine = /** @class */ (function (_super) {
                    __extends(ChartLine, _super);
                    function ChartLine(serverObject, lineTypes) {
                        var _this = _super.call(this, serverObject) || this;
                        _this.id = Shared.Contract.Code.SourceParameterRepresentationType.ChartLine;
                        if (serverObject != null) {
                            _this.colour = serverObject.Colour;
                            _this.lineType = _(lineTypes).find(function (lt) { return lt.Id === serverObject.LineType; });
                        }
                        return _this;
                    }
                    ChartLine.prototype.toServerWrite = function () {
                        var result = _super.prototype.toServerWrite.call(this);
                        result.colour = this.colour;
                        result.lineType = this.lineType.Id;
                        result.type = SourceParameterRepresentationType.ChartLine;
                        return result;
                    };
                    return ChartLine;
                }(SourceParameterRepresentation));
                Model.ChartLine = ChartLine;
                var ChartBar = /** @class */ (function (_super) {
                    __extends(ChartBar, _super);
                    function ChartBar(serverObject) {
                        var _this = _super.call(this, serverObject) || this;
                        _this.id = Shared.Contract.Code.SourceParameterRepresentationType.ChartBar;
                        if (serverObject != null) {
                            _this.colour = serverObject.Colour;
                        }
                        return _this;
                    }
                    ChartBar.prototype.toServerWrite = function () {
                        var result = _super.prototype.toServerWrite.call(this);
                        result.colour = this.colour;
                        result.type = SourceParameterRepresentationType.ChartBar;
                        return result;
                    };
                    return ChartBar;
                }(SourceParameterRepresentation));
                Model.ChartBar = ChartBar;
                var TableColumn = /** @class */ (function (_super) {
                    __extends(TableColumn, _super);
                    function TableColumn(serverObject) {
                        var _this = _super.call(this, serverObject) || this;
                        _this.id = Shared.Contract.Code.SourceParameterRepresentationType.TableColumn;
                        return _this;
                    }
                    TableColumn.prototype.toServerWrite = function () {
                        var result = _super.prototype.toServerWrite.call(this);
                        result.type = SourceParameterRepresentationType.TableColumn;
                        return result;
                    };
                    return TableColumn;
                }(SourceParameterRepresentation));
                Model.TableColumn = TableColumn;
                var ColourQualitative = /** @class */ (function (_super) {
                    __extends(ColourQualitative, _super);
                    function ColourQualitative(serverObject) {
                        var _this = _super.call(this, serverObject) || this;
                        _this.id = Shared.Contract.Code.SourceParameterRepresentationType.ColourQualitative;
                        if (serverObject != null) {
                            _this.codeSetColours = _(serverObject.CodeSetColours).map(function (csc) { return new Model.CodeSetColour(csc); });
                        }
                        return _this;
                    }
                    ColourQualitative.prototype.toServerWrite = function () {
                        var result = _super.prototype.toServerWrite.call(this);
                        result.codeSetColours = _(this.codeSetColours).map(function (csc) { return csc.toServerWrite(); });
                        result.type = SourceParameterRepresentationType.ColourQualitative;
                        return result;
                    };
                    return ColourQualitative;
                }(SourceParameterRepresentation));
                Model.ColourQualitative = ColourQualitative;
                var Boxplot = /** @class */ (function (_super) {
                    __extends(Boxplot, _super);
                    function Boxplot(serverObject) {
                        var _this = _super.call(this, serverObject) || this;
                        _this.id = Shared.Contract.Code.SourceParameterRepresentationType.Boxplot;
                        if (serverObject != null) {
                            _this.colour = serverObject.Colour;
                            _this.boxplotDataType = serverObject.BoxplotDataType;
                        }
                        return _this;
                    }
                    Boxplot.prototype.toServerWrite = function () {
                        var result = _super.prototype.toServerWrite.call(this);
                        result.colour = this.colour;
                        result.boxplotDataType = this.boxplotDataType;
                        result.type = SourceParameterRepresentationType.Boxplot;
                        return result;
                    };
                    return Boxplot;
                }(SourceParameterRepresentation));
                Model.Boxplot = Boxplot;
            })(Model = Framework.Model || (Framework.Model = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
