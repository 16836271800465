/// <reference path="../../contract/code/monitoringPartSourceType.ts"/>
/// <reference path="../../contract/code/monitoringPartDataSourceType.ts"/>

namespace RemeCare.Shared.Framework.Factory {
    import Guid = Shared.Contract.Guid;
    import MonitoringPartSourceTypeCode = Shared.Contract.Code.MonitoringPartSourceType;
    import DataSourceType = Shared.Contract.Code.MonitoringPartDataSourceType;
    import EntityTranslation = Shared.Contract.IEntityTranslation;

    export interface IMonitoringPartMonitoringPartSources {
        monitoringPartId: Guid;
        monitoringPartSources: Model.MonitoringPartSource[];
    }

    export interface IMonitoringPartSourceFactory {
        createAllAsync(
            therapyId: Guid,
            serverObjects: Shared.Contract.Read.IMonitoringPart[]
        ): Promise<IMonitoringPartMonitoringPartSources[]>;
        createFromType(type: MonitoringPartSourceTypeCode): Model.MonitoringPartSource;
        createFromDataSource(dataSource: Model.DataSourceTranslation): Model.MonitoringPartSourceParameter;
    }

    class MonitoringPartSourceFactory implements IMonitoringPartSourceFactory {
        constructor(
            private readonly masterdataSvc: Framework.MasterdataService,
            private readonly sourceParameterRepresentationFactory: ISourceParameterRepresentationFactory
        ) {}

        public async createAllAsync(
            therapyId: Guid,
            serverObjects: Shared.Contract.Read.IMonitoringPart[]
        ): Promise<IMonitoringPartMonitoringPartSources[]> {
            const monitoringPartSources = _.chain(serverObjects)
                .map((so) => so.MonitoringPartSources)
                .flatten()
                .value();
            const observableEntityIds = _.chain(monitoringPartSources)
                .map(
                    (so) =>
                        (so as
                            | Shared.Contract.Read.IQualitativeMeasuringPointParameter
                            | Shared.Contract.Read.IQualitativeReferenceParameterAnamnesis).ObservableEntityId
                )
                .filter((id) => id != null)
                .uniq()
                .value();
            const codeSetIds = _.chain(monitoringPartSources)
                .map(
                    (so) =>
                        (so as
                            | Shared.Contract.Read.IQualitativeMeasuringPointParameter
                            | Shared.Contract.Read.IQualitativeReferenceParameterAnamnesis).CodeSetId
                )
                .filter((id) => id != null)
                .uniq()
                .value();
            const characteristicIds = _.chain(monitoringPartSources)
                .map(
                    (so) =>
                        (so as
                            | Shared.Contract.Read.IQuantitativeMeasuringPointParameter
                            | Shared.Contract.Read.IQuantitativeReferenceParameterAnamnesis).CharacteristicId
                )
                .filter((id) => id != null)
                .uniq()
                .value();
            const unitIds = _.chain(monitoringPartSources)
                .map(
                    (so) =>
                        (so as
                            | Shared.Contract.Read.IQuantitativeMeasuringPointParameter
                            | Shared.Contract.Read.IQuantitativeReferenceParameterAnamnesis).UnitId
                )
                .filter((id) => id != null)
                .uniq()
                .value();
            const ruleThresholdIds = _.chain(monitoringPartSources)
                .map((so) => (so as Shared.Contract.Read.IReferenceParameterThreshold).RuleThresholdId)
                .filter((id) => id != null)
                .uniq()
                .value();
            const objectiveIds = _.chain(monitoringPartSources)
                .map((so) => (so as Shared.Contract.Read.IReferenceParameterObjective).ObjectiveId)
                .filter((id) => id != null)
                .uniq()
                .value();

            const observableEntitiesPromise = observableEntityIds.length
                ? this.masterdataSvc.getObservableEntitiesAsync(observableEntityIds)
                : this.getEmptyPromise<EntityTranslation>();
            const codeSetPromise = codeSetIds.length
                ? this.masterdataSvc.getCodeSetTypesAsync()
                : this.getEmptyPromise<EntityTranslation>();
            const characteristicPromise = characteristicIds.length
                ? this.masterdataSvc.getCharacteristicsAsync(characteristicIds, true)
                : this.getEmptyPromise<EntityTranslation>();
            const unitPromise = unitIds.length
                ? this.masterdataSvc.getUnitsAsync(true)
                : this.getEmptyPromise<EntityTranslation>();
            const ruleThresholdPromise = ruleThresholdIds.length
                ? this.masterdataSvc.getRuleThresholdsAsync(therapyId, ruleThresholdIds)
                : this.getEmptyPromise<Contract.Read.IRuleThresholdDefinition>();
            const objectivePromise = objectiveIds.length
                ? this.masterdataSvc.getObjectivesAsync(therapyId, objectiveIds)
                : this.getEmptyPromise<Contract.Read.IObjectiveDefinition>();

            const [observableEntities, codeSetTypess, characteristics, units, ruleThresholdsResult, objectivesResult]: [
                EntityTranslation[],
                EntityTranslation[],
                EntityTranslation[],
                EntityTranslation[],
                Contract.Read.IRuleThresholdDefinition[],
                Contract.Read.IObjectiveDefinition[]
            ] = await Promise.all([
                observableEntitiesPromise,
                codeSetPromise,
                characteristicPromise,
                unitPromise,
                ruleThresholdPromise,
                objectivePromise,
            ]);

            const ruleThresholds = ruleThresholdsResult.map((rtd) => {
                return { Id: rtd.Id, Text: rtd.Name };
            }) as EntityTranslation[];
            const objectives = objectivesResult.map((od) => ({
                Id: od.Id,
                Text: od.Name,
            }));

            return Promise.all(
                _.map(serverObjects, async (mpmpss) => {
                    const mps = await Promise.all(
                        _.map(mpmpss.MonitoringPartSources, (mps) =>
                            this.createAsync(
                                mps,
                                observableEntities,
                                codeSetTypess,
                                characteristics,
                                units,
                                ruleThresholds,
                                objectives
                            )
                        )
                    );
                    return {
                        monitoringPartId: mpmpss.Id,
                        monitoringPartSources: mps,
                    } as IMonitoringPartMonitoringPartSources;
                })
            );
        }

        public createFromDataSource(dataSource: Model.DataSourceTranslation): Model.MonitoringPartSourceParameter {
            let result: Model.MonitoringPartSourceParameter;
            switch (dataSource.dataSourceType.Id) {
                case DataSourceType.RegistrationValue:
                    if (dataSource.isNumeric) {
                        result = this.createFromType(
                            MonitoringPartSourceTypeCode.QuantitativeMeasuringPointParameter
                        ) as Model.QuantitativeMeasuringPointParameter;
                        (result as Model.QuantitativeMeasuringPointParameter).characteristic = dataSource.toEntityTranslation();
                        (result as Model.QuantitativeMeasuringPointParameter).unit = dataSource.dependantObject;
                    } else {
                        result = this.createFromType(
                            MonitoringPartSourceTypeCode.QualitativeMeasuringPointParameter
                        ) as Model.QualitativeMeasuringPointParameter;
                        (result as Model.QualitativeMeasuringPointParameter).observableEntity = dataSource.toEntityTranslation();
                        (result as Model.QualitativeMeasuringPointParameter).codeSet = dataSource.dependantObject;
                    }
                    break;
                case DataSourceType.CarePlanAnamnesisValue:
                    if (dataSource.isNumeric) {
                        result = this.createFromType(
                            MonitoringPartSourceTypeCode.QuantitativeReferenceParameterAnamnesis
                        ) as Model.QuantitativeMeasuringPointParameter;
                        (result as Model.QuantitativeMeasuringPointParameter).characteristic = dataSource.toEntityTranslation();
                        (result as Model.QuantitativeMeasuringPointParameter).unit = dataSource.dependantObject;
                    } else {
                        result = this.createFromType(
                            MonitoringPartSourceTypeCode.QualitativeReferenceParameterAnamnesis
                        ) as Model.QualitativeMeasuringPointParameter;
                        (result as Model.QualitativeMeasuringPointParameter).observableEntity = dataSource.toEntityTranslation();
                        (result as Model.QualitativeMeasuringPointParameter).codeSet = dataSource.dependantObject;
                    }
                    break;
                case DataSourceType.ObjectValue:
                    result = this.createFromType(
                        MonitoringPartSourceTypeCode.ReferenceParameterObjective
                    ) as Model.ReferenceParameterObjective;
                    (result as Model.ReferenceParameterObjective).objective = dataSource.toEntityTranslation();
                    break;
                case DataSourceType.ThresholdValue:
                    result = this.createFromType(
                        MonitoringPartSourceTypeCode.ReferenceParameterThreshold
                    ) as Model.ReferenceParameterThreshold;
                    (result as Model.ReferenceParameterThreshold).ruleThreshold = dataSource.toEntityTranslation();
                    break;
                case DataSourceType.ExternalSourceData:
                    result = this.createFromType(
                        MonitoringPartSourceTypeCode.ExternalDataSourceParameter
                    ) as Model.ExternalDataSourceParameter;
                    break;
            }
            result.sourceType = dataSource.dataSourceType.Id;
            return result;
        }

        public createFromType(type: MonitoringPartSourceTypeCode): Model.MonitoringPartSource {
            return this.createPartSource(type);
        }

        private async createAsync(
            serverObject: Shared.Contract.Read.IMonitoringPartSource,
            observableEntities: EntityTranslation[],
            codeSets: EntityTranslation[],
            characteristics: EntityTranslation[],
            units: EntityTranslation[],
            ruleThresholds: EntityTranslation[],
            objectives: EntityTranslation[]
        ): Promise<Model.MonitoringPartSource> {
            if (serverObject == null) {
                return null;
            }

            const result = this.createPartSource(
                serverObject.Type,
                serverObject,
                observableEntities,
                codeSets,
                characteristics,
                units,
                ruleThresholds,
                objectives
            );

            if (
                serverObject.Type !== MonitoringPartSourceTypeCode.MonitoringPartSourceAction &&
                serverObject.Type !== MonitoringPartSourceTypeCode.MonitoringPartSourceFilter
            ) {
                const r = await this.sourceParameterRepresentationFactory.createAsync(
                    (serverObject as Shared.Contract.Read.IMonitoringPartSourceParameter).SourceParameterRepresentation
                );
                (result as Model.MonitoringPartSourceParameter).sourceParameterRepresentation = r;
                return result;
            } else {
                return result;
            }
        }

        private getEmptyPromise<TPromise>(): Promise<TPromise[]> {
            return new Promise<TPromise[]>((resolve) => resolve([]));
        }

        private createPartSource(
            type: MonitoringPartSourceTypeCode,
            serverObject?: Shared.Contract.Read.IMonitoringPartSource,
            observableEntities?: EntityTranslation[],
            codeSets?: EntityTranslation[],
            characteristics?: EntityTranslation[],
            units?: EntityTranslation[],
            ruleThresholds?: EntityTranslation[],
            objectives?: EntityTranslation[]
        ): Model.MonitoringPartSource {
            let result: Model.MonitoringPartSource;
            switch (type) {
                case MonitoringPartSourceTypeCode.MonitoringPartSourceAction:
                    result = new Model.MonitoringPartSourceAction(
                        serverObject as Shared.Contract.Read.IMonitoringPartSourceAction
                    );
                    break;
                case MonitoringPartSourceTypeCode.QualitativeMeasuringPointParameter:
                    result = new Model.QualitativeMeasuringPointParameter(
                        serverObject as Shared.Contract.Read.IQualitativeMeasuringPointParameter,
                        observableEntities,
                        codeSets
                    );
                    break;
                case MonitoringPartSourceTypeCode.QuantitativeMeasuringPointParameter:
                    result = new Model.QuantitativeMeasuringPointParameter(
                        serverObject as Shared.Contract.Read.IQuantitativeMeasuringPointParameter,
                        characteristics,
                        units
                    );
                    break;
                case MonitoringPartSourceTypeCode.QualitativeReferenceParameterAnamnesis:
                    result = new Model.QualitativeReferenceParameterAnamnesis(
                        serverObject as Shared.Contract.Read.IQualitativeReferenceParameterAnamnesis,
                        observableEntities,
                        codeSets
                    );
                    break;
                case MonitoringPartSourceTypeCode.QuantitativeReferenceParameterAnamnesis:
                    result = new Model.QuantitativeReferenceParameterAnamnesis(
                        serverObject as Shared.Contract.Read.IQuantitativeReferenceParameterAnamnesis,
                        characteristics,
                        units
                    );
                    break;
                case MonitoringPartSourceTypeCode.ReferenceParameterThreshold:
                    result = new Model.ReferenceParameterThreshold(
                        serverObject as Shared.Contract.Read.IReferenceParameterThreshold,
                        ruleThresholds
                    );
                    break;
                case MonitoringPartSourceTypeCode.ReferenceParameterObjective:
                    result = new Model.ReferenceParameterObjective(
                        serverObject as Shared.Contract.Read.IReferenceParameterObjective,
                        objectives
                    );
                    break;
                case MonitoringPartSourceTypeCode.ExternalDataSourceParameter:
                    result = new Model.ExternalDataSourceParameter(
                        serverObject as Shared.Contract.Read.IExternalDataSourceParameter
                    );
                    break;
                case MonitoringPartSourceTypeCode.MonitoringPartSourceFilter:
                    result = new Model.MonitoringPartSourceFilter(
                        serverObject as Shared.Contract.Read.IMonitoringPartSourceFilter
                    );
                    break;
                default:
                    return null;
            }
            return result;
        }
    }

    remeCareSharedModule.service('monitoringPartSourceFactory', MonitoringPartSourceFactory);
}
