module RemeCare.Shared.Framework.Directive {
    class DropdownButtonController<TOption> implements ng.IComponentController {

        // bindings
        public displayField: string;
        public label: string;
        public onSelected: (args: { option: TOption }) => void;
        public options: TOption[];

        public $onInit(): void {
        }

        public optionSelected(option: TOption): void {
            this.onSelected({
                option: option
            });
        }
    }

    remeCareSharedModule.component('rcDropdownButton', {
        controller: DropdownButtonController,
        templateUrl: 'views/controls/dropdownButton.html',
        bindings: {
            buttonClasses: '@',
            displayField: '@',
            label: '@',
            onSelected: '&',
            options: '<',
        }
    });
}