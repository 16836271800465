module RemeCare.Shared.Framework.Component {
    import EnumTranslation = Contract.IEnumTranslation;

    class ApplicationProfileController implements ng.IComponentController {
        public applicationProfiles: EnumTranslation[];

        constructor(
            private readonly $location: ng.ILocationService,
            private readonly $state: ng.ui.IStateService,
            private readonly $stateParams,
            private readonly authservice: Framework.AuthService,
            private readonly modalBuilderFactory: Shared.Framework.Helper.ModalBuilderFactory,
            private readonly informedConsentSvc: Shared.Framework.Service.InformedConsentService,
            private readonly securityApiSvc: Framework.Service.SecurityApiSvc,
            private readonly toaster: Toaster
        ) {}

        public $onInit(): void {
            this.findApplicationProfiles();
        }

        private async findApplicationProfiles(): Promise<void> {
            try {
                this.applicationProfiles = await this.securityApiSvc.findMyApplicationProfilesAsync();
            } catch (e) {
                this.toaster.error(e);
            }
        }

        public async setApplicationProfile(applicationProfile: EnumTranslation): Promise<void> {
            try {
                const authInfo = await this.securityApiSvc.setApplicationProfile(applicationProfile.Id);
                this.authservice.setRights(authInfo.Rights);
                this.authservice.setClaim(ClaimTypes.applicationProfile, applicationProfile.Id.toString());
                this.authservice.setClaim(ClaimTypes.partyRoleId, authInfo.PartyRoleId as string);
                const newState = this.$stateParams.redirect ? this.$stateParams.redirect : 'home';
                this.$state.go(newState, this.$stateParams.params);
            } catch (e) {
                this.toaster.error(e);
            }
        }
    }

    remeCareSharedModule.component('rcApplicationProfile', {
        controller: ApplicationProfileController,
        templateUrl: 'views/applicationProfile.html'
    });
}
