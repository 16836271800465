var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Filter;
        (function (Filter) {
            var displayUtcDateTimeFilter = function (dateFilter) {
                return function (utcString, dateFormat) {
                    var date = Shared.DateHelper.serverDateStringToDateTime(utcString);
                    dateFormat = dateFormat || 'short';
                    if (!moment(date).isValid())
                        return "";
                    return dateFilter(date, dateFormat);
                };
            };
            angular.module('RemeCare.Shared').filter('displayUtcDateTimeFilter', displayUtcDateTimeFilter);
        })(Filter = Shared.Filter || (Shared.Filter = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
