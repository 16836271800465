var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Periodicity;
            (function (Periodicity_1) {
                var PeriodicityFactory = Framework.Factory.PeriodicityFactory;
                var PeriodicityController = /** @class */ (function (_super) {
                    __extends(PeriodicityController, _super);
                    function PeriodicityController($scope, $translate, toaster, masterdataSvc) {
                        var _this = _super.call(this, $scope, $translate, toaster) || this;
                        _this.$scope = $scope;
                        _this.$translate = $translate;
                        _this.toaster = toaster;
                        _this.masterdataSvc = masterdataSvc;
                        $scope.clearDuration = function () { return _this.clearDuration(); };
                        $scope.maxDuration = function () { return _this.maxDuration(); };
                        $scope.changeAlternation = function () { return _this.changeAlternation(); };
                        $scope.changeEvenOddDays = function () { return _this.changeEvenOddDays(); };
                        $scope.isRoot = function () { return _this.isRoot(); };
                        return _this;
                    }
                    PeriodicityController.prototype.$onInit = function () {
                        this.updatingAlternation = false;
                        this.getDurationsUnitsAsync();
                        this.watchRecurrence();
                    };
                    PeriodicityController.prototype.watchRecurrence = function () {
                        var _this = this;
                        this.$scope.$watch(function (s) { return s.periodicity.recurrence.Unit; }, function (newValue, oldValue) {
                            if (newValue != null && (oldValue == null || newValue !== oldValue)) {
                                var unit = _.find(_this.$scope.possibleDurationUnits, function (u) { return u.Id === newValue; });
                                if (_this.$scope.newPeriodicity) {
                                    _this.$scope.newPeriodicity()(unit);
                                }
                                else if (_this.$scope.periodicity.parentPeriodicity != null) {
                                    _this.newPeriodicity(newValue);
                                }
                                _this.setDurationUnits();
                            }
                        });
                    };
                    PeriodicityController.prototype.changeAlternation = function () {
                        if (this.$scope.setRecurrentCycleMembers) {
                            this.$scope.setRecurrentCycleMembers()(this.$scope.periodicity);
                        }
                    };
                    PeriodicityController.prototype.changeEvenOddDays = function () {
                        if (this.$scope.setEvenOddDays) {
                            this.$scope.setEvenOddDays()(this.$scope.periodicity.dailyAlternationType);
                        }
                    };
                    PeriodicityController.prototype.newPeriodicity = function (durationUnit) {
                        var oldPeriodicity = this.$scope.periodicity;
                        var newPeriodicity = new PeriodicityFactory().createPeriodicityFromDurationUnit(durationUnit);
                        var type = newPeriodicity.type;
                        oldPeriodicity.setCommonParts(newPeriodicity);
                        newPeriodicity.type = type;
                        newPeriodicity.parentPeriodicity = oldPeriodicity.parentPeriodicity;
                        newPeriodicity.parentPeriodicity.childPeriodicity = newPeriodicity;
                        newPeriodicity.childPeriodicity = null;
                        this.$scope.periodicity = newPeriodicity;
                    };
                    PeriodicityController.prototype.getDurationsUnitsAsync = function () {
                        return __awaiter(this, void 0, void 0, function () {
                            var durationUnits, e_1;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        _a.trys.push([0, 2, , 3]);
                                        return [4 /*yield*/, this.masterdataSvc.getDurationUnitsAsync()];
                                    case 1:
                                        durationUnits = _a.sent();
                                        this.$scope.possibleDurationUnits = _(durationUnits).filter(function (unit) { return unit.Id !== Shared.Contract.Code.DurationUnit.Hours; });
                                        this.setDurationUnits();
                                        return [3 /*break*/, 3];
                                    case 2:
                                        e_1 = _a.sent();
                                        this.toaster.error(e_1);
                                        return [3 /*break*/, 3];
                                    case 3: return [2 /*return*/];
                                }
                            });
                        });
                    };
                    PeriodicityController.prototype.setDurationUnits = function () {
                        var _this = this;
                        if (this.$scope.periodicity != null && this.$scope.periodicity.parentPeriodicity != null) {
                            this.$scope.limitedDurationUnits = _(this.$scope.possibleDurationUnits).filter(function (u) { return u.Id <= _this.$scope.periodicity.parentPeriodicity.recurrence.Unit; });
                            this.$scope.extraLimitedDurationUnits = _(this.$scope.possibleDurationUnits).filter(function (u) { return u.Id < _this.$scope.periodicity.parentPeriodicity.recurrence.Unit; });
                        }
                        else {
                            this.$scope.limitedDurationUnits = this.$scope.possibleDurationUnits;
                            this.$scope.extraLimitedDurationUnits = this.$scope.possibleDurationUnits;
                        }
                    };
                    PeriodicityController.prototype.clearDuration = function () {
                        this.$scope.periodicity.duration = {};
                    };
                    PeriodicityController.prototype.maxDuration = function () {
                        if (this.$scope.periodicity.parentPeriodicity == null ||
                            this.$scope.periodicity.duration == null ||
                            this.$scope.periodicity.duration.Unit == null) {
                            return 0;
                        }
                        var parentMax = this.getDurationInDays(this.$scope.periodicity.parentPeriodicity.recurrence);
                        return this.daysToDurationUnit(parentMax, this.$scope.periodicity.duration.Unit);
                    };
                    PeriodicityController.prototype.daysToDurationUnit = function (days, durationUnit) {
                        switch (durationUnit) {
                            case Shared.Contract.Code.DurationUnit.Days:
                                return days;
                            case Shared.Contract.Code.DurationUnit.Weeks:
                                return days / 7;
                            case Shared.Contract.Code.DurationUnit.Months:
                                return days / 28;
                            case Shared.Contract.Code.DurationUnit.Years:
                                return days / 365;
                        }
                        return 0;
                    };
                    PeriodicityController.prototype.isRoot = function () {
                        return this.$scope.periodicity && !this.$scope.periodicity.parentPeriodicity;
                    };
                    PeriodicityController.prototype.getDurationInDays = function (duration) {
                        switch (duration.Unit) {
                            case Shared.Contract.Code.DurationUnit.Days:
                                return duration.Quantity;
                            case Shared.Contract.Code.DurationUnit.Weeks:
                                return duration.Quantity * 7;
                            case Shared.Contract.Code.DurationUnit.Months:
                                return duration.Quantity * 28;
                            case Shared.Contract.Code.DurationUnit.Years:
                                return duration.Quantity * 365;
                        }
                        return 0;
                    };
                    return PeriodicityController;
                }(Framework.ControllerBase));
                Shared.remeCareSharedModule.controller('periodicityCtrl', PeriodicityController);
            })(Periodicity = Framework.Periodicity || (Framework.Periodicity = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
