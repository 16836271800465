var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Factory;
            (function (Factory) {
                var UrlBinder = /** @class */ (function () {
                    function UrlBinder($location, parentObject, propertyMappers) {
                        this.$location = $location;
                        this.parentObject = parentObject;
                        this.propertyMappers = propertyMappers;
                        this.properties = [];
                        this.propertyMappers = propertyMappers || {};
                    }
                    UrlBinder.prototype.bind = function (properties) {
                        return __awaiter(this, void 0, void 0, function () {
                            var _i, _a, property, object, _b, _c, _d;
                            var _this = this;
                            return __generator(this, function (_e) {
                                switch (_e.label) {
                                    case 0:
                                        properties = properties || [];
                                        _(properties).forEach(function (p) { return _this.properties.push(p); });
                                        this.properties = _(this.properties).unique();
                                        _i = 0, _a = this.properties;
                                        _e.label = 1;
                                    case 1:
                                        if (!(_i < _a.length)) return [3 /*break*/, 6];
                                        property = _a[_i];
                                        object = this.$location.search()[property];
                                        _b = this.parentObject;
                                        _c = property;
                                        if (!this.propertyMappers[property]) return [3 /*break*/, 3];
                                        return [4 /*yield*/, this.propertyMappers[property].getObject(object)];
                                    case 2:
                                        _d = _e.sent();
                                        return [3 /*break*/, 4];
                                    case 3:
                                        _d = this.getObjectValue(object);
                                        _e.label = 4;
                                    case 4:
                                        _b[_c] = _d;
                                        _e.label = 5;
                                    case 5:
                                        _i++;
                                        return [3 /*break*/, 1];
                                    case 6: return [2 /*return*/];
                                }
                            });
                        });
                    };
                    UrlBinder.prototype.bindAllFromUrl = function () {
                        return __awaiter(this, void 0, void 0, function () {
                            var properties;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        properties = _(this.$location.search()).keys();
                                        return [4 /*yield*/, this.bind(properties)];
                                    case 1:
                                        _a.sent();
                                        return [2 /*return*/];
                                }
                            });
                        });
                    };
                    UrlBinder.prototype.reflectInUrl = function (fullParent) {
                        var _this = this;
                        if (fullParent) {
                            _(this.parentObject).chain().keys().forEach(function (k) { return _this.properties.push(k); });
                            this.properties = _(this.properties).unique();
                        }
                        this.$location.replace();
                        _(this.properties).forEach(function (p) {
                            var stringValue = _this.propertyMappers[p]
                                ? _this.propertyMappers[p].getString(_this.parentObject[p])
                                : JSON.stringify(_this.parentObject[p]);
                            _this.$location.search(p, stringValue);
                        });
                    };
                    UrlBinder.prototype.isEmpty = function () {
                        var _this = this;
                        return _(this.properties).all(function (p) {
                            var property = _this.parentObject[p];
                            return property == null ||
                                (_(property).isString() && property === '') ||
                                (_(property).isObject() && _(property).isEmpty());
                        });
                    };
                    UrlBinder.prototype.getObjectValue = function (object) {
                        return object
                            ? JSON.parse(object)
                            : null;
                    };
                    return UrlBinder;
                }());
                Factory.UrlBinder = UrlBinder;
                var UrlBinderFactory = /** @class */ (function () {
                    function UrlBinderFactory($location) {
                        this.$location = $location;
                    }
                    UrlBinderFactory.prototype.createUrlBinder = function (parentObject, propertyMappers) {
                        return new UrlBinder(this.$location, parentObject, propertyMappers);
                    };
                    return UrlBinderFactory;
                }());
                Factory.UrlBinderFactory = UrlBinderFactory;
                Shared.remeCareSharedModule.service('urlBinderFactory', UrlBinderFactory);
            })(Factory = Framework.Factory || (Framework.Factory = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
