var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Factory;
            (function (Factory) {
                var GuidanceStepFactory = /** @class */ (function () {
                    function GuidanceStepFactory() {
                    }
                    GuidanceStepFactory.createGuidanceStep = function (serverObject) {
                        return new RemeCare.Shared.Framework.Model.GuidanceStep(serverObject);
                    };
                    return GuidanceStepFactory;
                }());
                Factory.GuidanceStepFactory = GuidanceStepFactory;
            })(Factory = Framework.Factory || (Framework.Factory = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
