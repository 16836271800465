module RemeCare.Shared.Contract.Code {
    export enum ContactPointUsageType {
        None = 0,
        Default = 1,
        Domicile = 2,
        Residence = 3,
        Work = 4,
        Invoicing = 5,
        Postal = 6
    }
}