var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Contract;
        (function (Contract) {
            var Code;
            (function (Code) {
                var DiseaseType;
                (function (DiseaseType) {
                    DiseaseType[DiseaseType["Comorbidity"] = 1] = "Comorbidity";
                    DiseaseType[DiseaseType["TreatedDisease"] = 2] = "TreatedDisease";
                })(DiseaseType = Code.DiseaseType || (Code.DiseaseType = {}));
            })(Code = Contract.Code || (Contract.Code = {}));
        })(Contract = Shared.Contract || (Shared.Contract = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
