var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var PkceHelper = /** @class */ (function () {
            function PkceHelper() {
            }
            PkceHelper.generatePkcePair = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var codeVerifier, codeChallenge;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                codeVerifier = this.generateRandomString();
                                return [4 /*yield*/, this.pkceChallengeFromVerifier(codeVerifier)];
                            case 1:
                                codeChallenge = _a.sent();
                                return [2 /*return*/, { codeChallenge: codeChallenge, codeVerifier: codeVerifier }];
                        }
                    });
                });
            };
            // Generate a secure random string using the browser crypto functions
            PkceHelper.generateRandomString = function () {
                var array = new Uint32Array(28);
                window.crypto.getRandomValues(array);
                return this.arrayFrom(array, function (dec) { return ('0' + dec.toString(16)).substr(-2); }, this).join('');
            };
            // Calculate the SHA256 hash of the input text.
            // Returns a promise that resolves to an ArrayBuffer
            PkceHelper.sha256 = function (plain) {
                var encoder = new TextEncoder();
                var data = encoder.encode(plain);
                return window.crypto.subtle.digest('SHA-256', data);
            };
            // Return the base64-urlencoded sha256 hash for the PKCE challenge
            PkceHelper.pkceChallengeFromVerifier = function (v) {
                return __awaiter(this, void 0, void 0, function () {
                    var hashed;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, this.sha256(v)];
                            case 1:
                                hashed = _a.sent();
                                // Convert the ArrayBuffer to string using Uint8 array to conver to what btoa accepts.
                                // btoa accepts chars only within ascii 0-255 and base64 encodes them.
                                return [2 /*return*/, Shared.Base64Helper.base64urlencode(String.fromCharCode.apply(null, new Uint8Array(hashed)))];
                        }
                    });
                });
            };
            // Array.from polyfill for IE
            PkceHelper.arrayFrom = function (arr, callbackFn, thisArg) {
                var arNew = [];
                var k = []; // used for convert Set to an Array
                var i = 0;
                for (; i < arr.length; i++) {
                    arNew[i] = callbackFn ? callbackFn.call(thisArg, arr[i], i, arr) : arr[i];
                }
                return arNew;
            };
            return PkceHelper;
        }());
        Shared.PkceHelper = PkceHelper;
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
