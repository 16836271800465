var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var StringHelper = /** @class */ (function () {
            function StringHelper() {
            }
            StringHelper.orEmpty = function (value) {
                return value || '';
            };
            StringHelper.joinNonEmpty = function (separator, items) {
                return _.filter(items, function (i) { return !!i; }).join(separator);
            };
            return StringHelper;
        }());
        Shared.StringHelper = StringHelper;
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
