/// <reference path="./version.ts"/>
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Model;
            (function (Model) {
                var ObjectiveDefinition = /** @class */ (function () {
                    function ObjectiveDefinition(serverObject) {
                        if (serverObject != null) {
                            this.id = serverObject.Id;
                            this.name = serverObject.Name;
                            this.isPersonalisable = serverObject.IsPersonalisable;
                            this.type = serverObject.Type;
                            this.unit = serverObject.Unit;
                            this.characteristic = serverObject.Characteristic;
                            this.sequence = serverObject.Sequence;
                            this.period = serverObject.Period;
                            this.objectiveValues = _(serverObject.ObjectiveValues).map(function (x) { return new ObjectiveValue(x); });
                            this.therapyId = serverObject.TherapyId;
                            this.readOnly = serverObject.ReadOnly;
                        }
                        else {
                            this.objectiveValues = [];
                        }
                    }
                    ObjectiveDefinition.prototype.addObjectiveValue = function (objectiveValue) {
                        if (!_(this.objectiveValues).isEmpty()) {
                            var previous = this.getLastObjectiveValue();
                            previous.validUntilDate = moment(objectiveValue.validFromDate).subtract(1, 'Day').toDate();
                        }
                        else {
                            objectiveValue.validFromDate = new Date();
                        }
                        objectiveValue.validUntilDate = null;
                        this.objectiveValues.push(objectiveValue);
                    };
                    ObjectiveDefinition.prototype.editObjectiveValue = function (objectiveValue, newValidFromDate, newValidUntilDate) {
                        var previous = _(this.objectiveValues)
                            .find(function (x) { return moment(x.validUntilDate)
                            .isSame(moment(objectiveValue.validFromDate).subtract(1, 'Day').toDate()); });
                        if (previous) {
                            previous.validUntilDate = moment(newValidFromDate).subtract(1, 'Day').toDate();
                        }
                        objectiveValue.validFromDate = newValidFromDate;
                        objectiveValue.validUntilDate = newValidUntilDate;
                        return objectiveValue;
                    };
                    ObjectiveDefinition.prototype.deleteObjectiveValue = function (objectiveValue) {
                        var previous = _(this.objectiveValues)
                            .find(function (x) { return moment(x.validUntilDate)
                            .isSame(moment(objectiveValue.validFromDate).subtract(1, 'Day').toDate()); });
                        previous.validUntilDate = null;
                        this.objectiveValues = _(this.objectiveValues).filter(function (x) { return x.validFromDate != objectiveValue.validFromDate; });
                    };
                    ObjectiveDefinition.prototype.toServerWrite = function () {
                        var _this = this;
                        _(this.objectiveValues).filter(function (x) { return x !== _this.getLastObjectiveValue(); }).map(function (v) { return v.validUntilDate = null; });
                        var result = {
                            Id: this.id,
                            Name: this.name,
                            IsPersonalisable: this.isPersonalisable,
                            Type: this.type.Id,
                            UnitId: this.unit != null ? this.unit.Id : null,
                            CharacteristicId: this.characteristic.Id,
                            Period: {
                                Quantity: this.period.Quantity,
                                Unit: this.period.Unit
                            },
                            Sequence: this.sequence,
                            ObjectiveValues: _(this.objectiveValues).map(function (x) { return x.toServerWrite(); }),
                            TherapyId: this.therapyId
                        };
                        return result;
                    };
                    ObjectiveDefinition.prototype.getCurrentObjectiveValue = function () {
                        var currentDate = new Date();
                        var result = _(this.objectiveValues)
                            .find(function (x) { return (moment(x.validFromDate).isBefore(currentDate) &&
                            (moment(x.validUntilDate).isAfter(currentDate) || x.validUntilDate == null)) ||
                            moment().isSame(x.validFromDate, 'day') ||
                            moment().isSame(x.validUntilDate, 'day'); });
                        return result ? result : null;
                    };
                    ObjectiveDefinition.prototype.getLastObjectiveValue = function () {
                        var maxDate = _(this.objectiveValues).max(function (x) { return x.validFromDate; }).validFromDate;
                        return _(this.objectiveValues).find(function (x) { return moment(x.validFromDate).isSame(maxDate); });
                    };
                    ObjectiveDefinition.prototype.getPreviousObjectiveValue = function (value) {
                        var values = _(this.objectiveValues).filter(function (v) { return moment(value.validFromDate).add(-1, 'day').isSame(v.validUntilDate); });
                        return _(values).size() !== 0 ? _(values).last() : null;
                    };
                    ObjectiveDefinition.prototype.hasFutureValue = function () {
                        return _(this.objectiveValues).any(function (x) { return moment(x.validFromDate).isAfter(moment()); });
                    };
                    return ObjectiveDefinition;
                }());
                Model.ObjectiveDefinition = ObjectiveDefinition;
                var ObjectiveValue = /** @class */ (function (_super) {
                    __extends(ObjectiveValue, _super);
                    function ObjectiveValue(serverObject) {
                        var _this = _super.call(this) || this;
                        if (serverObject != null) {
                            _this.id = serverObject.Id;
                            _this.amount = serverObject.Amount;
                            _this.validFromDate = Shared.DateHelper.serverDateStringToDate(serverObject.ValidFromDate);
                            _this.validUntilDate = Shared.DateHelper.serverDateStringToDate(serverObject.ValidUntilDate);
                            _this.isPersonalised = serverObject.IsPersonalised;
                        }
                        return _this;
                    }
                    ObjectiveValue.prototype.toServerWrite = function () {
                        var result = {
                            Id: this.id,
                            Amount: this.amount,
                            ValidFromDate: Shared.DateHelper.toServerDateString(this.validFromDate)
                        };
                        if (this.validUntilDate !== null) {
                            result.ValidUntilDate = result.ValidUntilDate = Shared.DateHelper.toServerDateString(this.validUntilDate);
                        }
                        return result;
                    };
                    return ObjectiveValue;
                }(Model.Version));
                Model.ObjectiveValue = ObjectiveValue;
            })(Model = Framework.Model || (Framework.Model = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
