module RemeCare.Shared.Framework.Factory {

    export class PeriodicityFactory {

        public createPeriodicity(serverObject: Shared.Contract.Read.IPeriodicity): Framework.Model.Periodicity {
            var result = this.createPeriodicityFromType(serverObject.Type, serverObject);
            return result;
        }

        public createPeriodicityFromType(type: Shared.Contract.Code.PeriodicityType, serverObject?: Shared.Contract.Read.IPeriodicity): Framework.Model.Periodicity {
            var result;
            switch (type) {
                case Shared.Contract.Code.PeriodicityType.PeriodicityDay:
                    result = new Framework.Model.PeriodicityDay(<Shared.Contract.Read.IPeriodicityDay>serverObject);
                    result.recurrence.Unit = Shared.Contract.Code.DurationUnit.Days;
                    break;
                case Shared.Contract.Code.PeriodicityType.PeriodicityWeek:
                    result = new Framework.Model.PeriodicityWeek(<Shared.Contract.Read.IPeriodicityWeek>serverObject);
                    result.recurrence.Unit = Shared.Contract.Code.DurationUnit.Weeks;
                    break;
                case Shared.Contract.Code.PeriodicityType.PeriodicityMonth:
                    result = new Framework.Model.PeriodicityMonth(<Shared.Contract.Read.IPeriodicityMonth>serverObject);
                    result.recurrence.Unit = Shared.Contract.Code.DurationUnit.Months;
                    break;
                case Shared.Contract.Code.PeriodicityType.PeriodicityYear:
                    result = new Framework.Model.PeriodicityYear(<Shared.Contract.Read.IPeriodicityYear>serverObject);
                    result.recurrence.Unit = Shared.Contract.Code.DurationUnit.Years;
                    break;
                default:
                    result = new Framework.Model.Periodicity(serverObject);
                    break;
            }

            if (serverObject != null) {
                if (serverObject.ChildPeriodicity != null)
                {
                    result.setChild(this.createPeriodicity(serverObject.ChildPeriodicity));
                    result.childPeriodicity.parentPeriodicity = result;
                }
                if (serverObject.IntraDayTimings != null) {
                    var intraDayTimingFactory = new IntraDayTimingFactory();
                    result.intraDayTimings = _(serverObject.IntraDayTimings).map((i) => intraDayTimingFactory.createIntraDayTiming(i));
                }
            }
            return result;
        }

        public createPeriodicityFromDurationUnit(recurrenceUnit: Shared.Contract.Code.DurationUnit, parentPeriodicity?: Framework.Model.Periodicity): Framework.Model.Periodicity {
            var result: Framework.Model.Periodicity;
            switch (recurrenceUnit) {
                case Shared.Contract.Code.DurationUnit.Days:
                    result = new Framework.Model.PeriodicityDay();
                    break;
                case Shared.Contract.Code.DurationUnit.Weeks:
                    result = new Framework.Model.PeriodicityWeek();
                    break;
                case Shared.Contract.Code.DurationUnit.Months:
                    result = new Framework.Model.PeriodicityMonth();
                    break;
                case Shared.Contract.Code.DurationUnit.Years:
                    result = new Framework.Model.PeriodicityYear();
                    break;
                default:
                    result = new Framework.Model.PeriodicityDay();
                    break;
            }
            result.recurrence = <Shared.Contract.IDuration>{
                Unit: recurrenceUnit
            }
            if (parentPeriodicity != null) {
                parentPeriodicity.setChild(result);
                result.parentPeriodicity = parentPeriodicity;
            }
            return result;
        }

        public createPeriodicitiesFromAdHoc(unstructuredPeriodicity: string, unstructuredTargetQuantity: number): Model.Periodicity {
            var periodicity = new Framework.Model.Periodicity();
            periodicity.unstructuredPeriodicity = unstructuredPeriodicity;
            periodicity.unstructuredTargetQuantity = unstructuredTargetQuantity;
            return periodicity;
        }

        public createPeriodicitiesFromSimplifiedRecurrence(simplifiedRecurrence: Contract.IEnumTranslation): Array<Model.Periodicity> {
            var result: Array<Model.Periodicity> = [];
            var periodicity: Model.Periodicity;
            switch (simplifiedRecurrence.Id) {
                case Contract.Code.SimplifiedRecurrence.XTimesPerDay:
                    periodicity = this.createPeriodicityWithIntraDayTiming(Shared.Contract.Code.PeriodicityType.PeriodicityDay, simplifiedRecurrence);
                    periodicity.recurrence = {
                        Quantity: 1,
                        Unit: Shared.Contract.Code.DurationUnit.Days
                    }
                    result.push(periodicity);
                    break;
                case Contract.Code.SimplifiedRecurrence.XTimesEveryYDays:
                    periodicity = this.createPeriodicityWithIntraDayTiming(Shared.Contract.Code.PeriodicityType.PeriodicityDay, simplifiedRecurrence);
                    periodicity.recurrence = {
                        Quantity: null,
                        Unit: Shared.Contract.Code.DurationUnit.Days
                    }
                    result.push(periodicity);
                    break;
                case Contract.Code.SimplifiedRecurrence.EveryOtherDay:
                    periodicity = this.createPeriodicityWithIntraDayTiming(Shared.Contract.Code.PeriodicityType.PeriodicityDay, simplifiedRecurrence);
                    periodicity.recurrence = {
                        Quantity: 2,
                        Unit: Shared.Contract.Code.DurationUnit.Days
                    }
                    periodicity.sequenceInRecurrenceCycle = 1;
                    result.push(periodicity);
                    periodicity = this.createPeriodicityWithIntraDayTiming(Shared.Contract.Code.PeriodicityType.PeriodicityDay, simplifiedRecurrence);
                    periodicity.recurrence = {
                        Quantity: 2,
                        Unit: Shared.Contract.Code.DurationUnit.Days
                    }
                    periodicity.sequenceInRecurrenceCycle = 2;
                    result.push(periodicity);
                    break;
                case Contract.Code.SimplifiedRecurrence.WeekSchedule:
                    periodicity = this.createPeriodicityWithIntraDayTiming(Shared.Contract.Code.PeriodicityType.PeriodicityWeek, simplifiedRecurrence);
                    periodicity.recurrence = {
                        Quantity: 1,
                        Unit: Shared.Contract.Code.DurationUnit.Weeks
                    }
                    result.push(periodicity);
                    break;
                case Contract.Code.SimplifiedRecurrence.Other:
                    periodicity = new Model.Periodicity();
                    periodicity.simplifiedRecurrence = simplifiedRecurrence;
                    result.push(periodicity);
                    break;
            }
            return result;
        }

        private createPeriodicityWithIntraDayTiming(type: Shared.Contract.Code.PeriodicityType, simplifiedRecurrence: Contract.IEnumTranslation): Model.Periodicity {
            var intraDayTimingFactory = new IntraDayTimingFactory();
            var periodicity = this.createPeriodicityFromType(type);
            periodicity.simplifiedRecurrence = simplifiedRecurrence;
            periodicity.intraDayTimings.push(intraDayTimingFactory.createIntraDayTimingWithTargetDoses(1));
            periodicity.sequence = 1;
            return periodicity;
        }
    }

    remeCareSharedModule.factory('periodicityFactory', () => new PeriodicityFactory());
} 