namespace RemeCare.Shared.Framework.Grid {
    remeCareSharedModule.component('rcSearchCriteria', {
        transclude: true,
        templateUrl: 'views/grid/searchCriteria.html',
        bindings: {
            search: '&',
            clearCriteria: '&',
            header: '@',
        },
    });
}
