var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Model;
            (function (Model) {
                var InformedConsentVersion = /** @class */ (function () {
                    function InformedConsentVersion(serverObject) {
                        if (serverObject != null) {
                            this.setIsDraft(serverObject.IsDraft);
                            this.setValidity(Shared.DateHelper.serverDateStringToDate(serverObject.ValidFromDate), Shared.DateHelper.serverDateStringToDate(serverObject.ValidUntilDate));
                            this.validityChangeRemark = serverObject.ValidityChangeRemark;
                            this.version = serverObject.Version;
                        }
                    }
                    InformedConsentVersion.prototype.setValidity = function (validFrom, validUntil) {
                        this.validFromDate = validFrom;
                        this.validUntilDate = validUntil;
                        this.validFromDisplay = this.isDraft ? null : this.validFromDate;
                        this.validUntilDisplay = this.isDraft ? null : this.validUntilDate;
                    };
                    InformedConsentVersion.prototype.setIsDraft = function (isDraft) {
                        this.isDraft = isDraft;
                        this.setValidity(this.validFromDate, this.validUntilDate);
                    };
                    return InformedConsentVersion;
                }());
                Model.InformedConsentVersion = InformedConsentVersion;
            })(Model = Framework.Model || (Framework.Model = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
