/// <reference path="../../contract/code/monitoringPartSourceType.ts"/>
/// <reference path="../../contract/code/monitoringPartDataSourceType.ts"/>
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Factory;
            (function (Factory) {
                var MonitoringPartSourceTypeCode = Shared.Contract.Code.MonitoringPartSourceType;
                var DataSourceType = Shared.Contract.Code.MonitoringPartDataSourceType;
                var MonitoringPartSourceFactory = /** @class */ (function () {
                    function MonitoringPartSourceFactory(masterdataSvc, sourceParameterRepresentationFactory) {
                        this.masterdataSvc = masterdataSvc;
                        this.sourceParameterRepresentationFactory = sourceParameterRepresentationFactory;
                    }
                    MonitoringPartSourceFactory.prototype.createAllAsync = function (therapyId, serverObjects) {
                        return __awaiter(this, void 0, void 0, function () {
                            var monitoringPartSources, observableEntityIds, codeSetIds, characteristicIds, unitIds, ruleThresholdIds, objectiveIds, observableEntitiesPromise, codeSetPromise, characteristicPromise, unitPromise, ruleThresholdPromise, objectivePromise, _a, observableEntities, codeSetTypess, characteristics, units, ruleThresholdsResult, objectivesResult, ruleThresholds, objectives;
                            var _this = this;
                            return __generator(this, function (_b) {
                                switch (_b.label) {
                                    case 0:
                                        monitoringPartSources = _.chain(serverObjects)
                                            .map(function (so) { return so.MonitoringPartSources; })
                                            .flatten()
                                            .value();
                                        observableEntityIds = _.chain(monitoringPartSources)
                                            .map(function (so) {
                                            return so.ObservableEntityId;
                                        })
                                            .filter(function (id) { return id != null; })
                                            .uniq()
                                            .value();
                                        codeSetIds = _.chain(monitoringPartSources)
                                            .map(function (so) {
                                            return so.CodeSetId;
                                        })
                                            .filter(function (id) { return id != null; })
                                            .uniq()
                                            .value();
                                        characteristicIds = _.chain(monitoringPartSources)
                                            .map(function (so) {
                                            return so.CharacteristicId;
                                        })
                                            .filter(function (id) { return id != null; })
                                            .uniq()
                                            .value();
                                        unitIds = _.chain(monitoringPartSources)
                                            .map(function (so) {
                                            return so.UnitId;
                                        })
                                            .filter(function (id) { return id != null; })
                                            .uniq()
                                            .value();
                                        ruleThresholdIds = _.chain(monitoringPartSources)
                                            .map(function (so) { return so.RuleThresholdId; })
                                            .filter(function (id) { return id != null; })
                                            .uniq()
                                            .value();
                                        objectiveIds = _.chain(monitoringPartSources)
                                            .map(function (so) { return so.ObjectiveId; })
                                            .filter(function (id) { return id != null; })
                                            .uniq()
                                            .value();
                                        observableEntitiesPromise = observableEntityIds.length
                                            ? this.masterdataSvc.getObservableEntitiesAsync(observableEntityIds)
                                            : this.getEmptyPromise();
                                        codeSetPromise = codeSetIds.length
                                            ? this.masterdataSvc.getCodeSetTypesAsync()
                                            : this.getEmptyPromise();
                                        characteristicPromise = characteristicIds.length
                                            ? this.masterdataSvc.getCharacteristicsAsync(characteristicIds, true)
                                            : this.getEmptyPromise();
                                        unitPromise = unitIds.length
                                            ? this.masterdataSvc.getUnitsAsync(true)
                                            : this.getEmptyPromise();
                                        ruleThresholdPromise = ruleThresholdIds.length
                                            ? this.masterdataSvc.getRuleThresholdsAsync(therapyId, ruleThresholdIds)
                                            : this.getEmptyPromise();
                                        objectivePromise = objectiveIds.length
                                            ? this.masterdataSvc.getObjectivesAsync(therapyId, objectiveIds)
                                            : this.getEmptyPromise();
                                        return [4 /*yield*/, Promise.all([
                                                observableEntitiesPromise,
                                                codeSetPromise,
                                                characteristicPromise,
                                                unitPromise,
                                                ruleThresholdPromise,
                                                objectivePromise,
                                            ])];
                                    case 1:
                                        _a = _b.sent(), observableEntities = _a[0], codeSetTypess = _a[1], characteristics = _a[2], units = _a[3], ruleThresholdsResult = _a[4], objectivesResult = _a[5];
                                        ruleThresholds = ruleThresholdsResult.map(function (rtd) {
                                            return { Id: rtd.Id, Text: rtd.Name };
                                        });
                                        objectives = objectivesResult.map(function (od) { return ({
                                            Id: od.Id,
                                            Text: od.Name,
                                        }); });
                                        return [2 /*return*/, Promise.all(_.map(serverObjects, function (mpmpss) { return __awaiter(_this, void 0, void 0, function () {
                                                var mps;
                                                var _this = this;
                                                return __generator(this, function (_a) {
                                                    switch (_a.label) {
                                                        case 0: return [4 /*yield*/, Promise.all(_.map(mpmpss.MonitoringPartSources, function (mps) {
                                                                return _this.createAsync(mps, observableEntities, codeSetTypess, characteristics, units, ruleThresholds, objectives);
                                                            }))];
                                                        case 1:
                                                            mps = _a.sent();
                                                            return [2 /*return*/, {
                                                                    monitoringPartId: mpmpss.Id,
                                                                    monitoringPartSources: mps,
                                                                }];
                                                    }
                                                });
                                            }); }))];
                                }
                            });
                        });
                    };
                    MonitoringPartSourceFactory.prototype.createFromDataSource = function (dataSource) {
                        var result;
                        switch (dataSource.dataSourceType.Id) {
                            case DataSourceType.RegistrationValue:
                                if (dataSource.isNumeric) {
                                    result = this.createFromType(MonitoringPartSourceTypeCode.QuantitativeMeasuringPointParameter);
                                    result.characteristic = dataSource.toEntityTranslation();
                                    result.unit = dataSource.dependantObject;
                                }
                                else {
                                    result = this.createFromType(MonitoringPartSourceTypeCode.QualitativeMeasuringPointParameter);
                                    result.observableEntity = dataSource.toEntityTranslation();
                                    result.codeSet = dataSource.dependantObject;
                                }
                                break;
                            case DataSourceType.CarePlanAnamnesisValue:
                                if (dataSource.isNumeric) {
                                    result = this.createFromType(MonitoringPartSourceTypeCode.QuantitativeReferenceParameterAnamnesis);
                                    result.characteristic = dataSource.toEntityTranslation();
                                    result.unit = dataSource.dependantObject;
                                }
                                else {
                                    result = this.createFromType(MonitoringPartSourceTypeCode.QualitativeReferenceParameterAnamnesis);
                                    result.observableEntity = dataSource.toEntityTranslation();
                                    result.codeSet = dataSource.dependantObject;
                                }
                                break;
                            case DataSourceType.ObjectValue:
                                result = this.createFromType(MonitoringPartSourceTypeCode.ReferenceParameterObjective);
                                result.objective = dataSource.toEntityTranslation();
                                break;
                            case DataSourceType.ThresholdValue:
                                result = this.createFromType(MonitoringPartSourceTypeCode.ReferenceParameterThreshold);
                                result.ruleThreshold = dataSource.toEntityTranslation();
                                break;
                            case DataSourceType.ExternalSourceData:
                                result = this.createFromType(MonitoringPartSourceTypeCode.ExternalDataSourceParameter);
                                break;
                        }
                        result.sourceType = dataSource.dataSourceType.Id;
                        return result;
                    };
                    MonitoringPartSourceFactory.prototype.createFromType = function (type) {
                        return this.createPartSource(type);
                    };
                    MonitoringPartSourceFactory.prototype.createAsync = function (serverObject, observableEntities, codeSets, characteristics, units, ruleThresholds, objectives) {
                        return __awaiter(this, void 0, void 0, function () {
                            var result, r;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        if (serverObject == null) {
                                            return [2 /*return*/, null];
                                        }
                                        result = this.createPartSource(serverObject.Type, serverObject, observableEntities, codeSets, characteristics, units, ruleThresholds, objectives);
                                        if (!(serverObject.Type !== MonitoringPartSourceTypeCode.MonitoringPartSourceAction &&
                                            serverObject.Type !== MonitoringPartSourceTypeCode.MonitoringPartSourceFilter)) return [3 /*break*/, 2];
                                        return [4 /*yield*/, this.sourceParameterRepresentationFactory.createAsync(serverObject.SourceParameterRepresentation)];
                                    case 1:
                                        r = _a.sent();
                                        result.sourceParameterRepresentation = r;
                                        return [2 /*return*/, result];
                                    case 2: return [2 /*return*/, result];
                                }
                            });
                        });
                    };
                    MonitoringPartSourceFactory.prototype.getEmptyPromise = function () {
                        return new Promise(function (resolve) { return resolve([]); });
                    };
                    MonitoringPartSourceFactory.prototype.createPartSource = function (type, serverObject, observableEntities, codeSets, characteristics, units, ruleThresholds, objectives) {
                        var result;
                        switch (type) {
                            case MonitoringPartSourceTypeCode.MonitoringPartSourceAction:
                                result = new Framework.Model.MonitoringPartSourceAction(serverObject);
                                break;
                            case MonitoringPartSourceTypeCode.QualitativeMeasuringPointParameter:
                                result = new Framework.Model.QualitativeMeasuringPointParameter(serverObject, observableEntities, codeSets);
                                break;
                            case MonitoringPartSourceTypeCode.QuantitativeMeasuringPointParameter:
                                result = new Framework.Model.QuantitativeMeasuringPointParameter(serverObject, characteristics, units);
                                break;
                            case MonitoringPartSourceTypeCode.QualitativeReferenceParameterAnamnesis:
                                result = new Framework.Model.QualitativeReferenceParameterAnamnesis(serverObject, observableEntities, codeSets);
                                break;
                            case MonitoringPartSourceTypeCode.QuantitativeReferenceParameterAnamnesis:
                                result = new Framework.Model.QuantitativeReferenceParameterAnamnesis(serverObject, characteristics, units);
                                break;
                            case MonitoringPartSourceTypeCode.ReferenceParameterThreshold:
                                result = new Framework.Model.ReferenceParameterThreshold(serverObject, ruleThresholds);
                                break;
                            case MonitoringPartSourceTypeCode.ReferenceParameterObjective:
                                result = new Framework.Model.ReferenceParameterObjective(serverObject, objectives);
                                break;
                            case MonitoringPartSourceTypeCode.ExternalDataSourceParameter:
                                result = new Framework.Model.ExternalDataSourceParameter(serverObject);
                                break;
                            case MonitoringPartSourceTypeCode.MonitoringPartSourceFilter:
                                result = new Framework.Model.MonitoringPartSourceFilter(serverObject);
                                break;
                            default:
                                return null;
                        }
                        return result;
                    };
                    return MonitoringPartSourceFactory;
                }());
                Shared.remeCareSharedModule.service('monitoringPartSourceFactory', MonitoringPartSourceFactory);
            })(Factory = Framework.Factory || (Framework.Factory = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
