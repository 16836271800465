namespace RemeCare.Shared.Framework.Service {

    export class LoadMeasuringService {

        private isMeasuring: boolean;
        private measuringContext: string;
        private measuringId: string;

        constructor(
            private readonly $transitions,
            private readonly measuredStates: { [key: string]: boolean },
            private readonly uuid4
        ) {
            this.setTransitionHooks();
        }

        public getMeasuringContext(): string {
            return this.measuringContext;
        }

        public getMeasuringId(): string {
            return this.measuringId;
        }

        /**
         * Restarts measuring if any measurement was active
         */
        public reset(): void {
            if (this.isMeasuring) {
                this.startMeasuring();
            }
        }

        public registerStateToMeasure(state: string): void {
            this.measuredStates[state] = true;
        }

        public unregisterStateToMeasure(state: string): void {
            delete this.measuredStates[state];
        }

        public stopMeasuring(): void {
            this.isMeasuring = false;
            this.measuringContext = null;
            this.measuringId = null;
        }

        private setTransitionHooks(): void {
            this.$transitions.onSuccess({}, (transition) => {
                const to = transition.to().name;
                if (this.measuredStates[to]) {
                    this.startMeasuring(to);
                }
            });

            this.$transitions.onStart({}, () => {
                this.stopMeasuring();
            });
        }

        private startMeasuring(context?: string): void {
            this.measuringId = this.uuid4.generate();
            this.measuringContext = context || this.measuringContext;
            this.isMeasuring = true;
        }
    }

    export class LoadMeasuringServiceProvider implements ng.IServiceProvider {

        private measuredStates: { [key: string]: boolean } = {};

        public $get = ['$transitions', 'uuid4', ($transitions, uuid4): LoadMeasuringService => {
            return new LoadMeasuringService($transitions, this.measuredStates, uuid4);
        }];

        public registerStateToMeasure(state: string): void {
            this.measuredStates[state] = true;
        }
    }

    remeCareSharedModule.provider('loadMeasuringSvc', LoadMeasuringServiceProvider);
}