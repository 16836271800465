var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Contract;
        (function (Contract) {
            var Code;
            (function (Code) {
                var PlanningDateTimeType;
                (function (PlanningDateTimeType) {
                    PlanningDateTimeType[PlanningDateTimeType["Date"] = 1] = "Date";
                    PlanningDateTimeType[PlanningDateTimeType["DateTime"] = 2] = "DateTime";
                    PlanningDateTimeType[PlanningDateTimeType["DateTimeWindow"] = 3] = "DateTimeWindow";
                })(PlanningDateTimeType = Code.PlanningDateTimeType || (Code.PlanningDateTimeType = {}));
            })(Code = Contract.Code || (Contract.Code = {}));
        })(Contract = Shared.Contract || (Shared.Contract = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
