module RemeCare.Shared.Directive {
    
    class RightAllignedDirective implements ng.IDirective {
        
        restrict = 'E';

        templateUrl = 'views/rightAlligned.html';

        transclude = true;
    }

    remeCareSharedModule.directive('rcRightAlligned', () => new RightAllignedDirective())
}