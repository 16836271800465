module RemeCare.Shared.Contract.Code {

    export enum EvenOddDays{
        Odd = 1,
        Even = 2
    }

    export enum DailyAlternationType {
        Day1Day2 = 1,
        EvenOdd = 2
    }
}