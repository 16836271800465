var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Directive;
            (function (Directive) {
                Shared.remeCareSharedModule.component('rcInputZipCity', {
                    controllerAs: 'inputCtrl',
                    templateUrl: 'views/formElements/inputZipCity.html',
                    bindings: {
                        model: '=ngModel',
                        required: '=ngRequired',
                        disabled: '=ngDisabled',
                        readOnly: '=ngReadonly',
                        hasWarning: '=?ngWarning',
                        blur: '&?ngBlur',
                        guidanceIconLocation: '<',
                        guidanceIconFunc: '<',
                        uniqueId: '@',
                    },
                    require: {
                        formCtrl: '^form',
                    },
                });
            })(Directive = Framework.Directive || (Framework.Directive = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
