module RemeCare.Shared.Framework.Model {

    export class DataSourceTranslation {
        id: Shared.Contract.Guid;
        text: string;
        isNumeric: boolean;
        dataSourceType: Shared.Contract.IEnumTranslation;
        dependantObject: Shared.Contract.IEntityTranslation;
        trackId: string;

        constructor(def: Contract.IDataSourceTranslation, dataSourceType: Shared.Contract.IEnumTranslation) {
            this.id = def.Id;
            this.text = def.Text;
            this.isNumeric = def.IsNumeric;
            this.dataSourceType = dataSourceType;
            this.dependantObject = def.DependantObject;
            this.trackId = `${this.id}${this.dataSourceType.Id}`;
            if (this.dependantObject != null) {
                this.trackId = this.trackId + `${this.dependantObject.Id}`;
            }
        }

        getText(): string {
            var result = `${this.text} - ${this.dataSourceType.Text}`;
            if (this.dependantObject != null)
                result = result + ` - ${this.dependantObject.Text}`;
            return result;
        }

        toEntityTranslation(): Shared.Contract.IEntityTranslation {
            return {
                Id: this.id,
                Text: this.text
            }
        }
    }
}