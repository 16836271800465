module RemeCare.Shared.Framework.Model {
    import PatientInformedConsentStatusCode = Shared.Contract.Code.PatientInformedConsentStatusCode;

    export class PatientInformedConsentQuestion {
        accepted: boolean = false;
        text: string;

        constructor(question: string, status: Shared.Contract.Code.PatientInformedConsentStatusCode) {
            this.text = question;
            if (status === PatientInformedConsentStatusCode.Accepted) {
                this.accepted = true;
            }
        }
    }
} 