var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Directive;
            (function (Directive) {
                var SimplePeriodicityController = /** @class */ (function (_super) {
                    __extends(SimplePeriodicityController, _super);
                    function SimplePeriodicityController($scope, $translate, toaster, masterdataSvc) {
                        var _this = _super.call(this, $scope, $translate, toaster) || this;
                        _this.masterdataSvc = masterdataSvc;
                        $scope.findIntakeMoment = function (d) { return _this.findIntakeMoment(d); };
                        $scope.toggleIntakeMoment = function (d) { return _this.toggleIntakeMoment(d); };
                        $scope.hourDisabled = function (d) { return _this.hourDisabled(d); };
                        _this.$onInit();
                        return _this;
                    }
                    SimplePeriodicityController.prototype.$onInit = function () {
                        return __awaiter(this, void 0, void 0, function () {
                            var _a, e_1;
                            return __generator(this, function (_b) {
                                switch (_b.label) {
                                    case 0:
                                        this.fillHours();
                                        _b.label = 1;
                                    case 1:
                                        _b.trys.push([1, 3, , 4]);
                                        _a = this.$scope;
                                        return [4 /*yield*/, this.masterdataSvc.getAdministrationTimings()];
                                    case 2:
                                        _a.administrationTimings = _b.sent();
                                        return [3 /*break*/, 4];
                                    case 3:
                                        e_1 = _b.sent();
                                        this.toaster.error(e_1);
                                        return [3 /*break*/, 4];
                                    case 4: return [2 /*return*/];
                                }
                            });
                        });
                    };
                    SimplePeriodicityController.prototype.fillHours = function () {
                        this.$scope.beforeNoon = [];
                        this.$scope.afterNoon = [];
                        for (var i = 0; i < 12; i++) {
                            this.$scope.beforeNoon.push(Shared.DateHelper.serverTimeToDate((i < 10 ? '0' + i : i) + ":00"));
                            this.$scope.afterNoon.push(Shared.DateHelper.serverTimeToDate(i + 12 + ":00"));
                        }
                    };
                    SimplePeriodicityController.prototype.hourDisabled = function (hour) {
                        return this.$scope.readOnly ||
                            this.$scope.onlyIntakeMoments && !this.findIntakeMoment(hour) && this.intakeMomentCache == null ||
                            this.$scope.onlyIntakeMoments && this.findIntakeMoment(hour) && this.intakeMomentCache != null;
                    };
                    SimplePeriodicityController.prototype.findIntakeMoment = function (hour) {
                        return _(this.$scope.periodicity.intraDayTimings)
                            .find(function (idt) {
                            return moment(idt.preferredTime).isSame(hour);
                        });
                    };
                    SimplePeriodicityController.prototype.toggleIntakeMoment = function (hour) {
                        var intakeMoment = this.findIntakeMoment(hour);
                        if (this.$scope.onlyIntakeMoments) {
                            if (this.intakeMomentCache == null) {
                                this.intakeMomentCache = intakeMoment;
                                intakeMoment.preferredTime = null;
                            }
                            else {
                                this.intakeMomentCache.preferredTime = hour;
                                this.intakeMomentCache = null;
                            }
                        }
                        else {
                            if (intakeMoment != null) {
                                var index = this.$scope.periodicity.intraDayTimings.indexOf(intakeMoment);
                                this.$scope.periodicity.intraDayTimings.splice(index, 1);
                            }
                            else {
                                intakeMoment = new Framework.Model.IntraDayTimingAdministration();
                                intakeMoment.preferredTime = hour;
                                var targetDose = new Framework.Model.TargetDose();
                                targetDose.quantity = 1;
                                intakeMoment.targetValues.push(targetDose);
                                this.$scope.periodicity.intraDayTimings.push(intakeMoment);
                            }
                        }
                    };
                    SimplePeriodicityController.$inject = ['$scope', '$translate', 'toaster', 'masterdataSvc'];
                    return SimplePeriodicityController;
                }(Framework.ControllerBase));
                var SimplePeriodicityDirective = /** @class */ (function () {
                    function SimplePeriodicityDirective() {
                        this.restrict = 'E';
                        this.controller = SimplePeriodicityController;
                        this.templateUrl = 'views/periodicity/simplePeriodicity.html';
                        this.scope = {
                            periodicity: '=',
                            medication: '=',
                            readOnly: '=',
                            onlyIntakeMoments: '='
                        };
                        this.require = '^form';
                        this.link = function (scope, element, attributes, formCtrl) {
                            scope.form = formCtrl;
                        };
                    }
                    return SimplePeriodicityDirective;
                }());
                Shared.remeCareSharedModule.directive('rcSimplePeriodicity', function () { return new SimplePeriodicityDirective(); });
            })(Directive = Framework.Directive || (Framework.Directive = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
