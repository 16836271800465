var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Directive;
            (function (Directive) {
                var InputTimeRangeController = /** @class */ (function () {
                    function InputTimeRangeController($timeout) {
                        this.$timeout = $timeout;
                    }
                    InputTimeRangeController.prototype.$onInit = function () {
                        this.updateDate();
                    };
                    InputTimeRangeController.prototype.$onChanges = function (changes) {
                        var _this = this;
                        if (changes.fromModel) {
                            this.$timeout(function () { return _this.updateDate(); });
                        }
                    };
                    InputTimeRangeController.prototype.updateDate = function () {
                        if (!this.fromModel) {
                            return;
                        }
                        if (!this.untilModel) {
                            this.untilModel = this.fromModel;
                        }
                        else {
                            this.untilModel.setFullYear(this.fromModel.getFullYear(), this.fromModel.getMonth(), this.fromModel.getDate());
                        }
                    };
                    return InputTimeRangeController;
                }());
                Shared.remeCareSharedModule.component('rcInputTimeRange', {
                    bindings: {
                        fromModel: '=',
                        untilModel: '=',
                        required: '=?ngRequired',
                        readOnly: '=?ngReadonly',
                        disabled: '=?ngDisabled',
                        guidanceIconLocation: '<',
                        guidanceIconFunc: '<',
                        uniqueId: '@',
                    },
                    controller: InputTimeRangeController,
                    require: {
                        formCtrl: '^form',
                    },
                    templateUrl: 'framework/directives/formElements/inputTimeRange/inputTimeRange.html',
                    transclude: true,
                });
            })(Directive = Framework.Directive || (Framework.Directive = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
