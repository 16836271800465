module RemeCare.Shared.Framework {
    export class ToasterType {
        public static success = 'success';
        public static error = 'error';
        public static info = 'info';
        public static warning = 'warning';
    }

    export class Toaster {
        constructor(
            private readonly $translate: ng.translate.ITranslateService,
            private readonly toastr,
            private readonly $log: ng.ILogService
        ) {}

        public pop(type: string, title: string, content?: string, persistent: boolean = false): void {
            const options = persistent
                ? {
                      closeButton: true,
                      timeOut: 0,
                      extendedTimeOut: 0,
                      tapToDismiss: false
                  }
                : {};

            const translatedTitle = this.$translate.instant(title);
            const translatedContent = this.$translate.instant(content);

            switch (type) {
                case ToasterType.success:
                    this.toastr.success(translatedContent, translatedTitle, options);
                    break;
                case ToasterType.error:
                    this.toastr.error(translatedContent, translatedTitle, options);
                    const stackTrace = this.stackTrace();
                    this.$log.debug(
                        translatedTitle +
                            (translatedContent == null ? '' : ': ' + translatedContent) +
                            (stackTrace == null ? '' : '\r\n' + stackTrace)
                    );
                    break;
                case ToasterType.info:
                    this.toastr.info(translatedContent, translatedTitle, options);
                    break;
                case ToasterType.warning:
                    this.toastr.warning(translatedContent, translatedTitle, options);
                    break;
                default:
                    this.toastr.info(translatedContent, translatedTitle, options);
            }
        }

        public success(title: string, content?: string, persistent: boolean = false): void {
            this.pop(ToasterType.success, title, content, persistent);
        }

        public error(title: string, content?: string, persistent: boolean = false): void {
            this.pop(ToasterType.error, title, content, persistent);
        }

        public info(title: string, content?: string, persistent: boolean = false): void {
            this.pop(ToasterType.info, title, content, persistent);
        }

        public warning(title: string, content?: string, persistent: boolean = false): void {
            this.pop(ToasterType.warning, title, content, persistent);
        }

        private stackTrace(): string {
            // Firefox & Chrome only, sorry
            const error = new Error();
            return error.stack;
        }
    }

    // declare global variable 'toastr' and make it an angular value so we can inject it the proper way
    declare var toastr: any;
    angular
        .module('RemeCare.Shared')
        .value('toastr', toastr)
        .factory('toaster', ($translate, toastr, $log) => new Toaster($translate, toastr, $log));
}
