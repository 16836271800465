var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Service;
        (function (Service) {
            var DocumentService = /** @class */ (function (_super) {
                __extends(DocumentService, _super);
                function DocumentService() {
                    return _super !== null && _super.apply(this, arguments) || this;
                }
                DocumentService.prototype.deleteDocument = function (documentId) {
                    var url = 'Documents/' + documentId;
                    return this.delete(url);
                };
                DocumentService.$inject = Shared.Framework.ApiServiceBase.injectionParameters;
                return DocumentService;
            }(RemeCare.Shared.Framework.ApiServiceBase));
            Service.DocumentService = DocumentService;
            Shared.remeCareSharedModule.service('documentSvc', DocumentService);
        })(Service = Shared.Service || (Shared.Service = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
