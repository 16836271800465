var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var AppVersionService = /** @class */ (function (_super) {
                __extends(AppVersionService, _super);
                function AppVersionService() {
                    return _super !== null && _super.apply(this, arguments) || this;
                }
                AppVersionService.prototype.getApplicationInformation = function () {
                    return this.get('CommercialVersion');
                };
                AppVersionService.$inject = Framework.ApiServiceBase.injectionParameters;
                return AppVersionService;
            }(Framework.ApiServiceBase));
            Framework.AppVersionService = AppVersionService;
            Shared.remeCareSharedModule.service('appVersionSvc', AppVersionService);
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
