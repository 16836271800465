var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Contract;
        (function (Contract) {
            var Code;
            (function (Code) {
                var ObjectiveType;
                (function (ObjectiveType) {
                    ObjectiveType[ObjectiveType["Cumulative"] = 1] = "Cumulative";
                    ObjectiveType[ObjectiveType["Average"] = 2] = "Average";
                })(ObjectiveType = Code.ObjectiveType || (Code.ObjectiveType = {}));
            })(Code = Contract.Code || (Contract.Code = {}));
        })(Contract = Shared.Contract || (Shared.Contract = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
