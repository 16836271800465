module RemeCare.Shared.Directive {

    class NgMinDirective implements ng.IDirective {

        restrict = 'A'

        require = 'ngModel'

        isEmpty(value): boolean {
            return angular.isUndefined(value) || value === '' || value === null || value !== value;
        }

        link: ng.IDirectiveLinkFn = (scope, elem, attr: any, ctrl: ng.INgModelController) => {
            scope.$watch(attr.ngMin, () => {
                ctrl.$setViewValue(ctrl.$viewValue);
            });
            var minValidator = (value) => {
                var min = scope.$eval(attr.ngMin) || 0;
                if (!this.isEmpty(value) && value < min) {
                    ctrl.$setValidity('ngMin', false);
                    return undefined;
                } else {
                    ctrl.$setValidity('ngMin', true);
                    return value;
                }
            };

            ctrl.$parsers.push(minValidator);
            ctrl.$formatters.push(minValidator);
        }

    }

    class NgMaxDirective implements ng.IDirective {

        restrict = 'A'

        require = 'ngModel'

        isEmpty(value): boolean {
            return angular.isUndefined(value) || value === '' || value === null || value !== value;
        }

        link: ng.IDirectiveLinkFn = (scope, elem, attr: any, ctrl: ng.INgModelController) => {
            scope.$watch(attr.ngMax, () => {
                ctrl.$setViewValue(ctrl.$viewValue);
            });
            var maxValidator = (value) => {
                var max = scope.$eval(attr.ngMax) || Infinity;
                if (!this.isEmpty(value) && value > max) {
                    ctrl.$setValidity('ngMax', false);
                    return undefined;
                } else {
                    ctrl.$setValidity('ngMax', true);
                    return value;
                }
            };

            ctrl.$parsers.push(maxValidator);
            ctrl.$formatters.push(maxValidator);
        }

    }

    angular.module('RemeCare.Shared')
        .directive('ngMin', () => new NgMinDirective())
        .directive('ngMax', () => new NgMaxDirective());
} 