namespace RemeCare.Shared.Framework.Directive {
    class InputTimeController implements ng.IComponentController {
        public change: () => void;
        constructor(private $timeout: ng.ITimeoutService) {}
        public $onInit(): void {}
        public onTimeChanged(): void {
            if (this.change) {
                this.$timeout(() => {
                    this.change();
                });
            }
        }
    }

    remeCareSharedModule.component('rcInputTime', {
        bindings: {
            label: '@',
            model: '=ngModel',
            required: '=?ngRequired',
            readOnly: '=?ngReadonly',
            disabled: '=?ngDisabled',
            change: '&?ngChange',
            guidanceIconLocation: '<',
            guidanceIconFunc: '<',
            uniqueId: '@',
        },
        controller: InputTimeController,
        require: {
            formCtrl: '^form',
        },
        templateUrl: 'framework/directives/formElements/inputTime/inputTime.html',
        transclude: true,
    });
}
