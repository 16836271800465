var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var TimeStampTextAreaDirective = /** @class */ (function () {
                function TimeStampTextAreaDirective($timeout, $filter, authservice) {
                    var _this = this;
                    this.$timeout = $timeout;
                    this.$filter = $filter;
                    this.authservice = authservice;
                    this.restrict = 'E';
                    this.scope = {
                        model: '=ngModel',
                        required: '=ngRequired',
                        adjustSize: '=',
                        label: '@',
                        textType: '@',
                        labelAbove: '@'
                    };
                    this.templateUrl = 'views/timeStampTextArea.html';
                    this.link = function (scope, element) {
                        scope.textModel = {
                            model: scope.model
                        };
                        scope.addTimestamp = function () {
                            var textArea = element.find('textarea')[0];
                            var cursorPos = scope.getCursorPosition(textArea);
                            var timeStamp = _this.$filter('date')(new Date(), 'short');
                            var text = textArea.value;
                            text = text.slice(0, cursorPos) + '[' + _this.authservice.getNameOfUser() + ' ' + timeStamp + '] ' + text.slice(cursorPos, text.length);
                            scope.textModel.model = text;
                            textArea.focus();
                        };
                        scope.getCursorPosition = function (textArea) {
                            var iCaretPos = 0;
                            if (document.selection) {
                                textArea.focus();
                                var oSel = document.selection.createRange();
                                oSel.moveStart('character', -textArea.value.length);
                                iCaretPos = oSel.text.length;
                            }
                            else if (textArea.selectionStart || textArea.selectionStart == 0) {
                                iCaretPos = textArea.selectionStart;
                            }
                            return iCaretPos;
                        };
                        scope.$watch(function (s) { return s.model; }, function (newValue, oldValue) {
                            if (newValue !== oldValue && newValue !== scope.textModel.model) {
                                scope.textModel.model = newValue;
                            }
                        });
                        scope.$watch(function (s) { return s.textModel.model; }, function (newValue, oldValue) {
                            if (newValue !== oldValue && newValue !== scope.model) {
                                scope.model = newValue;
                            }
                        });
                    };
                }
                return TimeStampTextAreaDirective;
            }());
            angular.module('RemeCare.Shared').directive('rcTimeStampTextArea', function ($timeout, $filter, authservice) { return new TimeStampTextAreaDirective($timeout, $filter, authservice); });
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
