namespace RemeCare.Shared {
    export class StringHelper {
        public static orEmpty(value: string): string {
            return value || '';
        }

        public static joinNonEmpty(separator: string, items: string[]): string {
            return _.filter(items, i => !!i).join(separator);
        }
    }
}
