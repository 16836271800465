module RemeCare.Shared.Framework.Service {

    export interface ISpinner {
        name: string;
        group: string;
        show(): void;
        hide(): void;
        toggle(): void;
    }

    export class SpinnerService {

        private spinningSpinners: { [name: string]: number } = {};

        private spinners: { [name: string]: Array<ISpinner> } = {};

        public register(spinner: ISpinner): void {
            this.spinners[spinner.name] = this.spinners[spinner.name] || [];
            if (!_(this.spinners[spinner.name]).contains(spinner)) {
                this.spinners[spinner.name].push(spinner);
            }
            this.checkSpin(spinner);
        }

        public unregister(spinner: ISpinner): void {
            var index = this.spinners[spinner.name].indexOf(spinner);
            this.spinners[spinner.name].splice(index, 1);
            if (this.spinners[spinner.name].length === 0) {
                delete this.spinners[spinner.name];
            }
        }

        public unregisterAll(): void {
            for (var name in this.spinners) {
                delete this.spinners[name];
            }
        }

        public show(name: string, increment = true): void {
            if (this.spinningSpinners[name] == null) {
                this.spinningSpinners[name] = 1;
            } else if (increment) {
                this.spinningSpinners[name]++;
            }
            var spinners = this.spinners[name];
            if (!spinners) {
                return;
            }
            _(spinners).each(s => s.show());
        }

        public hide(name: string): void {
            if (this.spinningSpinners[name] == null) return;
            this.spinningSpinners[name]--;
            if (this.spinningSpinners[name] !== 0) {
                return;
            }
            delete this.spinningSpinners[name];
            var spinners = this.spinners[name];
            if (!spinners) {
                return;
            }
            _(spinners).each(s => s.hide());
        }

        public showAll(): void {
            for (var name in this.spinners) {
                _(this.spinners[name]).each(s => s.show());
            }
        }

        public hideAll() {
            for (var name in this.spinners) {
                _(this.spinners[name]).each(s => s.hide());
            }
        }

        private checkSpin(spinner: ISpinner): void {
            if (this.spinningSpinners.hasOwnProperty(spinner.name) && this.spinningSpinners[spinner.name]) {
                this.show(spinner.name, false);
                return;
            }
            if (this.spinningSpinners.hasOwnProperty(spinner.group) && this.spinningSpinners[spinner.group]) {
                this.show(spinner.name, false);
                return;
            }
        }
    }

    remeCareSharedModule.factory('spinnerSvc', () => new SpinnerService())
}