var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Contract;
        (function (Contract) {
            var Code;
            (function (Code) {
                var InformationObjectType;
                (function (InformationObjectType) {
                    InformationObjectType[InformationObjectType["Text"] = 1] = "Text";
                    InformationObjectType[InformationObjectType["LinkToFile"] = 2] = "LinkToFile";
                    InformationObjectType[InformationObjectType["InScreenVideo"] = 3] = "InScreenVideo";
                })(InformationObjectType = Code.InformationObjectType || (Code.InformationObjectType = {}));
            })(Code = Contract.Code || (Contract.Code = {}));
        })(Contract = Shared.Contract || (Shared.Contract = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
