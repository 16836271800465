var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Periodicity;
            (function (Periodicity) {
                var TargetPhysicalQuantityRange = Framework.Model.TargetPhysicalQuantityRange;
                var TargetPhysicalQuantityController = /** @class */ (function (_super) {
                    __extends(TargetPhysicalQuantityController, _super);
                    function TargetPhysicalQuantityController($scope, $translate, toaster) {
                        var _this = _super.call(this, $scope, $translate, toaster) || this;
                        $scope.confirm = function () { return _this.confirm(); };
                        $scope.hasLimit = _this.$scope.targetValue instanceof TargetPhysicalQuantityRange;
                        $scope.maxValue = function () { return _this.maxValue(); };
                        return _this;
                    }
                    TargetPhysicalQuantityController.prototype.$onInit = function () { };
                    TargetPhysicalQuantityController.prototype.confirm = function () {
                        if (this.$scope.form.$invalid) {
                            this.showValidationErrorMessage();
                            return;
                        }
                        this.$scope.$close(this.$scope.targetValue);
                    };
                    TargetPhysicalQuantityController.prototype.maxValue = function () {
                        if (this.$scope.hasLimit)
                            return this.$scope.targetValue.upperAmount;
                        else
                            return this.$scope.targetValue.amount;
                    };
                    return TargetPhysicalQuantityController;
                }(Framework.ControllerBase));
                Shared.remeCareSharedModule.controller('targetPhysicalQuantityCtrl', function ($scope, $translate, toaster) { return new TargetPhysicalQuantityController($scope, $translate, toaster); });
            })(Periodicity = Framework.Periodicity || (Framework.Periodicity = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
