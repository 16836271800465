module RemeCare.Shared.Framework.Directive {

    interface ISpinnerScope extends ng.IScope {
        name: string;
        group: string;
        show: boolean;
        size: number;
        viewStateChanged(show: boolean): void;
    }

    class SpinnerController implements ng.IController {

        static $inject = ['$scope', 'spinnerSvc'];
        constructor(
            private $scope: ISpinnerScope,
            private spinnerSvc: Service.SpinnerService) {
            this.$onInit();
        }

        private spinner: Service.ISpinner;

        public $onInit(): void {
            this.spinner = this.constructApi();
            this.spinnerSvc.register(this.spinner);
        }

        public $onDestroy(): void {
            this.spinnerSvc.unregister(this.spinner);
        }

        private constructApi(): Service.ISpinner {
            return {
                name: this.$scope.name,
                group: this.$scope.group,
                show: () => {
                    this.$scope.show = true;
                    if (this.$scope.viewStateChanged) {
                        this.$scope.viewStateChanged(this.$scope.show);
                    }
                },
                hide: () => {
                    this.$scope.show = false;
                    if (this.$scope.viewStateChanged) {
                        this.$scope.viewStateChanged(this.$scope.show);
                    }
                },
                toggle: () => {
                    this.$scope.show = !this.$scope.show;
                    if (this.$scope.viewStateChanged) {
                        this.$scope.viewStateChanged(this.$scope.show);
                    }
                }
            };
        }
    }

    class SpinnerDirective implements ng.IDirective {

        restrict = 'E';

        replace = true;

        transclude = true;

        scope: { [boundProperty: string]: string } = {
            name: '@?',
            group: '@?',
            show: '=?',
            imgSrc: '@?',
            size: '='
        }

        template = [
            '<div>',
            '  <i ng-show="show" class="spinner fa fa-spinner fa-pulse {{ size ? \'fa-\' + size + \'x\' : \'fa-2x\'}} fa-fw" ></i>',
            '</div>'
        ].join('');

        link: ng.IDirectiveLinkFn = (scope: ISpinnerScope, element: ng.IAugmentedJQuery, attrs, ctrl, transclude) => {
            transclude(scope.$parent, (clone) => {
                element.append(clone);
            });
        }

        controller = SpinnerController;
    }

    remeCareSharedModule.directive('rcSpinner', () => new SpinnerDirective());

    class FullPageSpinnerDirective implements ng.IDirective {
        restrict = 'E';

        replace = true;

        transclude = true;

        scope: { [boundProperty: string]: string } = {
            name: '@?',
            group: '@?',
            show: '=?',
            imgSrc: '@?',
            register: '@?'
        }

        template = [
            '<div>',
            '  <div ng-show="show" class="loading" >',
            '    <i class="spinner fa fa-spinner fa-pulse fa-5x fa-fw" ></i>',
            '  </div>',
            '</div>'
        ].join('');

        link: ng.IDirectiveLinkFn = (scope: ISpinnerScope, element: ng.IAugmentedJQuery, attrs, ctrl, transclude) => {
            transclude(scope.$parent, (clone) => {
                element.append(clone);
            });
        }

        controller = SpinnerController;
    }

    remeCareSharedModule.directive('rcFullPageSpinner', () => new FullPageSpinnerDirective());

    interface IBlockingSpinnerScope extends ISpinnerScope {
        height: () => string;
        width: () => string;
    }
    class BlockingSpinnerDirective implements ng.IDirective {
        restrict = 'E';

        replace = true;

        transclude = true;

        scope: { [boundProperty: string]: string } = {
            name: '@?',
            group: '@?',
            show: '=?',
            imgSrc: '@?',
            register: '@?'
        }

        templateUrl = 'views/spinner/blockingSpinner.html';

        link: ng.IDirectiveLinkFn = (scope: IBlockingSpinnerScope, element: ng.IAugmentedJQuery, attrs, ctrl, transclude) => {
            transclude(scope.$parent, (clone) => {
                element.append(clone);
            });
            scope.height = () => {
                return `${element.height()}px`;
            }
            scope.width = () => {
                return `${element.width()}px`;
            }
        }

        controller = SpinnerController;
    }

    remeCareSharedModule.directive('rcBlockingSpinner', () => new BlockingSpinnerDirective());

    class LoaderDirective implements ng.IDirective {

        restrict = 'E';

        replace = true;

        scope: { [boundProperty: string]: string } = {
            name: '@?',
            group: '@?'
        }

        template = [
            '<div ng-show="show" class="loading" >',
            '  <i class="spinner fa fa-spinner fa-pulse fa-5x fa-fw" ></i>',
            '</div>'
        ].join('');

        controller = SpinnerController;
    }

    remeCareSharedModule.directive('rcLoader', () => new LoaderDirective());

    class LoadingButtonDirective implements ng.IDirective {
        restrict = 'A';

        scope: { [boundProperty: string]: string } = {
            name: '@rcLoadingButton'
        }

        controller = SpinnerController;

        link: ng.IDirectiveLinkFn = (scope: ISpinnerScope, element: ng.IAugmentedJQuery, attrs: ng.IAttributes) => {

            const template = '<i ng-show="show" class="fa fa-spinner fa-pulse" style="margin-right: 5px;" />';

            let isShowing: boolean;
            scope.viewStateChanged = (show: boolean) => {
                if (show) {
                    if (isShowing) {
                        return;
                    }
                    isShowing = true;
                    attrs.$set('disabled', 'disabled');
                    element.addClass('disabled');
                    const height = element.height() - 6;
                    const width = element.width() - 6;
                    element.prepend(template);
                    const image = element.children().first();
                    const size = Math.min(height, width);
                    image.height(size);
                } else {
                    if (!isShowing) {
                        return;
                    }
                    isShowing = false;
                    element.removeAttr('disabled');
                    element.removeClass('disabled');
                    element.children().first().remove();
                }
            };
        }
    }

    remeCareSharedModule.directive('rcLoadingButton', () => new LoadingButtonDirective());
}