var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Contract;
        (function (Contract) {
            var Read;
            (function (Read) {
                var TherapyActionRelationType;
                (function (TherapyActionRelationType) {
                    TherapyActionRelationType[TherapyActionRelationType["PreviousRegistration"] = 3] = "PreviousRegistration";
                })(TherapyActionRelationType = Read.TherapyActionRelationType || (Read.TherapyActionRelationType = {}));
            })(Read = Contract.Read || (Contract.Read = {}));
        })(Contract = Shared.Contract || (Shared.Contract = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
