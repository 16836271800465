var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Contract;
        (function (Contract) {
            var Code;
            (function (Code) {
                var PartyRoleType;
                (function (PartyRoleType) {
                    PartyRoleType[PartyRoleType["Patient"] = 1] = "Patient";
                    PartyRoleType[PartyRoleType["HealthCareOrganisation"] = 2] = "HealthCareOrganisation";
                    PartyRoleType[PartyRoleType["HealthCareProfessional"] = 3] = "HealthCareProfessional";
                    PartyRoleType[PartyRoleType["HealthCareNonProfessional"] = 4] = "HealthCareNonProfessional";
                    PartyRoleType[PartyRoleType["ContactPerson"] = 5] = "ContactPerson";
                    PartyRoleType[PartyRoleType["Employee"] = 6] = "Employee";
                })(PartyRoleType = Code.PartyRoleType || (Code.PartyRoleType = {}));
            })(Code = Contract.Code || (Contract.Code = {}));
        })(Contract = Shared.Contract || (Shared.Contract = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
