namespace RemeCare.Shared.Framework {
    class UserUnknownController implements ng.IComponentController {
        public remedusInfoMail: string;
        public remedusPhone: string;

        constructor(
            private readonly $state: ng.ui.IStateService,
            private readonly appConfig: Shared.Contract.IAppConfig
        ) {}

        public $onInit(): void {
            this.remedusInfoMail = this.appConfig.remedusInfoMail;
            this.remedusPhone = this.appConfig.remedusPhone;
        }

        public requestAccess(): void {
            this.$state.go('selfservice.requestUserAccount');
        }
    }

    remeCareSharedModule.component('rcUserUnknown', {
        controller: UserUnknownController,
        templateUrl: 'framework/components/userUnknown/userUnknown.html',
    });
}
