var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Directive;
            (function (Directive) {
                var InputPasswordController = /** @class */ (function () {
                    function InputPasswordController() {
                    }
                    InputPasswordController.prototype.$onInit = function () { };
                    InputPasswordController.prototype.validate = function (value) {
                        this.passwordsMatch = !this.sameAs || !value || value === this.sameAs;
                        return this.passwordsMatch;
                    };
                    InputPasswordController.passwordMatch = 'passwordMatch';
                    return InputPasswordController;
                }());
                Shared.remeCareSharedModule.component('rcInputPassword', {
                    bindings: {
                        label: '@',
                        matchError: '@',
                        model: '=ngModel',
                        sameAs: '<?sameAs',
                        guidanceIconLocation: '<',
                        guidanceIconFunc: '<',
                        uniqueId: '@',
                    },
                    controller: InputPasswordController,
                    require: {
                        formCtrl: '^form',
                    },
                    templateUrl: 'views/formElements/inputPassword.html',
                });
            })(Directive = Framework.Directive || (Framework.Directive = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
