'use strict';
var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var RouteConfiguration = /** @class */ (function () {
                function RouteConfiguration($stateProvider) {
                    this.$stateProvider = $stateProvider;
                    this.snakeCaseRegexp = /[A-Z]/g;
                    this.config();
                }
                /** @deprecated */
                RouteConfiguration.prototype.addRoute = function (state, url, template, controller, translationSets, resolveFunctions, reloadOnSearch, permissions, config, controllerAs) {
                    var resolve = {};
                    if (resolveFunctions != null) {
                        resolve = resolveFunctions;
                    }
                    resolve.trans = [
                        'translationResolver',
                        function (translationResolver) {
                            return translationResolver.setParts(translationSets);
                        },
                    ];
                    reloadOnSearch = reloadOnSearch == null ? true : reloadOnSearch;
                    config = config || {};
                    config.url = url;
                    if (_.isFunction(template)) {
                        config.template = template;
                    }
                    else {
                        config.templateUrl = template;
                    }
                    config.controller = controller;
                    config.controllerAs = controllerAs;
                    config.resolve = resolve;
                    config.reloadOnSearch = reloadOnSearch;
                    config.data = config.data || {};
                    config.data.permissions = permissions;
                    config.data.allowAnonymous = _.isEmpty(permissions);
                    this.$stateProvider.state(state, config);
                };
                /** @deprecated */
                RouteConfiguration.prototype.addComponentRoute = function (state, url, template, translationSets, resolveFunctions, reloadOnSearch, permissions, config) {
                    var resolve = {};
                    if (resolveFunctions != null) {
                        resolve = resolveFunctions;
                    }
                    resolve.trans = [
                        'translationResolver',
                        function (translationResolver) {
                            return translationResolver.setParts(translationSets);
                        },
                    ];
                    reloadOnSearch = reloadOnSearch == null ? true : reloadOnSearch;
                    config = config || {};
                    config.url = url;
                    if (_.isFunction(template)) {
                        config.template = template;
                    }
                    else {
                        var component = this.snakeCase(template, '-');
                        config.template = "<" + component + "><" + component + "/>";
                    }
                    config.resolve = resolve;
                    config.reloadOnSearch = reloadOnSearch;
                    config.data = config.data || {};
                    config.data.permissions = permissions;
                    config.data.allowAnonymous = _.isEmpty(permissions);
                    this.$stateProvider.state(state, config);
                };
                /** @deprecated */
                RouteConfiguration.prototype.add = function (state, config, translationSets, permissions) {
                    config = config || {};
                    config.reloadOnSearch = config.reloadOnSearch == null ? true : config.reloadOnSearch;
                    config.resolve = config.resolve || {};
                    config.resolve.trans = [
                        'translationResolver',
                        function (translationResolver) { return translationResolver.setParts(translationSets); },
                    ];
                    config.data = config.data || {};
                    config.data.permissions = permissions;
                    config.data.allowAnonymous = _.isEmpty(permissions);
                    this.$stateProvider.state(state, config);
                };
                RouteConfiguration.prototype.addGeneric = function (state, config, translationSets, permissions) {
                    config = config || {};
                    config.reloadOnSearch = config.reloadOnSearch == null ? true : config.reloadOnSearch;
                    config.resolve = config.resolve || {};
                    config.resolve.trans = [
                        'translationResolver',
                        function (translationResolver) { return translationResolver.setParts(translationSets); },
                    ];
                    config.data = config.data || {};
                    config.data.permissions = permissions;
                    config.data.allowAnonymous = _.isEmpty(permissions);
                    this.$stateProvider.state(state, config);
                };
                RouteConfiguration.prototype.snakeCase = function (name, separator) {
                    separator = separator || '_';
                    return name.replace(this.snakeCaseRegexp, function (letter, pos) {
                        return (pos ? separator : '') + letter.toLowerCase();
                    });
                };
                return RouteConfiguration;
            }());
            Framework.RouteConfiguration = RouteConfiguration;
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
