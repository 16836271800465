module RemeCare.Shared.Framework.Model {
    import EntityTranslation = Shared.Contract.IEntityTranslation;
    import EnumTranslation = Shared.Contract.IEnumTranslation;
    import Guid = Shared.Contract.Guid;
    import Version = Shared.Framework.Model.Version;
    import CodeSetItem = Shared.Contract.IChoiceListOption;

    export class RuleThresholdDefinition {
        id: Guid;

        therapyId: Guid;
        name: string;
        isPersonalisable: boolean;
        sequence: number;

        characteristic: EntityTranslation;
        unit: EntityTranslation;

        observableEntity: EntityTranslation;
        codeSet: EntityTranslation;

        ruleThresholdValues: Array<RuleThresholdValue>;

        readOnly: boolean;

        constructor(serverObject?: Shared.Contract.Read.IRuleThresholdDefinition) {
            if (serverObject != null) {
                this.id = serverObject.Id;
                this.therapyId = serverObject.TherapyId;
                this.name = serverObject.Name;
                this.isPersonalisable = serverObject.IsPersonalisable;
                this.sequence = serverObject.Sequence;
                this.characteristic = serverObject.Characteristic;
                this.unit = serverObject.Unit;
                this.observableEntity = serverObject.ObservableEntity;
                this.codeSet = serverObject.CodeSet;
                this.ruleThresholdValues = _(serverObject.RuleThresholdValues).map(x => new RuleThresholdValue(x));
                this.readOnly = serverObject.ReadOnly;
            } else {
                this.ruleThresholdValues = [];
            }
        }

        public getCurrentRuleThresholdValue(): RuleThresholdValue {
            var currentDate = new Date();
            var result = _(this.ruleThresholdValues).find(x => (moment(x.validFromDate).isBefore(currentDate) && (moment(x.validUntilDate).isAfter(currentDate) || x.validUntilDate == null))
                || moment().isSame(x.validFromDate, 'day') || moment().isSame(x.validUntilDate, 'day'));
            return result ? result : null;
        }

        public getCurrentRuleThresholdValueString(): string {
            var currentDate = new Date();
            var lastValue = _(this.ruleThresholdValues).find(x => (moment(x.validFromDate).isBefore(currentDate) && (moment(x.validUntilDate).isAfter(currentDate) || x.validUntilDate == null))
                || moment().isSame(x.validFromDate, 'day') || moment().isSame(x.validUntilDate, 'day'));
            return lastValue ? lastValue.getValueString() : null;
        }

        public getLastRuleThresholdValue(): RuleThresholdValue {
            var maxDate = _(this.ruleThresholdValues).max(x => x.validFromDate).validFromDate;
            return _(this.ruleThresholdValues).find(x => moment(x.validFromDate).isSame(maxDate));
        }

        public getPreviousRuleThresholdValue(value: RuleThresholdValue): RuleThresholdValue {
            var values = _(this.ruleThresholdValues).filter(v => moment(value.validFromDate).add(-1, 'day').isSame(v.validUntilDate));
            return _(values).size() !== 0 ? _(values).last() : null;
        }

        public addRuleThresholdValue(value: RuleThresholdValue): void {
            if (!_(this.ruleThresholdValues).isEmpty()) {
                var previous = this.getLastRuleThresholdValue();
                previous.validUntilDate = moment(value.validFromDate).subtract(1, 'Day').toDate();
            } else {
                value.validFromDate = new Date();
            }
            value.validUntilDate = null;
            this.ruleThresholdValues.push(value);
        }

        public editRuleThresholdValue(thresholdValue: RuleThresholdValue, newValidFromDate: Date, newValidUntilDate: Date): RuleThresholdValue {
            var previous = _(this.ruleThresholdValues).find(x => moment(x.validUntilDate).isSame(moment(thresholdValue.validFromDate).subtract(1, 'Day').toDate()));
            if (previous) {
                previous.validUntilDate = moment(newValidFromDate).subtract(1, 'Day').toDate();
            }
            thresholdValue.validFromDate = newValidFromDate;
            thresholdValue.validUntilDate = newValidUntilDate;
            return thresholdValue;
        }

        public deleteRuleThresholdValue(thresholdValue: RuleThresholdValue): void {
            var previous = _(this.ruleThresholdValues).find(x => moment(x.validUntilDate).isSame(moment(thresholdValue.validFromDate).subtract(1, 'Day').toDate()));
            previous.validUntilDate = null;
            this.ruleThresholdValues = _(this.ruleThresholdValues).filter(x => x.validFromDate != thresholdValue.validFromDate);
        }

        public toServerWrite(): Shared.Contract.Write.IRuleThresholdDefinition {
            _(this.ruleThresholdValues).filter(x => x !== this.getLastRuleThresholdValue()).map(v => v.validUntilDate = null);

            var result = <Shared.Contract.Write.IRuleThresholdDefinition>{
                Id: this.id,
                Name: this.name,

                IsPersonalisable: this.isPersonalisable,
                Sequence: this.sequence,

                UnitId: this.unit ? this.unit.Id : null,
                CharacteristicId: this.characteristic ? this.characteristic.Id : null,

                CodeSetId: this.codeSet ? this.codeSet.Id : null,
                ObservableEntityId: this.observableEntity ? this.observableEntity.Id : null,

                RuleThresholdValues: _(this.ruleThresholdValues).map(x => x.toServerWrite()),

                TherapyId: this.therapyId
            };

            return result;
        }

        public hasFutureValue(): boolean {
            return _(this.ruleThresholdValues).any(x => moment(x.validFromDate).isAfter(moment()))
        }
    }

    export class RuleThresholdValue extends Version {
        id: Guid;

        amount: number;
        codeSetItem: CodeSetItem;

        isPersonalised: boolean;

        constructor(serverObject?: Shared.Contract.Read.IRuleThresholdValue) {
            super();
            if (serverObject != null) {
                this.id = serverObject.Id;
                this.amount = serverObject.Amount;
                this.codeSetItem = serverObject.CodeSetItem;
                this.validFromDate = Shared.DateHelper.serverDateStringToDate(serverObject.ValidFromDate);
                this.validUntilDate = Shared.DateHelper.serverDateStringToDate(serverObject.ValidUntilDate);
                this.isPersonalised = serverObject.IsPersonalised;
            }
        }

        public getValueString(): string {
            if (this.codeSetItem) {
                return this.codeSetItem.Name + ' (' + this.codeSetItem.NumericValue + ')';
            } else
                return this.amount.toString();
        }

        public toServerWrite(): Shared.Contract.Write.IRuleThresholdValue {
            var result = <Shared.Contract.Write.IRuleThresholdValue>{
                Id: this.id,
                Amount: this.amount,
                CodeSetItemId: this.codeSetItem ? this.codeSetItem.Id : null,
                ValidFromDate: Shared.DateHelper.toServerDateString(this.validFromDate)
            }

            if (this.validUntilDate !== null) {
                result.ValidUntilDate = result.ValidUntilDate = Shared.DateHelper.toServerDateString(this.validUntilDate);
            }

            return result;
        }
    }
}