module RemeCare.Shared.Contract.Code {
    
    export enum SimplifiedRecurrence {
        XTimesPerDay = 1,
        XTimesEveryYDays = 2,
        EveryOtherDay = 3,
        WeekSchedule = 4,
        Other = 5,
        AdHoc = 6
    }
}