namespace RemeCare.Shared.Framework.Directive {
    class InputPasswordController implements ng.IComponentController {
        private static passwordMatch = 'passwordMatch';

        public sameAs: string;

        public passwordsMatch: boolean;

        public $onInit(): void {}

        public validate(value: string): boolean {
            this.passwordsMatch = !this.sameAs || !value || value === this.sameAs;
            return this.passwordsMatch;
        }
    }

    remeCareSharedModule.component('rcInputPassword', {
        bindings: {
            label: '@',
            matchError: '@',
            model: '=ngModel',
            sameAs: '<?sameAs',
            guidanceIconLocation: '<',
            guidanceIconFunc: '<',
            uniqueId: '@',
        },
        controller: InputPasswordController,
        require: {
            formCtrl: '^form',
        },
        templateUrl: 'views/formElements/inputPassword.html',
    });
}
