var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Contract;
        (function (Contract) {
            var Code;
            (function (Code) {
                var MonitoringPartSourceType;
                (function (MonitoringPartSourceType) {
                    MonitoringPartSourceType[MonitoringPartSourceType["MonitoringPartSourceAction"] = 1] = "MonitoringPartSourceAction";
                    MonitoringPartSourceType[MonitoringPartSourceType["QualitativeMeasuringPointParameter"] = 2] = "QualitativeMeasuringPointParameter";
                    MonitoringPartSourceType[MonitoringPartSourceType["QuantitativeMeasuringPointParameter"] = 3] = "QuantitativeMeasuringPointParameter";
                    MonitoringPartSourceType[MonitoringPartSourceType["QualitativeReferenceParameterAnamnesis"] = 4] = "QualitativeReferenceParameterAnamnesis";
                    MonitoringPartSourceType[MonitoringPartSourceType["QuantitativeReferenceParameterAnamnesis"] = 5] = "QuantitativeReferenceParameterAnamnesis";
                    MonitoringPartSourceType[MonitoringPartSourceType["ReferenceParameterThreshold"] = 6] = "ReferenceParameterThreshold";
                    MonitoringPartSourceType[MonitoringPartSourceType["ReferenceParameterObjective"] = 7] = "ReferenceParameterObjective";
                    MonitoringPartSourceType[MonitoringPartSourceType["ExternalDataSourceParameter"] = 8] = "ExternalDataSourceParameter";
                    MonitoringPartSourceType[MonitoringPartSourceType["MonitoringPartSourceFilter"] = 9] = "MonitoringPartSourceFilter";
                })(MonitoringPartSourceType = Code.MonitoringPartSourceType || (Code.MonitoringPartSourceType = {}));
            })(Code = Contract.Code || (Contract.Code = {}));
        })(Contract = Shared.Contract || (Shared.Contract = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
