var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var InformedConsent;
            (function (InformedConsent) {
                var ApproveModalController = /** @class */ (function () {
                    function ApproveModalController() {
                    }
                    ApproveModalController.prototype.$onInit = function () {
                        this.askApproval = true;
                        this.minDate = Shared.DateHelper.addDuration(this.minDate, {
                            Quantity: 1,
                            Unit: Shared.Contract.Code.DurationUnit.Days
                        });
                        if (this.minDate < Shared.DateHelper.tomorrow()) {
                            this.minDate = Shared.DateHelper.tomorrow();
                        }
                    };
                    ApproveModalController.prototype.confirm = function (confirmation) {
                        this.needsConfirmation = confirmation;
                        this.$close({ result: { needsConfirmation: this.needsConfirmation, date: this.startDate } });
                    };
                    ApproveModalController.prototype.approve = function () {
                        this.form.$setSubmitted();
                        if (this.form.$valid) {
                            this.askApproval = false;
                        }
                    };
                    return ApproveModalController;
                }());
                Shared.remeCareSharedModule.component('rcApproveModal', {
                    bindings: {
                        $close: '&',
                        $dismiss: '&',
                        minDate: '<'
                    },
                    controller: ApproveModalController,
                    templateUrl: 'framework/informedConsent/approveModal/approveModal.html'
                });
            })(InformedConsent = Framework.InformedConsent || (Framework.InformedConsent = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
