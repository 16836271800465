var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Directive;
            (function (Directive) {
                var TextAreaDirective = /** @class */ (function () {
                    function TextAreaDirective($filter, authservice) {
                        var _this = this;
                        this.$filter = $filter;
                        this.authservice = authservice;
                        this.restrict = 'E';
                        this.require = '^form';
                        this.scope = {
                            model: '=ngModel',
                            required: '=ngRequired',
                            label: '@',
                            textType: '@',
                            labelAbove: '@',
                            readOnly: '=ngReadonly',
                            timestamp: '=',
                            disabled: '=ngDisabled',
                            guidanceIconLocation: '<',
                            guidanceIconFunc: '<',
                            uniqueId: '@',
                        };
                        this.templateUrl = 'views/formElements/textArea.html';
                        this.link = function (scope, element, attrs, formCtrl) {
                            scope.formCtrl = formCtrl;
                            scope.rows = attrs.rows ? parseInt(attrs.rows) : 3;
                            scope.labelAbove = attrs.labelAbove;
                            if (attrs.maxLength) {
                                scope.maxLength = parseInt(attrs.maxLength);
                            }
                            else {
                                switch (scope.textType) {
                                    case Directive.TextLength.extraShort:
                                        scope.maxLength = 100;
                                        break;
                                    case Directive.TextLength.short:
                                        scope.maxLength = 200;
                                        break;
                                    case Directive.TextLength.medium:
                                        scope.maxLength = 400;
                                        break;
                                    case Directive.TextLength.long:
                                        scope.maxLength = 1000;
                                        break;
                                    case Directive.TextLength.extraLong:
                                        scope.maxLength = 4000;
                                        break;
                                    default:
                                        scope.maxLength = 400;
                                        break;
                                }
                            }
                            var getCursorPosition = function (textArea) {
                                var iCaretPos = 0;
                                if (document.selection) {
                                    textArea.focus();
                                    var oSel = document.selection.createRange();
                                    oSel.moveStart('character', -textArea.value.length);
                                    iCaretPos = oSel.text.length;
                                }
                                else if (textArea.selectionStart || textArea.selectionStart === '0') {
                                    iCaretPos = textArea.selectionStart;
                                }
                                return iCaretPos;
                            };
                            scope.addTimestamp = function () {
                                if (scope.readOnly) {
                                    return;
                                }
                                var textArea = element.find('textarea')[0];
                                var cursorPos = getCursorPosition(textArea);
                                var timeStamp = _this.$filter('date')(new Date(), 'short');
                                var text = textArea.value;
                                text =
                                    text.slice(0, cursorPos) +
                                        '[' +
                                        _this.authservice.getClaim(Framework.ClaimTypes.firstname) +
                                        ' ' +
                                        _this.authservice.getClaim(Framework.ClaimTypes.lastname) +
                                        ' ' +
                                        timeStamp +
                                        '] ' +
                                        text.slice(cursorPos, text.length);
                                if (text.length > 3000) {
                                    text = text.slice(0, 3000);
                                }
                                scope.model = text;
                                textArea.focus();
                            };
                        };
                    }
                    return TextAreaDirective;
                }());
                Shared.remeCareSharedModule.directive('rcTextArea', function ($filter, authservice) { return new TextAreaDirective($filter, authservice); });
            })(Directive = Framework.Directive || (Framework.Directive = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
