var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Grid;
            (function (Grid) {
                var SearchGridTab = /** @class */ (function () {
                    function SearchGridTab() {
                    }
                    return SearchGridTab;
                }());
                Grid.SearchGridTab = SearchGridTab;
                var SearchGridController = /** @class */ (function () {
                    function SearchGridController($transclude) {
                        this.$transclude = $transclude;
                    }
                    SearchGridController.prototype.$onInit = function () {
                        this.advancedFiltersCollapsed = true;
                        this.hasAdvancedFilter = this.$transclude.isSlotFilled('advancedFilter');
                        if (this.activeTab === undefined) {
                            this.activeTab = 0;
                        }
                        if (this.hideCriteria === undefined) {
                            this.hideCriteria = false;
                        }
                        if (this.hideCriteria) {
                            this.search();
                        }
                    };
                    SearchGridController.prototype.search = function () {
                        this.grid.pagingOptions.currentPage = 1;
                        this.grid.search();
                    };
                    SearchGridController.prototype.toggleAdvancedFilters = function () {
                        this.advancedFiltersCollapsed = !this.advancedFiltersCollapsed;
                    };
                    SearchGridController.prototype.useTabs = function () {
                        return this.tabs !== undefined && this.tabs.length > 0;
                    };
                    SearchGridController.prototype.tabSelected = function (tab) {
                        if (tab.tabActivated !== undefined) {
                            tab.tabActivated();
                        }
                    };
                    SearchGridController.prototype.clearCriteria = function () {
                        this.grid.clearCriteria();
                        if (this.useTabs()) {
                            this.tabSelected(this.tabs[this.activeTab]);
                        }
                    };
                    return SearchGridController;
                }());
                Shared.remeCareSharedModule.component('rcSearchGrid', {
                    bindings: {
                        grid: '=',
                        header: '@?',
                        hideCriteria: '<?',
                        progressBar: '@?',
                        showTitle: '<?',
                        activeTab: '<?',
                        tabs: '<?',
                    },
                    controller: SearchGridController,
                    template: [
                        '$attrs',
                        '$templateCache',
                        function ($attrs, $templateCache) {
                            var template = $templateCache.get('framework/grid/searchGrid/searchGrid.html');
                            if (!('expandable' in $attrs)) {
                                template = template.replace('expandable', '');
                            }
                            return template;
                        },
                    ],
                    transclude: {
                        filter: '?filter',
                        advancedFilter: '?advancedFilter',
                    },
                });
            })(Grid = Framework.Grid || (Framework.Grid = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
