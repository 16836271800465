var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Contract;
        (function (Contract) {
            var Code;
            (function (Code) {
                var InformedConsentHistoryType;
                (function (InformedConsentHistoryType) {
                    InformedConsentHistoryType[InformedConsentHistoryType["Rejected"] = 0] = "Rejected";
                    InformedConsentHistoryType[InformedConsentHistoryType["Accepted"] = 1] = "Accepted";
                    InformedConsentHistoryType[InformedConsentHistoryType["Updated"] = 2] = "Updated";
                })(InformedConsentHistoryType = Code.InformedConsentHistoryType || (Code.InformedConsentHistoryType = {}));
            })(Code = Contract.Code || (Contract.Code = {}));
        })(Contract = Shared.Contract || (Shared.Contract = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
