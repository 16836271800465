var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Model;
            (function (Model) {
                var InformedConsentQuestionTranslation = /** @class */ (function () {
                    function InformedConsentQuestionTranslation(sequence, title) {
                        this.sequence = sequence;
                        this.question = title;
                    }
                    return InformedConsentQuestionTranslation;
                }());
                Model.InformedConsentQuestionTranslation = InformedConsentQuestionTranslation;
            })(Model = Framework.Model || (Framework.Model = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
