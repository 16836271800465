module RemeCare.Shared.Framework.Service {

    class HttpRequestInterceptor implements ng.IHttpInterceptor {

        public request: (request: ng.IRequestConfig) => ng.IRequestConfig;

        constructor(
            private $location: ng.ILocationService) {
            this.request = r => this.req(r);
        }

        private req(request: ng.IRequestConfig): ng.IRequestConfig {
            request.headers['X-AppStateUrl'] = this.$location.absUrl();
            return request;
        }
    }

    remeCareSharedModule.factory('httpRequestInterceptor', ($location: ng.ILocationService) => new HttpRequestInterceptor($location));
}