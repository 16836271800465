var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var InformedConsent;
            (function (InformedConsent) {
                var DisplayPatientInformedConsentController = /** @class */ (function () {
                    function DisplayPatientInformedConsentController() {
                    }
                    DisplayPatientInformedConsentController.prototype.$onInit = function () {
                    };
                    return DisplayPatientInformedConsentController;
                }());
                Shared.remeCareSharedModule.component('rcDisplayPatientInformedConsent', {
                    controller: DisplayPatientInformedConsentController,
                    templateUrl: 'framework/informedConsent/displayPatientInformedConsent/displayPatientInformedConsent.html',
                    bindings: {
                        informedConsent: '<',
                        readOnly: '<'
                    },
                });
            })(InformedConsent = Framework.InformedConsent || (Framework.InformedConsent = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
