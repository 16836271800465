'use strict';

namespace RemeCare.Shared.Contract {
    export interface ICodeSetItem {
        CodeSetTypeCodeSetItemId: Guid;
        CodeSetItemId: Guid;
        CodeSetTypeId: Guid;
        NumericValue: number;
        Text: string;
        Icon: string;
    }

    export interface IChoiceListOption {
        Id: Guid;
        Icon: string;
        Name: string;
        NumericValue: number;
        Sequence: number;
    }
}
