var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Contract;
        (function (Contract) {
            var Code;
            (function (Code) {
                var GuidanceStepType;
                (function (GuidanceStepType) {
                    GuidanceStepType[GuidanceStepType["Text"] = 1] = "Text";
                    GuidanceStepType[GuidanceStepType["Image"] = 2] = "Image";
                    GuidanceStepType[GuidanceStepType["Video"] = 3] = "Video";
                    GuidanceStepType[GuidanceStepType["File"] = 4] = "File";
                    GuidanceStepType[GuidanceStepType["RichText"] = 5] = "RichText";
                })(GuidanceStepType = Code.GuidanceStepType || (Code.GuidanceStepType = {}));
            })(Code = Contract.Code || (Contract.Code = {}));
        })(Contract = Shared.Contract || (Shared.Contract = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
