module RemeCare.Shared.Framework.Model {
    export enum Change {
        None = 0,
        Added = 1,
        Deleted = 2,
        Modified = 3
    }

    export interface IChange {
        change: Change;
    }
}
