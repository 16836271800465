namespace RemeCare.Shared.Framework.Factory {
    import EnumTranslation = Shared.Contract.IEnumTranslation;
    import EntityTranslation = Shared.Contract.IEntityTranslation;
    import MonitoringPartSourceAction = Framework.Model.MonitoringPartSourceAction;
    import MonitoringPartSourceFilter = Framework.Model.MonitoringPartSourceFilter;
    import MonitoringPartSourceType = Shared.Contract.Code.MonitoringPartSourceType;
    import MonitoringPartType = Shared.Contract.Code.MonitoringPartType;

    export class MonitoringPartSummaryFactory {
        constructor(
            private readonly masterdataSvc: Framework.MasterdataService,
            private readonly toaster: Framework.Toaster
        ) {}

        public async createManyAsync(
            therapyId: Shared.Contract.Guid,
            serverObjects: Shared.Contract.Read.IMonitoringPartSummary[]
        ): Promise<Model.MonitoringPartSummary[]> {
            if (!serverObjects || !serverObjects.length) {
                return [];
            }
            const sources = _(serverObjects)
                .chain()
                .map((so) => so.MonitoringPartSources)
                .flatten()
                .value();
            const characteristicIds = _(sources)
                .chain()
                .map((s) => s.CharacteristicId)
                .filter((c) => c != null)
                .uniq()
                .value();
            const observableEntityIds = _(sources)
                .chain()
                .map((s) => s.ObservableEntityId)
                .filter((c) => c != null)
                .uniq()
                .value();
            const ruleThesholdIds = _(sources)
                .chain()
                .map((s) => s.RuleThresholdId)
                .filter((c) => c != null)
                .uniq()
                .value();
            const objectiveIds = _(sources)
                .chain()
                .map((s) => s.ObjectiveId)
                .filter((c) => c != null)
                .uniq()
                .value();

            const observableEntitiesPromise = observableEntityIds.length
                ? this.masterdataSvc.getObservableEntitiesAsync(observableEntityIds)
                : this.getEmptyPromise<EntityTranslation>();
            const characteristicPromise = characteristicIds.length
                ? this.masterdataSvc.getCharacteristicsAsync(characteristicIds, true)
                : this.getEmptyPromise<EntityTranslation>();
            const ruleThresholdPromise = ruleThesholdIds.length
                ? this.masterdataSvc.getRuleThresholdsAsync(therapyId, ruleThesholdIds)
                : this.getEmptyPromise<Contract.Read.IRuleThresholdDefinition>();
            const objectivePromise = objectiveIds.length
                ? this.masterdataSvc.getObjectivesAsync(therapyId, objectiveIds)
                : this.getEmptyPromise<Contract.Read.IObjectiveDefinition>();
            const monitoringPartTypesPromise = this.masterdataSvc.getMonitoringPartTypesAsync();
            const partWidthsPromise = this.masterdataSvc.getPartWidthsAsync();

            try {
                const [
                    observableEntities,
                    characteristics,
                    ruleThresholdsResult,
                    objectivesResult,
                    monitoringPartTypes,
                    partWidths,
                ]: [
                    EntityTranslation[],
                    EntityTranslation[],
                    Contract.Read.IRuleThresholdDefinition[],
                    Contract.Read.IObjectiveDefinition[],
                    EnumTranslation[],
                    EnumTranslation[]
                ] = await Promise.all([
                    observableEntitiesPromise,
                    characteristicPromise,
                    ruleThresholdPromise,
                    objectivePromise,
                    monitoringPartTypesPromise,
                    partWidthsPromise,
                ]);

                return _(serverObjects).map((so) =>
                    this.create(
                        so,
                        characteristics,
                        observableEntities,
                        ruleThresholdsResult,
                        objectivesResult,
                        monitoringPartTypes,
                        partWidths
                    )
                );
            } catch (e) {
                this.toaster.error(e);
            }
        }

        private getEmptyPromise<TPromise>(): Promise<TPromise[]> {
            return new Promise<TPromise[]>((resolve) => resolve([]));
        }

        private create(
            serverObject: Shared.Contract.Read.IMonitoringPartSummary,
            characteristics: EntityTranslation[],
            observableEntities: EntityTranslation[],
            ruleThresholds: Shared.Contract.Read.IRuleThresholdDefinition[],
            objectives: Shared.Contract.Read.IObjectiveDefinition[],
            monitoringPartTypes: EnumTranslation[],
            partWidths: EnumTranslation[]
        ): Model.MonitoringPartSummary {
            const result = new Model.MonitoringPartSummary();
            result.id = serverObject.Id;
            result.sequence = serverObject.Sequence;
            result.type = _.find(monitoringPartTypes, (m) => m.Id === serverObject.Type);
            result.width = _.find(partWidths, (p) => p.Id === serverObject.Width);
            result.assignedGroup = serverObject.AssignedGroup;
            result.isMobile = serverObject.IsMobile;

            const characteristicIds = _(serverObject.MonitoringPartSources)
                .chain()
                .map((mps) => mps.CharacteristicId)
                .filter((c) => c != null)
                .value();
            const observableEntityIds = _(serverObject.MonitoringPartSources)
                .chain()
                .map((mps) => mps.ObservableEntityId)
                .filter((o) => o != null)
                .value();
            const ruleThresholdIds = _(serverObject.MonitoringPartSources)
                .chain()
                .map((mps) => mps.RuleThresholdId)
                .filter((r) => r != null)
                .value();
            const objectiveIds = _(serverObject.MonitoringPartSources)
                .chain()
                .map((mps) => mps.ObjectiveId)
                .filter((o) => o != null)
                .value();

            result.parameters = _([])
                .chain()
                .union(_(characteristics).filter((c) => _(characteristicIds).contains(c.Id)))
                .union(_(observableEntities).filter((o) => _(observableEntityIds).contains(o.Id)))
                .union(
                    _(ruleThresholds)
                        .chain()
                        .filter((r) => _(ruleThresholdIds).contains(r.Id))
                        .map((r) => {
                            return { Id: r.Id, Text: r.Name };
                        })
                        .filter((c) => c != null)
                        .value()
                )
                .union(
                    _(objectives)
                        .chain()
                        .filter((r) => _(objectiveIds).contains(r.Id))
                        .map((r) => {
                            return { Id: r.Id, Text: r.Name };
                        })
                        .filter((c) => c != null)
                        .value()
                )
                .value();
            return result;
        }
    }

    remeCareSharedModule.service('monitoringPartSummaryFactory', MonitoringPartSummaryFactory);

    export interface IMonitoringPartFactory {
        createAsync(
            therapyId: Shared.Contract.Guid,
            serverObject: Shared.Contract.Read.IMonitoringPart[]
        ): Promise<Model.MonitoringPart[]>;
        createFromType(type: MonitoringPartType): Model.MonitoringPart;
    }

    class MonitoringPartFactory implements IMonitoringPartFactory {
        constructor(private readonly monitoringPartSourceFactory: IMonitoringPartSourceFactory) {}

        public async createAsync(
            therapyId: Shared.Contract.Guid,
            serverObjects: Shared.Contract.Read.IMonitoringPart[]
        ): Promise<Model.MonitoringPart[]> {
            if (!serverObjects) {
                return Promise.resolve([]);
            }

            const mpmpss = await this.monitoringPartSourceFactory.createAllAsync(therapyId, serverObjects);
            return _.map(serverObjects, (mp) => {
                const monitoringPart = this.createPart(mp.Type, mp);
                const monitoringPartMonitoringPartSources = _.find(mpmpss, (mpmps) => mpmps.monitoringPartId === mp.Id);
                monitoringPart.monitoringPartSources = monitoringPartMonitoringPartSources
                    ? monitoringPartMonitoringPartSources.monitoringPartSources
                    : [];
                if (monitoringPart.type === MonitoringPartType.MedicationDoseChart) {
                    const actionSource = _.find(
                        monitoringPart.monitoringPartSources,
                        (mps) => mps.type === MonitoringPartSourceType.MonitoringPartSourceAction
                    ) as MonitoringPartSourceAction;
                    actionSource.filter = _.find(
                        monitoringPart.monitoringPartSources,
                        (mps) => mps.type === MonitoringPartSourceType.MonitoringPartSourceFilter
                    ) as MonitoringPartSourceFilter;
                }
                return monitoringPart;
            });
        }

        public createFromType(type: MonitoringPartType): Model.MonitoringPart {
            return this.createPart(type);
        }

        private createPart(
            type: MonitoringPartType,
            serverObject?: Shared.Contract.Read.IMonitoringPart
        ): Model.MonitoringPart {
            let result: Model.MonitoringPart;
            switch (type) {
                case MonitoringPartType.LineChartNumeric:
                    result = new Model.MonitoringPartChart(serverObject as Shared.Contract.Read.IMonitoringPartChart);
                    break;
                case MonitoringPartType.TableNumericQualitative:
                    result = new Model.MonitoringPartTable(serverObject as Shared.Contract.Read.IMonitoringPartTable);
                    break;
                case MonitoringPartType.CumulativeBarChart:
                    result = new Model.MonitoringPartChart(serverObject as Shared.Contract.Read.IMonitoringPartChart);
                    break;
                case MonitoringPartType.FloatingBarChart:
                    result = new Model.MonitoringPartChart(serverObject as Shared.Contract.Read.IMonitoringPartChart);
                    break;
                case MonitoringPartType.ColourQualitativeTimeLine:
                    result = new Model.MonitoringPartTimeLine(serverObject);
                    break;
                case MonitoringPartType.MedicationAdherenceChart:
                    result = new Model.MonitoringPartChart(serverObject as Shared.Contract.Read.IMonitoringPartChart);
                    break;
                case MonitoringPartType.MedicationDoseChart:
                    result = new Model.MonitoringPartChart(serverObject as Shared.Contract.Read.IMonitoringPartChart);
                    break;
                case MonitoringPartType.ActionTable:
                    result = new Model.MonitoringPartActionTable(
                        serverObject as Shared.Contract.Read.IMonitoringPartActionTable
                    );
                    break;
                case MonitoringPartType.ActionTimeLine:
                    result = new Model.MonitoringPartActionTimeLine(
                        serverObject as Shared.Contract.Read.IMonitoringPartActionTimeLine
                    );
                    break;
                case MonitoringPartType.CumulativeObjectiveChart:
                    result = new Model.MonitoringPartChart(serverObject as Shared.Contract.Read.IMonitoringPartChart);
                    break;
                case MonitoringPartType.ObjectiveScoreTable:
                    result = new Model.MonitoringPartTable(serverObject as Shared.Contract.Read.IMonitoringPartTable);
                    break;
                case MonitoringPartType.LineChartNumericExternal:
                    result = new Model.MonitoringPartChart(serverObject as Shared.Contract.Read.IMonitoringPartChart);
                    break;
                case MonitoringPartType.Boxplot:
                    result = new Model.MonitoringPartChart(serverObject as Shared.Contract.Read.IMonitoringPartChart);
                    break;
                default:
                    return null;
            }
            result.type = type;
            return result;
        }
    }

    remeCareSharedModule.service('monitoringPartFactory', MonitoringPartFactory);
}
