namespace RemeCare.Shared.Framework.InformedConsent {
    import InformedConsentQuestionTranslation = Shared.Framework.Model.InformedConsentQuestionTranslation;

    class AddOrUpdateInformedConsentTranslationController implements ng.IComponentController {
        public grid: Shared.Framework.Grid.Grid<Shared.Framework.Model.InformedConsentQuestionTranslation>;
        public translation: Shared.Framework.Model.InformedConsentTranslation;
        public readOnly: boolean;
        public hasChanged: boolean;
        public tinymceOptions = {
            menubar: false,
            min_height: 250,
            theme_url: '/modern/theme.min.js',
            plugins: 'autolink link code table wordcount',
            toolbar:
                'undo redo | styleselect | cut copy paste | bold italic underline strikethrough subscript superscript removeformat | alignleft aligncenter alignright alignjustify | outdent indent | link code table',
            default_link_target: '_blank',
            baseURL: '/lib',
        };
        public tinymceOptionsReadOnly = {
            readonly: 1,
            menubar: false,
            toolbar: false,
            min_height: 250,
            theme_url: '/modern/theme.min.js',
            baseURL: '/lib',
        };

        private tabIndex: number;
        private activeTab: number;

        constructor(
            private readonly gridBuilderSvc: Shared.Framework.Grid.GridBuilderFactory,
            private readonly modalBuilderFactory: Shared.Framework.Helper.ModalBuilderFactory
        ) {}

        public async $onInit(): Promise<void> {
            this.buildGrid();
            this.grid.setData(this.translation.questions);
        }

        public $onChanges(onChangesObj: ng.IOnChangesObject): void {
            if (onChangesObj.translation) {
                if (this.grid != null) {
                    if (this.translation != null) {
                        this.grid.setData(this.translation.questions);
                    }
                }
            }

            if (onChangesObj.activeTab) {
                // Hacky way to trigger the hacky refresh of grid
                if (this.activeTab === this.tabIndex && this.grid) {
                    this.grid.setData(this.grid.getData());
                }
            }
        }

        public addCheckbox(): void {
            const maxSequence = Math.max.apply(
                null,
                _.map(this.translation.questions, (q) => {
                    return q.sequence;
                })
            );
            const newSequence = maxSequence < 0 ? 1 : maxSequence + 1;
            this.hasChanged = true;

            this.modalBuilderFactory
                .createComponentModalBuilder<InformedConsentQuestionTranslation>('rcAddCheckboxModal')
                .setBindings({
                    sequence: newSequence,
                    culture: this.translation.culture,
                })
                .setResultCallBack((r) => {
                    this.grid.addRow(r);
                })
                .build();
        }

        private buildGrid(): void {
            const grid = this.gridBuilderSvc
                .createGridBuilder<InformedConsentQuestionTranslation>()
                .addTextColumn('question', 'Views.InformedConsent.Title', (x) => this.readOnly)
                .addConditionalDeleteButtonColumn(
                    (x) => this.readOnly,
                    (deleted) => {
                        this.translation.questions.forEach((q) => {
                            if (q.sequence > deleted.sequence) {
                                q.sequence--;
                            }
                        });
                    }
                );

            if (!this.readOnly) {
                grid.addUpDownButtonColumn('sequence');
            }

            this.grid = grid.build();
        }
    }

    Shared.remeCareSharedModule.component('rcAddOrUpdateInformedConsentTranslation', {
        controller: AddOrUpdateInformedConsentTranslationController,
        templateUrl: 'framework/informedConsent/addOrUpdateInformedConsent/addOrUpdateInformedConsentTranslation.html',
        bindings: {
            translation: '<',
            readOnly: '<readonly',
            activeTab: '<',
            tabIndex: '<',
            hasChanged: '=',
        },
    });
}
