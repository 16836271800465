var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var InformedConsent;
            (function (InformedConsent) {
                var AddOrUpdateInformedConsentController = /** @class */ (function () {
                    function AddOrUpdateInformedConsentController($translate, $timeout, $state, $stateParams, informedConsentSvc, masterdataSvc, modalBuilderFactory, toaster) {
                        this.$translate = $translate;
                        this.$timeout = $timeout;
                        this.$state = $state;
                        this.$stateParams = $stateParams;
                        this.informedConsentSvc = informedConsentSvc;
                        this.masterdataSvc = masterdataSvc;
                        this.modalBuilderFactory = modalBuilderFactory;
                        this.toaster = toaster;
                        this.languages = [];
                        this.activeTab = 0;
                        this.versionRegex = /^\d+\.\d$/;
                    }
                    AddOrUpdateInformedConsentController.prototype.$onInit = function () {
                        return __awaiter(this, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                this.routePrefix = this.$transition$.params().routePrefix;
                                this.currentLanguage = this.$translate.preferredLanguage();
                                this.setInformedConsent();
                                return [2 /*return*/];
                            });
                        });
                    };
                    AddOrUpdateInformedConsentController.prototype.setInformedConsent = function () {
                        return __awaiter(this, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: return [4 /*yield*/, this.getLanguages()];
                                    case 1:
                                        _a.sent();
                                        if (this.informedConsent == null) {
                                            this.informedConsent = new Shared.Framework.Model.InformedConsent(this.languages);
                                            this.informedConsent.therapyId = this.$stateParams.therapyId;
                                        }
                                        this.getMatchingLanguages();
                                        this.updateName();
                                        return [2 /*return*/];
                                }
                            });
                        });
                    };
                    AddOrUpdateInformedConsentController.prototype.getLanguages = function () {
                        return __awaiter(this, void 0, void 0, function () {
                            var _a, e_1;
                            return __generator(this, function (_b) {
                                switch (_b.label) {
                                    case 0:
                                        _b.trys.push([0, 2, , 3]);
                                        _a = this;
                                        return [4 /*yield*/, this.masterdataSvc.getInterfaceLanguagesAsync()];
                                    case 1:
                                        _a.languages = _b.sent();
                                        return [3 /*break*/, 3];
                                    case 2:
                                        e_1 = _b.sent();
                                        this.toaster.error(e_1);
                                        return [3 /*break*/, 3];
                                    case 3: return [2 /*return*/];
                                }
                            });
                        });
                    };
                    AddOrUpdateInformedConsentController.prototype.getMatchingLanguages = function () {
                        var _this = this;
                        if (this.informedConsent != null) {
                            this.informedConsentsWithLanguages = _.map(this.languages, function (l) {
                                return {
                                    Code: l.Code,
                                    Id: l.Id,
                                    Text: l.Text,
                                    InformedConsentTranslation: _.find(_this.informedConsent.informedConsentTranslations, function (ict) {
                                        return ict.culture === l.Code;
                                    })
                                };
                            });
                        }
                    };
                    AddOrUpdateInformedConsentController.prototype.updateName = function () {
                        var _this = this;
                        if (this.informedConsent != null) {
                            var currentTitleTranslation = _.find(this.informedConsent.informedConsentTranslations, function (ict) {
                                return ict.culture === _this.currentLanguage;
                            });
                            this.informedConsentTitle =
                                currentTitleTranslation != null && currentTitleTranslation.title != null
                                    ? currentTitleTranslation.title
                                    : this.$translate.instant('Views.InformedConsent.NewInformedConsent');
                        }
                    };
                    AddOrUpdateInformedConsentController.prototype.updateTherapyId = function () {
                        if (this.informedConsent != null) {
                            this.informedConsent.therapyId = this.$stateParams.therapyId;
                        }
                    };
                    AddOrUpdateInformedConsentController.prototype.updateLanguages = function () {
                        return __awaiter(this, void 0, void 0, function () {
                            var _a, currentTitleTranslation, e_2;
                            var _this = this;
                            return __generator(this, function (_b) {
                                switch (_b.label) {
                                    case 0:
                                        _b.trys.push([0, 2, , 3]);
                                        _a = this;
                                        return [4 /*yield*/, this.masterdataSvc.getInterfaceLanguagesAsync()];
                                    case 1:
                                        _a.languages = _b.sent();
                                        if (this.informedConsent != null) {
                                            this.informedConsentsWithLanguages = _.map(this.languages, function (l) {
                                                return {
                                                    Code: l.Code,
                                                    Id: l.Id,
                                                    Text: l.Text,
                                                    InformedConsentTranslation: _.find(_this.informedConsent.informedConsentTranslations, function (ict) {
                                                        return ict.culture === l.Code;
                                                    })
                                                };
                                            });
                                            currentTitleTranslation = _.find(this.informedConsent.informedConsentTranslations, function (ict) {
                                                return ict.culture === _this.$translate.preferredLanguage();
                                            });
                                            this.informedConsentTitle =
                                                currentTitleTranslation != null && currentTitleTranslation.title != null
                                                    ? currentTitleTranslation.title
                                                    : this.$translate.instant('Views.InformedConsent.NewInformedConsent');
                                            this.$timeout(function () { return (_this.activeTab = 0); });
                                        }
                                        return [3 /*break*/, 3];
                                    case 2:
                                        e_2 = _b.sent();
                                        this.toaster.error(e_2);
                                        return [3 /*break*/, 3];
                                    case 3: return [2 /*return*/];
                                }
                            });
                        });
                    };
                    AddOrUpdateInformedConsentController.prototype.$onChanges = function (onChangesObj) {
                        if (onChangesObj['informedConsent']) {
                            this.updateTherapyId();
                            this.updateLanguages();
                            this.canApprove = true;
                            this.hasTranslationChanged = false;
                        }
                    };
                    AddOrUpdateInformedConsentController.prototype.confirm = function () {
                        return __awaiter(this, void 0, void 0, function () {
                            var e_3;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        _a.trys.push([0, 5, , 6]);
                                        if (!(this.informedConsent.id == null)) return [3 /*break*/, 2];
                                        return [4 /*yield*/, this.informedConsentSvc.saveInformedConsentDraft(this.informedConsent.toServerWrite())];
                                    case 1:
                                        _a.sent();
                                        return [3 /*break*/, 4];
                                    case 2: return [4 /*yield*/, this.informedConsentSvc.updateInformedConsentDraft(this.informedConsent.toServerWrite())];
                                    case 3:
                                        _a.sent();
                                        _a.label = 4;
                                    case 4:
                                        this.toaster.success('General.SaveSuccess');
                                        this.$state.go(this.routePrefix + 'informedConsents.overview');
                                        return [3 /*break*/, 6];
                                    case 5:
                                        e_3 = _a.sent();
                                        this.toaster.error(e_3);
                                        return [3 /*break*/, 6];
                                    case 6: return [2 /*return*/];
                                }
                            });
                        });
                    };
                    AddOrUpdateInformedConsentController.prototype.cancel = function () {
                        this.$state.go(this.routePrefix + 'informedConsents.overview');
                    };
                    AddOrUpdateInformedConsentController.prototype.approve = function () {
                        var _this = this;
                        this.modalBuilderFactory
                            .createComponentModalBuilder('rcApproveModal')
                            .setBindings({
                            informedConsentId: this.informedConsent.id,
                            minDate: this.informedConsent.getLastVersion().validFromDate
                        })
                            .setResultCallBack(function (r) { return __awaiter(_this, void 0, void 0, function () {
                            var e_4;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        _a.trys.push([0, 2, , 3]);
                                        return [4 /*yield*/, this.informedConsentSvc.approveInformedConsent(this.informedConsent.id, Shared.DateHelper.toServerDateString(r.date), r.needsConfirmation)];
                                    case 1:
                                        _a.sent();
                                        this.informedConsent.acceptDraftVersion(r.date);
                                        this.readOnly = true;
                                        this.toaster.success('General.SaveSuccess');
                                        return [3 /*break*/, 3];
                                    case 2:
                                        e_4 = _a.sent();
                                        this.toaster.error(e_4);
                                        return [3 /*break*/, 3];
                                    case 3: return [2 /*return*/];
                                }
                            });
                        }); })
                            .build();
                    };
                    return AddOrUpdateInformedConsentController;
                }());
                Shared.remeCareSharedModule.component('rcAddOrUpdateInformedConsent', {
                    controller: AddOrUpdateInformedConsentController,
                    templateUrl: 'framework/informedConsent/addOrUpdateInformedConsent/addOrUpdateInformedConsent.html',
                    bindings: {
                        informedConsent: '<',
                        readOnly: '<',
                        $transition$: '<',
                        routePrefix: '<'
                    }
                });
            })(InformedConsent = Framework.InformedConsent || (Framework.InformedConsent = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
