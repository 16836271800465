var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Directive;
        (function (Directive) {
            Shared.remeCareSharedModule.config(function ($datepickerProvider, $timepickerProvider) {
                angular.extend($datepickerProvider.defaults, {
                    startWeek: 1,
                    autoclose: true,
                    useNative: true,
                    placement: 'bottom-right',
                });
                angular.extend($timepickerProvider.defaults, {
                    timeFormat: 'HH:mm',
                    roundDisplay: true,
                    useNative: true,
                });
            });
            var DatepickerController = /** @class */ (function () {
                // @ngInject
                function DatepickerController($scope, $locale, $timeout) {
                    this.$locale = $locale;
                    this.$timeout = $timeout;
                    this.dateModel = {
                        date: undefined,
                        dateTime: undefined,
                    };
                    this.id = $scope.$id;
                }
                DatepickerController.prototype.$onInit = function () {
                    switch (this.startingView) {
                        case 'year':
                            this.startView = 2;
                            break;
                        case 'month':
                            this.startView = 1;
                            break;
                        case 'day':
                        default:
                            this.startView = 0;
                            break;
                    }
                    this.placeholder = this.$locale.DATETIME_FORMATS.shortTime;
                    this.showDate = this.showDate == null ? true : this.showDate;
                    this.showTime = this.showTime || !this.showDate;
                    if (this.date && moment(this.date).isValid()) {
                        this.previousDateValue = this.date.valueOf();
                        this.dateModel = {
                            date: this.toDate(this.date),
                            dateTime: new Date(this.date.valueOf()),
                        };
                    }
                    else {
                        this.dateModel = { date: null, dateTime: null };
                        if (this.showTime && !this.showDate) {
                            // scope.date = new Date();
                            this.dateModel.date = new Date(); // scope.date;
                        }
                        if (!this.showTime) {
                            this.dateModel.dateTime = new Date();
                        }
                    }
                    if (this.minDate != null) {
                        this.fromDate = moment(this.minDate)
                            .startOf('day')
                            .valueOf();
                    }
                    if (this.maxDate != null) {
                        this.untilDate = moment(this.maxDate)
                            .startOf('day')
                            .valueOf();
                    }
                };
                DatepickerController.prototype.$onChanges = function (changes) {
                    var _this = this;
                    this.$timeout(function () {
                        if (changes.minDate) {
                            var newValue = changes.minDate.currentValue;
                            _this.fromDate =
                                newValue == null
                                    ? null
                                    : moment(newValue)
                                        .startOf('day')
                                        .valueOf();
                        }
                        if (changes.maxDate) {
                            var newValue = changes.maxDate.currentValue;
                            _this.untilDate =
                                newValue == null
                                    ? null
                                    : moment(newValue)
                                        .startOf('day')
                                        .valueOf();
                        }
                        if (changes.minTime) {
                            var newValue = changes.minTime.currentValue;
                            _this.fromTime = _.isDate(newValue) ? moment(newValue).format('HH:mm') : newValue;
                        }
                        if (changes.maxTime) {
                            var newValue = changes.maxTime.currentValue;
                            _this.untilTime = _.isDate(newValue) ? moment(newValue).format('HH:mm') : newValue;
                        }
                    }, 0);
                };
                DatepickerController.prototype.$doCheck = function () {
                    var currentValue = this.date && this.date.valueOf();
                    if (this.previousDateValue !== currentValue) {
                        this.previousDateValue = currentValue;
                        if (this.date != null) {
                            this.dateModel.date = new Date(currentValue);
                            this.dateModel.dateTime = new Date(currentValue);
                        }
                        else {
                            if (this.showDate) {
                                this.dateModel.date = null;
                            }
                            if (this.showTime) {
                                this.dateModel.dateTime = null;
                            }
                        }
                    }
                };
                DatepickerController.prototype.dateChanged = function () {
                    if (this.dateModel.date == null && (!this.showTime || this.dateModel.dateTime == null)) {
                        this.date = null;
                    }
                    else if (_.isDate(this.dateModel.date) &&
                        _.isDate(this.dateModel.dateTime) &&
                        moment(this.dateModel.date).isValid() &&
                        moment(this.dateModel.dateTime).isValid()) {
                        var hours = this.showTime ? this.dateModel.dateTime.getHours() : 0;
                        var minutes = this.showTime ? this.dateModel.dateTime.getMinutes() : 0;
                        this.date = new Date(this.dateModel.date.getFullYear(), this.dateModel.date.getMonth(), this.dateModel.date.getDate(), hours, minutes, 0, 0);
                    }
                    this.previousDateValue = this.date && this.date.valueOf();
                    this.changed();
                };
                DatepickerController.prototype.toDate = function (date) {
                    // NULL protection
                    if (!date) {
                        return date;
                    }
                    if (!(date instanceof Date && !isNaN(date.valueOf()))) {
                        date = moment(date).toDate();
                    }
                    return date;
                };
                DatepickerController.prototype.changed = function () {
                    var _this = this;
                    if (this.changeFunction != null) {
                        this.$timeout(function () {
                            _this.changeFunction();
                        }, 0);
                    }
                };
                return DatepickerController;
            }());
            Shared.remeCareSharedModule.component('rcDatepicker', {
                controller: DatepickerController,
                templateUrl: 'views/datepicker.html',
                bindings: {
                    date: '=ngModel',
                    minDate: '<minDate',
                    maxDate: '<maxDate',
                    minTime: '<minTime',
                    maxTime: '<maxTime',
                    required: '=?ngRequired',
                    disabledInput: '=?ngDisabled',
                    showTime: '<?',
                    showDate: '<?',
                    changeFunction: '&?change',
                    hasWarning: '<?ngWarning',
                    startingView: '@?',
                },
            });
            var SeparatedDatePicker = /** @class */ (function () {
                function SeparatedDatePicker($scope, $locale) {
                    this.$scope = $scope;
                    this.$locale = $locale;
                }
                SeparatedDatePicker.prototype.$onInit = function () {
                    this.id = this.$scope.$id;
                    this.placeholder = this.$locale.DATETIME_FORMATS.shortTime;
                };
                SeparatedDatePicker.prototype.$onChanges = function () {
                    this.setIsValid();
                };
                SeparatedDatePicker.prototype.isDateShown = function () {
                    return this.showDate || this.showDate == null || !this.showTime;
                };
                SeparatedDatePicker.prototype.isTimeShown = function () {
                    return this.showTime || this.showDate === false;
                };
                SeparatedDatePicker.prototype.setIsValid = function () {
                    // The time-picker doesn't seem to play well with ui-validate. That's we we use a hidden checkbox
                    // bound to isValid to check the validity
                    var currentDate = Shared.DateHelper.mergeDateAndTime(this.date, this.time);
                    var minDate = this.minDate || (this.isDateShown() ? Shared.DateHelper.startOfTime() : Shared.DateHelper.today());
                    var maxDate = this.maxDate || (this.isDateShown() ? Shared.DateHelper.endOfTime() : Shared.DateHelper.today());
                    var minTime = this.minTime || Shared.DateHelper.today();
                    var maxTime = this.maxTime ||
                        moment(Shared.DateHelper.today())
                            .endOf('day')
                            .toDate();
                    var minDateTime = Shared.DateHelper.mergeDateAndTime(minDate, minTime);
                    var maxDateTime = Shared.DateHelper.mergeDateAndTime(maxDate, maxTime);
                    this.isValid = !moment(minDateTime).isAfter(currentDate) && !moment(currentDate).isAfter(maxDateTime);
                };
                return SeparatedDatePicker;
            }());
            Shared.remeCareSharedModule.component('rcSeparatedDatepicker', {
                controller: SeparatedDatePicker,
                controllerAs: 'dateCtrl',
                templateUrl: 'views/separatedDatepicker.html',
                bindings: {
                    date: '=?',
                    time: '=?',
                    minDate: '<minDate',
                    maxDate: '<maxDate',
                    minTime: '<minTime',
                    maxTime: '<maxTime',
                    ngDisabled: '<?',
                    ngRequired: '<?',
                    showDate: '<?',
                    showTime: '<?',
                },
            });
        })(Directive = Shared.Directive || (Shared.Directive = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
