var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Filter;
        (function (Filter) {
            var durationDisplayFilter = function (masterdataSvc) {
                var units = null; // DATA RECEIVED ASYNCHRONOUSLY AND CACHED HERE
                var serviceInvoked = false;
                var realFilter = function (duration) {
                    if (!duration || (duration.Quantity == null && duration.Unit == null)) {
                        return '';
                    }
                    return duration.Quantity + ' ' + _(units).find(function (u) { return u.Id === duration.Unit; }).Text;
                };
                return function (duration) {
                    if (!units) {
                        if (!serviceInvoked) {
                            serviceInvoked = true;
                            // CALL THE SERVICE THAT FETCHES THE DATA HERE
                            masterdataSvc.getDurationUnits().success(function (data) {
                                units = data;
                            });
                        }
                        return "-"; // PLACEHOLDER WHILE LOADING, COULD BE EMPTY
                    }
                    else
                        return realFilter(duration);
                };
            };
            durationDisplayFilter.$inject = ['masterdataSvc'];
            angular.module('RemeCare.Shared').filter('rcDurationDisplay', durationDisplayFilter);
        })(Filter = Shared.Filter || (Shared.Filter = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
