var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Periodicity;
            (function (Periodicity) {
                var TargetPhysicalQuantity = Framework.Model.TargetPhysicalQuantity;
                var TargetDose = Framework.Model.TargetDose;
                var TargetPhysicalQuantityRange = Framework.Model.TargetPhysicalQuantityRange;
                var IntraDayTimingController = /** @class */ (function (_super) {
                    __extends(IntraDayTimingController, _super);
                    function IntraDayTimingController($scope, $translate, toaster, gridBuilderSvc, modalBuilderFactory, masterdataSvc) {
                        var _this = _super.call(this, $scope, $translate, toaster) || this;
                        _this.$scope = $scope;
                        _this.$translate = $translate;
                        _this.toaster = toaster;
                        _this.gridBuilderSvc = gridBuilderSvc;
                        _this.modalBuilderFactory = modalBuilderFactory;
                        _this.masterdataSvc = masterdataSvc;
                        $scope.confirm = function () { return _this.confirm(); };
                        return _this;
                    }
                    IntraDayTimingController.prototype.$onInit = function () {
                        return __awaiter(this, void 0, void 0, function () {
                            var targetValues, targetDoses, targetPhysicalQuantities, _a, e_1;
                            return __generator(this, function (_b) {
                                switch (_b.label) {
                                    case 0:
                                        targetValues = this.$scope.intraDayTiming.targetValues;
                                        targetDoses = _(targetValues)
                                            .filter(function (t) { return t instanceof TargetDose; });
                                        if (targetDoses.length > 0) {
                                            this.$scope.targetDose = targetDoses[0];
                                        }
                                        targetPhysicalQuantities = _(targetValues)
                                            .filter(function (t) { return t instanceof TargetPhysicalQuantity || t instanceof TargetPhysicalQuantityRange; });
                                        this.$scope.targetPhysicalQuantities = targetPhysicalQuantities;
                                        targetValues = _(targetValues)
                                            .filter(function (t) { return !_(targetDoses).contains(t)
                                            && !_(targetPhysicalQuantities).contains(t); });
                                        this.targetValues = targetValues;
                                        this.buildTargetValuesGrid();
                                        _b.label = 1;
                                    case 1:
                                        _b.trys.push([1, 3, , 4]);
                                        _a = this.$scope;
                                        return [4 /*yield*/, this.masterdataSvc.getAdministrationTimings()];
                                    case 2:
                                        _a.administrationTimings = _b.sent();
                                        return [3 /*break*/, 4];
                                    case 3:
                                        e_1 = _b.sent();
                                        this.toaster.error(e_1);
                                        return [3 /*break*/, 4];
                                    case 4: return [2 /*return*/];
                                }
                            });
                        });
                    };
                    IntraDayTimingController.prototype.buildTargetValuesGrid = function () {
                        var _this = this;
                        var builder = this.gridBuilderSvc
                            .createGridBuilder()
                            .addColumn('contextSetting.TherapyActionPart.Characteristic', 'Configuration.Therapies.StructuralTherapyAction.Characteristic')
                            .addColumn('getTarget()', 'Configuration.Therapies.StructuralTherapyAction.TargetValues')
                            .addColumn('contextSetting.TherapyActionPart.Unit', 'Periodicity.Unit');
                        if (!this.$scope.readOnly) {
                            builder
                                .addEditButtonWithPromiseFunctionColumn(function (t) { return _this.editTargetValue(t); });
                        }
                        this.$scope.targetValuesGrid = builder.build();
                        this.$scope.targetValuesGrid.setData(this.$scope.targetPhysicalQuantities);
                    };
                    IntraDayTimingController.prototype.editTargetValue = function (targetValue) {
                        var _this = this;
                        return new Promise(function (resolve) {
                            _this.modalBuilderFactory.createModalBuilder()
                                .setTemplateUrl('views/periodicity/targetPhysicalQuantity.html')
                                .setController('targetPhysicalQuantityCtrl')
                                .setScope({
                                targetValue: targetValue.copy()
                            })
                                .setResultCallBack(function (tv) { return resolve(tv); })
                                .build();
                        });
                    };
                    IntraDayTimingController.prototype.confirm = function () {
                        if (this.$scope.form.$invalid) {
                            this.showValidationErrorMessage();
                            return;
                        }
                        var targetValues = _(this.targetValues)
                            .union(this.$scope.targetPhysicalQuantities);
                        if (this.$scope.targetDose != null) {
                            targetValues.push(this.$scope.targetDose);
                        }
                        this.$scope.intraDayTiming.targetValues = targetValues;
                        this.$scope.$close(this.$scope.intraDayTiming);
                    };
                    return IntraDayTimingController;
                }(Framework.ControllerBase));
                Shared.remeCareSharedModule.controller('intraDayTimingCtrl', IntraDayTimingController);
            })(Periodicity = Framework.Periodicity || (Framework.Periodicity = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
