namespace Promise {
    export function wrap<T>(ngPromise: ng.IPromise<T>): Promise<T> {
        return new Promise<T>((resolve, reject) => {
            ngPromise
                .then((result) => {
                    resolve(result);
                }).catch((error) => {
                    reject(error);
                });
        });
    }
}