var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var SliderController = /** @class */ (function () {
                function SliderController($element, $timeout) {
                    var _this = this;
                    this.$element = $element;
                    this.$timeout = $timeout;
                    this.valueChanged = function () {
                        var selectedOption = _.find(_this.options, function (csi) { return csi.NumericValue === _this.configuration.value; });
                        _this.model = selectedOption && selectedOption.Id;
                    };
                }
                SliderController.prototype.$onInit = function () {
                    this.configure();
                    this.setSliderWidth();
                };
                SliderController.prototype.selectOption = function (range) {
                    this.configuration.value = range.value;
                    this.valueChanged();
                };
                SliderController.prototype.hasValue = function () {
                    return this.model != null;
                };
                SliderController.prototype.removeValue = function () {
                    this.model = null;
                };
                SliderController.prototype.configure = function () {
                    var _this = this;
                    var orderedOptions = _.sortBy(this.options, function (csi) { return csi.Sequence; });
                    var leftAnswer = _(orderedOptions).first();
                    var rightAnswer = _(orderedOptions).last();
                    var selectedAnswer = _.find(this.options, function (csi) { return csi.Id === _this.model; });
                    if (selectedAnswer == null) {
                        var average_1 = this.calcAverageValue(leftAnswer.NumericValue, rightAnswer.NumericValue, true);
                        selectedAnswer = _.find(this.options, function (csi) { return csi.NumericValue === average_1; });
                    }
                    this.configuration = {
                        minValue: leftAnswer.NumericValue,
                        maxValue: rightAnswer.NumericValue,
                        stepValue: 1,
                        leftLegend: leftAnswer.Name,
                        rightLegend: rightAnswer.Name,
                        value: selectedAnswer && selectedAnswer.NumericValue,
                    };
                    this.rangeIndicators = _.map(orderedOptions, function (o) {
                        return {
                            value: o.NumericValue,
                            text: o.Name,
                        };
                    });
                };
                SliderController.prototype.setSliderWidth = function () {
                    var _this = this;
                    this.$timeout(function () {
                        var width = 100 - 100 / (_this.configuration.maxValue - _this.configuration.minValue + 1);
                        _this.$element.find('.slider').width(width + "%");
                        _this.rightOffset = (100 - width) / 4 + "%";
                    });
                };
                SliderController.prototype.calcAverageValue = function (min, max, round) {
                    var average = (min + max) / 2;
                    return round ? Math.round(average) : average;
                };
                return SliderController;
            }());
            Shared.remeCareSharedModule.component('rcSlider', {
                controller: SliderController,
                bindings: {
                    model: '=ngModel',
                    options: '<',
                    ngDisabled: '<ngDisabled',
                    required: '<ngRequired',
                    sliderClass: '<',
                    text: '<',
                    guidanceIconLocation: '<',
                    guidanceIconFunc: '<',
                    uniqueId: '@',
                },
                require: {
                    formCtrl: '^form',
                },
                templateUrl: 'views/slider.html',
            });
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
