var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Directive;
            (function (Directive) {
                var RemeCareWarningController = /** @class */ (function () {
                    function RemeCareWarningController() {
                    }
                    RemeCareWarningController.prototype.$onInit = function () { };
                    return RemeCareWarningController;
                }());
                Shared.remeCareSharedModule.component('rcWarning', {
                    controller: RemeCareWarningController,
                    templateUrl: 'framework/directives/controls/warning/warning.html',
                    bindings: {
                        message: '@',
                    },
                });
            })(Directive = Framework.Directive || (Framework.Directive = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
