var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Model;
            (function (Model) {
                var Guidance = /** @class */ (function () {
                    function Guidance(serverObject) {
                        if (serverObject != null) {
                            this.guidanceId = serverObject.GuidanceId;
                            this.code = serverObject.Code;
                            this.internalName = serverObject.InternalName;
                            this.internalDescription = serverObject.InternalDescription;
                            this.status = serverObject.Status;
                            this.steps = _(serverObject.GuidanceSteps)
                                .chain()
                                .map(function (tap) {
                                return RemeCare.Shared.Framework.Factory.GuidanceStepFactory.createGuidanceStep(tap);
                            })
                                .sortBy(function (tap) { return tap.Sequence; })
                                .value();
                            this.isGlobal = serverObject.IsGlobal;
                        }
                        else {
                            this.status = {
                                Text: 'InPreparation',
                                Id: Shared.Contract.Code.GuidanceStatus.InPreparation,
                            };
                            this.steps = [];
                        }
                    }
                    Guidance.prototype.toServerWrite = function () {
                        var result = {
                            GuidanceId: this.guidanceId,
                            StatusId: this.status.Id,
                            Code: this.code,
                            InternalDescription: this.internalDescription,
                            InternalName: this.internalName,
                        };
                        result.GuidanceSteps = _(this.steps).map(function (part) { return part.toServerWrite(); });
                        return result;
                    };
                    Guidance.prototype.anonimise = function () {
                        this.status = {
                            Id: RemeCare.Shared.Contract.Code.GuidanceStatus.InPreparation,
                            Text: 'InPreparation',
                        };
                        this.code = null;
                        this.guidanceId = null;
                        this.internalDescription = null;
                        this.internalName = null;
                        _(this.steps).each(function (tap) { return tap.anonimise(); });
                    };
                    return Guidance;
                }());
                Model.Guidance = Guidance;
            })(Model = Framework.Model || (Framework.Model = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
