module RemeCare.Shared.Directive {

    class LoadTranslationsDirective implements ng.IDirective {

        constructor(private translationResolver) {}

        restrict = 'EA';

        link = (scope, element, attrs) => {
            this.translationResolver.setParts([attrs.key]);
        }
    }

    remeCareSharedModule.directive('loadTranslations', (translationResolver) => new LoadTranslationsDirective(translationResolver));
}