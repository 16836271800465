module RemeCare.Shared.Contract.Code {

    export enum MonitoringPartSourceType {
        MonitoringPartSourceAction = 1,
        QualitativeMeasuringPointParameter = 2,
        QuantitativeMeasuringPointParameter = 3,
        QualitativeReferenceParameterAnamnesis = 4,
        QuantitativeReferenceParameterAnamnesis = 5,
        ReferenceParameterThreshold = 6,
        ReferenceParameterObjective = 7,
        ExternalDataSourceParameter = 8,
        MonitoringPartSourceFilter = 9
    }
}