var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Directive;
            (function (Directive) {
                var CancelButton = /** @class */ (function () {
                    function CancelButton() {
                    }
                    CancelButton.prototype.$onInit = function () {
                        this.label = this.label || 'General.Cancel';
                    };
                    return CancelButton;
                }());
                Shared.remeCareSharedModule.component('rcCancel', {
                    controller: CancelButton,
                    templateUrl: 'views/controls/cancelButton.html',
                    bindings: {
                        cancel: '&?',
                        label: '@?',
                        ngDisabled: '<?',
                        link: '@?',
                        spinner: '@?',
                    },
                });
            })(Directive = Framework.Directive || (Framework.Directive = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
