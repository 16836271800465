namespace RemeCare.Shared.Framework.Directive {
    class ConfirmButton {
        public label: string;

        public $onInit() {
            this.label = this.label || 'General.Confirm';
        }
    }

    remeCareSharedModule.component('rcConfirm', {
        controller: ConfirmButton,
        templateUrl: 'views/controls/confirmButton.html',
        bindings: {
            label: '@?',
            ngDisabled: '<?',
            spinner: '@?',
        },
    });
}
