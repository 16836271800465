var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Directive;
        (function (Directive) {
            var ToggleController = /** @class */ (function () {
                function ToggleController($timeout) {
                    this.$timeout = $timeout;
                }
                ToggleController.prototype.$onInit = function () { };
                ToggleController.prototype.labelClicked = function () {
                    var _this = this;
                    if (this.disabled) {
                        return;
                    }
                    this.model = !this.model;
                    this.$timeout(function () {
                        _this.changed();
                    });
                };
                ToggleController.prototype.onToggleValueChange = function () {
                    var _this = this;
                    this.$timeout(function () {
                        _this.changed();
                    });
                };
                return ToggleController;
            }());
            Shared.remeCareSharedModule.component('rcToggle', {
                bindings: {
                    changed: '&ngChange',
                    disabled: '=ngDisabled',
                    label: '@',
                    model: '=ngModel',
                    infoMessage: '@?',
                },
                controller: ToggleController,
                templateUrl: 'views/controls/toggle.html',
            });
        })(Directive = Shared.Directive || (Shared.Directive = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
