var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Contract;
        (function (Contract) {
            var Code;
            (function (Code) {
                var PatientInformedConsentStatusCode;
                (function (PatientInformedConsentStatusCode) {
                    PatientInformedConsentStatusCode[PatientInformedConsentStatusCode["Unanswered"] = 0] = "Unanswered";
                    PatientInformedConsentStatusCode[PatientInformedConsentStatusCode["Accepted"] = 1] = "Accepted";
                    PatientInformedConsentStatusCode[PatientInformedConsentStatusCode["Rejected"] = 2] = "Rejected";
                    PatientInformedConsentStatusCode[PatientInformedConsentStatusCode["Updated"] = 3] = "Updated";
                })(PatientInformedConsentStatusCode = Code.PatientInformedConsentStatusCode || (Code.PatientInformedConsentStatusCode = {}));
            })(Code = Contract.Code || (Contract.Code = {}));
        })(Contract = Shared.Contract || (Shared.Contract = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
