module RemeCare.Shared.Filter {
    
    var displayUtcDateTimeFilter: (dateFilter) => (utcString: string, dateFormat?: string) => string = (dateFilter) => {
        return (utcString: string, dateFormat?: string) => {
            var date = DateHelper.serverDateStringToDateTime(utcString);
            dateFormat = dateFormat || 'short';
            if (!moment(date).isValid())
                return "";
            return dateFilter(date, dateFormat);
        }
    }

    angular.module('RemeCare.Shared').filter('displayUtcDateTimeFilter', displayUtcDateTimeFilter);
}