namespace RemeCare.Shared.Framework.Directive {
    class InputRadioToggleController implements ng.IComponentController {
        public change: () => void;

        constructor(private readonly $timeout: ng.ITimeoutService) {}

        public $onInit(): void {}

        public onChange(): void {
            if (this.change) {
                this.$timeout(() => {
                    this.change();
                });
            }
        }
    }

    remeCareSharedModule.component('rcInputRadioToggle', {
        bindings: {
            label: '@',
            model: '=ngModel',
            labelOff: '@',
            labelOn: '@',
            change: '&?ngChange',
            info: '@?',
            guidanceIconLocation: '<',
            guidanceIconFunc: '<',
            uniqueId: '@',
        },
        controller: InputRadioToggleController,
        require: {
            formCtrl: '^form',
        },
        templateUrl: 'framework/directives/formElements/inputRadioToggle/inputRadioToggle.html',
    });
}
