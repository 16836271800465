module RemeCare.Shared.Framework {
    
    interface IMultiSelectSearchModalScope<TResult> extends IBaseScope, ng.ui.bootstrap.IModalScope {
        searchInfo: {
            title: string;
            searchCriteriaSrc: string;
            searchFunction(): Contract.ISearchResult<TResult>;
            columns: Array<Shared.Directive.IColumn>;
            idField: string;
            currentResults: Array<TResult>;
        }
        resultsGrid: Grid.Grid<TResult>;

        progressBar: string;

        select(r: TResult): void;
        confirm(): void;
    }

    class MultiSelectSearchModalController<TResult> extends ControllerBase<IMultiSelectSearchModalScope<TResult>> {
        
        constructor(
            $scope: IMultiSelectSearchModalScope<TResult>,
            $translate,
            toaster,
            private gridBuilderSvc: Grid.GridBuilderFactory) {
            super($scope, $translate, toaster);

            $scope.select = (r) => this.select(r);
            $scope.confirm = () => this.confirm();
        }

        public $onInit(): void {
            this.buildResultsGrid();
        }

        private select(r: TResult): void {
            if (_(this.$scope.resultsGrid.getData())
                .any(t => t[this.$scope.searchInfo.idField] === r[this.$scope.searchInfo.idField])) {
                this.toaster.info(this.$translate.instant('General.SelectionContains'));
                return;
            }
            this.$scope.resultsGrid.addRow(r);
        }

        private confirm(): void {
            this.$scope.$close(this.$scope.resultsGrid.getData());
        }

        private buildResultsGrid(): void {
            var gridBuilder = this.gridBuilderSvc
                .createGridBuilder<TResult>();
            _(this.$scope.searchInfo.columns).each(c => {
                gridBuilder = gridBuilder.addColumn(c.field, c.displayName, c.options);
            });
            this.$scope.resultsGrid = gridBuilder
                .addDeleteButtonColumn()
                .build();
            if (this.$scope.searchInfo.currentResults) {
                this.$scope.resultsGrid.setData(this.$scope.searchInfo.currentResults);
            }
        }
    }

    angular.module('RemeCare.Shared').controller('multiSelectSearchModalCtrl', MultiSelectSearchModalController);
}