var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Directive;
        (function (Directive) {
            var ColourpickerDirective = /** @class */ (function () {
                function ColourpickerDirective() {
                    this.restrict = 'E';
                    this.require = '^form';
                    this.scope = {
                        label: '@label',
                        colour: '=ngModel',
                        required: '=ngRequired',
                        disabled: '=ngDisabled',
                        guidanceIconLocation: '<',
                        guidanceIconFunc: '<',
                        uniqueId: '@',
                    };
                    this.templateUrl = 'views/formElements/colourpicker.html';
                    this.link = function (scope, element, attrs, formCtrl) {
                        scope.formCtrl = formCtrl;
                    };
                }
                return ColourpickerDirective;
            }());
            Shared.remeCareSharedModule.directive('rcColourpicker', function () { return new ColourpickerDirective(); });
        })(Directive = Shared.Directive || (Shared.Directive = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
