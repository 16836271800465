var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Directive;
            (function (Directive) {
                var DropdownButtonController = /** @class */ (function () {
                    function DropdownButtonController() {
                    }
                    DropdownButtonController.prototype.$onInit = function () {
                    };
                    DropdownButtonController.prototype.optionSelected = function (option) {
                        this.onSelected({
                            option: option
                        });
                    };
                    return DropdownButtonController;
                }());
                Shared.remeCareSharedModule.component('rcDropdownButton', {
                    controller: DropdownButtonController,
                    templateUrl: 'views/controls/dropdownButton.html',
                    bindings: {
                        buttonClasses: '@',
                        displayField: '@',
                        label: '@',
                        onSelected: '&',
                        options: '<',
                    }
                });
            })(Directive = Framework.Directive || (Framework.Directive = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
