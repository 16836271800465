namespace RemeCare.Shared.Directive {
    class GuidancePartStepController implements ng.IComponentController {
        public set step(value: RemeCare.Shared.Contract.Read.IGuidanceStep) {
            this.stepValue = value;
            this.init();
        }

        public get step(): RemeCare.Shared.Contract.Read.IGuidanceStep {
            return this.stepValue;
        }
        public html: any;
        public current: Shared.Framework.Model.GuidanceStepTranslation;

        private stepValue: RemeCare.Shared.Contract.Read.IGuidanceStep;
        private sce: ng.ISCEService;
        private lang: string;

        // @ngInject
        constructor(private $sce: ng.ISCEService, private readonly $translate: ng.translate.ITranslateService) {
            this.lang = this.$translate.preferredLanguage();
            this.sce = $sce;
            this.init();
        }

        public getTemplateUrl(): string {
            switch (this.current.GuidanceStepTypeCode) {
                case RemeCare.Shared.Contract.Code.GuidanceStepType.Image:
                    return 'views/guidance/imagelink.html';
                case RemeCare.Shared.Contract.Code.GuidanceStepType.Text:
                    return 'views/guidance/text.html';
                case RemeCare.Shared.Contract.Code.GuidanceStepType.File:
                    return 'views/guidance/file.html';
                case RemeCare.Shared.Contract.Code.GuidanceStepType.Video:
                    return 'views/guidance/videolink.html';
                case RemeCare.Shared.Contract.Code.GuidanceStepType.RichText:
                    return 'views/guidance/rich.html';
                default:
                    return 'views/guidance/guidancePartStep.html';
            }
        }

        public $onInit(): void {}

        private init(): void {
            if (this.lang != null && this.sce != null) {
                this.current = new Shared.Framework.Model.GuidanceStepTranslation(
                    _.find(this.step.Translations, t => t.Culture === this.lang)
                );
                this.html = this.sce.trustAsHtml(this.current.Content);
            }
        }
    }

    remeCareSharedModule.component('rcGuidancePartStep', {
        bindings: {
            step: '=',
        },
        controller: GuidancePartStepController,
        template: '<ng-include src="$ctrl.getTemplateUrl()"/>',
    });
}
