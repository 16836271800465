var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var RemeCareSharedConfig = /** @class */ (function () {
            function RemeCareSharedConfig($httpProvider) {
                this.$httpProvider = $httpProvider;
                this.config();
            }
            RemeCareSharedConfig.prototype.config = function () {
                this.$httpProvider.interceptors.push('authHttpInterceptor');
                this.$httpProvider.interceptors.push('httpRequestInterceptor');
                this.$httpProvider.interceptors.push('loadMeasuringHttpInterceptor');
            };
            return RemeCareSharedConfig;
        }());
        Shared.remeCareSharedModule.config(function ($httpProvider) { return new RemeCareSharedConfig($httpProvider); });
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
