module RemeCare.Shared.Directive {

    interface IPriorityCircleScope {
        priorityColor: string;
        priority: Shared.Contract.Code.AlarmLevel;
    }

    class PriorityCircleDirective implements ng.IDirective {

        scope: { [boundProperty: string]: string } = {
            priority: '=',
            width: '@',
            radius: '@'
        }

        restrict = 'E';

        templateUrl: string = 'views/priorityCircle.html';

        transclude: boolean = true;

        link = (scope, element) => {
            scope.$watch((s: IPriorityCircleScope) => s.priority, (newValue, oldValue) => {
                if (newValue === Shared.Contract.Code.AlarmLevel.High) {
                    scope.priorityColor = '#e60000';
                } else if (newValue === Shared.Contract.Code.AlarmLevel.Medium) {
                    scope.priorityColor = '#eb9d1a';
                } else if (newValue === Shared.Contract.Code.AlarmLevel.Low) {
                    scope.priorityColor = '#555';
                }
            })
            
        }

    }

    angular.module('RemeCare.Shared').directive('rcPriorityCircle', () => new PriorityCircleDirective());
}