/// <reference path="./version.ts"/>

module RemeCare.Shared.Framework.Model {
    import EntityTranslation = Shared.Contract.IEntityTranslation;
    import EnumTranslation = Shared.Contract.IEnumTranslation;
    import Guid = Shared.Contract.Guid;

    export class ObjectiveDefinition {
        id: Guid;
        name: string;

        isPersonalisable: boolean;

        type: EnumTranslation;
        unit: EntityTranslation;
        characteristic: EntityTranslation;
        period: Shared.Contract.IDuration;
        sequence: number;

        objectiveValues: Array<ObjectiveValue>;

        therapyId: Guid;

        readOnly: boolean;

        constructor(serverObject?: Shared.Contract.Read.IObjectiveDefinition) {
            if (serverObject != null) {
                this.id = serverObject.Id;
                this.name = serverObject.Name;
                this.isPersonalisable = serverObject.IsPersonalisable;
                this.type = serverObject.Type;
                this.unit = serverObject.Unit;
                this.characteristic = serverObject.Characteristic;
                this.sequence = serverObject.Sequence;

                this.period = serverObject.Period;
                this.objectiveValues = _(serverObject.ObjectiveValues).map(x => new ObjectiveValue(x));
                this.therapyId = serverObject.TherapyId;
                this.readOnly = serverObject.ReadOnly;
            } else {
                this.objectiveValues = [];
            }
        }

        public addObjectiveValue(objectiveValue: ObjectiveValue): void {
            if (!_(this.objectiveValues).isEmpty()) {
                var previous = this.getLastObjectiveValue();
                previous.validUntilDate = moment(objectiveValue.validFromDate).subtract(1, 'Day').toDate();
            } else {
                objectiveValue.validFromDate = new Date();
            }
            objectiveValue.validUntilDate = null;
            this.objectiveValues.push(objectiveValue);
        }

        public editObjectiveValue(objectiveValue: ObjectiveValue, newValidFromDate: Date, newValidUntilDate: Date): ObjectiveValue {
            var previous = _(this.objectiveValues)
                .find<ObjectiveValue>(x => moment(x.validUntilDate)
                    .isSame(moment(objectiveValue.validFromDate).subtract(1, 'Day').toDate()));
            if (previous) {
                previous.validUntilDate = moment(newValidFromDate).subtract(1, 'Day').toDate();
            }
            objectiveValue.validFromDate = newValidFromDate;
            objectiveValue.validUntilDate = newValidUntilDate;
            return objectiveValue;
        }

        public deleteObjectiveValue(objectiveValue: ObjectiveValue): void {
            var previous = _(this.objectiveValues)
                .find<ObjectiveValue>(x => moment(x.validUntilDate)
                    .isSame(moment(objectiveValue.validFromDate).subtract(1, 'Day').toDate()));
            previous.validUntilDate = null;
            this.objectiveValues = _(this.objectiveValues).filter(x => x.validFromDate != objectiveValue.validFromDate);
        }

        public toServerWrite(): Shared.Contract.Write.IObjectiveDefinition {
            _(this.objectiveValues).filter(x => x !== this.getLastObjectiveValue()).map(v => v.validUntilDate = null);

            var result = <Shared.Contract.Write.IObjectiveDefinition>{
                Id: this.id,
                Name: this.name,

                IsPersonalisable: this.isPersonalisable,

                Type: this.type.Id,
                UnitId: this.unit != null ? this.unit.Id : null,
                CharacteristicId: this.characteristic.Id,
                Period: <Shared.Contract.IDuration>{
                    Quantity: this.period.Quantity,
                    Unit: this.period.Unit
                },
                Sequence: this.sequence,

                ObjectiveValues: _(this.objectiveValues).map(x => x.toServerWrite()),

                TherapyId: this.therapyId
            };

            return result;
        }

        public getCurrentObjectiveValue() : ObjectiveValue {
            var currentDate = new Date();
            var result = _(this.objectiveValues)
                .find<ObjectiveValue>(x => (moment(x.validFromDate).isBefore(currentDate) &&
                    (moment(x.validUntilDate).isAfter(currentDate) || x.validUntilDate == null)) ||
                    moment().isSame(x.validFromDate, 'day') ||
                    moment().isSame(x.validUntilDate, 'day'));
            return result ? result : null;
        }

        public getLastObjectiveValue(): ObjectiveValue {
            var maxDate = _(this.objectiveValues).max(x => x.validFromDate).validFromDate;
            return _(this.objectiveValues).find<ObjectiveValue>(x => moment(x.validFromDate).isSame(maxDate));
        }

        public getPreviousObjectiveValue(value: ObjectiveValue): ObjectiveValue {
            var values = _(this.objectiveValues).filter(v => moment(value.validFromDate).add(-1, 'day').isSame(v.validUntilDate));
            return _(values).size() !== 0 ? _(values).last() : null;
        }

        public hasFutureValue(): boolean {
            return _(this.objectiveValues).any(x => moment(x.validFromDate).isAfter(moment()));
        }
    }

    export class ObjectiveValue extends Model.Version {
        id: Guid;

        amount: number;
        isPersonalised: boolean;

        constructor(serverObject?: Shared.Contract.Read.IObjectiveValue) {
            super();
            if (serverObject != null) {
                this.id = serverObject.Id;
                this.amount = serverObject.Amount;
                this.validFromDate = Shared.DateHelper.serverDateStringToDate(serverObject.ValidFromDate);
                this.validUntilDate = Shared.DateHelper.serverDateStringToDate(serverObject.ValidUntilDate);
                this.isPersonalised = serverObject.IsPersonalised;
            }
        }

        public toServerWrite(): Shared.Contract.Write.IObjectiveValue {
            var result = <Shared.Contract.Write.IObjectiveValue>{
                Id: this.id,
                Amount: this.amount,
                ValidFromDate: Shared.DateHelper.toServerDateString(this.validFromDate)
            }

            if (this.validUntilDate !== null) {
                result.ValidUntilDate = result.ValidUntilDate = Shared.DateHelper.toServerDateString(this.validUntilDate);
            }

            return result;
        }
    }
}