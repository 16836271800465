var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Contract;
        (function (Contract) {
            var Code;
            (function (Code) {
                var CareRequestStatus;
                (function (CareRequestStatus) {
                    CareRequestStatus[CareRequestStatus["Open"] = 1] = "Open";
                    CareRequestStatus[CareRequestStatus["ToValidate"] = 2] = "ToValidate";
                    CareRequestStatus[CareRequestStatus["Final"] = 3] = "Final";
                    CareRequestStatus[CareRequestStatus["Cancelled"] = 4] = "Cancelled";
                })(CareRequestStatus = Code.CareRequestStatus || (Code.CareRequestStatus = {}));
            })(Code = Contract.Code || (Contract.Code = {}));
        })(Contract = Shared.Contract || (Shared.Contract = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
