namespace RemeCare.Shared {

    class RemeCareSharedConfig {
        constructor(
            private readonly $httpProvider: ng.IHttpProvider
        ) {
            this.config();
        }

        private config() {
            this.$httpProvider.interceptors.push('authHttpInterceptor');
            this.$httpProvider.interceptors.push('httpRequestInterceptor');
            this.$httpProvider.interceptors.push('loadMeasuringHttpInterceptor');
        }
    }

    remeCareSharedModule.config((
        $httpProvider: ng.IHttpProvider
    ) => new RemeCareSharedConfig(
        $httpProvider
    ));
}