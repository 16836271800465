module RemeCare.Shared.Directive {


    abstract class AuthDirective implements ng.IDirective {

        restrict = 'A';

        constructor(private authservice: Shared.Framework.AuthService) {
        }

        link: ng.IDirectiveLinkFn = (scope: ng.IScope, element: ng.IAugmentedJQuery, attrs: any) => {
            scope.$on('rightsChanged', () => {
                this.checkRight(attrs.authContext, attrs.authright, element);
            });
            this.checkRight(attrs.authContext, attrs.authright, element);
        }

        private checkRight(context: string, right: Shared.Framework.AuthRight, element: ng.IAugmentedJQuery): void {
            var hasRight = this.authservice.hasRight(context, right);
            if (hasRight) {
                this.onHasRight(element);
            } else {
                this.onNotHasRight(element);
            }
        }

        protected abstract onHasRight(element: ng.IAugmentedJQuery): void;
        protected abstract onNotHasRight(element: ng.IAugmentedJQuery): void;
    }

    class AuthDisableDirective extends AuthDirective {
        
        protected onHasRight(element: ng.IAugmentedJQuery): void {
            element.removeAttr('disabled');
            element.find(":input").removeAttr('disabled');
            element.find(":button,:submit").removeAttr('disabled');
            element.find("a").removeAttr('disabled');
        }

        protected onNotHasRight(element: ng.IAugmentedJQuery): void {
            element.attr('disabled', 'disabled');
            element.find(':input').attr('disabled', 'disabled');
            element.find(':button,:submit').attr('disabled', 'disabled');
            element.find('a').attr('disabled', 'disabled');
        }
    }

    angular.module('RemeCare.Shared').directive("authdisabled", (
        authservice
    ) => new AuthDisableDirective(
            authservice));

    class AuthContextDirective extends AuthDirective {

        protected onHasRight(element: ng.IAugmentedJQuery): void {
            element.css('display', '');
        }

        protected onNotHasRight(element: ng.IAugmentedJQuery): void {
            element.css('display', 'none');
        }
    }

    remeCareSharedModule.directive('authContext', (authservice) => new AuthContextDirective(authservice));
}