var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Directive;
            (function (Directive) {
                var ModalFooter = /** @class */ (function () {
                    function ModalFooter() {
                        this.restrict = 'C';
                        this.templateUrl = 'views/modal/modalFooter.html';
                        this.transclude = true;
                    }
                    return ModalFooter;
                }());
                Shared.remeCareSharedModule.directive('modalFooter', function () { return new ModalFooter(); });
            })(Directive = Framework.Directive || (Framework.Directive = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
