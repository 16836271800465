var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Grid;
            (function (Grid) {
                var UiGridAutoResizeDirective = /** @class */ (function () {
                    function UiGridAutoResizeDirective(gridUtil) {
                        var _this = this;
                        this.gridUtil = gridUtil;
                        this.require = 'uiGrid';
                        this.scope = false;
                        this.link = function (scope, element, attributes, uiGridCtrl) {
                            var prevGridHeight, prevGridWidth;
                            var getDimensions = function () {
                                prevGridHeight = _this.gridUtil.elementHeight(element);
                                prevGridWidth = _this.gridUtil.elementWidth(element);
                            };
                            scope.$watch(('grid.gridOptions.data.length'), function () {
                                uiGridCtrl.grid.refreshCanvas(true);
                            });
                        };
                    }
                    return UiGridAutoResizeDirective;
                }());
                angular.module('RemeCare.Shared').directive('rcUiGridAutoResize', function (gridUtil) { return new UiGridAutoResizeDirective(gridUtil); });
            })(Grid = Framework.Grid || (Framework.Grid = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
