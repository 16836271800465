namespace RemeCare.Shared.Framework.Directive {
    class InputRadioHorizontalController<TModel> implements ng.IComponentController {
        // bindings
        public uniqueName: string;
        public required: boolean;
        public model: TModel;
        public optionSize: number;
        public options: TModel[];

        public change: () => void;
        private previousValue: TModel;

        constructor(
            private readonly idService: Shared.Framework.Service.IIdService,
            private readonly $timeout: ng.ITimeoutService
        ) {}

        public $onInit(): void {
            this.previousValue = this.model;
            this.uniqueName = this.idService.generateId();
            this.setOptionSize();
        }

        public $onChanges(onChangesObj: ng.IOnChangesObject): void {
            if (onChangesObj.options) {
                this.setOptionSize();
            }
        }

        public onChange(): void {
            if (this.change) {
                this.$timeout(() => {
                    this.change();
                });
            }
        }

        public optionClicked(): void {
            if (!this.required && this.model !== null && angular.equals(this.model, this.previousValue)) {
                this.model = null;
            }
            this.previousValue = this.model;
        }

        private setOptionSize(): void {
            if (this.options) {
                this.optionSize = Math.floor(12 / this.options.length);
            }
        }
    }

    remeCareSharedModule.component('rcInputRadioHorizontal', {
        bindings: {
            label: '@',
            model: '=ngModel',
            options: '<',
            displayText: '@',
            value: '@',
            required: '<ngRequired',
            disabled: '<ngDisabled',
            change: '&?ngChange',
            guidanceIconLocation: '<',
            guidanceIconFunc: '<',
            uniqueId: '@',
        },
        controller: InputRadioHorizontalController,
        require: {
            formCtrl: '^form',
        },
        templateUrl: 'framework/directives/formElements/inputRadioHorizontal/inputRadioHorizontal.html',
    });
}
