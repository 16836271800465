var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Service;
            (function (Service) {
                var SpinnerService = /** @class */ (function () {
                    function SpinnerService() {
                        this.spinningSpinners = {};
                        this.spinners = {};
                    }
                    SpinnerService.prototype.register = function (spinner) {
                        this.spinners[spinner.name] = this.spinners[spinner.name] || [];
                        if (!_(this.spinners[spinner.name]).contains(spinner)) {
                            this.spinners[spinner.name].push(spinner);
                        }
                        this.checkSpin(spinner);
                    };
                    SpinnerService.prototype.unregister = function (spinner) {
                        var index = this.spinners[spinner.name].indexOf(spinner);
                        this.spinners[spinner.name].splice(index, 1);
                        if (this.spinners[spinner.name].length === 0) {
                            delete this.spinners[spinner.name];
                        }
                    };
                    SpinnerService.prototype.unregisterAll = function () {
                        for (var name in this.spinners) {
                            delete this.spinners[name];
                        }
                    };
                    SpinnerService.prototype.show = function (name, increment) {
                        if (increment === void 0) { increment = true; }
                        if (this.spinningSpinners[name] == null) {
                            this.spinningSpinners[name] = 1;
                        }
                        else if (increment) {
                            this.spinningSpinners[name]++;
                        }
                        var spinners = this.spinners[name];
                        if (!spinners) {
                            return;
                        }
                        _(spinners).each(function (s) { return s.show(); });
                    };
                    SpinnerService.prototype.hide = function (name) {
                        if (this.spinningSpinners[name] == null)
                            return;
                        this.spinningSpinners[name]--;
                        if (this.spinningSpinners[name] !== 0) {
                            return;
                        }
                        delete this.spinningSpinners[name];
                        var spinners = this.spinners[name];
                        if (!spinners) {
                            return;
                        }
                        _(spinners).each(function (s) { return s.hide(); });
                    };
                    SpinnerService.prototype.showAll = function () {
                        for (var name in this.spinners) {
                            _(this.spinners[name]).each(function (s) { return s.show(); });
                        }
                    };
                    SpinnerService.prototype.hideAll = function () {
                        for (var name in this.spinners) {
                            _(this.spinners[name]).each(function (s) { return s.hide(); });
                        }
                    };
                    SpinnerService.prototype.checkSpin = function (spinner) {
                        if (this.spinningSpinners.hasOwnProperty(spinner.name) && this.spinningSpinners[spinner.name]) {
                            this.show(spinner.name, false);
                            return;
                        }
                        if (this.spinningSpinners.hasOwnProperty(spinner.group) && this.spinningSpinners[spinner.group]) {
                            this.show(spinner.name, false);
                            return;
                        }
                    };
                    return SpinnerService;
                }());
                Service.SpinnerService = SpinnerService;
                Shared.remeCareSharedModule.factory('spinnerSvc', function () { return new SpinnerService(); });
            })(Service = Framework.Service || (Framework.Service = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
