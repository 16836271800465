var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Directive;
            (function (Directive) {
                Shared.remeCareSharedModule.component('rcStaticHtml', {
                    bindings: {
                        model: '<ngModel',
                        adjustSize: '<',
                        label: '@',
                        text: '@',
                        guidanceIconLocation: '<',
                        guidanceIconFunc: '<',
                        uniqueId: '@',
                    },
                    templateUrl: 'framework/directives/formElements/staticHtml/staticHtml.html',
                });
            })(Directive = Framework.Directive || (Framework.Directive = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
