namespace RemeCare.Shared.Framework {
    export class HealthCarePartyService extends ApiServiceBase {
        public static $inject = Shared.Framework.ApiServiceBase.injectionParameters;

        public searchHealthCareSpecialtyProfessionsAsync(
            query: Contract.Query.IHealthCareSpecialtyProfessionQuery
        ): Promise<Contract.ISearchResult<Contract.IEnumTranslation>> {
            return this.getPromise('HealthCareSpecialtyProfessions', query, 'healthCareSpecialtyProfessions');
        }

        public getHealthCareSpecialtyProfessionsAsync(): Promise<Contract.IEnumTranslation[]> {
            return this.getPromise(
                'HealthCareSpecialtyProfessions',
                null,
                'healthCareParty.healthCareSpecialtyProfessions',
                null,
                t => t.Items
            );
        }

        public searchHealthCareOrganisationTypesAsync(
            query: Contract.Query.IHealthCareOrganisationTypeQuery
        ): Promise<Contract.ISearchResult<Contract.IEnumTranslation>> {
            return this.getPromise('HealthCareParty/HealthCareOrganisationTypes', query);
        }

        public getHealthCareOrganisationTypesAsync(): Promise<Contract.IEnumTranslation[]> {
            return this.getPromise(
                'HealthCareParty/HealthCareOrganisationTypes',
                null,
                'organisationTypes',
                null,
                r => r.Items
            );
        }

        public findHealthCareProfessionalTypesAsync(): Promise<Contract.IEnumTranslation[]> {
            return this.getPromise('HealthCareParty/HealthCareProfessionalTypes');
        }

        public searchPersonPartyRoleAsync(
            query: Contract.Query.IPersonPartyRolesQuery
        ): Promise<Contract.ISearchResult<Contract.Read.IPartyRole>> {
            return this.getPromise('HealthCareParty/PersonPartyRoles', query, 'personPartyRole');
        }

        public searchOrganisationPartyRoleAsync(
            query: Contract.Query.IOrganisationPartyRolesQuery
        ): Promise<Contract.ISearchResult<Contract.Read.IPartyRole>> {
            return this.getPromise('HealthCareParty/OrganisationPartyRoles', query, 'organisationPartyRole');
        }
    }

    remeCareSharedModule.service('healthCarePartySvc', HealthCarePartyService);
}
