var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Helper;
            (function (Helper) {
                var FlaggedEnum;
                (function (FlaggedEnum) {
                    "use strict";
                    /** create a class definition for a Flagged Enum
                     * @method create
                     * @param _enum {enum} The enum definition being exteded
                     * @param _max {number} the maximum possible value of the enum being extended
                     * @returns {IFlaggedEnum} the class definition for the provided enum
                     */
                    FlaggedEnum.create = function (_enum, _max) {
                        var base = _enum, max = _max;
                        var Base = function (val) {
                            if (typeof (val) === "string") {
                                val = base[val];
                            }
                            this.value = val + 0;
                        };
                        var proto = Base.prototype;
                        proto.valueOf = function () { return this.value; };
                        proto.toString = function () {
                            var list = [];
                            for (var i = 1; i < max; i = i << 1) {
                                if ((this.value & i) !== 0) {
                                    list.push(base[i]);
                                }
                            }
                            return list.toString();
                        };
                        proto.toArray = function () {
                            var list = [];
                            for (var i = 1; i < max; i = i << 1) {
                                if ((this.value & i) !== 0) {
                                    list.push(new Base(i));
                                }
                            }
                            return list;
                        };
                        proto.contains = function (val) {
                            if (typeof (val) === "string") {
                                val = base[val];
                            }
                            return (this.value & val) === (val + 0);
                        };
                        proto.add = function (val) {
                            if (typeof (val) === "string") {
                                val = base[val];
                            }
                            return new Base(this.value | val);
                        };
                        proto.remove = function (val) {
                            if (typeof (val) === "string") {
                                val = this.base[val];
                            }
                            return new Base((this.value ^ val) & this.value);
                        };
                        proto.intersect = function (val) {
                            if (typeof (val) === "string") {
                                val = base[val];
                            }
                            var final = 0;
                            for (var i = 1; i < max; i = (i << 1)) {
                                if ((this.value & i) !== 0 && (val & i) !== 0) {
                                    final += i;
                                }
                            }
                            return new Base(final);
                        };
                        proto.equals = function (val) {
                            if (typeof (val) === "string") {
                                val = base[val];
                            }
                            return this.value === (val + 0);
                        };
                        return Base;
                    };
                })(FlaggedEnum = Helper.FlaggedEnum || (Helper.FlaggedEnum = {}));
            })(Helper = Framework.Helper || (Framework.Helper = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
