module RemeCare.Shared.Framework.InformedConsent {
    interface ILanguageExtension extends Shared.Contract.IEnumCodeTranslation {
        InformedConsentTranslation: Shared.Framework.Model.InformedConsentTranslation;
    }

    class AddOrUpdateInformedConsentController implements ng.IComponentController {
        public $transition$;
        public informedConsent: Shared.Framework.Model.InformedConsent;
        public informedConsentTitle: string;
        public currentLanguage: string;
        public form: ng.IFormController;
        public readOnly: boolean;
        public routePrefix: string;
        public languages: Array<Shared.Contract.IEnumCodeTranslation> = [];
        public informedConsentsWithLanguages: Array<ILanguageExtension>;
        public activeTab = 0;
        public versionRegex = /^\d+\.\d$/;
        
        public canApprove: boolean;
        public hasTranslationChanged: boolean;

        constructor(
            private readonly $translate: ng.translate.ITranslateService,
            private $timeout: ng.ITimeoutService,
            private readonly $state: ng.ui.IStateService,
            private readonly $stateParams,
            private readonly informedConsentSvc: Shared.Framework.Service.InformedConsentService,
            private readonly masterdataSvc: Shared.Framework.MasterdataService,
            private readonly modalBuilderFactory: Shared.Framework.Helper.ModalBuilderFactory,
            private readonly toaster: Shared.Framework.Toaster
        ) {}

        public async $onInit(): Promise<void> {
            this.routePrefix = this.$transition$.params().routePrefix;
            this.currentLanguage = this.$translate.preferredLanguage();
            this.setInformedConsent();
        }

        private async setInformedConsent(): Promise<void> {
            await this.getLanguages();
            if (this.informedConsent == null) {
                this.informedConsent = new Shared.Framework.Model.InformedConsent(this.languages);
                this.informedConsent.therapyId = this.$stateParams.therapyId;
            }
            this.getMatchingLanguages();
            this.updateName();
        }

        private async getLanguages(): Promise<void> {
            try {
                this.languages = await this.masterdataSvc.getInterfaceLanguagesAsync();
            } catch (e) {
                this.toaster.error(e);
            }
        }

        private getMatchingLanguages(): void {
            if (this.informedConsent != null) {
                this.informedConsentsWithLanguages = _.map(this.languages, l => {
                    return <ILanguageExtension>{
                        Code: l.Code,
                        Id: l.Id,
                        Text: l.Text,
                        InformedConsentTranslation: _.find(this.informedConsent.informedConsentTranslations, ict => {
                            return ict.culture === l.Code;
                        })
                    };
                });
            }
        }

        private updateName(): void {
            if (this.informedConsent != null) {
                const currentTitleTranslation = _.find(this.informedConsent.informedConsentTranslations, ict => {
                    return ict.culture === this.currentLanguage;
                });
                this.informedConsentTitle =
                    currentTitleTranslation != null && currentTitleTranslation.title != null
                        ? currentTitleTranslation.title
                        : this.$translate.instant('Views.InformedConsent.NewInformedConsent');
            }
        }

        public updateTherapyId(): void {
            if (this.informedConsent != null) {
                this.informedConsent.therapyId = this.$stateParams.therapyId;
            }
        }

        private async updateLanguages(): Promise<void> {
            try {
                this.languages = await this.masterdataSvc.getInterfaceLanguagesAsync();
                if (this.informedConsent != null) {
                    this.informedConsentsWithLanguages = _.map(this.languages, l => {
                        return <ILanguageExtension>{
                            Code: l.Code,
                            Id: l.Id,
                            Text: l.Text,
                            InformedConsentTranslation: _.find(
                                this.informedConsent.informedConsentTranslations,
                                ict => {
                                    return ict.culture === l.Code;
                                }
                            )
                        };
                    });

                    const currentTitleTranslation = _.find(this.informedConsent.informedConsentTranslations, ict => {
                        return ict.culture === this.$translate.preferredLanguage();
                    });
                    this.informedConsentTitle =
                        currentTitleTranslation != null && currentTitleTranslation.title != null
                            ? currentTitleTranslation.title
                            : this.$translate.instant('Views.InformedConsent.NewInformedConsent');
                    this.$timeout(() => (this.activeTab = 0));
                }
            } catch (e) {
                this.toaster.error(e);
            }
        }

        public $onChanges(onChangesObj: ng.IOnChangesObject): void {
            if (onChangesObj['informedConsent']) {
                this.updateTherapyId();
                this.updateLanguages();
                this.canApprove = true;
                this.hasTranslationChanged = false;
            }
        }

        public async confirm(): Promise<void> {
            try {
                if (this.informedConsent.id == null) {
                    await this.informedConsentSvc.saveInformedConsentDraft(this.informedConsent.toServerWrite());
                } else {
                    await this.informedConsentSvc.updateInformedConsentDraft(this.informedConsent.toServerWrite());
                }
                this.toaster.success('General.SaveSuccess');
                this.$state.go(this.routePrefix + 'informedConsents.overview');
            } catch (e) {
                this.toaster.error(e);
            }
        }

        public cancel(): void {
            this.$state.go(this.routePrefix + 'informedConsents.overview');
        }

        public approve(): void {
            this.modalBuilderFactory
                .createComponentModalBuilder<IApproveModalResult>('rcApproveModal')
                .setBindings({
                    informedConsentId: this.informedConsent.id,
                    minDate: this.informedConsent.getLastVersion().validFromDate
                })
                .setResultCallBack(async r => {
                    try {
                        await this.informedConsentSvc.approveInformedConsent(
                            this.informedConsent.id,
                            Shared.DateHelper.toServerDateString(r.date),
                            r.needsConfirmation
                        );
                        this.informedConsent.acceptDraftVersion(r.date);
                        this.readOnly = true;
                        this.toaster.success('General.SaveSuccess');
                    } catch (e) {
                        this.toaster.error(e);
                    }
                })
                .build();
        }
    }

    Shared.remeCareSharedModule.component('rcAddOrUpdateInformedConsent', {
        controller: AddOrUpdateInformedConsentController,
        templateUrl: 'framework/informedConsent/addOrUpdateInformedConsent/addOrUpdateInformedConsent.html',
        bindings: {
            informedConsent: '<',
            readOnly: '<',
            $transition$: '<',
            routePrefix: '<'
        }
    });
}
