namespace RemeCare.Shared.Framework.Model {
    export class TimeSpan {
        public Days: number;
        public Hours: number;
        public Minutes: number;

        constructor(serverObject?: Date) {
            if (serverObject != null) {
                this.Days = serverObject.getDate();
                this.Hours = serverObject.getHours();
                this.Minutes = serverObject.getMinutes();
            }
        }
    }
}
