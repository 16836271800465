namespace RemeCare.Shared.Framework.Directive {
    class InputToggleController implements ng.IComponentController {
        // bindings
        public changed: () => void;
        public disabled: boolean;
        public formCtrl: IFormController;
        public label: string;
        public model: boolean;
        public required: boolean;

        public $onInit(): void {}

        public labelClicked(): void {
            if (this.disabled) {
                return;
            }
            this.model = !this.model;
            this.changed();
        }
    }

    remeCareSharedModule.component('rcInputToggleInline', {
        bindings: {
            changed: '&ngChange',
            disabled: '=ngDisabled',
            label: '@',
            model: '=ngModel',
            required: '=ngRequired',
            guidanceIconLocation: '<',
            guidanceIconFunc: '<',
            uniqueId: '@',
        },
        controller: InputToggleController,
        require: {
            formCtrl: '^form',
        },
        templateUrl: 'views/formElements/inputToggleInline.html',
    });
}
