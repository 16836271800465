module RemeCare.Shared.Framework.Directive {

    class CloseButton {
        label: string;
        close: () => any;

        $onInit() {
            this.label = this.label || 'General.Close';
        }

        confirm(): void {
            this.close();
        }
    }

    remeCareSharedModule.component('rcClose',
    {
        controller: CloseButton,
        controllerAs: 'btnCtrl',
        templateUrl: 'views/controls/primaryButton.html',
        bindings: {
            close: '&',
            label: '@?',
            ngDisabled: '<?'
        }
    });
}