var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var AppEvents = /** @class */ (function () {
            function AppEvents() {
            }
            AppEvents.claimsChanged = 'claimsChanged';
            AppEvents.rightsChanged = 'rightsChanged';
            AppEvents.verticalScrollBar = 'verticalScrollBar';
            return AppEvents;
        }());
        Shared.AppEvents = AppEvents;
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
