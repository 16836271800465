namespace RemeCare.Shared.Framework.Directive {
    class InputQualitativeController implements ng.IComponentController {
        constructor() {}

        public $onInit(): void {}
    }

    remeCareSharedModule.component('rcInputQualitative', {
        bindings: {
            singleModel: '=', // The model to use if the ui control type has a single output (value)
            multiModel: '=', // the model to use if the ui control type has multiple outputs (list)
            label: '@',
            uiControlType: '=',
            options: '=',
            displayText: '@', // What should be shown in the drop down
            value: '@', // What will be saved in ng-model
            required: '=ngRequired',
            disabled: '=ngDisabled',
            inputClass: '<',
            iconField: '@',
            showErrors: '=',
            guidanceIconLocation: '<',
            guidanceIconFunc: '<',
            uniqueId: '@',
        },
        controller: InputQualitativeController,
        require: {
            formCtrl: '^form',
        },
        templateUrl: 'framework/directives/formElements/inputQualitative/inputQualitative.html',
    });
}
