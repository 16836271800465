var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Model;
            (function (Model) {
                var VersionValidityChange = /** @class */ (function () {
                    function VersionValidityChange(version) {
                        this.pointInTime = Shared.DateHelper.serverDateStringToDate(version.ValidFromDate);
                        this.newValidFromDate = Shared.DateHelper.serverDateStringToDate(version.ValidFromDate);
                    }
                    VersionValidityChange.prototype.toServerWrite = function () {
                        return {
                            PointInTime: Shared.DateHelper.toServerDateString(this.pointInTime),
                            NewValidFromDate: Shared.DateHelper.toServerDateString(this.newValidFromDate),
                        };
                    };
                    return VersionValidityChange;
                }());
                Model.VersionValidityChange = VersionValidityChange;
                var VersionFromUntilChange = /** @class */ (function (_super) {
                    __extends(VersionFromUntilChange, _super);
                    function VersionFromUntilChange(version) {
                        var _this = _super.call(this, version) || this;
                        _this.newValidUntilDate = Shared.DateHelper.serverDateStringToDate(version.ValidUntilDate);
                        return _this;
                    }
                    VersionFromUntilChange.prototype.toServerWrite = function () {
                        return {
                            PointInTime: Shared.DateHelper.toServerDateString(this.pointInTime),
                            NewValidFromDate: Shared.DateHelper.toServerDateString(this.newValidFromDate),
                            NewValidUntilDate: Shared.DateHelper.toServerDateString(this.newValidUntilDate),
                        };
                    };
                    return VersionFromUntilChange;
                }(VersionValidityChange));
                Model.VersionFromUntilChange = VersionFromUntilChange;
            })(Model = Framework.Model || (Framework.Model = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
