module RemeCare.Shared.Framework.Helper {

    export interface IAppConfig {
        baseUrl: string;
        googleAnalyticsTracker: string;
    }

    export class ConfigLoader {

        constructor(private $http: ng.IHttpService) { }

        public loadConfig(): ng.IHttpPromise<IAppConfig> {
            const url: string = './config.json';
            this.windowLocationOriginInternetExplorer10Fix();
            return this.$http.get<IAppConfig>(url).then(d => {
                d.data.baseUrl = window.location.origin + '/api/';
                return d;
            });
        }

        private windowLocationOriginInternetExplorer10Fix(): void {
            if (!window.location.origin) {
                (window.location as any).origin = `${window.location.protocol}//${window.location.hostname}`;
            }
        }
    }
}