'use strict';

module RemeCare.Shared.Contract {
    export interface IMediaLibItemQuestionDetail {
        Id: Contract.Guid;
        Question: string;
        SelectedValues: Array<Guid>;
        Sequence: number;
        Answers: Contract.IChoiceListOption[];
        ObservableEntity: IObservableEntity;
        UIControlType: Contract.Code.UIControlType;
        AnswerConditions: Array<Contract.Read.IAnswerCondition>;
    }
}
