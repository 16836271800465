namespace RemeCare.Digest {

    class DigestRunner {

        constructor(private $rootScope: ng.IRootScopeService) {
            this.run();
        }

        private run(): void {
            const self = this;
            window['Promise'] = ((Promise) => {
                const NewPromise = function (fn) {
                    const promise = new Promise(fn);

                    promise.then((value) => {
                        self.triggerDigestIfNeeded();

                        return value;
                    }, (reason) => {
                        self.triggerDigestIfNeeded();

                        return reason;
                    });

                    return promise;
                };

                // Clone the prototype
                NewPromise.prototype = Promise.prototype;

                // Clone all writable instance properties
                for (const propertyName of Object.getOwnPropertyNames(Promise)) {
                    const propertyDescription = Object.getOwnPropertyDescriptor(Promise, propertyName);

                    if (propertyDescription.writable) {
                        NewPromise[propertyName] = Promise[propertyName];
                    }
                }

                return NewPromise;
            })(Promise) as any;
        }

        private triggerDigestIfNeeded(): void {
            this.$rootScope.$applyAsync();
        }
    }

    remeCareDigestModule.run((
        $rootScope: ng.IRootScopeService
    ) => new DigestRunner(
        $rootScope
    ));
}