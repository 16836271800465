var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Contract;
        (function (Contract) {
            var Code;
            (function (Code) {
                var StructuralTherapyActionAttribute;
                (function (StructuralTherapyActionAttribute) {
                    StructuralTherapyActionAttribute[StructuralTherapyActionAttribute["MinutesUntilCloseRegistrationWindow"] = 1] = "MinutesUntilCloseRegistrationWindow";
                    StructuralTherapyActionAttribute[StructuralTherapyActionAttribute["DelayDateAfterStartCarePlan"] = 2] = "DelayDateAfterStartCarePlan";
                    StructuralTherapyActionAttribute[StructuralTherapyActionAttribute["PlannedDateTimeFrom"] = 3] = "PlannedDateTimeFrom";
                })(StructuralTherapyActionAttribute = Code.StructuralTherapyActionAttribute || (Code.StructuralTherapyActionAttribute = {}));
            })(Code = Contract.Code || (Contract.Code = {}));
        })(Contract = Shared.Contract || (Shared.Contract = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
