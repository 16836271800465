var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Service;
            (function (Service) {
                var HttpRequestInterceptor = /** @class */ (function () {
                    function HttpRequestInterceptor($location) {
                        var _this = this;
                        this.$location = $location;
                        this.request = function (r) { return _this.req(r); };
                    }
                    HttpRequestInterceptor.prototype.req = function (request) {
                        request.headers['X-AppStateUrl'] = this.$location.absUrl();
                        return request;
                    };
                    return HttpRequestInterceptor;
                }());
                Shared.remeCareSharedModule.factory('httpRequestInterceptor', function ($location) { return new HttpRequestInterceptor($location); });
            })(Service = Framework.Service || (Framework.Service = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
