module RemeCare.Shared.Framework.Model {

    export class ActorRoleInclusionDetail {
        id: Shared.Contract.Guid;
        healthCareOrganisationTypeId: number;
        partyRoleId: Shared.Contract.Guid;
        healthCareProfessionalTypeId: number;
        healthCareSpecialtyProfessionId: number;
        partyName: string;

        constructor(serverObject?: Contract.Read.IActorRoleInclusionDetail) {
            if (serverObject != null) {
                this.id = serverObject.Id;
                this.healthCareOrganisationTypeId = serverObject.HealthCareOrganisationTypeId;
                this.partyRoleId = serverObject.PartyRoleId;
                this.healthCareProfessionalTypeId = serverObject.HealthCareProfessionalTypeId;
                this.healthCareSpecialtyProfessionId = serverObject.HealthCareSpecialtyProfessionId;
                this.partyName = serverObject.PartyName;
            }
        }

        copy(): ActorRoleInclusionDetail {
            var result = new ActorRoleInclusionDetail();
            angular.copy(this, result);
            return result;
        }

        public toServerWrite(): Contract.Write.IActorRoleInclusionDetail {
            return <Contract.Write.IActorRoleInclusionDetail> {
                id: this.id,
                healthCareOrganisationTypeId: this.healthCareOrganisationTypeId,
                partyRoleId: this.partyRoleId,
                healthCareProfessionalTypeId: this.healthCareProfessionalTypeId,
                healthCareSpecialtyProfessionId: this.healthCareSpecialtyProfessionId
            };
        }
    }
} 