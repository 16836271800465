namespace RemeCare.Shared.Framework.Directive {
    class InputAddressController implements ng.IComponentController {
        public hasWarning: boolean;
        private warningDirty: boolean = false;

        constructor(private toaster: Shared.Framework.Toaster) {}

        public $onInit(): void {}

        public validate(address): any {
            this.hasWarning = false;
            if (
                address.country === Shared.Contract.Code.Country.Belgium &&
                address.zipCode &&
                !this.containsOnlyFourNumbers(address.zipCode)
            ) {
                if (!this.warningDirty) {
                    this.toaster.warning('General.ZipCodeWarning');
                    this.warningDirty = true;
                }
                this.hasWarning = true;
            } else {
                this.warningDirty = false;
            }
        }
        private containsOnlyFourNumbers(string) {
            return /^\d{4}$/.test(string);
        }
    }

    remeCareSharedModule.component('rcInputAddress', {
        templateUrl: 'views/formElements/inputAddress.html',
        controller: InputAddressController,
        bindings: {
            model: '=ngModel',
            required: '=ngRequired',
            countryRequired: '=',
            disabled: '=ngDisabled',
            readOnly: '=ngReadonly',
            countries: '=ngCountryOptions',
            complete: '=',
            guidanceIconLocation: '<',
            guidanceIconFunc: '<',
            uniqueId: '@',
        },
        require: {
            formCtrl: '^form',
        },
    });
}
