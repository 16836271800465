module RemeCare.Shared.Framework.Directive {

    class MinuteDirective implements ng.IDirective {
        require = 'ngModel';
        restrict = 'A';
        link = (scope, element, attrs, ngModelController) => {
            ngModelController.$parsers.push((data) => {
                return parseInt(data);
            });

            ngModelController.$formatters.push((data) => {
                if (data !== 0 && !data) return '';
                var s = data + '';
                while (s.length < 2) s = '0' + s;
                return s;
            });
        }
    }

    class HourDirective implements ng.IDirective {
        require = 'ngModel';
        restrict = 'A';
        link = (scope, element, attrs, ngModelController) => {
            ngModelController.$parsers.push((data) => {
                return parseInt(data);
            });

            ngModelController.$formatters.push((data) => {
                if (data !== 0 && !data) return '';
                var s = data + '';
                while (s.length < 2) s = '0' + s;
                return s;
            });
        }
    }

    remeCareSharedModule
        .directive('minute', () => new MinuteDirective())
        .directive('hour', () => new HourDirective());
}