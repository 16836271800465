var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Service;
            (function (Service) {
                var SidebarService = /** @class */ (function () {
                    function SidebarService() {
                    }
                    SidebarService.prototype.setLeftSidebarTitle = function (title) {
                        this.leftSidebarTitle = title;
                    };
                    SidebarService.prototype.getLeftSidebarTitle = function () {
                        return this.leftSidebarTitle;
                    };
                    SidebarService.prototype.setLeftSidebarIcon = function (icon) {
                        this.leftSidebarIcon = icon;
                    };
                    SidebarService.prototype.getLeftSidebarIcon = function () {
                        return this.leftSidebarIcon;
                    };
                    SidebarService.prototype.hasLeftSidebar = function () {
                        return (this.leftSidebarTitle != null && this.leftSidebarTitle !== '')
                            || (this.leftSidebarIcon != null && this.leftSidebarIcon !== '');
                    };
                    SidebarService.prototype.clear = function () {
                        this.leftSidebarTitle = null;
                        this.leftSidebarIcon = null;
                    };
                    return SidebarService;
                }());
                Service.SidebarService = SidebarService;
                Shared.remeCareSharedModule.service('sidebarSvc', SidebarService);
            })(Service = Framework.Service || (Framework.Service = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
