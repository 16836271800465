var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Filter;
        (function (Filter) {
            function findFilter() {
                return function (value, items, property) {
                    return _(items).find(function (i) { return i[property] === value; });
                };
            }
            Shared.remeCareSharedModule.filter('find', findFilter);
        })(Filter = Shared.Filter || (Shared.Filter = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
