var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var ToasterType = /** @class */ (function () {
                function ToasterType() {
                }
                ToasterType.success = 'success';
                ToasterType.error = 'error';
                ToasterType.info = 'info';
                ToasterType.warning = 'warning';
                return ToasterType;
            }());
            Framework.ToasterType = ToasterType;
            var Toaster = /** @class */ (function () {
                function Toaster($translate, toastr, $log) {
                    this.$translate = $translate;
                    this.toastr = toastr;
                    this.$log = $log;
                }
                Toaster.prototype.pop = function (type, title, content, persistent) {
                    if (persistent === void 0) { persistent = false; }
                    var options = persistent
                        ? {
                            closeButton: true,
                            timeOut: 0,
                            extendedTimeOut: 0,
                            tapToDismiss: false
                        }
                        : {};
                    var translatedTitle = this.$translate.instant(title);
                    var translatedContent = this.$translate.instant(content);
                    switch (type) {
                        case ToasterType.success:
                            this.toastr.success(translatedContent, translatedTitle, options);
                            break;
                        case ToasterType.error:
                            this.toastr.error(translatedContent, translatedTitle, options);
                            var stackTrace = this.stackTrace();
                            this.$log.debug(translatedTitle +
                                (translatedContent == null ? '' : ': ' + translatedContent) +
                                (stackTrace == null ? '' : '\r\n' + stackTrace));
                            break;
                        case ToasterType.info:
                            this.toastr.info(translatedContent, translatedTitle, options);
                            break;
                        case ToasterType.warning:
                            this.toastr.warning(translatedContent, translatedTitle, options);
                            break;
                        default:
                            this.toastr.info(translatedContent, translatedTitle, options);
                    }
                };
                Toaster.prototype.success = function (title, content, persistent) {
                    if (persistent === void 0) { persistent = false; }
                    this.pop(ToasterType.success, title, content, persistent);
                };
                Toaster.prototype.error = function (title, content, persistent) {
                    if (persistent === void 0) { persistent = false; }
                    this.pop(ToasterType.error, title, content, persistent);
                };
                Toaster.prototype.info = function (title, content, persistent) {
                    if (persistent === void 0) { persistent = false; }
                    this.pop(ToasterType.info, title, content, persistent);
                };
                Toaster.prototype.warning = function (title, content, persistent) {
                    if (persistent === void 0) { persistent = false; }
                    this.pop(ToasterType.warning, title, content, persistent);
                };
                Toaster.prototype.stackTrace = function () {
                    // Firefox & Chrome only, sorry
                    var error = new Error();
                    return error.stack;
                };
                return Toaster;
            }());
            Framework.Toaster = Toaster;
            angular
                .module('RemeCare.Shared')
                .value('toastr', toastr)
                .factory('toaster', function ($translate, toastr, $log) { return new Toaster($translate, toastr, $log); });
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
