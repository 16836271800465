var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Helper;
            (function (Helper) {
                var ButtonSelector = /** @class */ (function () {
                    function ButtonSelector(modalBuilderFactory) {
                        this.modalBuilderFactory = modalBuilderFactory;
                    }
                    ButtonSelector.prototype.select = function (items, selectors, separator, title, modalSize) {
                        var deferred = jQuery.Deferred();
                        var size = modalSize != null ? modalSize : Helper.ModalSize.small;
                        this.modalBuilderFactory
                            .createModalBuilder()
                            .setTemplateUrl('views/buttonSelector.html')
                            .setSize(size)
                            .setScope({
                            items: items,
                            selectors: selectors,
                            separator: separator,
                            title: title
                        })
                            .setResultCallBack(function (item) {
                            deferred.resolve(item);
                        })
                            .setDismissCallBack(function () {
                            deferred.reject();
                        })
                            .build();
                        return deferred;
                    };
                    return ButtonSelector;
                }());
                Helper.ButtonSelector = ButtonSelector;
                angular.module('RemeCare.Shared').factory('buttonSelector', function (modalBuilderFactory) { return new ButtonSelector(modalBuilderFactory); });
            })(Helper = Framework.Helper || (Framework.Helper = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
