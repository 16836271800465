var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Model;
            (function (Model) {
                var InformedConsentHistoryPart = /** @class */ (function () {
                    function InformedConsentHistoryPart(informedConsentHistory) {
                        this.informedConsentTitle = informedConsentHistory.InformedConsentTitle;
                        this.version = informedConsentHistory.Version;
                        this.validFrom = Shared.DateHelper.serverDateStringToDateTime(informedConsentHistory.ValidFrom);
                        this.patientInformedConsentStateCode = informedConsentHistory.PatientInformedConsentStateCode;
                        this.stateSetByPartyRole = informedConsentHistory.StateSetByPartyRole;
                        this.remark = informedConsentHistory.Remark;
                        var validUntil = Shared.DateHelper.serverDateStringToDateTime(informedConsentHistory.ValidUntil);
                        if (validUntil && moment(validUntil).isBefore(moment())) {
                            this.validUntil = validUntil;
                            this.revokedByPartyRole = informedConsentHistory.RevokedByPartyRole;
                            this.revokedRemark = informedConsentHistory.RevokedRemark;
                        }
                    }
                    return InformedConsentHistoryPart;
                }());
                Model.InformedConsentHistoryPart = InformedConsentHistoryPart;
            })(Model = Framework.Model || (Framework.Model = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
