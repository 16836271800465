var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Contract;
        (function (Contract) {
            var Code;
            (function (Code) {
                var LineType;
                (function (LineType) {
                    LineType[LineType["Normal"] = 1] = "Normal";
                    LineType[LineType["Thin"] = 2] = "Thin";
                    LineType[LineType["Dashed"] = 3] = "Dashed";
                })(LineType = Code.LineType || (Code.LineType = {}));
            })(Code = Contract.Code || (Contract.Code = {}));
        })(Contract = Shared.Contract || (Shared.Contract = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
