var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Grid;
            (function (Grid) {
                var PagingType;
                (function (PagingType) {
                    PagingType[PagingType["FullyPaged"] = 1] = "FullyPaged";
                    PagingType[PagingType["PrevNext"] = 2] = "PrevNext";
                })(PagingType = Grid.PagingType || (Grid.PagingType = {}));
                var PagingController = /** @class */ (function () {
                    function PagingController() {
                    }
                    PagingController.prototype.$onInit = function () {
                        this.isPrevNext = this.grid.pagingType === PagingType.PrevNext;
                    };
                    PagingController.prototype.goToPreviousPage = function () {
                        this.grid.pagingOptions.currentPage--;
                        this.grid.currentPageChanged();
                    };
                    PagingController.prototype.goToNextPage = function () {
                        this.grid.pagingOptions.currentPage++;
                        this.grid.currentPageChanged();
                    };
                    return PagingController;
                }());
                Shared.remeCareSharedModule.component('rcPaging', {
                    controller: PagingController,
                    bindings: {
                        grid: '=rcGrid'
                    },
                    templateUrl: 'views/grid/paging.html'
                });
            })(Grid = Framework.Grid || (Framework.Grid = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
