namespace RemeCare.Shared.Contract.Code {
    export enum Nationality {
        Belgian = 1,
        Dutch = 2,
        French = 3,
        German = 4,
        Luxembourgish = 5,
    }

    export enum NationalityIso2 {
        Belgian = 'BE',
        Dutch = 'NL',
        French = 'FR',
        German = 'DE',
        Luxembourgish = 'LU',
    }
}
