module RemeCare.Shared.Framework {

    interface ITimeStampTextAreaScope extends ng.IScope {
        model: string;
        label: string;
        disabled: boolean;
        addTimestamp: () => void;
        getCursorPosition: (textArea: HTMLTextAreaElement) => number;

        textModel: {
            model: string;
        }
    }

    class TimeStampTextAreaDirective implements ng.IDirective {

        constructor(
            private $timeout: ng.ITimeoutService,
            private $filter: ng.IFilterService,
            private authservice: Shared.Framework.AuthService) {

        }

        restrict: string = 'E';

        scope: { [boundProperty: string]: string } = {
            model: '=ngModel',
            required: '=ngRequired',
            adjustSize: '=',
            label: '@',
            textType: '@',
            labelAbove: '@'
        }

        templateUrl: string = 'views/timeStampTextArea.html';

        link: ng.IDirectiveLinkFn = (scope: ITimeStampTextAreaScope, element) => {
            scope.textModel = {
                model: scope.model
            }

            scope.addTimestamp = () => {
                var textArea = <HTMLTextAreaElement>element.find('textarea')[0];
                var cursorPos = scope.getCursorPosition(textArea);
                var timeStamp = this.$filter('date')(new Date(), 'short');
                var text = textArea.value;
                text = text.slice(0, cursorPos) + '[' + this.authservice.getNameOfUser() + ' ' + timeStamp + '] ' + text.slice(cursorPos, text.length);
                scope.textModel.model = text;

                textArea.focus();
            }

            scope.getCursorPosition = (textArea: HTMLTextAreaElement) => {
                var iCaretPos = 0;

                if ((<any>document).selection) {
                    textArea.focus();
                    var oSel = (<any>document).selection.createRange();
                    oSel.moveStart('character', -textArea.value.length);
                    iCaretPos = oSel.text.length;
                } else if (textArea.selectionStart || textArea.selectionStart == 0) {
                    iCaretPos = textArea.selectionStart;
                }
                return iCaretPos;
            }

            scope.$watch((s: ITimeStampTextAreaScope) => s.model, (newValue, oldValue) => {
                if (newValue !== oldValue && newValue !== scope.textModel.model) {
                    scope.textModel.model = newValue;
                }
            });

            scope.$watch((s: ITimeStampTextAreaScope) => s.textModel.model, (newValue, oldValue) => {
                if (newValue !== oldValue && newValue !== scope.model) {
                    scope.model = newValue;
                }
            });
        }
    }

    angular.module('RemeCare.Shared').directive('rcTimeStampTextArea',
        ($timeout,
        $filter: ng.IFilterService,
        authservice: Shared.Framework.AuthService
        ) => new TimeStampTextAreaDirective(
            $timeout,
            $filter,
            authservice));
}