var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var InformedConsent;
            (function (InformedConsent) {
                var PatientInformedConsent = Shared.Framework.Model.PatientInformedConsent;
                var PatientInformedConsentOverviewController = /** @class */ (function () {
                    function PatientInformedConsentOverviewController($dialog, $translate, gridBuilderSvc, informedConsentSvc, authservice, toaster) {
                        this.$dialog = $dialog;
                        this.$translate = $translate;
                        this.gridBuilderSvc = gridBuilderSvc;
                        this.informedConsentSvc = informedConsentSvc;
                        this.authservice = authservice;
                        this.toaster = toaster;
                    }
                    PatientInformedConsentOverviewController.prototype.$onInit = function () {
                        this.buildGrid();
                        this.patientId = this.authservice.getClaim(Shared.Framework.ClaimTypes.partyRoleId);
                        this.loadPatientInformedConsents();
                        this.grid.setData(this.acceptedInformedConsents);
                    };
                    PatientInformedConsentOverviewController.prototype.revoke = function () {
                        var _this = this;
                        this.$dialog
                            .warningMessageBox(this.$translate.instant('Views.InformedConsent.RevokeTitle'), this.$translate.instant('Views.InformedConsent.RevokeWarning'), [
                            { result: 'cancel', label: this.$translate.instant('General.Cancel'), cssClass: 'btn-default' },
                            {
                                result: 'yes',
                                label: this.$translate.instant('Views.InformedConsent.AcceptRevoke'),
                                cssClass: 'btn-primary'
                            }
                        ])
                            .then(function (result) { return __awaiter(_this, void 0, void 0, function () {
                            var e_1;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        if (!(result === 'yes')) return [3 /*break*/, 4];
                                        _a.label = 1;
                                    case 1:
                                        _a.trys.push([1, 3, , 4]);
                                        return [4 /*yield*/, this.informedConsentSvc.revokePatientInformedConsent(this.patientId, this.informedConsent.informedConsentId, null)];
                                    case 2:
                                        _a.sent();
                                        this.loadPatientInformedConsents();
                                        return [3 /*break*/, 4];
                                    case 3:
                                        e_1 = _a.sent();
                                        this.toaster.error(e_1);
                                        return [3 /*break*/, 4];
                                    case 4: return [2 /*return*/];
                                }
                            });
                        }); });
                    };
                    PatientInformedConsentOverviewController.prototype.loadPatientInformedConsents = function () {
                        return __awaiter(this, void 0, void 0, function () {
                            var informedConsentsSearchResult, patientInformedConsentReadModel, e_2;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        _a.trys.push([0, 4, , 5]);
                                        return [4 /*yield*/, this.informedConsentSvc.getInformedConsentSummary(this.patientId)];
                                    case 1:
                                        informedConsentsSearchResult = _a.sent();
                                        this.informedConsents = informedConsentsSearchResult.Items;
                                        this.acceptedInformedConsents = _.filter(this.informedConsents, function (ic) { return ic.Status === Shared.Contract.Code.PatientInformedConsentStatusCode.Accepted; });
                                        this.informedConsentsToAccept = _.filter(this.informedConsents, function (ic) { return ic.Status !== Shared.Contract.Code.PatientInformedConsentStatusCode.Accepted; });
                                        this.grid.setData(this.acceptedInformedConsents);
                                        if (this.acceptedInformedConsents.length < 1 && this.informedConsentsToAccept.length > 0) {
                                            this.authservice.signOut();
                                        }
                                        if (!(this.acceptedInformedConsents.length > 0)) return [3 /*break*/, 3];
                                        return [4 /*yield*/, this.informedConsentSvc.getPatientInformedConsent(this.patientId, this.acceptedInformedConsents[0].InformedConsentId)];
                                    case 2:
                                        patientInformedConsentReadModel = _a.sent();
                                        this.informedConsent = new PatientInformedConsent(patientInformedConsentReadModel);
                                        _a.label = 3;
                                    case 3: return [3 /*break*/, 5];
                                    case 4:
                                        e_2 = _a.sent();
                                        this.toaster.error(e_2);
                                        return [3 /*break*/, 5];
                                    case 5: return [2 /*return*/];
                                }
                            });
                        });
                    };
                    PatientInformedConsentOverviewController.prototype.showDetail = function (informedConsent) {
                        return __awaiter(this, void 0, void 0, function () {
                            var acceptedInformedConsentReadModel;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: return [4 /*yield*/, this.informedConsentSvc.getPatientInformedConsent(this.patientId, informedConsent.InformedConsentId)];
                                    case 1:
                                        acceptedInformedConsentReadModel = _a.sent();
                                        this.informedConsent = new PatientInformedConsent(acceptedInformedConsentReadModel);
                                        return [2 /*return*/];
                                }
                            });
                        });
                    };
                    PatientInformedConsentOverviewController.prototype.buildGrid = function () {
                        var _this = this;
                        this.grid = this.gridBuilderSvc
                            .createGridBuilder()
                            .addColumn('TherapyName', 'General.Therapy')
                            .addColumn('Version', 'General.Version')
                            .addColumn('Title', 'General.Name')
                            .addActionColumn('folder-open', function (ic) { return _this.showDetail(ic); })
                            .build();
                    };
                    return PatientInformedConsentOverviewController;
                }());
                Shared.remeCareSharedModule.component('rcPatientInformedConsentOverview', {
                    controller: PatientInformedConsentOverviewController,
                    templateUrl: 'framework/informedConsent/patientInformedConsentOverview/patientInformedConsentOverview.html'
                });
            })(InformedConsent = Framework.InformedConsent || (Framework.InformedConsent = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
