module RemeCare.Shared.Contract.Code {

    export enum RequestAccessType {
        DoctorSpecialistAssociatedToAHospital = 1, 
        DoctorSpecialistNotAssociatedToAHospital = 2,
        GeneralPractitioner=3,
        HospitalNurse = 4,
        HomeCareNurse = 5,
        HospitalPharmacist = 6,
        Pharmacist = 7,
        HospitalStaff = 8, 
        Other = 9,
    }
}