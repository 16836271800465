var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Contract;
        (function (Contract) {
            var Code;
            (function (Code) {
                var RegistrationSource;
                (function (RegistrationSource) {
                    RegistrationSource[RegistrationSource["Unkown"] = 0] = "Unkown";
                    RegistrationSource[RegistrationSource["RemeCare"] = 1] = "RemeCare";
                    RegistrationSource[RegistrationSource["RemeCareApp"] = 2] = "RemeCareApp";
                    RegistrationSource[RegistrationSource["TelemonitoringDevice"] = 3] = "TelemonitoringDevice";
                })(RegistrationSource = Code.RegistrationSource || (Code.RegistrationSource = {}));
            })(Code = Contract.Code || (Contract.Code = {}));
        })(Contract = Shared.Contract || (Shared.Contract = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
