var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Service;
        (function (Service) {
            var DayService = /** @class */ (function () {
                function DayService($locale) {
                    this.$locale = $locale;
                }
                DayService.prototype.getDays = function () {
                    var days = this.$locale.DATETIME_FORMATS.DAY;
                    return [
                        {
                            name: days[1],
                            value: Shared.Contract.Code.DayOfWeek.Monday,
                        },
                        {
                            name: days[2],
                            value: Shared.Contract.Code.DayOfWeek.Tuesday,
                        },
                        {
                            name: days[3],
                            value: Shared.Contract.Code.DayOfWeek.Wednesday,
                        },
                        {
                            name: days[4],
                            value: Shared.Contract.Code.DayOfWeek.Thursday,
                        },
                        {
                            name: days[5],
                            value: Shared.Contract.Code.DayOfWeek.Friday,
                        },
                        {
                            name: days[6],
                            value: Shared.Contract.Code.DayOfWeek.Saturday,
                        },
                        {
                            name: days[0],
                            value: Shared.Contract.Code.DayOfWeek.Sunday,
                        },
                    ];
                };
                DayService.prototype.getWeekDayOccurences = function () {
                    return [
                        {
                            text: 'General.First',
                            value: 1,
                        },
                        {
                            text: 'General.Second',
                            value: 2,
                        },
                        {
                            text: 'General.Third',
                            value: 3,
                        },
                        {
                            text: 'General.Forth',
                            value: 4,
                        },
                        {
                            text: 'General.Last',
                            value: 9,
                        },
                    ];
                };
                return DayService;
            }());
            Service.DayService = DayService;
            Shared.remeCareSharedModule.factory('daySvc', function ($locale) { return new DayService($locale); });
        })(Service = Shared.Service || (Shared.Service = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
