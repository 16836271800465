module RemeCare.Shared.Contract.Code {
    
    export enum PartyRoleType {
        Patient = 1,
        HealthCareOrganisation = 2,
        HealthCareProfessional = 3,
        HealthCareNonProfessional = 4,
        ContactPerson = 5,
        Employee = 6
    }
}