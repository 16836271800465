var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var FileReadDirective = /** @class */ (function () {
                function FileReadDirective() {
                    this.scope = {
                        fileRead: '=rcFileRead'
                    };
                    this.link = function (scope, element) {
                        element.bind('change', function (changeEvent) {
                            var reader = new FileReader();
                            reader.onload = function (loadEvent) {
                                scope.$apply(function () {
                                    scope.fileRead = loadEvent.target.result; // result is there, but typescript doesn't know it
                                });
                            };
                            reader.readAsDataURL(changeEvent.target.files[0]);
                        });
                    };
                }
                return FileReadDirective;
            }());
            angular.module('RemeCare.Shared').directive('rcFileRead', function () { return new FileReadDirective(); });
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
