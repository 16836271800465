var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Service;
            (function (Service) {
                var StateDecorator = /** @class */ (function () {
                    function StateDecorator($delegate, $log) {
                        this.$delegate = $delegate;
                        this.$log = $log;
                    }
                    StateDecorator.factory = function ($delegate, $log) {
                        return new StateDecorator($delegate, $log).decorate();
                    };
                    StateDecorator.prototype.decorate = function () {
                        var _this = this;
                        var delegateReload = this.$delegate.reload;
                        var reload = function () {
                            var state = _this.$delegate.current;
                            _this.$log.debug("Reload state called for " + JSON.stringify(state));
                            return delegateReload();
                        };
                        this.$delegate.reload = reload;
                        return this.$delegate;
                    };
                    return StateDecorator;
                }());
                Shared.remeCareSharedModule.decorator('$state', [
                    '$delegate',
                    '$log',
                    StateDecorator.factory
                ]);
            })(Service = Framework.Service || (Framework.Service = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
