var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var TranslationResolver = /** @class */ (function () {
                function TranslationResolver($translate, $translatePartialLoader, $q) {
                    this.$translate = $translate;
                    this.$translatePartialLoader = $translatePartialLoader;
                    this.$q = $q;
                }
                TranslationResolver.prototype.setParts = function (keys) {
                    var _this = this;
                    angular.forEach(keys, function (key) {
                        _this.$translatePartialLoader.addPart(key);
                    });
                    return this.$translate.refresh();
                };
                return TranslationResolver;
            }());
            Framework.TranslationResolver = TranslationResolver;
            angular.module('RemeCare.Shared').factory('translationResolver', function ($translate, $translatePartialLoader, $q) { return new TranslationResolver($translate, $translatePartialLoader, $q); });
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
