var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Selector = /** @class */ (function () {
                function Selector($timeout) {
                    this.$timeout = $timeout;
                }
                Selector.prototype.$onInit = function () { };
                Selector.prototype.selectItem = function (item) {
                    if (this.disabled)
                        return;
                    this.itemId = item.Id;
                    this.action({ item: item });
                };
                return Selector;
            }());
            Shared.remeCareSharedModule.component('rcSelector', {
                controller: Selector,
                controllerAs: 'selectorCtrl',
                templateUrl: 'views/selector.html',
                bindings: {
                    items: '<',
                    disabled: '<ngDisabled',
                    action: '&',
                    itemId: '='
                }
            });
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
