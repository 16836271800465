/// <reference path="../../contract/code/sourceParameterRepresentationType.ts"/>
namespace RemeCare.Shared.Framework.Factory {
    import SourceParameterRepresentationType = Shared.Contract.Code.SourceParameterRepresentationType;

    export interface ISourceParameterRepresentationFactory {
        createAsync(
            serverObject: Shared.Contract.Read.ISourceParameterRepresentation
        ): Promise<Model.SourceParameterRepresentation>;
        createFromTypeAsync(type: SourceParameterRepresentationType): Promise<Model.SourceParameterRepresentation>;
    }

    class SourceParameterRepresentationFactory implements ISourceParameterRepresentationFactory {
        constructor(private readonly masterdataSvc: Shared.Framework.MasterdataService) {}

        public async createAsync(
            serverObject: Shared.Contract.Read.ISourceParameterRepresentation
        ): Promise<Model.SourceParameterRepresentation> {
            if (serverObject == null) {
                return null;
            }
            return await this.createRepresentationAsync(serverObject.Type, serverObject);
        }

        public createFromTypeAsync(
            type: SourceParameterRepresentationType
        ): Promise<Model.SourceParameterRepresentation> {
            return this.createRepresentationAsync(type);
        }

        private async createRepresentationAsync(
            type: SourceParameterRepresentationType,
            serverObject?: Shared.Contract.Read.ISourceParameterRepresentation
        ): Promise<Model.SourceParameterRepresentation> {
            switch (type) {
                case SourceParameterRepresentationType.ChartLine:
                    const r = await this.masterdataSvc.getLineTypesAsync();
                    return new Model.ChartLine(<Shared.Contract.Read.IChartLine>serverObject, r);
                case SourceParameterRepresentationType.ChartBar:
                    return new Model.ChartBar(<Shared.Contract.Read.IChartBar>serverObject);
                case SourceParameterRepresentationType.TableColumn:
                    return new Model.TableColumn(serverObject);
                case SourceParameterRepresentationType.ColourQualitative:
                    return new Model.ColourQualitative(<Shared.Contract.Read.IColourQualitative>serverObject);
                case SourceParameterRepresentationType.Boxplot:
                    return new Model.Boxplot(<Shared.Contract.Read.IBoxplot>serverObject);
                default:
                    throw new Error('Unknown part type');
            }
        }
    }

    remeCareSharedModule.factory(
        'sourceParameterRepresentationFactory',
        masterdataSvc => new SourceParameterRepresentationFactory(masterdataSvc)
    );
}
