namespace RemeCare.Shared.Framework.Periodicity {
    import Periodicity = Framework.Model.Periodicity;
    import PeriodicityFactory = Framework.Factory.PeriodicityFactory;
    import DailyAlternationType = RemeCare.Shared.Contract.Code.DailyAlternationType;
    import PeriodicityDay = RemeCare.Shared.Framework.Model.PeriodicityDay;

    interface IPeriodicityScope extends Framework.IBaseScope {
        periodicity: Periodicity;
        possibleDurationUnits: Shared.Contract.IEnumTranslation[];
        limitedDurationUnits: Shared.Contract.IEnumTranslation[];
        extraLimitedDurationUnits: Shared.Contract.IEnumTranslation[];
        clearDuration: () => void;
        newPeriodicity: () => (durationUnit: Shared.Contract.IEnumTranslation) => void;
        maxDuration: () => number;
        setEvenOddDays: () => (dailyAlternationType: DailyAlternationType) => void;
        setRecurrentCycleMembers: () => (periodicity: Periodicity) => void;
        changeAlternation: () => void;
        changeEvenOddDays: () => void;
        isRoot: () => boolean;
    }

    class PeriodicityController extends Framework.ControllerBase<IPeriodicityScope> {
        private updatingAlternation: boolean;

        constructor(
            protected $scope: IPeriodicityScope,
            protected $translate: ng.translate.ITranslateService, 
            protected toaster: Framework.Toaster,
            private readonly masterdataSvc: Framework.MasterdataService) {
            super($scope, $translate, toaster);

            $scope.clearDuration = () => this.clearDuration();
            $scope.maxDuration = () => this.maxDuration();
            $scope.changeAlternation = () => this.changeAlternation();
            $scope.changeEvenOddDays = () => this.changeEvenOddDays();
            $scope.isRoot = () => this.isRoot();
        }

        public $onInit(): void {
            this.updatingAlternation = false;
            this.getDurationsUnitsAsync();
            this.watchRecurrence();
        }

        private watchRecurrence(): void {
            this.$scope.$watch(
                (s: IPeriodicityScope) => s.periodicity.recurrence.Unit,
                (newValue: Shared.Contract.Code.DurationUnit, oldValue: Shared.Contract.Code.DurationUnit) => {
                    if (newValue != null && (oldValue == null || newValue !== oldValue)) {
                        const unit = _.find(this.$scope.possibleDurationUnits, u => u.Id === newValue);
                        if (this.$scope.newPeriodicity) {
                            this.$scope.newPeriodicity()(unit);
                        } else if (this.$scope.periodicity.parentPeriodicity != null) {
                            this.newPeriodicity(newValue);
                        }
                        this.setDurationUnits();
                    }
                }
            );
        }

        private changeAlternation(): void {
            if (this.$scope.setRecurrentCycleMembers) {
                this.$scope.setRecurrentCycleMembers()(this.$scope.periodicity);
            }
        }

        private changeEvenOddDays(): void {
            if (this.$scope.setEvenOddDays) {
                this.$scope.setEvenOddDays()((this.$scope.periodicity as PeriodicityDay).dailyAlternationType);
            }
        }

        private newPeriodicity(durationUnit: Shared.Contract.Code.DurationUnit): void {
            const oldPeriodicity = this.$scope.periodicity;
            const newPeriodicity = new PeriodicityFactory().createPeriodicityFromDurationUnit(durationUnit);
            const type = newPeriodicity.type;
            oldPeriodicity.setCommonParts(newPeriodicity);
            newPeriodicity.type = type;
            newPeriodicity.parentPeriodicity = oldPeriodicity.parentPeriodicity;
            newPeriodicity.parentPeriodicity.childPeriodicity = newPeriodicity;
            newPeriodicity.childPeriodicity = null;
            this.$scope.periodicity = newPeriodicity;
        }

        private async getDurationsUnitsAsync(): Promise<void> {
            try {
                const durationUnits = await this.masterdataSvc.getDurationUnitsAsync();
                this.$scope.possibleDurationUnits = _(durationUnits).filter(
                    unit => unit.Id !== Shared.Contract.Code.DurationUnit.Hours
                );
                this.setDurationUnits();
            } catch (e) {
                this.toaster.error(e);
            }
        }

        private setDurationUnits(): void {
            if (this.$scope.periodicity != null && this.$scope.periodicity.parentPeriodicity != null) {
                this.$scope.limitedDurationUnits = _(this.$scope.possibleDurationUnits).filter(
                    u => u.Id <= this.$scope.periodicity.parentPeriodicity.recurrence.Unit
                );
                this.$scope.extraLimitedDurationUnits = _(this.$scope.possibleDurationUnits).filter(
                    u => u.Id < this.$scope.periodicity.parentPeriodicity.recurrence.Unit
                );
            } else {
                this.$scope.limitedDurationUnits = this.$scope.possibleDurationUnits;
                this.$scope.extraLimitedDurationUnits = this.$scope.possibleDurationUnits;
            }
        }

        private clearDuration(): void {
            this.$scope.periodicity.duration = {} as Shared.Contract.IDuration;
        }

        private maxDuration(): number {
            if (
                this.$scope.periodicity.parentPeriodicity == null ||
                this.$scope.periodicity.duration == null ||
                this.$scope.periodicity.duration.Unit == null
            ) {
                return 0;
            }
            const parentMax = this.getDurationInDays(this.$scope.periodicity.parentPeriodicity.recurrence);
            return this.daysToDurationUnit(parentMax, this.$scope.periodicity.duration.Unit);
        }

        private daysToDurationUnit(days: number, durationUnit: Shared.Contract.Code.DurationUnit): number {
            switch (durationUnit) {
                case Shared.Contract.Code.DurationUnit.Days:
                    return days;
                case Shared.Contract.Code.DurationUnit.Weeks:
                    return days / 7;
                case Shared.Contract.Code.DurationUnit.Months:
                    return days / 28;
                case Shared.Contract.Code.DurationUnit.Years:
                    return days / 365;
            }
            return 0;
        }

        private isRoot(): boolean {
            return this.$scope.periodicity && !this.$scope.periodicity.parentPeriodicity;
        }

        private getDurationInDays(duration: Shared.Contract.IDuration): number {
            switch (duration.Unit) {
                case Shared.Contract.Code.DurationUnit.Days:
                    return duration.Quantity;
                case Shared.Contract.Code.DurationUnit.Weeks:
                    return duration.Quantity * 7;
                case Shared.Contract.Code.DurationUnit.Months:
                    return duration.Quantity * 28;
                case Shared.Contract.Code.DurationUnit.Years:
                    return duration.Quantity * 365;
            }
            return 0;
        }
    }

    remeCareSharedModule.controller('periodicityCtrl', PeriodicityController);
}
