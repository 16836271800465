namespace RemeCare.Shared.Framework {
    interface IAutoLogOutScope extends ng.IScope, ng.ui.bootstrap.IModalScope {
        newAutoLogOutForm: ng.IFormController;

        remainingTime: string;

        logout(): void;
        stayLoggedIn(): void;
    }

    class AutoLogOutCtrl implements ng.IController {
        private countDown: number;
        private countDownTimer: ng.IPromise<any>;

        constructor(
            private readonly $interval: ng.IIntervalService,
            private readonly $log: ng.ILogService,
            private readonly $scope: IAutoLogOutScope,
            private readonly authservice: Framework.AuthService
        ) {
            $scope.stayLoggedIn = () => this.stayLoggedIn();
            $scope.logout = () => this.logout();
        }

        public $onInit(): void {
            // Time before sign out 1min30
            this.countDown = 90;
            this.formatRemainingTime();
            this.startCountDown();
        }

        public logout(): void {
            this.stop();
            this.$log.debug('Logging out due to 15 minutes of inactivity');
            this.authservice.signOut();
            this.$scope.$close();
        }

        public stayLoggedIn(): void {
            this.stop();
            this.$scope.$close();
        }

        private startCountDown(): void {
            this.countDownTimer = this.$interval(() => {
                this.countDown--;
                this.formatRemainingTime();
                if (this.countDown === 0) {
                    this.logout();
                }
            }, 1000);
        }

        private stop = () => {
            this.$interval.cancel(this.countDownTimer);
        };

        private formatRemainingTime(): void {
            const minutes = Math.floor(this.countDown / 60);
            const seconds = this.countDown % 60;
            this.$scope.remainingTime = `${minutes}:${this.pad(seconds)}`;
        }

        private pad(number: number): string {
            return `0${number}`.slice(-2);
        }
    }

    remeCareSharedModule.controller('autoLogOutCtrl', AutoLogOutCtrl);
}
