module RemeCare.Shared.Framework.Factory {

    export class TargetValueFactory {

        public createTargetValue(serverObject: Shared.Contract.Read.ITargetValue): Framework.Model.TargetValue {
            var result;
            switch (serverObject.Type) {
                case Shared.Contract.Code.TargetValueType.TargetDose:
                    result = new Framework.Model.TargetDose(<Shared.Contract.Read.ITargetDose>serverObject);
                    break;
                case Shared.Contract.Code.TargetValueType.TargetPhysicalQuantity:
                    result = new Framework.Model.TargetPhysicalQuantity(<Shared.Contract.Read.ITargetPhysicalQuantity>serverObject);
                    break;
                case Shared.Contract.Code.TargetValueType.TargetPhysicalQuantityRange:
                    result = new Framework.Model.TargetPhysicalQuantityRange(<Shared.Contract.Read.ITargetPhysicalQuantityRange>serverObject);
                    break;
                default:
                    result = new Framework.Model.TargetValue();
            }
            return result;
        }
    }
}