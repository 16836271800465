module RemeCare.Shared.Contract.Code {

    export enum AlarmLevel {
        Low = 1,
        Medium = 2,
        High = 3
    }

    export enum AlarmStatus {
        Open = 1,
        Pending = 2,
        Handled = 3
    }
}