module RemeCare.Shared.Framework {

    export class AppVersionService extends ApiServiceBase {

        public static $inject = Framework.ApiServiceBase.injectionParameters;

        getApplicationInformation():
            ng.IHttpPromise<Contract.IApplicationInfo> {
            return this.get('CommercialVersion');
        }
    }

    remeCareSharedModule.service('appVersionSvc', AppVersionService);
}