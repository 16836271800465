var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Directive;
            (function (Directive) {
                var VerticalScrollListenerDirective = /** @class */ (function () {
                    function VerticalScrollListenerDirective($rootScope) {
                        var _this = this;
                        this.$rootScope = $rootScope;
                        this.restrict = 'A';
                        this.link = function (scope, element) {
                            scope.$watch(function () { return element[0].clientWidth; }, function () {
                                _this.$rootScope.$emit(Shared.AppEvents.verticalScrollBar);
                            });
                        };
                    }
                    // @ngInject
                    VerticalScrollListenerDirective.factory = function ($rootScope) {
                        return new VerticalScrollListenerDirective($rootScope);
                    };
                    return VerticalScrollListenerDirective;
                }());
                Shared.remeCareSharedModule.directive('rcVerticalScrollListener', VerticalScrollListenerDirective.factory);
            })(Directive = Framework.Directive || (Framework.Directive = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
