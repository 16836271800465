namespace RemeCare.Shared.Framework.Service {
    declare var riveted;

    export class UserTrackService {
        private inactiveTimer: ng.IPromise<any>;
        private lastActiveTime: Date;
        private inactiveMinutes: number;
        private allowedIdleMinutes = 15;

        constructor(
            private readonly $timeout: ng.ITimeoutService,
            private readonly modalBuilderFactory: Framework.Helper.ModalBuilderFactory,
            private readonly $log: ng.ILogService,
            private readonly authservice: Framework.AuthService
        ) {
            window.onunload = () => this.deleteAuthCookies();
        }

        public startTracking(): void {
            this.$log.debug('Start tracking whether or not user is active');
            this.lastActiveTime = DateHelper.now();
            this.inactiveMinutes = 0;
            this.setActivity();

            riveted.init({
                // reportInterval: 5,   // Default: 5
                // idleTimeout: 15,      // Default: 30
                // nonInteraction: true,  // Default: true
                eventHandler: () => {
                    this.lastActiveTime = DateHelper.now();
                    if (this.inactiveMinutes > 0) {
                        this.$log.debug('User is active again');
                        this.inactiveMinutes = 0;
                    }
                },
            });
        }

        public userHasBeenActive(): boolean {
            const currentInactiveMinutes = Math.floor(
                (DateHelper.now().getTime() - this.lastActiveTime.getTime()) / 60000
            );
            if (currentInactiveMinutes > this.inactiveMinutes) {
                this.inactiveMinutes = currentInactiveMinutes;
                this.$log.debug(`User seems inactive for ${currentInactiveMinutes} minutes.`);
            }

            const userActive = DateHelper.addMinutes(this.lastActiveTime, this.allowedIdleMinutes) > DateHelper.now();
            return userActive || !this.authservice.isAuthenticated();
        }

        private setActivity(): void {
            this.$timeout.cancel(this.inactiveTimer);
            this.inactiveTimer = this.$timeout(() => {
                if (this.userHasBeenActive()) {
                    this.setActivity();
                    return;
                }

                this.loadTimeOutModalScreen();
            }, 6000);
        }

        private deleteAuthCookies(): void {
            this.authservice.signOut();
        }

        private loadTimeOutModalScreen(): void {
            this.$log.debug('Opening auto logout window. Last active time: ', this.lastActiveTime);
            this.modalBuilderFactory
                .createModalBuilder()
                .setTemplateUrl('views/autoLogOut.html')
                .setController('autoLogOutCtrl')
                .setSize(Framework.Helper.ModalSize.medium)
                .build();
        }
    }

    remeCareSharedModule.service('userTrackSvc', UserTrackService);
}
