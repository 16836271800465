module RemeCare.Shared.Framework.Directive {

    export interface IFormController extends ng.IFormController {
        showValidationErrorMessage(): void;
    }

    class FormDirective implements ng.IDirective {

        constructor(
            private readonly $rootScope: ng.IRootScopeService,
            private readonly $timeout: ng.ITimeoutService,
            private readonly $translate: ng.translate.ITranslateService,
            private readonly toaster: Framework.Toaster) {

        }

        public restrict = 'E';

        public require = 'form';

        public link: ng.IDirectiveLinkFn = (scope: ng.IScope, elem, attrs, formCtrl: IFormController) => {
            scope.$watch(() => formCtrl.$submitted, () => {
                this.$timeout(() => {
                    this.$rootScope.$broadcast('formSubmitted');
                });
            });

            formCtrl.showValidationErrorMessage = () => {
                this.toaster.error(this.$translate.instant('General.FieldValidationError'));
            }
        }
    }

    remeCareSharedModule.directive('form',
        (
            $rootScope: ng.IRootScopeService,
            $timeout: ng.ITimeoutService,
            $translate: ng.translate.ITranslateService,
            toaster: Framework.Toaster
        ) => new FormDirective(
            $rootScope,
            $timeout,
            $translate,
            toaster
        ));
}