var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Directive;
        (function (Directive) {
            var PriorityCircleDirective = /** @class */ (function () {
                function PriorityCircleDirective() {
                    this.scope = {
                        priority: '=',
                        width: '@',
                        radius: '@'
                    };
                    this.restrict = 'E';
                    this.templateUrl = 'views/priorityCircle.html';
                    this.transclude = true;
                    this.link = function (scope, element) {
                        scope.$watch(function (s) { return s.priority; }, function (newValue, oldValue) {
                            if (newValue === Shared.Contract.Code.AlarmLevel.High) {
                                scope.priorityColor = '#e60000';
                            }
                            else if (newValue === Shared.Contract.Code.AlarmLevel.Medium) {
                                scope.priorityColor = '#eb9d1a';
                            }
                            else if (newValue === Shared.Contract.Code.AlarmLevel.Low) {
                                scope.priorityColor = '#555';
                            }
                        });
                    };
                }
                return PriorityCircleDirective;
            }());
            angular.module('RemeCare.Shared').directive('rcPriorityCircle', function () { return new PriorityCircleDirective(); });
        })(Directive = Shared.Directive || (Shared.Directive = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
