var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Contract;
        (function (Contract) {
            var Code;
            (function (Code) {
                var DateTypeCode;
                (function (DateTypeCode) {
                    DateTypeCode[DateTypeCode["Single"] = 1] = "Single";
                    DateTypeCode[DateTypeCode["Range"] = 2] = "Range";
                })(DateTypeCode = Code.DateTypeCode || (Code.DateTypeCode = {}));
            })(Code = Contract.Code || (Contract.Code = {}));
        })(Contract = Shared.Contract || (Shared.Contract = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
