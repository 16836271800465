'use strict';

module RemeCare.Shared.Contract {
    
    export interface IDocument {
        Id: Contract.Guid;
        Name: string;
        Url: string;
    }

    export interface IDatedDocument extends IDocument {
        ReferenceDate?: string;
    }

    export interface IMetaDataDocument extends IDatedDocument {
        Size: number;
        UploadDateTime: string;
        Uploader: string;
    }
}