var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Model;
            (function (Model) {
                var CareRequestUIControl = /** @class */ (function () {
                    function CareRequestUIControl(serverObject) {
                        if (serverObject != null) {
                            this.id = serverObject.Id;
                            this.uiControlTypeId = serverObject.UIControlTypeId;
                            this.modalityType = serverObject.ModalityType;
                            this.careRequestStepProcessActivityReference = serverObject.CareRequestStepProcessActivityReference;
                        }
                        else {
                            this.modalityType = {};
                        }
                    }
                    CareRequestUIControl.prototype.toServerWrite = function () {
                        return {
                            id: this.id,
                            uiControlTypeId: this.uiControlTypeId,
                            modalityTypeId: this.modalityType.Id,
                            careRequestStepProcessActivityReference: this.careRequestStepProcessActivityReference,
                        };
                    };
                    return CareRequestUIControl;
                }());
                Model.CareRequestUIControl = CareRequestUIControl;
                var CareRequestSetup = /** @class */ (function () {
                    function CareRequestSetup(serverObject) {
                        if (serverObject != null) {
                            var factory_1 = new Framework.Factory.CareRequestPartFactory();
                            this.careRequestParts = _(serverObject.CareRequestParts).map(function (p) { return factory_1.createCareRequestPart(p); });
                            this.careRequestPartsIncludingInactive = _(serverObject.CareRequestPartsIncludingInactive).map(function (p) {
                                return factory_1.createCareRequestPart(p);
                            });
                            this.textHeader = serverObject.TextHeader;
                            this.textFooter = serverObject.TextFooter;
                            this.careRequestProcessReference = serverObject.CareRequestProcessReference;
                            this.isLatestValidCareRequestSetupVersion = serverObject.IsLatestValidCareRequestSetupVersion;
                            this.autoCreateUserForPatient = serverObject.AutoCreateUserForPatient;
                        }
                        else {
                            this.careRequestParts = [];
                        }
                    }
                    CareRequestSetup.prototype.toServerWrite = function () {
                        return {
                            careRequestParts: _(this.careRequestParts).map(function (cp) { return cp.toServerWrite(); }),
                            careRequestPartsIncludingInactive: _(this.careRequestPartsIncludingInactive).map(function (cp) {
                                return cp.toServerWrite();
                            }),
                            textHeader: this.textHeader,
                            textFooter: this.textFooter,
                            careRequestProcessReference: this.careRequestProcessReference,
                            autoCreateUserForPatient: this.autoCreateUserForPatient
                        };
                    };
                    return CareRequestSetup;
                }());
                Model.CareRequestSetup = CareRequestSetup;
                var CareRequestPart = /** @class */ (function () {
                    function CareRequestPart(serverObject) {
                        this.uiControls = [];
                        this.answerConditions = [];
                        if (serverObject != null) {
                            this.id = serverObject.Id;
                            this.sequence = serverObject.Sequence;
                            this.type = serverObject.Type;
                            this.text = serverObject.Text;
                            this.preceedingTitle = serverObject.PreceedingTitle;
                            this.uiControls = _(serverObject.UIControls).map(function (x) { return new CareRequestUIControl(x); });
                            this.localIdentity = serverObject.LocalIdentity;
                            this.isActive = serverObject.IsActive;
                            this.answerConditions = _(serverObject.AnswerConditions).map(function (x) { return new AnswerConditionDefinition(x); });
                        }
                    }
                    CareRequestPart.prototype.getDetail = function () {
                        return '';
                    };
                    CareRequestPart.prototype.getDropdownText = function () {
                        var text;
                        switch (this.type.Id) {
                            case Shared.Contract.Code.CareRequestPartType.QuantitativeAnamnesis:
                                text = this.type.Text + ': ' + this.valueOf().quantitativeCharacteristic.Name;
                                break;
                            case Shared.Contract.Code.CareRequestPartType.QualitativeAnamnesis:
                                text = this.type.Text + ': ' + this.valueOf().qualitativeEntity.Text;
                                break;
                            case Shared.Contract.Code.CareRequestPartType.Date:
                                text = this.type.Text + ': ' + this.valueOf().name;
                                break;
                            case Shared.Contract.Code.CareRequestPartType.TherapyOption:
                                text = this.type.Text + ': ' + this.valueOf().name;
                                break;
                            default:
                                text = this.type.Text;
                                break;
                        }
                        return this.sequence + '. ' + text;
                    };
                    CareRequestPart.prototype.toServerWrite = function () {
                        return {
                            id: this.id,
                            sequence: this.sequence,
                            type: this.type.Id,
                            text: this.text,
                            preceedingTitle: this.preceedingTitle,
                            uiControls: _(this.uiControls).map(function (u) { return u.toServerWrite(); }),
                            isActive: this.isActive,
                        };
                    };
                    return CareRequestPart;
                }());
                Model.CareRequestPart = CareRequestPart;
                var CareRequestorDefinition = /** @class */ (function (_super) {
                    __extends(CareRequestorDefinition, _super);
                    function CareRequestorDefinition(serverObject) {
                        var _this = _super.call(this, serverObject) || this;
                        if (serverObject != null) {
                            _this.canBeSearchedByExternal = serverObject.CareRequestor.CanBeSearchedByExternal;
                            _this.roleRequestingHealthCareProfessional =
                                serverObject.CareRequestor.RoleRequestingHealthCareProfessional != null
                                    ? new Model.ActorRole(serverObject.CareRequestor.RoleRequestingHealthCareProfessional)
                                    : null;
                            _this.roleRequestingHealthCareOrganisation =
                                serverObject.CareRequestor.RoleRequestingHealthCareOrganisation != null
                                    ? new Model.ActorRole(serverObject.CareRequestor.RoleRequestingHealthCareOrganisation)
                                    : null;
                        }
                        else {
                            _this.canBeSearchedByExternal = false;
                        }
                        return _this;
                    }
                    CareRequestorDefinition.prototype.toServerWrite = function () {
                        var result = _super.prototype.toServerWrite.call(this);
                        result.canBeSearchedByExternal = this.canBeSearchedByExternal;
                        result.roleRequestingHealthCareProfessionalId =
                            this.roleRequestingHealthCareProfessional != null
                                ? this.roleRequestingHealthCareProfessional.actorRoleId
                                : null;
                        result.roleRequestingHealthCareOrganisationId =
                            this.roleRequestingHealthCareOrganisation != null
                                ? this.roleRequestingHealthCareOrganisation.actorRoleId
                                : null;
                        return result;
                    };
                    return CareRequestorDefinition;
                }(CareRequestPart));
                Model.CareRequestorDefinition = CareRequestorDefinition;
                var CareRequestRoleDefinition = /** @class */ (function (_super) {
                    __extends(CareRequestRoleDefinition, _super);
                    function CareRequestRoleDefinition(serverObject) {
                        var _this = _super.call(this, serverObject) || this;
                        if (serverObject != null) {
                            _this.careRequestRoleConfigurations = _(serverObject.CareRequestRoleConfigurations).map(function (crc) { return new CareRequestRoleConfiguration(crc); });
                        }
                        else {
                            _this.careRequestRoleConfigurations = [];
                        }
                        return _this;
                    }
                    CareRequestRoleDefinition.prototype.toServerWrite = function () {
                        var result = _super.prototype.toServerWrite.call(this);
                        result.careRequestRoleConfigurations = _(this.careRequestRoleConfigurations).map(function (crc) {
                            return crc.toServerWrite();
                        });
                        return result;
                    };
                    return CareRequestRoleDefinition;
                }(CareRequestPart));
                Model.CareRequestRoleDefinition = CareRequestRoleDefinition;
                var CareRequestRoleConfiguration = /** @class */ (function () {
                    function CareRequestRoleConfiguration(serverObject) {
                        if (serverObject != null) {
                            this.id = serverObject.Id;
                            this.role = new Model.ActorRole(serverObject.Role);
                            this.canBeSearchedByExternal = serverObject.CanBeSearchedByExternal;
                            this.isVisible = true;
                        }
                        else {
                            this.canBeSearchedByExternal = false;
                        }
                    }
                    CareRequestRoleConfiguration.prototype.toServerWrite = function () {
                        return {
                            actorRoleId: this.role.actorRoleId,
                            canBeSearchedByExternal: this.canBeSearchedByExternal,
                        };
                    };
                    return CareRequestRoleConfiguration;
                }());
                Model.CareRequestRoleConfiguration = CareRequestRoleConfiguration;
                var AnamnesisDefinition = /** @class */ (function (_super) {
                    __extends(AnamnesisDefinition, _super);
                    function AnamnesisDefinition(serverObject) {
                        var _this = _super.call(this, serverObject) || this;
                        if (serverObject != null) {
                            _this.name = serverObject.Name;
                            _this.showInMonitoringDashboard = serverObject.ShowInMonitoringDashboard;
                            _this.showInPatientFile = serverObject.ShowInPatientFile;
                        }
                        return _this;
                    }
                    AnamnesisDefinition.prototype.toServerWrite = function () {
                        var result = _super.prototype.toServerWrite.call(this);
                        result.name = this.name;
                        result.showInPatientFile = this.showInPatientFile;
                        result.showInMonitoringDashboard = this.showInMonitoringDashboard;
                        return result;
                    };
                    return AnamnesisDefinition;
                }(CareRequestPart));
                Model.AnamnesisDefinition = AnamnesisDefinition;
                var QualitativeAnamnesisDefinition = /** @class */ (function (_super) {
                    __extends(QualitativeAnamnesisDefinition, _super);
                    function QualitativeAnamnesisDefinition(serverObject) {
                        var _this = _super.call(this, serverObject) || this;
                        _this.answerConditions = [];
                        if (serverObject != null) {
                            _this.qualitativeEntity = serverObject.QualitativeAnamnesis.QualitativeEntity;
                            _this.codeSet = serverObject.QualitativeAnamnesis.CodeSet;
                            _this.uiControlType = serverObject.QualitativeAnamnesis.UIControlType;
                            _this.answerConditions = _(serverObject.AnswerConditions).map(function (x) { return new AnswerConditionDefinition(x); });
                        }
                        return _this;
                    }
                    QualitativeAnamnesisDefinition.prototype.getDetail = function () {
                        return this.qualitativeEntity != null ? this.qualitativeEntity.Text : '';
                    };
                    QualitativeAnamnesisDefinition.prototype.toServerWrite = function () {
                        var result = _super.prototype.toServerWrite.call(this);
                        result.qualitativeEntityId = this.qualitativeEntity != null ? this.qualitativeEntity.Id : null;
                        result.codeSetId = this.codeSet != null ? this.codeSet.Id : null;
                        result.uiControlTypeId = this.uiControlType != null ? this.uiControlType.Id : null;
                        result.answerConditions = this.answerConditions;
                        return result;
                    };
                    return QualitativeAnamnesisDefinition;
                }(AnamnesisDefinition));
                Model.QualitativeAnamnesisDefinition = QualitativeAnamnesisDefinition;
                var AnswerConditionDefinition = /** @class */ (function () {
                    function AnswerConditionDefinition(serverObject) {
                        if (serverObject != null) {
                            this.id = serverObject.Id;
                            this.answerCodeSetItemId = serverObject.AnswerCodeSetItemId;
                            this.answerBoolean = serverObject.AnswerBoolean;
                            this.AnswerTherapyDiseaseId = serverObject.AnswerTherapyDiseaseId;
                            this.goToCareRequestPartId = serverObject.GoToCareRequestPartId;
                        }
                    }
                    return AnswerConditionDefinition;
                }());
                Model.AnswerConditionDefinition = AnswerConditionDefinition;
                var QuantitativeAnamnesisDefinition = /** @class */ (function (_super) {
                    __extends(QuantitativeAnamnesisDefinition, _super);
                    function QuantitativeAnamnesisDefinition(serverObject) {
                        var _this = _super.call(this, serverObject) || this;
                        if (serverObject != null) {
                            _this.quantitativeCharacteristic = serverObject.QuantitativeAnamnesis.QuantitativeCharacteristic;
                            _this.unit = serverObject.QuantitativeAnamnesis.Unit;
                            _this.decimals = serverObject.QuantitativeAnamnesis.Decimals;
                            _this.minimumValue = serverObject.QuantitativeAnamnesis.MinimumValue;
                            _this.maximumValue = serverObject.QuantitativeAnamnesis.MaximumValue;
                        }
                        return _this;
                    }
                    QuantitativeAnamnesisDefinition.prototype.getDetail = function () {
                        return this.quantitativeCharacteristic != null ? this.quantitativeCharacteristic.Name : '';
                    };
                    QuantitativeAnamnesisDefinition.prototype.getMinRegistrationValue = function () {
                        if (this.minimumValue != null) {
                            return this.minimumValue;
                        }
                        if (this.unit) {
                            return this.unit.MinimumValue;
                        }
                        return undefined;
                    };
                    QuantitativeAnamnesisDefinition.prototype.getMaxRegistrationValue = function () {
                        if (this.maximumValue != null) {
                            return this.maximumValue;
                        }
                        if (this.unit) {
                            return this.unit.MaximumValue;
                        }
                        return undefined;
                    };
                    QuantitativeAnamnesisDefinition.prototype.toServerWrite = function () {
                        var result = _super.prototype.toServerWrite.call(this);
                        result.quantitativeCharacteristicId =
                            this.quantitativeCharacteristic != null ? this.quantitativeCharacteristic.Id : null;
                        result.unitId = this.unit != null ? this.unit.Id : null;
                        result.decimals = this.decimals;
                        result.minimumValue = this.minimumValue;
                        result.maximumValue = this.maximumValue;
                        return result;
                    };
                    return QuantitativeAnamnesisDefinition;
                }(AnamnesisDefinition));
                Model.QuantitativeAnamnesisDefinition = QuantitativeAnamnesisDefinition;
                var OptionDefinition = /** @class */ (function (_super) {
                    __extends(OptionDefinition, _super);
                    function OptionDefinition(serverObject) {
                        var _this = _super.call(this, serverObject) || this;
                        _this.answerConditions = [];
                        if (serverObject != null) {
                            _this.name = serverObject.Name;
                            _this.showInMonitoringDashboard = serverObject.ShowInMonitoringDashboard;
                            _this.showInPatientFile = serverObject.ShowInPatientFile;
                            _this.answerConditions = _(serverObject.AnswerConditions).map(function (x) { return new AnswerConditionDefinition(x); });
                        }
                        return _this;
                    }
                    OptionDefinition.prototype.getDetail = function () {
                        return this.name || '';
                    };
                    OptionDefinition.prototype.toServerWrite = function () {
                        var result = _super.prototype.toServerWrite.call(this);
                        result.name = this.name;
                        result.showInPatientFile = this.showInPatientFile;
                        result.showInMonitoringDashboard = this.showInMonitoringDashboard;
                        result.answerConditions = this.answerConditions;
                        return result;
                    };
                    return OptionDefinition;
                }(CareRequestPart));
                Model.OptionDefinition = OptionDefinition;
                var DateDefinition = /** @class */ (function (_super) {
                    __extends(DateDefinition, _super);
                    function DateDefinition(serverObject) {
                        var _this = _super.call(this, serverObject) || this;
                        if (serverObject != null) {
                            _this.isInternalCarePlanStartDate = serverObject.IsInternalCarePlanStartDate;
                            _this.isInclusionDate = serverObject.IsInclusionDate;
                            _this.name = serverObject.Name;
                            _this.showInMonitoringDashboard = serverObject.ShowInMonitoringDashboard;
                            _this.showInPatientFile = serverObject.ShowInPatientFile;
                        }
                        return _this;
                    }
                    DateDefinition.prototype.getDetail = function () {
                        return this.name || '';
                    };
                    DateDefinition.prototype.toServerWrite = function () {
                        var result = _super.prototype.toServerWrite.call(this);
                        result.isInternalCarePlanStartDate = this.isInternalCarePlanStartDate;
                        result.isInclusionDate = this.isInclusionDate;
                        result.name = this.name;
                        result.showInPatientFile = this.showInPatientFile;
                        result.showInMonitoringDashboard = this.showInMonitoringDashboard;
                        return result;
                    };
                    return DateDefinition;
                }(CareRequestPart));
                Model.DateDefinition = DateDefinition;
                var StringDefinition = /** @class */ (function (_super) {
                    __extends(StringDefinition, _super);
                    function StringDefinition(serverObject) {
                        var _this = _super.call(this, serverObject) || this;
                        if (serverObject != null) {
                            _this.name = serverObject.Name;
                            _this.showInMonitoringDashboard = serverObject.ShowInMonitoringDashboard;
                            _this.showInPatientFile = serverObject.ShowInPatientFile;
                        }
                        return _this;
                    }
                    StringDefinition.prototype.getDetail = function () {
                        return this.name || '';
                    };
                    StringDefinition.prototype.toServerWrite = function () {
                        var result = _super.prototype.toServerWrite.call(this);
                        result.name = this.name;
                        result.showInPatientFile = this.showInPatientFile;
                        result.showInMonitoringDashboard = this.showInMonitoringDashboard;
                        return result;
                    };
                    return StringDefinition;
                }(CareRequestPart));
                Model.StringDefinition = StringDefinition;
                var CareRequestDocumentDefinition = /** @class */ (function (_super) {
                    __extends(CareRequestDocumentDefinition, _super);
                    function CareRequestDocumentDefinition(serverObject) {
                        var _this = _super.call(this, serverObject) || this;
                        _this.fileTypes = [];
                        _this.documentCategories = [];
                        if (serverObject != null) {
                            _this.allowsAllFileTypes = serverObject.CareRequestDocument.AllowsAllFileTypes;
                            _this.hasReferenceDate = serverObject.CareRequestDocument.HasReferenceDate;
                            _this.fileTypes = serverObject.CareRequestDocument.FileTypes;
                            _this.documentCategories = _(serverObject.CareRequestDocument.DocumentCategories).map(function (dc) { return dc.Id; });
                        }
                        return _this;
                    }
                    CareRequestDocumentDefinition.prototype.toServerWrite = function () {
                        var result = _super.prototype.toServerWrite.call(this);
                        result.allowsAllFileTypes = this.allowsAllFileTypes;
                        result.hasReferenceDate = this.hasReferenceDate;
                        result.fileTypes = _(this.fileTypes).map(function (ft) { return ft.Id; });
                        result.documentCategories = this.documentCategories;
                        return result;
                    };
                    return CareRequestDocumentDefinition;
                }(CareRequestPart));
                Model.CareRequestDocumentDefinition = CareRequestDocumentDefinition;
                var MedicationSchemaDefinition = /** @class */ (function (_super) {
                    __extends(MedicationSchemaDefinition, _super);
                    function MedicationSchemaDefinition(serverObject) {
                        var _this = _super.call(this, serverObject) || this;
                        if (serverObject != null) {
                            _this.showInMonitoringDashboard = serverObject.ShowInMonitoringDashboard;
                            _this.showInPatientFile = serverObject.ShowInPatientFile;
                            _this.medicationActionId = serverObject.MedicationActionId;
                        }
                        return _this;
                    }
                    MedicationSchemaDefinition.prototype.getDetail = function () {
                        return this.preceedingTitle != null ? this.preceedingTitle : '';
                    };
                    MedicationSchemaDefinition.prototype.toServerWrite = function () {
                        var result = _super.prototype.toServerWrite.call(this);
                        result.showInMonitoringDashboard = this.showInMonitoringDashboard;
                        result.showInPatientFile = this.showInPatientFile;
                        result.medicationActionId = this.medicationActionId;
                        return result;
                    };
                    return MedicationSchemaDefinition;
                }(CareRequestPart));
                Model.MedicationSchemaDefinition = MedicationSchemaDefinition;
                var DiseaseDefinition = /** @class */ (function (_super) {
                    __extends(DiseaseDefinition, _super);
                    function DiseaseDefinition(serverObject) {
                        var _this = _super.call(this, serverObject) || this;
                        _this.answerConditions = [];
                        if (serverObject != null) {
                            _this.answerConditions = _(serverObject.AnswerConditions).map(function (x) { return new AnswerConditionDefinition(x); });
                        }
                        return _this;
                    }
                    DiseaseDefinition.prototype.getDetail = function () {
                        return this.preceedingTitle != null ? this.preceedingTitle : '';
                    };
                    DiseaseDefinition.prototype.toServerWrite = function () {
                        var result = _super.prototype.toServerWrite.call(this);
                        result.answerConditions = this.answerConditions;
                        return result;
                    };
                    return DiseaseDefinition;
                }(CareRequestPart));
                Model.DiseaseDefinition = DiseaseDefinition;
            })(Model = Framework.Model || (Framework.Model = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
