var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var InformedConsent;
            (function (InformedConsent) {
                var AddOrUpdateInformedConsentTranslationController = /** @class */ (function () {
                    function AddOrUpdateInformedConsentTranslationController(gridBuilderSvc, modalBuilderFactory) {
                        this.gridBuilderSvc = gridBuilderSvc;
                        this.modalBuilderFactory = modalBuilderFactory;
                        this.tinymceOptions = {
                            menubar: false,
                            min_height: 250,
                            theme_url: '/modern/theme.min.js',
                            plugins: 'autolink link code table wordcount',
                            toolbar: 'undo redo | styleselect | cut copy paste | bold italic underline strikethrough subscript superscript removeformat | alignleft aligncenter alignright alignjustify | outdent indent | link code table',
                            default_link_target: '_blank',
                            baseURL: '/lib',
                        };
                        this.tinymceOptionsReadOnly = {
                            readonly: 1,
                            menubar: false,
                            toolbar: false,
                            min_height: 250,
                            theme_url: '/modern/theme.min.js',
                            baseURL: '/lib',
                        };
                    }
                    AddOrUpdateInformedConsentTranslationController.prototype.$onInit = function () {
                        return __awaiter(this, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                this.buildGrid();
                                this.grid.setData(this.translation.questions);
                                return [2 /*return*/];
                            });
                        });
                    };
                    AddOrUpdateInformedConsentTranslationController.prototype.$onChanges = function (onChangesObj) {
                        if (onChangesObj.translation) {
                            if (this.grid != null) {
                                if (this.translation != null) {
                                    this.grid.setData(this.translation.questions);
                                }
                            }
                        }
                        if (onChangesObj.activeTab) {
                            // Hacky way to trigger the hacky refresh of grid
                            if (this.activeTab === this.tabIndex && this.grid) {
                                this.grid.setData(this.grid.getData());
                            }
                        }
                    };
                    AddOrUpdateInformedConsentTranslationController.prototype.addCheckbox = function () {
                        var _this = this;
                        var maxSequence = Math.max.apply(null, _.map(this.translation.questions, function (q) {
                            return q.sequence;
                        }));
                        var newSequence = maxSequence < 0 ? 1 : maxSequence + 1;
                        this.hasChanged = true;
                        this.modalBuilderFactory
                            .createComponentModalBuilder('rcAddCheckboxModal')
                            .setBindings({
                            sequence: newSequence,
                            culture: this.translation.culture,
                        })
                            .setResultCallBack(function (r) {
                            _this.grid.addRow(r);
                        })
                            .build();
                    };
                    AddOrUpdateInformedConsentTranslationController.prototype.buildGrid = function () {
                        var _this = this;
                        var grid = this.gridBuilderSvc
                            .createGridBuilder()
                            .addTextColumn('question', 'Views.InformedConsent.Title', function (x) { return _this.readOnly; })
                            .addConditionalDeleteButtonColumn(function (x) { return _this.readOnly; }, function (deleted) {
                            _this.translation.questions.forEach(function (q) {
                                if (q.sequence > deleted.sequence) {
                                    q.sequence--;
                                }
                            });
                        });
                        if (!this.readOnly) {
                            grid.addUpDownButtonColumn('sequence');
                        }
                        this.grid = grid.build();
                    };
                    return AddOrUpdateInformedConsentTranslationController;
                }());
                Shared.remeCareSharedModule.component('rcAddOrUpdateInformedConsentTranslation', {
                    controller: AddOrUpdateInformedConsentTranslationController,
                    templateUrl: 'framework/informedConsent/addOrUpdateInformedConsent/addOrUpdateInformedConsentTranslation.html',
                    bindings: {
                        translation: '<',
                        readOnly: '<readonly',
                        activeTab: '<',
                        tabIndex: '<',
                        hasChanged: '=',
                    },
                });
            })(InformedConsent = Framework.InformedConsent || (Framework.InformedConsent = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
