var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Service;
            (function (Service) {
                var GuidanceApiService = /** @class */ (function (_super) {
                    __extends(GuidanceApiService, _super);
                    function GuidanceApiService(baseUrl, $http, spinnerSvc, $q, $anchorScroll, $location, $translate) {
                        var _this = _super.call(this, baseUrl, $http, spinnerSvc, $q) || this;
                        _this.baseUrl = baseUrl;
                        _this.$http = $http;
                        _this.spinnerSvc = spinnerSvc;
                        _this.$q = $q;
                        _this.$anchorScroll = $anchorScroll;
                        _this.$location = $location;
                        _this.$translate = $translate;
                        _this.guidanceItems = [];
                        _this.guidanceData = [];
                        _this.$location = $location;
                        _this.$anchorScroll = $anchorScroll;
                        return _this;
                    }
                    GuidanceApiService.prototype.createGuidanceItem = function (id, code) {
                        var _this = this;
                        return new Promise(function (resolve, reject) {
                            var item = {
                                Id: id,
                                Code: code,
                                Visible: false,
                                Show: false,
                                CodeData: null,
                            };
                            _this.guidanceItems.push(item);
                            _this.getOrLoadGuidanceData(item.Code)
                                .then(function (d) {
                                item.Visible = _this.checkContent(d);
                                resolve(item.Visible);
                            })
                                .catch(function (o) { return resolve(false); });
                        });
                    };
                    GuidanceApiService.prototype.getGuidance = function (code) {
                        var data = _.find(this.guidanceData, function (gd) { return gd.Code === code; });
                        if (data != null) {
                            return data.Data;
                        }
                        return null;
                    };
                    GuidanceApiService.prototype.getGuidanceItem = function (id) {
                        return _.find(this.guidanceItems, function (gd) { return gd.Id === id; });
                    };
                    GuidanceApiService.prototype.toggleItem = function (id, overrideValue) {
                        if (overrideValue === void 0) { overrideValue = null; }
                        var item = _.find(this.guidanceItems, function (gd) { return gd.Id === id; });
                        item.Show = overrideValue !== null ? overrideValue : !item.Show;
                        if (item.Show) {
                            this.$anchorScroll.yOffset = 55;
                            this.$location.hash("gds_" + id);
                            var cId = '#gd_' + id;
                            $(cId).on('slide.bs.carousel', function (e) {
                                var iframes = $('.guidance-iframe-container iframe');
                                for (var i = 0; i < iframes.length; i++) {
                                    var player = iframes[i];
                                    var slideType = player
                                        .getAttribute('src')
                                        .toLowerCase()
                                        .indexOf('vimeo') > 0
                                        ? 'vimeo'
                                        : 'other';
                                    var command = void 0;
                                    if (slideType === 'vimeo') {
                                        command = {
                                            method: 'pause',
                                            value: 'true',
                                        };
                                    }
                                    else {
                                        command = {
                                            event: 'command',
                                            func: 'pauseVideo',
                                        };
                                    }
                                    // check if the player exists.
                                    if (player !== undefined) {
                                        // post our command to the iframe.
                                        player.contentWindow.postMessage(JSON.stringify(command), '*');
                                    }
                                }
                            });
                        }
                        item.CodeData = _.find(this.guidanceData, function (gd) { return gd.Code === item.Code; });
                    };
                    GuidanceApiService.prototype.checkContent = function (data) {
                        if (data == null || data.Data == null || data.Data.GuidanceSteps == null) {
                            return false;
                        }
                        var stepsWithContent = 0;
                        var lang = this.$translate.preferredLanguage();
                        _(data.Data.GuidanceSteps).forEach(function (part, index) {
                            _(part.Translations).forEach(function (tran) {
                                if (tran.Culture === lang) {
                                    stepsWithContent |= tran.Content != null ? 1 : 0;
                                }
                            });
                        });
                        return stepsWithContent === 1;
                    };
                    GuidanceApiService.prototype.getOrLoadGuidanceData = function (code) {
                        var _this = this;
                        return new Promise(function (resolve, reject) {
                            var data = _.find(_this.guidanceData, function (gd) { return gd.Code === code; });
                            if (data == null) {
                                _this.getGuidanceAsync(code)
                                    .then(function (o) {
                                    if (o.GuidanceSteps != null && o.GuidanceSteps.length > 0) {
                                        // Test again to avoid pushing duplicates
                                        var test = _.find(_this.guidanceData, function (gd) { return gd.Code === code; });
                                        if (test == null) {
                                            data = { Code: code, Data: null };
                                            data.Data = o;
                                            _this.guidanceData.push(data);
                                            resolve(data);
                                        }
                                        else {
                                            resolve(test);
                                        }
                                    }
                                })
                                    .catch(reject);
                            }
                            else {
                                resolve(data);
                            }
                        });
                    };
                    GuidanceApiService.prototype.getGuidanceAsync = function (code) {
                        return this.getPromise("Guidance/" + code, null, null, true);
                    };
                    GuidanceApiService.$inject = ['baseUrl', '$http', 'spinnerSvc', '$q', '$anchorScroll', '$location', '$translate'];
                    return GuidanceApiService;
                }(Shared.Framework.ApiServiceBase));
                Service.GuidanceApiService = GuidanceApiService;
                Shared.remeCareSharedModule.service('guidanceApiSvc', GuidanceApiService);
            })(Service = Framework.Service || (Framework.Service = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
