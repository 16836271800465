var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Directive;
            (function (Directive) {
                var InputDateController = /** @class */ (function () {
                    function InputDateController() {
                    }
                    InputDateController.prototype.$onInit = function () { };
                    InputDateController.prototype.onDateChanged = function () {
                        if (this.change) {
                            var changeFunction = this.change();
                            if (_.isFunction(changeFunction)) {
                                changeFunction();
                            }
                        }
                    };
                    return InputDateController;
                }());
                Shared.remeCareSharedModule.component('rcInputDate', {
                    bindings: {
                        model: '=ngModel',
                        required: '=?ngRequired',
                        readOnly: '=?ngReadonly',
                        disabled: '=?ngDisabled',
                        hasWarning: '<?ngWarning',
                        showTime: '=?',
                        label: '@',
                        minDate: '=?',
                        maxDate: '=?',
                        adjustSize: '=?',
                        change: '&?',
                        startingView: '@?',
                        guidanceIconLocation: '<',
                        guidanceIconFunc: '<',
                        uniqueId: '@',
                    },
                    controller: InputDateController,
                    require: {
                        formCtrl: '^form',
                    },
                    templateUrl: 'framework/directives/formElements/inputDate/inputDate.html',
                    transclude: true,
                });
            })(Directive = Framework.Directive || (Framework.Directive = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
