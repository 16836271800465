var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var ShowReadOnlyPeriodicityModalController = /** @class */ (function () {
                function ShowReadOnlyPeriodicityModalController(gridBuilderSvc, $locale, daySvc) {
                    this.gridBuilderSvc = gridBuilderSvc;
                    this.$locale = $locale;
                    this.daySvc = daySvc;
                }
                ShowReadOnlyPeriodicityModalController.prototype.$onInit = function () {
                    return __awaiter(this, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            this.initialisePeriodicities();
                            this.CarePlanStartDateReadable = Shared.DateHelper.dateHumanReadible(this.carePlanStartDate);
                            this.days = this.daySvc.getDays();
                            return [2 /*return*/];
                        });
                    });
                };
                ShowReadOnlyPeriodicityModalController.prototype.isDayChecked = function (dayValue, daysOfWeekValue) {
                    return (daysOfWeekValue & dayValue) === dayValue;
                };
                ShowReadOnlyPeriodicityModalController.prototype.getDaysChecked = function (daysOfWeekValue) {
                    var _this = this;
                    var daysString = '';
                    _.forEach(this.days, function (d) {
                        if (_this.isDayChecked(d.value, daysOfWeekValue)) {
                            if (daysString !== '') {
                                daysString = daysString + ', ';
                            }
                            daysString = daysString + ' ' + d.name;
                        }
                    });
                    return daysString;
                };
                ShowReadOnlyPeriodicityModalController.prototype.getWeekDayOccurance = function (value) {
                    var weekDayOccurences = this.daySvc.getWeekDayOccurences();
                    var occurence = _.find(weekDayOccurences, function (wdo) { return wdo.value === value; });
                    if (occurence != null) {
                        return occurence.text;
                    }
                    else {
                        return '';
                    }
                };
                ShowReadOnlyPeriodicityModalController.prototype.getMonth = function (value) {
                    var months = _(this.$locale.DATETIME_FORMATS.MONTH).map(function (m, i) {
                        return {
                            value: i + 1,
                            name: m,
                        };
                    });
                    var month = _.find(months, function (m) { return m.value === value; });
                    if (month != null) {
                        return month.name;
                    }
                    else {
                        return '';
                    }
                };
                ShowReadOnlyPeriodicityModalController.prototype.initialisePeriodicities = function () {
                    this.periodicities = [];
                    for (var _i = 0, _a = this.recurrentCycle.periodicities; _i < _a.length; _i++) {
                        var periodicity = _a[_i];
                        var grid = this.buildGrid();
                        grid.setData(periodicity.intraDayTimings);
                        this.periodicities.push({
                            grid: grid,
                            periodicity: periodicity,
                        });
                    }
                };
                ShowReadOnlyPeriodicityModalController.prototype.buildGrid = function () {
                    return (this.gridBuilderSvc
                        .createGridBuilder()
                        // No addDateColumn on purpose, because it sets a fixed width
                        .addColumn('preferredTime', 'Periodicity.PreferredTime', {
                        cellFilter: 'date: "HH:mm"',
                        sort: { direction: 'asc' },
                    })
                        .addColumn('preferredTimeWindowBefore', 'Periodicity.PreferredTimeWindowBefore', {
                        cellFilter: 'date: "HH:mm"',
                    })
                        .addColumn('preferredTimeWindowAfter', 'Periodicity.PreferredTimeWindowAfter', {
                        cellFilter: 'date: "HH:mm"',
                    })
                        .addColumn('reminderElapseTimeAfterOpeningWindow', 'Periodicity.ReminderElapseTimeAfterOpeningWindow', {
                        cellFilter: 'date: "HH:mm"',
                    })
                        .build());
                };
                return ShowReadOnlyPeriodicityModalController;
            }());
            Shared.remeCareSharedModule.component('rcShowReadOnlyPeriodicity', {
                bindings: {
                    $close: '&',
                    $dismiss: '&',
                    recurrentCycle: '<',
                    carePlanStartDate: '<',
                },
                controller: ShowReadOnlyPeriodicityModalController,
                templateUrl: 'framework/components/showReadOnlyPeriodicity/showReadOnlyPeriodicity.html',
            });
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
