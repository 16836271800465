namespace RemeCare.Shared.Framework.Directive {
    interface ITextAreaScope extends ng.IScope {
        model: string;
        required: boolean;
        textType: string;
        maxLength: number;
        formCtrl: ng.IFormController;
        rows: number;
        labelAbove: boolean;
        readOnly: boolean;
        addTimestamp(): void;
    }

    class TextAreaDirective implements ng.IDirective {
        public restrict = 'E';

        public require = '^form';

        public scope: { [boundProperty: string]: string } = {
            model: '=ngModel',
            required: '=ngRequired',
            label: '@',
            textType: '@',
            labelAbove: '@',
            readOnly: '=ngReadonly',
            timestamp: '=',
            disabled: '=ngDisabled',
            guidanceIconLocation: '<',
            guidanceIconFunc: '<',
            uniqueId: '@',
        };

        public templateUrl = 'views/formElements/textArea.html';
        constructor(private readonly $filter: ng.IFilterService, private readonly authservice: Framework.AuthService) {}

        public link: ng.IDirectiveLinkFn = (
            scope: ITextAreaScope,
            element,
            attrs: any,
            formCtrl: ng.IFormController
        ) => {
            scope.formCtrl = formCtrl;

            scope.rows = attrs.rows ? parseInt(attrs.rows) : 3;

            scope.labelAbove = attrs.labelAbove;

            if (attrs.maxLength) {
                scope.maxLength = parseInt(attrs.maxLength);
            } else {
                switch (scope.textType) {
                    case TextLength.extraShort:
                        scope.maxLength = 100;
                        break;
                    case TextLength.short:
                        scope.maxLength = 200;
                        break;
                    case TextLength.medium:
                        scope.maxLength = 400;
                        break;
                    case TextLength.long:
                        scope.maxLength = 1000;
                        break;
                    case TextLength.extraLong:
                        scope.maxLength = 4000;
                        break;
                    default:
                        scope.maxLength = 400;
                        break;
                }
            }

            const getCursorPosition = textArea => {
                let iCaretPos = 0;

                if ((document as any).selection) {
                    textArea.focus();
                    const oSel = (document as any).selection.createRange();
                    oSel.moveStart('character', -textArea.value.length);
                    iCaretPos = oSel.text.length;
                } else if (textArea.selectionStart || textArea.selectionStart === '0') {
                    iCaretPos = textArea.selectionStart;
                }
                return iCaretPos;
            };

            scope.addTimestamp = () => {
                if (scope.readOnly) {
                    return;
                }
                const textArea = element.find('textarea')[0] as HTMLTextAreaElement;
                const cursorPos = getCursorPosition(textArea);
                const timeStamp = this.$filter('date')(new Date(), 'short');
                let text = textArea.value;
                text =
                    text.slice(0, cursorPos) +
                    '[' +
                    this.authservice.getClaim(Framework.ClaimTypes.firstname) +
                    ' ' +
                    this.authservice.getClaim(Framework.ClaimTypes.lastname) +
                    ' ' +
                    timeStamp +
                    '] ' +
                    text.slice(cursorPos, text.length);
                if (text.length > 3000) {
                    text = text.slice(0, 3000);
                }
                scope.model = text;

                textArea.focus();
            };
        };
    }

    remeCareSharedModule.directive('rcTextArea', ($filter, authservice) => new TextAreaDirective($filter, authservice));
}
