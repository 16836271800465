var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Factory;
            (function (Factory) {
                var DeferredArrayFactory = /** @class */ (function () {
                    function DeferredArrayFactory($q) {
                        this.$q = $q;
                    }
                    DeferredArrayFactory.prototype.createMany = function (serverObjects, create) {
                        var deferred = this.$q.defer();
                        var promises = _(serverObjects).map(function (s) { return create(s); });
                        this.$q.all(promises)
                            .then(function (r) { return deferred.resolve(r); });
                        return deferred.promise;
                    };
                    return DeferredArrayFactory;
                }());
                Factory.DeferredArrayFactory = DeferredArrayFactory;
                Shared.remeCareSharedModule.factory('deferredArrayFactory', function ($q) { return new DeferredArrayFactory($q); });
            })(Factory = Framework.Factory || (Framework.Factory = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
