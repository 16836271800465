var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Model;
            (function (Model) {
                var PossibleActionDurationOption = /** @class */ (function () {
                    function PossibleActionDurationOption(idService) {
                        this.selectionId = idService.generateId();
                    }
                    PossibleActionDurationOption.prototype.toServerWrite = function () {
                        return {
                            Id: this.id,
                            DurationOfAction: Shared.DateHelper.toServerTimeString(this.durationOfAction),
                            Sequence: this.sequence,
                            Name: this.name,
                        };
                    };
                    return PossibleActionDurationOption;
                }());
                Model.PossibleActionDurationOption = PossibleActionDurationOption;
            })(Model = Framework.Model || (Framework.Model = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
