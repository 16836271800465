/// <reference path="../services/authservice.ts"/>
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Directive;
        (function (Directive) {
            var ClaimTypes = RemeCare.Shared.Framework.ClaimTypes;
            ;
            var ApplicationProfileDirective = /** @class */ (function () {
                function ApplicationProfileDirective(authservice) {
                    var _this = this;
                    this.authservice = authservice;
                    this.restrict = 'A';
                    this.scope = {
                        hideForProfile: '='
                    };
                    this.link = function (scope, element, attrs) {
                        scope.$on('rightsChanged', function (event) {
                            _this.checkProfile(scope.hideForProfile, element);
                        });
                        _this.checkProfile(scope.hideForProfile, element);
                    };
                }
                ApplicationProfileDirective.prototype.checkProfile = function (profile, element) {
                    var isProfile = this.authservice.getClaim(ClaimTypes.applicationProfile) === profile.toString();
                    if (isProfile) {
                        this.onIsProfile(element);
                    }
                    else {
                        this.onNotIsProfile(element);
                    }
                };
                return ApplicationProfileDirective;
            }());
            var HideForProfileDirective = /** @class */ (function (_super) {
                __extends(HideForProfileDirective, _super);
                function HideForProfileDirective() {
                    return _super !== null && _super.apply(this, arguments) || this;
                }
                HideForProfileDirective.prototype.onIsProfile = function (element) {
                    element.css('display', 'none');
                };
                HideForProfileDirective.prototype.onNotIsProfile = function (element) {
                    element.css('display', '');
                };
                return HideForProfileDirective;
            }(ApplicationProfileDirective));
            Shared.remeCareSharedModule.directive('hideForProfile', function (authservice) { return new HideForProfileDirective(authservice); });
            ;
            var ShowForProfileDirective = /** @class */ (function () {
                function ShowForProfileDirective(authservice) {
                    var _this = this;
                    this.authservice = authservice;
                    this.restrict = 'A';
                    this.scope = {
                        showForProfile: '='
                    };
                    this.link = function (scope, element, attrs) {
                        scope.$on('rightsChanged', function (event) {
                            _this.checkProfile(scope.showForProfile, element);
                        });
                        _this.checkProfile(scope.showForProfile, element);
                    };
                }
                ShowForProfileDirective.prototype.checkProfile = function (profile, element) {
                    var isProfile = this.authservice.getClaim(ClaimTypes.applicationProfile) === profile.toString();
                    if (isProfile) {
                        this.onIsProfile(element);
                    }
                    else {
                        this.onNotIsProfile(element);
                    }
                };
                ShowForProfileDirective.prototype.onIsProfile = function (element) {
                    element.css('display', '');
                };
                ShowForProfileDirective.prototype.onNotIsProfile = function (element) {
                    element.css('display', 'none');
                };
                return ShowForProfileDirective;
            }());
            Shared.remeCareSharedModule.directive('showForProfile', function (authservice) { return new ShowForProfileDirective(authservice); });
        })(Directive = Shared.Directive || (Shared.Directive = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
