module RemeCare.Shared.Framework.Grid {
    
    class UiGridAutoResizeDirective implements ng.IDirective {
        
        constructor(private gridUtil) {
            
        }

    require = 'uiGrid';

        scope = false;

        link: ng.IDirectiveLinkFn = (scope: ng.IScope, element, attributes, uiGridCtrl: any) => {
            
            var prevGridHeight, prevGridWidth;

            var getDimensions = () => {
                prevGridHeight = this.gridUtil.elementHeight(element);
                prevGridWidth = this.gridUtil.elementWidth(element);
            }
            scope.$watch(('grid.gridOptions.data.length'), () => {
                uiGridCtrl.grid.refreshCanvas(true);
            });
        }
    }

    angular.module('RemeCare.Shared').directive('rcUiGridAutoResize', (gridUtil) => new UiGridAutoResizeDirective(gridUtil));
}