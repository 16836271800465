namespace RemeCare.Shared {
    class SharedModuleRunner {
        constructor(
            private readonly $log: ng.ILogService,
            private readonly $state: ng.ui.IStateService,
            private readonly $transitions,
            private readonly authservice: Framework.AuthService,
            private readonly securityApiSvc: Framework.Service.SecurityApiSvc,
            private readonly patientInformedConsentService: Framework.Service.PatientInformedConsentService
        ) {
            this.run();
        }

        public async retrieveAuthorizationData(): Promise<void> {
            const claims = await this.securityApiSvc.getClaims();
            this.authservice.setClaims(claims);
            const securityContexts = await this.securityApiSvc.getSecurityContexts();
            this.authservice.setRights(securityContexts);
        }

        private run(): void {
            this.$log.debug('Started up application');
            this.handleStateChanges();
        }

        private handleStateChanges(): void {
            this.$transitions.onStart({}, transition => {
                this.checkAllowAnonymous(transition) ||
                    this.checkAuthenticated(transition) ||
                    this.checkWithoutProfile(transition) ||
                    this.checkInformedConsents(transition) ||
                    this.checkRedirect(transition) ||
                    this.checkPermissions(transition);
            });
        }

        private checkAllowAnonymous(transition): boolean {
            const nextState = transition.to();
            if (nextState.data && nextState.data.allowAnonymous) {
                return true;
            }
            return false;
        }

        private checkAuthenticated(transition): boolean {
            const nextState = transition.to();
            if (!this.authservice.isAuthenticated()) {
                transition.abort();
                this.retrieveAuthorizationData().then(() => {
                    if (!this.authservice.isAuthenticated()) {
                        // User is still not known in RemeCare although he is authenticated
                        // this can happen when the user logs in via an external identity provider
                        // and then navigates to RemeCare without being linked in RemeCare
                        this.$state.go('userUnknown');
                        return;
                    }
                    this.$state.go(nextState, transition.params());
                });
                return true;
            }
            return false;
        }

        private checkWithoutProfile(transition): boolean {
            const nextStateName = transition.to().name;
            if (
                this.authservice.getProfile() === 0 &&
                nextStateName !== 'checkApplicationProfile' &&
                nextStateName !== 'applicationProfile'
            ) {
                transition.abort();
                this.$state.go('checkApplicationProfile', {
                    redirect: nextStateName,
                    params: transition.params(),
                });
                return true;
            }
            return false;
        }

        private checkInformedConsents(transition): boolean {
            const nextStateName = transition.to().name;
            if (
                this.patientInformedConsentService.shouldCheckPatientInformedConsents() &&
                nextStateName !== 'checkInformedConsent'
            ) {
                transition.abort();
                this.$state.go('checkInformedConsent', {
                    redirect: nextStateName,
                    params: transition.params(),
                });
                return true;
            }

            return false;
        }

        private checkRedirect(transition): boolean {
            const redirect = transition.to().redirectTo;
            if (redirect) {
                transition.abort();
                this.$state.go(redirect, transition.params());
                return true;
            }
            return false;
        }

        private checkPermissions(transition): boolean {
            const nextState = transition.to();
            if (!!nextState.data && !!nextState.data.permissions) {
                if (!this.authservice.hasRight(nextState.data.permissions.context, nextState.data.permissions.right)) {
                    this.$state.go('unauthorized');
                }
                return true;
            }
            return false;
        }
    }

    remeCareSharedModule.run(
        (
            $log: ng.ILogService,
            $state: ng.ui.IStateService,
            $transitions,
            authservice: Framework.AuthService,
            securityApiSvc: Framework.Service.SecurityApiSvc,
            patientInformedConsentService: Framework.Service.PatientInformedConsentService
        ) =>
            new SharedModuleRunner(
                $log,
                $state,
                $transitions,
                authservice,
                securityApiSvc,
                patientInformedConsentService
            )
    );
}
