var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Service;
            (function (Service) {
                var SettingsService = /** @class */ (function () {
                    function SettingsService(storageSvc) {
                        this.storageSvc = storageSvc;
                    }
                    SettingsService.prototype.getSettings = function () {
                        return this.storageSvc.get(SettingsService.userSettingsKey) || {};
                    };
                    SettingsService.prototype.saveSettings = function (settings) {
                        this.storageSvc.store(SettingsService.userSettingsKey, settings);
                    };
                    SettingsService.userSettingsKey = 'userSettings';
                    return SettingsService;
                }());
                Service.SettingsService = SettingsService;
                Shared.remeCareSharedModule.service('settingsSvc', SettingsService);
            })(Service = Framework.Service || (Framework.Service = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
