var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Contract;
        (function (Contract) {
            var Code;
            (function (Code) {
                var EvenOddDays;
                (function (EvenOddDays) {
                    EvenOddDays[EvenOddDays["Odd"] = 1] = "Odd";
                    EvenOddDays[EvenOddDays["Even"] = 2] = "Even";
                })(EvenOddDays = Code.EvenOddDays || (Code.EvenOddDays = {}));
                var DailyAlternationType;
                (function (DailyAlternationType) {
                    DailyAlternationType[DailyAlternationType["Day1Day2"] = 1] = "Day1Day2";
                    DailyAlternationType[DailyAlternationType["EvenOdd"] = 2] = "EvenOdd";
                })(DailyAlternationType = Code.DailyAlternationType || (Code.DailyAlternationType = {}));
            })(Code = Contract.Code || (Contract.Code = {}));
        })(Contract = Shared.Contract || (Shared.Contract = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
