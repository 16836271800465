module RemeCare.Shared.Contract.Code {

    export enum ProcessParameterType {
        Anamnesis = 1,
        TherapyOption = 2,
        Contact = 3,
        Objective = 4,
        RuleThreshold = 5,
        StructuralTherapyAction = 6,
        CareRequest = 7,
        CodeSetType = 8,
        CodeSetItem = 9,
        Characteristic = 10,
        ObservableEntity = 11,
        TherapyAction = 12,
        Therapy = 13
    }
}