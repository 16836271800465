var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Filter;
        (function (Filter) {
            function numberFraction(numberFilter, $locale) {
                return function (number, fractionSize) {
                    number = Math.round(number * Math.pow(10, fractionSize)) / Math.pow(10, fractionSize);
                    return numberFilter(number);
                };
            }
            Shared.remeCareSharedModule.filter('numberEx', numberFraction);
        })(Filter = Shared.Filter || (Shared.Filter = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
