module RemeCare.Shared.Directive {

    interface UiSrefIfScope extends ng.IScope {
        val: string;
        if: boolean;
    }

    class UiSrefIfDirective implements ng.IDirective {

        constructor(private $compile: ng.ICompileService) {

        }

        scope: { [boundProperty: string]: string } = {
            val: '@uiSrefVal',
            if: '=uiSrefIf'
        }

        link: ng.IDirectiveLinkFn = (scope: UiSrefIfScope, element: ng.IAugmentedJQuery, attrs) => {
            element.removeAttr('ui-sref-if');
            this.$compile(element)(scope);

            scope.$watch('if', (newVal) => {
                if (newVal) {
                    element.attr('ui-sref', scope.val);
                } else {
                    element.removeAttr('ui-sref');
                    element.removeAttr('href');
                }
                this.$compile(element)(scope);
            })
        }
    }

    remeCareSharedModule.directive('uiSrefIf', ($compile) => new UiSrefIfDirective($compile));
}