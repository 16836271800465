/// <reference path="../../contract/code/partyRoleType.ts"/>
var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Model;
            (function (Model) {
                var PartyRoleType = Shared.Contract.Code.PartyRoleType;
                var ActorRole = /** @class */ (function () {
                    function ActorRole(serverObject) {
                        if (serverObject != null) {
                            this.actorRoleId = serverObject.ActorRoleId;
                            this.name = serverObject.Name;
                            this.partyType = serverObject.PartyType;
                            this.internalRemark = serverObject.InternalRemark;
                            this.minimalNumber = serverObject.MinimalNumber;
                            this.maximalNumber = serverObject.MaximalNumber;
                            this.actorRoleInclusions = _(serverObject.ActorRoleInclusions)
                                .map(function (a) { return new Model.ActorRoleInclusion(a); });
                            this.setRadioSelection();
                        }
                        else {
                            this.actorRoleInclusions = [];
                        }
                    }
                    ActorRole.prototype.copy = function () {
                        var result = new ActorRole();
                        angular.copy(this, result);
                        return result;
                    };
                    ActorRole.prototype.toServerWrite = function () {
                        return {
                            actorRoleId: this.actorRoleId,
                            name: this.name,
                            partyType: this.partyType,
                            internalRemark: this.internalRemark,
                            minimalNumber: this.minimalNumber,
                            maximalNumber: this.maximalNumber,
                            actorRoleInclusions: _(this.actorRoleInclusions).map(function (ari) { return ari.toServerWrite(); })
                        };
                    };
                    ActorRole.prototype.setRadioSelection = function () {
                        if (this.actorRoleInclusions.length === 0)
                            return;
                        switch (this.actorRoleInclusions[0].partyRoleType) {
                            case PartyRoleType.HealthCareNonProfessional:
                                this.radioSelection = ActorRoleInclusionOption.InformalCareProvider;
                                break;
                            case PartyRoleType.Patient:
                                this.radioSelection = ActorRoleInclusionOption.Patient;
                                break;
                            case PartyRoleType.HealthCareOrganisation:
                                if (this.actorRoleInclusions[0].actorRoleInclusionDetails.length > 0) {
                                    if (this.actorRoleInclusions[0].actorRoleInclusionDetails[0].partyRoleId != null) {
                                        this.radioSelection = ActorRoleInclusionOption.Organisations;
                                    }
                                    else {
                                        this.radioSelection = ActorRoleInclusionOption.OrganisationTypes;
                                    }
                                }
                                break;
                            case PartyRoleType.HealthCareProfessional:
                                if (this.actorRoleInclusions[0].actorRoleInclusionDetails.length > 0) {
                                    if (this.actorRoleInclusions[0].actorRoleInclusionDetails[0].partyRoleId != null) {
                                        this.radioSelection = ActorRoleInclusionOption.Individuals;
                                    }
                                    else {
                                        this.radioSelection = ActorRoleInclusionOption.Specialties;
                                    }
                                }
                                break;
                            case PartyRoleType.Employee:
                                this.radioSelection = ActorRoleInclusionOption.Individuals;
                                break;
                            default:
                                this.radioSelection = null;
                                break;
                        }
                    };
                    ActorRole.prototype.getRadioSelectionString = function () {
                        switch (this.radioSelection) {
                            case ActorRoleInclusionOption.InformalCareProvider:
                                return 'Configuration.Therapies.General.InformalCareProvider';
                            case ActorRoleInclusionOption.Patient:
                                return 'Configuration.Therapies.General.Patient';
                            case ActorRoleInclusionOption.Individuals:
                                return 'Configuration.Therapies.General.Individuals';
                            case ActorRoleInclusionOption.Specialties:
                                return 'Configuration.Therapies.General.Specialties';
                            case ActorRoleInclusionOption.Organisations:
                                return 'Configuration.Therapies.General.Organisations';
                            case ActorRoleInclusionOption.OrganisationTypes:
                                return 'Configuration.Therapies.General.OrganisationTypes';
                            default:
                                return '';
                        }
                    };
                    return ActorRole;
                }());
                Model.ActorRole = ActorRole;
                var ActorRoleInclusionOption;
                (function (ActorRoleInclusionOption) {
                    ActorRoleInclusionOption[ActorRoleInclusionOption["Patient"] = 1] = "Patient";
                    ActorRoleInclusionOption[ActorRoleInclusionOption["Individuals"] = 2] = "Individuals";
                    ActorRoleInclusionOption[ActorRoleInclusionOption["Specialties"] = 3] = "Specialties";
                    ActorRoleInclusionOption[ActorRoleInclusionOption["Organisations"] = 4] = "Organisations";
                    ActorRoleInclusionOption[ActorRoleInclusionOption["OrganisationTypes"] = 5] = "OrganisationTypes";
                    ActorRoleInclusionOption[ActorRoleInclusionOption["InformalCareProvider"] = 6] = "InformalCareProvider";
                })(ActorRoleInclusionOption = Model.ActorRoleInclusionOption || (Model.ActorRoleInclusionOption = {}));
            })(Model = Framework.Model || (Framework.Model = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
