namespace RemeCare.Shared.Framework.Model {
    import EnumTranslation = Shared.Contract.IEnumTranslation;
    import SourceParameterRepresentationType = Shared.Contract.Code.SourceParameterRepresentationType;

    export class SourceParameterRepresentation {
        public id: number;

        constructor(serverObject?: Shared.Contract.Read.ISourceParameterRepresentation) {
            if (serverObject != null) {
                this.id = serverObject.Id;
            }
        }

        public toServerWrite(): Shared.Contract.Write.ISourceParameterRepresentation {
            return {
                id: this.id,
            } as Shared.Contract.Write.ISourceParameterRepresentation;
        }
    }

    export class ChartLine extends SourceParameterRepresentation {
        public colour: string;
        public lineType: EnumTranslation;

        constructor(serverObject?: Shared.Contract.Read.IChartLine, lineTypes?: EnumTranslation[]) {
            super(serverObject);
            this.id = Contract.Code.SourceParameterRepresentationType.ChartLine;
            if (serverObject != null) {
                this.colour = serverObject.Colour;
                this.lineType = _(lineTypes).find(lt => lt.Id === serverObject.LineType);
            }
        }

        public toServerWrite(): Shared.Contract.Write.IChartLine {
            const result = super.toServerWrite() as Shared.Contract.Write.IChartLine;
            result.colour = this.colour;
            result.lineType = this.lineType.Id;
            result.type = SourceParameterRepresentationType.ChartLine;
            return result;
        }
    }

    export class ChartBar extends SourceParameterRepresentation {
        public colour: string;

        constructor(serverObject?: Shared.Contract.Read.IChartBar) {
            super(serverObject);
            this.id = Contract.Code.SourceParameterRepresentationType.ChartBar;
            if (serverObject != null) {
                this.colour = serverObject.Colour;
            }
        }

        public toServerWrite(): Shared.Contract.Write.IChartBar {
            const result = super.toServerWrite() as Shared.Contract.Write.IChartBar;
            result.colour = this.colour;
            result.type = SourceParameterRepresentationType.ChartBar;
            return result;
        }
    }

    export class TableColumn extends SourceParameterRepresentation {
        constructor(serverObject?: Shared.Contract.Read.ITableColumn) {
            super(serverObject);
            this.id = Contract.Code.SourceParameterRepresentationType.TableColumn;
        }
        public toServerWrite(): Shared.Contract.Write.ISourceParameterRepresentation {
            const result = super.toServerWrite() as Shared.Contract.Write.ISourceParameterRepresentation;
            result.type = SourceParameterRepresentationType.TableColumn;
            return result;
        }
    }

    export class ColourQualitative extends SourceParameterRepresentation {
        public codeSetColours: Model.CodeSetColour[];

        constructor(serverObject?: Shared.Contract.Read.IColourQualitative) {
            super(serverObject);
            this.id = Contract.Code.SourceParameterRepresentationType.ColourQualitative;
            if (serverObject != null) {
                this.codeSetColours = _(serverObject.CodeSetColours).map(csc => new Model.CodeSetColour(csc));
            }
        }

        public toServerWrite(): Shared.Contract.Write.IColourQualitative {
            const result = super.toServerWrite() as Shared.Contract.Write.IColourQualitative;
            result.codeSetColours = _(this.codeSetColours).map(csc => csc.toServerWrite());
            result.type = SourceParameterRepresentationType.ColourQualitative;
            return result;
        }
    }

    export class Boxplot extends SourceParameterRepresentation {
        public colour: string;
        public boxplotDataType: Shared.Contract.Code.BoxplotDataType;

        constructor(serverObject?: Shared.Contract.Read.IBoxplot) {
            super(serverObject);
            this.id = Contract.Code.SourceParameterRepresentationType.Boxplot;
            if (serverObject != null) {
                this.colour = serverObject.Colour;
                this.boxplotDataType = serverObject.BoxplotDataType;
            }
        }

        public toServerWrite(): Shared.Contract.Write.IBoxplot {
            const result = super.toServerWrite() as Shared.Contract.Write.IBoxplot;
            result.colour = this.colour;
            result.boxplotDataType = this.boxplotDataType;
            result.type = SourceParameterRepresentationType.Boxplot;
            return result;
        }
    }
}
