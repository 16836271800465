module RemeCare.Shared.Framework.Model {

    export class PossibleActionDurationOption {
        public id: Shared.Contract.Guid;
        public durationOfAction: Date;
        public sequence: number;
        public name: string;
        // Used to save the defaultly chosen possibleActionDurationOption
        public selectionId: string;

        constructor(idService: Shared.Framework.Service.IIdService) {
            this.selectionId = idService.generateId();
        }
        
        public toServerWrite(): Contract.Write.IPossibleActionDurationOption {
            return {
                Id: this.id,
                DurationOfAction: Shared.DateHelper.toServerTimeString(this.durationOfAction),
                Sequence: this.sequence,
                Name: this.name,
            } as Contract.Write.IPossibleActionDurationOption;
        }
    }
}