module RemeCare.Shared.Framework.Helper {
    export class ModalSize {
        static small: string = 'sm';
        static medium: string = '';
        static large: string = 'lg';
    }

    abstract class ModalBuilderBase<TResult> {
        protected modalSettings: ng.ui.bootstrap.IModalSettings = {};
        private dismissCallback: (reason: any) => void;
        private resultCallback: (promiseValue: TResult) => void;

        protected constructor(private readonly $uibModal: ng.ui.bootstrap.IModalService) {
            this.modalSettings.backdrop = 'static';
        }

        public build(): ng.ui.bootstrap.IModalServiceInstance {
            const modal = this.$uibModal.open(this.modalSettings);
            modal.result.then(this.resultCallback, this.dismissCallback);
            return modal;
        }

        protected setSmall(): ModalBuilderBase<TResult> {
            return this.setSize(ModalSize.small);
        }

        protected setLarge(): ModalBuilderBase<TResult> {
            return this.setSize(ModalSize.large);
        }

        protected setSize(size: string): ModalBuilderBase<TResult> {
            this.modalSettings.size = size;
            return this;
        }

        public setResultCallBack(callback: (promiseValue: TResult) => void): ModalBuilderBase<TResult> {
            this.resultCallback = callback;
            return this;
        }

        public setDismissCallBack(callback: (reason: any) => void): ModalBuilderBase<TResult> {
            this.dismissCallback = callback;
            return this;
        }
    }

    export class ComponentModalBuilder<TResult> extends ModalBuilderBase<TResult> {
        private bindings: Dictionary<any>;

        constructor($uibModal: ng.ui.bootstrap.IModalService, private readonly componentName: any) {
            super($uibModal);
        }

        public build(): ng.ui.bootstrap.IModalServiceInstance {
            const componentName = this.snakeCase(this.componentName);
            let template = `<${componentName}`;
            if (this.bindings) {
                for (let key in this.bindings) {
                    if (this.bindings.hasOwnProperty(key)) {
                        template = `${template} ${this.snakeCase(key)}="$ctrl.bindings.${key}"`;
                    }
                }
            }
            template = `${template} $close="$close(result)" $dismiss="$dismiss(result)"></${componentName}>`;
            this.modalSettings.template = template;
            const that = this;
            this.modalSettings.controller = [
                function() {
                    this.bindings = that.bindings;
                }
            ];
            this.modalSettings.controllerAs = '$ctrl';
            return super.build();
        }

        public setBindings(bindings: Dictionary<any>): ComponentModalBuilder<TResult> {
            this.bindings = bindings;
            return this;
        }

        public setResultCallBack(callback: (promiseValue: TResult) => void): ComponentModalBuilder<TResult> {
            return super.setResultCallBack(callback) as ComponentModalBuilder<TResult>;
        }

        public setDismissCallBack(callback: (reason: any) => void): ModalBuilder<TResult> {
            return super.setDismissCallBack(callback) as ModalBuilder<TResult>;
        }

        public setSmall(): ComponentModalBuilder<TResult> {
            return super.setSmall() as ComponentModalBuilder<TResult>;
        }

        public setLarge(): ComponentModalBuilder<TResult> {
            return super.setLarge() as ComponentModalBuilder<TResult>;
        }

        public setSize(size: string): ComponentModalBuilder<TResult> {
            return super.setSize(size) as ComponentModalBuilder<TResult>;
        }

        private snakeCase(name: string): string {
            return name.replace(/[A-Z]/g, (letter, pos) => {
                return (pos ? '-' : '') + letter.toLowerCase();
            });
        }
    }

    export class ModalBuilder<TResult> extends ModalBuilderBase<TResult> {
        constructor(private readonly $rootScope: ng.IRootScopeService, $uibModal: ng.ui.bootstrap.IModalService) {
            super($uibModal);
        }

        public setController(controller: string): ModalBuilder<TResult> {
            this.modalSettings.controller = controller;
            return this;
        }

        public setResolve(resolveItems: {
            [key: string]: string | Function | Array<string | Function> | Object;
        }): ModalBuilder<TResult> {
            this.modalSettings.resolve = resolveItems;
            return this;
        }

        public setResultCallBack(callback: (promiseValue: TResult) => void): ModalBuilder<TResult> {
            return super.setResultCallBack(callback) as ModalBuilder<TResult>;
        }

        public setDismissCallBack(callback: (reason: any) => void): ModalBuilder<TResult> {
            return super.setDismissCallBack(callback) as ModalBuilder<TResult>;
        }

        public setScope(scope: {}): ModalBuilder<TResult> {
            const modalScope = (this.modalSettings.scope || this.$rootScope.$new(true)) as ng.ui.bootstrap.IModalScope;
            for (let key in scope) {
                if (scope.hasOwnProperty(key)) {
                    modalScope[key] = scope[key];
                }
            }
            this.modalSettings.scope = modalScope;
            return this;
        }

        public setSmall(): ModalBuilder<TResult> {
            return super.setSmall() as ModalBuilder<TResult>;
        }

        public setLarge(): ModalBuilder<TResult> {
            return super.setLarge() as ModalBuilder<TResult>;
        }

        public setSize(size: string): ModalBuilder<TResult> {
            return super.setSize(size) as ModalBuilder<TResult>;
        }

        public setTemplate(template: string): ModalBuilder<TResult> {
            this.modalSettings.template = template;
            this.modalSettings.templateUrl = null;
            return this;
        }

        public setTemplateUrl(templateUrl: string): ModalBuilder<TResult> {
            this.modalSettings.templateUrl = templateUrl;
            this.modalSettings.template = null;
            return this;
        }
    }

    export class ModalBuilderFactory {
        constructor(
            private readonly $rootScope: ng.IRootScopeService,
            private readonly $uibModal: ng.ui.bootstrap.IModalService
        ) {}

        public createComponentModalBuilder<TResult>(componentName: string): ComponentModalBuilder<TResult> {
            return new ComponentModalBuilder<TResult>(this.$uibModal, componentName);
        }

        /** @deprecated use createComponentModalBuilder */
        public createModalBuilder<TResult>(): ModalBuilder<TResult> {
            return new ModalBuilder<TResult>(this.$rootScope, this.$uibModal);
        }

        public showSelectorAsync<T>(
            items: Array<T>,
            selectors: Array<string>,
            separator: string,
            title: string,
            modalSize?: string
        ): Promise<T> {
            return new Promise<T>((resolve, reject) => {
                const size = modalSize != null ? modalSize : ModalSize.small;
                this.createModalBuilder<T>()
                    .setTemplateUrl('views/buttonSelector.html')
                    .setSize(size)
                    .setScope({
                        items: items,
                        selectors: selectors,
                        separator: separator,
                        title: title
                    })
                    .setResultCallBack((item: T) => {
                        resolve(item);
                    })
                    .setDismissCallBack(() => {
                        reject();
                    })
                    .build();
            });
        }

        /** @deprecated use showSelectorAsync */
        public showSelector<T>(
            items: Array<T>,
            selectors: Array<string>,
            separator: string,
            title: string,
            modalSize?: string
        ): JQueryPromise<T> {
            var deferred = jQuery.Deferred<T>();
            const size = modalSize != null ? modalSize : ModalSize.small;
            this.createModalBuilder<T>()
                .setTemplateUrl('views/buttonSelector.html')
                .setSize(size)
                .setScope({
                    items: items,
                    selectors: selectors,
                    separator: separator,
                    title: title
                })
                .setResultCallBack((item: T) => {
                    deferred.resolve(item);
                })
                .setDismissCallBack(() => {
                    deferred.reject();
                })
                .build();
            return deferred;
        }

        public showSearchModalAsync<TResult>(
            title: string,
            searchCriteriaTemplate: string,
            searchFunction: (q: Contract.ISearchQuery) => Promise<Contract.ISearchResult<TResult>>,
            columns: Array<Shared.Directive.IColumn>,
            detailsTemplate?: string,
            detailsHeight?: number
        ): Promise<TResult> {
            return new Promise<TResult>(resolve => {
                this.createModalBuilder<TResult>()
                    .setTemplateUrl('views/search/searchModal.html')
                    .setSize(ModalSize.large)
                    .setScope({
                        searchInfo: {
                            title: title,
                            searchCriteriaSrc: searchCriteriaTemplate,
                            searchFunction: searchFunction,
                            columns: columns,
                            detailsTemplate: detailsTemplate,
                            detailsHeight: detailsHeight
                        }
                    })
                    .setResultCallBack(r => resolve(r))
                    .build();
            });
        }

        public showMultiSelectSearchModalAsync<TResult>(
            title: string,
            searchCriteriaTemplate: string,
            searchFunction: (q: Contract.ISearchQuery) => Promise<Contract.ISearchResult<TResult>>,
            columns: Array<Shared.Directive.IColumn>,
            idField: string,
            currentResults: Array<TResult>,
            progressBar: string
        ): Promise<Array<TResult>> {
            return new Promise<Array<TResult>>(resolve => {
                this.createModalBuilder<Array<TResult>>()
                    .setTemplateUrl('views/search/multiSelectSearchModal.html')
                    .setController('multiSelectSearchModalCtrl')
                    .setSize(ModalSize.large)
                    .setScope({
                        searchInfo: {
                            title: title,
                            searchCriteriaSrc: searchCriteriaTemplate,
                            searchFunction: searchFunction,
                            columns: columns,
                            idField: idField,
                            currentResults: currentResults
                        },
                        progressBar: progressBar
                    })
                    .setResultCallBack(r => resolve(r))
                    .build();
            });
        }
    }

    remeCareSharedModule.service('modalBuilderFactory', ModalBuilderFactory);
}
