namespace RemeCare.Shared.Directive {
    class ToggleController implements ng.IComponentController {
        public changed: () => void;
        public disabled: boolean;
        public label: string;
        public model: boolean;

        constructor(private readonly $timeout: ng.ITimeoutService) {}

        public $onInit(): void {}

        public labelClicked(): void {
            if (this.disabled) {
                return;
            }
            this.model = !this.model;
            this.$timeout(() => {
                this.changed();
            });
        }

        public onToggleValueChange(): void {
            this.$timeout(() => {
                this.changed();
            });
        }
    }

    remeCareSharedModule.component('rcToggle', {
        bindings: {
            changed: '&ngChange',
            disabled: '=ngDisabled',
            label: '@',
            model: '=ngModel',
            infoMessage: '@?',
        },
        controller: ToggleController,
        templateUrl: 'views/controls/toggle.html',
    });
}
