var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Service;
            (function (Service) {
                var LogLevel;
                (function (LogLevel) {
                    LogLevel["Debug"] = "DEBUG";
                    LogLevel["Info"] = "INFO";
                    LogLevel["Log"] = "LOG";
                    LogLevel["Warn"] = "WARN";
                    LogLevel["Error"] = "ERROR";
                })(LogLevel || (LogLevel = {}));
                var LoggingService = /** @class */ (function () {
                    function LoggingService() {
                        var _this = this;
                        this.maxMessages = 100;
                        this.storageKey = 'RemeCareLogs';
                        this.debug = function () {
                            var args = [];
                            for (var _i = 0; _i < arguments.length; _i++) {
                                args[_i] = arguments[_i];
                            }
                            _this.logMessage(args, LogLevel.Debug);
                        };
                        this.error = function () {
                            var args = [];
                            for (var _i = 0; _i < arguments.length; _i++) {
                                args[_i] = arguments[_i];
                            }
                            _this.logMessage(args, LogLevel.Error);
                        };
                        this.info = function () {
                            var args = [];
                            for (var _i = 0; _i < arguments.length; _i++) {
                                args[_i] = arguments[_i];
                            }
                            _this.logMessage(args, LogLevel.Info);
                        };
                        this.log = function () {
                            var args = [];
                            for (var _i = 0; _i < arguments.length; _i++) {
                                args[_i] = arguments[_i];
                            }
                            _this.logMessage(args, LogLevel.Log);
                        };
                        this.warn = function () {
                            var args = [];
                            for (var _i = 0; _i < arguments.length; _i++) {
                                args[_i] = arguments[_i];
                            }
                            _this.logMessage(args, LogLevel.Warn);
                        };
                    }
                    LoggingService.prototype.logMessage = function (args, level) {
                        var formatted = moment().toISOString() + " - " + level + " - " + args;
                        var stored = localStorage.getItem(this.storageKey);
                        var messages = stored
                            ? JSON.parse(stored)
                            : [];
                        if (messages.push(formatted) > this.maxMessages) {
                            messages.shift();
                        }
                        localStorage.setItem(this.storageKey, JSON.stringify(messages));
                    };
                    return LoggingService;
                }());
                Shared.remeCareSharedModule.service('loggingSvc', LoggingService);
                var LoggingDecorator = /** @class */ (function () {
                    function LoggingDecorator($delegate, loggingSvc) {
                        this.$delegate = $delegate;
                        this.loggingSvc = loggingSvc;
                    }
                    LoggingDecorator.factory = function ($delegate, loggingSvc) {
                        return new LoggingDecorator($delegate, loggingSvc).decorate();
                    };
                    LoggingDecorator.prototype.decorate = function () {
                        this.substitute('log');
                        this.substitute('info');
                        this.substitute('warn');
                        this.substitute('error');
                        this.substitute('debug');
                        return this.$delegate;
                    };
                    LoggingDecorator.prototype.substitute = function (fn) {
                        var _this = this;
                        var $logFn = this.$delegate[fn];
                        var newFn = function () {
                            var args = [];
                            for (var _i = 0; _i < arguments.length; _i++) {
                                args[_i] = arguments[_i];
                            }
                            $logFn.apply(_this.$delegate, args);
                            _this.loggingSvc[fn].apply(null, args);
                        };
                        this.$delegate[fn] = newFn;
                    };
                    return LoggingDecorator;
                }());
                Shared.remeCareSharedModule.decorator('$log', [
                    '$delegate',
                    'loggingSvc',
                    LoggingDecorator.factory
                ]);
            })(Service = Framework.Service || (Framework.Service = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
