module RemeCare.Shared.Framework.Model {
    import Guid = Shared.Contract.Guid;
    import EntityTranslation = Shared.Contract.IEntityTranslation;
    import MonitoringPartSourceType = Shared.Contract.Code.MonitoringPartSourceType;
    import MonitoringPartDataSourceType = Shared.Contract.Code.MonitoringPartDataSourceType;

    export abstract class MonitoringPartSource {
        public id: number;
        public type: MonitoringPartSourceType;

        constructor(serverObject?: Shared.Contract.Read.IMonitoringPartSource) {
            if (serverObject != null) {
                this.id = serverObject.Id;
            }
        }

        public toServerWrite(): Shared.Contract.Write.IMonitoringPartSource {
            return <Shared.Contract.Write.IMonitoringPartSource>{
                id: this.id,
                type: this.type
            }
        }

        public hasCharacteristicAndUnit(characteristic: EntityTranslation, unit: EntityTranslation): boolean {
            return false;
        };

        public hasObservableEntityAndCodeSet(observableEntity: EntityTranslation, codeSet: EntityTranslation): boolean {
            return false;
        }

        public hasObjective(objective: EntityTranslation): boolean {
            return false;
        }

        public hasRuleThreshold(ruleThreshold: EntityTranslation): boolean {
            return false;
        }

        public isEqual(monitoringPartSource: MonitoringPartSource): boolean {
            return monitoringPartSource && this.type === monitoringPartSource.type;
        }
    }

    export class MonitoringPartSourceAction extends MonitoringPartSource {
        public structuralTherapyActionId: Guid;
        public name: string;
        public filter: MonitoringPartSourceFilter;

        constructor(serverObject?: Shared.Contract.Read.IMonitoringPartSourceAction) {
            super(serverObject);
            this.type = MonitoringPartSourceType.MonitoringPartSourceAction;
            if (serverObject != null) {
                this.structuralTherapyActionId = serverObject.StructuralTherapyActionId;
            }
        }

        public toServerWrite(): Shared.Contract.Write.IMonitoringPartSourceAction {
            const result = <Shared.Contract.Write.IMonitoringPartSourceAction>super.toServerWrite();
            result.structuralTherapyActionId = this.structuralTherapyActionId;
            return result;
        }

        public isEqual(monitoringPartSource: MonitoringPartSource): boolean {
            const monitoringPartSourceAction = monitoringPartSource as MonitoringPartSourceAction;
            return super.isEqual(monitoringPartSource)
                && this.structuralTherapyActionId === monitoringPartSourceAction.structuralTherapyActionId;
        }
    }

    export class MonitoringPartSourceFilter extends MonitoringPartSource {
        public ingredientIds: Array<Guid>;
        public name: string;

        constructor(serverObject?: Shared.Contract.Read.IMonitoringPartSourceFilter) {
            super(serverObject);
            this.type = MonitoringPartSourceType.MonitoringPartSourceFilter;
            if (serverObject != null) {
                this.ingredientIds = serverObject.IngredientIds;
            }
        }

        public toServerWrite(): Shared.Contract.Write.IMonitoringPartSourceFilter {
            const result = <Shared.Contract.Write.IMonitoringPartSourceFilter>super.toServerWrite();
            result.ingredientIds = this.ingredientIds;
            return result;
        }

        public isEqual(monitoringPartSource: MonitoringPartSource): boolean {
            const monitoringPartSourceFilter = monitoringPartSource as MonitoringPartSourceFilter;
            return super.isEqual(monitoringPartSource)
                && _.all(this.ingredientIds,
                    (ingredientId) => {
                        return _.contains(monitoringPartSourceFilter.ingredientIds,
                            ingredientId);
                    })
                && _.all(monitoringPartSourceFilter.ingredientIds,
                    (ingredientId) => {
                        return _.contains(this.ingredientIds, ingredientId);
                    });
        }
    }

    export abstract class MonitoringPartSourceParameter extends MonitoringPartSource {
        public sequence: number;
        public sourceType: Shared.Contract.Code.MonitoringPartDataSourceType;
        public sourceParameterRepresentation: SourceParameterRepresentation;

        constructor(serverObject?: Shared.Contract.Read.IMonitoringPartSourceParameter) {
            super(serverObject);
            if (serverObject != null) {
                this.sequence = serverObject.Sequence;
                this.sourceType = serverObject.SourceType;
            }
        }

        public toServerWrite(): Shared.Contract.Write.IMonitoringPartSourceParameter {
            const result = <Shared.Contract.Write.IMonitoringPartSourceParameter>super.toServerWrite();
            result.sequence = this.sequence;
            result.sourceType = this.sourceType;
            result.sourceParameterRepresentation = this.sourceParameterRepresentation != null
                ? this.sourceParameterRepresentation.toServerWrite()
                : null;
            return result;
        }

        public abstract getName(): string;

        public isEqual(monitoringPartSource: MonitoringPartSource): boolean {
            const monitoringPartSourceParameter = monitoringPartSource as MonitoringPartSourceParameter;
            return super.isEqual(monitoringPartSource)
                && this.sourceType === monitoringPartSourceParameter.sourceType;
        }
    }

    export class QualitativeMeasuringPointParameter extends MonitoringPartSourceParameter {
        public observableEntity: EntityTranslation;
        public codeSet: EntityTranslation;
        public useTherapyActionPartTextRegistration: boolean;

        constructor(serverObject?: Shared.Contract.Read.IQualitativeMeasuringPointParameter,
            observableEntities?: Array<EntityTranslation>, codeSets?: Array<EntityTranslation>) {
            super(serverObject);
            this.type = MonitoringPartSourceType.QualitativeMeasuringPointParameter;
            if (serverObject != null) {
                this.observableEntity = _.find(observableEntities, oe => oe.Id === serverObject.ObservableEntityId);
                this.codeSet = _.find(codeSets, cs => cs.Id === serverObject.CodeSetId);
                this.useTherapyActionPartTextRegistration = serverObject.UseTherapyActionPartTextRegistration;
            }
        }

        public getName(): string {
            return this.observableEntity.Text;
        }


        public toServerWrite(): Shared.Contract.Write.IQualitativeMeasuringPointParameter {
            const result = <Shared.Contract.Write.IQualitativeMeasuringPointParameter>super.toServerWrite();
            result.observableEntity = this.observableEntity.Id;
            result.codeSet = this.codeSet.Id;
            result.useTherapyActionPartTextRegistration = this.useTherapyActionPartTextRegistration;
            return result;
        }

        public hasObservableEntityAndCodeSet(observableEntity: EntityTranslation, codeSet: EntityTranslation): boolean {
            const observableEntityEqual = (this.observableEntity == null && observableEntity == null
                || this.observableEntity != null && observableEntity != null && this.observableEntity.Id === observableEntity.Id);
            const codeSetEqual = (this.codeSet == null && codeSet == null
                || this.codeSet != null && codeSet != null && this.codeSet.Id === codeSet.Id);
            return observableEntityEqual && codeSetEqual;
        }

        public isEqual(monitoringPartSource: MonitoringPartSource): boolean {
            const qualitativeMeasuringPointParameter = monitoringPartSource as QualitativeMeasuringPointParameter;
            return super.isEqual(monitoringPartSource)
                && _.isEqual(this.observableEntity, qualitativeMeasuringPointParameter.observableEntity)
                && _.isEqual(this.codeSet, qualitativeMeasuringPointParameter.codeSet);
        }
    }

    export class QuantitativeMeasuringPointParameter extends MonitoringPartSourceParameter {
        public characteristic: EntityTranslation;
        public unit: EntityTranslation;
        public useTherapyActionPartTextRegistration: boolean;

        constructor(serverObject?: Shared.Contract.Read.IQuantitativeMeasuringPointParameter,
            characteristics?: Array<EntityTranslation>, units?: Array<EntityTranslation>) {
            super(serverObject);
            this.type = MonitoringPartSourceType.QuantitativeMeasuringPointParameter;
            if (serverObject != null) {
                this.characteristic = _.find(characteristics, c => c.Id === serverObject.CharacteristicId);
                this.unit = _.find(units, u => u.Id === serverObject.UnitId);
                this.useTherapyActionPartTextRegistration = serverObject.UseTherapyActionPartTextRegistration;
            }
        }

        public getName(): string {
            return this.unit ? `${this.characteristic.Text} (${this.unit.Text})` : this.characteristic.Text;
        }

        public toServerWrite(): Shared.Contract.Write.IQuantitativeMeasuringPointParameter {
            const result = <Shared.Contract.Write.IQuantitativeMeasuringPointParameter>super.toServerWrite();
            result.characteristic = this.characteristic.Id;
            result.unit = this.unit ? this.unit.Id : null;
            result.useTherapyActionPartTextRegistration = this.useTherapyActionPartTextRegistration;
            return result;
        }

        public hasCharacteristicAndUnit(characteristic: EntityTranslation, unit: EntityTranslation): boolean {
            const characteristicEqual = (this.characteristic == null && characteristic == null
                || this.characteristic != null && characteristic != null && this.characteristic.Id === characteristic.Id);
            const unitEqual = (this.unit == null && unit == null
                || this.unit != null && unit != null && this.unit.Id === unit.Id);
            return characteristicEqual && unitEqual;
        }

        public isEqual(monitoringPartSource: MonitoringPartSource): boolean {
            const quantitativeMeasuringPointParameter = monitoringPartSource as QuantitativeMeasuringPointParameter;
            return super.isEqual(monitoringPartSource)
                && _.isEqual(this.characteristic, quantitativeMeasuringPointParameter.characteristic)
                && _.isEqual(this.unit, quantitativeMeasuringPointParameter.unit);
        }
    }

    export class QualitativeReferenceParameterAnamnesis extends MonitoringPartSourceParameter {
        public observableEntity: EntityTranslation;
        public codeSet: EntityTranslation;

        constructor(serverObject?: Shared.Contract.Read.IQualitativeReferenceParameterAnamnesis,
            observableEntities?: Array<EntityTranslation>, codeSets?: Array<EntityTranslation>) {
            super(serverObject);
            this.type = MonitoringPartSourceType.QualitativeReferenceParameterAnamnesis;
            if (serverObject != null) {
                this.observableEntity = _.find(observableEntities, oe => oe.Id === serverObject.ObservableEntityId);
                this.codeSet = _.find(codeSets, cs => cs.Id === serverObject.CodeSetId);
            }
        }

        public getName(): string {
            return this.observableEntity.Text;
        }

        public toServerWrite(): Shared.Contract.Write.IQualitativeReferenceParameterAnamnesis {
            const result = <Shared.Contract.Write.IQualitativeReferenceParameterAnamnesis>super.toServerWrite();
            result.observableEntity = this.observableEntity.Id;
            result.codeSet = this.codeSet.Id;
            return result;
        }

        public hasObservableEntityAndCodeSet(observableEntity: EntityTranslation, codeSet: EntityTranslation): boolean {
            const observableEntityEqual = (this.observableEntity == null && observableEntity == null
                || this.observableEntity != null && observableEntity != null && this.observableEntity.Id === observableEntity.Id);
            const codeSetEqual = (this.codeSet == null && codeSet == null
                || this.codeSet != null && codeSet != null && this.codeSet.Id === codeSet.Id);
            return observableEntityEqual && codeSetEqual;
        }

        public isEqual(monitoringPartSource: MonitoringPartSource): boolean {
            const qualitativeReferenceParameterAnamnesis =
                monitoringPartSource as QualitativeReferenceParameterAnamnesis;
            return super.isEqual(monitoringPartSource)
                && _.isEqual(this.observableEntity, qualitativeReferenceParameterAnamnesis.observableEntity)
                && _.isEqual(this.codeSet, qualitativeReferenceParameterAnamnesis.codeSet);
        }
    }

    export class QuantitativeReferenceParameterAnamnesis extends MonitoringPartSourceParameter {
        public characteristic: EntityTranslation;
        public unit: EntityTranslation;

        constructor(serverObject?: Shared.Contract.Read.IQuantitativeReferenceParameterAnamnesis,
            characteristics?: Array<EntityTranslation>, units?: Array<EntityTranslation>) {
            super(serverObject);
            this.type = MonitoringPartSourceType.QuantitativeReferenceParameterAnamnesis;
            if (serverObject != null) {
                this.characteristic = _.find(characteristics, c => c.Id === serverObject.CharacteristicId);
                this.unit = _.find(units, u => u.Id === serverObject.UnitId);
            }
        }

        public getName(): string {
            return this.characteristic.Text;
        }

        public toServerWrite(): Shared.Contract.Write.IQuantitativereferenceParameterAnamnesis {
            const result = <Shared.Contract.Write.IQuantitativereferenceParameterAnamnesis>super.toServerWrite();
            result.characteristic = this.characteristic.Id;
            result.unit = this.unit ? this.unit.Id : null;
            return result;
        }

        public hasCharacteristicAndUnit(characteristic: EntityTranslation, unit: EntityTranslation): boolean {
            const characteristicEqual = (this.characteristic == null && characteristic == null
                || this.characteristic != null && characteristic != null && this.characteristic.Id === characteristic.Id);
            const unitEqual = (this.unit == null && unit == null
                || this.unit != null && unit != null && this.unit.Id === unit.Id);
            return characteristicEqual && unitEqual;
        };

        public isEqual(monitoringPartSource: MonitoringPartSource): boolean {
            const quantitativeReferenceParameterAnamnesis =
                monitoringPartSource as QuantitativeReferenceParameterAnamnesis;
            return super.isEqual(monitoringPartSource)
                && _.isEqual(this.characteristic, quantitativeReferenceParameterAnamnesis.characteristic)
                && _.isEqual(this.unit, quantitativeReferenceParameterAnamnesis.unit);
        }
    }

    export class ReferenceParameterThreshold extends MonitoringPartSourceParameter {
        public ruleThreshold: EntityTranslation;

        constructor(serverObject?: Shared.Contract.Read.IReferenceParameterThreshold,
            ruleThresholds?: Array<EntityTranslation>) {
            super(serverObject);
            this.type = MonitoringPartSourceType.ReferenceParameterThreshold;
            if (serverObject != null) {
                this.ruleThreshold = _.find(ruleThresholds, rt => rt.Id === serverObject.RuleThresholdId);
            }
        }

        public getName(): string {
            return this.ruleThreshold.Text;
        }

        public toServerWrite(): Shared.Contract.Write.IReferenceParameterThreshold {
            const result = <Shared.Contract.Write.IReferenceParameterThreshold>super.toServerWrite();
            result.ruleThreshold = this.ruleThreshold.Id;
            return result;
        }

        public hasRuleThreshold(ruleThreshold: EntityTranslation): boolean {
            return (this.ruleThreshold == null && ruleThreshold == null
                || this.ruleThreshold != null && ruleThreshold != null && this.ruleThreshold.Id === ruleThreshold.Id);
        }

        public isEqual(monitoringPartSource: MonitoringPartSource): boolean {
            const referenceParameterThreshold = monitoringPartSource as ReferenceParameterThreshold;
            return super.isEqual(monitoringPartSource) &&
                _.isEqual(this.ruleThreshold, referenceParameterThreshold.ruleThreshold);
        }
    }

    export class ReferenceParameterObjective extends MonitoringPartSourceParameter {
        public objective: EntityTranslation;

        constructor(serverObject?: Shared.Contract.Read.IReferenceParameterObjective,
            objectives?: Array<EntityTranslation>) {
            super(serverObject);
            this.type = MonitoringPartSourceType.ReferenceParameterObjective;
            if (serverObject != null) {
                this.objective = _.find(objectives, o => o.Id === serverObject.ObjectiveId);
            }
        }

        public getName(): string {
            return this.objective.Text;
        }

        public toServerWrite(): Shared.Contract.Write.IReferenceParameterObjective {
            const result = <Shared.Contract.Write.IReferenceParameterObjective>super.toServerWrite();
            result.objective = this.objective.Id;
            return result;
        }

        public hasObjective(objective: EntityTranslation): boolean {
            return (this.objective == null && objective == null
                || this.objective != null && objective != null && this.objective.Id === objective.Id);
        }

        public isEqual(monitoringPartSource: MonitoringPartSource): boolean {
            const referenceParameterObjective = monitoringPartSource as ReferenceParameterObjective;
            return super.isEqual(monitoringPartSource) &&
                _.isEqual(this.objective, referenceParameterObjective.objective);
        }
    }

    export class ExternalDataSourceParameter extends MonitoringPartSourceParameter {
        public externalCharacteristicReference: string;
        public externalContextReference: string;
        public labelCharacteristic: string;
        public unit: string;

        constructor(serverObject?: Shared.Contract.Read.IExternalDataSourceParameter) {
            super(serverObject);
            this.type = MonitoringPartSourceType.ExternalDataSourceParameter;
            this.sourceType = MonitoringPartDataSourceType.ExternalSourceData;
            if (serverObject != null) {
                this.externalCharacteristicReference = serverObject.ExternalCharacteristicReference;
                this.externalContextReference = serverObject.ExternalContextReference;
                this.labelCharacteristic = serverObject.LabelCharacteristic;
                this.unit = serverObject.Unit;
            }
        }

        public getName(): string {
            return this.externalCharacteristicReference;
        }

        public toServerWrite(): Shared.Contract.Write.IExternalDataSourceParameter {
            const result = <Shared.Contract.Write.IExternalDataSourceParameter>super.toServerWrite();
            result.externalCharacteristicReference = this.externalCharacteristicReference;
            result.externalContextReference = this.externalContextReference;
            result.labelCharacteristic = this.labelCharacteristic;
            result.unitString = this.unit;
            return result;
        }

        public isEqual(monitoringPartSource: MonitoringPartSource): boolean {
            const externalDataSourceParameter = monitoringPartSource as ExternalDataSourceParameter;
            return super.isEqual(monitoringPartSource)
                && this.externalCharacteristicReference === externalDataSourceParameter.externalCharacteristicReference
                && this.externalContextReference === externalDataSourceParameter.externalContextReference;
        }
    }
}