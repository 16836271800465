namespace RemeCare.Shared.Framework.Service {
    export class StorageService {
        constructor(private $window: ng.IWindowService, private authservice: Framework.AuthService) {}

        public store<T>(key: string, value: T, personalize = true): void {
            const serialized = JSON.stringify(value);
            const personalizedKey = personalize ? this.generatePersonalizedKey(key) : key;
            if (personalizedKey && serialized) {
                this.$window.localStorage.setItem(personalizedKey, serialized);
            }
        }

        public get<T>(key: string, personalize = true): T {
            const personalizedKey = personalize ? this.generatePersonalizedKey(key) : key;
            if (personalizedKey) {
                const serialized = this.$window.localStorage.getItem(personalizedKey);
                if (serialized) {
                    return JSON.parse(serialized);
                }
            }

            return null;
        }

        public remove(key: string, personalize = true): void {
            const personalizedKey = personalize ? this.generatePersonalizedKey(key) : key;
            if (personalizedKey) {
                this.$window.localStorage.removeItem(personalizedKey);
            }
        }

        private generatePersonalizedKey(key: string): string {
            const partyRoleId = this.authservice.getClaim(ClaimTypes.partyRoleId);
            return partyRoleId && key ? `${key}-${partyRoleId}` : null;
        }
    }

    remeCareSharedModule.service('storageSvc', StorageService);
}
