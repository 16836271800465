namespace RemeCare.Shared.Directive {
    import GuidanceApiService = RemeCare.Shared.Framework.Service.GuidanceApiService;

    class GuidancePartController implements ng.IComponentController {
        public guidanceOutput: string;
        public uniqueId: string;
        public item: RemeCare.Shared.Contract.IGuidanceItem;

        private _activeIndex: number;

        get activeIndex(): number {
            return this._activeIndex;
        }

        set activeIndex(i: number) {
            this._activeIndex = i;
            alert(this._activeIndex);
        }

        // @ngInject
        constructor(
            protected $translate: ng.translate.ITranslateService,
            protected toaster: Shared.Framework.Toaster,
            private readonly guidanceApiSvc: GuidanceApiService
        ) {
            this.guidanceOutput = '';
        }

        public $onInit(): void {
            this.item = this.guidanceApiSvc.getGuidanceItem(this.uniqueId);
        }
    }

    remeCareSharedModule.component('rcGuidancePart', {
        bindings: {
            uniqueId: '@',
            guidanceOutput: '@',
        },
        controller: GuidancePartController,
        controllerAs: 'guidanceCtrl',
        templateUrl: 'views/guidance/guidancePart.html',
    });
}
