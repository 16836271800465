var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Directive;
            (function (Directive) {
                var TextLength = /** @class */ (function () {
                    function TextLength() {
                    }
                    TextLength.extraShort = 'extraShort';
                    TextLength.short = 'short';
                    TextLength.medium = 'medium';
                    TextLength.long = 'long';
                    TextLength.extraLong = 'extraLong';
                    TextLength.textArea = 'textArea';
                    return TextLength;
                }());
                Directive.TextLength = TextLength;
                var InputTextController = /** @class */ (function () {
                    function InputTextController($timeout) {
                        this.$timeout = $timeout;
                    }
                    InputTextController.prototype.$onInit = function () {
                        this.showTextInput = true;
                        this.showTextArea = false;
                        if (!this.textType && this.specificMaxLength) {
                            this.maxLength = this.specificMaxLength;
                        }
                        if (!this.maxLength) {
                            switch (this.textType) {
                                case TextLength.extraShort:
                                    this.maxLength = 10;
                                    break;
                                case TextLength.short:
                                    this.maxLength = 50;
                                    break;
                                case TextLength.medium:
                                    this.maxLength = 100;
                                    break;
                                case TextLength.long:
                                    this.maxLength = 200;
                                    break;
                                case TextLength.extraLong:
                                    this.maxLength = 400;
                                    break;
                                case TextLength.textArea:
                                    this.maxLength = 1000;
                                    this.showTextArea = true;
                                    this.showTextInput = false;
                                    break;
                                default:
                                    this.maxLength = 50;
                                    break;
                            }
                        }
                    };
                    InputTextController.prototype.textChanged = function () {
                        var _this = this;
                        this.$timeout(function () {
                            _this.ngChange && _this.ngChange();
                        });
                    };
                    return InputTextController;
                }());
                Shared.remeCareSharedModule.component('rcInputText', {
                    bindings: {
                        model: '=ngModel',
                        required: '=ngRequired',
                        disabled: '=ngDisabled',
                        readOnly: '=ngReadonly',
                        pattern: '=ngPattern',
                        blur: '&?ngBlur',
                        info: '@',
                        adjustSize: '=',
                        label: '@',
                        textType: '@',
                        ngChange: '&?',
                        hasWarning: '=?ngWarning',
                        specificMaxLength: '@',
                        placeholder: '@',
                        inputmode: '@',
                        guidanceIconLocation: '<',
                        guidanceIconFunc: '<',
                        uniqueId: '@',
                        name: '@',
                    },
                    controller: InputTextController,
                    require: {
                        formCtrl: '^form',
                    },
                    templateUrl: 'framework/directives/formElements/inputText/inputText.html',
                });
            })(Directive = Framework.Directive || (Framework.Directive = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
