namespace RemeCare.Shared.Framework.Directive {
    class InputRadioController<TModel> implements ng.IComponentController {
        // bindings
        public adjustSize: boolean;
        public disabled: boolean;
        public displayText: string;
        public fullWidth: boolean;
        public label: string;
        public model: TModel;
        public options: any[];
        public readonly: boolean;
        public required: boolean;
        public value: string;
        public uniqueName: string;

        public formCtrl: ng.IFormController;

        private previousValue: TModel;

        constructor(private readonly idService: Shared.Framework.Service.IIdService) {}

        public $onInit(): void {
            this.previousValue = this.model;
            this.uniqueName = this.idService.generateId();
        }

        public optionClicked(): void {
            if (!this.required && this.model !== null && angular.equals(this.model, this.previousValue)) {
                this.model = null;
            }
            this.previousValue = this.model;
        }
    }

    remeCareSharedModule.component('rcInputRadio', {
        bindings: {
            adjustSize: '<',
            disabled: '<ngDisabled',
            displayText: '@',
            fullWidth: '<',
            iconField: '@',
            inputClass: '<',
            label: '@',
            model: '=ngModel',
            options: '<',
            readOnly: '<ngReadonly',
            required: '<ngRequired',
            value: '@',
            change: '=ngChange',
            guidanceIconLocation: '<',
            guidanceIconFunc: '<',
            uniqueId: '@',
        },
        controller: InputRadioController,
        require: {
            formCtrl: '^form',
        },
        templateUrl: 'views/formElements/inputRadio.html',
    });
}
/* most common HTML template
<rc-input-radio ng-model=""
                ng-required=""
                ng-disabled=""
                label=""
                display-text=""
                options=""
                value=""></rc-input-radio>
*/
