namespace RemeCare.Shared.Framework {
    export interface IRequestShortcutConfig extends ng.IRequestShortcutConfig {
        ignoreUnauthorizedError?: boolean;
    }

    export class ApiServiceBase {
        protected static injectionParameters = ['baseUrl', '$http', 'spinnerSvc', '$q'];

        constructor(
            protected baseUrl: string,
            protected $http: ng.IHttpService,
            protected spinnerSvc: Framework.Service.SpinnerService,
            protected $q: ng.IQService
        ) {}

        /** @deprecated use getPromise */
        public get<TResult>(
            url: string,
            params?: any,
            spinner?: string,
            cache?: boolean,
            transformResponse?: (r: any) => TResult
        ): ng.IHttpPromise<TResult> {
            if (spinner) {
                this.spinnerSvc.show(spinner);
            }
            const fullUrl = this.baseUrl + url;
            const config: IRequestShortcutConfig = {
                params: params,
                cache: cache ? cache : false,
            };
            if (transformResponse != null) {
                config.transformResponse = this.appendTransform(
                    this.$http.defaults.transformResponse,
                    transformResponse
                );
            }
            const promise: ng.IHttpPromise<TResult> = this.$http.get(fullUrl, config);

            promise.finally(() => {
                if (spinner) {
                    this.spinnerSvc.hide(spinner);
                }
            });
            return promise;
        }

        public getPromise<TResult>(
            url: string,
            params?: any,
            spinner?: string,
            cache?: boolean,
            transformResponse?: (r: any) => TResult
        ): Promise<TResult> {
            return Promise.wrap(this.get<TResult>(url, params, spinner, cache, transformResponse))
                .then((httpResult) => httpResult.data)
                .catch((httpError) => Promise.reject(httpError.data));
        }

        /** @deprecated use postPromise */
        public post(
            url: string,
            params: any,
            spinner?: string,
            ignoreAuthenticationError?: boolean
        ): ng.IHttpPromise<{}> {
            if (spinner) {
                this.spinnerSvc.show(spinner);
            }
            const fullUrl = this.baseUrl + url;
            const config: IRequestShortcutConfig = {
                ignoreUnauthorizedError: ignoreAuthenticationError,
            };
            const promise: ng.IHttpPromise<{}> = this.$http.post(fullUrl, params, config);
            promise.finally(() => {
                if (spinner) {
                    this.spinnerSvc.hide(spinner);
                }
            });
            return promise;
        }

        public postPromise<TResult>(
            url: string,
            params: any,
            spinner?: string,
            ignoreAuthenticationError?: boolean,
            errorCallback?: (httpError: angular.IHttpPromiseCallbackArg<string>) => void
        ): Promise<TResult> {
            return Promise.wrap(this.post(url, params, spinner, ignoreAuthenticationError))
                .then((httpResult) => httpResult.data)
                .catch((httpError) => {
                    if (errorCallback) {
                        errorCallback(httpError);
                    }
                    return Promise.reject(httpError.data);
                });
        }

        public patch(
            url: string,
            params: any,
            spinner?: string,
            ignoreAuthenticationError?: boolean
        ): ng.IHttpPromise<{}> {
            if (spinner) {
                this.spinnerSvc.show(spinner);
            }
            const fullUrl = this.baseUrl + url;
            const config: IRequestShortcutConfig = {
                ignoreUnauthorizedError: ignoreAuthenticationError,
            };
            const promise: ng.IHttpPromise<{}> = this.$http.patch(fullUrl, params, config);
            promise.finally(() => {
                if (spinner) {
                    this.spinnerSvc.hide(spinner);
                }
            });
            return promise;
        }

        public patchPromise<TResult>(
            url: string,
            params: any,
            spinner?: string,
            ignoreAuthenticationError?: boolean,
            errorCallback?: (httpError: angular.IHttpPromiseCallbackArg<string>) => void
        ): Promise<TResult> {
            return Promise.wrap(this.patch(url, params, spinner, ignoreAuthenticationError))
                .then((httpResult) => httpResult.data)
                .catch((httpError) => {
                    if (errorCallback) {
                        errorCallback(httpError);
                    }
                    return Promise.reject(httpError.data);
                });
        }

        /** @deprecated use putPromise */
        public put(url: string, params: any, spinner?: string): ng.IHttpPromise<{}> {
            if (spinner) {
                this.spinnerSvc.show(spinner);
            }
            const fullUrl = this.baseUrl + url;
            const promise: ng.IHttpPromise<{}> = this.$http.put(fullUrl, params);
            promise.finally(() => {
                if (spinner) {
                    this.spinnerSvc.hide(spinner);
                }
            });
            return promise;
        }

        public putPromise<TResult>(url: string, params: any, spinner?: string): Promise<TResult> {
            return Promise.wrap(this.put(url, params, spinner))
                .then((httpResult) => httpResult.data)
                .catch((httpError) => Promise.reject(httpError.data));
        }

        /** @deprecated use deletePromise */
        public delete(url: string, spinner?: string): ng.IHttpPromise<{}> {
            if (spinner) {
                this.spinnerSvc.show(spinner);
            }
            const fullUrl = this.baseUrl + url;
            const promise: ng.IHttpPromise<{}> = this.$http.delete(fullUrl);
            promise.finally(() => {
                if (spinner) {
                    this.spinnerSvc.hide(spinner);
                }
            });
            return promise;
        }

        public deletePromise<TResult>(url: string, spinner?: string): Promise<TResult> {
            return Promise.wrap(this.delete(url, spinner))
                .then((httpResult) => httpResult.data)
                .catch((httpError) => Promise.reject(httpError.data));
        }

        /**
         * Get many items in batches / paged
         * @param url
         * @param set
         * @param chunkSize
         * @param queryMapper
         * @param spinner
         * @param cache
         * @param transformResponse
         * @returns
         */
        public async getInParts<T, TResult>(
            url: string,
            set: T[],
            chunkSize: number,
            queryMapper: (subset: T[]) => any,
            spinner?: string,
            cache?: boolean,
            transformResponse?: (r: any) => TResult[]
        ): Promise<TResult[]> {
            const calls = _.chunk<T>(set, chunkSize).map((subset) => {
                const query = queryMapper(subset as T[]);
                return this.getPromise<TResult[]>(url, query, spinner, cache, transformResponse);
            });

            const results = await Promise.all(calls);
            return _.chain(results)
                .map((r) => r)
                .flatten()
                .value();
        }

        private appendTransform(defaults, transform) {
            defaults = angular.isArray(defaults) ? defaults : [defaults];
            return defaults.concat(transform);
        }
    }
}
