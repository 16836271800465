module RemeCare.Shared.Contract.Code {

    export enum UIControlType {
        Dropdown = 1,
        Radio = 2,
        Slider = 3,
        Checkbox = 4, 
        CheckboxMultiple = 5,
        DropdownListMultiple = 6
}
}