namespace RemeCare.Shared.Framework.Directive {
    remeCareSharedModule.component('rcStatic', {
        bindings: {
            model: '<ngModel',
            adjustSize: '<',
            label: '@',
            text: '@',
            guidanceIconLocation: '<',
            guidanceIconFunc: '<',
            uniqueId: '@',
        },
        templateUrl: 'framework/directives/formElements/static/static.html',
    });
}
