var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Contract;
        (function (Contract) {
            var Code;
            (function (Code) {
                var PartWidth;
                (function (PartWidth) {
                    PartWidth[PartWidth["Full"] = 1] = "Full";
                    PartWidth[PartWidth["Half"] = 2] = "Half";
                    PartWidth[PartWidth["Third"] = 3] = "Third";
                    PartWidth[PartWidth["TwoThird"] = 4] = "TwoThird";
                })(PartWidth = Code.PartWidth || (Code.PartWidth = {}));
            })(Code = Contract.Code || (Contract.Code = {}));
        })(Contract = Shared.Contract || (Shared.Contract = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
