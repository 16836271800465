var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Directive;
            (function (Directive) {
                var PeriodicityEditorDirective = /** @class */ (function () {
                    function PeriodicityEditorDirective() {
                        this.scope = {
                            readOnly: '=',
                            recurrentCycle: '=',
                            setRecurrentCycleMembers: '&?setMembers',
                            setEvenOddDays: '&?setEvenOdd',
                            form: '=',
                            therapyActionPartContextSettings: '=',
                            forMedication: '=forMedication',
                            limitedConfig: '=',
                            medication: '=',
                            onlyIntakeMoments: '=',
                            carePlanStartDate: '<',
                            prescriptionStartDate: '<',
                        };
                        this.templateUrl = 'views/periodicity/periodicityEditor.html';
                        this.controller = 'PeriodicityEditorCtrl';
                    }
                    return PeriodicityEditorDirective;
                }());
                Shared.remeCareSharedModule.directive('rcPeriodicityEditor', function () { return new PeriodicityEditorDirective(); });
            })(Directive = Framework.Directive || (Framework.Directive = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
