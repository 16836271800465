var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Directive;
        (function (Directive) {
            var FileUploadTarget;
            (function (FileUploadTarget) {
                FileUploadTarget[FileUploadTarget["Document"] = 0] = "Document";
                FileUploadTarget[FileUploadTarget["Guidance"] = 1] = "Guidance";
                FileUploadTarget[FileUploadTarget["GenericDocument"] = 2] = "GenericDocument";
            })(FileUploadTarget = Directive.FileUploadTarget || (Directive.FileUploadTarget = {}));
            var ImmediateUploaderController = /** @class */ (function () {
                function ImmediateUploaderController($translate, $timeout, $filter, toaster, FileUploader, baseUrl) {
                    this.$translate = $translate;
                    this.$timeout = $timeout;
                    this.$filter = $filter;
                    this.toaster = toaster;
                    this.FileUploader = FileUploader;
                    this.baseUrl = baseUrl;
                }
                ImmediateUploaderController.prototype.$onInit = function () {
                    this.max = this.max || 5;
                    this.documents = this.documents || [];
                    this.target = this.target || FileUploadTarget.Document;
                    this.targetUrl = this.getTargetUrl(this.target);
                    this.createUploader();
                    this.setDocuments();
                };
                ImmediateUploaderController.prototype.$doCheck = function () {
                    if (!this.documents && this.expectedNumberOfDocuments !== 0) {
                        this.setDocuments();
                    }
                    if (this.documents && this.documents.length !== this.expectedNumberOfDocuments) {
                        this.setDocuments();
                    }
                };
                ImmediateUploaderController.prototype.getTargetUrl = function (uploadTarget) {
                    if (uploadTarget === FileUploadTarget.Guidance) {
                        return 'Documents/guidance';
                    }
                    if (uploadTarget === FileUploadTarget.GenericDocument) {
                        return 'Documents/Generic';
                    }
                    return 'Documents';
                };
                ImmediateUploaderController.prototype.createUploader = function () {
                    var _this = this;
                    var uploader = new this.FileUploader({
                        scope: this,
                        url: this.baseUrl + this.targetUrl,
                        filters: [
                            {
                                name: 'fileSize',
                                fn: function (item) {
                                    if (item.size <= 5 * 1024 * 1024) {
                                        return true;
                                    }
                                    return false;
                                },
                            },
                            {
                                name: 'fileType',
                                fn: function (item) {
                                    if (_this.fileExtensions != null && _this.fileExtensions.length > 0) {
                                        return _(_this.fileExtensions).any(function (f) {
                                            return item.name
                                                .toLowerCase()
                                                .indexOf(f.Text.toLowerCase(), item.name.length - f.Text.length) !== -1;
                                        });
                                    }
                                    return true;
                                },
                            },
                            {
                                name: 'fileNameSize',
                                fn: function (item) {
                                    return item.name.length <= 150;
                                },
                            },
                        ],
                        queueLimit: this.max || 5,
                    });
                    uploader.onErrorItem = function (item) {
                        _this.showProgress = false;
                        _this.uploader.removeFromQueue(item);
                        _this.toaster.error(_this.$translate.instant('Document.UploadError'));
                    };
                    uploader.onSuccessItem = function (item, response) {
                        _this.showProgress = false;
                        item.downloadUrl = _this.baseUrl + _this.targetUrl + '/' + response.Id;
                        item.id = response.Id;
                        response.Size = item.file.size;
                        item.remove = function () {
                            _this.onDocumentDelete(item, response);
                        };
                        if (_this.onItemAdded) {
                            var func = _this.onItemAdded({ doc: response });
                            if (_.isFunction(func)) {
                                func(response);
                            }
                        }
                        _this.expectedNumberOfDocuments++;
                        _this.documents.push(response);
                    };
                    uploader.onAfterAddingFile = function () {
                        _this.$timeout(function () {
                            if (!_this.uploadQueueEmpty()) {
                                _this.uploader.uploadAll(function () { });
                                _this.showProgress = true;
                            }
                        });
                    };
                    uploader.onWhenAddingFileFailed = function (item, filter) {
                        switch (filter.name) {
                            case 'fileType':
                                var fileTypes = _this.$filter('delimitedDisplay')(_this.fileExtensions, ', ');
                                _this.toaster.error(_this.$translate.instant('General.FileUpload.FileTypeIncorrect') + fileTypes);
                                break;
                            case 'fileSize':
                                _this.toaster.error(_this.$translate.instant('General.FileUpload.FileTooLarge'));
                                break;
                            case 'fileNameSize':
                                _this.toaster.error(_this.$translate.instant('General.FileUpload.FileNameTooLarge'));
                                break;
                        }
                    };
                    uploader.onProgressItem = function (item, progress) {
                        _this.progress = progress;
                    };
                    this.uploader = uploader;
                };
                ImmediateUploaderController.prototype.onDocumentDelete = function (item, document) {
                    // this.documentSvc.deleteDocument(document.Id).then(() => {
                    this.uploader.removeFromQueue(item);
                    var doc = _.find(this.documents, function (d) { return d.Id === document.Id; });
                    var index = this.documents.indexOf(doc);
                    this.expectedNumberOfDocuments--;
                    this.documents.splice(index, 1);
                    if (this.onItemDeleted) {
                        var func = this.onItemDeleted({ doc: doc });
                        if (_.isFunction(func)) {
                            func(doc);
                        }
                    }
                };
                ImmediateUploaderController.prototype.uploadQueueEmpty = function () {
                    return _.all(this.uploader.queue, function (item) {
                        return item.isUploaded;
                    });
                };
                ImmediateUploaderController.prototype.setDocuments = function () {
                    var _this = this;
                    this.uploader.queue = [];
                    this.expectedNumberOfDocuments = this.documents.length;
                    angular.forEach(this.documents, function (document) {
                        var item = new _this.FileUploader.FileItem(_this.uploader, {
                            name: document.Name ? document.Name.replace(/\"/g, '') : '',
                            size: document.Size,
                        });
                        item.progress = 100;
                        item.isUploaded = true;
                        item.isSuccess = true;
                        item.downloadUrl = _this.baseUrl + _this.targetUrl + '/' + document.Id;
                        item.remove = null;
                        item.remove = function () {
                            _this.onDocumentDelete(item, document);
                        };
                        item.id = document.Id;
                        _this.uploader.queue.push(item);
                    });
                };
                return ImmediateUploaderController;
            }());
            Shared.remeCareSharedModule.component('rcUploader', {
                controller: ImmediateUploaderController,
                templateUrl: 'views/uploader.html',
                bindings: {
                    readOnly: '<ngReadonly',
                    ngRequired: '<?',
                    documents: '=',
                    max: '<?',
                    onItemAdded: '&',
                    onItemDeleted: '&',
                    fileExtensions: '=?',
                    advancedColumns: '=?',
                    advancedColumnsData: '&?',
                    target: '<?',
                },
                require: {
                    formCtrl: '^form',
                },
            });
        })(Directive = Shared.Directive || (Shared.Directive = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
