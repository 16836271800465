var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Directive;
            (function (Directive) {
                var InputRadioController = /** @class */ (function () {
                    function InputRadioController(idService) {
                        this.idService = idService;
                    }
                    InputRadioController.prototype.$onInit = function () {
                        this.previousValue = this.model;
                        this.uniqueName = this.idService.generateId();
                    };
                    InputRadioController.prototype.optionClicked = function () {
                        if (!this.required && this.model !== null && angular.equals(this.model, this.previousValue)) {
                            this.model = null;
                        }
                        this.previousValue = this.model;
                    };
                    return InputRadioController;
                }());
                Shared.remeCareSharedModule.component('rcInputRadio', {
                    bindings: {
                        adjustSize: '<',
                        disabled: '<ngDisabled',
                        displayText: '@',
                        fullWidth: '<',
                        iconField: '@',
                        inputClass: '<',
                        label: '@',
                        model: '=ngModel',
                        options: '<',
                        readOnly: '<ngReadonly',
                        required: '<ngRequired',
                        value: '@',
                        change: '=ngChange',
                        guidanceIconLocation: '<',
                        guidanceIconFunc: '<',
                        uniqueId: '@',
                    },
                    controller: InputRadioController,
                    require: {
                        formCtrl: '^form',
                    },
                    templateUrl: 'views/formElements/inputRadio.html',
                });
            })(Directive = Framework.Directive || (Framework.Directive = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
/* most common HTML template
<rc-input-radio ng-model=""
                ng-required=""
                ng-disabled=""
                label=""
                display-text=""
                options=""
                value=""></rc-input-radio>
*/
