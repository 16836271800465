var Promise;
(function (Promise) {
    function wrap(ngPromise) {
        return new Promise(function (resolve, reject) {
            ngPromise
                .then(function (result) {
                resolve(result);
            }).catch(function (error) {
                reject(error);
            });
        });
    }
    Promise.wrap = wrap;
})(Promise || (Promise = {}));
