var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Service;
            (function (Service) {
                var StorageServiceConstants = /** @class */ (function () {
                    function StorageServiceConstants() {
                    }
                    // Keys
                    StorageServiceConstants.agendaKey = 'agendaFilter';
                    StorageServiceConstants.teamAgendaKey = 'teamAgendaFilter';
                    StorageServiceConstants.carePlanActionKey = 'carePlanActionFilter';
                    StorageServiceConstants.integrationMonitorKey = 'integrationMonitorFilter';
                    StorageServiceConstants.patientKey = 'patientFilter';
                    // String values for agendaFilter
                    StorageServiceConstants.myAgenda = 'agenda.myagenda';
                    StorageServiceConstants.teamAgenda = 'agenda.teamagenda';
                    StorageServiceConstants.colleagueAgenda = 'agenda.colleagueagenda';
                    StorageServiceConstants.fitbitPkceVerifier = 'fitbit.pkce.verifier';
                    return StorageServiceConstants;
                }());
                Service.StorageServiceConstants = StorageServiceConstants;
            })(Service = Framework.Service || (Framework.Service = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
