var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Contract;
        (function (Contract) {
            var Code;
            (function (Code) {
                var ContactPointUsageType;
                (function (ContactPointUsageType) {
                    ContactPointUsageType[ContactPointUsageType["None"] = 0] = "None";
                    ContactPointUsageType[ContactPointUsageType["Default"] = 1] = "Default";
                    ContactPointUsageType[ContactPointUsageType["Domicile"] = 2] = "Domicile";
                    ContactPointUsageType[ContactPointUsageType["Residence"] = 3] = "Residence";
                    ContactPointUsageType[ContactPointUsageType["Work"] = 4] = "Work";
                    ContactPointUsageType[ContactPointUsageType["Invoicing"] = 5] = "Invoicing";
                    ContactPointUsageType[ContactPointUsageType["Postal"] = 6] = "Postal";
                })(ContactPointUsageType = Code.ContactPointUsageType || (Code.ContactPointUsageType = {}));
            })(Code = Contract.Code || (Contract.Code = {}));
        })(Contract = Shared.Contract || (Shared.Contract = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
