var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Model;
            (function (Model) {
                var InformedConsent = /** @class */ (function () {
                    function InformedConsent(languages, informedConsent) {
                        var _this = this;
                        if (informedConsent == null) {
                            this.informedConsentTranslations = [];
                            languages.forEach(function (l) {
                                _this.informedConsentTranslations.push(new Model.InformedConsentTranslation(l.Code));
                            });
                            this.versions = [];
                        }
                        else {
                            this.id = informedConsent.Id;
                            this.therapyId = informedConsent.TherapyId;
                            this.version = informedConsent.Version;
                            this.versions = _.sortBy(_.map(informedConsent.Versions, function (v) { return new Model.InformedConsentVersion(v); }), function (v) {
                                return v.isDraft ? Shared.DateHelper.endOfTime() : v.validFromDate;
                            }).reverse();
                            this.informedConsentTranslations = [];
                            languages.forEach(function (l) {
                                _this.informedConsentTranslations.push(new Model.InformedConsentTranslation(l.Code, _.find(informedConsent.Translations, function (t) {
                                    return t.Culture === l.Code;
                                }), informedConsent.Questions));
                            });
                        }
                    }
                    InformedConsent.prototype.getLastVersion = function () {
                        var sorted = _.sortBy(this.versions, function (v) { return v.validFromDate; });
                        return _.last(sorted);
                    };
                    InformedConsent.prototype.getVersion = function (perspectiveDate) {
                        return _.find(this.versions, function (v) {
                            return (v.validFromDate <= perspectiveDate &&
                                (v.validUntilDate == null ||
                                    v.validUntilDate <= moment('2000-01-01').toDate() ||
                                    v.validUntilDate >= perspectiveDate));
                        });
                    };
                    InformedConsent.prototype.acceptDraftVersion = function (fromDate) {
                        var draftVersion = _.find(this.versions, function (v) { return v.isDraft; });
                        if (draftVersion) {
                            var lastVersion = this.getLastVersion();
                            lastVersion.setValidity(lastVersion.validFromDate, fromDate);
                            draftVersion.setIsDraft(false);
                            draftVersion.setValidity(fromDate, null);
                        }
                    };
                    InformedConsent.prototype.toServerWrite = function () {
                        var questions = [];
                        var maxSequence = Math.max.apply(null, _.map(this.informedConsentTranslations, function (ict) {
                            return ict.questions.length;
                        })) + 1;
                        var _loop_1 = function (i) {
                            var translations = [];
                            this_1.informedConsentTranslations.forEach(function (infcot) {
                                var value = _.filter(infcot.questions, function (q) {
                                    return q.sequence === i;
                                });
                                if (value.length > 0) {
                                    translations.push({
                                        Culture: infcot.culture,
                                        Name: value[0].question
                                    });
                                }
                            });
                            if (translations.length > 0) {
                                questions.push({
                                    Sequence: i,
                                    Question: translations
                                });
                            }
                        };
                        var this_1 = this;
                        for (var i = 1; i <= maxSequence; i++) {
                            _loop_1(i);
                        }
                        var titles = _.map(this.informedConsentTranslations, function (infcot) {
                            if (infcot.title != null) {
                                return {
                                    Culture: infcot.culture,
                                    Name: infcot.title
                                };
                            }
                        });
                        var contents = _.map(this.informedConsentTranslations, function (infcot) {
                            if (infcot.content != null) {
                                return {
                                    Culture: infcot.culture,
                                    Name: infcot.content
                                };
                            }
                        });
                        return {
                            Id: this.id,
                            TherapyId: this.therapyId,
                            Version: this.version,
                            Title: _.filter(titles, function (x) { return x != null; }),
                            Content: _.filter(contents, function (c) { return c != null; }),
                            Questions: questions
                        };
                    };
                    return InformedConsent;
                }());
                Model.InformedConsent = InformedConsent;
            })(Model = Framework.Model || (Framework.Model = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
