module RemeCare.Shared.Framework.Model {
    import ITranslationParameters = Shared.Contract.ITranslationParameters;

    export class InformedConsent {
        id: Shared.Contract.Guid;
        therapyId: Shared.Contract.Guid;
        version: string;
        versions: InformedConsentVersion[];
        informedConsentTranslations: InformedConsentTranslation[];

        constructor(
            languages: Array<Shared.Contract.IEnumCodeTranslation>,
            informedConsent?: Contract.Read.IInformedConsent
        ) {
            if (informedConsent == null) {
                this.informedConsentTranslations = [];
                languages.forEach(l => {
                    this.informedConsentTranslations.push(new InformedConsentTranslation(l.Code));
                });
                this.versions = [];
            } else {
                this.id = informedConsent.Id;
                this.therapyId = informedConsent.TherapyId;
                this.version = informedConsent.Version;
                this.versions = _.sortBy(_.map(informedConsent.Versions, v => new InformedConsentVersion(v)), v => {
                    return v.isDraft ? Shared.DateHelper.endOfTime() : v.validFromDate;
                }).reverse();
                this.informedConsentTranslations = [];
                languages.forEach(l => {
                    this.informedConsentTranslations.push(
                        new InformedConsentTranslation(
                            l.Code,
                            _.find(informedConsent.Translations, t => {
                                return t.Culture === l.Code;
                            }),
                            informedConsent.Questions
                        )
                    );
                });
            }
        }

        public getLastVersion(): InformedConsentVersion {
            const sorted = _.sortBy(this.versions, v => v.validFromDate);
            return _.last(sorted);
        }

        public getVersion(perspectiveDate): InformedConsentVersion {
            return _.find(this.versions, v => {
                return (
                    v.validFromDate <= perspectiveDate &&
                    (v.validUntilDate == null ||
                        v.validUntilDate <= moment('2000-01-01').toDate() ||
                        v.validUntilDate >= perspectiveDate)
                );
            });
        }

        public acceptDraftVersion(fromDate: Date): void {
            const draftVersion = _.find(this.versions, v => v.isDraft);
            if (draftVersion) {
                const lastVersion = this.getLastVersion();
                lastVersion.setValidity(lastVersion.validFromDate, fromDate);
                draftVersion.setIsDraft(false);
                draftVersion.setValidity(fromDate, null);
            }
        }

        public toServerWrite(): Contract.Write.IInformedConsent {
            const questions = [];
            const maxSequence =
                Math.max.apply(
                    null,
                    _.map(this.informedConsentTranslations, ict => {
                        return ict.questions.length;
                    })
                ) + 1;
            for (let i = 1; i <= maxSequence; i++) {
                const translations = [];
                this.informedConsentTranslations.forEach(infcot => {
                    var value = _.filter(infcot.questions, q => {
                        return q.sequence === i;
                    });
                    if (value.length > 0) {
                        translations.push(<ITranslationParameters>{
                            Culture: infcot.culture,
                            Name: value[0].question
                        });
                    }
                });
                if (translations.length > 0) {
                    questions.push(<Shared.Contract.Write.IInformedConsentQuestion>{
                        Sequence: i,
                        Question: translations
                    });
                }
            }

            const titles = _.map(this.informedConsentTranslations, infcot => {
                if (infcot.title != null) {
                    return <ITranslationParameters>{
                        Culture: infcot.culture,
                        Name: infcot.title
                    };
                }
            });
            const contents = _.map(this.informedConsentTranslations, infcot => {
                if (infcot.content != null) {
                    return <ITranslationParameters>{
                        Culture: infcot.culture,
                        Name: infcot.content
                    };
                }
            });
            return <Contract.Write.IInformedConsent>{
                Id: this.id,
                TherapyId: this.therapyId,
                Version: this.version,
                Title: _.filter(titles, x => x != null),
                Content: _.filter(contents, c => c != null),
                Questions: questions
            };
        }
    }
}
