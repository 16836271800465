namespace RemeCare.Shared.Framework.Service {
    import EnumTranslation = Shared.Contract.IEnumTranslation;
    import Guid = Shared.Contract.Guid;

    export class SecurityApiSvc extends Framework.ApiServiceBase {
        public static $inject = Shared.Framework.ApiServiceBase.injectionParameters;

        public getSecurityContexts(): Promise<{ [id: string]: number }> {
            return this.getPromise('Security');
        }

        public getClaims(): Promise<{ [id: string]: string }> {
            return this.getPromise('Security/Claims', null, 'claims');
        }

        public findMyApplicationProfilesAsync(): Promise<EnumTranslation[]> {
            return this.getPromise('Security/MyApplicationProfiles');
        }

        /** @deprecated */
        public findAssignableApplicationProfiles(): ng.IHttpPromise<EnumTranslation[]> {
            return this.get('Security/AssignableApplicationProfiles');
        }

        public findAssignableApplicationProfilesAsync(): Promise<EnumTranslation[]> {
            return this.getPromise('Security/AssignableApplicationProfiles');
        }

        public setApplicationProfile(
            applicationProfile: Contract.Code.ApplicationProfileType
        ): Promise<{
            Rights: { [id: string]: AuthRight };
            PartyRoleId: Guid;
        }> {
            return this.postPromise('Security/MyApplicationProfiles', {
                applicationProfile,
            }) as Promise<{
                Rights: { [id: string]: AuthRight };
                PartyRoleId: Guid;
            }>;
        }

        public isUserBlocked(partyId: Guid): Promise<Contract.Read.IUserStatus> {
            return this.getPromise(`Security/Users/${partyId}/Blocked`);
        }

        public getUserDetailsAsync(partyId: Guid): Promise<Contract.Read.IUserDetails> {
            return this.getPromise(`Security/Users/${partyId}`, null, 'userDetails');
        }

        public getApplicationProfileAssignmentsAsync(
            partyId: Guid
        ): Promise<Contract.Read.IApplicationProfileAssignment[]> {
            return this.getPromise(`Security/Users/${partyId}/ApplicationProfileAssignments`);
        }

        public checkUserNameExists(userName: string): ng.IHttpPromise<string> {
            return this.get(
                'Security/Users/CheckUserName',
                {
                    userName,
                },
                'user'
            );
        }

        public createUser(user: Shared.Contract.Write.INewUser): ng.IHttpPromise<{}> {
            return this.post('Security/Users', user, 'user');
        }

        public assignProfile(
            partyId: Guid,
            applicationProfile: Contract.Code.ApplicationProfileType,
            reason: string
        ): ng.IHttpPromise<{}> {
            return this.post(`Security/Users/${partyId}/ApplicationProfileAssignments/${applicationProfile}/Assign`, {
                statusChangeReason: reason,
            });
        }

        public revokeProfile(
            partyId: Guid,
            applicationProfile: Contract.Code.ApplicationProfileType,
            reason: string
        ): ng.IHttpPromise<{}> {
            return this.post(`Security/Users/${partyId}/ApplicationProfileAssignments/${applicationProfile}/Revoke`, {
                statusChangeReason: reason,
            });
        }

        public sendResetMail(partyId: Guid, sendToUser: boolean): ng.IHttpPromise<{}> {
            return this.post(`Security/Users/${partyId}/SendResetMail`, {
                sendToUser,
            });
        }

        public blockUser(partyId: Guid, reason: string): ng.IHttpPromise<{}> {
            return this.post(`Security/Users/${partyId}/Block`, {
                statusChangeReason: reason,
            });
        }

        public unblockUser(partyId: Guid, reason: string): ng.IHttpPromise<{}> {
            return this.post(`Security/Users/${partyId}/Unblock`, {
                statusChangeReason: reason,
            });
        }

        public searchUserAccountHistoryAsync(
            partyId: Guid,
            query: Shared.Contract.ISearchQuery
        ): Promise<Shared.Contract.ISearchResult<Shared.Contract.Read.IUserAccountHistory>> {
            return this.getPromise(`Security/Users/${partyId}/UserAccountHistory`, query);
        }

        public changeUsername(partyId: Guid, userName: string, sendMailToUser: boolean): ng.IHttpPromise<{}> {
            return this.post(`Security/Users/${partyId}/ChangeUserName`, {
                username: userName,
                sendToUser: sendMailToUser
            });
        }
    }

    remeCareSharedModule.service('securityApiSvc', SecurityApiSvc);
}
