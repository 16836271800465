var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var MultiSelectorDirective = /** @class */ (function () {
                function MultiSelectorDirective($timeout) {
                    var _this = this;
                    this.$timeout = $timeout;
                    this.restrict = 'E';
                    this.scope = {
                        items: '=items',
                        selectedItems: '=selectedItems',
                        itemsTitle: '@itemsTitle',
                        selectedTitle: '@selectedTitle',
                        disabled: '=ngDisabled'
                    };
                    this.templateUrl = 'views/multiSelector.html';
                    this.link = function (scope) {
                        scope.selectedItems = _(scope.selectedItems).sortBy(function (item) { return item.Text != null ? item.Text.toLowerCase() : ''; });
                        var selectedItemIds = _(scope.selectedItems).map(function (item) { return item.Id; });
                        scope.items = _(scope.items).chain()
                            .filter(function (item) {
                            return !_(selectedItemIds).any(function (itemId) { return itemId === item.Id; });
                        })
                            .sortBy(function (item) { return item.Text != null ? item.Text.toLowerCase() : ''; })
                            .value();
                        scope.addItem = function (selected) {
                            if (scope.disabled)
                                return;
                            scope.items = _(scope.items).without(selected);
                            scope.selectedItems.push(selected);
                            scope.selectedItems = _(scope.selectedItems).sortBy(function (item) { return item.Text != null ? item.Text.toLowerCase() : ''; });
                            _this.$timeout(function () { scope.$apply(); });
                        };
                        scope.removeItem = function (removed) {
                            if (scope.disabled)
                                return;
                            scope.selectedItems = _(scope.selectedItems).without(removed);
                            scope.items.push(removed);
                            scope.items = _(scope.items).sortBy(function (item) { return item.Text != null ? item.Text.toLowerCase() : ''; });
                            _this.$timeout(function () { scope.$apply(); });
                        };
                    };
                }
                return MultiSelectorDirective;
            }());
            angular.module('RemeCare.Shared').directive('rcMultiSelector', function ($timeout) { return new MultiSelectorDirective($timeout); });
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
