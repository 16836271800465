module RemeCare.Shared.Framework.Model {
    import Guid = Shared.Contract.Guid;

    export class CodeSetColour {
        codeSetItemId: Guid;
        colour: string;

        constructor(serverObject?: Shared.Contract.Read.ICodeSetColour) {
            if (serverObject != null) {
                this.codeSetItemId = serverObject.CodeSetItemId;
                this.colour = serverObject.Colour;
            }
        }

        toServerWrite(): Shared.Contract.Write.ICodeSetColour {
            return <Shared.Contract.Write.ICodeSetColour>{
                codeSetItemId: this.codeSetItemId,
                colour: this.colour
            }
        }
    }
}