var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Service;
            (function (Service) {
                var IdService = /** @class */ (function () {
                    function IdService() {
                        this.currentId = 0;
                    }
                    IdService.prototype.generateId = function () {
                        return "rc-id-" + this.currentId++;
                    };
                    return IdService;
                }());
                Shared.remeCareSharedModule.factory('idService', function () { return new IdService(); });
            })(Service = Framework.Service || (Framework.Service = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
