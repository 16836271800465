var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Directive;
        (function (Directive) {
            var GuidancePartController = /** @class */ (function () {
                // @ngInject
                function GuidancePartController($translate, toaster, guidanceApiSvc) {
                    this.$translate = $translate;
                    this.toaster = toaster;
                    this.guidanceApiSvc = guidanceApiSvc;
                    this.guidanceOutput = '';
                }
                Object.defineProperty(GuidancePartController.prototype, "activeIndex", {
                    get: function () {
                        return this._activeIndex;
                    },
                    set: function (i) {
                        this._activeIndex = i;
                        alert(this._activeIndex);
                    },
                    enumerable: true,
                    configurable: true
                });
                GuidancePartController.prototype.$onInit = function () {
                    this.item = this.guidanceApiSvc.getGuidanceItem(this.uniqueId);
                };
                return GuidancePartController;
            }());
            Shared.remeCareSharedModule.component('rcGuidancePart', {
                bindings: {
                    uniqueId: '@',
                    guidanceOutput: '@',
                },
                controller: GuidancePartController,
                controllerAs: 'guidanceCtrl',
                templateUrl: 'views/guidance/guidancePart.html',
            });
        })(Directive = Shared.Directive || (Shared.Directive = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
