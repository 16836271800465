var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var RouteHighlightDirective = /** @class */ (function () {
                function RouteHighlightDirective($transitions, $location) {
                    var _this = this;
                    this.$transitions = $transitions;
                    this.$location = $location;
                    this.restrict = 'A';
                    this.scope = {
                        route: '@rcRouteHighlight'
                    };
                    this.link = function (scope, element) {
                        var setClass = function () {
                            var reg = new RegExp(scope.route);
                            if (_this.$location.path().match(reg)) {
                                element.addClass('active');
                            }
                            else {
                                element.removeClass('active');
                            }
                        };
                        setClass();
                        _this.$transitions.onSuccess({}, function () { return setClass(); });
                    };
                }
                return RouteHighlightDirective;
            }());
            angular.module('RemeCare.Shared').directive('rcRouteHighlight', function ($transitions, $location) { return new RouteHighlightDirective($transitions, $location); });
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
