var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Contract;
        (function (Contract) {
            var Code;
            (function (Code) {
                var AlarmLevel;
                (function (AlarmLevel) {
                    AlarmLevel[AlarmLevel["Low"] = 1] = "Low";
                    AlarmLevel[AlarmLevel["Medium"] = 2] = "Medium";
                    AlarmLevel[AlarmLevel["High"] = 3] = "High";
                })(AlarmLevel = Code.AlarmLevel || (Code.AlarmLevel = {}));
                var AlarmStatus;
                (function (AlarmStatus) {
                    AlarmStatus[AlarmStatus["Open"] = 1] = "Open";
                    AlarmStatus[AlarmStatus["Pending"] = 2] = "Pending";
                    AlarmStatus[AlarmStatus["Handled"] = 3] = "Handled";
                })(AlarmStatus = Code.AlarmStatus || (Code.AlarmStatus = {}));
            })(Code = Contract.Code || (Contract.Code = {}));
        })(Contract = Shared.Contract || (Shared.Contract = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
