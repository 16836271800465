module RemeCare.Shared.Framework {

    interface IMultiSelectorScope extends ng.IScope {
        items: Array<Contract.IEntityTranslation>;
        selectedItems: Array<Contract.IEntityTranslation>;
        addItem: (item: Contract.IEntityTranslation) => void;
        removeItem: (item: Contract.IEntityTranslation) => void;
        disabled: boolean;
    }

    class MultiSelectorDirective implements ng.IDirective {
        
        constructor(private $timeout: ng.ITimeoutService) {
            
        }
        restrict: string = 'E';

        scope: { [boundProperty: string]: string } = {
            items: '=items',
            selectedItems: '=selectedItems',
            itemsTitle: '@itemsTitle',
            selectedTitle: '@selectedTitle',
            disabled: '=ngDisabled'
        }

        templateUrl: string = 'views/multiSelector.html';

        link: ng.IDirectiveLinkFn = (scope: IMultiSelectorScope) => {
            scope.selectedItems = _(scope.selectedItems).sortBy((item: Contract.IEntityTranslation) => item.Text != null ? item.Text.toLowerCase() : '');
            var selectedItemIds = _(scope.selectedItems).map((item: Contract.IEntityTranslation) => item.Id);
            scope.items = _(scope.items).chain()
                .filter((item: Contract.IEntityTranslation) => {
                    return !_(selectedItemIds).any((itemId) => { return itemId === item.Id; });
                })
                .sortBy((item: Contract.IEntityTranslation) => item.Text != null ? item.Text.toLowerCase() : '')
                .value();

            scope.addItem = (selected: Contract.ICodedEntityTranslation) => {
                if (scope.disabled) return;
                scope.items = _(scope.items).without(selected);
                scope.selectedItems.push(selected);
                scope.selectedItems = _(scope.selectedItems).sortBy((item: Contract.ICodedEntityTranslation) => item.Text != null ? item.Text.toLowerCase() : '');
                this.$timeout(() => { scope.$apply(); });
            }

            scope.removeItem = (removed: Contract.ICodedEntityTranslation) => {
                if (scope.disabled) return;
                scope.selectedItems = _(scope.selectedItems).without(removed);
                scope.items.push(removed);
                scope.items = _(scope.items).sortBy((item: Contract.ICodedEntityTranslation) => item.Text != null ? item.Text.toLowerCase() : '');
                this.$timeout(() => { scope.$apply(); });
            }
        }
    }

    angular.module('RemeCare.Shared').directive('rcMultiSelector', ($timeout) => new MultiSelectorDirective($timeout));
}