var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Model;
            (function (Model) {
                var ActorRoleInclusionDetail = /** @class */ (function () {
                    function ActorRoleInclusionDetail(serverObject) {
                        if (serverObject != null) {
                            this.id = serverObject.Id;
                            this.healthCareOrganisationTypeId = serverObject.HealthCareOrganisationTypeId;
                            this.partyRoleId = serverObject.PartyRoleId;
                            this.healthCareProfessionalTypeId = serverObject.HealthCareProfessionalTypeId;
                            this.healthCareSpecialtyProfessionId = serverObject.HealthCareSpecialtyProfessionId;
                            this.partyName = serverObject.PartyName;
                        }
                    }
                    ActorRoleInclusionDetail.prototype.copy = function () {
                        var result = new ActorRoleInclusionDetail();
                        angular.copy(this, result);
                        return result;
                    };
                    ActorRoleInclusionDetail.prototype.toServerWrite = function () {
                        return {
                            id: this.id,
                            healthCareOrganisationTypeId: this.healthCareOrganisationTypeId,
                            partyRoleId: this.partyRoleId,
                            healthCareProfessionalTypeId: this.healthCareProfessionalTypeId,
                            healthCareSpecialtyProfessionId: this.healthCareSpecialtyProfessionId
                        };
                    };
                    return ActorRoleInclusionDetail;
                }());
                Model.ActorRoleInclusionDetail = ActorRoleInclusionDetail;
            })(Model = Framework.Model || (Framework.Model = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
