var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Contract;
        (function (Contract) {
            var Code;
            (function (Code) {
                var PeriodicityType;
                (function (PeriodicityType) {
                    PeriodicityType[PeriodicityType["Periodicity"] = 1] = "Periodicity";
                    PeriodicityType[PeriodicityType["PeriodicityDay"] = 2] = "PeriodicityDay";
                    PeriodicityType[PeriodicityType["PeriodicityWeek"] = 3] = "PeriodicityWeek";
                    PeriodicityType[PeriodicityType["PeriodicityMonth"] = 4] = "PeriodicityMonth";
                    PeriodicityType[PeriodicityType["PeriodicityYear"] = 5] = "PeriodicityYear";
                })(PeriodicityType = Code.PeriodicityType || (Code.PeriodicityType = {}));
            })(Code = Contract.Code || (Contract.Code = {}));
        })(Contract = Shared.Contract || (Shared.Contract = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
