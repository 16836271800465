module RemeCare.Shared.Contract.Code {
    
    export enum Right {
        Nothing = 0,
        Read = 1,
        Write = 2,
        Create = 4,
        Delete = 8,
        All = Read | Write | Create | Delete
    }
}