namespace RemeCare.Shared.Framework.Directive {
    class InputTimeRangeController implements ng.IComponentController {
        // bindings
        public change: () => void;
        public fromModel: Date;
        public untilModel: Date;

        constructor(private $timeout: ng.ITimeoutService) {}

        public $onInit(): void {
            this.updateDate();
        }

        public $onChanges(changes: ng.IOnChangesObject): void {
            if (changes.fromModel) {
                this.$timeout(() => this.updateDate());
            }
        }

        private updateDate(): void {
            if (!this.fromModel) {
                return;
            }

            if (!this.untilModel) {
                this.untilModel = this.fromModel;
            } else {
                this.untilModel.setFullYear(
                    this.fromModel.getFullYear(),
                    this.fromModel.getMonth(),
                    this.fromModel.getDate()
                );
            }
        }
    }

    remeCareSharedModule.component('rcInputTimeRange', {
        bindings: {
            fromModel: '=',
            untilModel: '=',
            required: '=?ngRequired',
            readOnly: '=?ngReadonly',
            disabled: '=?ngDisabled',
            guidanceIconLocation: '<',
            guidanceIconFunc: '<',
            uniqueId: '@',
        },
        controller: InputTimeRangeController,
        require: {
            formCtrl: '^form',
        },
        templateUrl: 'framework/directives/formElements/inputTimeRange/inputTimeRange.html',
        transclude: true,
    });
}
