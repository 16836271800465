var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Contract;
        (function (Contract) {
            var Code;
            (function (Code) {
                var MonitoringPartDataSourceType;
                (function (MonitoringPartDataSourceType) {
                    MonitoringPartDataSourceType[MonitoringPartDataSourceType["RegistrationValue"] = 1] = "RegistrationValue";
                    MonitoringPartDataSourceType[MonitoringPartDataSourceType["CarePlanAnamnesisValue"] = 2] = "CarePlanAnamnesisValue";
                    MonitoringPartDataSourceType[MonitoringPartDataSourceType["ObjectValue"] = 3] = "ObjectValue";
                    MonitoringPartDataSourceType[MonitoringPartDataSourceType["ThresholdValue"] = 4] = "ThresholdValue";
                    MonitoringPartDataSourceType[MonitoringPartDataSourceType["MediaLibQuestion"] = 5] = "MediaLibQuestion";
                    MonitoringPartDataSourceType[MonitoringPartDataSourceType["ExternalSourceData"] = 6] = "ExternalSourceData";
                })(MonitoringPartDataSourceType = Code.MonitoringPartDataSourceType || (Code.MonitoringPartDataSourceType = {}));
            })(Code = Contract.Code || (Contract.Code = {}));
        })(Contract = Shared.Contract || (Shared.Contract = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
