module RemeCare.Shared.Framework.InformedConsent {
    import SearchResult = Shared.Contract.ISearchResult;
    import IInformedConsentSummary = Shared.Contract.Read.IInformedConsentSummary;

    class InformedConsentsOverviewController implements ng.IComponentController {
        public $transition$;
        public grid: Shared.Framework.Grid.Grid<IInformedConsentSummary>;
        public informedConsents: IInformedConsentSummary[];
        public routePrefix: string;

        constructor(
            private readonly toaster: Shared.Framework.Toaster,
            private readonly $state: ng.ui.IStateService,
            private readonly $stateParams,
            private readonly informedConsentSvc: Shared.Framework.Service.InformedConsentService,
            private readonly gridBuilderSvc: Shared.Framework.Grid.GridBuilderFactory
        ) {}

        public async $onInit(): Promise<void> {
            this.routePrefix = this.$transition$.params().routePrefix;
            this.buildGrid();
            try {
                const searchResult = await this.informedConsentSvc.getInformedConsents(this.$stateParams.therapyId);
                this.informedConsents = searchResult.Items;
                this.grid.setData(this.informedConsents);
            } catch (e) {
                this.toaster.error(e);
            }
        }

        private buildGrid(): void {
            const builder = this.gridBuilderSvc
                .createGridBuilder<IInformedConsentSummary>()
                .addColumn('Title', 'General.Name')
                .addColumn('Version', 'General.Version')
                .addColumn('DraftVersion', 'Views.InformedConsent.Draft')
                .addColumn('LastUpdatedBy', 'Views.InformedConsent.LastUpdatedBy')
                .addNavigationColumn(
                    'pencil',
                    this.routePrefix + 'informedConsents.informedConsentVersion',
                    {
                        informedConsentId: 'Id'
                    },
                    { routePrefix: 'routePrefix' }
                );

            this.grid = builder.build();
        }
    }

    Shared.remeCareSharedModule.component('rcInformedConsentsOverview', {
        controller: InformedConsentsOverviewController,
        templateUrl: 'framework/informedConsent/overview/informedConsentsOverview.html',
        bindings: {
            $transition$: '<'
        }
    });
}
