namespace RemeCare.Shared.Framework.Directive {
    class CancelButton {
        public label: string;

        public $onInit(): void {
            this.label = this.label || 'General.Cancel';
        }
    }

    remeCareSharedModule.component('rcCancel', {
        controller: CancelButton,
        templateUrl: 'views/controls/cancelButton.html',
        bindings: {
            cancel: '&?',
            label: '@?',
            ngDisabled: '<?',
            link: '@?',
            spinner: '@?',
        },
    });
}
