namespace RemeCare.Shared.Framework.Periodicity {
    import Periodicity = Framework.Model.Periodicity;
    import IntraDayTiming = Framework.Model.IntraDayTiming;
    import PeriodicityFactory = Framework.Factory.PeriodicityFactory;
    import IntraDayTimingFactory = Framework.Factory.IntraDayTimingFactory;
    import DailyAlternationType = RemeCare.Shared.Contract.Code.DailyAlternationType;
    import EvenOddDays = RemeCare.Shared.Contract.Code.EvenOddDays;
    import PeriodicityDay = RemeCare.Shared.Framework.Model.PeriodicityDay;

    export interface IPeriodicityEditorScope extends Framework.IBaseScope, ng.ui.bootstrap.IModalScope {
        form: ng.IFormController;
        periodicity: Periodicity;
        currentSequence: number;
        recurrentCycle: Periodicity[];
        intraDayTimingsGrid: Framework.Grid.Grid<IntraDayTiming>;
        minDurationQuantity: number;
        possibleDurationUnits: Contract.IEnumTranslation[];
        readOnly: boolean;
        newPeriodicity: (durationUnit: Contract.IEnumTranslation) => void;
        addIntraDayTiming: () => void;
        isFirstInRecurrence: () => boolean;
        isLastInRecurrence: () => boolean;
        setRecurrentCycleMembers: (periodicity: Periodicity) => void;
        setEvenOddDays: (dailyAlternationType: DailyAlternationType) => void;
        changeDuration: () => void;
        clearDuration: () => void;
        changeAlternation: () => void;
        changeRecurrenceCycleView: (sequence: number) => void;
        forMedication: boolean;
        limitedConfig: boolean;
        medication: Shared.Contract.Read.IMedication;
        onlyIntakeMoments: boolean;
        carePlanStartDate: Date;
        carePlanStartDateReadable: string;
        prescriptionStartDate: Date;
        prescriptionStartDateReadable: () => string;
        days: Shared.Service.IDay[];
        therapyActionPartContextSettings: Framework.Model.TherapyActionPartContextSetting[];
        isRoot: () => boolean;
        limitedDurationUnits: Shared.Contract.IEnumTranslation[];
        extraLimitedDurationUnits: Shared.Contract.IEnumTranslation[];
        weekdayOccurences: Array<{
            text: string;
            value: number;
        }>;
        months: Array<{
            name: string;
            value: number;
        }>;
        activeTab: number;
    }

    class PeriodicityEditorController extends Framework.ControllerBase<IPeriodicityEditorScope> {
        constructor(
            protected $scope: IPeriodicityEditorScope,
            protected $translate: ng.translate.ITranslateService,
            protected toaster: Framework.Toaster,
            private readonly gridBuilderSvc: Framework.Grid.GridBuilderFactory,
            private readonly modalBuilderFactory: Framework.Helper.ModalBuilderFactory,
            private readonly masterdataSvc: Framework.MasterdataService,
            private readonly daySvc: Shared.Service.DayService,
            private readonly $locale: ng.ILocaleService
        ) {
            super($scope, $translate, toaster);

            $scope.newPeriodicity = d => this.newPeriodicity(d.Id);
            $scope.addIntraDayTiming = () => this.addIntraDayTimingAsync();
            $scope.isFirstInRecurrence = () => this.isFirstInRecurrence();
            $scope.isLastInRecurrence = () => this.isLastInRecurrence();
            $scope.setEvenOddDays = t => this.setEvenOddDays(t);
            $scope.setRecurrentCycleMembers = p => this.setRecurrentCycleMembers(p);
            $scope.changeDuration = () => this.changeDuration();
            $scope.clearDuration = () => this.clearDuration();
            $scope.changeRecurrenceCycleView = s => this.changeRecurrenceCycleView(s);
            $scope.isRoot = () => this.isRoot();
            $scope.changeAlternation = () => this.changeAlternation();
        }

        public $onInit(): void {
            this.$scope.recurrentCycle = _.sortBy(this.$scope.recurrentCycle, x => x.sequenceInRecurrenceCycle);
            if (this.$scope.recurrentCycle != null && this.$scope.recurrentCycle.length > 0) {
                this.$scope.periodicity = _.find(
                    this.$scope.recurrentCycle,
                    p => p.sequenceInRecurrenceCycle === 0 || p.sequenceInRecurrenceCycle === 1
                );
                this.$scope.currentSequence = this.$scope.periodicity.sequenceInRecurrenceCycle;
            }
            this.getDurationsUnitsAsync();
            if (this.$scope.forMedication) {
                this.$scope.minDurationQuantity = 1;
            }
            this.$scope.carePlanStartDateReadable = DateHelper.dateHumanReadible(this.$scope.carePlanStartDate);
            this.$scope.prescriptionStartDateReadable = () =>
                DateHelper.dateHumanReadible(this.$scope.prescriptionStartDate);
            this.$scope.days = this.daySvc.getDays();
            this.$scope.weekdayOccurences = this.daySvc.getWeekDayOccurences();
            this.$scope.months = _(this.$locale.DATETIME_FORMATS.MONTH).map((m, i) => {
                return {
                    value: i + 1,
                    name: m,
                };
            });
            this.buildGrid();

            this.ensureActiveTab();
            this.$scope.$watch('activeTab', (newValue, oldValue) => this.ensureActiveTab());
        }

        public confirm(): void {
            if (this.$scope.form.$invalid) {
                this.showValidationErrorMessage();
                this.$scope.showErrors = true;
                return;
            }
            this.$scope.$close(this.$scope.recurrentCycle);
        }

        private ensureActiveTab(): void {
            if (!this.$scope.activeTab) {
                this.$scope.activeTab = 0;
            }
        }

        private changeAlternation(): void {
            this.setRecurrentCycleMembers(this.$scope.periodicity);
        }

        private isRoot(): boolean {
            return this.$scope.periodicity && !this.$scope.periodicity.parentPeriodicity;
        }

        private setDurationUnits(): void {
            if (this.$scope.periodicity != null && this.$scope.periodicity.parentPeriodicity != null) {
                this.$scope.limitedDurationUnits = _(this.$scope.possibleDurationUnits).filter(
                    u => u.Id <= this.$scope.periodicity.parentPeriodicity.recurrence.Unit
                );
                this.$scope.extraLimitedDurationUnits = _(this.$scope.possibleDurationUnits).filter(
                    u => u.Id < this.$scope.periodicity.parentPeriodicity.recurrence.Unit
                );
            } else {
                this.$scope.limitedDurationUnits = this.$scope.possibleDurationUnits;
                this.$scope.extraLimitedDurationUnits = this.$scope.possibleDurationUnits;
            }
        }

        private async getDurationsUnitsAsync(): Promise<void> {
            try {
                const durationUnits = await this.masterdataSvc.getDurationUnitsAsync();
                this.$scope.possibleDurationUnits = _(durationUnits).filter(
                    unit => unit.Id !== Shared.Contract.Code.DurationUnit.Hours
                );
                if (this.$scope.periodicity == null) {
                    const periodicity = new PeriodicityFactory().createPeriodicityFromType(
                        Shared.Contract.Code.PeriodicityType.PeriodicityDay
                    );
                    periodicity.recurrence.Unit = this.$scope.possibleDurationUnits[0].Id;
                    this.$scope.periodicity = periodicity;
                    this.$scope.recurrentCycle = [periodicity];
                }
                this.buildGrid();
                this.setDurationUnits();
            } catch (e) {
                this.toaster.error(e);
            }
        }

        private changeRecurrenceCycleView(sequence: number): void {
            this.$scope.currentSequence = sequence;
            const target = _.find(
                this.$scope.recurrentCycle,
                p => p.sequenceInRecurrenceCycle === this.$scope.currentSequence
            );

            if (target) {
                this.$scope.periodicity = target;
                this.$scope.intraDayTimingsGrid.setData(this.$scope.periodicity.intraDayTimings);
            }
        }

        private isFirstInRecurrence(): boolean {
            if (!this.$scope.periodicity) {
                return true;
            }

            return this.$scope.periodicity.sequenceInRecurrenceCycle <= 1;
        }

        private isLastInRecurrence(): boolean {
            const maxSequence = _(this.$scope.recurrentCycle).max(p => p.sequenceInRecurrenceCycle)
                .sequenceInRecurrenceCycle;
            return this.$scope.periodicity.sequenceInRecurrenceCycle === maxSequence;
        }

        private buildGrid(): void {
            let builder = this.gridBuilderSvc
                .createGridBuilder<IntraDayTiming>()
                // No addDateColumn on purpose, because it sets a fixed width
                .addColumn('preferredTime', 'Periodicity.PreferredTime', {
                    cellFilter: 'date: "HH:mm"',
                    sort: { direction: 'asc' },
                });

            if (!this.$scope.limitedConfig) {
                builder = builder
                    .addColumn('preferredTimeWindowBefore', 'Periodicity.PreferredTimeWindowBefore', {
                        cellFilter: 'date: "HH:mm"',
                    })
                    .addColumn('preferredTimeWindowAfter', 'Periodicity.PreferredTimeWindowAfter', {
                        cellFilter: 'date: "HH:mm"',
                    })
                    .addColumn(
                        'reminderElapseTimeAfterOpeningWindow',
                        'Periodicity.ReminderElapseTimeAfterOpeningWindow',
                        { cellFilter: 'date: "HH:mm"' }
                    );
            }

            if (this.$scope.forMedication) {
                builder = builder.addColumn('targetValues[0].quantity', 'Periodicity.Number');
            }

            if (this.$scope.readOnly) {
                builder.addShowDetailButtonColumn(i => this.editIntraDayTimingAsync(i));
            } else {
                builder.addEditButtonWithPromiseFunctionColumn(i => this.editIntraDayTimingAsync(i));
                builder.addConditionalDeleteButtonColumn(p => this.$scope.onlyIntakeMoments);
            }
            builder = builder.setExternalSorting(false);

            this.$scope.intraDayTimingsGrid = builder.build();
            if (this.$scope.periodicity != null) {
                this.$scope.intraDayTimingsGrid.setData(this.$scope.periodicity.intraDayTimings);
            }
        }

        private setRecurrentCycleMembers(firstInSequence: Periodicity): void {
            firstInSequence.isAlternating = firstInSequence.isAlternating && firstInSequence.recurrence.Quantity > 1;

            if (!firstInSequence.isAlternating) {
                firstInSequence.sequenceInRecurrenceCycle = 0;
                this.$scope.recurrentCycle = [firstInSequence];
            } else {
                this.$scope.currentSequence = 1;
                firstInSequence.sequenceInRecurrenceCycle = 1;
                this.$scope.recurrentCycle.length = firstInSequence.recurrence.Quantity;
                this.$scope.recurrentCycle[0] = firstInSequence;

                for (let i = 1; i < this.$scope.recurrentCycle.length; i++) {
                    const periodicity = new PeriodicityFactory().createPeriodicityFromDurationUnit(
                        firstInSequence.recurrence.Unit
                    );
                    firstInSequence.setCommonParts(periodicity, true);
                    periodicity.sequenceInRecurrenceCycle = i + 1;
                    this.$scope.recurrentCycle[i] = periodicity;

                    const member = this.$scope.recurrentCycle[i];

                    member.recurrence = firstInSequence.recurrence;
                    member.duration = firstInSequence.duration;
                }
            }
        }

        private setEvenOddDays(dailyAlternationType: DailyAlternationType): void {
            if (dailyAlternationType === DailyAlternationType.EvenOdd) {
                const day1 = this.$scope.recurrentCycle[0] as PeriodicityDay;
                day1.evenUnevenDays = EvenOddDays.Even;
                const day2 = this.$scope.recurrentCycle[1] as PeriodicityDay;
                day2.evenUnevenDays = EvenOddDays.Odd;
            } else {
                _(this.$scope.recurrentCycle).forEach((p: PeriodicityDay) => (p.evenUnevenDays = null));
            }

            _(this.$scope.recurrentCycle).forEach(
                (p: PeriodicityDay) => (p.dailyAlternationType = dailyAlternationType)
            );
        }

        private async addIntraDayTimingAsync(): Promise<void> {
            const intraDayTiming = this.$scope.therapyActionPartContextSettings
                ? new IntraDayTimingFactory().createWithTherapyActionPartContextSettings(
                      this.$scope.therapyActionPartContextSettings
                  )
                : this.$scope.forMedication
                ? new IntraDayTimingFactory().createIntraDayTimingWithTargetDoses(1)
                : new IntraDayTiming();
            try {
                const i = await this.editIntraDayTimingAsync(intraDayTiming);
                this.$scope.intraDayTimingsGrid.addRow(i);
            } catch (e) {
                this.toaster.error(e);
            }
        }

        private async editIntraDayTimingAsync(intraDayTiming: IntraDayTiming): Promise<IntraDayTiming> {
            return new Promise<IntraDayTiming>(async resolve => {
                const i = await this.showIntraDayTimingModalAsync(intraDayTiming);
                resolve(i);
            });
        }

        private showIntraDayTimingModalAsync(intraDayTiming: IntraDayTiming): Promise<IntraDayTiming> {
            return new Promise<IntraDayTiming>(resolve => {
                intraDayTiming = intraDayTiming.copy();
                this.modalBuilderFactory
                    .createModalBuilder<IntraDayTiming>()
                    .setTemplateUrl('views/periodicity/intraDayTiming.html')
                    .setController('intraDayTimingCtrl')
                    .setScope({
                        readOnly: this.$scope.readOnly,
                        intraDayTiming: intraDayTiming.copy(),
                        unit: this.$scope.medication ? this.$scope.medication.UnitName : null,
                        forMedication: this.$scope.forMedication,
                        limitedConfig: this.$scope.limitedConfig,
                        onlyIntakeMoments: this.$scope.onlyIntakeMoments,
                    })
                    .setSize(Framework.Helper.ModalSize.large)
                    .setResultCallBack(r => resolve(r))
                    .build();
            });
        }

        private newPeriodicity(durationUnit: Shared.Contract.Code.DurationUnit): void {
            const oldPeriodicity = this.$scope.periodicity;
            this.$scope.periodicity = new PeriodicityFactory().createPeriodicityFromDurationUnit(durationUnit);
            const type = this.$scope.periodicity.type;
            oldPeriodicity.setCommonParts(this.$scope.periodicity);
            this.$scope.periodicity.type = type;
            this.$scope.periodicity.childPeriodicity = null;
            this.$scope.periodicity.sequenceInRecurrenceCycle = 1;

            this.setRecurrentCycleMembers(this.$scope.periodicity);
            this.$scope.intraDayTimingsGrid.setData(this.$scope.periodicity.intraDayTimings);
        }

        private changeDuration(): void {
            const duration = this.$scope.recurrentCycle[0].duration;
            for (let i = 1; i < this.$scope.recurrentCycle.length; i++) {
                const durationToUpdate = this.$scope.recurrentCycle[i].duration;
                if (durationToUpdate == null) {
                    this.$scope.recurrentCycle[i].duration = {
                        Quantity: duration.Quantity,
                        Unit: duration.Unit,
                    } as Shared.Contract.IDuration;
                } else {
                    durationToUpdate.Quantity = duration.Quantity;
                    durationToUpdate.Unit = duration.Unit;
                }
            }
        }

        private clearDuration(): void {
            this.$scope.recurrentCycle[0].duration = {
                Quantity: null,
                Unit: null,
            };
            this.changeDuration();
        }
    }

    remeCareSharedModule.controller('PeriodicityEditorCtrl', PeriodicityEditorController);
}
