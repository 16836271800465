var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Model;
            (function (Model) {
                var InformedConsentTranslation = /** @class */ (function () {
                    function InformedConsentTranslation(culture, informedConsentTranslation, informedConsentQuestions) {
                        var _this = this;
                        this.culture = culture;
                        if (informedConsentTranslation != null) {
                            this.title = informedConsentTranslation.Title;
                            this.content = informedConsentTranslation.Content;
                            this.culture = informedConsentTranslation.Culture;
                        }
                        else {
                            this.culture = culture;
                        }
                        this.questions = [];
                        if (informedConsentQuestions) {
                            informedConsentQuestions.forEach(function (question) {
                                var translation = _.find(question.Translations, function (t) { return t.Culture == _this.culture; });
                                if (translation) {
                                    _this.questions.push(new Model.InformedConsentQuestionTranslation(question.Sequence, translation.Name));
                                }
                            });
                            this.questions = _.sortBy(this.questions, function (q) { return q.sequence; });
                        }
                    }
                    return InformedConsentTranslation;
                }());
                Model.InformedConsentTranslation = InformedConsentTranslation;
            })(Model = Framework.Model || (Framework.Model = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
