var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Factory;
            (function (Factory) {
                var IntraDayTimingFactory = /** @class */ (function () {
                    function IntraDayTimingFactory() {
                    }
                    IntraDayTimingFactory.prototype.createIntraDayTiming = function (serverObject) {
                        var result;
                        switch (serverObject.Type) {
                            case Shared.Contract.Code.IntraDayTimingType.IntraDayTimingAdministration:
                                result = new Framework.Model.IntraDayTimingAdministration(serverObject);
                                break;
                            default:
                                result = new Framework.Model.IntraDayTiming(serverObject);
                                break;
                        }
                        if (serverObject.TargetValues != null) {
                            var targetValueFactory_1 = new Factory.TargetValueFactory();
                            result.targetValues = _(serverObject.TargetValues).map(function (t) { return targetValueFactory_1.createTargetValue(t); });
                        }
                        return result;
                    };
                    IntraDayTimingFactory.prototype.createIntraDayTimingWithTargetDoses = function (numberOfTargetDoses) {
                        var result = new Framework.Model.IntraDayTimingAdministration();
                        for (var i = 0; i < numberOfTargetDoses; i++) {
                            result.targetValues.push(new Framework.Model.TargetDose());
                        }
                        return result;
                    };
                    IntraDayTimingFactory.prototype.createWithTherapyActionPartContextSettings = function (parts) {
                        var _this = this;
                        var result;
                        var administrationPartSetting = _(parts).find(function (tapcs) { return tapcs.TherapyActionPart.ActionTypeCode === Shared.Contract.Code.ActionType.Administration; });
                        if (administrationPartSetting != null) {
                            result = new Framework.Model.IntraDayTimingAdministration();
                            var targetDose = new Framework.Model.TargetDose();
                            if (administrationPartSetting) {
                                targetDose.useContextSetting(administrationPartSetting);
                            }
                            result.targetValues = [targetDose];
                        }
                        else {
                            result = new Framework.Model.IntraDayTiming();
                            result.targetValues = [];
                        }
                        var quantitativePartSettings = _(parts)
                            .filter(function (p) { return p.TherapyActionPart.ActionTypeCode === Shared.Contract.Code.ActionType.QuantitativeObservation; });
                        _(quantitativePartSettings).each(function (q) {
                            if (q.IntendedValue != null) {
                                if (q.IntendedValue.Id !== Shared.Contract.Code.IntendedValue.None) {
                                    result.targetValues.push(_this.createTargetPhysicalQuantity(q));
                                }
                            }
                        });
                        return result;
                    };
                    IntraDayTimingFactory.prototype.createTargetPhysicalQuantity = function (therapyActionPartContextSetting) {
                        var targetValue;
                        if (therapyActionPartContextSetting.IntendedValue.Id === Shared.Contract.Code.IntendedValue.Single) {
                            targetValue = new Framework.Model.TargetPhysicalQuantity();
                        }
                        else {
                            targetValue = new Framework.Model.TargetPhysicalQuantityRange();
                        }
                        targetValue.useContextSetting(therapyActionPartContextSetting);
                        return targetValue;
                    };
                    return IntraDayTimingFactory;
                }());
                Factory.IntraDayTimingFactory = IntraDayTimingFactory;
            })(Factory = Framework.Factory || (Framework.Factory = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
