var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Model;
            (function (Model) {
                var GuidanceStepTranslation = /** @class */ (function () {
                    function GuidanceStepTranslation(serverObject) {
                        if (serverObject != null) {
                            this.Content = serverObject.Content;
                            this.OriginalContent = serverObject.Content;
                            this.Culture = serverObject.Culture;
                            this.guidanceStepType = serverObject.GuidanceStepTypeCode;
                            this.GuidanceStepTypeCode = this.guidanceStepType;
                            this.Id = serverObject.Id;
                            this.Name = serverObject.Name;
                            this.ParentId = serverObject.ParentId;
                        }
                        else {
                            this.Content = '';
                            this.OriginalContent = '';
                            this.guidanceStepType = Shared.Contract.Code.GuidanceStepType.Text;
                            this.GuidanceStepTypeCode = Shared.Contract.Code.GuidanceStepType.Text;
                            this.Culture = '';
                        }
                    }
                    Object.defineProperty(GuidanceStepTranslation.prototype, "GuidanceStepType", {
                        get: function () {
                            return this.guidanceStepType;
                        },
                        set: function (value) {
                            if (value !== this.GuidanceStepTypeCode) {
                                this.Content = '';
                            }
                            else {
                                this.Content = this.OriginalContent;
                            }
                            this.guidanceStepType = value;
                            this.GuidanceStepTypeCode = value;
                        },
                        enumerable: true,
                        configurable: true
                    });
                    Object.defineProperty(GuidanceStepTranslation.prototype, "TextContent", {
                        get: function () {
                            return this.Content;
                        },
                        set: function (value) {
                            this.Content = value;
                        },
                        enumerable: true,
                        configurable: true
                    });
                    Object.defineProperty(GuidanceStepTranslation.prototype, "VideoContent", {
                        get: function () {
                            return this.Content;
                        },
                        set: function (value) {
                            this.Content = value;
                        },
                        enumerable: true,
                        configurable: true
                    });
                    Object.defineProperty(GuidanceStepTranslation.prototype, "FileContent", {
                        get: function () {
                            if (this.files == null) {
                                try {
                                    var f = JSON.parse(this.Content);
                                    this.files = [f];
                                }
                                catch (e) {
                                    this.files = [];
                                }
                            }
                            this.setFileContent();
                            return this.files;
                        },
                        set: function (value) {
                            this.files = value;
                            this.setFileContent();
                        },
                        enumerable: true,
                        configurable: true
                    });
                    GuidanceStepTranslation.prototype.setFileContent = function () {
                        if (this.files.length > 1) {
                            this.files[0] = this.files[this.files.length - 1];
                            this.files.length = 1;
                        }
                        this.Content = this.files.length > 0 ? JSON.stringify(this.files[0]) : '';
                    };
                    return GuidanceStepTranslation;
                }());
                Model.GuidanceStepTranslation = GuidanceStepTranslation;
                var GuidanceStep = /** @class */ (function () {
                    function GuidanceStep(serverObject) {
                        var _this = this;
                        this.Translations = {};
                        if (serverObject != null) {
                            this.GuidanceStepId = serverObject.GuidanceStepId;
                            this.Sequence = serverObject.Sequence;
                            _(serverObject.Translations).each(function (t) {
                                _this.Translations[t.Culture] = new GuidanceStepTranslation(t);
                                _this.Translations[t.Culture].Culture = t.Culture;
                            });
                            this.Width = serverObject.Width;
                        }
                    }
                    GuidanceStep.prototype.toServerWrite = function () {
                        var result = {
                            Sequence: this.Sequence,
                            GuidanceStepId: this.GuidanceStepId,
                            Translations: this.getTranslations(),
                            Width: this.Width,
                        };
                        return result;
                    };
                    GuidanceStep.prototype.copy = function () {
                        var result = new GuidanceStep();
                        result.setCommonParts(this);
                        return result;
                    };
                    GuidanceStep.prototype.setCommonParts = function (part) {
                        this.GuidanceStepId = part.GuidanceStepId;
                        this.Sequence = part.Sequence;
                        this.Translations = angular.copy(part.Translations);
                        this.Width = part.Width;
                        return part;
                    };
                    GuidanceStep.prototype.anonimise = function () {
                        this.GuidanceStepId = null;
                        for (var key in this.Translations) {
                            if (this.Translations.hasOwnProperty(key)) {
                                this.Translations[key].Id = null;
                            }
                        }
                    };
                    GuidanceStep.prototype.getTranslations = function () {
                        var translations = [];
                        for (var key in this.Translations) {
                            if (this.Translations.hasOwnProperty(key)) {
                                var trans = this.Translations[key];
                                translations.push({
                                    Type: trans.GuidanceStepType,
                                    ParentId: trans.ParentId,
                                    Id: trans.Id,
                                    Culture: key,
                                    Name: trans.Name,
                                    Content: trans.Content,
                                });
                            }
                        }
                        return translations;
                    };
                    return GuidanceStep;
                }());
                Model.GuidanceStep = GuidanceStep;
            })(Model = Framework.Model || (Framework.Model = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
