module RemeCare.Shared.Framework.Grid {

    export enum PagingType {
        FullyPaged = 1,
        PrevNext = 2,
    }
    
    class PagingController<TGrid> implements ng.IComponentController {

        // bindings
        public grid: Grid<TGrid>;

        public isPrevNext: boolean;

        public $onInit(): void {
            this.isPrevNext = this.grid.pagingType === PagingType.PrevNext;
        }

        public goToPreviousPage(): void {
            this.grid.pagingOptions.currentPage--;
            this.grid.currentPageChanged();
        }

        public goToNextPage(): void {
            this.grid.pagingOptions.currentPage++;
            this.grid.currentPageChanged();
        }
    }

    remeCareSharedModule.component('rcPaging',
        {
            controller: PagingController,
            bindings: {
                grid: '=rcGrid'
            },
            templateUrl: 'views/grid/paging.html'
        });
} 