namespace RemeCare.Shared.Framework.Directive {
    interface ITimeScope extends ng.IScope {
        model: string;
        required: boolean;
        formCtrl: ng.IFormController;
        placeholder: string;
        dateModel: {
            model: string;
        };
        noStaticData: boolean;
    }

    class TimeDirective implements ng.IDirective {

        public restrict = 'E';

        public require = '^form';

        public scope: { [boundProperty: string]: string } = {
            model: '=ngModel',
            required: '=ngRequired',
            readOnly: '=ngReadonly',
            disabled: '=ngDisabled',
            label: '@',
            noStaticData: '=',
            guidanceIconLocation: '<',
            guidanceIconFunc: '<',
            uniqueId: '@',
        };

        public transclude = true;

        public templateUrl = 'views/formElements/time.html';
        constructor(private $locale: ng.ILocaleService) {}

        public link: ng.IDirectiveLinkFn = (scope: ITimeScope, element, attrs, formCtrl: ng.IFormController) => {
            scope.formCtrl = formCtrl;
            scope.placeholder = this.$locale.DATETIME_FORMATS.shortTime;

            scope.dateModel = {
                model: scope.model,
            };

            scope.$watch(
                (s: ITimeScope) => s.model,
                (newValue, oldValue) => {
                    if (newValue !== oldValue && newValue !== scope.dateModel.model) {
                        scope.dateModel.model = newValue;
                    }
                }
            );

            scope.$watch(
                (s: ITimeScope) => s.dateModel.model,
                (newValue, oldValue) => {
                    if (newValue !== oldValue && newValue !== scope.model) {
                        scope.model = newValue;
                    }
                }
            );
        };
    }

    remeCareSharedModule.directive('rcTime', $locale => new TimeDirective($locale));
}
