var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Contract;
        (function (Contract) {
            var Code;
            (function (Code) {
                var IntraDayTimingType;
                (function (IntraDayTimingType) {
                    IntraDayTimingType[IntraDayTimingType["IntraDayTiming"] = 1] = "IntraDayTiming";
                    IntraDayTimingType[IntraDayTimingType["IntraDayTimingAdministration"] = 2] = "IntraDayTimingAdministration";
                })(IntraDayTimingType = Code.IntraDayTimingType || (Code.IntraDayTimingType = {}));
            })(Code = Contract.Code || (Contract.Code = {}));
        })(Contract = Shared.Contract || (Shared.Contract = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
