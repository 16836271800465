module RemeCare.Shared.Filter {
    
    function timeFilter(dateFilter) : (time: any) => string {
        return (time: any) => {
            var date = new Date();
            if (time == null) return '';
            else if (time instanceof String) {
                var split = time.split(':');
                if (split.length < 2) return '';

                date.setMinutes(parseInt(split[0]));
                date.setHours(parseInt(split[1]));
            } else if (time.hasOwnProperty('hour') && time.hasOwnProperty('minute')) {
                if (time.minute == null || time.minute === '' || time.hour == null || time.hour === '') return '';
                date.setMinutes(time.minute);
                date.setHours(time.hour);
            } else return '';
            return dateFilter(date, 'shortTime');
        }
    }

    angular.module('RemeCare.Shared').filter('time', timeFilter);
}