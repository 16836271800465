var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Directive;
            (function (Directive) {
                var MinuteDirective = /** @class */ (function () {
                    function MinuteDirective() {
                        this.require = 'ngModel';
                        this.restrict = 'A';
                        this.link = function (scope, element, attrs, ngModelController) {
                            ngModelController.$parsers.push(function (data) {
                                return parseInt(data);
                            });
                            ngModelController.$formatters.push(function (data) {
                                if (data !== 0 && !data)
                                    return '';
                                var s = data + '';
                                while (s.length < 2)
                                    s = '0' + s;
                                return s;
                            });
                        };
                    }
                    return MinuteDirective;
                }());
                var HourDirective = /** @class */ (function () {
                    function HourDirective() {
                        this.require = 'ngModel';
                        this.restrict = 'A';
                        this.link = function (scope, element, attrs, ngModelController) {
                            ngModelController.$parsers.push(function (data) {
                                return parseInt(data);
                            });
                            ngModelController.$formatters.push(function (data) {
                                if (data !== 0 && !data)
                                    return '';
                                var s = data + '';
                                while (s.length < 2)
                                    s = '0' + s;
                                return s;
                            });
                        };
                    }
                    return HourDirective;
                }());
                Shared.remeCareSharedModule
                    .directive('minute', function () { return new MinuteDirective(); })
                    .directive('hour', function () { return new HourDirective(); });
            })(Directive = Framework.Directive || (Framework.Directive = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
