var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Base64Helper = /** @class */ (function () {
            function Base64Helper() {
            }
            // Base64-urlencodes the input buffer
            Base64Helper.base64urlencode = function (str) {
                // Convert the base64 encoded to base64url encoded
                //   (replace + with -, replace / with _, trim trailing =)
                return btoa(str)
                    .replace(/\+/g, '-')
                    .replace(/\//g, '_')
                    .replace(/=+$/, '');
            };
            Base64Helper.base64urldecode = function (str) {
                // Replace non-url compatible chars with base64 standard chars
                str = str.replace(/-/g, '+').replace(/_/g, '/');
                // Pad out with standard base64 required padding characters
                var pad = str.length % 4;
                if (pad) {
                    if (pad === 1) {
                        throw new Error('InvalidLengthError: Input base64url string is the wrong length to determine padding');
                    }
                    str += new Array(5 - pad).join('=');
                }
                return atob(str);
            };
            return Base64Helper;
        }());
        Shared.Base64Helper = Base64Helper;
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
