var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Component;
            (function (Component) {
                Shared.remeCareSharedModule.component('rcNavItem', {
                    bindings: {
                        label: '@',
                        state: '@',
                    },
                    require: {
                        headerCtrl: '^rcHeader'
                    },
                    templateUrl: 'views/navigationItem.html'
                });
            })(Component = Framework.Component || (Framework.Component = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
