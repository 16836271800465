module RemeCare.Shared.Framework.InformedConsent {

    class DisplayPatientInformedConsentController implements ng.IComponentController {
        public informedConsent: Framework.Model.PatientInformedConsent;
        public readOnly :boolean;

        public $onInit(): void {
        }
    }

    remeCareSharedModule.component('rcDisplayPatientInformedConsent',
        {
            controller: DisplayPatientInformedConsentController,
            templateUrl: 'framework/informedConsent/displayPatientInformedConsent/displayPatientInformedConsent.html',
            bindings: {
                informedConsent: '<',
                readOnly:'<'
            },
        });
}