var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Model;
            (function (Model) {
                var MonitoringPartSourceType = Shared.Contract.Code.MonitoringPartSourceType;
                var MonitoringPartDataSourceType = Shared.Contract.Code.MonitoringPartDataSourceType;
                var MonitoringPartSource = /** @class */ (function () {
                    function MonitoringPartSource(serverObject) {
                        if (serverObject != null) {
                            this.id = serverObject.Id;
                        }
                    }
                    MonitoringPartSource.prototype.toServerWrite = function () {
                        return {
                            id: this.id,
                            type: this.type
                        };
                    };
                    MonitoringPartSource.prototype.hasCharacteristicAndUnit = function (characteristic, unit) {
                        return false;
                    };
                    ;
                    MonitoringPartSource.prototype.hasObservableEntityAndCodeSet = function (observableEntity, codeSet) {
                        return false;
                    };
                    MonitoringPartSource.prototype.hasObjective = function (objective) {
                        return false;
                    };
                    MonitoringPartSource.prototype.hasRuleThreshold = function (ruleThreshold) {
                        return false;
                    };
                    MonitoringPartSource.prototype.isEqual = function (monitoringPartSource) {
                        return monitoringPartSource && this.type === monitoringPartSource.type;
                    };
                    return MonitoringPartSource;
                }());
                Model.MonitoringPartSource = MonitoringPartSource;
                var MonitoringPartSourceAction = /** @class */ (function (_super) {
                    __extends(MonitoringPartSourceAction, _super);
                    function MonitoringPartSourceAction(serverObject) {
                        var _this = _super.call(this, serverObject) || this;
                        _this.type = MonitoringPartSourceType.MonitoringPartSourceAction;
                        if (serverObject != null) {
                            _this.structuralTherapyActionId = serverObject.StructuralTherapyActionId;
                        }
                        return _this;
                    }
                    MonitoringPartSourceAction.prototype.toServerWrite = function () {
                        var result = _super.prototype.toServerWrite.call(this);
                        result.structuralTherapyActionId = this.structuralTherapyActionId;
                        return result;
                    };
                    MonitoringPartSourceAction.prototype.isEqual = function (monitoringPartSource) {
                        var monitoringPartSourceAction = monitoringPartSource;
                        return _super.prototype.isEqual.call(this, monitoringPartSource)
                            && this.structuralTherapyActionId === monitoringPartSourceAction.structuralTherapyActionId;
                    };
                    return MonitoringPartSourceAction;
                }(MonitoringPartSource));
                Model.MonitoringPartSourceAction = MonitoringPartSourceAction;
                var MonitoringPartSourceFilter = /** @class */ (function (_super) {
                    __extends(MonitoringPartSourceFilter, _super);
                    function MonitoringPartSourceFilter(serverObject) {
                        var _this = _super.call(this, serverObject) || this;
                        _this.type = MonitoringPartSourceType.MonitoringPartSourceFilter;
                        if (serverObject != null) {
                            _this.ingredientIds = serverObject.IngredientIds;
                        }
                        return _this;
                    }
                    MonitoringPartSourceFilter.prototype.toServerWrite = function () {
                        var result = _super.prototype.toServerWrite.call(this);
                        result.ingredientIds = this.ingredientIds;
                        return result;
                    };
                    MonitoringPartSourceFilter.prototype.isEqual = function (monitoringPartSource) {
                        var _this = this;
                        var monitoringPartSourceFilter = monitoringPartSource;
                        return _super.prototype.isEqual.call(this, monitoringPartSource)
                            && _.all(this.ingredientIds, function (ingredientId) {
                                return _.contains(monitoringPartSourceFilter.ingredientIds, ingredientId);
                            })
                            && _.all(monitoringPartSourceFilter.ingredientIds, function (ingredientId) {
                                return _.contains(_this.ingredientIds, ingredientId);
                            });
                    };
                    return MonitoringPartSourceFilter;
                }(MonitoringPartSource));
                Model.MonitoringPartSourceFilter = MonitoringPartSourceFilter;
                var MonitoringPartSourceParameter = /** @class */ (function (_super) {
                    __extends(MonitoringPartSourceParameter, _super);
                    function MonitoringPartSourceParameter(serverObject) {
                        var _this = _super.call(this, serverObject) || this;
                        if (serverObject != null) {
                            _this.sequence = serverObject.Sequence;
                            _this.sourceType = serverObject.SourceType;
                        }
                        return _this;
                    }
                    MonitoringPartSourceParameter.prototype.toServerWrite = function () {
                        var result = _super.prototype.toServerWrite.call(this);
                        result.sequence = this.sequence;
                        result.sourceType = this.sourceType;
                        result.sourceParameterRepresentation = this.sourceParameterRepresentation != null
                            ? this.sourceParameterRepresentation.toServerWrite()
                            : null;
                        return result;
                    };
                    MonitoringPartSourceParameter.prototype.isEqual = function (monitoringPartSource) {
                        var monitoringPartSourceParameter = monitoringPartSource;
                        return _super.prototype.isEqual.call(this, monitoringPartSource)
                            && this.sourceType === monitoringPartSourceParameter.sourceType;
                    };
                    return MonitoringPartSourceParameter;
                }(MonitoringPartSource));
                Model.MonitoringPartSourceParameter = MonitoringPartSourceParameter;
                var QualitativeMeasuringPointParameter = /** @class */ (function (_super) {
                    __extends(QualitativeMeasuringPointParameter, _super);
                    function QualitativeMeasuringPointParameter(serverObject, observableEntities, codeSets) {
                        var _this = _super.call(this, serverObject) || this;
                        _this.type = MonitoringPartSourceType.QualitativeMeasuringPointParameter;
                        if (serverObject != null) {
                            _this.observableEntity = _.find(observableEntities, function (oe) { return oe.Id === serverObject.ObservableEntityId; });
                            _this.codeSet = _.find(codeSets, function (cs) { return cs.Id === serverObject.CodeSetId; });
                            _this.useTherapyActionPartTextRegistration = serverObject.UseTherapyActionPartTextRegistration;
                        }
                        return _this;
                    }
                    QualitativeMeasuringPointParameter.prototype.getName = function () {
                        return this.observableEntity.Text;
                    };
                    QualitativeMeasuringPointParameter.prototype.toServerWrite = function () {
                        var result = _super.prototype.toServerWrite.call(this);
                        result.observableEntity = this.observableEntity.Id;
                        result.codeSet = this.codeSet.Id;
                        result.useTherapyActionPartTextRegistration = this.useTherapyActionPartTextRegistration;
                        return result;
                    };
                    QualitativeMeasuringPointParameter.prototype.hasObservableEntityAndCodeSet = function (observableEntity, codeSet) {
                        var observableEntityEqual = (this.observableEntity == null && observableEntity == null
                            || this.observableEntity != null && observableEntity != null && this.observableEntity.Id === observableEntity.Id);
                        var codeSetEqual = (this.codeSet == null && codeSet == null
                            || this.codeSet != null && codeSet != null && this.codeSet.Id === codeSet.Id);
                        return observableEntityEqual && codeSetEqual;
                    };
                    QualitativeMeasuringPointParameter.prototype.isEqual = function (monitoringPartSource) {
                        var qualitativeMeasuringPointParameter = monitoringPartSource;
                        return _super.prototype.isEqual.call(this, monitoringPartSource)
                            && _.isEqual(this.observableEntity, qualitativeMeasuringPointParameter.observableEntity)
                            && _.isEqual(this.codeSet, qualitativeMeasuringPointParameter.codeSet);
                    };
                    return QualitativeMeasuringPointParameter;
                }(MonitoringPartSourceParameter));
                Model.QualitativeMeasuringPointParameter = QualitativeMeasuringPointParameter;
                var QuantitativeMeasuringPointParameter = /** @class */ (function (_super) {
                    __extends(QuantitativeMeasuringPointParameter, _super);
                    function QuantitativeMeasuringPointParameter(serverObject, characteristics, units) {
                        var _this = _super.call(this, serverObject) || this;
                        _this.type = MonitoringPartSourceType.QuantitativeMeasuringPointParameter;
                        if (serverObject != null) {
                            _this.characteristic = _.find(characteristics, function (c) { return c.Id === serverObject.CharacteristicId; });
                            _this.unit = _.find(units, function (u) { return u.Id === serverObject.UnitId; });
                            _this.useTherapyActionPartTextRegistration = serverObject.UseTherapyActionPartTextRegistration;
                        }
                        return _this;
                    }
                    QuantitativeMeasuringPointParameter.prototype.getName = function () {
                        return this.unit ? this.characteristic.Text + " (" + this.unit.Text + ")" : this.characteristic.Text;
                    };
                    QuantitativeMeasuringPointParameter.prototype.toServerWrite = function () {
                        var result = _super.prototype.toServerWrite.call(this);
                        result.characteristic = this.characteristic.Id;
                        result.unit = this.unit ? this.unit.Id : null;
                        result.useTherapyActionPartTextRegistration = this.useTherapyActionPartTextRegistration;
                        return result;
                    };
                    QuantitativeMeasuringPointParameter.prototype.hasCharacteristicAndUnit = function (characteristic, unit) {
                        var characteristicEqual = (this.characteristic == null && characteristic == null
                            || this.characteristic != null && characteristic != null && this.characteristic.Id === characteristic.Id);
                        var unitEqual = (this.unit == null && unit == null
                            || this.unit != null && unit != null && this.unit.Id === unit.Id);
                        return characteristicEqual && unitEqual;
                    };
                    QuantitativeMeasuringPointParameter.prototype.isEqual = function (monitoringPartSource) {
                        var quantitativeMeasuringPointParameter = monitoringPartSource;
                        return _super.prototype.isEqual.call(this, monitoringPartSource)
                            && _.isEqual(this.characteristic, quantitativeMeasuringPointParameter.characteristic)
                            && _.isEqual(this.unit, quantitativeMeasuringPointParameter.unit);
                    };
                    return QuantitativeMeasuringPointParameter;
                }(MonitoringPartSourceParameter));
                Model.QuantitativeMeasuringPointParameter = QuantitativeMeasuringPointParameter;
                var QualitativeReferenceParameterAnamnesis = /** @class */ (function (_super) {
                    __extends(QualitativeReferenceParameterAnamnesis, _super);
                    function QualitativeReferenceParameterAnamnesis(serverObject, observableEntities, codeSets) {
                        var _this = _super.call(this, serverObject) || this;
                        _this.type = MonitoringPartSourceType.QualitativeReferenceParameterAnamnesis;
                        if (serverObject != null) {
                            _this.observableEntity = _.find(observableEntities, function (oe) { return oe.Id === serverObject.ObservableEntityId; });
                            _this.codeSet = _.find(codeSets, function (cs) { return cs.Id === serverObject.CodeSetId; });
                        }
                        return _this;
                    }
                    QualitativeReferenceParameterAnamnesis.prototype.getName = function () {
                        return this.observableEntity.Text;
                    };
                    QualitativeReferenceParameterAnamnesis.prototype.toServerWrite = function () {
                        var result = _super.prototype.toServerWrite.call(this);
                        result.observableEntity = this.observableEntity.Id;
                        result.codeSet = this.codeSet.Id;
                        return result;
                    };
                    QualitativeReferenceParameterAnamnesis.prototype.hasObservableEntityAndCodeSet = function (observableEntity, codeSet) {
                        var observableEntityEqual = (this.observableEntity == null && observableEntity == null
                            || this.observableEntity != null && observableEntity != null && this.observableEntity.Id === observableEntity.Id);
                        var codeSetEqual = (this.codeSet == null && codeSet == null
                            || this.codeSet != null && codeSet != null && this.codeSet.Id === codeSet.Id);
                        return observableEntityEqual && codeSetEqual;
                    };
                    QualitativeReferenceParameterAnamnesis.prototype.isEqual = function (monitoringPartSource) {
                        var qualitativeReferenceParameterAnamnesis = monitoringPartSource;
                        return _super.prototype.isEqual.call(this, monitoringPartSource)
                            && _.isEqual(this.observableEntity, qualitativeReferenceParameterAnamnesis.observableEntity)
                            && _.isEqual(this.codeSet, qualitativeReferenceParameterAnamnesis.codeSet);
                    };
                    return QualitativeReferenceParameterAnamnesis;
                }(MonitoringPartSourceParameter));
                Model.QualitativeReferenceParameterAnamnesis = QualitativeReferenceParameterAnamnesis;
                var QuantitativeReferenceParameterAnamnesis = /** @class */ (function (_super) {
                    __extends(QuantitativeReferenceParameterAnamnesis, _super);
                    function QuantitativeReferenceParameterAnamnesis(serverObject, characteristics, units) {
                        var _this = _super.call(this, serverObject) || this;
                        _this.type = MonitoringPartSourceType.QuantitativeReferenceParameterAnamnesis;
                        if (serverObject != null) {
                            _this.characteristic = _.find(characteristics, function (c) { return c.Id === serverObject.CharacteristicId; });
                            _this.unit = _.find(units, function (u) { return u.Id === serverObject.UnitId; });
                        }
                        return _this;
                    }
                    QuantitativeReferenceParameterAnamnesis.prototype.getName = function () {
                        return this.characteristic.Text;
                    };
                    QuantitativeReferenceParameterAnamnesis.prototype.toServerWrite = function () {
                        var result = _super.prototype.toServerWrite.call(this);
                        result.characteristic = this.characteristic.Id;
                        result.unit = this.unit ? this.unit.Id : null;
                        return result;
                    };
                    QuantitativeReferenceParameterAnamnesis.prototype.hasCharacteristicAndUnit = function (characteristic, unit) {
                        var characteristicEqual = (this.characteristic == null && characteristic == null
                            || this.characteristic != null && characteristic != null && this.characteristic.Id === characteristic.Id);
                        var unitEqual = (this.unit == null && unit == null
                            || this.unit != null && unit != null && this.unit.Id === unit.Id);
                        return characteristicEqual && unitEqual;
                    };
                    ;
                    QuantitativeReferenceParameterAnamnesis.prototype.isEqual = function (monitoringPartSource) {
                        var quantitativeReferenceParameterAnamnesis = monitoringPartSource;
                        return _super.prototype.isEqual.call(this, monitoringPartSource)
                            && _.isEqual(this.characteristic, quantitativeReferenceParameterAnamnesis.characteristic)
                            && _.isEqual(this.unit, quantitativeReferenceParameterAnamnesis.unit);
                    };
                    return QuantitativeReferenceParameterAnamnesis;
                }(MonitoringPartSourceParameter));
                Model.QuantitativeReferenceParameterAnamnesis = QuantitativeReferenceParameterAnamnesis;
                var ReferenceParameterThreshold = /** @class */ (function (_super) {
                    __extends(ReferenceParameterThreshold, _super);
                    function ReferenceParameterThreshold(serverObject, ruleThresholds) {
                        var _this = _super.call(this, serverObject) || this;
                        _this.type = MonitoringPartSourceType.ReferenceParameterThreshold;
                        if (serverObject != null) {
                            _this.ruleThreshold = _.find(ruleThresholds, function (rt) { return rt.Id === serverObject.RuleThresholdId; });
                        }
                        return _this;
                    }
                    ReferenceParameterThreshold.prototype.getName = function () {
                        return this.ruleThreshold.Text;
                    };
                    ReferenceParameterThreshold.prototype.toServerWrite = function () {
                        var result = _super.prototype.toServerWrite.call(this);
                        result.ruleThreshold = this.ruleThreshold.Id;
                        return result;
                    };
                    ReferenceParameterThreshold.prototype.hasRuleThreshold = function (ruleThreshold) {
                        return (this.ruleThreshold == null && ruleThreshold == null
                            || this.ruleThreshold != null && ruleThreshold != null && this.ruleThreshold.Id === ruleThreshold.Id);
                    };
                    ReferenceParameterThreshold.prototype.isEqual = function (monitoringPartSource) {
                        var referenceParameterThreshold = monitoringPartSource;
                        return _super.prototype.isEqual.call(this, monitoringPartSource) &&
                            _.isEqual(this.ruleThreshold, referenceParameterThreshold.ruleThreshold);
                    };
                    return ReferenceParameterThreshold;
                }(MonitoringPartSourceParameter));
                Model.ReferenceParameterThreshold = ReferenceParameterThreshold;
                var ReferenceParameterObjective = /** @class */ (function (_super) {
                    __extends(ReferenceParameterObjective, _super);
                    function ReferenceParameterObjective(serverObject, objectives) {
                        var _this = _super.call(this, serverObject) || this;
                        _this.type = MonitoringPartSourceType.ReferenceParameterObjective;
                        if (serverObject != null) {
                            _this.objective = _.find(objectives, function (o) { return o.Id === serverObject.ObjectiveId; });
                        }
                        return _this;
                    }
                    ReferenceParameterObjective.prototype.getName = function () {
                        return this.objective.Text;
                    };
                    ReferenceParameterObjective.prototype.toServerWrite = function () {
                        var result = _super.prototype.toServerWrite.call(this);
                        result.objective = this.objective.Id;
                        return result;
                    };
                    ReferenceParameterObjective.prototype.hasObjective = function (objective) {
                        return (this.objective == null && objective == null
                            || this.objective != null && objective != null && this.objective.Id === objective.Id);
                    };
                    ReferenceParameterObjective.prototype.isEqual = function (monitoringPartSource) {
                        var referenceParameterObjective = monitoringPartSource;
                        return _super.prototype.isEqual.call(this, monitoringPartSource) &&
                            _.isEqual(this.objective, referenceParameterObjective.objective);
                    };
                    return ReferenceParameterObjective;
                }(MonitoringPartSourceParameter));
                Model.ReferenceParameterObjective = ReferenceParameterObjective;
                var ExternalDataSourceParameter = /** @class */ (function (_super) {
                    __extends(ExternalDataSourceParameter, _super);
                    function ExternalDataSourceParameter(serverObject) {
                        var _this = _super.call(this, serverObject) || this;
                        _this.type = MonitoringPartSourceType.ExternalDataSourceParameter;
                        _this.sourceType = MonitoringPartDataSourceType.ExternalSourceData;
                        if (serverObject != null) {
                            _this.externalCharacteristicReference = serverObject.ExternalCharacteristicReference;
                            _this.externalContextReference = serverObject.ExternalContextReference;
                            _this.labelCharacteristic = serverObject.LabelCharacteristic;
                            _this.unit = serverObject.Unit;
                        }
                        return _this;
                    }
                    ExternalDataSourceParameter.prototype.getName = function () {
                        return this.externalCharacteristicReference;
                    };
                    ExternalDataSourceParameter.prototype.toServerWrite = function () {
                        var result = _super.prototype.toServerWrite.call(this);
                        result.externalCharacteristicReference = this.externalCharacteristicReference;
                        result.externalContextReference = this.externalContextReference;
                        result.labelCharacteristic = this.labelCharacteristic;
                        result.unitString = this.unit;
                        return result;
                    };
                    ExternalDataSourceParameter.prototype.isEqual = function (monitoringPartSource) {
                        var externalDataSourceParameter = monitoringPartSource;
                        return _super.prototype.isEqual.call(this, monitoringPartSource)
                            && this.externalCharacteristicReference === externalDataSourceParameter.externalCharacteristicReference
                            && this.externalContextReference === externalDataSourceParameter.externalContextReference;
                    };
                    return ExternalDataSourceParameter;
                }(MonitoringPartSourceParameter));
                Model.ExternalDataSourceParameter = ExternalDataSourceParameter;
            })(Model = Framework.Model || (Framework.Model = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
