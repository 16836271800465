var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Contract;
        (function (Contract) {
            var Code;
            (function (Code) {
                var DurationUnit;
                (function (DurationUnit) {
                    DurationUnit[DurationUnit["Hours"] = 1] = "Hours";
                    DurationUnit[DurationUnit["Days"] = 2] = "Days";
                    DurationUnit[DurationUnit["Weeks"] = 3] = "Weeks";
                    DurationUnit[DurationUnit["Months"] = 4] = "Months";
                    DurationUnit[DurationUnit["Years"] = 5] = "Years";
                })(DurationUnit = Code.DurationUnit || (Code.DurationUnit = {}));
            })(Code = Contract.Code || (Contract.Code = {}));
        })(Contract = Shared.Contract || (Shared.Contract = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
