var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var ApiServiceBase = /** @class */ (function () {
                function ApiServiceBase(baseUrl, $http, spinnerSvc, $q) {
                    this.baseUrl = baseUrl;
                    this.$http = $http;
                    this.spinnerSvc = spinnerSvc;
                    this.$q = $q;
                }
                /** @deprecated use getPromise */
                ApiServiceBase.prototype.get = function (url, params, spinner, cache, transformResponse) {
                    var _this = this;
                    if (spinner) {
                        this.spinnerSvc.show(spinner);
                    }
                    var fullUrl = this.baseUrl + url;
                    var config = {
                        params: params,
                        cache: cache ? cache : false,
                    };
                    if (transformResponse != null) {
                        config.transformResponse = this.appendTransform(this.$http.defaults.transformResponse, transformResponse);
                    }
                    var promise = this.$http.get(fullUrl, config);
                    promise.finally(function () {
                        if (spinner) {
                            _this.spinnerSvc.hide(spinner);
                        }
                    });
                    return promise;
                };
                ApiServiceBase.prototype.getPromise = function (url, params, spinner, cache, transformResponse) {
                    return Promise.wrap(this.get(url, params, spinner, cache, transformResponse))
                        .then(function (httpResult) { return httpResult.data; })
                        .catch(function (httpError) { return Promise.reject(httpError.data); });
                };
                /** @deprecated use postPromise */
                ApiServiceBase.prototype.post = function (url, params, spinner, ignoreAuthenticationError) {
                    var _this = this;
                    if (spinner) {
                        this.spinnerSvc.show(spinner);
                    }
                    var fullUrl = this.baseUrl + url;
                    var config = {
                        ignoreUnauthorizedError: ignoreAuthenticationError,
                    };
                    var promise = this.$http.post(fullUrl, params, config);
                    promise.finally(function () {
                        if (spinner) {
                            _this.spinnerSvc.hide(spinner);
                        }
                    });
                    return promise;
                };
                ApiServiceBase.prototype.postPromise = function (url, params, spinner, ignoreAuthenticationError, errorCallback) {
                    return Promise.wrap(this.post(url, params, spinner, ignoreAuthenticationError))
                        .then(function (httpResult) { return httpResult.data; })
                        .catch(function (httpError) {
                        if (errorCallback) {
                            errorCallback(httpError);
                        }
                        return Promise.reject(httpError.data);
                    });
                };
                ApiServiceBase.prototype.patch = function (url, params, spinner, ignoreAuthenticationError) {
                    var _this = this;
                    if (spinner) {
                        this.spinnerSvc.show(spinner);
                    }
                    var fullUrl = this.baseUrl + url;
                    var config = {
                        ignoreUnauthorizedError: ignoreAuthenticationError,
                    };
                    var promise = this.$http.patch(fullUrl, params, config);
                    promise.finally(function () {
                        if (spinner) {
                            _this.spinnerSvc.hide(spinner);
                        }
                    });
                    return promise;
                };
                ApiServiceBase.prototype.patchPromise = function (url, params, spinner, ignoreAuthenticationError, errorCallback) {
                    return Promise.wrap(this.patch(url, params, spinner, ignoreAuthenticationError))
                        .then(function (httpResult) { return httpResult.data; })
                        .catch(function (httpError) {
                        if (errorCallback) {
                            errorCallback(httpError);
                        }
                        return Promise.reject(httpError.data);
                    });
                };
                /** @deprecated use putPromise */
                ApiServiceBase.prototype.put = function (url, params, spinner) {
                    var _this = this;
                    if (spinner) {
                        this.spinnerSvc.show(spinner);
                    }
                    var fullUrl = this.baseUrl + url;
                    var promise = this.$http.put(fullUrl, params);
                    promise.finally(function () {
                        if (spinner) {
                            _this.spinnerSvc.hide(spinner);
                        }
                    });
                    return promise;
                };
                ApiServiceBase.prototype.putPromise = function (url, params, spinner) {
                    return Promise.wrap(this.put(url, params, spinner))
                        .then(function (httpResult) { return httpResult.data; })
                        .catch(function (httpError) { return Promise.reject(httpError.data); });
                };
                /** @deprecated use deletePromise */
                ApiServiceBase.prototype.delete = function (url, spinner) {
                    var _this = this;
                    if (spinner) {
                        this.spinnerSvc.show(spinner);
                    }
                    var fullUrl = this.baseUrl + url;
                    var promise = this.$http.delete(fullUrl);
                    promise.finally(function () {
                        if (spinner) {
                            _this.spinnerSvc.hide(spinner);
                        }
                    });
                    return promise;
                };
                ApiServiceBase.prototype.deletePromise = function (url, spinner) {
                    return Promise.wrap(this.delete(url, spinner))
                        .then(function (httpResult) { return httpResult.data; })
                        .catch(function (httpError) { return Promise.reject(httpError.data); });
                };
                /**
                 * Get many items in batches / paged
                 * @param url
                 * @param set
                 * @param chunkSize
                 * @param queryMapper
                 * @param spinner
                 * @param cache
                 * @param transformResponse
                 * @returns
                 */
                ApiServiceBase.prototype.getInParts = function (url, set, chunkSize, queryMapper, spinner, cache, transformResponse) {
                    return __awaiter(this, void 0, void 0, function () {
                        var calls, results;
                        var _this = this;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    calls = _.chunk(set, chunkSize).map(function (subset) {
                                        var query = queryMapper(subset);
                                        return _this.getPromise(url, query, spinner, cache, transformResponse);
                                    });
                                    return [4 /*yield*/, Promise.all(calls)];
                                case 1:
                                    results = _a.sent();
                                    return [2 /*return*/, _.chain(results)
                                            .map(function (r) { return r; })
                                            .flatten()
                                            .value()];
                            }
                        });
                    });
                };
                ApiServiceBase.prototype.appendTransform = function (defaults, transform) {
                    defaults = angular.isArray(defaults) ? defaults : [defaults];
                    return defaults.concat(transform);
                };
                ApiServiceBase.injectionParameters = ['baseUrl', '$http', 'spinnerSvc', '$q'];
                return ApiServiceBase;
            }());
            Framework.ApiServiceBase = ApiServiceBase;
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
