var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Contract;
        (function (Contract) {
            var Code;
            (function (Code) {
                var AnamnesisRegistrationType;
                (function (AnamnesisRegistrationType) {
                    AnamnesisRegistrationType[AnamnesisRegistrationType["RegistrationPhysicalQuantity"] = 1] = "RegistrationPhysicalQuantity";
                    AnamnesisRegistrationType[AnamnesisRegistrationType["RegistrationCodeSetValue"] = 2] = "RegistrationCodeSetValue";
                    AnamnesisRegistrationType[AnamnesisRegistrationType["RegistrationMultiCodeSetValue"] = 3] = "RegistrationMultiCodeSetValue";
                })(AnamnesisRegistrationType = Code.AnamnesisRegistrationType || (Code.AnamnesisRegistrationType = {}));
            })(Code = Contract.Code || (Contract.Code = {}));
        })(Contract = Shared.Contract || (Shared.Contract = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
