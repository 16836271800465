var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Contract;
        (function (Contract) {
            var Code;
            (function (Code) {
                var ContactPointType;
                (function (ContactPointType) {
                    ContactPointType[ContactPointType["None"] = 0] = "None";
                    ContactPointType[ContactPointType["Address"] = 1] = "Address";
                    ContactPointType[ContactPointType["Telephone"] = 2] = "Telephone";
                    ContactPointType[ContactPointType["MobilePhone"] = 3] = "MobilePhone";
                    ContactPointType[ContactPointType["Fax"] = 4] = "Fax";
                    ContactPointType[ContactPointType["Email"] = 5] = "Email";
                    ContactPointType[ContactPointType["Website"] = 6] = "Website";
                })(ContactPointType = Code.ContactPointType || (Code.ContactPointType = {}));
            })(Code = Contract.Code || (Contract.Code = {}));
        })(Contract = Shared.Contract || (Shared.Contract = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
