var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Contract;
        (function (Contract) {
            var Code;
            (function (Code) {
                var TargetValueType;
                (function (TargetValueType) {
                    TargetValueType[TargetValueType["TargetDose"] = 1] = "TargetDose";
                    TargetValueType[TargetValueType["TargetPhysicalQuantity"] = 2] = "TargetPhysicalQuantity";
                    TargetValueType[TargetValueType["TargetPhysicalQuantityRange"] = 3] = "TargetPhysicalQuantityRange";
                })(TargetValueType = Code.TargetValueType || (Code.TargetValueType = {}));
            })(Code = Contract.Code || (Contract.Code = {}));
        })(Contract = Shared.Contract || (Shared.Contract = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
