var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Model;
            (function (Model) {
                var Version = Shared.Framework.Model.Version;
                var RuleThresholdDefinition = /** @class */ (function () {
                    function RuleThresholdDefinition(serverObject) {
                        if (serverObject != null) {
                            this.id = serverObject.Id;
                            this.therapyId = serverObject.TherapyId;
                            this.name = serverObject.Name;
                            this.isPersonalisable = serverObject.IsPersonalisable;
                            this.sequence = serverObject.Sequence;
                            this.characteristic = serverObject.Characteristic;
                            this.unit = serverObject.Unit;
                            this.observableEntity = serverObject.ObservableEntity;
                            this.codeSet = serverObject.CodeSet;
                            this.ruleThresholdValues = _(serverObject.RuleThresholdValues).map(function (x) { return new RuleThresholdValue(x); });
                            this.readOnly = serverObject.ReadOnly;
                        }
                        else {
                            this.ruleThresholdValues = [];
                        }
                    }
                    RuleThresholdDefinition.prototype.getCurrentRuleThresholdValue = function () {
                        var currentDate = new Date();
                        var result = _(this.ruleThresholdValues).find(function (x) { return (moment(x.validFromDate).isBefore(currentDate) && (moment(x.validUntilDate).isAfter(currentDate) || x.validUntilDate == null))
                            || moment().isSame(x.validFromDate, 'day') || moment().isSame(x.validUntilDate, 'day'); });
                        return result ? result : null;
                    };
                    RuleThresholdDefinition.prototype.getCurrentRuleThresholdValueString = function () {
                        var currentDate = new Date();
                        var lastValue = _(this.ruleThresholdValues).find(function (x) { return (moment(x.validFromDate).isBefore(currentDate) && (moment(x.validUntilDate).isAfter(currentDate) || x.validUntilDate == null))
                            || moment().isSame(x.validFromDate, 'day') || moment().isSame(x.validUntilDate, 'day'); });
                        return lastValue ? lastValue.getValueString() : null;
                    };
                    RuleThresholdDefinition.prototype.getLastRuleThresholdValue = function () {
                        var maxDate = _(this.ruleThresholdValues).max(function (x) { return x.validFromDate; }).validFromDate;
                        return _(this.ruleThresholdValues).find(function (x) { return moment(x.validFromDate).isSame(maxDate); });
                    };
                    RuleThresholdDefinition.prototype.getPreviousRuleThresholdValue = function (value) {
                        var values = _(this.ruleThresholdValues).filter(function (v) { return moment(value.validFromDate).add(-1, 'day').isSame(v.validUntilDate); });
                        return _(values).size() !== 0 ? _(values).last() : null;
                    };
                    RuleThresholdDefinition.prototype.addRuleThresholdValue = function (value) {
                        if (!_(this.ruleThresholdValues).isEmpty()) {
                            var previous = this.getLastRuleThresholdValue();
                            previous.validUntilDate = moment(value.validFromDate).subtract(1, 'Day').toDate();
                        }
                        else {
                            value.validFromDate = new Date();
                        }
                        value.validUntilDate = null;
                        this.ruleThresholdValues.push(value);
                    };
                    RuleThresholdDefinition.prototype.editRuleThresholdValue = function (thresholdValue, newValidFromDate, newValidUntilDate) {
                        var previous = _(this.ruleThresholdValues).find(function (x) { return moment(x.validUntilDate).isSame(moment(thresholdValue.validFromDate).subtract(1, 'Day').toDate()); });
                        if (previous) {
                            previous.validUntilDate = moment(newValidFromDate).subtract(1, 'Day').toDate();
                        }
                        thresholdValue.validFromDate = newValidFromDate;
                        thresholdValue.validUntilDate = newValidUntilDate;
                        return thresholdValue;
                    };
                    RuleThresholdDefinition.prototype.deleteRuleThresholdValue = function (thresholdValue) {
                        var previous = _(this.ruleThresholdValues).find(function (x) { return moment(x.validUntilDate).isSame(moment(thresholdValue.validFromDate).subtract(1, 'Day').toDate()); });
                        previous.validUntilDate = null;
                        this.ruleThresholdValues = _(this.ruleThresholdValues).filter(function (x) { return x.validFromDate != thresholdValue.validFromDate; });
                    };
                    RuleThresholdDefinition.prototype.toServerWrite = function () {
                        var _this = this;
                        _(this.ruleThresholdValues).filter(function (x) { return x !== _this.getLastRuleThresholdValue(); }).map(function (v) { return v.validUntilDate = null; });
                        var result = {
                            Id: this.id,
                            Name: this.name,
                            IsPersonalisable: this.isPersonalisable,
                            Sequence: this.sequence,
                            UnitId: this.unit ? this.unit.Id : null,
                            CharacteristicId: this.characteristic ? this.characteristic.Id : null,
                            CodeSetId: this.codeSet ? this.codeSet.Id : null,
                            ObservableEntityId: this.observableEntity ? this.observableEntity.Id : null,
                            RuleThresholdValues: _(this.ruleThresholdValues).map(function (x) { return x.toServerWrite(); }),
                            TherapyId: this.therapyId
                        };
                        return result;
                    };
                    RuleThresholdDefinition.prototype.hasFutureValue = function () {
                        return _(this.ruleThresholdValues).any(function (x) { return moment(x.validFromDate).isAfter(moment()); });
                    };
                    return RuleThresholdDefinition;
                }());
                Model.RuleThresholdDefinition = RuleThresholdDefinition;
                var RuleThresholdValue = /** @class */ (function (_super) {
                    __extends(RuleThresholdValue, _super);
                    function RuleThresholdValue(serverObject) {
                        var _this = _super.call(this) || this;
                        if (serverObject != null) {
                            _this.id = serverObject.Id;
                            _this.amount = serverObject.Amount;
                            _this.codeSetItem = serverObject.CodeSetItem;
                            _this.validFromDate = Shared.DateHelper.serverDateStringToDate(serverObject.ValidFromDate);
                            _this.validUntilDate = Shared.DateHelper.serverDateStringToDate(serverObject.ValidUntilDate);
                            _this.isPersonalised = serverObject.IsPersonalised;
                        }
                        return _this;
                    }
                    RuleThresholdValue.prototype.getValueString = function () {
                        if (this.codeSetItem) {
                            return this.codeSetItem.Name + ' (' + this.codeSetItem.NumericValue + ')';
                        }
                        else
                            return this.amount.toString();
                    };
                    RuleThresholdValue.prototype.toServerWrite = function () {
                        var result = {
                            Id: this.id,
                            Amount: this.amount,
                            CodeSetItemId: this.codeSetItem ? this.codeSetItem.Id : null,
                            ValidFromDate: Shared.DateHelper.toServerDateString(this.validFromDate)
                        };
                        if (this.validUntilDate !== null) {
                            result.ValidUntilDate = result.ValidUntilDate = Shared.DateHelper.toServerDateString(this.validUntilDate);
                        }
                        return result;
                    };
                    return RuleThresholdValue;
                }(Version));
                Model.RuleThresholdValue = RuleThresholdValue;
            })(Model = Framework.Model || (Framework.Model = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
