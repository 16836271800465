var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Directive;
            (function (Directive) {
                var DropdownCreateButton = /** @class */ (function () {
                    function DropdownCreateButton() {
                    }
                    DropdownCreateButton.prototype.$onInit = function () {
                        this.label = this.label || 'General.Add';
                    };
                    DropdownCreateButton.prototype.selected = function (option) {
                        this.create({ option: option });
                    };
                    return DropdownCreateButton;
                }());
                Shared.remeCareSharedModule.component('rcDropdownCreate', {
                    controller: DropdownCreateButton,
                    controllerAs: 'dropdownCtrl',
                    templateUrl: 'views/controls/dropdownCreateButton.html',
                    bindings: {
                        buttonClasses: '@',
                        create: '&',
                        options: '<',
                        label: '@?',
                        displayField: '@',
                        rightAlligned: '<?',
                        ngDisabled: '<?'
                    }
                });
            })(Directive = Framework.Directive || (Framework.Directive = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
