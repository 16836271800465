namespace RemeCare.Shared.Framework.Model {
    import Document = Shared.Contract.IMetaDataDocument;

    export class GuidanceStepTranslation {
        public GuidanceStepTypeCode: Shared.Contract.Code.GuidanceStepType;
        public OriginalContent: string;
        public Content: string;
        public Id?: Shared.Contract.Guid;
        public Culture: string;
        public Name: string;
        public ParentId?: Shared.Contract.Guid;

        private guidanceStepType: Shared.Contract.Code.GuidanceStepType;
        private files: Document[];

        constructor(serverObject?: Shared.Contract.Read.IGuidanceStepTranslation) {
            if (serverObject != null) {
                this.Content = serverObject.Content;
                this.OriginalContent = serverObject.Content;
                this.Culture = serverObject.Culture;
                this.guidanceStepType = serverObject.GuidanceStepTypeCode;
                this.GuidanceStepTypeCode = this.guidanceStepType;
                this.Id = serverObject.Id;
                this.Name = serverObject.Name;
                this.ParentId = serverObject.ParentId;
            } else {
                this.Content = '';
                this.OriginalContent = '';
                this.guidanceStepType = Shared.Contract.Code.GuidanceStepType.Text;
                this.GuidanceStepTypeCode = Shared.Contract.Code.GuidanceStepType.Text;
                this.Culture = '';
            }
        }

        public get GuidanceStepType(): Shared.Contract.Code.GuidanceStepType {
            return this.guidanceStepType;
        }

        public set GuidanceStepType(value: Shared.Contract.Code.GuidanceStepType) {
            if (value !== this.GuidanceStepTypeCode) {
                this.Content = '';
            } else {
                this.Content = this.OriginalContent;
            }
            this.guidanceStepType = value;
            this.GuidanceStepTypeCode = value;
        }

        public get TextContent(): string {
            return this.Content;
        }

        public set TextContent(value: string) {
            this.Content = value;
        }

        public get VideoContent(): string {
            return this.Content;
        }

        public set VideoContent(value: string) {
            this.Content = value;
        }

        public get FileContent(): Document[] {
            if (this.files == null) {
                try {
                    const f: Document = JSON.parse(this.Content);
                    this.files = [f];
                } catch (e) {
                    this.files = [];
                }
            }

            this.setFileContent();
            return this.files;
        }

        public set FileContent(value: Document[]) {
            this.files = value;
            this.setFileContent();
        }

        private setFileContent(): void {
            if (this.files.length > 1) {
                this.files[0] = this.files[this.files.length - 1];
                this.files.length = 1;
            }

            this.Content = this.files.length > 0 ? JSON.stringify(this.files[0]) : '';
        }
    }

    export class GuidanceStep {
        public GuidanceStepId: Shared.Contract.Guid;
        public Sequence: number;
        public Translations: {
            [culture: string]: GuidanceStepTranslation;
        } = {};
        public Width: Shared.Contract.Code.PartWidth;

        constructor(serverObject?: Shared.Contract.Read.IGuidanceStep) {
            if (serverObject != null) {
                this.GuidanceStepId = serverObject.GuidanceStepId;
                this.Sequence = serverObject.Sequence;
                _(serverObject.Translations).each(t => {
                    this.Translations[t.Culture] = new GuidanceStepTranslation(t);
                    this.Translations[t.Culture].Culture = t.Culture;
                });
                this.Width = serverObject.Width;
            }
        }

        public toServerWrite(): RemeCare.Shared.Contract.Write.IGuidanceStep {
            const result = {
                Sequence: this.Sequence,
                GuidanceStepId: this.GuidanceStepId,
                Translations: this.getTranslations(),
                Width: this.Width,
            } as RemeCare.Shared.Contract.Write.IGuidanceStep;
            return result;
        }

        public copy(): GuidanceStep {
            const result = new GuidanceStep();

            result.setCommonParts(this);

            return result;
        }

        public setCommonParts<T extends GuidanceStep>(part: T): T {
            this.GuidanceStepId = part.GuidanceStepId;
            this.Sequence = part.Sequence;
            this.Translations = angular.copy(part.Translations);
            this.Width = part.Width;
            return part;
        }

        public anonimise(): void {
            this.GuidanceStepId = null;
            for (const key in this.Translations) {
                if (this.Translations.hasOwnProperty(key)) {
                    this.Translations[key].Id = null;
                }
            }
        }

        private getTranslations(): RemeCare.Shared.Contract.Write.IGuidanceStepTranslation[] {
            const translations: RemeCare.Shared.Contract.Write.IGuidanceStepTranslation[] = [];
            for (const key in this.Translations) {
                if (this.Translations.hasOwnProperty(key)) {
                    const trans = this.Translations[key];
                    translations.push({
                        Type: trans.GuidanceStepType as number,
                        ParentId: trans.ParentId,
                        Id: trans.Id,
                        Culture: key,
                        Name: trans.Name,
                        Content: trans.Content,
                    } as RemeCare.Shared.Contract.Write.IGuidanceStepTranslation);
                }
            }
            return translations;
        }
    }
}
