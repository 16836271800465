var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var TranslationHelper = /** @class */ (function () {
            function TranslationHelper() {
            }
            TranslationHelper.getTranslations = function (dictionairy, parentId) {
                var translations = [];
                for (var key in dictionairy) {
                    if (dictionairy.hasOwnProperty(key)) {
                        var trans = dictionairy[key];
                        translations.push({
                            id: trans.Id,
                            culture: key,
                            name: trans.Name,
                            parentId: parentId != null ? parentId : trans.ParentId
                        });
                    }
                }
                return translations;
            };
            TranslationHelper.getTranslationInts = function (dictionairy, parentId) {
                var translations = [];
                for (var key in dictionairy) {
                    if (dictionairy.hasOwnProperty(key)) {
                        var trans = dictionairy[key];
                        translations.push({
                            id: trans.Id,
                            culture: key,
                            name: trans.Name,
                            parentId: parentId != null ? parentId : trans.ParentId
                        });
                    }
                }
                return translations;
            };
            TranslationHelper.getDescriptionTranslations = function (dictionairy, parentId) {
                var translations = [];
                for (var key in dictionairy) {
                    if (dictionairy.hasOwnProperty(key)) {
                        var trans = dictionairy[key];
                        translations.push({
                            id: trans.Id,
                            culture: key,
                            name: trans.Name,
                            parentId: parentId != null ? parentId : trans.ParentId,
                            description: trans.Description
                        });
                    }
                }
                return translations;
            };
            return TranslationHelper;
        }());
        Shared.TranslationHelper = TranslationHelper;
        Shared.remeCareSharedModule.factory('translationHelper', function () { return new TranslationHelper(); });
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
