/// <reference path="../../contract/code/evenOddDays.ts"/>
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Model;
            (function (Model) {
                var DailyAlternationType = Shared.Contract.Code.DailyAlternationType;
                var Periodicity = /** @class */ (function () {
                    function Periodicity(serverObject) {
                        this.intraDayTimings = [];
                        this.type = Shared.Contract.Code.PeriodicityType.Periodicity;
                        if (serverObject != null) {
                            this.sequence = serverObject.Sequence;
                            this.unstructuredPeriodicity = serverObject.UnstructuredPeriodicity;
                            this.duration = serverObject.Duration;
                            this.recurrence = serverObject.Recurrence;
                            this.simplifiedRecurrence = serverObject.SimplifiedRecurrence;
                            this.unstructuredTargetQuantity = serverObject.UnstructuredTargetQuantity;
                            this.isAlternating = serverObject.SequenceInRecurrenceCycle ? true : false;
                            this.sequenceInRecurrenceCycle = serverObject.SequenceInRecurrenceCycle
                                ? serverObject.SequenceInRecurrenceCycle
                                : 0;
                            this.hasDuration = this.duration != null && this.duration !== {};
                        }
                        else {
                            this.recurrence = {};
                            this.sequenceInRecurrenceCycle = 0;
                            this.isAlternating = false;
                            this.hasDuration = false;
                        }
                        this.intraDayTimings = [];
                    }
                    Periodicity.prototype.setChild = function (child) {
                        this.childPeriodicity = child;
                    };
                    Periodicity.prototype.copy = function () {
                        var result = new Periodicity();
                        this.setCommonParts(result);
                        return result;
                    };
                    Periodicity.prototype.setCommonParts = function (periodicity, ignoreIntraDayTimings) {
                        if (ignoreIntraDayTimings === void 0) { ignoreIntraDayTimings = false; }
                        periodicity.type = this.type;
                        periodicity.sequence = this.sequence;
                        periodicity.sequenceInRecurrenceCycle = this.sequenceInRecurrenceCycle;
                        periodicity.isAlternating = this.isAlternating;
                        periodicity.unstructuredPeriodicity = this.unstructuredPeriodicity;
                        periodicity.unstructuredTargetQuantity = this.unstructuredTargetQuantity;
                        if (this.childPeriodicity) {
                            periodicity.childPeriodicity = this.childPeriodicity.copy();
                            periodicity.childPeriodicity.parentPeriodicity = periodicity;
                        }
                        if (!ignoreIntraDayTimings) {
                            periodicity.intraDayTimings = _(this.intraDayTimings).map(function (i) { return i.copy(); });
                        }
                        periodicity.duration = angular.copy(this.duration);
                        periodicity.recurrence = angular.copy(this.recurrence);
                        periodicity.hasDuration = this.hasDuration;
                    };
                    Periodicity.prototype.toWriteModel = function () {
                        return {
                            Type: this.recurrence != null && this.recurrence.Unit != null ? this.recurrence.Unit : 0,
                            Duration: this.duration != null && this.duration.Unit != null
                                ? {
                                    Unit: this.duration.Unit,
                                    Quantity: this.duration.Quantity,
                                }
                                : null,
                            Recurrence: this.recurrence != null && this.recurrence.Unit != null
                                ? {
                                    Unit: this.recurrence.Unit,
                                    Quantity: this.recurrence.Quantity,
                                }
                                : null,
                            Sequence: this.sequence,
                            SequenceInRecurrenceCycle: this.sequenceInRecurrenceCycle > 0 ? this.sequenceInRecurrenceCycle : null,
                            ChildPeriodicity: this.childPeriodicity != null ? this.childPeriodicity.toWriteModel() : null,
                            IntraDayTimings: _(this.intraDayTimings).map(function (idt) { return idt.toWriteModel(); }),
                            SimplifiedRecurrence: this.simplifiedRecurrence != null ? this.simplifiedRecurrence.Id : null,
                            UnstructuredPeriodicity: this.unstructuredPeriodicity,
                            UnstructuredTargetQuantity: this.unstructuredTargetQuantity,
                        };
                    };
                    return Periodicity;
                }());
                Model.Periodicity = Periodicity;
                var PeriodicityDay = /** @class */ (function (_super) {
                    __extends(PeriodicityDay, _super);
                    function PeriodicityDay(serverObject) {
                        var _this = _super.call(this, serverObject) || this;
                        _this.unitTextKey = 'General.Day';
                        _this.type = Shared.Contract.Code.PeriodicityType.PeriodicityDay;
                        if (serverObject != null) {
                            _this.evenUnevenDays = serverObject.EvenUnevenDays;
                            if (_this.isEvery2Days()) {
                                _this.dailyAlternationType = _this.evenUnevenDays
                                    ? DailyAlternationType.EvenOdd
                                    : DailyAlternationType.Day1Day2;
                            }
                        }
                        return _this;
                    }
                    PeriodicityDay.prototype.copy = function () {
                        var result = new PeriodicityDay();
                        _super.prototype.setCommonParts.call(this, result);
                        result.evenUnevenDays = this.evenUnevenDays;
                        result.dailyAlternationType = this.dailyAlternationType;
                        return result;
                    };
                    PeriodicityDay.prototype.toWriteModel = function () {
                        var result = _super.prototype.toWriteModel.call(this);
                        result.Type = Shared.Contract.Code.PeriodicityType.PeriodicityDay;
                        result.EvenUnevenDays = this.evenUnevenDays;
                        return result;
                    };
                    PeriodicityDay.prototype.isEvery2Days = function () {
                        return (this.isAlternating &&
                            this.recurrence.Unit === Shared.Contract.Code.DurationUnit.Days &&
                            this.recurrence.Quantity === 2);
                    };
                    return PeriodicityDay;
                }(Periodicity));
                Model.PeriodicityDay = PeriodicityDay;
                var PeriodicityWeek = /** @class */ (function (_super) {
                    __extends(PeriodicityWeek, _super);
                    function PeriodicityWeek(serverObject) {
                        var _this = _super.call(this, serverObject) || this;
                        _this.unitTextKey = 'General.Week';
                        _this.type = Shared.Contract.Code.PeriodicityType.PeriodicityWeek;
                        _this.daysOfWeek = 0;
                        _this.weekRepetitionType = 1;
                        if (serverObject != null) {
                            _this.daysOfWeekDuration = serverObject.DaysOfWeekDuration;
                            _this.daysOfWeek = serverObject.DaysOfWeek;
                            if (!_this.daysOfWeek && _this.daysOfWeekDuration == null && _this.childPeriodicity == null) {
                                _this.weekRepetitionType = 1;
                            }
                            else if (_this.childPeriodicity != null) {
                                _this.weekRepetitionType = 3;
                            }
                            else {
                                _this.weekRepetitionType = 2;
                            }
                        }
                        return _this;
                    }
                    PeriodicityWeek.prototype.copy = function () {
                        var result = new PeriodicityWeek();
                        _super.prototype.setCommonParts.call(this, result);
                        result.daysOfWeekDuration = this.daysOfWeekDuration;
                        result.daysOfWeek = this.daysOfWeek;
                        result.weekRepetitionType = this.weekRepetitionType;
                        return result;
                    };
                    PeriodicityWeek.prototype.toWriteModel = function () {
                        var result = _super.prototype.toWriteModel.call(this);
                        result.Type = Shared.Contract.Code.PeriodicityType.PeriodicityWeek;
                        result.DaysOfWeekDuration = this.daysOfWeekDuration;
                        result.DaysOfWeek = this.daysOfWeek;
                        return result;
                    };
                    PeriodicityWeek.prototype.setChild = function (child) {
                        _super.prototype.setChild.call(this, child);
                        this.weekRepetitionType = 3;
                    };
                    return PeriodicityWeek;
                }(Periodicity));
                Model.PeriodicityWeek = PeriodicityWeek;
                var PeriodicityMonth = /** @class */ (function (_super) {
                    __extends(PeriodicityMonth, _super);
                    function PeriodicityMonth(serverObject) {
                        var _this = _super.call(this, serverObject) || this;
                        _this.unitTextKey = 'General.Month';
                        _this.type = Shared.Contract.Code.PeriodicityType.PeriodicityMonth;
                        if (serverObject != null) {
                            _this.dayOfMonth = serverObject.DayOfMonth;
                            _this.weekDayOccurence = serverObject.WeekDayOccurence;
                            _this.dayOfWeek = serverObject.DayOfWeek;
                        }
                        if (_this.dayOfMonth == null &&
                            _this.weekDayOccurence == null &&
                            _this.dayOfWeek == null &&
                            _this.childPeriodicity == null) {
                            _this.monthRepetitionType = 1;
                        }
                        else if (_this.dayOfMonth != null) {
                            _this.monthRepetitionType = 2;
                        }
                        else if (_this.childPeriodicity != null) {
                            _this.monthRepetitionType = 4;
                        }
                        else {
                            _this.monthRepetitionType = 3;
                        }
                        return _this;
                    }
                    PeriodicityMonth.prototype.copy = function () {
                        var result = new PeriodicityMonth();
                        _super.prototype.setCommonParts.call(this, result);
                        result.dayOfMonth = this.dayOfMonth;
                        result.weekDayOccurence = this.weekDayOccurence;
                        result.dayOfWeek = this.dayOfWeek;
                        result.monthRepetitionType = this.monthRepetitionType;
                        return result;
                    };
                    PeriodicityMonth.prototype.toWriteModel = function () {
                        var result = _super.prototype.toWriteModel.call(this);
                        result.Type = Shared.Contract.Code.PeriodicityType.PeriodicityMonth;
                        result.DayOfMonth = this.dayOfMonth;
                        result.WeekDayOccurence = this.weekDayOccurence;
                        result.DayOfWeek = this.dayOfWeek;
                        return result;
                    };
                    PeriodicityMonth.prototype.setChild = function (child) {
                        _super.prototype.setChild.call(this, child);
                        this.monthRepetitionType = 4;
                    };
                    return PeriodicityMonth;
                }(Periodicity));
                Model.PeriodicityMonth = PeriodicityMonth;
                var PeriodicityYear = /** @class */ (function (_super) {
                    __extends(PeriodicityYear, _super);
                    function PeriodicityYear(serverObject) {
                        var _this = _super.call(this, serverObject) || this;
                        _this.unitTextKey = 'General.Year';
                        _this.type = Shared.Contract.Code.PeriodicityType.PeriodicityYear;
                        _this.yearRepetitionType = Shared.Contract.Code.PeriodicityType.PeriodicityYear;
                        if (serverObject != null) {
                            _this.dayOfMonth = serverObject.DayOfMonth;
                            _this.monthOfYear = serverObject.MonthOfYear;
                            _this.weekDayOccurence = serverObject.WeekDayOccurence;
                            _this.dayOfWeek = serverObject.DayOfWeek;
                            if (_this.dayOfMonth == null &&
                                _this.weekDayOccurence == null &&
                                _this.dayOfWeek == null &&
                                _this.monthOfYear == null &&
                                _this.childPeriodicity == null) {
                                _this.yearRepetitionType = 1;
                            }
                            else if ((_this.weekDayOccurence != null || _this.dayOfMonth != null) &&
                                _this.dayOfWeek == null &&
                                _this.childPeriodicity == null) {
                                _this.yearRepetitionType = 2;
                            }
                            else if (_this.childPeriodicity != null) {
                                _this.yearRepetitionType = 4;
                            }
                            else {
                                _this.yearRepetitionType = 3;
                            }
                        }
                        return _this;
                    }
                    PeriodicityYear.prototype.copy = function () {
                        var result = new PeriodicityYear();
                        _super.prototype.setCommonParts.call(this, result);
                        result.dayOfMonth = this.dayOfMonth;
                        result.monthOfYear = this.monthOfYear;
                        result.weekDayOccurence = this.weekDayOccurence;
                        result.dayOfWeek = this.dayOfWeek;
                        result.yearRepetitionType = this.yearRepetitionType;
                        return result;
                    };
                    PeriodicityYear.prototype.toWriteModel = function () {
                        var result = _super.prototype.toWriteModel.call(this);
                        result.Type = Shared.Contract.Code.PeriodicityType.PeriodicityYear;
                        result.DayOfMonth = this.dayOfMonth;
                        result.MonthOfYear = this.monthOfYear;
                        result.WeekDayOccurence = this.weekDayOccurence;
                        result.DayOfWeek = this.dayOfWeek;
                        return result;
                    };
                    PeriodicityYear.prototype.setChild = function (child) {
                        _super.prototype.setChild.call(this, child);
                        this.yearRepetitionType = 4;
                    };
                    return PeriodicityYear;
                }(Periodicity));
                Model.PeriodicityYear = PeriodicityYear;
                var RecurrentCycle = /** @class */ (function () {
                    function RecurrentCycle(periodicities) {
                        this.periodicities = periodicities;
                    }
                    return RecurrentCycle;
                }());
                Model.RecurrentCycle = RecurrentCycle;
            })(Model = Framework.Model || (Framework.Model = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
