var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Directive;
        (function (Directive) {
            var LoadTranslationsDirective = /** @class */ (function () {
                function LoadTranslationsDirective(translationResolver) {
                    var _this = this;
                    this.translationResolver = translationResolver;
                    this.restrict = 'EA';
                    this.link = function (scope, element, attrs) {
                        _this.translationResolver.setParts([attrs.key]);
                    };
                }
                return LoadTranslationsDirective;
            }());
            Shared.remeCareSharedModule.directive('loadTranslations', function (translationResolver) { return new LoadTranslationsDirective(translationResolver); });
        })(Directive = Shared.Directive || (Shared.Directive = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
