var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Service;
            (function (Service) {
                var PatientInformedConsentService = /** @class */ (function () {
                    function PatientInformedConsentService(authservice) {
                        this.authservice = authservice;
                    }
                    PatientInformedConsentService.prototype.shouldCheckPatientInformedConsents = function () {
                        if (!this.authservice.isPatient()) {
                            return false;
                        }
                        if (this.patientInformedConsentsChecked) {
                            return false;
                        }
                        return true;
                    };
                    PatientInformedConsentService.prototype.allPatientInformedConsentsChecked = function (acceptedInformedConsents) {
                        this.patientInformedConsentsChecked = true;
                        this.acceptedInformedConsents = acceptedInformedConsents;
                    };
                    PatientInformedConsentService.prototype.hasAcceptedPatientInformedConsents = function () {
                        return this.acceptedInformedConsents > 0;
                    };
                    return PatientInformedConsentService;
                }());
                Service.PatientInformedConsentService = PatientInformedConsentService;
                Shared.remeCareSharedModule.service('patientInformedConsentService', PatientInformedConsentService);
            })(Service = Framework.Service || (Framework.Service = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
