var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Contract;
        (function (Contract) {
            var Code;
            (function (Code) {
                var SourceParameterRepresentationType;
                (function (SourceParameterRepresentationType) {
                    SourceParameterRepresentationType[SourceParameterRepresentationType["ChartLine"] = 1] = "ChartLine";
                    SourceParameterRepresentationType[SourceParameterRepresentationType["ChartBar"] = 2] = "ChartBar";
                    SourceParameterRepresentationType[SourceParameterRepresentationType["TableColumn"] = 3] = "TableColumn";
                    SourceParameterRepresentationType[SourceParameterRepresentationType["ColourQualitative"] = 4] = "ColourQualitative";
                    SourceParameterRepresentationType[SourceParameterRepresentationType["Boxplot"] = 5] = "Boxplot";
                })(SourceParameterRepresentationType = Code.SourceParameterRepresentationType || (Code.SourceParameterRepresentationType = {}));
            })(Code = Contract.Code || (Contract.Code = {}));
        })(Contract = Shared.Contract || (Shared.Contract = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
