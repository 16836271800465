namespace RemeCare.Shared.Framework.Directive {
    class InputNumberController implements ng.IComponentController {
        private min: number;
        private max: number;

        public $onInit(): void {}

        public placeholder(): string {
            if (this.min != null && this.max != null) {
                return '[' + this.min + ' - ' + this.max + ']';
            } else if (this.min != null) {
                return '>=' + this.min;
            } else if (this.max != null) {
                return '<=' + this.max;
            } else {
                return '';
            }
        }
    }

    remeCareSharedModule.component('rcInputNumber', {
        bindings: {
            model: '=ngModel',
            required: '=ngRequired',
            readOnly: '=ngReadonly',
            disabled: '=ngDisabled',
            info: '@',
            adjustSize: '=',
            label: '@',
            min: '=ngMin',
            max: '=ngMax',
            forceDecimals: '=',
            unit: '@',
            guidanceIconLocation: '<',
            guidanceIconFunc: '<',
            uniqueId: '@',
        },
        controller: InputNumberController,
        require: {
            formCtrl: '^form',
        },
        templateUrl: 'framework/directives/formElements/inputNumber/inputNumber.html',
    });
}
