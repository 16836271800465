namespace RemeCare.Shared.Framework.Directive {
    class RemeCareButtonController implements ng.IComponentController {
        public $onInit(): void {}
    }

    remeCareSharedModule.component('rcButton', {
        controller: RemeCareButtonController,
        templateUrl: 'framework/directives/controls/button/button.html',
        bindings: {
            click: '&?',
            label: '@?',
            ngDisabled: '<?',
            link: '@?',
            pullRight: '<?',
            isPrimary: '<?',
            spinner: '@?',
        },
    });
}
