var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Periodicity;
            (function (Periodicity) {
                var PeriodicityFactory = Framework.Factory.PeriodicityFactory;
                var PeriodicityYearController = /** @class */ (function (_super) {
                    __extends(PeriodicityYearController, _super);
                    function PeriodicityYearController($scope, $translate, toaster, $timeout, masterdataSvc) {
                        var _this = _super.call(this, $scope, $translate, toaster) || this;
                        _this.$timeout = $timeout;
                        _this.masterdataSvc = masterdataSvc;
                        $scope.repetitionTypeChange = function () { return _this.repetitionTypeChange(); };
                        return _this;
                    }
                    PeriodicityYearController.prototype.$onInit = function () { };
                    PeriodicityYearController.prototype.watchPeriodicityType = function () {
                        var _this = this;
                        this.$scope.$watch(function (s) { return s.periodicity.type; }, function (newValue, oldValue) {
                            if (newValue !== oldValue) {
                                _this.$scope.periodicity.yearRepetitionType = 1;
                            }
                        });
                    };
                    PeriodicityYearController.prototype.repetitionTypeChange = function () {
                        this.$scope.periodicity.dayOfMonth = null;
                        this.$scope.periodicity.weekDayOccurence = null;
                        this.$scope.periodicity.dayOfWeek = null;
                        this.$scope.periodicity.monthOfYear = null;
                        this.$scope.periodicity.childPeriodicity = null;
                        if (this.$scope.periodicity.yearRepetitionType === 4 && this.$scope.periodicity.childPeriodicity == null) {
                            this.$scope.periodicity.childPeriodicity = new PeriodicityFactory()
                                .createPeriodicityFromDurationUnit(this.$scope.limitedDurationUnits[0].Id, this.$scope.periodicity);
                        }
                    };
                    return PeriodicityYearController;
                }(Framework.ControllerBase));
                Shared.remeCareSharedModule.controller('periodicityYearCtrl', function ($scope, $translate, toaster, $timeout, masterdataSvc) { return new PeriodicityYearController($scope, $translate, toaster, $timeout, masterdataSvc); });
            })(Periodicity = Framework.Periodicity || (Framework.Periodicity = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
