module RemeCare.Shared.Framework {
    export class MasterdataSearchService extends Shared.Framework.ApiServiceBase {
        public static $inject = Shared.Framework.ApiServiceBase.injectionParameters;

        public searchDiseasesAsync(
            query: Contract.Query.IDiseaseQuery
        ): Promise<Contract.ISearchResult<Shared.Contract.IEntityTranslation>> {
            const url = 'Diseases';
            return this.getPromise(url, query);
        }

        public searchCarePlanStatusReasonTypes(
            query: Contract.Query.ICarePlanStatusReasonTypesQuery
        ): ng.IHttpPromise<Contract.ISearchResult<Contract.IEntityTranslation>> {
            const url = 'CarePlanStatusReasonTypes';
            return this.get(url, query, 'careplanStatusReasonTypes');
        }

        public searchCodeSetTypes(
            query: Shared.Contract.Query.ICodeSetTypeQuery
        ): ng.IHttpPromise<Contract.ISearchResult<Shared.Contract.IEntityTranslation>> {
            const url = 'CodeSetTypes';
            return this.get(url, query, 'codesettypes');
        }

        public searchObservableEntitiesAsync(
            query: Shared.Contract.Query.IObservableEntityQuery
        ): Promise<Contract.ISearchResult<Shared.Contract.IEntityTranslation>> {
            return this.getPromise('ObservableEntities', query);
        }

        public searchHospitalisationReasonsAsync(
            query: Contract.Query.IHospitalisationReasonQuery
        ): Promise<Contract.ISearchResult<Shared.Contract.IEntityTranslation>> {
            return this.getPromise('HospitalisationReasons', query);
        }
    }

    remeCareSharedModule.service('masterdataSearchSvc', MasterdataSearchService);
}
