namespace RemeCare.Shared.Contract.Code {
    export enum CareRequestFilterTypeCode {
        HandleByMe = 1,
        HandleByOthers = 2,
        Final = 3,
        Cancelled = 4,
        OnGoing = 5,
        HandleByMyTeams = 6,
        RequestedByMe = 7
    }
}
