var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Contract;
        (function (Contract) {
            var Code;
            (function (Code) {
                var BoxplotDataType;
                (function (BoxplotDataType) {
                    BoxplotDataType[BoxplotDataType["Minimum"] = 1] = "Minimum";
                    BoxplotDataType[BoxplotDataType["FirstQuartile"] = 2] = "FirstQuartile";
                    BoxplotDataType[BoxplotDataType["Median"] = 3] = "Median";
                    BoxplotDataType[BoxplotDataType["LastQuartile"] = 4] = "LastQuartile";
                    BoxplotDataType[BoxplotDataType["Maximum"] = 5] = "Maximum";
                    BoxplotDataType[BoxplotDataType["Mean"] = 6] = "Mean"; // Only for UI purposes, not needed in back-end
                })(BoxplotDataType = Code.BoxplotDataType || (Code.BoxplotDataType = {}));
            })(Code = Contract.Code || (Contract.Code = {}));
        })(Contract = Shared.Contract || (Shared.Contract = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
