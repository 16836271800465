module RemeCare.Shared.Framework.Model {
    export class InformedConsentVersion {
        isDraft: boolean;
        validFromDisplay: Date;
        validUntilDisplay: Date;
        validFromDate: Date;
        validUntilDate: Date;
        validityChangeRemark: string;
        version: string;

        constructor(serverObject?: Contract.Read.IInformedConsentVersion) {
            if (serverObject != null) {
                this.setIsDraft(serverObject.IsDraft);
                this.setValidity(
                    Shared.DateHelper.serverDateStringToDate(serverObject.ValidFromDate),
                    Shared.DateHelper.serverDateStringToDate(serverObject.ValidUntilDate)
                );
                this.validityChangeRemark = serverObject.ValidityChangeRemark;
                this.version = serverObject.Version;
            }
        }

        public setValidity(validFrom: Date, validUntil: Date) {
            this.validFromDate = validFrom;
            this.validUntilDate = validUntil;
            this.validFromDisplay = this.isDraft ? null : this.validFromDate;
            this.validUntilDisplay = this.isDraft ? null : this.validUntilDate;
        }

        public setIsDraft(isDraft: boolean) {
            this.isDraft = isDraft;
            this.setValidity(this.validFromDate, this.validUntilDate);
        }
    }
}
