var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Service;
            (function (Service) {
                var LoadMeasurementHttpInterceptor = /** @class */ (function () {
                    function LoadMeasurementHttpInterceptor(loadMeasuringSvc, settingsSvc) {
                        var _this = this;
                        this.loadMeasuringSvc = loadMeasuringSvc;
                        this.settingsSvc = settingsSvc;
                        this.request = function (config) {
                            if (!_this.settingsSvc.getSettings().sendLogs) {
                                return config;
                            }
                            var measuringContext = _this.loadMeasuringSvc.getMeasuringContext();
                            var measuringId = _this.loadMeasuringSvc.getMeasuringId();
                            if (measuringContext) {
                                config.headers['X-PageLoadContext'] = measuringContext;
                            }
                            if (measuringId) {
                                config.headers['X-PageLoadId'] = measuringId;
                            }
                            return config;
                        };
                    }
                    return LoadMeasurementHttpInterceptor;
                }());
                Shared.remeCareSharedModule.factory('loadMeasuringHttpInterceptor', function (loadMeasuringSvc, settingsSvc) { return new LoadMeasurementHttpInterceptor(loadMeasuringSvc, settingsSvc); });
            })(Service = Framework.Service || (Framework.Service = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
