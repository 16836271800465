module RemeCare.Shared.Framework.Service {

    export class SidebarService {

        private leftSidebarTitle: string;
        private leftSidebarIcon: string;

        setLeftSidebarTitle(title: string) {
            this.leftSidebarTitle = title;
        }

        getLeftSidebarTitle(): string {
            return this.leftSidebarTitle;
        }

        setLeftSidebarIcon(icon: string) {
            this.leftSidebarIcon = icon;
        }

        getLeftSidebarIcon(): string {
            return this.leftSidebarIcon;
        }

        hasLeftSidebar(): boolean {
            return (this.leftSidebarTitle != null && this.leftSidebarTitle !== '')
                || (this.leftSidebarIcon != null && this.leftSidebarIcon !== '');
        }

        clear(): void {
            this.leftSidebarTitle = null;
            this.leftSidebarIcon = null;
        }
    }

    remeCareSharedModule.service('sidebarSvc', SidebarService);
}