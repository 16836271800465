var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Contract;
        (function (Contract) {
            var Code;
            (function (Code) {
                var DayOfWeek;
                (function (DayOfWeek) {
                    DayOfWeek[DayOfWeek["None"] = 0] = "None";
                    DayOfWeek[DayOfWeek["Monday"] = 1] = "Monday";
                    DayOfWeek[DayOfWeek["Tuesday"] = 2] = "Tuesday";
                    DayOfWeek[DayOfWeek["Wednesday"] = 4] = "Wednesday";
                    DayOfWeek[DayOfWeek["Thursday"] = 8] = "Thursday";
                    DayOfWeek[DayOfWeek["Friday"] = 16] = "Friday";
                    DayOfWeek[DayOfWeek["Saturday"] = 32] = "Saturday";
                    DayOfWeek[DayOfWeek["Sunday"] = 64] = "Sunday";
                })(DayOfWeek = Code.DayOfWeek || (Code.DayOfWeek = {}));
                var DayOfWeekFlags = /** @class */ (function () {
                    function DayOfWeekFlags() {
                    }
                    DayOfWeekFlags.create = function () {
                        return Shared.Framework.Helper.FlaggedEnum.create(DayOfWeek, DayOfWeek.Sunday);
                    };
                    return DayOfWeekFlags;
                }());
                Code.DayOfWeekFlags = DayOfWeekFlags;
            })(Code = Contract.Code || (Contract.Code = {}));
        })(Contract = Shared.Contract || (Shared.Contract = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
