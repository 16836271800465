var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Filter;
        (function (Filter) {
            function projectFilter() {
                return function (item, property) {
                    return item != null ? item[property] : null;
                };
            }
            Shared.remeCareSharedModule.filter('project', projectFilter);
        })(Filter = Shared.Filter || (Shared.Filter = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
