namespace RemeCare.Shared.Service {
    export interface IDay {
        name: string;
        value: Shared.Contract.Code.DayOfWeek;
    }

    export class DayService {
        constructor(private $locale: ng.ILocaleService) {}

        public getDays(): IDay[] {
            const days = this.$locale.DATETIME_FORMATS.DAY;
            return [
                {
                    name: days[1],
                    value: Shared.Contract.Code.DayOfWeek.Monday,
                },
                {
                    name: days[2],
                    value: Shared.Contract.Code.DayOfWeek.Tuesday,
                },
                {
                    name: days[3],
                    value: Shared.Contract.Code.DayOfWeek.Wednesday,
                },
                {
                    name: days[4],
                    value: Shared.Contract.Code.DayOfWeek.Thursday,
                },
                {
                    name: days[5],
                    value: Shared.Contract.Code.DayOfWeek.Friday,
                },
                {
                    name: days[6],
                    value: Shared.Contract.Code.DayOfWeek.Saturday,
                },
                {
                    name: days[0],
                    value: Shared.Contract.Code.DayOfWeek.Sunday,
                },
            ];
        }

        public getWeekDayOccurences(): Array<{
            text: string;
            value: number;
        }> {
            return [
                {
                    text: 'General.First',
                    value: 1,
                },
                {
                    text: 'General.Second',
                    value: 2,
                },
                {
                    text: 'General.Third',
                    value: 3,
                },
                {
                    text: 'General.Forth',
                    value: 4,
                },
                {
                    text: 'General.Last',
                    value: 9,
                },
            ];
        }
    }

    remeCareSharedModule.factory('daySvc', $locale => new DayService($locale));
}
