module RemeCare.Shared.Framework {
    
    export class TranslationResolver {
        
        constructor(
            private $translate,
            private $translatePartialLoader,
            private $q: ng.IQService) {
        }

        setParts(keys: string[]): void {
            angular.forEach(keys, (key) => {
                this.$translatePartialLoader.addPart(key);
            });
            return this.$translate.refresh();
        }
    }

    angular.module('RemeCare.Shared').factory('translationResolver', ($translate, $translatePartialLoader, $q) => new TranslationResolver($translate, $translatePartialLoader, $q));
}