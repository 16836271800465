var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Helper;
            (function (Helper) {
                var ModalSize = /** @class */ (function () {
                    function ModalSize() {
                    }
                    ModalSize.small = 'sm';
                    ModalSize.medium = '';
                    ModalSize.large = 'lg';
                    return ModalSize;
                }());
                Helper.ModalSize = ModalSize;
                var ModalBuilderBase = /** @class */ (function () {
                    function ModalBuilderBase($uibModal) {
                        this.$uibModal = $uibModal;
                        this.modalSettings = {};
                        this.modalSettings.backdrop = 'static';
                    }
                    ModalBuilderBase.prototype.build = function () {
                        var modal = this.$uibModal.open(this.modalSettings);
                        modal.result.then(this.resultCallback, this.dismissCallback);
                        return modal;
                    };
                    ModalBuilderBase.prototype.setSmall = function () {
                        return this.setSize(ModalSize.small);
                    };
                    ModalBuilderBase.prototype.setLarge = function () {
                        return this.setSize(ModalSize.large);
                    };
                    ModalBuilderBase.prototype.setSize = function (size) {
                        this.modalSettings.size = size;
                        return this;
                    };
                    ModalBuilderBase.prototype.setResultCallBack = function (callback) {
                        this.resultCallback = callback;
                        return this;
                    };
                    ModalBuilderBase.prototype.setDismissCallBack = function (callback) {
                        this.dismissCallback = callback;
                        return this;
                    };
                    return ModalBuilderBase;
                }());
                var ComponentModalBuilder = /** @class */ (function (_super) {
                    __extends(ComponentModalBuilder, _super);
                    function ComponentModalBuilder($uibModal, componentName) {
                        var _this = _super.call(this, $uibModal) || this;
                        _this.componentName = componentName;
                        return _this;
                    }
                    ComponentModalBuilder.prototype.build = function () {
                        var componentName = this.snakeCase(this.componentName);
                        var template = "<" + componentName;
                        if (this.bindings) {
                            for (var key in this.bindings) {
                                if (this.bindings.hasOwnProperty(key)) {
                                    template = template + " " + this.snakeCase(key) + "=\"$ctrl.bindings." + key + "\"";
                                }
                            }
                        }
                        template = template + " $close=\"$close(result)\" $dismiss=\"$dismiss(result)\"></" + componentName + ">";
                        this.modalSettings.template = template;
                        var that = this;
                        this.modalSettings.controller = [
                            function () {
                                this.bindings = that.bindings;
                            }
                        ];
                        this.modalSettings.controllerAs = '$ctrl';
                        return _super.prototype.build.call(this);
                    };
                    ComponentModalBuilder.prototype.setBindings = function (bindings) {
                        this.bindings = bindings;
                        return this;
                    };
                    ComponentModalBuilder.prototype.setResultCallBack = function (callback) {
                        return _super.prototype.setResultCallBack.call(this, callback);
                    };
                    ComponentModalBuilder.prototype.setDismissCallBack = function (callback) {
                        return _super.prototype.setDismissCallBack.call(this, callback);
                    };
                    ComponentModalBuilder.prototype.setSmall = function () {
                        return _super.prototype.setSmall.call(this);
                    };
                    ComponentModalBuilder.prototype.setLarge = function () {
                        return _super.prototype.setLarge.call(this);
                    };
                    ComponentModalBuilder.prototype.setSize = function (size) {
                        return _super.prototype.setSize.call(this, size);
                    };
                    ComponentModalBuilder.prototype.snakeCase = function (name) {
                        return name.replace(/[A-Z]/g, function (letter, pos) {
                            return (pos ? '-' : '') + letter.toLowerCase();
                        });
                    };
                    return ComponentModalBuilder;
                }(ModalBuilderBase));
                Helper.ComponentModalBuilder = ComponentModalBuilder;
                var ModalBuilder = /** @class */ (function (_super) {
                    __extends(ModalBuilder, _super);
                    function ModalBuilder($rootScope, $uibModal) {
                        var _this = _super.call(this, $uibModal) || this;
                        _this.$rootScope = $rootScope;
                        return _this;
                    }
                    ModalBuilder.prototype.setController = function (controller) {
                        this.modalSettings.controller = controller;
                        return this;
                    };
                    ModalBuilder.prototype.setResolve = function (resolveItems) {
                        this.modalSettings.resolve = resolveItems;
                        return this;
                    };
                    ModalBuilder.prototype.setResultCallBack = function (callback) {
                        return _super.prototype.setResultCallBack.call(this, callback);
                    };
                    ModalBuilder.prototype.setDismissCallBack = function (callback) {
                        return _super.prototype.setDismissCallBack.call(this, callback);
                    };
                    ModalBuilder.prototype.setScope = function (scope) {
                        var modalScope = (this.modalSettings.scope || this.$rootScope.$new(true));
                        for (var key in scope) {
                            if (scope.hasOwnProperty(key)) {
                                modalScope[key] = scope[key];
                            }
                        }
                        this.modalSettings.scope = modalScope;
                        return this;
                    };
                    ModalBuilder.prototype.setSmall = function () {
                        return _super.prototype.setSmall.call(this);
                    };
                    ModalBuilder.prototype.setLarge = function () {
                        return _super.prototype.setLarge.call(this);
                    };
                    ModalBuilder.prototype.setSize = function (size) {
                        return _super.prototype.setSize.call(this, size);
                    };
                    ModalBuilder.prototype.setTemplate = function (template) {
                        this.modalSettings.template = template;
                        this.modalSettings.templateUrl = null;
                        return this;
                    };
                    ModalBuilder.prototype.setTemplateUrl = function (templateUrl) {
                        this.modalSettings.templateUrl = templateUrl;
                        this.modalSettings.template = null;
                        return this;
                    };
                    return ModalBuilder;
                }(ModalBuilderBase));
                Helper.ModalBuilder = ModalBuilder;
                var ModalBuilderFactory = /** @class */ (function () {
                    function ModalBuilderFactory($rootScope, $uibModal) {
                        this.$rootScope = $rootScope;
                        this.$uibModal = $uibModal;
                    }
                    ModalBuilderFactory.prototype.createComponentModalBuilder = function (componentName) {
                        return new ComponentModalBuilder(this.$uibModal, componentName);
                    };
                    /** @deprecated use createComponentModalBuilder */
                    ModalBuilderFactory.prototype.createModalBuilder = function () {
                        return new ModalBuilder(this.$rootScope, this.$uibModal);
                    };
                    ModalBuilderFactory.prototype.showSelectorAsync = function (items, selectors, separator, title, modalSize) {
                        var _this = this;
                        return new Promise(function (resolve, reject) {
                            var size = modalSize != null ? modalSize : ModalSize.small;
                            _this.createModalBuilder()
                                .setTemplateUrl('views/buttonSelector.html')
                                .setSize(size)
                                .setScope({
                                items: items,
                                selectors: selectors,
                                separator: separator,
                                title: title
                            })
                                .setResultCallBack(function (item) {
                                resolve(item);
                            })
                                .setDismissCallBack(function () {
                                reject();
                            })
                                .build();
                        });
                    };
                    /** @deprecated use showSelectorAsync */
                    ModalBuilderFactory.prototype.showSelector = function (items, selectors, separator, title, modalSize) {
                        var deferred = jQuery.Deferred();
                        var size = modalSize != null ? modalSize : ModalSize.small;
                        this.createModalBuilder()
                            .setTemplateUrl('views/buttonSelector.html')
                            .setSize(size)
                            .setScope({
                            items: items,
                            selectors: selectors,
                            separator: separator,
                            title: title
                        })
                            .setResultCallBack(function (item) {
                            deferred.resolve(item);
                        })
                            .setDismissCallBack(function () {
                            deferred.reject();
                        })
                            .build();
                        return deferred;
                    };
                    ModalBuilderFactory.prototype.showSearchModalAsync = function (title, searchCriteriaTemplate, searchFunction, columns, detailsTemplate, detailsHeight) {
                        var _this = this;
                        return new Promise(function (resolve) {
                            _this.createModalBuilder()
                                .setTemplateUrl('views/search/searchModal.html')
                                .setSize(ModalSize.large)
                                .setScope({
                                searchInfo: {
                                    title: title,
                                    searchCriteriaSrc: searchCriteriaTemplate,
                                    searchFunction: searchFunction,
                                    columns: columns,
                                    detailsTemplate: detailsTemplate,
                                    detailsHeight: detailsHeight
                                }
                            })
                                .setResultCallBack(function (r) { return resolve(r); })
                                .build();
                        });
                    };
                    ModalBuilderFactory.prototype.showMultiSelectSearchModalAsync = function (title, searchCriteriaTemplate, searchFunction, columns, idField, currentResults, progressBar) {
                        var _this = this;
                        return new Promise(function (resolve) {
                            _this.createModalBuilder()
                                .setTemplateUrl('views/search/multiSelectSearchModal.html')
                                .setController('multiSelectSearchModalCtrl')
                                .setSize(ModalSize.large)
                                .setScope({
                                searchInfo: {
                                    title: title,
                                    searchCriteriaSrc: searchCriteriaTemplate,
                                    searchFunction: searchFunction,
                                    columns: columns,
                                    idField: idField,
                                    currentResults: currentResults
                                },
                                progressBar: progressBar
                            })
                                .setResultCallBack(function (r) { return resolve(r); })
                                .build();
                        });
                    };
                    return ModalBuilderFactory;
                }());
                Helper.ModalBuilderFactory = ModalBuilderFactory;
                Shared.remeCareSharedModule.service('modalBuilderFactory', ModalBuilderFactory);
            })(Helper = Framework.Helper || (Framework.Helper = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
