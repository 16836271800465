module RemeCare.Shared.Framework.Model {
    import ITranslationParameters = Shared.Contract.ITranslationParameters;

    export class InformedConsentTranslation {
        title: string;
        content: string;
        questions: InformedConsentQuestionTranslation[];
        culture: string;

        constructor(
            culture: string,
            informedConsentTranslation?: Contract.Read.IInformedConsentTranslation,
            informedConsentQuestions?: Contract.Read.IInformedConsentQuestion[]
        ) {
            this.culture = culture;
            if (informedConsentTranslation != null) {
                this.title = informedConsentTranslation.Title;
                this.content = informedConsentTranslation.Content;
                this.culture = informedConsentTranslation.Culture;
            } else {
                this.culture = culture;
            }

            this.questions = [];
            if (informedConsentQuestions) {
                informedConsentQuestions.forEach((question: Contract.Read.IInformedConsentQuestion) => {
                    const translation = _.find(question.Translations, t => t.Culture == this.culture);
                    if (translation) {
                        this.questions.push(
                            new InformedConsentQuestionTranslation(question.Sequence, translation.Name)
                        );
                    }
                });

                this.questions = _.sortBy(this.questions, q => q.sequence);
            }
        }
    }
}
