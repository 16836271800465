var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Filter;
        (function (Filter) {
            function delimitedDisplayFilter() {
                return function (items, delimiter) {
                    var result;
                    if (items == null || items.length === 0) {
                        result = '';
                    }
                    else {
                        delimiter = delimiter || ' | ';
                        items = angular.copy(items);
                        var init = items.shift();
                        var initText = init != null ? init.Text : '';
                        result = _(items).reduce(function (memo, category) {
                            var categoryText = category != null ? category.Text : '';
                            return memo + delimiter + categoryText;
                        }, initText);
                    }
                    return result;
                };
            }
            angular.module('RemeCare.Shared').filter('delimitedDisplay', delimitedDisplayFilter);
        })(Filter = Shared.Filter || (Shared.Filter = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
