module RemeCare.Shared.Framework.Service {

    interface IRequestConfig extends ng.IRequestConfig, Framework.IRequestShortcutConfig {
        isRetry: boolean;
    }

    class AuthHttpInterceptor implements ng.IHttpInterceptor {

        constructor(
            private readonly $injector,
            private readonly $log: ng.ILogService) {
        }

        public responseError = (rejection: ng.IHttpPromiseCallbackArg<any>) => this.respErr(rejection);

        private async respErr(rejection: ng.IHttpPromiseCallbackArg<any>): Promise<any> {
            if (rejection.status === 401) {
                const config = rejection.config as IRequestConfig;
                if (config.ignoreUnauthorizedError) {
                    return Promise.reject(rejection);
                }
                const signInUrl = rejection.headers('X-SignInUrl');
                if (signInUrl) {
                    if (config && !config.isRetry) {
                        config.isRetry = true;
                        const $http = this.$injector.get('$http') as ng.IHttpService;
                        try {
                            await this.createRefreshFrame(signInUrl);
                            return await $http(config);
                        } catch (e) {
                            return Promise.reject(e);
                        }
                    } else {
                        this.$log.error(`Failed to retrieve ${config.url} a second time due to authorization issues. Reloading page.`);
                        window.location.href = signInUrl;
                    }
                }
            }
            return Promise.reject(rejection);
        }

        private createRefreshFrame(src: string): Promise<void> {
            return new Promise((resolve) => {

                const iframe = angular.element('<iframe></iframe>');
                (iframe[0] as HTMLIFrameElement).name = 'refreshFrame';
                iframe.hide();
                iframe.appendTo('body');
                iframe.attr('src', src);

                // listen to load event so we can close the frame when the page does not redirect
                // (this is teh case when re-authentication was unsuccessful)
                $(iframe).on('load', () => {
                    iframe.remove();
                    resolve();
                });
            });
        }
    }

    remeCareSharedModule.factory('authHttpInterceptor',
        (
            $injector,
            $log: ng.ILogService
        ) => new AuthHttpInterceptor(
            $injector,
            $log
        ));
}