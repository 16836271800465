namespace RemeCare.Shared.Framework.Directive {
    class RemeCareWarningController implements ng.IComponentController {
        public $onInit(): void {}
    }

    remeCareSharedModule.component('rcWarning', {
        controller: RemeCareWarningController,
        templateUrl: 'framework/directives/controls/warning/warning.html',
        bindings: {
            message: '@',
        },
    });
}
