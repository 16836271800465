module RemeCare.Shared.Framework.InformedConsent {
    import PatientInformedConsent = Shared.Framework.Model.PatientInformedConsent;

    class PatientInformedConsentsController implements ng.IComponentController {
        public grid: Shared.Framework.Grid.Grid<Shared.Contract.Read.IPatientInformedConsentSummary>;
        public informedConsents: Shared.Contract.Read.IPatientInformedConsentSummary[];
        public acceptedInformedConsents: Shared.Contract.Read.IPatientInformedConsentSummary[];
        public informedConsentsToAccept: Shared.Contract.Read.IPatientInformedConsentSummary[];
        public informedConsent: Framework.Model.PatientInformedConsent;
        public patientId: Shared.Contract.Guid;

        constructor(
            private readonly $dialog: Shared.Service.DialogService,
            private readonly $translate: ng.translate.ITranslateService,
            private readonly gridBuilderSvc: Framework.Grid.GridBuilderFactory,
            private readonly $state: ng.ui.IStateService,
            private readonly $stateParams,
            private readonly informedConsentSvc: Framework.Service.InformedConsentService,
            private readonly authservice: Framework.AuthService,
            private readonly toaster: Toaster,
            private readonly patientInformedConsentService: Framework.Service.PatientInformedConsentService
        ) {}

        public $onInit(): void {
            this.buildGrid();
            if (!this.patientInformedConsentService.shouldCheckPatientInformedConsents()) {
                this.goToNextState();
                return;
            }

            this.patientId = this.authservice.getClaim(ClaimTypes.partyRoleId);
            this.loadPatientInformedConsents();
        }

        public refuse(): void {
            this.$dialog
                .warningMessageBox(
                    this.$translate.instant('Views.InformedConsent.RefuseTitle'),
                    this.$translate.instant('Views.InformedConsent.RefuseWarning'),
                    [
                        { result: 'cancel', label: this.$translate.instant('General.Cancel'), cssClass: 'btn-default' },
                        {
                            result: 'yes',
                            label: this.$translate.instant('Views.InformedConsent.AcceptRefuse'),
                            cssClass: 'btn-primary'
                        }
                    ]
                )
                .then(async result => {
                    if (result === 'yes') {
                        try {
                            await this.informedConsentSvc.saveInformedConsent(
                                this.patientId,
                                this.informedConsent.informedConsentId,
                                false,
                                null
                            );
                            this.informedConsentsToAccept = _.filter(
                                this.informedConsentsToAccept,
                                ic => ic.InformedConsentId !== this.informedConsent.informedConsentId
                            );
                            this.grid.setData(this.informedConsentsToAccept);
                            this.informedConsent = null;
                            if (this.acceptedInformedConsents.length < 1 && this.informedConsentsToAccept.length < 1) {
                                this.authservice.signOut();
                            } else if (this.informedConsentsToAccept.length < 1) {
                                this.goToNextState();
                            } else {
                                const patientInformedConsentReadModel = await this.informedConsentSvc.getPatientInformedConsent(
                                    this.patientId,
                                    this.informedConsentsToAccept[0].InformedConsentId
                                );
                                this.informedConsent = new PatientInformedConsent(patientInformedConsentReadModel);
                            }
                        } catch (e) {
                            this.toaster.error(e);
                        }
                    }
                });
        }

        public async accept(): Promise<void> {
            if (_.some(this.informedConsent.questions, q => q.accepted === false)) {
                this.$dialog.warningMessageBox(
                    null,
                    this.$translate.instant('Views.InformedConsent.AcceptAllQuestions'),
                    [{ result: 'Ok', label: this.$translate.instant('General.Ok'), cssClass: 'btn-default' }]
                );
            } else {
                try {
                    await this.informedConsentSvc.saveInformedConsent(
                        this.patientId,
                        this.informedConsent.informedConsentId,
                        true,
                        null
                    );
                    this.acceptedInformedConsents.push(
                        _.find(
                            this.informedConsentsToAccept,
                            ic => ic.InformedConsentId === this.informedConsent.informedConsentId
                        )
                    );
                    this.informedConsentsToAccept = _.filter(
                        this.informedConsentsToAccept,
                        ic => ic.InformedConsentId !== this.informedConsent.informedConsentId
                    );
                    this.grid.setData(this.informedConsentsToAccept);
                    this.informedConsent = null;
                    if (this.informedConsentsToAccept.length < 1) {
                        this.goToNextState();
                    } else {
                        const patientInformedConsentReadModel = await this.informedConsentSvc.getPatientInformedConsent(
                            this.patientId,
                            this.informedConsentsToAccept[0].InformedConsentId
                        );
                        this.informedConsent = new PatientInformedConsent(patientInformedConsentReadModel);
                    }
                } catch (e) {
                    this.toaster.error(e);
                }
            }
        }

        private async loadPatientInformedConsents(): Promise<void> {
            try {
                const informedConsentsSearchResult = await this.informedConsentSvc.getInformedConsentSummary(
                    this.patientId
                );
                this.informedConsents = informedConsentsSearchResult.Items;
                this.acceptedInformedConsents = _.filter(
                    this.informedConsents,
                    ic => ic.Status === Shared.Contract.Code.PatientInformedConsentStatusCode.Accepted
                );
                this.informedConsentsToAccept = _.filter(
                    this.informedConsents,
                    ic => ic.Status !== Shared.Contract.Code.PatientInformedConsentStatusCode.Accepted
                );
                this.grid.setData(this.informedConsentsToAccept);
                if (this.informedConsentsToAccept.length > 0) {
                    const patientInformedConsentReadModel = await this.informedConsentSvc.getPatientInformedConsent(
                        this.patientId,
                        this.informedConsentsToAccept[0].InformedConsentId
                    );
                    this.informedConsent = new PatientInformedConsent(patientInformedConsentReadModel);
                } else {
                    this.goToNextState();
                }
            } catch (e) {
                this.toaster.error(e);
            }
        }

        private buildGrid(): void {
            this.grid = this.gridBuilderSvc
                .createGridBuilder<Shared.Contract.Read.IPatientInformedConsentSummary>()
                .addColumn('TherapyName', 'General.Therapy')
                .addColumn('Version', 'General.Version')
                .addColumn('Title', 'General.Name')
                .build();
        }

        private goToNextState(): void {
            this.patientInformedConsentService.allPatientInformedConsentsChecked(this.acceptedInformedConsents.length);
            if (this.$stateParams.redirect && this.$stateParams.redirect !== 'checkInformedConsent') {
                this.$state.go(this.$stateParams.redirect, this.$stateParams.params);
            } else {
                this.$state.go('home');
            }
        }
    }

    remeCareSharedModule.component('rcPatientInformedConsents', {
        controller: PatientInformedConsentsController,
        templateUrl: 'framework/informedConsent/patientInformedConsents/patientInformedConsents.html',
        bindings: {
            patientId: '<'
        }
    });
}
