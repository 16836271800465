namespace RemeCare.Shared.Service {
    import ISearchResult = Shared.Contract.ISearchResult;
    import IMedication = Contract.Read.IMedication;
    import SearchMedicationQuery = Contract.Read.Query.ISearchMedicationQuery;

    export class MedicationSearchSvc extends Shared.Framework.ApiServiceBase {
        public static $inject = Shared.Framework.ApiServiceBase.injectionParameters;

        /** @deprecated */
        public searchMedications(query: SearchMedicationQuery): ng.IHttpPromise<ISearchResult<IMedication>> {
            const url = 'Medication';
            return super.get(url, query, 'medications');
        }

        public searchMedicationsAsync(query: SearchMedicationQuery): Promise<ISearchResult<IMedication>> {
            const url = 'Medication';
            return super.getPromise(url, query, 'medications');
        }

        /** @deprecated */
        public getMedications(productIds: Array<Shared.Contract.Guid>): ng.IHttpPromise<Array<IMedication>> {
            const url = 'Medication';
            const query = <SearchMedicationQuery>{
                pageSize: productIds.length,
                productIds: productIds
            };
            return this.get(url, query, null, null, r => r.Items);
        }

        public getMedicationsAsync(productIds: Shared.Contract.Guid[]): Promise<IMedication[]> {
            const url = 'Medication';
            const query: SearchMedicationQuery = {
                pageSize: productIds.length,
                productIds: productIds,
            };
            return this.getPromise(url, query, null, null, r => r.Items);
        }

        /** @deprecated */
        public getMedication(productId: Shared.Contract.Guid): ng.IHttpPromise<IMedication> {
            const url = `Medication/${productId}`;
            return this.get(url);
        }

        public getMedicationAsync(productId: Shared.Contract.Guid): Promise<IMedication> {
            const url = `Medication/${productId}`;
            return this.getPromise(url);
        }
    }

    remeCareSharedModule.service('medicationSearchSvc', MedicationSearchSvc);
}
