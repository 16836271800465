/// <reference path="../controllers/controllerBase.ts"/>

module RemeCare.Shared.Directive {
    export interface IColumn {
        field: string;
        displayName: string;
        options?: any;
    }

    export class SearchController<T> implements ng.IComponentController {
        grid: Shared.Framework.Grid.Grid<T>;
        isBusy: boolean;
        columns: Array<IColumn>;
        searchDetails: string;
        progressBar: string;
        searchDetailsHeight: number;
        searchFunction: () => (q: Contract.ISearchQuery) => Promise<Contract.ISearchResult<T>>;
        resultCallback: () => (result: T) => any;

        constructor(
            private toaster: Shared.Framework.Toaster,
            private gridBuilderSvc: Framework.Grid.GridBuilderFactory
        ) {}

        $onInit(): void {
            this.buildGrid();
        }

        private search(): void {
            this.grid.pagingOptions.currentPage = 1;
            this.grid.search();
        }

        private async executeSearch(
            page: number,
            pageSize: number,
            sortField: string,
            sortDirection: string,
            criteria: any
        ): Promise<Contract.ISearchResult<T>> {
            var query = <Contract.ISearchQuery>criteria || <Contract.ISearchQuery>{};
            query.page = page;
            query.pageSize = pageSize;
            query.sortField = sortField;
            query.sortOrder = sortDirection;

            this.isBusy = true;

            try {
                const r = await this.searchFunction()(query);
                this.isBusy = false;
                return r;
            } catch (e) {
                this.toaster.error(e);
                this.isBusy = false;
            }
        }

        private buildGrid(): void {
            var gridBuilder = this.gridBuilderSvc.createGridBuilder<T>(
                (page, pageSize, sortField, sortDirection, criteria) =>
                    this.executeSearch(page, pageSize, sortField, sortDirection, criteria)
            );
            _(this.columns).each(c => {
                gridBuilder = gridBuilder.addColumn(c.field, c.displayName, c.options);
            });
            if (this.searchDetails) {
                gridBuilder = gridBuilder.setRowDetailsTemplate(this.searchDetails, this.searchDetailsHeight);
            }
            this.grid = gridBuilder.addSelectButtonColumn(r => this.resultCallback()(r)).build();
        }
    }

    remeCareSharedModule.component('rcSearch', {
        controller: SearchController,
        bindings: {
            columns: '=',
            searchDetails: '=',
            searchDetailsHeight: '=',
            searchFunction: '&',
            resultCallback: '&',
            progressBar: '@?',
            grid: '='
        },
        template: [
            '$element',
            '$attrs',
            '$templateCache',
            ($element, $attrs: ng.IAttributes, $templateCache: ng.ITemplateCacheService) => {
                var template = $templateCache.get<string>('views/search/search.html');
                if (!('searchDetails' in $attrs)) {
                    template = template.replace('expandable', '');
                }
                return template;
            }
        ],
        transclude: true
    });
}
