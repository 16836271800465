module RemeCare.Shared.Contract.Code {

    export enum MonitoringPartDataSourceType {
        RegistrationValue = 1,
        CarePlanAnamnesisValue = 2,
        ObjectValue = 3,
        ThresholdValue = 4,
        MediaLibQuestion = 5,
        ExternalSourceData = 6
    }
}