var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Service;
            (function (Service) {
                var LanguageService = /** @class */ (function () {
                    function LanguageService($log, $translate, $http, $cacheFactory, $cookies, $state, $rootScope, tmhDynamicLocale) {
                        this.$log = $log;
                        this.$translate = $translate;
                        this.$http = $http;
                        this.$cacheFactory = $cacheFactory;
                        this.$cookies = $cookies;
                        this.$state = $state;
                        this.$rootScope = $rootScope;
                        this.tmhDynamicLocale = tmhDynamicLocale;
                    }
                    LanguageService.prototype.setLang = function (langKey, needsReload) {
                        var _this = this;
                        var current = this.$translate.preferredLanguage();
                        if (current === langKey)
                            return;
                        var $httpDefaultCache = this.$cacheFactory.get('$http');
                        $httpDefaultCache.removeAll();
                        this.$translate.preferredLanguage(langKey);
                        this.$translate.use(langKey).then(function () {
                            if (needsReload) {
                                _this.$log.debug('Language switched and asked to reload');
                                _this.$state.reload();
                            }
                        });
                        this.$http.defaults.headers.common['Accept-Language'] = langKey;
                        this.$cookies.put('applicationLanguage', langKey, {
                            expires: 'Fri, 31 Dec 9999 23:59:59 GMT'
                        });
                        this.tmhDynamicLocale.set(langKey);
                        this.$rootScope.$broadcast('applicationLanguageChanged');
                    };
                    return LanguageService;
                }());
                Service.LanguageService = LanguageService;
                Shared.remeCareSharedModule.service('languageSvc', LanguageService);
            })(Service = Framework.Service || (Framework.Service = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
