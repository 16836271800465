var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Directive;
            (function (Directive) {
                var SpinnerController = /** @class */ (function () {
                    function SpinnerController($scope, spinnerSvc) {
                        this.$scope = $scope;
                        this.spinnerSvc = spinnerSvc;
                        this.$onInit();
                    }
                    SpinnerController.prototype.$onInit = function () {
                        this.spinner = this.constructApi();
                        this.spinnerSvc.register(this.spinner);
                    };
                    SpinnerController.prototype.$onDestroy = function () {
                        this.spinnerSvc.unregister(this.spinner);
                    };
                    SpinnerController.prototype.constructApi = function () {
                        var _this = this;
                        return {
                            name: this.$scope.name,
                            group: this.$scope.group,
                            show: function () {
                                _this.$scope.show = true;
                                if (_this.$scope.viewStateChanged) {
                                    _this.$scope.viewStateChanged(_this.$scope.show);
                                }
                            },
                            hide: function () {
                                _this.$scope.show = false;
                                if (_this.$scope.viewStateChanged) {
                                    _this.$scope.viewStateChanged(_this.$scope.show);
                                }
                            },
                            toggle: function () {
                                _this.$scope.show = !_this.$scope.show;
                                if (_this.$scope.viewStateChanged) {
                                    _this.$scope.viewStateChanged(_this.$scope.show);
                                }
                            }
                        };
                    };
                    SpinnerController.$inject = ['$scope', 'spinnerSvc'];
                    return SpinnerController;
                }());
                var SpinnerDirective = /** @class */ (function () {
                    function SpinnerDirective() {
                        this.restrict = 'E';
                        this.replace = true;
                        this.transclude = true;
                        this.scope = {
                            name: '@?',
                            group: '@?',
                            show: '=?',
                            imgSrc: '@?',
                            size: '='
                        };
                        this.template = [
                            '<div>',
                            '  <i ng-show="show" class="spinner fa fa-spinner fa-pulse {{ size ? \'fa-\' + size + \'x\' : \'fa-2x\'}} fa-fw" ></i>',
                            '</div>'
                        ].join('');
                        this.link = function (scope, element, attrs, ctrl, transclude) {
                            transclude(scope.$parent, function (clone) {
                                element.append(clone);
                            });
                        };
                        this.controller = SpinnerController;
                    }
                    return SpinnerDirective;
                }());
                Shared.remeCareSharedModule.directive('rcSpinner', function () { return new SpinnerDirective(); });
                var FullPageSpinnerDirective = /** @class */ (function () {
                    function FullPageSpinnerDirective() {
                        this.restrict = 'E';
                        this.replace = true;
                        this.transclude = true;
                        this.scope = {
                            name: '@?',
                            group: '@?',
                            show: '=?',
                            imgSrc: '@?',
                            register: '@?'
                        };
                        this.template = [
                            '<div>',
                            '  <div ng-show="show" class="loading" >',
                            '    <i class="spinner fa fa-spinner fa-pulse fa-5x fa-fw" ></i>',
                            '  </div>',
                            '</div>'
                        ].join('');
                        this.link = function (scope, element, attrs, ctrl, transclude) {
                            transclude(scope.$parent, function (clone) {
                                element.append(clone);
                            });
                        };
                        this.controller = SpinnerController;
                    }
                    return FullPageSpinnerDirective;
                }());
                Shared.remeCareSharedModule.directive('rcFullPageSpinner', function () { return new FullPageSpinnerDirective(); });
                var BlockingSpinnerDirective = /** @class */ (function () {
                    function BlockingSpinnerDirective() {
                        this.restrict = 'E';
                        this.replace = true;
                        this.transclude = true;
                        this.scope = {
                            name: '@?',
                            group: '@?',
                            show: '=?',
                            imgSrc: '@?',
                            register: '@?'
                        };
                        this.templateUrl = 'views/spinner/blockingSpinner.html';
                        this.link = function (scope, element, attrs, ctrl, transclude) {
                            transclude(scope.$parent, function (clone) {
                                element.append(clone);
                            });
                            scope.height = function () {
                                return element.height() + "px";
                            };
                            scope.width = function () {
                                return element.width() + "px";
                            };
                        };
                        this.controller = SpinnerController;
                    }
                    return BlockingSpinnerDirective;
                }());
                Shared.remeCareSharedModule.directive('rcBlockingSpinner', function () { return new BlockingSpinnerDirective(); });
                var LoaderDirective = /** @class */ (function () {
                    function LoaderDirective() {
                        this.restrict = 'E';
                        this.replace = true;
                        this.scope = {
                            name: '@?',
                            group: '@?'
                        };
                        this.template = [
                            '<div ng-show="show" class="loading" >',
                            '  <i class="spinner fa fa-spinner fa-pulse fa-5x fa-fw" ></i>',
                            '</div>'
                        ].join('');
                        this.controller = SpinnerController;
                    }
                    return LoaderDirective;
                }());
                Shared.remeCareSharedModule.directive('rcLoader', function () { return new LoaderDirective(); });
                var LoadingButtonDirective = /** @class */ (function () {
                    function LoadingButtonDirective() {
                        this.restrict = 'A';
                        this.scope = {
                            name: '@rcLoadingButton'
                        };
                        this.controller = SpinnerController;
                        this.link = function (scope, element, attrs) {
                            var template = '<i ng-show="show" class="fa fa-spinner fa-pulse" style="margin-right: 5px;" />';
                            var isShowing;
                            scope.viewStateChanged = function (show) {
                                if (show) {
                                    if (isShowing) {
                                        return;
                                    }
                                    isShowing = true;
                                    attrs.$set('disabled', 'disabled');
                                    element.addClass('disabled');
                                    var height = element.height() - 6;
                                    var width = element.width() - 6;
                                    element.prepend(template);
                                    var image = element.children().first();
                                    var size = Math.min(height, width);
                                    image.height(size);
                                }
                                else {
                                    if (!isShowing) {
                                        return;
                                    }
                                    isShowing = false;
                                    element.removeAttr('disabled');
                                    element.removeClass('disabled');
                                    element.children().first().remove();
                                }
                            };
                        };
                    }
                    return LoadingButtonDirective;
                }());
                Shared.remeCareSharedModule.directive('rcLoadingButton', function () { return new LoadingButtonDirective(); });
            })(Directive = Framework.Directive || (Framework.Directive = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
