module RemeCare.Shared.Framework.Helper {

    export class ButtonSelector {

        constructor(
            private modalBuilderFactory: ModalBuilderFactory) {

        }

        select<T>(items: Array<T>, selectors: Array<string>, separator: string, title: string, modalSize?: string): JQueryPromise<T> {
            var deferred = jQuery.Deferred<T>();
            var size = modalSize != null ? modalSize : Helper.ModalSize.small; 
            this.modalBuilderFactory
                .createModalBuilder<T>()
                .setTemplateUrl('views/buttonSelector.html')
                .setSize(size)
                .setScope({
                    items: items,
                    selectors: selectors,
                    separator: separator,
                    title: title
                })
                .setResultCallBack((item: T) => {
                    deferred.resolve(item);
                })
                .setDismissCallBack(() => {
                    deferred.reject();
                })
                .build();
            return deferred;
        }
    }

    angular.module('RemeCare.Shared').factory('buttonSelector', (modalBuilderFactory) => new ButtonSelector(modalBuilderFactory));
} 