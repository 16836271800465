module RemeCare.Shared.Framework.Service {

    export class LanguageService {

        constructor(
            private readonly $log: ng.ILogService,
            private readonly $translate: ng.translate.ITranslateService,
            private readonly $http: ng.IHttpService,
            private readonly $cacheFactory: ng.ICacheFactoryService,
            private readonly $cookies: ng.cookies.ICookiesService,
            private readonly $state: ng.ui.IStateService,
            private readonly $rootScope: ng.IRootScopeService,
            private readonly tmhDynamicLocale) {
        }

        public setLang(langKey:string, needsReload:boolean): void {
            const current = this.$translate.preferredLanguage();
            if (current === langKey) return;
            const $httpDefaultCache = this.$cacheFactory.get('$http');
            $httpDefaultCache.removeAll();
            this.$translate.preferredLanguage(langKey);
            this.$translate.use(langKey).then(() => {
                if (needsReload) {
                    this.$log.debug('Language switched and asked to reload');
                    this.$state.reload();
                }
            });
            this.$http.defaults.headers.common['Accept-Language'] = langKey;
            this.$cookies.put('applicationLanguage', langKey, {
                expires: 'Fri, 31 Dec 9999 23:59:59 GMT'
            });
            this.tmhDynamicLocale.set(langKey);
            this.$rootScope.$broadcast('applicationLanguageChanged');
        }
    }
    remeCareSharedModule.service('languageSvc', LanguageService);
}