/// <reference path="../models/careRequestConfiguration.ts"/>

namespace RemeCare.Shared.Framework.Factory {
    import CareRequestPart = Framework.Model.CareRequestPart;
    import CareRequestorDefinition = Framework.Model.CareRequestorDefinition;
    import CareRequestRoleDefinition = Framework.Model.CareRequestRoleDefinition;
    import QualitativeAnamnesisDefinition = Framework.Model.QualitativeAnamnesisDefinition;
    import QuantitativeAnamnesisDefinition = Framework.Model.QuantitativeAnamnesisDefinition;
    import OptionDefinition = Framework.Model.OptionDefinition;
    import DateDefinition = Framework.Model.DateDefinition;
    import StringDefinition = Framework.Model.StringDefinition;
    import CareRequestDocumentDefinition = Framework.Model.CareRequestDocumentDefinition;
    import MedicationSchemaDefinition = Framework.Model.MedicationSchemaDefinition;
    import DiseaseDefinition = Framework.Model.DiseaseDefinition;

    export class CareRequestPartFactory {
        public createCareRequestPart(serverObject: Contract.Read.ICareRequestPart): CareRequestPart {
            return this.create(serverObject.Type.Id, serverObject);
        }

        public createFromType(type: Shared.Contract.IEnumTranslation): CareRequestPart {
            const part = this.create(type.Id);
            part.type = type;
            return part;
        }

        private create(
            type: Shared.Contract.Code.CareRequestPartType,
            serverObject?: Contract.Read.ICareRequestPart
        ): CareRequestPart {
            switch (type) {
                case Shared.Contract.Code.CareRequestPartType.CareRequestor:
                    return new CareRequestorDefinition(serverObject);
                case Shared.Contract.Code.CareRequestPartType.CareRequestRole:
                    return new CareRequestRoleDefinition(serverObject);
                case Shared.Contract.Code.CareRequestPartType.QualitativeAnamnesis:
                    return new QualitativeAnamnesisDefinition(serverObject);
                case Shared.Contract.Code.CareRequestPartType.QuantitativeAnamnesis:
                    return new QuantitativeAnamnesisDefinition(serverObject);
                case Shared.Contract.Code.CareRequestPartType.TherapyOption:
                    return new OptionDefinition(serverObject);
                case Shared.Contract.Code.CareRequestPartType.Date:
                    return new DateDefinition(serverObject);
                case Shared.Contract.Code.CareRequestPartType.String:
                    return new StringDefinition(serverObject);
                case Shared.Contract.Code.CareRequestPartType.RequestDocument:
                    return new CareRequestDocumentDefinition(serverObject);
                case Shared.Contract.Code.CareRequestPartType.MedicationSchemaTherapyAction:
                case Shared.Contract.Code.CareRequestPartType.MedicationSchemaOther:
                    return new MedicationSchemaDefinition(serverObject);
                case Shared.Contract.Code.CareRequestPartType.Comorbidities:
                case Shared.Contract.Code.CareRequestPartType.TreatedDiseases:
                    return new DiseaseDefinition(serverObject);
                default:
                    return new CareRequestPart(serverObject);
            }
        }
    }
}
