var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Directive;
        (function (Directive) {
            var GuidancePartStepController = /** @class */ (function () {
                // @ngInject
                function GuidancePartStepController($sce, $translate) {
                    this.$sce = $sce;
                    this.$translate = $translate;
                    this.lang = this.$translate.preferredLanguage();
                    this.sce = $sce;
                    this.init();
                }
                Object.defineProperty(GuidancePartStepController.prototype, "step", {
                    get: function () {
                        return this.stepValue;
                    },
                    set: function (value) {
                        this.stepValue = value;
                        this.init();
                    },
                    enumerable: true,
                    configurable: true
                });
                GuidancePartStepController.prototype.getTemplateUrl = function () {
                    switch (this.current.GuidanceStepTypeCode) {
                        case RemeCare.Shared.Contract.Code.GuidanceStepType.Image:
                            return 'views/guidance/imagelink.html';
                        case RemeCare.Shared.Contract.Code.GuidanceStepType.Text:
                            return 'views/guidance/text.html';
                        case RemeCare.Shared.Contract.Code.GuidanceStepType.File:
                            return 'views/guidance/file.html';
                        case RemeCare.Shared.Contract.Code.GuidanceStepType.Video:
                            return 'views/guidance/videolink.html';
                        case RemeCare.Shared.Contract.Code.GuidanceStepType.RichText:
                            return 'views/guidance/rich.html';
                        default:
                            return 'views/guidance/guidancePartStep.html';
                    }
                };
                GuidancePartStepController.prototype.$onInit = function () { };
                GuidancePartStepController.prototype.init = function () {
                    var _this = this;
                    if (this.lang != null && this.sce != null) {
                        this.current = new Shared.Framework.Model.GuidanceStepTranslation(_.find(this.step.Translations, function (t) { return t.Culture === _this.lang; }));
                        this.html = this.sce.trustAsHtml(this.current.Content);
                    }
                };
                return GuidancePartStepController;
            }());
            Shared.remeCareSharedModule.component('rcGuidancePartStep', {
                bindings: {
                    step: '=',
                },
                controller: GuidancePartStepController,
                template: '<ng-include src="$ctrl.getTemplateUrl()"/>',
            });
        })(Directive = Shared.Directive || (Shared.Directive = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
