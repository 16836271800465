module RemeCare.Bootstrap {

    export var remeBootModule = angular.module('RemeBoot', []);

    class Bootstrapper {

        public static $inject = ['appName'];

        constructor(
            private appName: string) {
        }

        public bootstrap(): ng.IPromise<void> {
            return this.getConfig();
        }

        private getConfig(): ng.IPromise<void> {
            const injector: ng.auto.IInjectorService = angular.injector(['ng']);
            const $http: ng.IHttpService = injector.get('$http') as ng.IHttpService;

            return new Shared.Framework.Helper.ConfigLoader($http)
                .loadConfig()
                .then(r => {
                    angular.module(Shared.remeCareSharedModule.name)
                        .constant('googleAnalyticsTracker', r.data.googleAnalyticsTracker)
                        .constant('baseUrl', r.data.baseUrl)
                        .constant('appConfig', r.data);
                });
        }
    }

    angular.module('RemeBoot').service('bootstrapper', Bootstrapper);

    angular.module('RemeBoot')
        .factory('httpRequestInterceptor',
        ($location: ng.ILocationService) => {
            return {
                request: (request: ng.IRequestConfig) => {
                    request.headers['X-AppStateUrl'] = $location.absUrl();
                    return request;
                }
            }
        });

    angular.module('RemeBoot')
        .config(($httpProvider) => {
            $httpProvider.interceptors.push('httpRequestInterceptor');
        });

    // create a div which is used as the root of the bootstrap app
    var appContainer = document.createElement('div');

    // in run() function you can now use the bootstrapper service and shutdown the bootstrapping app after initialization of your actual app
    angular.module('RemeBoot').run((bootstrapper: Bootstrapper, appName: string) => {
        bootstrapper.bootstrap().then(() => {
            angular.bootstrap(document.body, [appName],
                {
                    strictDi: true
                });
        });

    });

    // make sure the DOM is fully loaded before bootstrapping.
    angular.element(document).ready(() => {
        angular.bootstrap(appContainer, ['RemeBoot']);
    });
} 