var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Service;
            (function (Service) {
                var InformedConsentService = /** @class */ (function (_super) {
                    __extends(InformedConsentService, _super);
                    function InformedConsentService() {
                        return _super !== null && _super.apply(this, arguments) || this;
                    }
                    InformedConsentService.prototype.getInformedConsents = function (therapyId) {
                        var params;
                        if (therapyId != null) {
                            params = {
                                therapyId: therapyId,
                            };
                        }
                        else {
                            params = null;
                        }
                        return this.getPromise('InformedConsents', params, 'informedConsents.overview');
                    };
                    InformedConsentService.prototype.saveInformedConsentDraft = function (informedConsent) {
                        return this.postPromise('InformedConsents', informedConsent, 'informedConsent.save');
                    };
                    InformedConsentService.prototype.getInformedConsent = function (informedConsentId, draft, perspectiveDate) {
                        return this.getPromise("InformedConsents/" + informedConsentId, {
                            draft: draft,
                            perspectiveDate: perspectiveDate,
                        }, 'informedConsent');
                    };
                    InformedConsentService.prototype.approveInformedConsent = function (informedConsentId, validFrom, shouldReApprove) {
                        return this.putPromise("InformedConsents/" + informedConsentId + "/Approve", {
                            InformedConsentId: informedConsentId,
                            validFrom: validFrom,
                            shouldReApprove: shouldReApprove,
                        }, 'informedConsent.save');
                    };
                    InformedConsentService.prototype.updateInformedConsentDraft = function (informedConsent) {
                        return this.putPromise("InformedConsents/" + informedConsent.Id, informedConsent, 'informedConsent.save');
                    };
                    InformedConsentService.prototype.getInformedConsentSummary = function (patientId) {
                        return this.getPromise("Patients/" + patientId + "/InformedConsents", null, 'patientInformedConsent.summary');
                    };
                    InformedConsentService.prototype.getPatientInformedConsent = function (patientId, informedConsentId) {
                        return this.getPromise("Patients/" + patientId + "/InformedConsents/" + informedConsentId, null, 'patientInformedConsent');
                    };
                    InformedConsentService.prototype.saveInformedConsent = function (patientId, informedConsentId, accepted, remark) {
                        var params = {
                            accepted: accepted,
                            remark: remark,
                        };
                        return this.postPromise("Patients/" + patientId + "/InformedConsents/" + informedConsentId, params, 'patientInformedConsent.save');
                    };
                    InformedConsentService.prototype.revokePatientInformedConsent = function (patientId, informedConsentId, remark) {
                        var params = {
                            remark: remark,
                        };
                        return this.putPromise("Patients/" + patientId + "/InformedConsents/" + informedConsentId, params);
                    };
                    InformedConsentService.prototype.getInformedConsentsHistory = function (patientId) {
                        return this.getPromise("Patients/" + patientId + "/InformedConsents/History", null, 'patientInformedConsent.History');
                    };
                    InformedConsentService.$inject = Shared.Framework.ApiServiceBase.injectionParameters;
                    return InformedConsentService;
                }(Shared.Framework.ApiServiceBase));
                Service.InformedConsentService = InformedConsentService;
                Shared.remeCareSharedModule.service('informedConsentSvc', InformedConsentService);
            })(Service = Framework.Service || (Framework.Service = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
