module RemeCare.Shared {
    export class TranslationHelper {

        static getTranslations(dictionairy: { [culture: string]: Shared.Contract.ITranslation }, parentId?: Shared.Contract.Guid): Array<Shared.Contract.ITranslationWrite> {
            var translations: Array<Shared.Contract.ITranslationWrite> = [];
            for (var key in dictionairy) {
                if (dictionairy.hasOwnProperty(key)) {
                    var trans = dictionairy[key];
                    translations.push({
                        id: trans.Id,
                        culture: key,
                        name: trans.Name,
                        parentId: parentId != null ? parentId : trans.ParentId
                    });
                }
            }
            return translations;
        }

        static getTranslationInts(dictionairy: { [culture: string]: Shared.Contract.ITranslationInt }, parentId?: Shared.Contract.Guid): Array<Shared.Contract.ITranslationIntWrite> {
            var translations: Array<Shared.Contract.ITranslationIntWrite> = [];
            for (var key in dictionairy) {
                if (dictionairy.hasOwnProperty(key)) {
                    var trans = dictionairy[key];
                    translations.push({
                        id: trans.Id,
                        culture: key,
                        name: trans.Name,
                        parentId: parentId != null ? parentId : trans.ParentId
                    });
                }
            }
            return translations;
        }

        static getDescriptionTranslations(dictionairy: { [culture: string]: Shared.Contract.IDescriptionTranslation }, parentId?: Shared.Contract.Guid): Array<Shared.Contract.IDescriptionTranslationWrite> {
            var translations: Array<Shared.Contract.IDescriptionTranslationWrite> = [];
            for (var key in dictionairy) {
                if (dictionairy.hasOwnProperty(key)) {
                    var trans = dictionairy[key];
                    translations.push({
                        id: trans.Id,
                        culture: key,
                        name: trans.Name,
                        parentId: parentId != null ? parentId : trans.ParentId,
                        description: trans.Description
                    });
                }
            }
            return translations;
        }
    }

    remeCareSharedModule.factory('translationHelper', () => new TranslationHelper());
} 