var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Model;
            (function (Model) {
                var InformedConsentHistory = /** @class */ (function () {
                    function InformedConsentHistory(serverObjects) {
                        this.parts = [];
                        if (serverObjects != null) {
                            this.parts = _(serverObjects).map(function (so) { return new Model.InformedConsentHistoryPart(so); });
                        }
                    }
                    return InformedConsentHistory;
                }());
                Model.InformedConsentHistory = InformedConsentHistory;
            })(Model = Framework.Model || (Framework.Model = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
