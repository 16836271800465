var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Contract;
        (function (Contract) {
            var Code;
            (function (Code) {
                var TherapyActionStartType;
                (function (TherapyActionStartType) {
                    TherapyActionStartType[TherapyActionStartType["OnCarePlanStartDate"] = 1] = "OnCarePlanStartDate";
                    TherapyActionStartType[TherapyActionStartType["DelayAfterStartDate"] = 2] = "DelayAfterStartDate";
                    TherapyActionStartType[TherapyActionStartType["RelativeToRelatedAction"] = 3] = "RelativeToRelatedAction";
                    TherapyActionStartType[TherapyActionStartType["ProcessDriven"] = 4] = "ProcessDriven";
                    TherapyActionStartType[TherapyActionStartType["AdHoc"] = 5] = "AdHoc";
                })(TherapyActionStartType = Code.TherapyActionStartType || (Code.TherapyActionStartType = {}));
            })(Code = Contract.Code || (Contract.Code = {}));
        })(Contract = Shared.Contract || (Shared.Contract = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
