namespace RemeCare.Shared.Framework.Service {
    import Guid = Shared.Contract.Guid;
    import SearchResult = Shared.Contract.ISearchResult;
    import EnumTranslation = Shared.Contract.IEnumTranslation;

    export class GuidanceApiService extends Shared.Framework.ApiServiceBase {
        public static $inject = ['baseUrl', '$http', 'spinnerSvc', '$q', '$anchorScroll', '$location', '$translate'];
        public guidanceItems: RemeCare.Shared.Contract.IGuidanceItem[];
        public guidanceData: RemeCare.Shared.Contract.IGuidanceCodeData[];
        public input;
        constructor(
            protected baseUrl: string,
            protected $http: ng.IHttpService,
            protected spinnerSvc: Framework.Service.SpinnerService,
            protected $q: ng.IQService,
            private readonly $anchorScroll: ng.IAnchorScrollService,
            private readonly $location: ng.ILocationService,
            private readonly $translate: ng.translate.ITranslateService
        ) {
            super(baseUrl, $http, spinnerSvc, $q);
            this.guidanceItems = [];
            this.guidanceData = [];
            this.$location = $location;
            this.$anchorScroll = $anchorScroll;
        }

        public createGuidanceItem(id: Shared.Contract.Guid, code: string): Promise<boolean> {
            return new Promise((resolve, reject) => {
                const item: RemeCare.Shared.Contract.IGuidanceItem = {
                    Id: id,
                    Code: code,
                    Visible: false,
                    Show: false,
                    CodeData: null,
                };

                this.guidanceItems.push(item);

                this.getOrLoadGuidanceData(item.Code)
                    .then((d) => {
                        item.Visible = this.checkContent(d);
                        resolve(item.Visible);
                    })
                    .catch((o) => resolve(false));
            });
        }

        public getGuidance(code: string): RemeCare.Shared.Contract.Read.IGuidance {
            const data = _.find(this.guidanceData, (gd) => gd.Code === code);

            if (data != null) {
                return data.Data;
            }

            return null;
        }

        public getGuidanceItem(id: Shared.Contract.Guid): RemeCare.Shared.Contract.IGuidanceItem {
            return _.find(this.guidanceItems, (gd) => gd.Id === id);
        }

        public toggleItem(id: Shared.Contract.Guid, overrideValue: boolean = null): void {
            const item = _.find(this.guidanceItems, (gd) => gd.Id === id);

            item.Show = overrideValue !== null ? overrideValue : !item.Show;

            if (item.Show) {
                this.$anchorScroll.yOffset = 55;
                this.$location.hash(`gds_${id}`);

                const cId = '#gd_' + id;
                $(cId).on('slide.bs.carousel', function(e) {
                    const iframes = $('.guidance-iframe-container iframe');
                    for (let i = 0; i < iframes.length; i++) {
                        const player = iframes[i];
                        const slideType =
                            player
                                .getAttribute('src')
                                .toLowerCase()
                                .indexOf('vimeo') > 0
                                ? 'vimeo'
                                : 'other';

                        let command;
                        if (slideType === 'vimeo') {
                            command = {
                                method: 'pause',
                                value: 'true',
                            };
                        } else {
                            command = {
                                event: 'command',
                                func: 'pauseVideo',
                            };
                        }

                        // check if the player exists.
                        if (player !== undefined) {
                            // post our command to the iframe.
                            (player as HTMLIFrameElement).contentWindow.postMessage(JSON.stringify(command), '*');
                        }
                    }
                });
            }

            item.CodeData = _.find(this.guidanceData, (gd) => gd.Code === item.Code);
        }

        private checkContent(data: RemeCare.Shared.Contract.IGuidanceCodeData): boolean {
            if (data == null || data.Data == null || data.Data.GuidanceSteps == null) {
                return false;
            }

            let stepsWithContent = 0;
            const lang = this.$translate.preferredLanguage();

            _(data.Data.GuidanceSteps).forEach((part, index) => {
                _(part.Translations).forEach((tran) => {
                    if (tran.Culture === lang) {
                        stepsWithContent |= tran.Content != null ? 1 : 0;
                    }
                });
            });

            return stepsWithContent === 1;
        }

        private getOrLoadGuidanceData(code: string): Promise<RemeCare.Shared.Contract.IGuidanceCodeData> {
            return new Promise((resolve, reject) => {
                let data = _.find(this.guidanceData, (gd) => gd.Code === code);

                if (data == null) {
                    this.getGuidanceAsync(code)
                        .then((o) => {
                            if (o.GuidanceSteps != null && o.GuidanceSteps.length > 0) {
                                // Test again to avoid pushing duplicates
                                const test = _.find(this.guidanceData, (gd) => gd.Code === code);
                                if (test == null) {
                                    data = { Code: code, Data: null };
                                    data.Data = o;
                                    this.guidanceData.push(data);
                                    resolve(data);
                                } else {
                                    resolve(test);
                                }
                            }
                        })
                        .catch(reject);
                } else {
                    resolve(data);
                }
            });
        }

        private getGuidanceAsync(code: string): Promise<RemeCare.Shared.Contract.Read.IGuidance> {
            return this.getPromise(`Guidance/${code}`, null, null, true);
        }
    }

    remeCareSharedModule.service('guidanceApiSvc', GuidanceApiService);
}
