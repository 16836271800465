module RemeCare.Shared.Filter {

    var percentageFilter: () => (withoutPercentage: string) => string = () => {
        return (withoutPercentage: string) => {
            if (!withoutPercentage)
                return '';
            return withoutPercentage + ' %';
        }
    }

    angular.module('RemeCare.Shared').filter('percentage', percentageFilter);
}