var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Grid;
            (function (Grid) {
                Shared.remeCareSharedModule.component('rcSearchCriteria', {
                    transclude: true,
                    templateUrl: 'views/grid/searchCriteria.html',
                    bindings: {
                        search: '&',
                        clearCriteria: '&',
                        header: '@',
                    },
                });
            })(Grid = Framework.Grid || (Framework.Grid = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
