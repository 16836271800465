var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Directive;
        (function (Directive) {
            var RightAllignedDirective = /** @class */ (function () {
                function RightAllignedDirective() {
                    this.restrict = 'E';
                    this.templateUrl = 'views/rightAlligned.html';
                    this.transclude = true;
                }
                return RightAllignedDirective;
            }());
            Shared.remeCareSharedModule.directive('rcRightAlligned', function () { return new RightAllignedDirective(); });
        })(Directive = Shared.Directive || (Shared.Directive = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
