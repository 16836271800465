namespace RemeCare.Shared {
    import TimeSpan = Shared.Framework.Model.TimeSpan;
    export class DateHelper {
        public static now(): Date {
            const date = new Date();
            return date;
        }

        public static today(): Date {
            const date = new Date();
            date.setHours(0, 0, 0, 0);
            return date;
        }

        public static tomorrow(): Date {
            const date = this.today();
            date.setDate(date.getDate() + 1);
            return date;
        }

        public static startOfTime(): Date {
            return moment('0001-01-01', 'YYYY-MM-DD').toDate();
        }

        public static endOfTime(): Date {
            return moment('9999-12-31', 'YYYY-MM-DD').toDate();
        }

        public static dayOfDate(date: Date): Date {
            if (date == null) {
                return null;
            }
            const momentDate = moment(date);
            const result = moment({
                year: momentDate.year(),
                month: momentDate.month(),
                day: momentDate.date(),
            });
            return result.toDate();
        }

        public static toServerDateTimeString(date: Date): string {
            if (date == null) {
                return null;
            }
            const momentDate = moment(date);
            return momentDate.isValid() ? momentDate.utc().format('YYYY-MM-DDTHH:mm:ss') : null;
        }

        public static momentToServerDateTimeString(dateTime: moment.Moment): string {
            if (dateTime == null) {
                return null;
            }
            return dateTime.isValid() ? dateTime.utc().toISOString() : null;
        }

        public static toServerDateString(date: Date): string {
            if (date == null) {
                return null;
            }
            const momentDate = moment(date);
            return momentDate.isValid() ? momentDate.format('YYYY-MM-DD') + 'Z' : null;
        }

        public static toServerTimeString(date: Date): string {
            if (date == null) {
                return null;
            }
            const momentDate = moment(date);
            return momentDate.isValid() ? momentDate.format('HH:mm:ss') : null;
        }

        public static timeSpanToServerTimeString(timeSpan: Shared.Framework.Model.TimeSpan): string {
            if (timeSpan == null) {
                return null;
            }
            return timeSpan.Days + ':' + timeSpan.Hours + ':' + timeSpan.Minutes;
        }

        public static serverTimeToDate(time: string): Date {
            if (time == null) {
                return null;
            }
            let duration = moment.duration(time);
            if (duration.hours() < 0) {
                duration = moment.duration({
                    seconds: duration.seconds(),
                    minutes: duration.minutes(),
                    hours: duration.hours() + 24,
                    days: -1,
                });
            }
            const momentDate = moment({
                hour: Math.abs(duration.hours()),
                minute: duration.minutes(),
                second: duration.seconds(),
            });
            momentDate.add(duration.days(), 'days');
            return momentDate.isValid() ? momentDate.toDate() : null;
        }

        public static serverTimeToTimeSpan(time: string): Shared.Framework.Model.TimeSpan {
            const date = this.serverTimeToDate(time);
            if (date == null) {
                return null;
            }

            const timeSpan = new Shared.Framework.Model.TimeSpan(date);
            return timeSpan;
        }

        public static serverDateStringToDate(date: string): Date {
            if (date == null) {
                return null;
            }
            const momentDate = moment(date, 'YYYY-MM-DD');
            return momentDate.isValid() ? momentDate.toDate() : null;
        }

        public static serverDateStringToDateTime(date: string): Date {
            if (date == null) {
                return null;
            }
            const momentDate = moment.utc(date).local();
            return momentDate.isValid() ? momentDate.toDate() : null;
        }

        public static addMinutes(date: Date, minutes: number): Date {
            return moment(date)
                .add(minutes, 'minutes')
                .toDate();
        }

        public static addDuration(date: Date, duration: Shared.Contract.Read.IDuration): Date {
            const momentDate = moment(date);
            switch (duration.Unit) {
                case Shared.Contract.Code.DurationUnit.Hours:
                    return momentDate.add(duration.Quantity, 'hours').toDate();
                case Shared.Contract.Code.DurationUnit.Days:
                    return momentDate.add(duration.Quantity, 'days').toDate();
                case Shared.Contract.Code.DurationUnit.Weeks:
                    return momentDate.add(7 * duration.Quantity, 'days').toDate();
                case Shared.Contract.Code.DurationUnit.Months:
                    return momentDate.add(duration.Quantity, 'months').toDate();
                case Shared.Contract.Code.DurationUnit.Years:
                    return momentDate.add(duration.Quantity, 'years').toDate();
                default:
                    return date;
            }
        }

        public static subtractDuration(date: Date, duration: Shared.Contract.Read.IDuration): Date {
            const negativeDuration = {
                Unit: duration.Unit,
                Quantity: -duration.Quantity,
            } as Shared.Contract.Read.IDuration;
            return this.addDuration(date, negativeDuration);
        }

        public static setTime(date: Date, time: Date): void {
            if (date == null || time == null) {
                return;
            }
            date.setHours(time.getHours());
            date.setMinutes(time.getMinutes());
        }

        public static mergeDateAndTime(date: Date, time: Date): Date {
            const momentDate = moment(date).startOf('day');
            if (time) {
                momentDate.hours(time.getHours());
                momentDate.minutes(time.getMinutes());
            }
            return momentDate.toDate();
        }

        public static dateTimeHumanReadible(date: Date): string {
            return moment(date).format('DD-MM-YYYY HH:mm');
        }

        public static dateHumanReadible(date: Date): string {
            return moment(date).format('DD-MM-YYYY');
        }
        public static timeHumanReadible(date: Date): string {
            return moment(date).format('HH:mm');
        }

        public today(): Date {
            return DateHelper.today();
        }

        public toServerDateTimeString(date: Date): string {
            return DateHelper.toServerDateTimeString(date);
        }

        public toServerDateString(date: Date): string {
            return DateHelper.toServerDateString(date);
        }

        public toServerTimeString(date: Date): string {
            return DateHelper.toServerTimeString(date);
        }

        public serverTimeToDate(time: string): Date {
            return DateHelper.serverTimeToDate(time);
        }

        public serverDateStringToDate(date: string): Date {
            return DateHelper.serverDateStringToDate(date);
        }

        public serverDateStringToDateTime(date: string): Date {
            return DateHelper.serverDateStringToDateTime(date);
        }

        public addDuration(date: Date, duration: Shared.Contract.Read.IDuration): Date {
            return DateHelper.addDuration(date, duration);
        }

        public subtractDuration(date: Date, duration: Shared.Contract.Read.IDuration): Date {
            return DateHelper.subtractDuration(date, duration);
        }
    }

    remeCareSharedModule.factory('dateHelper', () => new DateHelper());
}
