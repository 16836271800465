namespace RemeCare.Shared.Framework {
    export class ClaimTypes {
        public static applicationProfile = 'applicationprofile';
        public static firstname = 'firstname';
        public static lastname = 'lastname';
        public static partyId = 'partyid';
        public static partyRoleId = 'partyroleid';
    }

    export class AuthContext {
        public static action = 'Action';
        public static agenda = 'Agenda';
        public static agendaColleague = 'Agenda.Colleague';
        public static agendaOwn = 'Agenda.Own';
        public static agendaTeam = 'Agenda.Team';
        public static alarm = 'Alarm';
        public static careRequest = 'CareRequest';
        public static configuration = 'Configuration';
        public static configurationActionLibrary = 'Configuration.ActionLibrary';
        public static configurationMasterData = 'Configuration.MasterData';
        public static configurationTherapy = 'Configuration.Therapy';
        public static conversations = 'Conversations';
        public static inbox = 'Inbox';
        public static inboxAlarms = 'Inbox.Alarm';
        public static inboxConversations = 'Inbox.Conversations';
        public static integrationMonitor = 'IntegrationMonitor';
        public static management = 'Management';
        public static managementActorRoles = 'Management.ActorRoles';
        public static managementMaterials = 'Management.Materials';
        public static managementOrganisations = 'Management.Organisations';
        public static managementOrganisationsExternalIdentity = 'Management.Organisations.ExternalIdentity';
        public static managementPersons = 'Management.Persons';
        public static managementPersonsUser = 'Management.Persons.User';
        public static managementProducts = 'Management.Products';
        public static managementTeams = 'Management.Teams';
        public static managementPatient = 'Management.Patient';
        public static managementGroups = 'Management.Groups';
        public static managementInformedConsents = 'Management.InformedConsents';
        public static patient = 'Patient';
        public static patientCarePlan = 'Patient.CarePlan';
        public static patientCarePlanCareRequest = 'Patient.CarePlan.CareRequest';
        public static patientCarePlanCorrectParameterValue = 'Patient.CarePlan.CorrectParameterValue';
        public static patientCarePlanMedication = 'Patient.CarePlan.Medication';
        public static patientCarePlanMedicationPeriodicity = 'Patient.CarePlan.Medication.Periodicity';
        public static patientCareTeam = 'Patient.CareTeam';
        public static patientCareTeamDetail = 'Patient.CareTeam.Detail';
        public static patientDocuments = 'Patient.Documents';
        public static patientInternal = 'Patient.Internal';
        public static patientMedia = 'Patient.Media';
        public static patientMedical = 'Patient.Medical';
        public static patientMedicalHospitalisation = 'Patient.Medical.Hospitalisation';
        public static patientMedication = 'Patient.Medication';
        public static patientMonitor = 'Patient.Monitor';
        public static patientMonitorDashboard = 'Patient.Monitor.Dashboard';
        public static patientMonitorRegistrations = 'Patient.Monitor.Registrations';
        public static patientMonitorAlarms = 'Patient.Monitor.Alarms';
        public static patientSinglePatient = 'Patient.SinglePatient';
        public static searchCareProvider = 'Search.CareProvider';
        public static searchContact = 'Search.Contact';
        public static searchOrganisation = 'Search.Organisation';
        public static searchPatient = 'Search.Patient';
        public static patientInformedConsents = 'Patient.InformedConsents';
    }

    export enum AuthRight {
        Nothing = 0,
        Read = 1,
        Write = 2,
        Create = 4,
        Delete = 8,
    }

    export class AuthService {
        private claims: { [id: string]: string } = {};
        private rights: { [id: string]: AuthRight } = {};

        constructor(private readonly $rootScope: ng.IRootScopeService, private readonly baseUrl: string) {}

        public setRights(rights: { [id: string]: AuthRight }): void {
            if (!rights) {
                this.rights = {};
            }
            for (const prop in this.rights) {
                if (this.rights.hasOwnProperty(prop)) {
                    delete this.rights[prop];
                }
            }
            for (const prop in rights) {
                if (rights.hasOwnProperty(prop)) {
                    this.rights[prop] = rights[prop];
                }
            }
            this.$rootScope.$broadcast(AppEvents.rightsChanged);
        }

        public hasRight(context: string, right: AuthRight) {
            if (this.rights.hasOwnProperty(context)) {
                // tslint changes to === which does not work
                /* tslint:disable */
                return (this.rights[context] & right) == right;
                /* tslint:enable */
            }
            return false;
        }

        public setClaims(claims: { [id: string]: string }) {
            for (const claim in claims) {
                if (claims.hasOwnProperty(claim)) {
                    this.setClaim(claim, claims[claim], true);
                }
            }
            this.$rootScope.$broadcast(AppEvents.claimsChanged);
        }

        public setClaim(key: string, value: string, preventEvent?: boolean) {
            this.claims[key] = value;
            if (!preventEvent) {
                this.$rootScope.$broadcast(AppEvents.claimsChanged);
            }
        }

        public getClaim(key: string): string {
            return this.claims[key];
        }

        public getNameOfUser(): string {
            return this.isAuthenticated()
                ? `${this.getClaim(ClaimTypes.firstname)} ${this.getClaim(ClaimTypes.lastname)}`
                : '';
        }

        public signOut() {
            window.location.href = `${this.baseUrl}Security/Logout`;
        }

        public getProfile(): Shared.Contract.Code.ApplicationProfileType {
            const profile = this.getClaim(ClaimTypes.applicationProfile);
            if (profile == null) {
                return 0;
            }
            return parseInt(profile);
        }

        public isPatient(): boolean {
            return this.getProfile() === Shared.Contract.Code.ApplicationProfileType.Patient;
        }

        public isUnknownCareProvider(): boolean {
            return this.getProfile() === Shared.Contract.Code.ApplicationProfileType.UnknownCareProvider;
        }

        public isAuthenticated(): boolean {
            return !!this.getClaim(ClaimTypes.partyId);
        }

        public isInternalUser(): boolean {
            const profile = this.getProfile();
            return (
                profile === Shared.Contract.Code.ApplicationProfileType.CareManager ||
                profile === Shared.Contract.Code.ApplicationProfileType.AdministrativeManager
            );
        }
    }

    remeCareSharedModule.service('authservice', AuthService);
}
