namespace RemeCare.Shared {
    export class PkceHelper {
        public static async generatePkcePair(): Promise<{ codeChallenge: string; codeVerifier: string }> {
            const codeVerifier = this.generateRandomString();

            // Hash and base64-urlencode the secret to use as the challenge
            const codeChallenge = await this.pkceChallengeFromVerifier(codeVerifier);

            return { codeChallenge, codeVerifier };
        }

        // Generate a secure random string using the browser crypto functions
        private static generateRandomString(): string {
            const array = new Uint32Array(28);
            window.crypto.getRandomValues(array);
            return this.arrayFrom(array, (dec) => ('0' + dec.toString(16)).substr(-2), this).join('');
        }

        // Calculate the SHA256 hash of the input text.
        // Returns a promise that resolves to an ArrayBuffer
        private static sha256(plain: string): PromiseLike<ArrayBuffer> {
            const encoder = new TextEncoder();
            const data = encoder.encode(plain);
            return window.crypto.subtle.digest('SHA-256', data);
        }

        // Return the base64-urlencoded sha256 hash for the PKCE challenge
        private static async pkceChallengeFromVerifier(v: string): Promise<string> {
            const hashed = await this.sha256(v);
            // Convert the ArrayBuffer to string using Uint8 array to conver to what btoa accepts.
            // btoa accepts chars only within ascii 0-255 and base64 encodes them.
            return Base64Helper.base64urlencode(String.fromCharCode.apply(null, new Uint8Array(hashed)));
        }

        // Array.from polyfill for IE
        private static arrayFrom(arr, callbackFn, thisArg) {
            const arNew = [];
            const k = []; // used for convert Set to an Array
            let i = 0;

            for (; i < arr.length; i++) {
                arNew[i] = callbackFn ? callbackFn.call(thisArg, arr[i], i, arr) : arr[i];
            }

            return arNew;
        }
    }
}
