namespace RemeCare.Shared.Filter {
    const enumFilter: (
        $rootScope: ng.IRootScopeService,
        masterdataSvc: Shared.Framework.MasterdataService
    ) => (enumValue: number, enumType: string) => string = ($rootScope, masterdataSvc) => {
        let enumerations: { [enumType: string]: { [enumValue: number]: string } } = {};
        let serviceInvoked: { [enumType: string]: boolean } = {};

        const filter = (enumValue: number, enumType: string) => {
            if (enumValue == null || enumType == null) {
                return '';
            }
            const enumeration = enumerations[enumType];
            if (enumeration == null) {
                return '';
            }
            return enumeration[enumValue];
        };

        // clean enumerations when language has changed so the new translations will be retrieved
        $rootScope.$on('applicationLanguageChanged', () => {
            enumerations = {};
            serviceInvoked = {};
        });

        const retrieveAsyncFunction = async enumType => {
            serviceInvoked[enumType] = true;
            const data = await masterdataSvc.getReferenceDataAsync(enumType);
            enumerations[enumType] = {};
            _(data).forEach(d => {
                enumerations[enumType][d.Id] = d.Text;
            });
        };

        const filterFunction = (enumValue, enumType) => {
            if (!enumerations[enumType]) {
                if (!serviceInvoked[enumType]) {
                    retrieveAsyncFunction(enumType);
                }
                return '-';
            } else {
                return filter(enumValue, enumType);
            }
        };

        const x = filterFunction as any;
        x.$stateful = true;
        return x;
    };

    remeCareSharedModule.filter('enum', enumFilter);
}
