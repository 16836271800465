module RemeCare.Shared.Framework.Directive {

    class SimplePagingController implements ng.IComponentController {

        constructor(private $timeout: ng.ITimeoutService) {}

        totalItems: number;
        itemsPerPage: number;
        totalPages: number;
        index: number;
        changedCallback: () => void;

        $onInit() {
            this.setTotalPages();
        }

        $onChanges() {
            this.setTotalPages();
        }

        setTotalPages(): void {
            this.totalPages = Math.ceil(this.totalItems / this.itemsPerPage);
        }

        moveNext(): void {
            this.index++;
            this.notifyChanges();
        }

        movePrevious(): void {
            this.index--;
            this.notifyChanges();
        }

        private notifyChanges(): void {
            this.$timeout(() => {
                if (this.changedCallback) {
                    this.changedCallback();
                }
            });
        }
    }

    remeCareSharedModule.component('rcSimplePaging',
    {
        controller: SimplePagingController,
        controllerAs: 'spCtrl',
        templateUrl: 'views/simplePaging.html',
        bindings: {
            index: '=ngModel',
            changedCallback: '&?ngChange',
            totalItems: '<',
            itemsPerPage: '<'
        }
    });
}