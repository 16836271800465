var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Contract;
        (function (Contract) {
            var Code;
            (function (Code) {
                var MonitoringPartType;
                (function (MonitoringPartType) {
                    MonitoringPartType[MonitoringPartType["LineChartNumeric"] = 1] = "LineChartNumeric";
                    MonitoringPartType[MonitoringPartType["TableNumericQualitative"] = 2] = "TableNumericQualitative";
                    MonitoringPartType[MonitoringPartType["CumulativeBarChart"] = 3] = "CumulativeBarChart";
                    MonitoringPartType[MonitoringPartType["FloatingBarChart"] = 4] = "FloatingBarChart";
                    MonitoringPartType[MonitoringPartType["ColourQualitativeTimeLine"] = 5] = "ColourQualitativeTimeLine";
                    MonitoringPartType[MonitoringPartType["MedicationAdherenceChart"] = 6] = "MedicationAdherenceChart";
                    MonitoringPartType[MonitoringPartType["MedicationDoseChart"] = 7] = "MedicationDoseChart";
                    MonitoringPartType[MonitoringPartType["ActionTable"] = 8] = "ActionTable";
                    MonitoringPartType[MonitoringPartType["ActionTimeLine"] = 9] = "ActionTimeLine";
                    MonitoringPartType[MonitoringPartType["CumulativeObjectiveChart"] = 10] = "CumulativeObjectiveChart";
                    MonitoringPartType[MonitoringPartType["ObjectiveScoreTable"] = 11] = "ObjectiveScoreTable";
                    MonitoringPartType[MonitoringPartType["LineChartNumericExternal"] = 12] = "LineChartNumericExternal";
                    MonitoringPartType[MonitoringPartType["Boxplot"] = 13] = "Boxplot";
                })(MonitoringPartType = Code.MonitoringPartType || (Code.MonitoringPartType = {}));
            })(Code = Contract.Code || (Contract.Code = {}));
        })(Contract = Shared.Contract || (Shared.Contract = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
