var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Contract;
        (function (Contract) {
            var Code;
            (function (Code) {
                var CarePlanStatus;
                (function (CarePlanStatus) {
                    CarePlanStatus[CarePlanStatus["Open"] = 1] = "Open";
                    CarePlanStatus[CarePlanStatus["OnHold"] = 2] = "OnHold";
                    CarePlanStatus[CarePlanStatus["Stopped"] = 3] = "Stopped";
                })(CarePlanStatus = Code.CarePlanStatus || (Code.CarePlanStatus = {}));
            })(Code = Contract.Code || (Contract.Code = {}));
        })(Contract = Shared.Contract || (Shared.Contract = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
