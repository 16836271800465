namespace RemeCare.Shared.Framework.Service {

    class StateDecorator {

        public static factory(
            $delegate: ng.ui.IStateService,
            $log: ng.ILogService
        ): ng.ui.IStateService {
            return new StateDecorator($delegate, $log).decorate();
        }

        private constructor(
            private readonly $delegate: ng.ui.IStateService,
            private readonly $log: ng.ILogService
            ) { }

        private decorate(): ng.ui.IStateService {

            const delegateReload = this.$delegate.reload;

            const reload = () => {
                const state = this.$delegate.current;
                this.$log.debug(`Reload state called for ${JSON.stringify(state)}`);
                return delegateReload();
            }

            this.$delegate.reload = reload;

            return this.$delegate;
        }
    }

    remeCareSharedModule.decorator('$state',
        [
            '$delegate',
            '$log',
            StateDecorator.factory
        ]);
}