module RemeCare.Shared.Directive {

    interface IEnumFlagScope extends ng.IScope {
        value: number;
        model: number;
    }
    
    class EnumFlagDirective implements ng.IDirective {
        
        restrict = 'A';

        scope: { [boundProperty: string]: string } = {
            value: '=rcEnumFlag',
            model: '=rcEnumModel'
        }

        link: ng.IDirectiveLinkFn = (scope: IEnumFlagScope, element: ng.IAugmentedJQuery) => {
            var checkbox: HTMLInputElement = <HTMLInputElement>element[0];
            element.on('change', () => {
                scope.$apply(() => {
                    scope.model = scope.model ? scope.model : 0;
                    if (checkbox.checked) {
                        scope.model += scope.value;
                    } else {
                        scope.model -= scope.value;
                    }
                });
            });
            scope.$watch('model', () => {
                checkbox.checked = (scope.model & scope.value) === scope.value;
            });
        }

    }

    angular.module('RemeCare.Shared').directive('rcEnumFlag', () => new EnumFlagDirective());
}