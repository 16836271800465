namespace RemeCare.Shared.Framework.Directive {
    class PeriodicityEditorDirective implements ng.IDirective {
        public scope: { [boundProperty: string]: string } = {
            readOnly: '=',
            recurrentCycle: '=',
            setRecurrentCycleMembers: '&?setMembers',
            setEvenOddDays: '&?setEvenOdd',
            form: '=',
            therapyActionPartContextSettings: '=',
            forMedication: '=forMedication',
            limitedConfig: '=',
            medication: '=',
            onlyIntakeMoments: '=',
            carePlanStartDate: '<',
            prescriptionStartDate: '<',
        };

        public templateUrl = 'views/periodicity/periodicityEditor.html';

        public controller = 'PeriodicityEditorCtrl';
    }

    remeCareSharedModule.directive('rcPeriodicityEditor', () => new PeriodicityEditorDirective());
}
