var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Directive;
            (function (Directive) {
                var SimplePagingController = /** @class */ (function () {
                    function SimplePagingController($timeout) {
                        this.$timeout = $timeout;
                    }
                    SimplePagingController.prototype.$onInit = function () {
                        this.setTotalPages();
                    };
                    SimplePagingController.prototype.$onChanges = function () {
                        this.setTotalPages();
                    };
                    SimplePagingController.prototype.setTotalPages = function () {
                        this.totalPages = Math.ceil(this.totalItems / this.itemsPerPage);
                    };
                    SimplePagingController.prototype.moveNext = function () {
                        this.index++;
                        this.notifyChanges();
                    };
                    SimplePagingController.prototype.movePrevious = function () {
                        this.index--;
                        this.notifyChanges();
                    };
                    SimplePagingController.prototype.notifyChanges = function () {
                        var _this = this;
                        this.$timeout(function () {
                            if (_this.changedCallback) {
                                _this.changedCallback();
                            }
                        });
                    };
                    return SimplePagingController;
                }());
                Shared.remeCareSharedModule.component('rcSimplePaging', {
                    controller: SimplePagingController,
                    controllerAs: 'spCtrl',
                    templateUrl: 'views/simplePaging.html',
                    bindings: {
                        index: '=ngModel',
                        changedCallback: '&?ngChange',
                        totalItems: '<',
                        itemsPerPage: '<'
                    }
                });
            })(Directive = Framework.Directive || (Framework.Directive = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
