namespace RemeCare.Shared.Framework.Directive {
    class InputCheckboxSingleController implements ng.IComponentController {
        public uniqueName: string;
        public checkboxModel: boolean;
        private option: any;
        private model: any;
        private value: string;

        constructor(private readonly idService: Shared.Framework.Service.IIdService) {}

        public $onInit(): void {
            this.uniqueName = this.idService.generateId();
            if (this.model) {
                this.checkboxModel = true;
            }
        }

        public changed(): void {
            this.model = this.checkboxModel ? (this.value ? this.option[this.value] : this.option) : null;
        }
    }

    remeCareSharedModule.component('rcInputCheckboxSingle', {
        controller: InputCheckboxSingleController,
        require: {
            formCtrl: '^form',
        },
        templateUrl: 'framework/directives/formElements/inputCheckboxSingle/inputCheckboxSingle.html',
        bindings: {
            model: '=ngModel',
            ngDisabled: '<?',
            ngReadonly: '<?',
            adjustSize: '<?',
            label: '@',
            option: '<',
            value: '@?',
            displayText: '@',
            inputClass: '<',
            required: '=ngRequired',
            iconField: '@',
            guidanceIconLocation: '<',
            guidanceIconFunc: '<',
            uniqueId: '@',
        },
    });
}
