var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Directive;
            (function (Directive) {
                var SaveButton = /** @class */ (function () {
                    function SaveButton() {
                    }
                    SaveButton.prototype.$onInit = function () {
                        this.label = this.label || 'General.Save';
                        this.type = this.type || 'button';
                    };
                    SaveButton.prototype.confirm = function () {
                        this.save && this.save();
                    };
                    return SaveButton;
                }());
                Shared.remeCareSharedModule.component('rcSave', {
                    controller: SaveButton,
                    controllerAs: 'btnCtrl',
                    templateUrl: 'views/controls/primaryButton.html',
                    bindings: {
                        save: '&?',
                        label: '@?',
                        ngDisabled: '<?',
                        loader: '@?',
                        type: '@?'
                    }
                });
            })(Directive = Framework.Directive || (Framework.Directive = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
