var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Contract;
        (function (Contract) {
            var Code;
            (function (Code) {
                var RequestAccessType;
                (function (RequestAccessType) {
                    RequestAccessType[RequestAccessType["DoctorSpecialistAssociatedToAHospital"] = 1] = "DoctorSpecialistAssociatedToAHospital";
                    RequestAccessType[RequestAccessType["DoctorSpecialistNotAssociatedToAHospital"] = 2] = "DoctorSpecialistNotAssociatedToAHospital";
                    RequestAccessType[RequestAccessType["GeneralPractitioner"] = 3] = "GeneralPractitioner";
                    RequestAccessType[RequestAccessType["HospitalNurse"] = 4] = "HospitalNurse";
                    RequestAccessType[RequestAccessType["HomeCareNurse"] = 5] = "HomeCareNurse";
                    RequestAccessType[RequestAccessType["HospitalPharmacist"] = 6] = "HospitalPharmacist";
                    RequestAccessType[RequestAccessType["Pharmacist"] = 7] = "Pharmacist";
                    RequestAccessType[RequestAccessType["HospitalStaff"] = 8] = "HospitalStaff";
                    RequestAccessType[RequestAccessType["Other"] = 9] = "Other";
                })(RequestAccessType = Code.RequestAccessType || (Code.RequestAccessType = {}));
            })(Code = Contract.Code || (Contract.Code = {}));
        })(Contract = Shared.Contract || (Shared.Contract = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
