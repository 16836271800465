namespace RemeCare.Shared.Framework.Directive {
    class InputCheckboxListController implements ng.IComponentController {
        public uniqueName: string;
        private value: string;
        private displayText: string;

        constructor(private readonly idService: Shared.Framework.Service.IIdService) {}

        public $onInit(): void {
            this.uniqueName = this.idService.generateId();
        }

        public getValue(item): any {
            return this.value ? item[this.value] : item;
        }

        public getDisplayText(item): any {
            if(this.displayText) {
                return this.displayText.split(".").reduce((o, key) => o && o[key] ? o[key] : null, item);
            }
            return null;
        }
    }

    remeCareSharedModule.component('rcInputCheckboxList', {
        controller: InputCheckboxListController,
        require: {
            formCtrl: '^form',
        },
        templateUrl: 'views/formElements/inputCheckboxList.html',
        bindings: {
            ngModel: '=',
            ngDisabled: '<?',
            ngReadonly: '<?',
            adjustSize: '<?',
            label: '@',
            options: '<',
            value: '@?',
            displayText: '@',
            inputClass: '<',
            required: '=ngRequired',
            iconField: '@',
            guidanceIconLocation: '<',
            guidanceIconFunc: '<',
            uniqueId: '@',
        },
    });
}
