var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Directive;
            (function (Directive) {
                var RightCreateButtonDirective = /** @class */ (function () {
                    function RightCreateButtonDirective() {
                        this.restrict = 'A';
                        this.scope = {
                            title: '@',
                            create: '&?',
                            link: '@?',
                            ngDisabled: '<?',
                            hide: '<?',
                            ngRequired: '='
                        };
                        this.templateUrl = 'views/controls/rightCreateButton.html';
                    }
                    return RightCreateButtonDirective;
                }());
                Shared.remeCareSharedModule.directive('rcRightCreateButton', function () { return new RightCreateButtonDirective(); });
            })(Directive = Framework.Directive || (Framework.Directive = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
