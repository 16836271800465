var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Contract;
        (function (Contract) {
            var Code;
            (function (Code) {
                var CommunicationTypePreference;
                (function (CommunicationTypePreference) {
                    CommunicationTypePreference[CommunicationTypePreference["None"] = 0] = "None";
                    CommunicationTypePreference[CommunicationTypePreference["Email"] = 1] = "Email";
                    CommunicationTypePreference[CommunicationTypePreference["EHealthBox"] = 2] = "EHealthBox";
                })(CommunicationTypePreference = Code.CommunicationTypePreference || (Code.CommunicationTypePreference = {}));
            })(Code = Contract.Code || (Contract.Code = {}));
        })(Contract = Shared.Contract || (Shared.Contract = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
