module RemeCare.Shared.Framework.InformedConsent {
    export interface IApproveModalResult {
        needsConfirmation: boolean;
        date: Date;
    }

    class ApproveModalController implements ng.IComponentController {
        public askApproval: boolean;
        public needsConfirmation: boolean;
        public startDate: Date;
        public minDate: Date;
        public $close: (result: { result: IApproveModalResult }) => void;
        public form: Directive.IFormController;

        public $onInit(): void {
            this.askApproval = true;
            this.minDate = Shared.DateHelper.addDuration(this.minDate, {
                Quantity: 1,
                Unit: Shared.Contract.Code.DurationUnit.Days
            });
            if (this.minDate < Shared.DateHelper.tomorrow()) {
                this.minDate = Shared.DateHelper.tomorrow();
            }
        }

        public confirm(confirmation: boolean): void {
            this.needsConfirmation = confirmation;
            this.$close({ result: { needsConfirmation: this.needsConfirmation, date: this.startDate } });
        }

        public approve(): void {
            this.form.$setSubmitted();
            if (this.form.$valid) {
                this.askApproval = false;
            }
        }
    }

    Shared.remeCareSharedModule.component('rcApproveModal', {
        bindings: {
            $close: '&',
            $dismiss: '&',
            minDate: '<'
        },
        controller: ApproveModalController,
        templateUrl: 'framework/informedConsent/approveModal/approveModal.html'
    });
}
