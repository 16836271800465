namespace RemeCare.Shared.Framework.Directive {
    interface IInputCheckboxScope extends ng.IScope {
        model: boolean;
        formCtrl: ng.IFormController;
        boolModel: {
            model: boolean;
        };
    }

    class InputCheckboxController implements ng.IComponentController {
        // bindings
        public adjustSize: boolean;
        public changed: () => void;
        public disabled: boolean;
        public formCtrl: Directive.IFormController;
        public label: string;
        public model: boolean;
        public readOnly: boolean;
        public required: boolean;

        public $onInit(): void {}

        public labelClicked(): void {
            if (this.disabled || this.readOnly) {
                return;
            }
            this.model = !this.model;
            this.changed();
        }
    }

    remeCareSharedModule.component('rcInputCheckbox', {
        bindings: {
            adjustSize: '=',
            changed: '&ngChange',
            disabled: '=ngDisabled',
            label: '@',
            model: '=ngModel',
            readOnly: '=ngReadonly',
            required: '=ngRequired',
            labelAfter: '=labelAfter',
            guidanceIconLocation: '<',
            guidanceIconFunc: '<',
            uniqueId: '@',
        },
        controller: InputCheckboxController,
        require: {
            formCtrl: '^form',
        },
        templateUrl: 'views/formElements/inputCheckbox.html',
    });
}
