var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Contract;
        (function (Contract) {
            var Code;
            (function (Code) {
                var IntendedValue;
                (function (IntendedValue) {
                    IntendedValue[IntendedValue["None"] = 1] = "None";
                    IntendedValue[IntendedValue["Single"] = 2] = "Single";
                    IntendedValue[IntendedValue["Multiple"] = 3] = "Multiple";
                    IntendedValue[IntendedValue["Complex"] = 4] = "Complex";
                })(IntendedValue = Code.IntendedValue || (Code.IntendedValue = {}));
            })(Code = Contract.Code || (Contract.Code = {}));
        })(Contract = Shared.Contract || (Shared.Contract = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
