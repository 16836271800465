namespace RemeCare.Shared.Framework.Directive {
    export class TextLength {
        public static extraShort = 'extraShort';
        public static short = 'short';
        public static medium = 'medium';
        public static long = 'long';
        public static extraLong = 'extraLong';
        public static textArea = 'textArea';
    }

    class InputTextController implements ng.IComponentController {
        // bindings
        public maxLength: number;
        public textType: string;
        public specificMaxLength: number;
        public showTextInput: boolean;
        public showTextArea: boolean;

        public ngChange: () => void;

        constructor(private readonly $timeout: ng.ITimeoutService) {}

        public $onInit(): void {
            this.showTextInput = true;
            this.showTextArea = false;
            if (!this.textType && this.specificMaxLength) {
                this.maxLength = this.specificMaxLength;
            }

            if (!this.maxLength) {
                switch (this.textType) {
                    case TextLength.extraShort:
                        this.maxLength = 10;
                        break;
                    case TextLength.short:
                        this.maxLength = 50;
                        break;
                    case TextLength.medium:
                        this.maxLength = 100;
                        break;
                    case TextLength.long:
                        this.maxLength = 200;
                        break;
                    case TextLength.extraLong:
                        this.maxLength = 400;
                        break;
                    case TextLength.textArea:
                        this.maxLength = 1000;
                        this.showTextArea = true;
                        this.showTextInput = false;
                        break;
                    default:
                        this.maxLength = 50;
                        break;
                }
            }
        }

        public textChanged(): void {
            this.$timeout(() => {
                this.ngChange && this.ngChange();
            });
        }
    }

    remeCareSharedModule.component('rcInputText', {
        bindings: {
            model: '=ngModel',
            required: '=ngRequired',
            disabled: '=ngDisabled',
            readOnly: '=ngReadonly',
            pattern: '=ngPattern',
            blur: '&?ngBlur',
            info: '@',
            adjustSize: '=',
            label: '@',
            textType: '@',
            ngChange: '&?',
            hasWarning: '=?ngWarning',
            specificMaxLength: '@', // When the default max lengths defined by text-type are not sufficient
            placeholder: '@',
            inputmode: '@',
            guidanceIconLocation: '<',
            guidanceIconFunc: '<',
            uniqueId: '@',
            name: '@',
        },
        controller: InputTextController,
        require: {
            formCtrl: '^form',
        },
        templateUrl: 'framework/directives/formElements/inputText/inputText.html',
    });
}
