module RemeCare.Shared.Framework {

    export interface IBaseScope extends ng.IScope {
        isFieldInvalid(fieldName: string, form: ng.IFormController);
        showErrors: boolean;
        copy<T>(t: T): T;
    }
    
    export abstract class ControllerBase<TScope extends IBaseScope> implements ng.IController {
        showPermErrors: boolean;

        constructor(
            protected $scope: TScope,
            protected $translate: ng.translate.ITranslateService,
            protected toaster: Toaster) {

            $scope.isFieldInvalid = (field: string, form: ng.IFormController) => this.isFieldInvalid(field, form);
            $scope.showErrors = false;
            $scope.copy = (t) => this.copy(t);
        }

        public abstract $onInit(): void;

        copy<T>(t: T): T {
            return angular.copy(t);
        }

        isFieldInvalid(field: string, form: ng.IFormController): boolean {
            return this.showPermErrors && form[field].$invalid;
        }

        generalSuccess(): void {
            this.toaster.success(this.$translate.instant('General.SaveSuccess'));
        }
        
        errorCallback(message: string): void {
            this.toaster.error(message);
        }

        showValidationErrorMessage(): void {
            this.toaster.error(this.$translate.instant('General.FieldValidationError'));
        }
    }
}