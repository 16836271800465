module RemeCare.Shared.Framework.Model {
    
    export class PossibleProductForAction {
        ProductId: Contract.Guid;
        Product: Contract.IEntityTranslation;
        ProductClassId: Contract.Guid;
        ProductClass: Contract.IEntityTranslation;
        ProductDisplayClasses: Array<Contract.IEntityTranslation>;
        IsDefault: boolean;
        IsPreferred: boolean;

        public displayClasses(): Array<Contract.IEntityTranslation> {
            if (this.ProductClass &&  this.ProductClass.Id)
                return [this.ProductClass];
            return this.ProductDisplayClasses;
        }
    }
}