module RemeCare.Shared.Framework.InformedConsent {
    class CheckboxModalController implements ng.IComponentController {
        public question: Shared.Framework.Model.InformedConsentQuestionTranslation;
        public culture: string;
        public sequence: number;

        public async $onInit(): Promise<void> {
            this.question = new Shared.Framework.Model.InformedConsentQuestionTranslation(this.sequence);
        }
    }

    Shared.remeCareSharedModule.component('rcAddCheckboxModal', {
        bindings: {
            sequence: '<',
            culture: '<',
            $close: '&',
            $dismiss: '&'
        },
        controller: CheckboxModalController,
        templateUrl: 'framework/informedConsent/checkboxModal/checkboxModal.html'
    });
}
