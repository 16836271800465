module RemeCare.Shared.Framework {

    class ErrorDecoratorController implements ng.IController {

        // bindings
        public showErrors: boolean;
        public showUnrequired: boolean;

        private hasErrorClass = 'has-error';

        constructor(
            private readonly $element: ng.IAugmentedJQuery,
            private readonly $rootScope: ng.IRootScopeService
        ) {
        }

        public $onInit(): void {
            this.$rootScope.$on('formSubmitted', () => this.$doCheck());
        }

        public $onChanges(changes: ng.IOnChangesObject) {
            if (changes.showErrors) {
                if (this.isInvalid() && changes.showErrors.currentValue) {
                    this.$element.addClass(this.hasErrorClass);
                } else {
                    this.$element.removeClass(this.hasErrorClass);
                }
            }
            if (changes.showUnrequired) {
                if (this.$element.find('[class*="ng-invalid-"]:not([class*="ng-invalid-required"])').length > 0
                    && changes.showUnrequired.currentValue) {
                    this.$element.addClass(this.hasErrorClass);
                } else {
                    this.$element.removeClass(this.hasErrorClass);
                }
            }
        }

        public $doCheck(): void {
            if (this.isInvalid() && this.showErrors) {
                this.$element.addClass(this.hasErrorClass);
            } else {
                this.$element.removeClass(this.hasErrorClass);
            }
        }

        private isInvalid(): boolean {
            return this.$element.find('.ng-invalid').length > 0;
        }
    }

    class ErrorDecoratorDirective implements ng.IDirective {

        public bindToController = true;

        public controller = ['$element', '$rootScope', ErrorDecoratorController];

        public restrict: string = 'A';

        public scope: { [boundProperty: string]: string } = {
            showErrors: '<rcShowErrors',
            showUnrequired: '<?'
        }
    }

    remeCareSharedModule.directive('rcShowErrors', () => new ErrorDecoratorDirective());
} 