var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Directive;
        (function (Directive) {
            var CollapsePanelController = /** @class */ (function () {
                function CollapsePanelController($rootScope) {
                    this.$rootScope = $rootScope;
                }
                CollapsePanelController.prototype.$onInit = function () {
                };
                CollapsePanelController.prototype.toggleCollapse = function () {
                    this.collapsed = !this.collapsed;
                    if (!this.collapsed) {
                        this.$rootScope.$broadcast('panelUncollapsed');
                    }
                };
                CollapsePanelController.prototype.getClass = function () {
                    var panelClass = this.borderless
                        ? 'panel-borderless'
                        : 'panel';
                    var extraClass = this.customClass || 'panel-default';
                    var collapsedClass = this.collapsed && 'collapsed';
                    return panelClass + " " + extraClass + " " + collapsedClass;
                };
                return CollapsePanelController;
            }());
            Shared.remeCareSharedModule.component('rcCollapsePanel', {
                controller: CollapsePanelController,
                bindings: {
                    title: '@?panelHeader',
                    collapsed: '=?',
                    disableBodyClass: '<?',
                    customClass: '@?',
                    borderless: '<?',
                    headerClass: '@?',
                    readOnly: '=ngReadonly',
                    create: '&?create',
                },
                templateUrl: 'views/collapsePanel.html',
                transclude: {
                    header: '?header'
                }
            });
        })(Directive = Shared.Directive || (Shared.Directive = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
