var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Directive;
            (function (Directive) {
                var InputNumberController = /** @class */ (function () {
                    function InputNumberController() {
                    }
                    InputNumberController.prototype.$onInit = function () { };
                    InputNumberController.prototype.placeholder = function () {
                        if (this.min != null && this.max != null) {
                            return '[' + this.min + ' - ' + this.max + ']';
                        }
                        else if (this.min != null) {
                            return '>=' + this.min;
                        }
                        else if (this.max != null) {
                            return '<=' + this.max;
                        }
                        else {
                            return '';
                        }
                    };
                    return InputNumberController;
                }());
                Shared.remeCareSharedModule.component('rcInputNumber', {
                    bindings: {
                        model: '=ngModel',
                        required: '=ngRequired',
                        readOnly: '=ngReadonly',
                        disabled: '=ngDisabled',
                        info: '@',
                        adjustSize: '=',
                        label: '@',
                        min: '=ngMin',
                        max: '=ngMax',
                        forceDecimals: '=',
                        unit: '@',
                        guidanceIconLocation: '<',
                        guidanceIconFunc: '<',
                        uniqueId: '@',
                    },
                    controller: InputNumberController,
                    require: {
                        formCtrl: '^form',
                    },
                    templateUrl: 'framework/directives/formElements/inputNumber/inputNumber.html',
                });
            })(Directive = Framework.Directive || (Framework.Directive = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
