var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Contract;
        (function (Contract) {
            var Code;
            (function (Code) {
                var Country;
                (function (Country) {
                    Country[Country["Belgium"] = 1] = "Belgium";
                    Country[Country["Netherlands"] = 2] = "Netherlands";
                    Country[Country["France"] = 3] = "France";
                    Country[Country["Luxembourg"] = 4] = "Luxembourg";
                    Country[Country["Germany"] = 5] = "Germany";
                    Country[Country["Austria"] = 6] = "Austria";
                    Country[Country["Estonia"] = 7] = "Estonia";
                    Country[Country["Israel"] = 8] = "Israel";
                    Country[Country["Slovakia"] = 9] = "Slovakia";
                    Country[Country["Ukraine"] = 10] = "Ukraine";
                })(Country = Code.Country || (Code.Country = {}));
                var CountryIso2;
                (function (CountryIso2) {
                    CountryIso2["Belgium"] = "BE";
                    CountryIso2["Netherlands"] = "NL";
                    CountryIso2["France"] = "FR";
                    CountryIso2["Luxembourg"] = "LU";
                    CountryIso2["Germany"] = "DE";
                    CountryIso2["Austria"] = "AT";
                    CountryIso2["Estonia"] = "EE";
                    CountryIso2["Israel"] = "IL";
                    CountryIso2["Slovakia"] = "SK";
                    CountryIso2["Ukraine"] = "UA";
                })(CountryIso2 = Code.CountryIso2 || (Code.CountryIso2 = {}));
            })(Code = Contract.Code || (Contract.Code = {}));
        })(Contract = Shared.Contract || (Shared.Contract = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
