module RemeCare.Shared.Framework.Factory {
    
    export interface IDeferredFactory<TServer, TResult> {
        create(serverObject: TServer): ng.IPromise<TResult>
    }

    export class DeferredArrayFactory {

        constructor(private $q: ng.IQService) {
            
        }
        
        createMany<TServer, TResult>(serverObjects: Array<TServer>,
            create: (o: TServer) => ng.IPromise<TResult>):
            ng.IPromise<Array<TResult>> {
            var deferred = this.$q.defer<Array<TResult>>();
            var promises = _(serverObjects).map(s => create(s));
            this.$q.all(promises)
                .then((r: Array<TResult>) => deferred.resolve(r));
            return deferred.promise;
        }
    }

    remeCareSharedModule.factory('deferredArrayFactory', ($q) => new DeferredArrayFactory($q));
}