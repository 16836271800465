namespace RemeCare.Shared.Directive {
    import Document = Shared.Contract.IMetaDataDocument;

    export enum FileUploadTarget {
        Document = 0,
        Guidance = 1,
        GenericDocument = 2,
    }

    class ImmediateUploaderController implements ng.IComponentController {
        public documents: Document[];
        public uploader;
        public max: number;
        public fileExtensions: Shared.Contract.IEntityTranslation[];
        public advancedColumns: string[];
        public target: FileUploadTarget;

        public showProgress: boolean;
        public progress: number;

        private onItemAdded: (params: { doc: Document }) => (d: Document) => void;
        private onItemDeleted: (params: { doc: Document }) => (d: Document) => void;

        private expectedNumberOfDocuments: number;
        private targetUrl: string;

        constructor(
            private readonly $translate: ng.translate.ITranslateService,
            private readonly $timeout: ng.ITimeoutService,
            private readonly $filter: ng.IFilterService,
            private readonly toaster: Shared.Framework.Toaster,
            private readonly FileUploader,
            private readonly baseUrl: string
        ) { }

        public $onInit(): void {
            this.max = this.max || 5;
            this.documents = this.documents || [];
            this.target = this.target || FileUploadTarget.Document;
            this.targetUrl = this.getTargetUrl(this.target);
            this.createUploader();
            this.setDocuments();
        }

        public $doCheck(): void {
            if (!this.documents && this.expectedNumberOfDocuments !== 0) {
                this.setDocuments();
            }

            if (this.documents && this.documents.length !== this.expectedNumberOfDocuments) {
                this.setDocuments();
            }
        }

        private getTargetUrl(uploadTarget: FileUploadTarget): string {
            if (uploadTarget === FileUploadTarget.Guidance) {
                return 'Documents/guidance';
            }
            if (uploadTarget === FileUploadTarget.GenericDocument) {
                return 'Documents/Generic';
            }
            return 'Documents';
        }

        private createUploader(): void {
            const uploader = new this.FileUploader({
                scope: this,
                url: this.baseUrl + this.targetUrl,
                filters: [
                    {
                        name: 'fileSize',
                        fn: (item) => {
                            if (item.size <= 5 * 1024 * 1024) {
                                return true;
                            }
                            return false;
                        },
                    },
                    {
                        name: 'fileType',
                        fn: (item) => {
                            if (this.fileExtensions != null && this.fileExtensions.length > 0) {
                                return _(this.fileExtensions).any(
                                    (f) =>
                                        item.name
                                            .toLowerCase()
                                            .indexOf(f.Text.toLowerCase(), item.name.length - f.Text.length) !== -1
                                );
                            }
                            return true;
                        },
                    },
                    {
                        name: 'fileNameSize',
                        fn: (item) => {
                            return item.name.length <= 150;
                        },
                    },
                ],
                queueLimit: this.max || 5,
            });

            uploader.onErrorItem = (item) => {
                this.showProgress = false;
                this.uploader.removeFromQueue(item);
                this.toaster.error(this.$translate.instant('Document.UploadError'));
            };

            uploader.onSuccessItem = (item, response: Document) => {
                this.showProgress = false;
                item.downloadUrl = this.baseUrl + this.targetUrl + '/' + response.Id;
                item.id = response.Id;
                response.Size = item.file.size;
                item.remove = () => {
                    this.onDocumentDelete(item, response);
                };
                if (this.onItemAdded) {
                    const func = this.onItemAdded({ doc: response });
                    if (_.isFunction(func)) {
                        func(response);
                    }
                }

                this.expectedNumberOfDocuments++;
                this.documents.push(response);
            };

            uploader.onAfterAddingFile = () => {
                this.$timeout(() => {
                    if (!this.uploadQueueEmpty()) {
                        this.uploader.uploadAll(() => { });
                        this.showProgress = true;
                    }
                });
            };

            uploader.onWhenAddingFileFailed = (item, filter) => {
                switch (filter.name) {
                    case 'fileType':
                        const fileTypes = this.$filter<(a, b) => string>('delimitedDisplay')(this.fileExtensions, ', ');
                        this.toaster.error(this.$translate.instant('General.FileUpload.FileTypeIncorrect') + fileTypes);
                        break;
                    case 'fileSize':
                        this.toaster.error(this.$translate.instant('General.FileUpload.FileTooLarge'));
                        break;
                    case 'fileNameSize':
                        this.toaster.error(this.$translate.instant('General.FileUpload.FileNameTooLarge'));
                        break;
                }
            };

            uploader.onProgressItem = (item, progress) => {
                this.progress = progress;
            };

            this.uploader = uploader;
        }

        private onDocumentDelete(item, document): void {
            // this.documentSvc.deleteDocument(document.Id).then(() => {
            this.uploader.removeFromQueue(item);
            const doc = _.find(this.documents, (d) => d.Id === document.Id);
            const index = this.documents.indexOf(doc);
            this.expectedNumberOfDocuments--;
            this.documents.splice(index, 1);
            if (this.onItemDeleted) {
                const func = this.onItemDeleted({ doc: doc });
                if (_.isFunction(func)) {
                    func(doc);
                }
            }
        }

        private uploadQueueEmpty(): boolean {
            return _.all(this.uploader.queue, (item: any) => {
                return item.isUploaded;
            });
        }

        private setDocuments(): void {
            this.uploader.queue = [];
            this.expectedNumberOfDocuments = this.documents.length;
            angular.forEach(this.documents, (document: Document) => {
                const item = new this.FileUploader.FileItem(this.uploader, {
                    name: document.Name ? document.Name.replace(/\"/g, '') : '',
                    size: document.Size,
                });
                item.progress = 100;
                item.isUploaded = true;
                item.isSuccess = true;
                item.downloadUrl = this.baseUrl + this.targetUrl + '/' + document.Id;
                item.remove = null;
                item.remove = () => {
                    this.onDocumentDelete(item, document);
                };
                item.id = document.Id;
                this.uploader.queue.push(item);
            });
        }
    }

    remeCareSharedModule.component('rcUploader', {
        controller: ImmediateUploaderController,
        templateUrl: 'views/uploader.html',
        bindings: {
            readOnly: '<ngReadonly',
            ngRequired: '<?',
            documents: '=',
            max: '<?',
            onItemAdded: '&',
            onItemDeleted: '&',
            fileExtensions: '=?',
            advancedColumns: '=?',
            advancedColumnsData: '&?',
            target: '<?',
        },
        require: {
            formCtrl: '^form',
        },
    });
}
