var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Directive;
            (function (Directive) {
                var InputRadioToggleController = /** @class */ (function () {
                    function InputRadioToggleController($timeout) {
                        this.$timeout = $timeout;
                    }
                    InputRadioToggleController.prototype.$onInit = function () { };
                    InputRadioToggleController.prototype.onChange = function () {
                        var _this = this;
                        if (this.change) {
                            this.$timeout(function () {
                                _this.change();
                            });
                        }
                    };
                    return InputRadioToggleController;
                }());
                Shared.remeCareSharedModule.component('rcInputRadioToggle', {
                    bindings: {
                        label: '@',
                        model: '=ngModel',
                        labelOff: '@',
                        labelOn: '@',
                        change: '&?ngChange',
                        info: '@?',
                        guidanceIconLocation: '<',
                        guidanceIconFunc: '<',
                        uniqueId: '@',
                    },
                    controller: InputRadioToggleController,
                    require: {
                        formCtrl: '^form',
                    },
                    templateUrl: 'framework/directives/formElements/inputRadioToggle/inputRadioToggle.html',
                });
            })(Directive = Framework.Directive || (Framework.Directive = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
