var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var AboutController = /** @class */ (function () {
                function AboutController($scope, $cookies, $translate, toaster, appVersionSvc, appConfig) {
                    this.$scope = $scope;
                    this.$cookies = $cookies;
                    this.$translate = $translate;
                    this.toaster = toaster;
                    this.appVersionSvc = appVersionSvc;
                    this.appConfig = appConfig;
                    this.$scope.languageSettings = {
                        selectedLanguage: $translate.use(),
                        availableLanguages: ['nl', 'fr', 'en', 'de'],
                    };
                    this.$scope.manualsUrl = this.appConfig.manualsUrl;
                }
                AboutController.prototype.$onInit = function () {
                    var _this = this;
                    this.appVersionSvc
                        .getApplicationInformation()
                        .success(function (r) {
                        _this.$scope.applicationInformation = r;
                    })
                        .error(function (e) { return _this.toaster.error(e); });
                };
                return AboutController;
            }());
            Shared.remeCareSharedModule.controller('aboutCtrl', AboutController);
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
