/// <reference path="../models/periodicity.ts"/>
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Directive;
            (function (Directive) {
                var RecurrentCycle = RemeCare.Shared.Framework.Model.RecurrentCycle;
                var PeriodicityDirective = /** @class */ (function () {
                    function PeriodicityDirective() {
                        this.scope = {
                            periodicity: '=ngModel',
                            possibleDurationUnits: '=durationUnits',
                            newPeriodicity: '&?newPeriodicity',
                            isFirstInRecurrence: '&?isFirst',
                            isLastInRecurrence: '&?isLast',
                            setRecurrentCycleMembers: '&?setMembers',
                            setEvenOddDays: '&?setEvenOdd',
                            form: '=',
                            readOnly: '=?',
                        };
                        this.templateUrl = 'views/periodicity/periodicity.html';
                        this.controller = 'periodicityCtrl';
                    }
                    return PeriodicityDirective;
                }());
                Shared.remeCareSharedModule.directive('rcPeriodicity', function () { return new PeriodicityDirective(); });
                var PeriodicityOverviewController = /** @class */ (function () {
                    function PeriodicityOverviewController($scope, gridBuilderSvc, toaster, modalBuilderFactory) {
                        var _this = this;
                        this.$scope = $scope;
                        this.gridBuilderSvc = gridBuilderSvc;
                        this.toaster = toaster;
                        this.modalBuilderFactory = modalBuilderFactory;
                        $scope.addPeriodicity = function () { return _this.addPeriodicityAsync(); };
                        this.$onInit();
                    }
                    PeriodicityOverviewController.prototype.$onInit = function () {
                        var _this = this;
                        if (this.$scope.createGrid) {
                            this.buildPeriodicitiesGrid();
                        }
                        else {
                            var watch_1 = this.$scope.$watch(function (s) { return s.createGrid; }, function (newValue, oldValue) {
                                if (newValue !== oldValue && newValue) {
                                    _this.buildPeriodicitiesGrid();
                                    watch_1();
                                }
                            });
                        }
                        this.checkAddDisable();
                    };
                    PeriodicityOverviewController.prototype.checkAddDisable = function () {
                        var lastPeriodicity = _(this.$scope.periodicities).last();
                        this.$scope.disableAdd =
                            this.$scope.readOnly ||
                                (lastPeriodicity != null &&
                                    (!this.$scope.allowMultiple ||
                                        lastPeriodicity.duration == null ||
                                        lastPeriodicity.duration.Quantity == null));
                    };
                    PeriodicityOverviewController.prototype.buildPeriodicitiesGrid = function () {
                        var _this = this;
                        this.$scope.periodicities = _(this.$scope.periodicities).sortBy(function (p) { return p.sequence; });
                        var builder = this.gridBuilderSvc
                            .createGridBuilder()
                            .addColumn('sequence', 'Periodicity.Sequence')
                            .addColumn('recurrence', 'Periodicity.Repetition', { cellFilter: 'rcDurationDisplay' })
                            .addColumn('duration', 'Periodicity.Duration', { cellFilter: 'rcDurationDisplay' })
                            .addColumn('intraDayTimings.length', 'Periodicity.TimesPerDay');
                        if (this.$scope.readOnly) {
                            builder.addActionColumn('search', function (p) {
                                _this.editRecurrenceCycle(p);
                            }, true);
                        }
                        else {
                            builder
                                .addActionColumn('pencil', function (p) { return _this.editRecurrenceCycle(p); }, true)
                                .addDeleteButtonColumn(function (p) { return _this.removeAllPeriodicitiesInSequence(p); });
                        }
                        this.$scope.periodicitiesGrid = builder.build();
                        this.$scope.periodicitiesGrid.setData(this.$scope.periodicities);
                        var readOnlyWatch = this.$scope.$watch(function (s) { return s.readOnly; }, function (newValue, oldValue) {
                            if (newValue !== oldValue) {
                                readOnlyWatch();
                                collectionWatch();
                                _this.buildPeriodicitiesGrid();
                            }
                        });
                        var collectionWatch = this.$scope.$watchCollection(function (s) { return s.periodicities; }, function (newValue, oldValue) {
                            if (newValue !== oldValue) {
                                readOnlyWatch();
                                collectionWatch();
                                _this.buildPeriodicitiesGrid();
                            }
                        });
                    };
                    PeriodicityOverviewController.prototype.removeAllPeriodicitiesInSequence = function (periodicity) {
                        var _this = this;
                        var toRemove = _(this.$scope.periodicities).filter(function (p) { return p != null && p.sequence === periodicity.sequence; });
                        angular.forEach(toRemove, function (p) { return _this.$scope.periodicities.splice(_this.$scope.periodicities.indexOf(p), 1); });
                        this.correctSequence();
                        this.checkAddDisable();
                    };
                    PeriodicityOverviewController.prototype.correctSequence = function () {
                        var currentSequence = 0;
                        angular.forEach(this.$scope.periodicities, function (p) {
                            if (p.sequence > currentSequence) {
                                currentSequence++;
                            }
                            p.sequence = currentSequence;
                        });
                    };
                    PeriodicityOverviewController.prototype.addPeriodicityAsync = function () {
                        return __awaiter(this, void 0, void 0, function () {
                            var r, maxSequence_1, e_1;
                            var _this = this;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        _a.trys.push([0, 2, , 3]);
                                        return [4 /*yield*/, this.editPeriodicityAsync()];
                                    case 1:
                                        r = _a.sent();
                                        maxSequence_1 = 0;
                                        if (this.$scope.periodicities.length > 0) {
                                            maxSequence_1 = _(this.$scope.periodicities).max(function (p) { return p.sequence; }).sequence;
                                        }
                                        maxSequence_1++;
                                        _(r).forEach(function (p) {
                                            p.sequence = maxSequence_1;
                                            _this.$scope.periodicities.push(p);
                                        });
                                        this.checkAddDisable();
                                        return [3 /*break*/, 3];
                                    case 2:
                                        e_1 = _a.sent();
                                        this.toaster.error(e_1);
                                        return [3 /*break*/, 3];
                                    case 3: return [2 /*return*/];
                                }
                            });
                        });
                    };
                    PeriodicityOverviewController.prototype.editPeriodicityAsync = function (recurrenceCycle) {
                        var _this = this;
                        if (recurrenceCycle != null) {
                            recurrenceCycle = recurrenceCycle.map(function (p) { return p.copy(); });
                        }
                        return new Promise(function (resolve) {
                            _this.modalBuilderFactory
                                .createModalBuilder()
                                .setController('periodicityModalCtrl')
                                .setTemplateUrl('views/periodicity/periodicityModal.html')
                                .setResolve({
                                therapyActionPartContextSettings: function () { return _this.$scope.therapyActionPartContextSettings; },
                                productIds: function () {
                                    return _this.$scope.possibleProductsForAction
                                        ? _(_this.$scope.possibleProductsForAction)
                                            .chain()
                                            .filter(function (p) { return p.ProductId != null; })
                                            .map(function (p) { return p.ProductId; })
                                            .value()
                                        : _this.$scope.productId
                                            ? [_this.$scope.productId]
                                            : [];
                                },
                                productClassIds: function () {
                                    return _(_this.$scope.possibleProductsForAction)
                                        .chain()
                                        .filter(function (p) { return p.ProductClassId != null; })
                                        .map(function (p) { return p.ProductClassId; })
                                        .value();
                                },
                            })
                                .setScope({
                                readOnly: _this.$scope.readOnly,
                                recurrentCycle: new RecurrentCycle(recurrenceCycle),
                                forMedication: _this.$scope.forMedication,
                                limitedConfig: _this.$scope.limitedConfig,
                                medication: _this.$scope.medication,
                                carePlanStartDate: _this.$scope.carePlanStartDate
                            })
                                .setSize(Framework.Helper.ModalSize.large)
                                .setResultCallBack(function (r) {
                                resolve(r);
                                _this.checkAddDisable();
                            })
                                .build();
                        });
                    };
                    PeriodicityOverviewController.prototype.showPeriodicityReadOnly = function (recurrenceCycle, periodicity) {
                        this.modalBuilderFactory
                            .createComponentModalBuilder('rcShowReadOnlyPeriodicity')
                            .setBindings({
                            recurrentCycle: new RecurrentCycle(recurrenceCycle),
                            carePlanStartDate: this.$scope.carePlanStartDate,
                        })
                            .setLarge()
                            .build();
                    };
                    PeriodicityOverviewController.prototype.editRecurrenceCycle = function (periodicity) {
                        var scope = this.$scope;
                        var recurrenceCycle = _(scope.periodicities)
                            .chain()
                            .filter(function (p) { return p.sequence === periodicity.sequence; })
                            .sortBy(function (p) { return p.sequenceInRecurrenceCycle; })
                            .value();
                        if (!this.$scope.forMedication && this.$scope.readOnly) {
                            this.showPeriodicityReadOnly(recurrenceCycle);
                        }
                        else {
                            this.editPeriodicityAsync(recurrenceCycle).then(function (r) {
                                _(recurrenceCycle).forEach(function (p) { return scope.periodicities.splice(scope.periodicities.indexOf(p), 1); });
                                _(r).forEach(function (p) { return scope.periodicities.push(p); });
                                scope.periodicities.sort(function (x, y) { return x.sequence * 100 + x.sequence - (y.sequence * 100 + y.sequence); });
                            });
                        }
                    };
                    return PeriodicityOverviewController;
                }());
                var PeriodicityOverviewDirective = /** @class */ (function () {
                    function PeriodicityOverviewDirective() {
                        this.scope = {
                            therapyActionPartContextSettings: '=',
                            possibleProductsForAction: '=',
                            productId: '=',
                            readOnly: '=ngReadonly',
                            periodicities: '=',
                            createGrid: '=',
                            forMedication: '=',
                            limitedConfig: '=',
                            medication: '=',
                            allowMultiple: '=',
                            carePlanStartDate: '<',
                        };
                        this.controller = [
                            '$scope',
                            'gridBuilderSvc',
                            'toaster',
                            'modalBuilderFactory',
                            function ($scope, gridBuilderSvc, toaster, modalBuilderFactory) {
                                return new PeriodicityOverviewController($scope, gridBuilderSvc, toaster, modalBuilderFactory);
                            },
                        ];
                        this.restrict = 'E';
                        this.templateUrl = 'views/periodicity/periodicityOverview.html';
                    }
                    return PeriodicityOverviewDirective;
                }());
                Shared.remeCareSharedModule.directive('rcPeriodicityOverview', function () { return new PeriodicityOverviewDirective(); });
            })(Directive = Framework.Directive || (Framework.Directive = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
