var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Service;
            (function (Service) {
                var LoadMeasuringService = /** @class */ (function () {
                    function LoadMeasuringService($transitions, measuredStates, uuid4) {
                        this.$transitions = $transitions;
                        this.measuredStates = measuredStates;
                        this.uuid4 = uuid4;
                        this.setTransitionHooks();
                    }
                    LoadMeasuringService.prototype.getMeasuringContext = function () {
                        return this.measuringContext;
                    };
                    LoadMeasuringService.prototype.getMeasuringId = function () {
                        return this.measuringId;
                    };
                    /**
                     * Restarts measuring if any measurement was active
                     */
                    LoadMeasuringService.prototype.reset = function () {
                        if (this.isMeasuring) {
                            this.startMeasuring();
                        }
                    };
                    LoadMeasuringService.prototype.registerStateToMeasure = function (state) {
                        this.measuredStates[state] = true;
                    };
                    LoadMeasuringService.prototype.unregisterStateToMeasure = function (state) {
                        delete this.measuredStates[state];
                    };
                    LoadMeasuringService.prototype.stopMeasuring = function () {
                        this.isMeasuring = false;
                        this.measuringContext = null;
                        this.measuringId = null;
                    };
                    LoadMeasuringService.prototype.setTransitionHooks = function () {
                        var _this = this;
                        this.$transitions.onSuccess({}, function (transition) {
                            var to = transition.to().name;
                            if (_this.measuredStates[to]) {
                                _this.startMeasuring(to);
                            }
                        });
                        this.$transitions.onStart({}, function () {
                            _this.stopMeasuring();
                        });
                    };
                    LoadMeasuringService.prototype.startMeasuring = function (context) {
                        this.measuringId = this.uuid4.generate();
                        this.measuringContext = context || this.measuringContext;
                        this.isMeasuring = true;
                    };
                    return LoadMeasuringService;
                }());
                Service.LoadMeasuringService = LoadMeasuringService;
                var LoadMeasuringServiceProvider = /** @class */ (function () {
                    function LoadMeasuringServiceProvider() {
                        var _this = this;
                        this.measuredStates = {};
                        this.$get = ['$transitions', 'uuid4', function ($transitions, uuid4) {
                                return new LoadMeasuringService($transitions, _this.measuredStates, uuid4);
                            }];
                    }
                    LoadMeasuringServiceProvider.prototype.registerStateToMeasure = function (state) {
                        this.measuredStates[state] = true;
                    };
                    return LoadMeasuringServiceProvider;
                }());
                Service.LoadMeasuringServiceProvider = LoadMeasuringServiceProvider;
                Shared.remeCareSharedModule.provider('loadMeasuringSvc', LoadMeasuringServiceProvider);
            })(Service = Framework.Service || (Framework.Service = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
