namespace RemeCare.Shared.Directive {
    remeCareSharedModule.config(($datepickerProvider, $timepickerProvider) => {
        angular.extend($datepickerProvider.defaults, {
            startWeek: 1,
            autoclose: true,
            useNative: true,
            placement: 'bottom-right',
        });
        angular.extend($timepickerProvider.defaults, {
            timeFormat: 'HH:mm',
            roundDisplay: true,
            useNative: true,
        });
    });
    class DatepickerController implements ng.IComponentController {
        public date: Date;
        public minDate: string;
        public maxDate: string;
        public minTime: string;
        public maxTime: string;
        public required: boolean;
        public disabledInput: boolean;
        public showTime: boolean;
        public showDate: boolean;
        public startingView: string;

        public startView: number;
        public dateModel: {
            date: Date;
            dateTime: Date;
        } = {
            date: undefined,
            dateTime: undefined,
        };
        public placeholder: string;
        public fromDate: number;
        public untilDate: number;
        public fromTime: string;
        public untilTime: string;
        public changeFunction: () => void;
        public id: number;
        private previousDateValue: number;

        // @ngInject
        constructor(
            $scope: ng.IScope,
            private readonly $locale: ng.ILocaleService,
            private readonly $timeout: ng.ITimeoutService
        ) {
            this.id = $scope.$id;
        }

        public $onInit(): void {
            switch (this.startingView) {
                case 'year':
                    this.startView = 2;
                    break;
                case 'month':
                    this.startView = 1;
                    break;
                case 'day':
                default:
                    this.startView = 0;
                    break;
            }
            this.placeholder = this.$locale.DATETIME_FORMATS.shortTime;
            this.showDate = this.showDate == null ? true : this.showDate;
            this.showTime = this.showTime || !this.showDate;
            if (this.date && moment(this.date).isValid()) {
                this.previousDateValue = this.date.valueOf();
                this.dateModel = {
                    date: this.toDate(this.date),
                    dateTime: new Date(this.date.valueOf()),
                };
            } else {
                this.dateModel = { date: null, dateTime: null };
                if (this.showTime && !this.showDate) {
                    // scope.date = new Date();
                    this.dateModel.date = new Date(); // scope.date;
                }
                if (!this.showTime) {
                    this.dateModel.dateTime = new Date();
                }
            }

            if (this.minDate != null) {
                this.fromDate = moment(this.minDate)
                    .startOf('day')
                    .valueOf();
            }
            if (this.maxDate != null) {
                this.untilDate = moment(this.maxDate)
                    .startOf('day')
                    .valueOf();
            }
        }

        public $onChanges(changes: ng.IOnChangesObject): void {
            this.$timeout(() => {
                if (changes.minDate) {
                    const newValue = changes.minDate.currentValue;
                    this.fromDate =
                        newValue == null
                            ? null
                            : moment(newValue)
                                  .startOf('day')
                                  .valueOf();
                }
                if (changes.maxDate) {
                    const newValue = changes.maxDate.currentValue;
                    this.untilDate =
                        newValue == null
                            ? null
                            : moment(newValue)
                                  .startOf('day')
                                  .valueOf();
                }
                if (changes.minTime) {
                    const newValue = changes.minTime.currentValue;
                    this.fromTime = _.isDate(newValue) ? moment(newValue).format('HH:mm') : newValue;
                }
                if (changes.maxTime) {
                    const newValue = changes.maxTime.currentValue;
                    this.untilTime = _.isDate(newValue) ? moment(newValue).format('HH:mm') : newValue;
                }
            }, 0);
        }

        public $doCheck(): void {
            const currentValue = this.date && this.date.valueOf();
            if (this.previousDateValue !== currentValue) {
                this.previousDateValue = currentValue;
                if (this.date != null) {
                    this.dateModel.date = new Date(currentValue);
                    this.dateModel.dateTime = new Date(currentValue);
                } else {
                    if (this.showDate) {
                        this.dateModel.date = null;
                    }
                    if (this.showTime) {
                        this.dateModel.dateTime = null;
                    }
                }
            }
        }

        public dateChanged(): void {
            if (this.dateModel.date == null && (!this.showTime || this.dateModel.dateTime == null)) {
                this.date = null;
            } else if (
                _.isDate(this.dateModel.date) &&
                _.isDate(this.dateModel.dateTime) &&
                moment(this.dateModel.date).isValid() &&
                moment(this.dateModel.dateTime).isValid()
            ) {
                const hours = this.showTime ? this.dateModel.dateTime.getHours() : 0;
                const minutes = this.showTime ? this.dateModel.dateTime.getMinutes() : 0;
                this.date = new Date(
                    this.dateModel.date.getFullYear(),
                    this.dateModel.date.getMonth(),
                    this.dateModel.date.getDate(),
                    hours,
                    minutes,
                    0,
                    0
                );
            }
            this.previousDateValue = this.date && this.date.valueOf();
            this.changed();
        }

        private toDate(date: Date): Date {
            // NULL protection
            if (!date) {
                return date;
            }

            if (!(date instanceof Date && !isNaN(date.valueOf()))) {
                date = moment(date).toDate();
            }
            return date;
        }

        private changed(): void {
            if (this.changeFunction != null) {
                this.$timeout(() => {
                    this.changeFunction();
                }, 0);
            }
        }
    }

    remeCareSharedModule.component('rcDatepicker', {
        controller: DatepickerController,
        templateUrl: 'views/datepicker.html',
        bindings: {
            date: '=ngModel',
            minDate: '<minDate',
            maxDate: '<maxDate',
            minTime: '<minTime',
            maxTime: '<maxTime',
            required: '=?ngRequired',
            disabledInput: '=?ngDisabled',
            showTime: '<?',
            showDate: '<?',
            changeFunction: '&?change',
            hasWarning: '<?ngWarning',
            startingView: '@?',
        },
    });

    class SeparatedDatePicker implements ng.IComponentController {
        public id: number;
        public date: Date;
        public time: Date;
        public minDate: Date;
        public maxDate: Date;
        public minTime: Date;
        public maxTime: Date;
        public showDate: boolean;
        public showTime: boolean;
        public placeholder: string;
        public isValid: boolean;

        constructor(private $scope: ng.IScope, private $locale: ng.ILocaleService) {}

        public $onInit(): void {
            this.id = this.$scope.$id;
            this.placeholder = this.$locale.DATETIME_FORMATS.shortTime;
        }

        public $onChanges(): void {
            this.setIsValid();
        }

        public isDateShown(): boolean {
            return this.showDate || this.showDate == null || !this.showTime;
        }

        public isTimeShown(): boolean {
            return this.showTime || this.showDate === false;
        }

        public setIsValid(): void {
            // The time-picker doesn't seem to play well with ui-validate. That's we we use a hidden checkbox
            // bound to isValid to check the validity
            const currentDate = DateHelper.mergeDateAndTime(this.date, this.time);
            const minDate = this.minDate || (this.isDateShown() ? DateHelper.startOfTime() : DateHelper.today());
            const maxDate = this.maxDate || (this.isDateShown() ? DateHelper.endOfTime() : DateHelper.today());
            const minTime = this.minTime || DateHelper.today();
            const maxTime =
                this.maxTime ||
                moment(DateHelper.today())
                    .endOf('day')
                    .toDate();
            const minDateTime = DateHelper.mergeDateAndTime(minDate, minTime);
            const maxDateTime = DateHelper.mergeDateAndTime(maxDate, maxTime);

            this.isValid = !moment(minDateTime).isAfter(currentDate) && !moment(currentDate).isAfter(maxDateTime);
        }
    }

    remeCareSharedModule.component('rcSeparatedDatepicker', {
        controller: SeparatedDatePicker,
        controllerAs: 'dateCtrl',
        templateUrl: 'views/separatedDatepicker.html',
        bindings: {
            date: '=?',
            time: '=?',
            minDate: '<minDate',
            maxDate: '<maxDate',
            minTime: '<minTime',
            maxTime: '<maxTime',
            ngDisabled: '<?',
            ngRequired: '<?',
            showDate: '<?',
            showTime: '<?',
        },
    });
}
