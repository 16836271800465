var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var ChangeVersionValidityController = /** @class */ (function () {
                function ChangeVersionValidityController($scope) {
                    this.$scope = $scope;
                }
                ChangeVersionValidityController.prototype.$onInit = function () {
                    var _this = this;
                    var minDate = moment()
                        .add(1, 'days')
                        .startOf('day')
                        .toDate();
                    var previousVersion = _(_(this.$scope.versions).filter(function (v) { return Shared.DateHelper.serverDateStringToDate(v.ValidFromDate) < _this.$scope.change.pointInTime; }))
                        .sortBy(function (v) { return v.ValidFromDate; })
                        .reverse()[0];
                    this.$scope.minDate =
                        previousVersion &&
                            moment(previousVersion.ValidFromDate, 'YYYY-MM-DD')
                                .add(1, 'day')
                                .toDate() > minDate
                            ? moment(previousVersion.ValidFromDate, 'YYYY-MM-DD')
                                .add(1, 'day')
                                .toDate()
                            : minDate;
                    var maxDate = Shared.DateHelper.endOfTime();
                    var nextVersion = _(_(this.$scope.versions).filter(function (v) { return Shared.DateHelper.serverDateStringToDate(v.ValidFromDate) > _this.$scope.change.pointInTime; })).sortBy(function (v) { return v.ValidFromDate; })[0];
                    this.$scope.maxDate =
                        nextVersion &&
                            moment(nextVersion.ValidFromDate, 'YYYY-MM-DD')
                                .add(-1, 'day')
                                .toDate() < maxDate
                            ? moment(nextVersion.ValidFromDate, 'YYYY-MM-DD')
                                .add(-1, 'day')
                                .toDate()
                            : maxDate;
                    this.$scope.confirm = function () { return _this.confirm(); };
                };
                ChangeVersionValidityController.prototype.confirm = function () {
                    this.$scope.$close(this.$scope.change);
                };
                return ChangeVersionValidityController;
            }());
            Framework.ChangeVersionValidityController = ChangeVersionValidityController;
            Shared.remeCareSharedModule.controller('changeVersionValidityCtrl', ChangeVersionValidityController);
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
