var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Contract;
        (function (Contract) {
            var Code;
            (function (Code) {
                var ContactAttribute;
                (function (ContactAttribute) {
                    ContactAttribute[ContactAttribute["Language"] = 1] = "Language";
                    ContactAttribute[ContactAttribute["Email"] = 2] = "Email";
                    ContactAttribute[ContactAttribute["Mobile"] = 3] = "Mobile";
                    ContactAttribute[ContactAttribute["Address"] = 4] = "Address";
                    ContactAttribute[ContactAttribute["FirstName"] = 5] = "FirstName";
                    ContactAttribute[ContactAttribute["LastName"] = 6] = "LastName";
                    ContactAttribute[ContactAttribute["Title"] = 7] = "Title";
                    ContactAttribute[ContactAttribute["Gender"] = 8] = "Gender";
                    ContactAttribute[ContactAttribute["Age"] = 9] = "Age";
                    ContactAttribute[ContactAttribute["IsOptedOut"] = 10] = "IsOptedOut";
                    ContactAttribute[ContactAttribute["Name"] = 11] = "Name";
                    ContactAttribute[ContactAttribute["ActorRoleId"] = 12] = "ActorRoleId";
                })(ContactAttribute = Code.ContactAttribute || (Code.ContactAttribute = {}));
            })(Code = Contract.Code || (Contract.Code = {}));
        })(Contract = Shared.Contract || (Shared.Contract = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
