namespace RemeCare.Shared.Framework.Service {

    class LoadMeasurementHttpInterceptor implements ng.IHttpInterceptor {

        constructor(
            private readonly loadMeasuringSvc: LoadMeasuringService,
            private readonly settingsSvc: SettingsService
        ) {}

        public request = (
            config: angular.IRequestConfig
        ): angular.IRequestConfig | angular.IPromise<angular.IRequestConfig>  => {
            if (!this.settingsSvc.getSettings().sendLogs) {
                return config;
            }

            const measuringContext = this.loadMeasuringSvc.getMeasuringContext();
            const measuringId = this.loadMeasuringSvc.getMeasuringId();
            if (measuringContext) {
                config.headers['X-PageLoadContext'] = measuringContext;
            }

            if (measuringId) {
                config.headers['X-PageLoadId'] = measuringId;
            }

            return config;
        }
    }

    remeCareSharedModule.factory('loadMeasuringHttpInterceptor',
        (
            loadMeasuringSvc: LoadMeasuringService,
            settingsSvc: SettingsService
        ) => new LoadMeasurementHttpInterceptor(
            loadMeasuringSvc,
            settingsSvc
        ));
}