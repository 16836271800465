var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Directive;
            (function (Directive) {
                var CloseButton = /** @class */ (function () {
                    function CloseButton() {
                    }
                    CloseButton.prototype.$onInit = function () {
                        this.label = this.label || 'General.Close';
                    };
                    CloseButton.prototype.confirm = function () {
                        this.close();
                    };
                    return CloseButton;
                }());
                Shared.remeCareSharedModule.component('rcClose', {
                    controller: CloseButton,
                    controllerAs: 'btnCtrl',
                    templateUrl: 'views/controls/primaryButton.html',
                    bindings: {
                        close: '&',
                        label: '@?',
                        ngDisabled: '<?'
                    }
                });
            })(Directive = Framework.Directive || (Framework.Directive = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
