module RemeCare.Shared.Service {
    
    export class DocumentService extends RemeCare.Shared.Framework.ApiServiceBase {

        public static $inject = Shared.Framework.ApiServiceBase.injectionParameters;

        deleteDocument(documentId: Contract.Guid): ng.IHttpPromise<{}> {
            var url = 'Documents/' + documentId;
            return this.delete(url);
        }
    }

    remeCareSharedModule.service('documentSvc', DocumentService);
}