module RemeCare.Shared.Framework.Periodicity {
    import PeriodicityMonth = Framework.Model.PeriodicityMonth;
    import PeriodicityFactory = Framework.Factory.PeriodicityFactory;

    interface IPeriodicityMonthScope extends Framework.IBaseScope {
        periodicity: PeriodicityMonth;
        limitedDurationUnits: Array<Shared.Contract.IEnumTranslation>;
        extraLimitedDurationUnits: Array<Shared.Contract.IEnumTranslation>;
        repetitionTypeChange: () => void;
    }

    class PeriodicityMonthController extends Framework.ControllerBase<IPeriodicityMonthScope> {

        constructor(
            $scope: IPeriodicityMonthScope,
            $translate,
            toaster,
            private $timeout: ng.ITimeoutService,
            private masterdataSvc: Framework.MasterdataService) {
            super($scope, $translate, toaster);

            $scope.repetitionTypeChange = () => this.repetitionTypeChange();
        }

        public $onInit(): void {}

        private watchPeriodicityType(): void {
            this.$scope.$watch((s: IPeriodicityMonthScope) => s.periodicity.type,
                (newValue: Shared.Contract.Code.PeriodicityType, oldValue: Shared.Contract.Code.PeriodicityType) => {
                if (newValue !== oldValue) {
                    this.$scope.periodicity.monthRepetitionType = 1;
                }
            });
        }

        private repetitionTypeChange(): void {
            this.$scope.periodicity.dayOfMonth = null;
            this.$scope.periodicity.weekDayOccurence = null;
            this.$scope.periodicity.dayOfWeek = null;
            this.$scope.periodicity.childPeriodicity = null;
            if (this.$scope.periodicity.monthRepetitionType === 4 && this.$scope.periodicity.childPeriodicity == null) {
                this.$scope.periodicity.childPeriodicity = new PeriodicityFactory()
                    .createPeriodicityFromDurationUnit(this.$scope.limitedDurationUnits[0].Id, this.$scope.periodicity);
            }
        }
    }

    remeCareSharedModule.controller('periodicityMonthCtrl', (
        $scope,
        $translate,
        toaster,
        $timeout,
        masterdataSvc
    ) => new PeriodicityMonthController(
        $scope,
        $translate,
        toaster,
        $timeout,
        masterdataSvc
    ));
}