module RemeCare.Shared.Framework {
    class CheckApplicationProfileController implements ng.IController {
        public applicationProfiles: Contract.IEnumTranslation[];

        constructor(
            private readonly $state: ng.ui.IStateService,
            private readonly $stateParams,
            private readonly toaster: Shared.Framework.Toaster,
            private readonly modalBuilderFactory: Shared.Framework.Helper.ModalBuilderFactory,
            private readonly informedConsentSvc: Shared.Framework.Service.InformedConsentService,
            private readonly securityApiSvc: Service.SecurityApiSvc,
            private readonly authservice: AuthService
        ) {}

        public $onInit(): void {
            this.getApplicationProfiles();
        }

        public async getApplicationProfiles(): Promise<void> {
            this.applicationProfiles = await this.securityApiSvc.findMyApplicationProfilesAsync();
            if (this.applicationProfiles.length > 1) {
                this.$state.go('applicationProfile', {
                    redirect: this.$stateParams.redirect,
                    params: this.$stateParams.params
                });
            } else if (this.applicationProfiles.length === 1) {
                this.setApplicationProfile();
            } else {
                this.$state.go('unauthorized');
            }
        }

        private async setApplicationProfile(): Promise<void> {
            try {
                const r = await this.securityApiSvc.setApplicationProfile(this.applicationProfiles[0].Id);
                this.authservice.setRights(r.Rights);
                this.authservice.setClaim(ClaimTypes.applicationProfile, this.applicationProfiles[0].Id.toString());
                this.authservice.setClaim(ClaimTypes.partyRoleId, <string>r.PartyRoleId);
                const newState = this.$stateParams.redirect ? this.$stateParams.redirect : 'home';
                this.$state.go(newState, this.$stateParams.params);
            } catch (e) {
                this.toaster.error(e);
            }
        }
    }

    remeCareSharedModule.controller('checkApplicationProfileController', CheckApplicationProfileController);
}
