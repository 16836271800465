var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var InformedConsent;
            (function (InformedConsent) {
                var PatientInformedConsent = Shared.Framework.Model.PatientInformedConsent;
                var PatientInformedConsentsController = /** @class */ (function () {
                    function PatientInformedConsentsController($dialog, $translate, gridBuilderSvc, $state, $stateParams, informedConsentSvc, authservice, toaster, patientInformedConsentService) {
                        this.$dialog = $dialog;
                        this.$translate = $translate;
                        this.gridBuilderSvc = gridBuilderSvc;
                        this.$state = $state;
                        this.$stateParams = $stateParams;
                        this.informedConsentSvc = informedConsentSvc;
                        this.authservice = authservice;
                        this.toaster = toaster;
                        this.patientInformedConsentService = patientInformedConsentService;
                    }
                    PatientInformedConsentsController.prototype.$onInit = function () {
                        this.buildGrid();
                        if (!this.patientInformedConsentService.shouldCheckPatientInformedConsents()) {
                            this.goToNextState();
                            return;
                        }
                        this.patientId = this.authservice.getClaim(Framework.ClaimTypes.partyRoleId);
                        this.loadPatientInformedConsents();
                    };
                    PatientInformedConsentsController.prototype.refuse = function () {
                        var _this = this;
                        this.$dialog
                            .warningMessageBox(this.$translate.instant('Views.InformedConsent.RefuseTitle'), this.$translate.instant('Views.InformedConsent.RefuseWarning'), [
                            { result: 'cancel', label: this.$translate.instant('General.Cancel'), cssClass: 'btn-default' },
                            {
                                result: 'yes',
                                label: this.$translate.instant('Views.InformedConsent.AcceptRefuse'),
                                cssClass: 'btn-primary'
                            }
                        ])
                            .then(function (result) { return __awaiter(_this, void 0, void 0, function () {
                            var patientInformedConsentReadModel, e_1;
                            var _this = this;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        if (!(result === 'yes')) return [3 /*break*/, 8];
                                        _a.label = 1;
                                    case 1:
                                        _a.trys.push([1, 7, , 8]);
                                        return [4 /*yield*/, this.informedConsentSvc.saveInformedConsent(this.patientId, this.informedConsent.informedConsentId, false, null)];
                                    case 2:
                                        _a.sent();
                                        this.informedConsentsToAccept = _.filter(this.informedConsentsToAccept, function (ic) { return ic.InformedConsentId !== _this.informedConsent.informedConsentId; });
                                        this.grid.setData(this.informedConsentsToAccept);
                                        this.informedConsent = null;
                                        if (!(this.acceptedInformedConsents.length < 1 && this.informedConsentsToAccept.length < 1)) return [3 /*break*/, 3];
                                        this.authservice.signOut();
                                        return [3 /*break*/, 6];
                                    case 3:
                                        if (!(this.informedConsentsToAccept.length < 1)) return [3 /*break*/, 4];
                                        this.goToNextState();
                                        return [3 /*break*/, 6];
                                    case 4: return [4 /*yield*/, this.informedConsentSvc.getPatientInformedConsent(this.patientId, this.informedConsentsToAccept[0].InformedConsentId)];
                                    case 5:
                                        patientInformedConsentReadModel = _a.sent();
                                        this.informedConsent = new PatientInformedConsent(patientInformedConsentReadModel);
                                        _a.label = 6;
                                    case 6: return [3 /*break*/, 8];
                                    case 7:
                                        e_1 = _a.sent();
                                        this.toaster.error(e_1);
                                        return [3 /*break*/, 8];
                                    case 8: return [2 /*return*/];
                                }
                            });
                        }); });
                    };
                    PatientInformedConsentsController.prototype.accept = function () {
                        return __awaiter(this, void 0, void 0, function () {
                            var patientInformedConsentReadModel, e_2;
                            var _this = this;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        if (!_.some(this.informedConsent.questions, function (q) { return q.accepted === false; })) return [3 /*break*/, 1];
                                        this.$dialog.warningMessageBox(null, this.$translate.instant('Views.InformedConsent.AcceptAllQuestions'), [{ result: 'Ok', label: this.$translate.instant('General.Ok'), cssClass: 'btn-default' }]);
                                        return [3 /*break*/, 7];
                                    case 1:
                                        _a.trys.push([1, 6, , 7]);
                                        return [4 /*yield*/, this.informedConsentSvc.saveInformedConsent(this.patientId, this.informedConsent.informedConsentId, true, null)];
                                    case 2:
                                        _a.sent();
                                        this.acceptedInformedConsents.push(_.find(this.informedConsentsToAccept, function (ic) { return ic.InformedConsentId === _this.informedConsent.informedConsentId; }));
                                        this.informedConsentsToAccept = _.filter(this.informedConsentsToAccept, function (ic) { return ic.InformedConsentId !== _this.informedConsent.informedConsentId; });
                                        this.grid.setData(this.informedConsentsToAccept);
                                        this.informedConsent = null;
                                        if (!(this.informedConsentsToAccept.length < 1)) return [3 /*break*/, 3];
                                        this.goToNextState();
                                        return [3 /*break*/, 5];
                                    case 3: return [4 /*yield*/, this.informedConsentSvc.getPatientInformedConsent(this.patientId, this.informedConsentsToAccept[0].InformedConsentId)];
                                    case 4:
                                        patientInformedConsentReadModel = _a.sent();
                                        this.informedConsent = new PatientInformedConsent(patientInformedConsentReadModel);
                                        _a.label = 5;
                                    case 5: return [3 /*break*/, 7];
                                    case 6:
                                        e_2 = _a.sent();
                                        this.toaster.error(e_2);
                                        return [3 /*break*/, 7];
                                    case 7: return [2 /*return*/];
                                }
                            });
                        });
                    };
                    PatientInformedConsentsController.prototype.loadPatientInformedConsents = function () {
                        return __awaiter(this, void 0, void 0, function () {
                            var informedConsentsSearchResult, patientInformedConsentReadModel, e_3;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        _a.trys.push([0, 5, , 6]);
                                        return [4 /*yield*/, this.informedConsentSvc.getInformedConsentSummary(this.patientId)];
                                    case 1:
                                        informedConsentsSearchResult = _a.sent();
                                        this.informedConsents = informedConsentsSearchResult.Items;
                                        this.acceptedInformedConsents = _.filter(this.informedConsents, function (ic) { return ic.Status === Shared.Contract.Code.PatientInformedConsentStatusCode.Accepted; });
                                        this.informedConsentsToAccept = _.filter(this.informedConsents, function (ic) { return ic.Status !== Shared.Contract.Code.PatientInformedConsentStatusCode.Accepted; });
                                        this.grid.setData(this.informedConsentsToAccept);
                                        if (!(this.informedConsentsToAccept.length > 0)) return [3 /*break*/, 3];
                                        return [4 /*yield*/, this.informedConsentSvc.getPatientInformedConsent(this.patientId, this.informedConsentsToAccept[0].InformedConsentId)];
                                    case 2:
                                        patientInformedConsentReadModel = _a.sent();
                                        this.informedConsent = new PatientInformedConsent(patientInformedConsentReadModel);
                                        return [3 /*break*/, 4];
                                    case 3:
                                        this.goToNextState();
                                        _a.label = 4;
                                    case 4: return [3 /*break*/, 6];
                                    case 5:
                                        e_3 = _a.sent();
                                        this.toaster.error(e_3);
                                        return [3 /*break*/, 6];
                                    case 6: return [2 /*return*/];
                                }
                            });
                        });
                    };
                    PatientInformedConsentsController.prototype.buildGrid = function () {
                        this.grid = this.gridBuilderSvc
                            .createGridBuilder()
                            .addColumn('TherapyName', 'General.Therapy')
                            .addColumn('Version', 'General.Version')
                            .addColumn('Title', 'General.Name')
                            .build();
                    };
                    PatientInformedConsentsController.prototype.goToNextState = function () {
                        this.patientInformedConsentService.allPatientInformedConsentsChecked(this.acceptedInformedConsents.length);
                        if (this.$stateParams.redirect && this.$stateParams.redirect !== 'checkInformedConsent') {
                            this.$state.go(this.$stateParams.redirect, this.$stateParams.params);
                        }
                        else {
                            this.$state.go('home');
                        }
                    };
                    return PatientInformedConsentsController;
                }());
                Shared.remeCareSharedModule.component('rcPatientInformedConsents', {
                    controller: PatientInformedConsentsController,
                    templateUrl: 'framework/informedConsent/patientInformedConsents/patientInformedConsents.html',
                    bindings: {
                        patientId: '<'
                    }
                });
            })(InformedConsent = Framework.InformedConsent || (Framework.InformedConsent = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
