var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Contract;
        (function (Contract) {
            var Code;
            (function (Code) {
                var TherapyStatus;
                (function (TherapyStatus) {
                    TherapyStatus[TherapyStatus["InPreparation"] = 1] = "InPreparation";
                    TherapyStatus[TherapyStatus["InUse"] = 2] = "InUse";
                    TherapyStatus[TherapyStatus["Inactive"] = 3] = "Inactive";
                    TherapyStatus[TherapyStatus["Test"] = 4] = "Test";
                })(TherapyStatus = Code.TherapyStatus || (Code.TherapyStatus = {}));
            })(Code = Contract.Code || (Contract.Code = {}));
        })(Contract = Shared.Contract || (Shared.Contract = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
