module RemeCare.Shared.Framework {

    import EnumCodeTranslation = Shared.Contract.IEnumCodeTranslation;
    
    class SettingsComponentController implements ng.IComponentController {
        
        // bindings
        public form: Framework.Directive.IFormController;

        public selectedLanguage: EnumCodeTranslation;
        public settings: Service.IUserSettings;
        public languages: Array<EnumCodeTranslation>;
        public $close: () => void;
        public $dismiss: () => void;

        constructor(
            private readonly toaster: Framework.Toaster,
            private readonly $cookies: ng.cookies.ICookiesService,
            private readonly masterdataSvc: Framework.MasterdataService,
            private readonly languageSvc: Framework.Service.LanguageService,
            private readonly settingsSvc: Service.SettingsService
        ) {
        }

        public async $onInit(): Promise<void> {
            try {
                const language = await this.masterdataSvc.getInterfaceLanguagesAsync();
                this.languages = language;
                var currentLang = this.$cookies.get('applicationLanguage') || 'nl';
                this.selectedLanguage = _(language).find<EnumCodeTranslation>(l => l.Code === currentLang);
                this.settings = this.settingsSvc.getSettings();
            } catch (exception) {
                this.toaster.error(exception);
            }
        }

        public confirm(): void {
            if (this.form.$invalid) {
                this.form.showValidationErrorMessage();
                return;
            }
            this.settingsSvc.saveSettings(this.settings);
            this.languageSvc.setLang(this.selectedLanguage.Code, true);
            this.$close();
        }
    }

    remeCareSharedModule.component('rcSettings',
        {
            bindings: {
                $close: '&',
                $dismiss: '&',
            },
            controller: SettingsComponentController,
            templateUrl: 'framework/components/settings/settings.html',
        });
}