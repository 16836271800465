var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Filter;
        (function (Filter) {
            var CurrentLanguageFilter = /** @class */ (function () {
                function CurrentLanguageFilter() {
                }
                CurrentLanguageFilter.filter = function ($translate) {
                    return function (translationDictionary, field) {
                        var currentLanguage = $translate.preferredLanguage();
                        var value = translationDictionary[currentLanguage];
                        if (!value) {
                            return value;
                        }
                        return field ? value[field] : value;
                    };
                };
                return CurrentLanguageFilter;
            }());
            CurrentLanguageFilter.filter.$inject = ['$translate'];
            Shared.remeCareSharedModule.filter('currentLanguage', CurrentLanguageFilter.filter);
        })(Filter = Shared.Filter || (Shared.Filter = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
