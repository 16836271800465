var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Contract;
        (function (Contract) {
            var Code;
            (function (Code) {
                var CareRequestStepType;
                (function (CareRequestStepType) {
                    CareRequestStepType[CareRequestStepType["Application"] = 1] = "Application";
                    CareRequestStepType[CareRequestStepType["Acceptance"] = 2] = "Acceptance";
                })(CareRequestStepType = Code.CareRequestStepType || (Code.CareRequestStepType = {}));
            })(Code = Contract.Code || (Contract.Code = {}));
        })(Contract = Shared.Contract || (Shared.Contract = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
