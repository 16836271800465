namespace RemeCare.Shared.Framework {
    interface ISliderConfig {
        minValue: number;
        maxValue: number;
        stepValue: number;
        leftLegend: string;
        rightLegend: string;
        value: number;
    }

    interface IRangeIndicator {
        value: number;
        text: string;
    }

    class SliderController implements ng.IComponentController {
        // bindings
        public options: Contract.IChoiceListOption[];
        public model: Contract.Guid;
        public text: string;

        public formCtrl: ng.IFormController;

        public configuration: ISliderConfig;
        public rangeIndicators: IRangeIndicator[];
        public rightOffset: string;

        constructor(private readonly $element: ng.IAugmentedJQuery, private readonly $timeout: ng.ITimeoutService) {}

        public $onInit(): void {
            this.configure();
            this.setSliderWidth();
        }

        public selectOption(range: IRangeIndicator): void {
            this.configuration.value = range.value;
            this.valueChanged();
        }

        public valueChanged = (): void => {
            const selectedOption = _.find(this.options, csi => csi.NumericValue === this.configuration.value);
            this.model = selectedOption && selectedOption.Id;
        };

        public hasValue(): boolean {
            return this.model != null;
        }

        public removeValue(): void {
            this.model = null;
        }

        private configure(): void {
            const orderedOptions = _.sortBy(this.options, csi => csi.Sequence);
            const leftAnswer = _(orderedOptions).first();
            const rightAnswer = _(orderedOptions).last();

            let selectedAnswer = _.find(this.options, csi => csi.Id === this.model);
            if (selectedAnswer == null) {
                const average = this.calcAverageValue(leftAnswer.NumericValue, rightAnswer.NumericValue, true);
                selectedAnswer = _.find(this.options, csi => csi.NumericValue === average);
            }

            this.configuration = {
                minValue: leftAnswer.NumericValue,
                maxValue: rightAnswer.NumericValue,
                stepValue: 1,
                leftLegend: leftAnswer.Name,
                rightLegend: rightAnswer.Name,
                value: selectedAnswer && selectedAnswer.NumericValue,
            };

            this.rangeIndicators = _.map(orderedOptions, o => {
                return {
                    value: o.NumericValue,
                    text: o.Name,
                } as IRangeIndicator;
            });
        }

        private setSliderWidth(): void {
            this.$timeout(() => {
                const width = 100 - 100 / (this.configuration.maxValue - this.configuration.minValue + 1);
                this.$element.find('.slider').width(`${width}%`);
                this.rightOffset = `${(100 - width) / 4}%`;
            });
        }

        private calcAverageValue(min: number, max: number, round: boolean): number {
            const average = (min + max) / 2;
            return round ? Math.round(average) : average;
        }
    }

    remeCareSharedModule.component('rcSlider', {
        controller: SliderController,
        bindings: {
            model: '=ngModel',
            options: '<',
            ngDisabled: '<ngDisabled',
            required: '<ngRequired',
            sliderClass: '<',
            text: '<',
            guidanceIconLocation: '<',
            guidanceIconFunc: '<',
            uniqueId: '@',
        },
        require: {
            formCtrl: '^form',
        },
        templateUrl: 'views/slider.html',
    });
}
