module RemeCare.Shared.Framework {
    
    interface IFileReadScope extends ng.IScope {
        fileRead
    }

    class FileReadDirective implements ng.IDirective {
        
        scope: { [boundProperty: string]: string } = {
            fileRead: '=rcFileRead'
        }

        link: ng.IDirectiveLinkFn = (scope: IFileReadScope, element: ng.IAugmentedJQuery) => {
            element.bind('change', (changeEvent: any) => {
                var reader = new FileReader();
                reader.onload = (loadEvent) => {
                    scope.$apply(() => {
                        scope.fileRead = (<any>loadEvent.target).result; // result is there, but typescript doesn't know it
                    });
                }
                reader.readAsDataURL(changeEvent.target.files[0]);
            });
        }
    }

    angular.module('RemeCare.Shared').directive('rcFileRead', () => new FileReadDirective());
}