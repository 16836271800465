module RemeCare.Shared.Framework {
    
    class Selector implements ng.IComponentController {

        public disabled: boolean;
        public itemId: Shared.Contract.Guid;
        public action: (parameters: { item: Contract.IEntityTranslation }) => void;

        constructor(private $timeout: ng.ITimeoutService) { }

        public $onInit(): void {}
        
        public selectItem(item: Contract.IEntityTranslation): void {
            if (this.disabled) return;
            this.itemId = item.Id;
            this.action({item: item});
        }
    }

    remeCareSharedModule.component('rcSelector',
    {
        controller: Selector,
        controllerAs: 'selectorCtrl',
        templateUrl: 'views/selector.html',
        bindings: {
            items: '<',
            disabled: '<ngDisabled',
            action: '&',
            itemId: '='
        }
    });
}