namespace RemeCare.Shared.Framework.Model {
  export class Guidance {
    public guidanceId: Shared.Contract.Guid;
    public status: Shared.Contract.IEnumTranslation;
    public code: string;
    public internalName: string;
    public internalDescription: string;
    public steps: GuidanceStep[];
    public isGlobal: boolean;

    constructor(serverObject?: RemeCare.Shared.Contract.Read.IGuidance) {
      if (serverObject != null) {
        this.guidanceId = serverObject.GuidanceId;
        this.code = serverObject.Code;
        this.internalName = serverObject.InternalName;
        this.internalDescription = serverObject.InternalDescription;
        this.status = serverObject.Status;
        this.steps = _(serverObject.GuidanceSteps)
          .chain()
          .map((tap) =>
            RemeCare.Shared.Framework.Factory.GuidanceStepFactory.createGuidanceStep(
              tap
            )
          )
          .sortBy((tap) => tap.Sequence)
          .value();
        this.isGlobal = serverObject.IsGlobal;
      } else {
        this.status = {
          Text: 'InPreparation',
          Id: Shared.Contract.Code.GuidanceStatus.InPreparation,
        };
        this.steps = [];
      }
    }

    public toServerWrite(): RemeCare.Shared.Contract.Write.IGuidance {
      const result = {
        GuidanceId: this.guidanceId,
        StatusId: this.status.Id,
        Code: this.code,
        InternalDescription: this.internalDescription,
        InternalName: this.internalName,
      } as RemeCare.Shared.Contract.Write.IGuidance;
      result.GuidanceSteps = _(this.steps).map((part) => part.toServerWrite());
      return result;
    }

    public anonimise(): void {
      this.status = {
        Id: RemeCare.Shared.Contract.Code.GuidanceStatus.InPreparation,
        Text: 'InPreparation',
      };
      this.code = null;
      this.guidanceId = null;
      this.internalDescription = null;
      this.internalName = null;
      _(this.steps).each((tap) => tap.anonimise());
    }
  }
}
