var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Factory;
            (function (Factory) {
                var PeriodicityFactory = /** @class */ (function () {
                    function PeriodicityFactory() {
                    }
                    PeriodicityFactory.prototype.createPeriodicity = function (serverObject) {
                        var result = this.createPeriodicityFromType(serverObject.Type, serverObject);
                        return result;
                    };
                    PeriodicityFactory.prototype.createPeriodicityFromType = function (type, serverObject) {
                        var result;
                        switch (type) {
                            case Shared.Contract.Code.PeriodicityType.PeriodicityDay:
                                result = new Framework.Model.PeriodicityDay(serverObject);
                                result.recurrence.Unit = Shared.Contract.Code.DurationUnit.Days;
                                break;
                            case Shared.Contract.Code.PeriodicityType.PeriodicityWeek:
                                result = new Framework.Model.PeriodicityWeek(serverObject);
                                result.recurrence.Unit = Shared.Contract.Code.DurationUnit.Weeks;
                                break;
                            case Shared.Contract.Code.PeriodicityType.PeriodicityMonth:
                                result = new Framework.Model.PeriodicityMonth(serverObject);
                                result.recurrence.Unit = Shared.Contract.Code.DurationUnit.Months;
                                break;
                            case Shared.Contract.Code.PeriodicityType.PeriodicityYear:
                                result = new Framework.Model.PeriodicityYear(serverObject);
                                result.recurrence.Unit = Shared.Contract.Code.DurationUnit.Years;
                                break;
                            default:
                                result = new Framework.Model.Periodicity(serverObject);
                                break;
                        }
                        if (serverObject != null) {
                            if (serverObject.ChildPeriodicity != null) {
                                result.setChild(this.createPeriodicity(serverObject.ChildPeriodicity));
                                result.childPeriodicity.parentPeriodicity = result;
                            }
                            if (serverObject.IntraDayTimings != null) {
                                var intraDayTimingFactory = new Factory.IntraDayTimingFactory();
                                result.intraDayTimings = _(serverObject.IntraDayTimings).map(function (i) { return intraDayTimingFactory.createIntraDayTiming(i); });
                            }
                        }
                        return result;
                    };
                    PeriodicityFactory.prototype.createPeriodicityFromDurationUnit = function (recurrenceUnit, parentPeriodicity) {
                        var result;
                        switch (recurrenceUnit) {
                            case Shared.Contract.Code.DurationUnit.Days:
                                result = new Framework.Model.PeriodicityDay();
                                break;
                            case Shared.Contract.Code.DurationUnit.Weeks:
                                result = new Framework.Model.PeriodicityWeek();
                                break;
                            case Shared.Contract.Code.DurationUnit.Months:
                                result = new Framework.Model.PeriodicityMonth();
                                break;
                            case Shared.Contract.Code.DurationUnit.Years:
                                result = new Framework.Model.PeriodicityYear();
                                break;
                            default:
                                result = new Framework.Model.PeriodicityDay();
                                break;
                        }
                        result.recurrence = {
                            Unit: recurrenceUnit
                        };
                        if (parentPeriodicity != null) {
                            parentPeriodicity.setChild(result);
                            result.parentPeriodicity = parentPeriodicity;
                        }
                        return result;
                    };
                    PeriodicityFactory.prototype.createPeriodicitiesFromAdHoc = function (unstructuredPeriodicity, unstructuredTargetQuantity) {
                        var periodicity = new Framework.Model.Periodicity();
                        periodicity.unstructuredPeriodicity = unstructuredPeriodicity;
                        periodicity.unstructuredTargetQuantity = unstructuredTargetQuantity;
                        return periodicity;
                    };
                    PeriodicityFactory.prototype.createPeriodicitiesFromSimplifiedRecurrence = function (simplifiedRecurrence) {
                        var result = [];
                        var periodicity;
                        switch (simplifiedRecurrence.Id) {
                            case Shared.Contract.Code.SimplifiedRecurrence.XTimesPerDay:
                                periodicity = this.createPeriodicityWithIntraDayTiming(Shared.Contract.Code.PeriodicityType.PeriodicityDay, simplifiedRecurrence);
                                periodicity.recurrence = {
                                    Quantity: 1,
                                    Unit: Shared.Contract.Code.DurationUnit.Days
                                };
                                result.push(periodicity);
                                break;
                            case Shared.Contract.Code.SimplifiedRecurrence.XTimesEveryYDays:
                                periodicity = this.createPeriodicityWithIntraDayTiming(Shared.Contract.Code.PeriodicityType.PeriodicityDay, simplifiedRecurrence);
                                periodicity.recurrence = {
                                    Quantity: null,
                                    Unit: Shared.Contract.Code.DurationUnit.Days
                                };
                                result.push(periodicity);
                                break;
                            case Shared.Contract.Code.SimplifiedRecurrence.EveryOtherDay:
                                periodicity = this.createPeriodicityWithIntraDayTiming(Shared.Contract.Code.PeriodicityType.PeriodicityDay, simplifiedRecurrence);
                                periodicity.recurrence = {
                                    Quantity: 2,
                                    Unit: Shared.Contract.Code.DurationUnit.Days
                                };
                                periodicity.sequenceInRecurrenceCycle = 1;
                                result.push(periodicity);
                                periodicity = this.createPeriodicityWithIntraDayTiming(Shared.Contract.Code.PeriodicityType.PeriodicityDay, simplifiedRecurrence);
                                periodicity.recurrence = {
                                    Quantity: 2,
                                    Unit: Shared.Contract.Code.DurationUnit.Days
                                };
                                periodicity.sequenceInRecurrenceCycle = 2;
                                result.push(periodicity);
                                break;
                            case Shared.Contract.Code.SimplifiedRecurrence.WeekSchedule:
                                periodicity = this.createPeriodicityWithIntraDayTiming(Shared.Contract.Code.PeriodicityType.PeriodicityWeek, simplifiedRecurrence);
                                periodicity.recurrence = {
                                    Quantity: 1,
                                    Unit: Shared.Contract.Code.DurationUnit.Weeks
                                };
                                result.push(periodicity);
                                break;
                            case Shared.Contract.Code.SimplifiedRecurrence.Other:
                                periodicity = new Framework.Model.Periodicity();
                                periodicity.simplifiedRecurrence = simplifiedRecurrence;
                                result.push(periodicity);
                                break;
                        }
                        return result;
                    };
                    PeriodicityFactory.prototype.createPeriodicityWithIntraDayTiming = function (type, simplifiedRecurrence) {
                        var intraDayTimingFactory = new Factory.IntraDayTimingFactory();
                        var periodicity = this.createPeriodicityFromType(type);
                        periodicity.simplifiedRecurrence = simplifiedRecurrence;
                        periodicity.intraDayTimings.push(intraDayTimingFactory.createIntraDayTimingWithTargetDoses(1));
                        periodicity.sequence = 1;
                        return periodicity;
                    };
                    return PeriodicityFactory;
                }());
                Factory.PeriodicityFactory = PeriodicityFactory;
                Shared.remeCareSharedModule.factory('periodicityFactory', function () { return new PeriodicityFactory(); });
            })(Factory = Framework.Factory || (Framework.Factory = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
