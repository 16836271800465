namespace RemeCare.Shared.Framework.Model {
    export class CareRequestUIControl {
        public id: number;
        public uiControlTypeId: number;
        public modalityType: Shared.Contract.IEnumTranslation;
        public careRequestStepProcessActivityReference: string;

        constructor(serverObject?: Contract.Read.ICareRequestUIControl) {
            if (serverObject != null) {
                this.id = serverObject.Id;
                this.uiControlTypeId = serverObject.UIControlTypeId;
                this.modalityType = serverObject.ModalityType;
                this.careRequestStepProcessActivityReference = serverObject.CareRequestStepProcessActivityReference;
            } else {
                this.modalityType = {} as Shared.Contract.IEnumTranslation;
            }
        }

        public toServerWrite(): Contract.Write.ICareRequestUIControl {
            return {
                id: this.id,
                uiControlTypeId: this.uiControlTypeId,
                modalityTypeId: this.modalityType.Id,
                careRequestStepProcessActivityReference: this.careRequestStepProcessActivityReference,
            } as Contract.Write.ICareRequestUIControl;
        }
    }
    export class CareRequestSetup {
        public careRequestParts: CareRequestPart[];
        public careRequestPartsIncludingInactive: CareRequestPart[];
        public textHeader: string;
        public textFooter: string;
        public careRequestProcessReference: string;
        public isLatestValidCareRequestSetupVersion: boolean;
        public autoCreateUserForPatient: boolean;

        constructor(serverObject?: Contract.Read.ICareRequestSetup) {
            if (serverObject != null) {
                const factory = new Framework.Factory.CareRequestPartFactory();
                this.careRequestParts = _(serverObject.CareRequestParts).map(p => factory.createCareRequestPart(p));
                this.careRequestPartsIncludingInactive = _(serverObject.CareRequestPartsIncludingInactive).map(p =>
                    factory.createCareRequestPart(p)
                );
                this.textHeader = serverObject.TextHeader;
                this.textFooter = serverObject.TextFooter;
                this.careRequestProcessReference = serverObject.CareRequestProcessReference;
                this.isLatestValidCareRequestSetupVersion = serverObject.IsLatestValidCareRequestSetupVersion;
                this.autoCreateUserForPatient = serverObject.AutoCreateUserForPatient;
            } else {
                this.careRequestParts = [];
            }
        }

        public toServerWrite(): Contract.Write.ICareRequestSetup {
            return {
                careRequestParts: _(this.careRequestParts).map(cp => cp.toServerWrite()),
                careRequestPartsIncludingInactive: _(this.careRequestPartsIncludingInactive).map(cp =>
                    cp.toServerWrite()
                ),
                textHeader: this.textHeader,
                textFooter: this.textFooter,
                careRequestProcessReference: this.careRequestProcessReference,
                autoCreateUserForPatient: this.autoCreateUserForPatient
            };
        }
    }

    export class CareRequestPart {
        public id: Shared.Contract.Guid;
        public sequence: number;
        public type: Shared.Contract.IEnumTranslation;
        public text: string;
        public preceedingTitle: string;
        public uiControls: CareRequestUIControl[] = [];
        public localIdentity: Contract.Guid;
        public isActive: boolean;
        public answerConditions: AnswerConditionDefinition[] = [];

        constructor(serverObject?: Contract.Read.ICareRequestPart) {
            if (serverObject != null) {
                this.id = serverObject.Id;
                this.sequence = serverObject.Sequence;
                this.type = serverObject.Type;
                this.text = serverObject.Text;
                this.preceedingTitle = serverObject.PreceedingTitle;
                this.uiControls = _(serverObject.UIControls).map(x => new CareRequestUIControl(x));
                this.localIdentity = serverObject.LocalIdentity;
                this.isActive = serverObject.IsActive;
                this.answerConditions = _(serverObject.AnswerConditions).map(x => new AnswerConditionDefinition(x));
            }
        }

        public getDetail(): string {
            return '';
        }

        public getDropdownText(): string {
            let text: string;
            switch (this.type.Id) {
                case Shared.Contract.Code.CareRequestPartType.QuantitativeAnamnesis:
                    text = this.type.Text + ': ' + (this.valueOf() as QuantitativeAnamnesisDefinition).quantitativeCharacteristic.Name;
                    break;
                case Shared.Contract.Code.CareRequestPartType.QualitativeAnamnesis:
                    text = this.type.Text + ': ' + (this.valueOf() as QualitativeAnamnesisDefinition).qualitativeEntity.Text;
                    break;
                case Shared.Contract.Code.CareRequestPartType.Date:
                    text = this.type.Text + ': ' + (this.valueOf() as DateDefinition).name;
                    break;
                case Shared.Contract.Code.CareRequestPartType.TherapyOption:
                    text = this.type.Text + ': ' + (this.valueOf() as OptionDefinition).name ;
                    break;
                default:
                    text = this.type.Text;
                    break;
            }
            return this.sequence + '. ' + text;
        }

        public toServerWrite(): Contract.Write.ICareRequestPart {
            return {
                id: this.id,
                sequence: this.sequence,
                type: this.type.Id,
                text: this.text,
                preceedingTitle: this.preceedingTitle,
                uiControls: _(this.uiControls).map(u => u.toServerWrite()),
                isActive: this.isActive,
            };
        }
    }


    
    export class CareRequestorDefinition extends CareRequestPart {
        public canBeSearchedByExternal: boolean;
        public roleRequestingHealthCareProfessional: ActorRole;
        public roleRequestingHealthCareOrganisation: ActorRole;

        constructor(serverObject?: Contract.Read.ICareRequestPart) {
            super(serverObject);
            if (serverObject != null) {
                this.canBeSearchedByExternal = serverObject.CareRequestor.CanBeSearchedByExternal;
                this.roleRequestingHealthCareProfessional =
                    serverObject.CareRequestor.RoleRequestingHealthCareProfessional != null
                        ? new ActorRole(serverObject.CareRequestor.RoleRequestingHealthCareProfessional)
                        : null;
                this.roleRequestingHealthCareOrganisation =
                    serverObject.CareRequestor.RoleRequestingHealthCareOrganisation != null
                        ? new ActorRole(serverObject.CareRequestor.RoleRequestingHealthCareOrganisation)
                        : null;
            } else {
                this.canBeSearchedByExternal = false;
            }
        }

        public toServerWrite(): Contract.Write.ICareRequestorDefinition {
            const result = super.toServerWrite() as Contract.Write.ICareRequestorDefinition;
            result.canBeSearchedByExternal = this.canBeSearchedByExternal;
            result.roleRequestingHealthCareProfessionalId =
                this.roleRequestingHealthCareProfessional != null
                    ? this.roleRequestingHealthCareProfessional.actorRoleId
                    : null;
            result.roleRequestingHealthCareOrganisationId =
                this.roleRequestingHealthCareOrganisation != null
                    ? this.roleRequestingHealthCareOrganisation.actorRoleId
                    : null;
            return result;
        }
    }

    export class CareRequestRoleDefinition extends CareRequestPart {
        public careRequestRoleConfigurations: CareRequestRoleConfiguration[];
        public visibleRoleConfigurations: CareRequestRoleConfiguration[];

        constructor(serverObject?: Contract.Read.ICareRequestPart) {
            super(serverObject);
            if (serverObject != null) {
                this.careRequestRoleConfigurations = _(serverObject.CareRequestRoleConfigurations).map(
                    crc => new CareRequestRoleConfiguration(crc)
                );
            } else {
                this.careRequestRoleConfigurations = [];
            }
        }

        public toServerWrite(): Contract.Write.ICareRequestRoleDefinition {
            const result = super.toServerWrite() as Contract.Write.ICareRequestRoleDefinition;
            result.careRequestRoleConfigurations = _(this.careRequestRoleConfigurations).map(crc =>
                crc.toServerWrite()
            );
            return result;
        }
    }

    export class CareRequestRoleConfiguration {
        public id: Shared.Contract.Guid;
        public role: ActorRole;
        public canBeSearchedByExternal: boolean;
        public isVisible: boolean;

        constructor(serverObject?: Contract.Read.ICareRequestRoleConfiguration) {
            if (serverObject != null) {
                this.id = serverObject.Id;
                this.role = new ActorRole(serverObject.Role);
                this.canBeSearchedByExternal = serverObject.CanBeSearchedByExternal;
                this.isVisible = true;
            } else {
                this.canBeSearchedByExternal = false;
            }
        }

        public toServerWrite(): Contract.Write.ICareRequestRoleConfiguration {
            return {
                actorRoleId: this.role.actorRoleId,
                canBeSearchedByExternal: this.canBeSearchedByExternal,
            };
        }
    }

    export class AnamnesisDefinition extends CareRequestPart {
        public name: string;
        public showInMonitoringDashboard: boolean;
        public showInPatientFile: boolean;

        constructor(serverObject?: Contract.Read.ICareRequestPart) {
            super(serverObject);
            if (serverObject != null) {
                this.name = serverObject.Name;
                this.showInMonitoringDashboard = serverObject.ShowInMonitoringDashboard;
                this.showInPatientFile = serverObject.ShowInPatientFile;
            }
        }

        public toServerWrite(): Contract.Write.IAnamnesisDefinition {
            const result = super.toServerWrite() as Contract.Write.IAnamnesisDefinition;
            result.name = this.name;
            result.showInPatientFile = this.showInPatientFile;
            result.showInMonitoringDashboard = this.showInMonitoringDashboard;
            return result;
        }
    }

    export class QualitativeAnamnesisDefinition extends AnamnesisDefinition {
        public qualitativeEntity: Shared.Contract.IEntityTranslation;
        public codeSet: Shared.Contract.IEntityTranslation;
        public uiControlType: Shared.Contract.IEnumTranslation;
        public answerConditions: AnswerConditionDefinition[] = [];

        constructor(serverObject?: Contract.Read.ICareRequestPart) {
            super(serverObject);
            if (serverObject != null) {
                this.qualitativeEntity = serverObject.QualitativeAnamnesis.QualitativeEntity;
                this.codeSet = serverObject.QualitativeAnamnesis.CodeSet;
                this.uiControlType = serverObject.QualitativeAnamnesis.UIControlType;
                this.answerConditions = _(serverObject.AnswerConditions).map(x => new AnswerConditionDefinition(x));
            }
        }

        public getDetail(): string {
            return this.qualitativeEntity != null ? this.qualitativeEntity.Text : '';
        }

        public toServerWrite(): Contract.Write.IQualitativeAnamnesisDefinition {
            const result = super.toServerWrite() as Contract.Write.IQualitativeAnamnesisDefinition;
            result.qualitativeEntityId = this.qualitativeEntity != null ? this.qualitativeEntity.Id : null;
            result.codeSetId = this.codeSet != null ? this.codeSet.Id : null;
            result.uiControlTypeId = this.uiControlType != null ? this.uiControlType.Id : null;
            result.answerConditions = this.answerConditions;
            return result;
        }
    }

    export class AnswerConditionDefinition {
        public id?: Shared.Contract.Guid;
        public answerCodeSetItemId?: Shared.Contract.Guid;
        public answerBoolean?: boolean;
        public AnswerTherapyDiseaseId?: Shared.Contract.Guid;
        public goToCareRequestPartId: Shared.Contract.Guid;

        constructor(serverObject?: Contract.Read.IAnswerConditionDefinition) {
            if (serverObject != null) {
                this.id = serverObject.Id;
                this.answerCodeSetItemId = serverObject.AnswerCodeSetItemId;
                this.answerBoolean = serverObject.AnswerBoolean;
                this.AnswerTherapyDiseaseId = serverObject.AnswerTherapyDiseaseId;
                this.goToCareRequestPartId = serverObject.GoToCareRequestPartId;
            }
        }
    }

    export class QuantitativeAnamnesisDefinition extends AnamnesisDefinition {
        public quantitativeCharacteristic: Shared.Contract.Read.ICharacteristic;
        public unit: Shared.Contract.Read.IUnitLimitTranslation;
        public decimals: number;
        public minimumValue?: number;
        public maximumValue?: number;

        constructor(serverObject?: Contract.Read.ICareRequestPart) {
            super(serverObject);
            if (serverObject != null) {
                this.quantitativeCharacteristic = serverObject.QuantitativeAnamnesis.QuantitativeCharacteristic;
                this.unit = serverObject.QuantitativeAnamnesis.Unit;
                this.decimals = serverObject.QuantitativeAnamnesis.Decimals;
                this.minimumValue = serverObject.QuantitativeAnamnesis.MinimumValue;
                this.maximumValue = serverObject.QuantitativeAnamnesis.MaximumValue;
            }
        }

        public getDetail(): string {
            return this.quantitativeCharacteristic != null ? this.quantitativeCharacteristic.Name : '';
        }

        public getMinRegistrationValue(): number {
            if (this.minimumValue != null) {
                return this.minimumValue;
            }

            if (this.unit) {
                return this.unit.MinimumValue;
            }

            return undefined;
        }

        public getMaxRegistrationValue(): number {
            if (this.maximumValue != null) {
                return this.maximumValue;
            }

            if (this.unit) {
                return this.unit.MaximumValue;
            }

            return undefined;
        }

        public toServerWrite(): Contract.Write.IQuantitativeAnamnesisDefinition {
            const result = super.toServerWrite() as Contract.Write.IQuantitativeAnamnesisDefinition;
            result.quantitativeCharacteristicId =
                this.quantitativeCharacteristic != null ? this.quantitativeCharacteristic.Id : null;
            result.unitId = this.unit != null ? this.unit.Id : null;
            result.decimals = this.decimals;
            result.minimumValue = this.minimumValue;
            result.maximumValue = this.maximumValue;
            return result;
        }
    }

    export class OptionDefinition extends CareRequestPart {
        public name: string;
        public showInMonitoringDashboard: boolean;
        public showInPatientFile: boolean;
        public answerConditions: AnswerConditionDefinition[] = [];

        constructor(serverObject?: Contract.Read.ICareRequestPart) {
            super(serverObject);
            if (serverObject != null) {
                this.name = serverObject.Name;
                this.showInMonitoringDashboard = serverObject.ShowInMonitoringDashboard;
                this.showInPatientFile = serverObject.ShowInPatientFile;
                this.answerConditions = _(serverObject.AnswerConditions).map(x => new AnswerConditionDefinition(x));
            }
        }

        public getDetail(): string {
            return this.name || '';
        }

        public toServerWrite(): Contract.Write.IOptionDefinition {
            const result = super.toServerWrite() as Contract.Write.IOptionDefinition;
            result.name = this.name;
            result.showInPatientFile = this.showInPatientFile;
            result.showInMonitoringDashboard = this.showInMonitoringDashboard;
            result.answerConditions = this.answerConditions;
            return result;
        }
    }

    export class DateDefinition extends CareRequestPart {
        public isInternalCarePlanStartDate: boolean;
        public isInclusionDate: boolean;
        public name: string;
        public showInMonitoringDashboard: boolean;
        public showInPatientFile: boolean;

        constructor(serverObject?: Contract.Read.ICareRequestPart) {
            super(serverObject);
            if (serverObject != null) {
                this.isInternalCarePlanStartDate = serverObject.IsInternalCarePlanStartDate;
                this.isInclusionDate = serverObject.IsInclusionDate;
                this.name = serverObject.Name;
                this.showInMonitoringDashboard = serverObject.ShowInMonitoringDashboard;
                this.showInPatientFile = serverObject.ShowInPatientFile;
            }
        }

        public getDetail(): string {
            return this.name || '';
        }

        public toServerWrite(): Contract.Write.IDateDefinintion {
            const result = super.toServerWrite() as Contract.Write.IDateDefinintion;
            result.isInternalCarePlanStartDate = this.isInternalCarePlanStartDate;
            result.isInclusionDate = this.isInclusionDate;
            result.name = this.name;
            result.showInPatientFile = this.showInPatientFile;
            result.showInMonitoringDashboard = this.showInMonitoringDashboard;
            return result;
        }
    }

    export class StringDefinition extends CareRequestPart {
        public name: string;
        public showInMonitoringDashboard: boolean;
        public showInPatientFile: boolean;

        constructor(serverObject?: Contract.Read.ICareRequestPart) {
            super(serverObject);
            if (serverObject != null) {
                this.name = serverObject.Name;
                this.showInMonitoringDashboard = serverObject.ShowInMonitoringDashboard;
                this.showInPatientFile = serverObject.ShowInPatientFile;
            }
        }

        public getDetail(): string {
            return this.name || '';
        }

        public toServerWrite(): Contract.Write.IStringDefinition {
            const result = super.toServerWrite() as Contract.Write.IStringDefinition;
            result.name = this.name;
            result.showInPatientFile = this.showInPatientFile;
            result.showInMonitoringDashboard = this.showInMonitoringDashboard;
            return result;
        }
    }

    export class CareRequestDocumentDefinition extends CareRequestPart {
        public allowsAllFileTypes: boolean;
        public hasReferenceDate: boolean;
        public fileTypes: Shared.Contract.IEntityTranslation[] = [];
        public documentCategories: Contract.Guid[] = [];

        constructor(serverObject?: Contract.Read.ICareRequestPart) {
            super(serverObject);
            if (serverObject != null) {
                this.allowsAllFileTypes = serverObject.CareRequestDocument.AllowsAllFileTypes;
                this.hasReferenceDate = serverObject.CareRequestDocument.HasReferenceDate;
                this.fileTypes = serverObject.CareRequestDocument.FileTypes;
                this.documentCategories = _(serverObject.CareRequestDocument.DocumentCategories).map(dc => dc.Id);
            }
        }

        public toServerWrite(): Contract.Write.ICareRequestDocumentDefinition {
            const result = super.toServerWrite() as Contract.Write.ICareRequestDocumentDefinition;
            result.allowsAllFileTypes = this.allowsAllFileTypes;
            result.hasReferenceDate = this.hasReferenceDate;
            result.fileTypes = _(this.fileTypes).map(ft => ft.Id);
            result.documentCategories = this.documentCategories;
            return result;
        }
    }

    export class MedicationSchemaDefinition extends CareRequestPart {
        public showInMonitoringDashboard: boolean;
        public showInPatientFile: boolean;
        public medicationActionId?: Contract.Guid;

        constructor(serverObject?: Contract.Read.ICareRequestPart) {
            super(serverObject);
            if (serverObject != null) {
                this.showInMonitoringDashboard = serverObject.ShowInMonitoringDashboard;
                this.showInPatientFile = serverObject.ShowInPatientFile;
                this.medicationActionId = serverObject.MedicationActionId;
            }
        }

        public getDetail(): string {
            return this.preceedingTitle != null ? this.preceedingTitle : '';
        }

        public toServerWrite(): Contract.Write.IMedicationSchemaDefinition {
            const result = super.toServerWrite() as Contract.Write.IMedicationSchemaDefinition;
            result.showInMonitoringDashboard = this.showInMonitoringDashboard;
            result.showInPatientFile = this.showInPatientFile;
            result.medicationActionId = this.medicationActionId;
            return result;
        }
    }

    export class DiseaseDefinition extends CareRequestPart {
        public answerConditions: AnswerConditionDefinition[] = [];

        constructor(serverObject?: Contract.Read.ICareRequestPart) {
            super(serverObject);
            if (serverObject != null) {
                this.answerConditions = _(serverObject.AnswerConditions).map(x => new AnswerConditionDefinition(x));
            }
        }

        public getDetail(): string {
            return this.preceedingTitle != null ? this.preceedingTitle : '';
        }

        public toServerWrite(): Contract.Write.IDiseaseDefinition {
            const result = super.toServerWrite() as Contract.Write.IDiseaseDefinition;
            result.answerConditions = this.answerConditions;
            return result;
        }
    }
}
