namespace RemeCare.Shared.Contract.Code {
    export enum ApplicationProfileType {
        Superuser = 1,
        CareManager = 2,
        AdministrativeManager = 3,
        CareProvider = 4,
        TherapyProvider = 5, // Involved with therapy
        Patient = 6,
        InformalCareProvider = 7,
        Configurator = 8,
        UnknownCareProvider = 9,
    }
}
