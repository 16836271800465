var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Directive;
            (function (Directive) {
                var FormDirective = /** @class */ (function () {
                    function FormDirective($rootScope, $timeout, $translate, toaster) {
                        var _this = this;
                        this.$rootScope = $rootScope;
                        this.$timeout = $timeout;
                        this.$translate = $translate;
                        this.toaster = toaster;
                        this.restrict = 'E';
                        this.require = 'form';
                        this.link = function (scope, elem, attrs, formCtrl) {
                            scope.$watch(function () { return formCtrl.$submitted; }, function () {
                                _this.$timeout(function () {
                                    _this.$rootScope.$broadcast('formSubmitted');
                                });
                            });
                            formCtrl.showValidationErrorMessage = function () {
                                _this.toaster.error(_this.$translate.instant('General.FieldValidationError'));
                            };
                        };
                    }
                    return FormDirective;
                }());
                Shared.remeCareSharedModule.directive('form', function ($rootScope, $timeout, $translate, toaster) { return new FormDirective($rootScope, $timeout, $translate, toaster); });
            })(Directive = Framework.Directive || (Framework.Directive = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
