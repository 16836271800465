module RemeCare.Shared.Filter {
    
    function delimitedDisplayFilter(): (items: Array<Shared.Contract.IEntityTranslation>, delimiter?: string) => string {
        return (items: Array<Shared.Contract.IEntityTranslation>, delimiter?: string) => {
            var result: string;
            if (items == null || items.length === 0) {
                result = '';
            } else {
                delimiter = delimiter || ' | ';
                items = angular.copy(items);
                var init = items.shift();
                var initText = init != null ? init.Text : '';
                result = <string> _(items).reduce((memo, category) => {
                        var categoryText = category != null ? category.Text : '';
                        return memo + delimiter + categoryText;
                    },
                    initText);
            }
            return result;
        }
    }

    angular.module('RemeCare.Shared').filter('delimitedDisplay', delimitedDisplayFilter);
}