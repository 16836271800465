namespace RemeCare.Shared.Directive {
    interface IColourPickerScope extends ng.IScope {
        formCtrl: ng.IFormController;
    }

    class ColourpickerDirective implements ng.IDirective {
        public restrict = 'E';

        public require = '^form';

        public scope: { [boundProperty: string]: string } = {
            label: '@label',
            colour: '=ngModel',
            required: '=ngRequired',
            disabled: '=ngDisabled',
            guidanceIconLocation: '<',
            guidanceIconFunc: '<',
            uniqueId: '@',
        };

        public templateUrl = 'views/formElements/colourpicker.html';
        public link: ng.IDirectiveLinkFn = (scope: IColourPickerScope, element, attrs, formCtrl: ng.IFormController) => {
            scope.formCtrl = formCtrl;
        };
    }

    remeCareSharedModule.directive('rcColourpicker', () => new ColourpickerDirective());
}
