var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Contract;
        (function (Contract) {
            var Code;
            (function (Code) {
                var ModalityType;
                (function (ModalityType) {
                    ModalityType[ModalityType["Not"] = 1] = "Not";
                    ModalityType[ModalityType["Optional"] = 2] = "Optional";
                    ModalityType[ModalityType["Mandatory"] = 3] = "Mandatory";
                })(ModalityType = Code.ModalityType || (Code.ModalityType = {}));
            })(Code = Contract.Code || (Contract.Code = {}));
        })(Contract = Shared.Contract || (Shared.Contract = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
