var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Contract;
        (function (Contract) {
            var Code;
            (function (Code) {
                var MedicationType;
                (function (MedicationType) {
                    MedicationType[MedicationType["FollowedUp"] = 1] = "FollowedUp";
                    MedicationType[MedicationType["NotFollowedUp"] = 2] = "NotFollowedUp";
                })(MedicationType = Code.MedicationType || (Code.MedicationType = {}));
            })(Code = Contract.Code || (Contract.Code = {}));
        })(Contract = Shared.Contract || (Shared.Contract = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
