module RemeCare.Shared.Filter {
    import EnumTranslation = RemeCare.Shared.Contract.IEnumTranslation;
    var durationDisplayFilter: (masterdataSvc: Shared.Framework.MasterdataService) => (duration: Shared.Contract.IDuration) => string = (masterdataSvc) => {
        var units: Array<EnumTranslation> = null; // DATA RECEIVED ASYNCHRONOUSLY AND CACHED HERE
        var serviceInvoked = false;
        
        var realFilter = (duration: Shared.Contract.IDuration) => { // REAL FILTER LOGIC
            if (!duration || (duration.Quantity == null && duration.Unit == null)) {
                return '';
            }

            return duration.Quantity + ' ' + _(units).find(u => u.Id === duration.Unit).Text;
        }

        return (duration: Shared.Contract.IDuration) => { // FILTER WRAPPER TO COPE WITH ASYNCHRONICITY
            if (!units) {
                if (!serviceInvoked) {
                    serviceInvoked = true;
                    // CALL THE SERVICE THAT FETCHES THE DATA HERE
                    
                    masterdataSvc.getDurationUnits().success(data => {
                        units = data;
                    });
                }
                return "-"; // PLACEHOLDER WHILE LOADING, COULD BE EMPTY
            }
            else return realFilter(duration);
        }
    }

    durationDisplayFilter.$inject = ['masterdataSvc'];

    angular.module('RemeCare.Shared').filter('rcDurationDisplay', durationDisplayFilter);
} 