var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var RemeCareSharedConfig = /** @class */ (function (_super) {
            __extends(RemeCareSharedConfig, _super);
            function RemeCareSharedConfig() {
                return _super !== null && _super.apply(this, arguments) || this;
            }
            RemeCareSharedConfig.prototype.config = function () {
                this.addComponentRoute('applicationProfile', '/ApplicationProfile', 'rcApplicationProfile', [], null, null, null, {
                    params: {
                        redirect: null,
                        params: null,
                    },
                });
                this.add('patientInformedConsentsOverview', {
                    component: 'rcPatientInformedConsentOverview',
                    url: '/MyInformedConsents',
                }, ['Views_InformedConsent'], {
                    context: Shared.Framework.AuthContext.patientInformedConsents,
                    right: Shared.Framework.AuthRight.Write,
                });
                this.add('checkInformedConsent', {
                    component: 'rcPatientInformedConsents',
                    url: '/CheckInformedConsent',
                    params: {
                        redirect: null,
                        params: null,
                    },
                }, ['Views_InformedConsent'], {
                    context: Shared.Framework.AuthContext.patientInformedConsents,
                    right: Shared.Framework.AuthRight.Read,
                });
                this.$stateProvider
                    .state('checkApplicationProfile', {
                    url: '/CheckApplicationProfile',
                    params: {
                        redirect: null,
                        params: null,
                    },
                    controller: 'checkApplicationProfileController',
                })
                    .state('unauthorized', {
                    url: '/Unauthorized',
                    controller: function () { },
                    templateUrl: 'views/unauthorized.html',
                    data: {
                        allowAnonymous: true,
                    },
                })
                    .state('userUnknown', {
                    url: '/UserUnknown',
                    component: 'rcUserUnknown',
                    data: {
                        allowAnonymous: true,
                    },
                });
            };
            return RemeCareSharedConfig;
        }(Shared.Framework.RouteConfiguration));
        Shared.remeCareSharedModule.config(function ($stateProvider) { return new RemeCareSharedConfig($stateProvider); });
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
