var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var ErrorDecoratorController = /** @class */ (function () {
                function ErrorDecoratorController($element, $rootScope) {
                    this.$element = $element;
                    this.$rootScope = $rootScope;
                    this.hasErrorClass = 'has-error';
                }
                ErrorDecoratorController.prototype.$onInit = function () {
                    var _this = this;
                    this.$rootScope.$on('formSubmitted', function () { return _this.$doCheck(); });
                };
                ErrorDecoratorController.prototype.$onChanges = function (changes) {
                    if (changes.showErrors) {
                        if (this.isInvalid() && changes.showErrors.currentValue) {
                            this.$element.addClass(this.hasErrorClass);
                        }
                        else {
                            this.$element.removeClass(this.hasErrorClass);
                        }
                    }
                    if (changes.showUnrequired) {
                        if (this.$element.find('[class*="ng-invalid-"]:not([class*="ng-invalid-required"])').length > 0
                            && changes.showUnrequired.currentValue) {
                            this.$element.addClass(this.hasErrorClass);
                        }
                        else {
                            this.$element.removeClass(this.hasErrorClass);
                        }
                    }
                };
                ErrorDecoratorController.prototype.$doCheck = function () {
                    if (this.isInvalid() && this.showErrors) {
                        this.$element.addClass(this.hasErrorClass);
                    }
                    else {
                        this.$element.removeClass(this.hasErrorClass);
                    }
                };
                ErrorDecoratorController.prototype.isInvalid = function () {
                    return this.$element.find('.ng-invalid').length > 0;
                };
                return ErrorDecoratorController;
            }());
            var ErrorDecoratorDirective = /** @class */ (function () {
                function ErrorDecoratorDirective() {
                    this.bindToController = true;
                    this.controller = ['$element', '$rootScope', ErrorDecoratorController];
                    this.restrict = 'A';
                    this.scope = {
                        showErrors: '<rcShowErrors',
                        showUnrequired: '<?'
                    };
                }
                return ErrorDecoratorDirective;
            }());
            Shared.remeCareSharedModule.directive('rcShowErrors', function () { return new ErrorDecoratorDirective(); });
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
