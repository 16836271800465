module RemeCare.Shared.Contract.Code {
    
    export enum DayOfWeek {
        None = 0,
        Monday = 1,
        Tuesday = 2,
        Wednesday = 4,
        Thursday = 8,
        Friday = 16,
        Saturday = 32,
        Sunday = 64
    }

    export class DayOfWeekFlags {
        public static create() {
            return Framework.Helper.FlaggedEnum.create(DayOfWeek, DayOfWeek.Sunday);
        } 
    }
} 