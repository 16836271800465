module RemeCare.Shared.Framework {
    import Medication = Shared.Contract.Read.IMedication;
    import SearchMedicationQuery = Shared.Contract.Read.Query.ISearchMedicationQuery;

    interface ISearchMedicationCriteria {
        medicationName?: string;
        activeSubstance?: string;
    }

    interface ISearchMedicationScope extends Framework.IBaseScope, ng.ui.bootstrap.IModalScope {
        searchMedicationGrid: Framework.Grid.Grid<Medication>;
        selectedMedicationGrid: Framework.Grid.Grid<Medication>;
        medications: Medication[];
        isBusy: boolean;
        criteriaCollapsed: boolean;
        totalItems: number;
        //title: string;

        clearSearchParams: () => void;
        toggleCriteria: () => void;
        search: () => void;
        select(): void;
    }

    class SearchProductCtrl extends Framework.ControllerBase<ISearchMedicationScope> {
        constructor(
            $scope: ISearchMedicationScope,
            $translate,
            toaster: Framework.Toaster,
            private gridBuilderSvc:Framework.Grid.GridBuilderFactory,
            private uiGridConstants,
            //private title: string,
            private medicationSearchSvc: Shared.Service.MedicationSearchSvc) {

            super($scope, $translate, toaster);

            this.$scope.medications = [];

            this.$scope.clearSearchParams = () => this.clearSearchParams();
            this.$scope.toggleCriteria = () => this.toggleCriteria();
            this.$scope.search = () => this.search();

            this.buildGrid();
            this.buildSelectedGrid();

            this.$scope.searchMedicationGrid.sortOptions = {
                field: 'MedicationName',
                direction: uiGridConstants.ASC
            }
            this.$scope.searchMedicationGrid.searchCriteria = {};

            this.$scope.select = () => this.select();
        }

        public $onInit() {
            this.$scope.criteriaCollapsed = false;
        }

        clearSearchParams(): void {
            this.$scope.searchMedicationGrid.searchCriteria = {}
        }

        toggleCriteria(): void {
            this.$scope.criteriaCollapsed = !this.$scope.criteriaCollapsed;
        }

        search(): void {
            this.$scope.searchMedicationGrid.pagingOptions.currentPage = 1;
            this.$scope.searchMedicationGrid.search();
        }

        private executeSearch(page: number, pageSize: number, sortField: string, sortDirection: string, criteria: ISearchMedicationCriteria)
            : JQueryPromise<Shared.Contract.ISearchResult<Medication>> {
            var deferred = jQuery.Deferred<Shared.Contract.ISearchResult<Medication>>();
            this.$scope.isBusy = true;
            var query: SearchMedicationQuery = {
                page: page,
                pageSize: pageSize,
                medicationName: criteria.medicationName,
                activeSubstance: criteria.activeSubstance,
                productClassIds: [],
                sortField: sortField,
                sortOrder: sortDirection
            }
            this.medicationSearchSvc.searchMedications(query)
                .success((data) => {
                    deferred.resolve(data);
                    this.$scope.totalItems = data.Total;
                    this.$scope.isBusy = false;
                    this.$scope.criteriaCollapsed = true;
                }).error((message) => {
                    super.errorCallback(message);
                    deferred.reject();
                });
            return deferred.promise();
        }

        private buildGrid(): void {
            this.$scope.searchMedicationGrid = this.gridBuilderSvc
                .createGridBuilder<Medication>((page: number, pageSize: number, sortField: string, sortDirection: string, criteria: ISearchMedicationCriteria) =>
                    this.executeSearch(page, pageSize, sortField, sortDirection, criteria))
                .addColumn('MedicationName', 'General.MedicationName')
                .addColumn('ActiveSubstance', 'General.ActiveSubstance')
                .addColumn('GalenicForm', 'General.GalenicForm')
                .addColumn('MedicationTypes', 'General.MedicationType', { cellFilter: 'delimitedDisplay', enableSorting: false })
                .addSelectButtonColumn((selected) => this.resultSelected(selected))
                .build();
        }

        private buildSelectedGrid(): void {
            this.$scope.selectedMedicationGrid = this.gridBuilderSvc
                .createGridBuilder<Medication>()
                .addColumn('MedicationName', 'General.MedicationName')
                .addColumn('ActiveSubstance', 'General.ActiveSubstance')
                .addColumn('GalenicForm', 'General.GalenicForm')
                .addColumn('MedicationTypes', 'General.MedicationType', { cellFilter: 'delimitedDisplay', enableSorting: false })
                .addDeleteButtonColumn((selected) => this.resultRemoved(selected))
                .setExternalSorting(false)
                .build();
            this.$scope.selectedMedicationGrid.setData(this.$scope.medications);
        }

        private resultSelected(result: Medication): void {
            if (!_(this.$scope.medications).any(m => m.ProductId === result.ProductId)) {
                this.$scope.medications.push(result);
            }
        }

        private resultRemoved(result: Medication): void {
            var index = this.$scope.medications.indexOf(result);
            this.$scope.medications.splice(index, 1);
        }

        private select() {
            this.$scope.$close(this.$scope.medications);
        }
    }

    remeCareSharedModule
        .controller('searchProductCtrl', (
            $scope: ISearchMedicationScope,
            $translate,
            toaster: Framework.Toaster,
            gridBuilderSvc: Framework.Grid.GridBuilderFactory,
            uiGridConstants,
            medicationSearchSvc: Shared.Service.MedicationSearchSvc
        ) => new SearchProductCtrl(
            $scope,
            $translate,
            toaster,
            gridBuilderSvc,
            uiGridConstants,
            medicationSearchSvc));
}