namespace RemeCare.Shared.Framework.Service {
    export class StorageServiceConstants {
        // Keys
        public static agendaKey: string = 'agendaFilter';
        public static teamAgendaKey: string = 'teamAgendaFilter';
        public static carePlanActionKey: string = 'carePlanActionFilter';
        public static integrationMonitorKey: string = 'integrationMonitorFilter';
        public static patientKey: string = 'patientFilter';

        // String values for agendaFilter
        public static myAgenda: string = 'agenda.myagenda';
        public static teamAgenda: string = 'agenda.teamagenda';
        public static colleagueAgenda: string = 'agenda.colleagueagenda';

        public static fitbitPkceVerifier: string = 'fitbit.pkce.verifier';
    }
}
