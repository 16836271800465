var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Model;
            (function (Model) {
                var DataSourceTranslation = /** @class */ (function () {
                    function DataSourceTranslation(def, dataSourceType) {
                        this.id = def.Id;
                        this.text = def.Text;
                        this.isNumeric = def.IsNumeric;
                        this.dataSourceType = dataSourceType;
                        this.dependantObject = def.DependantObject;
                        this.trackId = "" + this.id + this.dataSourceType.Id;
                        if (this.dependantObject != null) {
                            this.trackId = this.trackId + ("" + this.dependantObject.Id);
                        }
                    }
                    DataSourceTranslation.prototype.getText = function () {
                        var result = this.text + " - " + this.dataSourceType.Text;
                        if (this.dependantObject != null)
                            result = result + (" - " + this.dependantObject.Text);
                        return result;
                    };
                    DataSourceTranslation.prototype.toEntityTranslation = function () {
                        return {
                            Id: this.id,
                            Text: this.text
                        };
                    };
                    return DataSourceTranslation;
                }());
                Model.DataSourceTranslation = DataSourceTranslation;
            })(Model = Framework.Model || (Framework.Model = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
