var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Contract;
        (function (Contract) {
            var Code;
            (function (Code) {
                var Nationality;
                (function (Nationality) {
                    Nationality[Nationality["Belgian"] = 1] = "Belgian";
                    Nationality[Nationality["Dutch"] = 2] = "Dutch";
                    Nationality[Nationality["French"] = 3] = "French";
                    Nationality[Nationality["German"] = 4] = "German";
                    Nationality[Nationality["Luxembourgish"] = 5] = "Luxembourgish";
                })(Nationality = Code.Nationality || (Code.Nationality = {}));
                var NationalityIso2;
                (function (NationalityIso2) {
                    NationalityIso2["Belgian"] = "BE";
                    NationalityIso2["Dutch"] = "NL";
                    NationalityIso2["French"] = "FR";
                    NationalityIso2["German"] = "DE";
                    NationalityIso2["Luxembourgish"] = "LU";
                })(NationalityIso2 = Code.NationalityIso2 || (Code.NationalityIso2 = {}));
            })(Code = Contract.Code || (Contract.Code = {}));
        })(Contract = Shared.Contract || (Shared.Contract = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
