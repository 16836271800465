module RemeCare.Shared.Framework.Factory {

    export type PropertyMappers = { [property: string]: IPropertyMapper<any> };

    export interface IPropertyMapper<T> {
        getString: (object: T) => string;
        getObject: (string: string) => Promise<T>;
    }

    export class UrlBinder {
        constructor(
            private readonly $location: ng.ILocationService,
            private readonly parentObject: {},
            private readonly propertyMappers?: PropertyMappers) {
            this.propertyMappers = propertyMappers || {};
        }

        private properties: Array<string> = [];

        public async bind(properties?: Array<string>): Promise<void> {
            properties = properties || [];
            _(properties).forEach(p => this.properties.push(p));
            this.properties = _(this.properties).unique();
            for (const property of this.properties) {
                const object = this.$location.search()[property];
                this.parentObject[property] = this.propertyMappers[property]
                    ? await this.propertyMappers[property].getObject(object)
                    : this.getObjectValue(object);
            }
        }

        public async bindAllFromUrl(): Promise<void> {
            const properties = _(this.$location.search()).keys();
            await this.bind(properties);
        }

        public reflectInUrl(fullParent?: boolean): void {
            if (fullParent) {
                _(this.parentObject).chain().keys().forEach(k => this.properties.push(k));
                this.properties = _(this.properties).unique();
            }

            this.$location.replace();
            _(this.properties).forEach((p) => {
                const stringValue = this.propertyMappers[p]
                    ? this.propertyMappers[p].getString(this.parentObject[p])
                    : JSON.stringify(this.parentObject[p]);
                this.$location.search(p, stringValue);
            });
        }

        public isEmpty(): boolean {
            return _(this.properties).all((p) => {
                const property = this.parentObject[p];
                return property == null ||
                    (_(property).isString() && property === '') ||
                    (_(property).isObject() && _(property).isEmpty());
            });
        }

        private getObjectValue(object): any {
            return object
                ? JSON.parse(object)
                : null;
        }
    }

    export class UrlBinderFactory {
        constructor(
            private readonly $location: ng.ILocationService) {
        }

        public createUrlBinder(parentObject: {}, propertyMappers?: PropertyMappers): UrlBinder {
            return new UrlBinder(this.$location, parentObject, propertyMappers);
        }
    }

    remeCareSharedModule.service('urlBinderFactory', UrlBinderFactory);
}