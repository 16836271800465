module RemeCare.Shared.Contract.Code {

    export enum ContactAttribute {
        Language = 1,
        Email = 2,
        Mobile = 3,
        Address = 4,
        FirstName = 5,
        LastName = 6,
        Title = 7,
        Gender = 8,
        Age = 9,
        IsOptedOut = 10,
        Name = 11,
        ActorRoleId = 12
    }
}