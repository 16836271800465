module RemeCare.Shared.Filter {

    function numberFraction(numberFilter, $locale: ng.ILocaleService): (number: number, fractionSize: number) => string {
        return (number: number, fractionSize: number) => {
            number = Math.round(number * Math.pow(10, fractionSize)) / Math.pow(10, fractionSize);
            return numberFilter(number);
        }
    }

    remeCareSharedModule.filter('numberEx', numberFraction);
}