namespace RemeCare.Shared.Contract.Code {
    export enum Country {
        Belgium = 1,
        Netherlands = 2,
        France = 3,
        Luxembourg = 4,
        Germany = 5,
        Austria = 6,
        Estonia = 7,
        Israel = 8,
        Slovakia = 9,
        Ukraine = 10,
    }

    export enum CountryIso2 {
        Belgium = 'BE',
        Netherlands = 'NL',
        France = 'FR',
        Luxembourg = 'LU',
        Germany = 'DE',
        Austria = 'AT',
        Estonia = 'EE',
        Israel = 'IL',
        Slovakia = 'SK',
        Ukraine = 'UA',
    }
}
