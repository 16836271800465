namespace RemeCare.Shared.Framework.Grid {
    export class SearchGridTab {
        public heading: string;
        public tabActivated: () => void;
    }

    class SearchGridController<T> {
        // bindings
        public grid: Grid.Grid<T>;
        public hideCriteria: boolean;

        public advancedFiltersCollapsed: boolean;
        public hasAdvancedFilter: boolean;

        public activeTab: number;
        public tabs: SearchGridTab[];

        constructor(private readonly $transclude) {}

        public $onInit(): void {
            this.advancedFiltersCollapsed = true;
            this.hasAdvancedFilter = this.$transclude.isSlotFilled('advancedFilter');
            if (this.activeTab === undefined) {
                this.activeTab = 0;
            }
            if (this.hideCriteria === undefined) {
                this.hideCriteria = false;
            }
            if (this.hideCriteria) {
                this.search();
            }
        }

        public search(): void {
            this.grid.pagingOptions.currentPage = 1;
            this.grid.search();
        }

        public toggleAdvancedFilters(): void {
            this.advancedFiltersCollapsed = !this.advancedFiltersCollapsed;
        }

        public useTabs(): boolean {
            return this.tabs !== undefined && this.tabs.length > 0;
        }

        public tabSelected(tab: SearchGridTab): void {
            if (tab.tabActivated !== undefined) {
                tab.tabActivated();
            }
        }

        public clearCriteria(): void {
            this.grid.clearCriteria();
            if (this.useTabs()) {
                this.tabSelected(this.tabs[this.activeTab]);
            }
        }
    }

    remeCareSharedModule.component('rcSearchGrid', {
        bindings: {
            grid: '=',
            header: '@?',
            hideCriteria: '<?',
            progressBar: '@?',
            showTitle: '<?',
            activeTab: '<?',
            tabs: '<?',
        },
        controller: SearchGridController,
        template: [
            '$attrs',
            '$templateCache',
            ($attrs: ng.IAttributes, $templateCache: ng.ITemplateCacheService) => {
                let template = $templateCache.get<string>('framework/grid/searchGrid/searchGrid.html');
                if (!('expandable' in $attrs)) {
                    template = template.replace('expandable', '');
                }
                return template;
            },
        ],
        transclude: {
            filter: '?filter',
            advancedFilter: '?advancedFilter',
        },
    });
}
