var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Service;
            (function (Service) {
                var StorageService = /** @class */ (function () {
                    function StorageService($window, authservice) {
                        this.$window = $window;
                        this.authservice = authservice;
                    }
                    StorageService.prototype.store = function (key, value, personalize) {
                        if (personalize === void 0) { personalize = true; }
                        var serialized = JSON.stringify(value);
                        var personalizedKey = personalize ? this.generatePersonalizedKey(key) : key;
                        if (personalizedKey && serialized) {
                            this.$window.localStorage.setItem(personalizedKey, serialized);
                        }
                    };
                    StorageService.prototype.get = function (key, personalize) {
                        if (personalize === void 0) { personalize = true; }
                        var personalizedKey = personalize ? this.generatePersonalizedKey(key) : key;
                        if (personalizedKey) {
                            var serialized = this.$window.localStorage.getItem(personalizedKey);
                            if (serialized) {
                                return JSON.parse(serialized);
                            }
                        }
                        return null;
                    };
                    StorageService.prototype.remove = function (key, personalize) {
                        if (personalize === void 0) { personalize = true; }
                        var personalizedKey = personalize ? this.generatePersonalizedKey(key) : key;
                        if (personalizedKey) {
                            this.$window.localStorage.removeItem(personalizedKey);
                        }
                    };
                    StorageService.prototype.generatePersonalizedKey = function (key) {
                        var partyRoleId = this.authservice.getClaim(Framework.ClaimTypes.partyRoleId);
                        return partyRoleId && key ? key + "-" + partyRoleId : null;
                    };
                    return StorageService;
                }());
                Service.StorageService = StorageService;
                Shared.remeCareSharedModule.service('storageSvc', StorageService);
            })(Service = Framework.Service || (Framework.Service = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
