var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Directive;
            (function (Directive) {
                var InputCheckboxListController = /** @class */ (function () {
                    function InputCheckboxListController(idService) {
                        this.idService = idService;
                    }
                    InputCheckboxListController.prototype.$onInit = function () {
                        this.uniqueName = this.idService.generateId();
                    };
                    InputCheckboxListController.prototype.getValue = function (item) {
                        return this.value ? item[this.value] : item;
                    };
                    InputCheckboxListController.prototype.getDisplayText = function (item) {
                        if (this.displayText) {
                            return this.displayText.split(".").reduce(function (o, key) { return o && o[key] ? o[key] : null; }, item);
                        }
                        return null;
                    };
                    return InputCheckboxListController;
                }());
                Shared.remeCareSharedModule.component('rcInputCheckboxList', {
                    controller: InputCheckboxListController,
                    require: {
                        formCtrl: '^form',
                    },
                    templateUrl: 'views/formElements/inputCheckboxList.html',
                    bindings: {
                        ngModel: '=',
                        ngDisabled: '<?',
                        ngReadonly: '<?',
                        adjustSize: '<?',
                        label: '@',
                        options: '<',
                        value: '@?',
                        displayText: '@',
                        inputClass: '<',
                        required: '=ngRequired',
                        iconField: '@',
                        guidanceIconLocation: '<',
                        guidanceIconFunc: '<',
                        uniqueId: '@',
                    },
                });
            })(Directive = Framework.Directive || (Framework.Directive = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
