module RemeCare.Shared.Framework.Service {
    
    export interface IIdService {
        generateId(): string;
    }

    class IdService implements IIdService {
        private currentId = 0;

        generateId(): string {
            return `rc-id-${this.currentId++}`;
        }
    }

    remeCareSharedModule.factory('idService', () => new IdService())
}