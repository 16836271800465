module RemeCare.Shared.Framework.Periodicity {
    import TargetPhysicalQuantity = Framework.Model.TargetPhysicalQuantity;
    import TargetPhysicalQuantityRange = Framework.Model.TargetPhysicalQuantityRange;

    interface ITargetPhysicalQuantityScope extends Framework.IBaseScope, ng.ui.bootstrap.IModalScope {
        form: ng.IFormController;
        targetValue: TargetPhysicalQuantity;
        hasLimit: boolean;
        confirm: () => void;
        maxValue: () => number;
    }

    class TargetPhysicalQuantityController extends Framework.ControllerBase<ITargetPhysicalQuantityScope> {

        constructor(
            $scope: ITargetPhysicalQuantityScope,
            $translate,
            toaster) {
            super($scope, $translate, toaster);
            $scope.confirm = () => this.confirm();
            $scope.hasLimit = this.$scope.targetValue instanceof TargetPhysicalQuantityRange;
            $scope.maxValue = () => this.maxValue();
        }

        public $onInit(): void {}

        private confirm(): void {
            if (this.$scope.form.$invalid) {
                this.showValidationErrorMessage();
                return;
            }
            this.$scope.$close(this.$scope.targetValue);
        }

        private maxValue(): number {
            if (this.$scope.hasLimit)
                return (<TargetPhysicalQuantityRange>this.$scope.targetValue).upperAmount;
            else
                return this.$scope.targetValue.amount;
        }
    }

    remeCareSharedModule.controller('targetPhysicalQuantityCtrl', (
        $scope,
        $translate,
        toaster
    ) => new TargetPhysicalQuantityController(
        $scope,
        $translate,
        toaster
    ));
}