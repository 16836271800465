var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Directive;
            (function (Directive) {
                var InputCheckboxSingleController = /** @class */ (function () {
                    function InputCheckboxSingleController(idService) {
                        this.idService = idService;
                    }
                    InputCheckboxSingleController.prototype.$onInit = function () {
                        this.uniqueName = this.idService.generateId();
                        if (this.model) {
                            this.checkboxModel = true;
                        }
                    };
                    InputCheckboxSingleController.prototype.changed = function () {
                        this.model = this.checkboxModel ? (this.value ? this.option[this.value] : this.option) : null;
                    };
                    return InputCheckboxSingleController;
                }());
                Shared.remeCareSharedModule.component('rcInputCheckboxSingle', {
                    controller: InputCheckboxSingleController,
                    require: {
                        formCtrl: '^form',
                    },
                    templateUrl: 'framework/directives/formElements/inputCheckboxSingle/inputCheckboxSingle.html',
                    bindings: {
                        model: '=ngModel',
                        ngDisabled: '<?',
                        ngReadonly: '<?',
                        adjustSize: '<?',
                        label: '@',
                        option: '<',
                        value: '@?',
                        displayText: '@',
                        inputClass: '<',
                        required: '=ngRequired',
                        iconField: '@',
                        guidanceIconLocation: '<',
                        guidanceIconFunc: '<',
                        uniqueId: '@',
                    },
                });
            })(Directive = Framework.Directive || (Framework.Directive = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
