var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Contract;
        (function (Contract) {
            var Code;
            (function (Code) {
                var UIControlType;
                (function (UIControlType) {
                    UIControlType[UIControlType["Dropdown"] = 1] = "Dropdown";
                    UIControlType[UIControlType["Radio"] = 2] = "Radio";
                    UIControlType[UIControlType["Slider"] = 3] = "Slider";
                    UIControlType[UIControlType["Checkbox"] = 4] = "Checkbox";
                    UIControlType[UIControlType["CheckboxMultiple"] = 5] = "CheckboxMultiple";
                    UIControlType[UIControlType["DropdownListMultiple"] = 6] = "DropdownListMultiple";
                })(UIControlType = Code.UIControlType || (Code.UIControlType = {}));
            })(Code = Contract.Code || (Contract.Code = {}));
        })(Contract = Shared.Contract || (Shared.Contract = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
