module RemeCare.Shared.Contract.Code {

    export enum MonitoringPartType {
        LineChartNumeric = 1,
        TableNumericQualitative = 2,
        CumulativeBarChart = 3,
        FloatingBarChart = 4,
        ColourQualitativeTimeLine = 5,
        MedicationAdherenceChart = 6,
        MedicationDoseChart = 7,
        ActionTable = 8,
        ActionTimeLine = 9,
        CumulativeObjectiveChart = 10,
        ObjectiveScoreTable = 11,
        LineChartNumericExternal = 12,
        Boxplot = 13
    }
}