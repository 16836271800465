var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Contract;
        (function (Contract) {
            var Code;
            (function (Code) {
                var TherapyBusinessContextCode = /** @class */ (function () {
                    function TherapyBusinessContextCode() {
                    }
                    TherapyBusinessContextCode.MedicationSchema = 'MedicationSchema';
                    TherapyBusinessContextCode.CarePlanConfiguration = 'CarePlan.Configuration';
                    TherapyBusinessContextCode.CarePlanStatusChange = 'CarePlan.StatusChange';
                    TherapyBusinessContextCode.CarePlanActionMedicationSchema = 'CarePlanAction.MedicationSchema';
                    TherapyBusinessContextCode.CarePlanCareTeamChange = 'CarePlan.CareTeamChange';
                    return TherapyBusinessContextCode;
                }());
                Code.TherapyBusinessContextCode = TherapyBusinessContextCode;
            })(Code = Contract.Code || (Contract.Code = {}));
        })(Contract = Shared.Contract || (Shared.Contract = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
