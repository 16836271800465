var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var HealthCarePartyService = /** @class */ (function (_super) {
                __extends(HealthCarePartyService, _super);
                function HealthCarePartyService() {
                    return _super !== null && _super.apply(this, arguments) || this;
                }
                HealthCarePartyService.prototype.searchHealthCareSpecialtyProfessionsAsync = function (query) {
                    return this.getPromise('HealthCareSpecialtyProfessions', query, 'healthCareSpecialtyProfessions');
                };
                HealthCarePartyService.prototype.getHealthCareSpecialtyProfessionsAsync = function () {
                    return this.getPromise('HealthCareSpecialtyProfessions', null, 'healthCareParty.healthCareSpecialtyProfessions', null, function (t) { return t.Items; });
                };
                HealthCarePartyService.prototype.searchHealthCareOrganisationTypesAsync = function (query) {
                    return this.getPromise('HealthCareParty/HealthCareOrganisationTypes', query);
                };
                HealthCarePartyService.prototype.getHealthCareOrganisationTypesAsync = function () {
                    return this.getPromise('HealthCareParty/HealthCareOrganisationTypes', null, 'organisationTypes', null, function (r) { return r.Items; });
                };
                HealthCarePartyService.prototype.findHealthCareProfessionalTypesAsync = function () {
                    return this.getPromise('HealthCareParty/HealthCareProfessionalTypes');
                };
                HealthCarePartyService.prototype.searchPersonPartyRoleAsync = function (query) {
                    return this.getPromise('HealthCareParty/PersonPartyRoles', query, 'personPartyRole');
                };
                HealthCarePartyService.prototype.searchOrganisationPartyRoleAsync = function (query) {
                    return this.getPromise('HealthCareParty/OrganisationPartyRoles', query, 'organisationPartyRole');
                };
                HealthCarePartyService.$inject = Shared.Framework.ApiServiceBase.injectionParameters;
                return HealthCarePartyService;
            }(Framework.ApiServiceBase));
            Framework.HealthCarePartyService = HealthCarePartyService;
            Shared.remeCareSharedModule.service('healthCarePartySvc', HealthCarePartyService);
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
