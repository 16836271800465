var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Model;
            (function (Model) {
                var TherapyActionPartContextSetting = /** @class */ (function () {
                    function TherapyActionPartContextSetting(serverObject) {
                        if (serverObject != null) {
                            var groupedAnswerConditions = _.groupBy(serverObject.AnswerConditions, function (ac) { return ac.AnswerCodeSetItem.Id; });
                            var answerConditions = _.map(groupedAnswerConditions, function (group) {
                                var ac = new Model.AnswerCondition();
                                ac.AnswerCodeSetItem = group[0].AnswerCodeSetItem;
                                ac.GoToIds = _.map(group, function (member) { return member.GoToId; });
                                return ac;
                            });
                            var mandatoryOverrides_1 = [];
                            if (serverObject.IsMandatoryOverrides !== undefined) {
                                serverObject.IsMandatoryOverrides.forEach(function (mor) {
                                    // if first override for this codesetitem create it
                                    var mandatoryOverride = mandatoryOverrides_1.filter(function (f) { return f.CareActCodeSetItemId === mor.CareActCodeSetItemId; })[0];
                                    if (mandatoryOverride === undefined) {
                                        mandatoryOverride = new TherapyActionPartContextSettingMandatoryOverride();
                                        mandatoryOverride.CareActCodeSetItemId = mor.CareActCodeSetItemId;
                                        mandatoryOverride.MandatoryTherapyActionParts = [];
                                        mandatoryOverrides_1.push(mandatoryOverride);
                                    }
                                    // add override to list
                                    mandatoryOverride.MandatoryTherapyActionParts.push(mor.MandatoryTherapyActionPartId);
                                });
                            }
                            this.TherapyActionPartContextSettingId = serverObject.TherapyActionPartContextSettingId;
                            this.IsMandatory = serverObject.IsMandatory;
                            this.GuidanceCode = serverObject.GuidanceCode;
                            this.GuidanceEnabled = serverObject.GuidanceEnabled;
                            this.IsLeftOutForRemeCoach = serverObject.IsLeftOutForRemeCoach;
                            this.RemeCoachDefaultValue = serverObject.RemeCoachDefaultValue;
                            this.TherapyActionPart = serverObject.TherapyActionPart;
                            this.HasLocation = serverObject.HasLocation;
                            this.HasExecutor = serverObject.HasExecutor;
                            this.CodeSetType = serverObject.CodeSetType;
                            this.DateType = serverObject.DateType;
                            this.HasTime = serverObject.HasTime;
                            this.IsWindow = serverObject.IsWindow;
                            this.Name = serverObject.Name;
                            this.NumberOfAttempts = serverObject.NumberOfAttempts;
                            this.IsTherapyMediaLibItem = serverObject.IsTherapyMediaLibItem;
                            this.SimpleInformationObject = serverObject.SimpleInformationObject;
                            this.AnswerConditions = answerConditions;
                            this.ShowObservableEntity = serverObject.ShowObservableEntity;
                            this.ShowCharacteristic = serverObject.ShowCharacteristic;
                            this.ExternalContextReference = serverObject.ExternalContextReference;
                            this.ExternalParameterReference = serverObject.ExternalParameterReference;
                            this.Decimals = serverObject.Decimals;
                            this.TherapyActionPartInputDevice = {
                                Id: serverObject.InputId,
                                Device: {
                                    Id: null,
                                    Device: null
                                },
                                Characteristic: {
                                    Id: null,
                                    Characteristic: null
                                }
                            };
                            this.IsTelemonitoringEnabled = serverObject.IsTelemonitoringEnabled;
                            this.IntendedValue = serverObject.IntendedValue;
                            this.HasStandardPhrases = serverObject.HasStandardPhrases;
                            this.AllowsAllFileTypes = serverObject.AllowsAllFileTypes;
                            this.HasReferenceDate = serverObject.HasReferenceDate;
                            this.IsFollowUpDocument = serverObject.IsFollowUpDocument;
                            this.IsExternalDocument = serverObject.IsExternalDocument;
                            this.RequestInfoFileTypes = serverObject.RequestInfoFileTypes;
                            this.DefaultDelayDuration = serverObject.DefaultDelayDuration;
                            this.DefaultFromTime = serverObject.DefaultFromTime;
                            this.DefaultUntilTime = serverObject.DefaultUntilTime;
                            this.IsBasedOnCarePlanStartDate = serverObject.IsBasedOnCarePlanStartDate;
                            this.LocationSettings = serverObject.LocationSettings;
                            this.IsEditable = serverObject.IsEditable;
                            this.CanPersonaliseTelemonitoringDeactivation = serverObject.CanPersonaliseTelemonitoringDeactivation;
                            this.CanPersonaliseToHidePart = serverObject.CanPersonaliseToHidePart;
                            this.ChildRelatedStructuralTherapyAction = serverObject.ChildRelatedStructuralTherapyAction;
                            this.DocumentCategories = serverObject.DocumentCategories;
                            this.IsCrossProcessInstance = serverObject.IsCrossProcessInstance;
                            this.LinkedThresholdId = serverObject.LinkedThresholdId;
                            this.MinimumValue = serverObject.MinimumValue;
                            this.MaximumValue = serverObject.MaximumValue;
                            this.StructuralTherapyActionToPlanId = serverObject.StructuralTherapyActionToPlanId;
                            this.IsMandatoryOverrides = mandatoryOverrides_1;
                            this.ExternalReference = serverObject.ExternalReference;
                            this.DoNotTreatRegistrationAsRemark = !serverObject.TreatRegistrationAsRemark;
                        }
                    }
                    TherapyActionPartContextSetting.prototype.getDropdownText = function () {
                        var text;
                        var tap = this.TherapyActionPart;
                        switch (tap.ActionTypeCode) {
                            case Shared.Contract.Code.ActionType.QualitativeObservation:
                                text = tap.valueOf().ObservableEntity;
                                break;
                            case Shared.Contract.Code.ActionType.QuantitativeObservation:
                                text = tap.ActionTypeText + ': ' + tap.ProcessParameter;
                                break;
                            case Shared.Contract.Code.ActionType.Date:
                                text = tap.ActionTypeText + ': ' + tap.ProcessParameter;
                                break;
                            default:
                                text = tap.ActionTypeText;
                                break;
                        }
                        return tap.Sequence + '. ' + text;
                    };
                    TherapyActionPartContextSetting.prototype.toServerWrite = function (isRemeCoachEnabled) {
                        if (isRemeCoachEnabled === void 0) { isRemeCoachEnabled = false; }
                        var simpleInformationObject = null;
                        if (!this.IsTherapyMediaLibItem && this.SimpleInformationObject) {
                            simpleInformationObject = {
                                InformationObjectId: this.SimpleInformationObject.InformationObjectId,
                                Name: this.SimpleInformationObject.Name,
                                Description: this.SimpleInformationObject.Description,
                                TypeId: this.SimpleInformationObject.Type.Id,
                                Url: this.SimpleInformationObject.Url,
                                Copy: this.SimpleInformationObject.Copy,
                            };
                            if (this.SimpleInformationObject.Type.Id === Shared.Contract.Code.InformationObjectType.LinkToFile &&
                                this.SimpleInformationObject.Documents.length > 0) {
                                simpleInformationObject.InformationObjectTranslationId = this.SimpleInformationObject.Documents[0].Id;
                                simpleInformationObject.Url = this.SimpleInformationObject.Documents[0].Url;
                            }
                        }
                        var childRelated = this.mapRelated(this.ChildRelatedStructuralTherapyAction);
                        var answerconditions = new Array();
                        _.map(this.AnswerConditions, function (ac) {
                            _.map(ac.GoToIds, function (goToId) {
                                answerconditions.push({
                                    AnswerCodeSetItemId: ac.AnswerCodeSetItem.Id,
                                    GoToActionPartId: goToId,
                                });
                            });
                        });
                        if (!this.IsTelemonitoringEnabled && !this.IsExternalDocument) {
                            this.ExternalContextReference = null;
                            this.ExternalParameterReference = null;
                        }
                        if (!isRemeCoachEnabled || !this.IsTelemonitoringEnabled) {
                            this.TherapyActionPartInputDevice = null;
                        }
                        var therapyActionPartContextSettingMandatoryOverridesToWrite = new Array();
                        if (this.IsMandatoryOverrides !== undefined && this.IsMandatoryOverrides !== null) {
                            for (var _i = 0, _a = this.IsMandatoryOverrides; _i < _a.length; _i++) {
                                var tapcsmo = _a[_i];
                                if (tapcsmo.MandatoryTherapyActionParts !== undefined &&
                                    tapcsmo.MandatoryTherapyActionParts !== null) {
                                    for (var _b = 0, _c = tapcsmo.MandatoryTherapyActionParts; _b < _c.length; _b++) {
                                        var tapcsItem = _c[_b];
                                        var tapcsmotw = new Shared.Contract.Write.ITherapyActionPartContextSettingMandatoryOverride();
                                        tapcsmotw.CareActCodeSetItemId = tapcsmo.CareActCodeSetItemId;
                                        tapcsmotw.MandatoryTherapyActionPartId = tapcsItem;
                                        therapyActionPartContextSettingMandatoryOverridesToWrite.push(tapcsmotw);
                                    }
                                }
                            }
                        }
                        var write = {
                            TherapyActionPartContextSettingId: this.TherapyActionPartContextSettingId,
                            IsMandatory: this.IsMandatory,
                            GuidanceCode: this.GuidanceCode,
                            GuidanceEnabled: this.GuidanceEnabled,
                            IsLeftOutForRemeCoach: this.IsLeftOutForRemeCoach,
                            RemeCoachDefaultValue: this.RemeCoachDefaultValue,
                            TherapyActionPartId: this.TherapyActionPart.TherapyActionPartId,
                            Decimals: this.Decimals,
                            InputId: this.TherapyActionPartInputDevice
                                ? this.TherapyActionPartInputDevice.Id
                                : null,
                            IntendedValueId: this.IntendedValue != null ? this.IntendedValue.Id : null,
                            NumberOfAttempts: this.NumberOfAttempts,
                            IsTelemonitoringEnabled: this.IsTelemonitoringEnabled,
                            HasLocation: this.HasLocation,
                            HasExecutor: this.HasExecutor,
                            CodeSetTypeId: this.CodeSetType != null ? this.CodeSetType.Id : null,
                            Name: this.Name,
                            DateTypeId: this.DateType != null ? this.DateType.Id : null,
                            HasTime: this.HasTime,
                            IsWindow: this.IsWindow,
                            HasStandardPhrases: this.HasStandardPhrases,
                            AllowsAllFileTypes: this.AllowsAllFileTypes,
                            HasReferenceDate: this.HasReferenceDate,
                            IsFollowUpDocument: this.IsFollowUpDocument,
                            IsExternalDocument: this.IsExternalDocument,
                            IsBasedOnCarePlanStartDate: this.IsBasedOnCarePlanStartDate,
                            IsEditable: this.IsEditable,
                            IsTherapyMediaLibItem: this.IsTherapyMediaLibItem,
                            SimpleInformationObject: simpleInformationObject,
                            DefaultDelayDuration: this.DefaultDelayDuration && this.DefaultDelayDuration.Unit
                                ? {
                                    Unit: this.DefaultDelayDuration.Unit,
                                    Quantity: this.DefaultDelayDuration.Quantity,
                                }
                                : null,
                            DefaultFromTime: this.DefaultFromTime,
                            DefaultUntilTime: this.DefaultUntilTime,
                            AnswerConditions: answerconditions,
                            ShowObservableEntity: this.ShowObservableEntity,
                            ShowCharacteristic: this.ShowCharacteristic,
                            ExternalContextReference: this.ExternalContextReference,
                            ExternalParameterReference: this.ExternalParameterReference,
                            RequestInfoFileTypes: _(this.RequestInfoFileTypes).map(function (x) {
                                return {
                                    IsActive: x.IsActive,
                                    FileTypeId: x.FileType.Id,
                                };
                            }),
                            LocationSettings: _(this.LocationSettings).map(function (x) {
                                return {
                                    LocationSettingId: x.LocationSettingId,
                                    AddressTypePatientId: x.ContactPointUsageType != null ? x.ContactPointUsageType.Id : null,
                                    Duration: x.Duration != null && x.Duration.Unit != null
                                        ? {
                                            Unit: x.Duration.Unit,
                                            Quantity: x.Duration.Quantity,
                                        }
                                        : null,
                                    Sequence: x.Sequence,
                                    UsesHCOrgAddress: x.UsesHCOrgAddress,
                                };
                            }),
                            CanPersonaliseTelemonitoringDeactivation: this.CanPersonaliseTelemonitoringDeactivation,
                            CanPersonaliseToHidePart: this.CanPersonaliseToHidePart,
                            ChildRelatedStructuralTherapyAction: childRelated,
                            DocumentCategories: this.DocumentCategories,
                            IsCrossProcessInstance: this.IsCrossProcessInstance,
                            LinkedThresholdId: this.LinkedThresholdId,
                            MinimumValue: this.MinimumValue,
                            MaximumValue: this.MaximumValue,
                            StructuralTherapyActionToPlanId: this.StructuralTherapyActionToPlanId,
                            IsMandatoryOverrides: therapyActionPartContextSettingMandatoryOverridesToWrite,
                            ExternalReference: this.ExternalReference,
                            TreatRegistrationAsRemark: !this.DoNotTreatRegistrationAsRemark
                        };
                        return write;
                    };
                    TherapyActionPartContextSetting.prototype.mapRelated = function (related) {
                        if (related == null || related.ChildStructuralTherapyAction == null) {
                            return null;
                        }
                        var rel = {
                            ChildStructuralTherapyActionId: related.ChildStructuralTherapyAction.Id,
                            TherapyActionRelationTypeId: related.TherapyActionRelationType.Id,
                            Delay: undefined,
                        };
                        if (related.Delay) {
                            rel.Delay = {
                                Unit: related.Delay.Unit,
                                Quantity: related.Delay.Quantity,
                            };
                        }
                        return rel;
                    };
                    return TherapyActionPartContextSetting;
                }());
                Model.TherapyActionPartContextSetting = TherapyActionPartContextSetting;
                var TherapyActionPartContextSettingMandatoryOverride = /** @class */ (function () {
                    function TherapyActionPartContextSettingMandatoryOverride() {
                    }
                    return TherapyActionPartContextSettingMandatoryOverride;
                }());
                Model.TherapyActionPartContextSettingMandatoryOverride = TherapyActionPartContextSettingMandatoryOverride;
            })(Model = Framework.Model || (Framework.Model = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
