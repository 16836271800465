namespace RemeCare.Shared.Framework.Directive {
    class InputDateController implements ng.IComponentController {
        // bindings
        public change: () => void | (() => void);

        public $onInit(): void {}

        public onDateChanged(): void {
            if (this.change) {
                const changeFunction = this.change();
                if (_.isFunction(changeFunction)) {
                    (changeFunction as (() => void))();
                }
            }
        }
    }

    remeCareSharedModule.component('rcInputDate', {
        bindings: {
            model: '=ngModel',
            required: '=?ngRequired',
            readOnly: '=?ngReadonly',
            disabled: '=?ngDisabled',
            hasWarning: '<?ngWarning',
            showTime: '=?',
            label: '@',
            minDate: '=?',
            maxDate: '=?',
            adjustSize: '=?', // The sm size of the label, defaults to 4
            change: '&?',
            startingView: '@?',
            guidanceIconLocation: '<',
            guidanceIconFunc: '<',
            uniqueId: '@',
        },
        controller: InputDateController,
        require: {
            formCtrl: '^form',
        },
        templateUrl: 'framework/directives/formElements/inputDate/inputDate.html',
        transclude: true,
    });
}
