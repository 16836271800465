var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Contract;
        (function (Contract) {
            var Code;
            (function (Code) {
                var CareRequestFilterTypeCode;
                (function (CareRequestFilterTypeCode) {
                    CareRequestFilterTypeCode[CareRequestFilterTypeCode["HandleByMe"] = 1] = "HandleByMe";
                    CareRequestFilterTypeCode[CareRequestFilterTypeCode["HandleByOthers"] = 2] = "HandleByOthers";
                    CareRequestFilterTypeCode[CareRequestFilterTypeCode["Final"] = 3] = "Final";
                    CareRequestFilterTypeCode[CareRequestFilterTypeCode["Cancelled"] = 4] = "Cancelled";
                    CareRequestFilterTypeCode[CareRequestFilterTypeCode["OnGoing"] = 5] = "OnGoing";
                    CareRequestFilterTypeCode[CareRequestFilterTypeCode["HandleByMyTeams"] = 6] = "HandleByMyTeams";
                    CareRequestFilterTypeCode[CareRequestFilterTypeCode["RequestedByMe"] = 7] = "RequestedByMe";
                })(CareRequestFilterTypeCode = Code.CareRequestFilterTypeCode || (Code.CareRequestFilterTypeCode = {}));
            })(Code = Contract.Code || (Contract.Code = {}));
        })(Contract = Shared.Contract || (Shared.Contract = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
