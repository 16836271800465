module RemeCare.Shared.Directive {
    class CollapsePanelController implements ng.IComponentController {

        // bindings
        public borderless: boolean;
        public collapsed: boolean;
        public customClass: string;

        constructor(private $rootScope: ng.IScope) {

        }

        public $onInit(): void {
        }

        public toggleCollapse(): void {
            this.collapsed = !this.collapsed;
            if (!this.collapsed) {
                this.$rootScope.$broadcast('panelUncollapsed');
            }
        }

        public getClass(): string {
            const panelClass = this.borderless
                ? 'panel-borderless'
                : 'panel';
            const extraClass = this.customClass || 'panel-default';
            const collapsedClass = this.collapsed && 'collapsed';
            return `${panelClass} ${extraClass} ${collapsedClass}`;
        }
    }

    remeCareSharedModule.component('rcCollapsePanel',
    {
        controller: CollapsePanelController,
        bindings: {
            title: '@?panelHeader',
            collapsed: '=?',
            disableBodyClass: '<?',
            customClass: '@?',
            borderless: '<?',
            headerClass: '@?',
            readOnly: '=ngReadonly',
            create: '&?create',
        },
        templateUrl: 'views/collapsePanel.html',
        transclude: {
            header: '?header'
        }
    });
}