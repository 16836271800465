module RemeCare.Shared.Framework {

    interface IVideoPlayerScope extends ng.IScope {
        url: string;
        autoplay: boolean;
        trustedUrl: string;
    }

    class VideoPlayerDirective implements ng.IDirective {

        constructor(private $sce: ng.ISCEService) { }

        restrict = 'E';

        scope: { [boundProperty: string]: string } = {
            url: '=url',
            autoplay: '='
        }

        link: ng.IDirectiveLinkFn = (scope: IVideoPlayerScope) => {
            var url = scope.url;
            if (scope.autoplay) {
                url += '?autoplay=1';
            }
            scope.trustedUrl = this.$sce.trustAsResourceUrl(url);
        }

        template =
        '<div ng-if="trustedUrl"><iframe width="640" height="390" ng-src="{{ trustedUrl }}" webkitallowfullscreen mozallowfullscreen allowfullscreen frameborder="0" /></div>';
    }

    angular.module('RemeCare.Shared').directive('rcVideoPlayer', ($sce) => new VideoPlayerDirective($sce));
}