module RemeCare.Shared.Framework.Factory {

    export class IntraDayTimingFactory {
        public createIntraDayTiming(serverObject: Shared.Contract.Read.IIntraDayTiming): Framework.Model.IntraDayTiming {
            let result;
            switch (serverObject.Type) {
                case Shared.Contract.Code.IntraDayTimingType.IntraDayTimingAdministration:
                    result = new Framework.Model.IntraDayTimingAdministration(<Shared.Contract.Read.IIntraDayTimingAdministration>serverObject);
                    break;
                default:
                    result = new Framework.Model.IntraDayTiming(serverObject);
                    break;
            }
            if (serverObject.TargetValues != null) {
                const targetValueFactory = new TargetValueFactory();
                result.targetValues = _(serverObject.TargetValues).map(t => targetValueFactory.createTargetValue(t));
            }
            return result;
        }

        public createIntraDayTimingWithTargetDoses(numberOfTargetDoses: number): Framework.Model.IntraDayTiming {

            const result = new Model.IntraDayTimingAdministration();
            for (let i = 0; i < numberOfTargetDoses; i++) {
                result.targetValues.push(new Model.TargetDose());
            }
            return result;
        }

        public createWithTherapyActionPartContextSettings(parts: Array<Model.TherapyActionPartContextSetting>): Framework.Model.IntraDayTiming {
            let result;
            const administrationPartSetting = _(parts).find(tapcs => tapcs.TherapyActionPart.ActionTypeCode === Shared.Contract.Code.ActionType.Administration);
            if (administrationPartSetting != null) {
                result = new Framework.Model.IntraDayTimingAdministration();
                const targetDose = new Framework.Model.TargetDose();
                if (administrationPartSetting) {
                    targetDose.useContextSetting(administrationPartSetting);
                }
                result.targetValues = [targetDose];
            } else {
                result = new Framework.Model.IntraDayTiming();
                result.targetValues = [];
            }
            const quantitativePartSettings = _(parts)
                .filter(p => p.TherapyActionPart.ActionTypeCode === Shared.Contract.Code.ActionType.QuantitativeObservation);
            _(quantitativePartSettings).each(q => {
                if (q.IntendedValue != null) {
                    if (q.IntendedValue.Id !== Shared.Contract.Code.IntendedValue.None) {
                        result.targetValues.push(this.createTargetPhysicalQuantity(q));
                    }
                }
            });
            return result;
        }

        private createTargetPhysicalQuantity(therapyActionPartContextSetting: Model.TherapyActionPartContextSetting):
            Framework.Model.TargetPhysicalQuantity {
            let targetValue: Framework.Model.TargetPhysicalQuantity;
            if (therapyActionPartContextSetting.IntendedValue.Id === Shared.Contract.Code.IntendedValue.Single) {
                targetValue = new Framework.Model.TargetPhysicalQuantity();
            } else {
                targetValue = new Framework.Model.TargetPhysicalQuantityRange();
            }
            targetValue.useContextSetting(therapyActionPartContextSetting);
            return targetValue;
        }
    }
}