var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Model;
            (function (Model) {
                var AnswerDiseaseCondition = /** @class */ (function () {
                    function AnswerDiseaseCondition() {
                    }
                    return AnswerDiseaseCondition;
                }());
                Model.AnswerDiseaseCondition = AnswerDiseaseCondition;
            })(Model = Framework.Model || (Framework.Model = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
