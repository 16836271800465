namespace RemeCare.Shared.Framework.Periodicity {
    import Periodicity = Framework.Model.Periodicity;
    import RecurrentCycle = RemeCare.Shared.Framework.Model.RecurrentCycle;

    interface IPeriodicityModalScope extends Framework.IBaseScope, ng.ui.bootstrap.IModalScope {
        form: ng.IFormController;
        periodicity: Periodicity;
        recurrentCycle: RecurrentCycle;
        readOnly: boolean;
        confirm: () => void;

        therapyActionPartContextSettings: Contract.Read.ITherapyActionPartContextSetting[];
        productIds: Contract.Guid[];
        productClassIds: Contract.Guid[];
        forMedication: boolean;
        limitedConfig: boolean;
        medication: Shared.Contract.Read.IMedication;
        carePlanStartDate: Date;
        prescriptionStartDate: Date;
    }

    class PeriodicityModalController extends Framework.ControllerBase<IPeriodicityModalScope> {
        constructor(
            protected $scope: IPeriodicityModalScope,
            protected $translate: ng.translate.ITranslateService,
            protected toaster: Framework.Toaster,
            private readonly therapyActionPartContextSettings: Contract.Read.ITherapyActionPartContextSetting[],
            private readonly productIds: Contract.Guid[],
            private readonly productClassIds: Contract.Guid[]
        ) {
            super($scope, $translate, toaster);

            $scope.confirm = () => this.confirm();

            $scope.therapyActionPartContextSettings = therapyActionPartContextSettings;
            $scope.productIds = productIds;
            $scope.productClassIds = productClassIds;
        }

        public $onInit(): void {}

        private confirm(): void {
            this.$scope.form.$setSubmitted();
            if (this.$scope.form.$invalid) {
                this.showValidationErrorMessage();
                this.$scope.showErrors = true;
                return;
            }
            this.$scope.$close(this.$scope.recurrentCycle.periodicities);
        }
    }

    remeCareSharedModule.controller('periodicityModalCtrl', PeriodicityModalController);
}
