namespace RemeCare.Shared.Framework.Service {

    enum LogLevel {
        Debug = 'DEBUG',
        Info = 'INFO',
        Log = 'LOG',
        Warn = 'WARN',
        Error = 'ERROR'
    }

    class LoggingService implements ng.ILogService {

        private readonly maxMessages = 100;
        private readonly storageKey = 'RemeCareLogs';
        
        public debug = (...args: any[]) => {
            this.logMessage(args, LogLevel.Debug);
        }

        public error = (...args: any[]) => {
            this.logMessage(args, LogLevel.Error);
        }

        public info = (...args: any[]) => {
            this.logMessage(args, LogLevel.Info);
        }

        public log = (...args: any[]) => {
            this.logMessage(args, LogLevel.Log);
        }

        public warn = (...args: any[]) => {
            this.logMessage(args, LogLevel.Warn);
        }

        private logMessage(args: any[], level: LogLevel): void {
            const formatted = `${moment().toISOString()} - ${level} - ${args}`;
            const stored = localStorage.getItem(this.storageKey);
            const messages = stored
                ? JSON.parse(stored) as string[]
                : [];
            if (messages.push(formatted) > this.maxMessages) {
                messages.shift();
            }
            localStorage.setItem(this.storageKey, JSON.stringify(messages));
        }
    }

    remeCareSharedModule.service('loggingSvc', LoggingService);

    class LoggingDecorator {

        public static factory(
            $delegate: ng.ILogService,
            loggingSvc: LoggingService
        ): ng.ILogService {
            return new LoggingDecorator($delegate, loggingSvc).decorate();
        }

        private constructor(
            private readonly $delegate: ng.ILogService,
            private readonly loggingSvc: LoggingService
        ) { }

        private decorate(): ng.ILogService {
            this.substitute('log');
            this.substitute('info');
            this.substitute('warn');
            this.substitute('error');
            this.substitute('debug');
            return this.$delegate;
        }

        private substitute(fn: string): void {
            const $logFn = this.$delegate[fn];

            const newFn = (...args: any[]) => {
                $logFn.apply(this.$delegate, args);
                this.loggingSvc[fn].apply(null, args);
            }

            this.$delegate[fn] = newFn;
        }
    }

    remeCareSharedModule.decorator('$log',
        [
            '$delegate',
            'loggingSvc',
            LoggingDecorator.factory
        ]);
}