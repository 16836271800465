namespace RemeCare.Shared {
    export class Base64Helper {
        // Base64-urlencodes the input buffer
        public static base64urlencode(str: string): string {
            // Convert the base64 encoded to base64url encoded
            //   (replace + with -, replace / with _, trim trailing =)
            return btoa(str)
                .replace(/\+/g, '-')
                .replace(/\//g, '_')
                .replace(/=+$/, '');
        }

        public static base64urldecode(str: string): string {
            // Replace non-url compatible chars with base64 standard chars
            str = str.replace(/-/g, '+').replace(/_/g, '/');
            // Pad out with standard base64 required padding characters
            const pad = str.length % 4;
            if (pad) {
                if (pad === 1) {
                    throw new Error(
                        'InvalidLengthError: Input base64url string is the wrong length to determine padding'
                    );
                }
                str += new Array(5 - pad).join('=');
            }

            return atob(str);
        }
    }
}
