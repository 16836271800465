module RemeCare.Shared.Framework.Model {

    import PartyRoleType = Shared.Contract.Code.PartyRoleType;
    
    export class ActorRoleInclusion {
        id: Shared.Contract.Guid;
        sequence: number;
        partyRoleType: PartyRoleType;
        actorRoleInclusionDetails: Array<ActorRoleInclusionDetail>;

        constructor(serverObject?: Contract.Read.IActorRoleInclusion) {
            if (serverObject != null) {
                this.id = serverObject.Id;
                this.sequence = serverObject.Sequence;
                this.partyRoleType = serverObject.PartyRoleType;
                this.actorRoleInclusionDetails = _(serverObject.ActorRoleInclusionDetails)
                    .map(a => new ActorRoleInclusionDetail(a));
            } else {
                this.actorRoleInclusionDetails = [];
            }
        }

        copy(): ActorRoleInclusion {
            var result = new ActorRoleInclusion();
            angular.copy(this, result);
            return result;
        }

        public toServerWrite(): Contract.Write.IActorRoleInclusion {
            return <Contract.Write.IActorRoleInclusion> {
                id: this.id,
                sequence: this.sequence,
                partyRoleType: this.partyRoleType,
                actorRoleInclusionDetails: _(this.actorRoleInclusionDetails).map(arid => arid.toServerWrite())
            };
        }
    }
} 