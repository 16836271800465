/**
 * Usage: Add 'ui.bootstrap.modal.dialog' to app dependencies, and then '$dialog' to module dependencies.
 * Use as:
 *   $dialog.messageBox('Title', 'Message', [{result:'cancel', label: 'Cancel'}, {result:'yes', label: 'Yes', cssClass: 'btn-primary'}])
 *     .open()
 *     .then(function(result) {
 *
 *     });
 *
 * Or just:
 *
 *  $dialog.prompt('Title', 'Message').then(function(result) { } );
 */

module RemeCare.Shared.Service {
    var uiModalDialog = angular.module('ui.bootstrap.modal.dialog', []);

    export interface IDialogOptions {
        isWarning?: boolean;
        messageValues?: {};
    }

    export class DialogService {
        constructor(private $uibModal: ng.ui.bootstrap.IModalService, private $q: ng.IQService) {}

        public prompt(title: string, message: string, buttons: any, options?: IDialogOptions): ng.IPromise<string> {
            if (typeof buttons === 'undefined') {
                buttons = [
                    { result: 'cancel', label: 'Cancel' },
                    { result: 'yes', label: 'Yes', cssClass: 'btn-primary' }
                ];
            }
            var modalCtrl = [
                '$scope',
                '$uibModalInstance',
                ($scope, $uibModalInstance) => {
                    $scope.title = title;
                    $scope.message = message;
                    $scope.buttons = buttons;
                    $scope.options = options || {};

                    $scope.close = result => {
                        $uibModalInstance.close(result);
                    };
                }
            ];

            if (options && options.isWarning) {
                return this.$uibModal.open({
                    templateUrl: 'template/dialog/warningMessage.html',
                    controller: modalCtrl
                }).result;
            } else {
                return this.$uibModal.open({
                    templateUrl: 'template/dialog/message.html',
                    controller: modalCtrl
                }).result;
            }
        }

        public messageBox(title: string, message: string, buttons: any, options?: IDialogOptions) {
            return this.prompt(title, message, buttons, options);
        }

        public warningMessageBox(title: string, message: string, buttons: any, options?: IDialogOptions) {
            const warningOptions = angular.copy(options) || ({} as IDialogOptions);
            warningOptions.isWarning = true;
            return this.prompt(title, message, buttons, warningOptions);
        }

        public okBox(title: string, message: string, options?: IDialogOptions): ng.IPromise<string> {
            return this.prompt(
                title,
                message,
                [
                    {
                        result: 'ok',
                        label: 'General.Ok',
                        cssClass: 'btn-primary'
                    }
                ],
                options
            );
        }

        public confirmBox(title: string, message: string, yes?: () => any, no?: () => any, options?: IDialogOptions) {
            return this.prompt(
                title,
                message,
                [
                    { result: 'cancel', label: 'General.No', cssClass: 'btn-default' },
                    { result: 'yes', label: 'General.Yes', cssClass: 'btn-primary' }
                ],
                options
            ).then(r => {
                if (r === 'yes') {
                    if (yes) {
                        yes();
                    }
                } else {
                    if (no) {
                        no();
                    }
                }
            });
        }

        public confirm(title: string, message: string, options?: IDialogOptions): ng.IPromise<void> {
            return this.$q<void>((resolve, reject) => {
                this.confirmBox(title, message, () => resolve(), () => reject(), options);
            });
        }
    }

    uiModalDialog.service('$dialog', DialogService);

    uiModalDialog.run([
        '$templateCache',
        function(e) {
            e.put(
                'template/dialog/message.html',
                '<div class="modal-header" ng-show="title">' +
                    '<h2>{{ title | translate }}</h2>' +
                    '</div>' +
                    '<div class="modal-body" ng-show="message">' +
                    '<p>{{ message | translate:options.messageValues }}</p>' +
                    '</div>' +
                    '<div class="modal-footer">' +
                    '<button ng-repeat="btn in buttons" ng-click="close(btn.result)" class=btn ng-class="btn.cssClass">{{ btn.label | translate }}</button>' +
                    '</div>'
            );
            e.put(
                'template/dialog/warningMessage.html',
                '<div class="modal-header" ng-show="title">' +
                    '<h2>{{ title | translate }}</h2>' +
                    '</div>' +
                    '<div class="modal-body" ng-show="message">' +
                    '<div class="row">' +
                    '<div class="col-sm-12">' +
                    '<span class="col-sm-1">' +
                    ' <i class="glyphicon glyphicon-exclamation-sign fa-2x"></i>' +
                    '</span>' +
                    ' <div class="col-sm-11">' +
                    '<p>{{ message | translate:options.messageValues }}</p>' +
                    '</div>' +
                    '</div>' +
                    '</div>' +
                    '</div>' +
                    '<div class="modal-footer">' +
                    '<button ng-repeat="btn in buttons" ng-click="close(btn.result)" class=btn ng-class="btn.cssClass">{{ btn.label | translate }}</button>' +
                    '</div>'
            );
        }
    ]);
}
