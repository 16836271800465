var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Contract;
        (function (Contract) {
            var Code;
            (function (Code) {
                var ActionAssignmentType;
                (function (ActionAssignmentType) {
                    ActionAssignmentType[ActionAssignmentType["Me"] = 1] = "Me";
                    ActionAssignmentType[ActionAssignmentType["OtherInActorRole"] = 2] = "OtherInActorRole";
                    ActionAssignmentType[ActionAssignmentType["Unassigned"] = 3] = "Unassigned";
                })(ActionAssignmentType = Code.ActionAssignmentType || (Code.ActionAssignmentType = {}));
            })(Code = Contract.Code || (Contract.Code = {}));
        })(Contract = Shared.Contract || (Shared.Contract = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
