module RemeCare.Shared.Framework.Model {
    export class InformedConsentQuestionTranslation {
        sequence: number;
        question: string;

        constructor(sequence?: number, title?: string) {
            this.sequence = sequence;
            this.question = title;
        }
    }
}
