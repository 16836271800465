namespace RemeCare.Shared.Framework.Directive {
    class InputDateRangeController implements ng.IComponentController {}

    remeCareSharedModule.component('rcInputDateRange', {
        controller: InputDateRangeController,
        templateUrl: 'views/formElements/inputDateRange.html',
        require: {
            formCtrl: '^form',
        },
        bindings: {
            fromDate: '=',
            untilDate: '=',
            ngRequired: '<?',
            label: '@?',
            adjustSize: '<?',
            guidanceIconLocation: '<',
            guidanceIconFunc: '<',
            uniqueId: '@',
        },
    });
}
