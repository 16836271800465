module RemeCare.Shared.Filter {

    class CurrentLanguageFilter {

        public static filter($translate: ng.translate.ITranslateService) {
            return (translationDictionary: { [culture: string]: any }, field?: string) => {
                const currentLanguage = $translate.preferredLanguage();
                const value = translationDictionary[currentLanguage];
                if (!value) {
                    return value;
                }
                return field ? value[field] : value;
            }
        }
    }

    CurrentLanguageFilter.filter.$inject = ['$translate'];

    remeCareSharedModule.filter('currentLanguage', CurrentLanguageFilter.filter);
}

declare namespace angular {
    interface IFilterService {
        (name: 'currentLanguage'): {
            (translationDictionary: { [culture: string]: any }, field?: string): string;
        };
    }
}