var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Directive;
            (function (Directive) {
                var InputDateRangeController = /** @class */ (function () {
                    function InputDateRangeController() {
                    }
                    return InputDateRangeController;
                }());
                Shared.remeCareSharedModule.component('rcInputDateRange', {
                    controller: InputDateRangeController,
                    templateUrl: 'views/formElements/inputDateRange.html',
                    require: {
                        formCtrl: '^form',
                    },
                    bindings: {
                        fromDate: '=',
                        untilDate: '=',
                        ngRequired: '<?',
                        label: '@?',
                        adjustSize: '<?',
                        guidanceIconLocation: '<',
                        guidanceIconFunc: '<',
                        uniqueId: '@',
                    },
                });
            })(Directive = Framework.Directive || (Framework.Directive = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
