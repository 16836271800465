var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Directive;
        (function (Directive) {
            var AuthDirective = /** @class */ (function () {
                function AuthDirective(authservice) {
                    var _this = this;
                    this.authservice = authservice;
                    this.restrict = 'A';
                    this.link = function (scope, element, attrs) {
                        scope.$on('rightsChanged', function () {
                            _this.checkRight(attrs.authContext, attrs.authright, element);
                        });
                        _this.checkRight(attrs.authContext, attrs.authright, element);
                    };
                }
                AuthDirective.prototype.checkRight = function (context, right, element) {
                    var hasRight = this.authservice.hasRight(context, right);
                    if (hasRight) {
                        this.onHasRight(element);
                    }
                    else {
                        this.onNotHasRight(element);
                    }
                };
                return AuthDirective;
            }());
            var AuthDisableDirective = /** @class */ (function (_super) {
                __extends(AuthDisableDirective, _super);
                function AuthDisableDirective() {
                    return _super !== null && _super.apply(this, arguments) || this;
                }
                AuthDisableDirective.prototype.onHasRight = function (element) {
                    element.removeAttr('disabled');
                    element.find(":input").removeAttr('disabled');
                    element.find(":button,:submit").removeAttr('disabled');
                    element.find("a").removeAttr('disabled');
                };
                AuthDisableDirective.prototype.onNotHasRight = function (element) {
                    element.attr('disabled', 'disabled');
                    element.find(':input').attr('disabled', 'disabled');
                    element.find(':button,:submit').attr('disabled', 'disabled');
                    element.find('a').attr('disabled', 'disabled');
                };
                return AuthDisableDirective;
            }(AuthDirective));
            angular.module('RemeCare.Shared').directive("authdisabled", function (authservice) { return new AuthDisableDirective(authservice); });
            var AuthContextDirective = /** @class */ (function (_super) {
                __extends(AuthContextDirective, _super);
                function AuthContextDirective() {
                    return _super !== null && _super.apply(this, arguments) || this;
                }
                AuthContextDirective.prototype.onHasRight = function (element) {
                    element.css('display', '');
                };
                AuthContextDirective.prototype.onNotHasRight = function (element) {
                    element.css('display', 'none');
                };
                return AuthContextDirective;
            }(AuthDirective));
            Shared.remeCareSharedModule.directive('authContext', function (authservice) { return new AuthContextDirective(authservice); });
        })(Directive = Shared.Directive || (Shared.Directive = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
