module RemeCare.Shared.Framework.Service {

    class ResponsiveParent {
        private mutationObserver: MutationObserver;
        private eventListenerSupported: (type: string, listener: EventListenerOrEventListenerObject, useCapture?: boolean) => void;

        constructor() {
            this.mutationObserver = (<any>window).MutationObserver || (<any>window).WebKitMutationObserver;
            this.eventListenerSupported = window.addEventListener;
            $(window)
                .resize(() => {
                    this.checkWidth();
                });
            this.observeDom();
        }

        private checkWidth(): void {
            let responsiveParents = $('.respond-parent');
            responsiveParents.each((i, e) => {
                this.applyResponsiveClass($(e));
            });
        }

        private observeDom(): void {
            var element = document;
            if (this.mutationObserver) {
                var obs = new MutationObserver((mutations) => {
                    if (mutations[0].addedNodes.length) {
                        this.checkWidth();
                    }
                });
                obs.observe(element, { childList: true, subtree:true });
            } else if (this.eventListenerSupported) {
                document.addEventListener('DOMNodeInserted', this.checkWidth, false);
            }
        }

        private applyResponsiveClass(responsiveParent: JQuery): void {
            let parentWidth = responsiveParent.parent().outerWidth();
            if (parentWidth >= 1200) {
                responsiveParent.removeClass('col-xs-12 col-sm-6 col-md-8').addClass('col-lg-12');
            } else if (parentWidth >= 992) {
                responsiveParent.removeClass('col-xs-12 col-sm-6 col-lg-12').addClass('col-md-8');
            } else if (parentWidth >= 768) {
                responsiveParent.removeClass('col-xs-12 col-md-8 col-lg-12').addClass('col-sm-6');
            } else {
                responsiveParent.removeClass('col-sm-6 col-md-8 col-lg-12').addClass('col-xs-12');
            }
        }
    }
    
    remeCareSharedModule.run(() => new ResponsiveParent());
}