var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Filter;
        (function (Filter) {
            var percentageFilter = function () {
                return function (withoutPercentage) {
                    if (!withoutPercentage)
                        return '';
                    return withoutPercentage + ' %';
                };
            };
            angular.module('RemeCare.Shared').filter('percentage', percentageFilter);
        })(Filter = Shared.Filter || (Shared.Filter = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
