namespace RemeCare.Shared.Framework.Directive {
    interface IInputSelectScope<TModel> extends ng.IScope {
        displayText: string;
        value: string;
        tracker: string;
        model: TModel;
        formCtrl: ng.IFormController;
        info: string;
        jsonModel: {
            model: TModel;
        };
        uniqueName: string;
        filter: string;
        disabled: boolean;
        guidanceIconLocations: object;
        uniqueId: Shared.Contract.Guid;
        checkDisabledLabels: () => void;
    }

    abstract class InputSelectBase<TModel> implements ng.IDirective {
        public restrict = 'E';

        public require = '^form';

        public scope: { [boundProperty: string]: string } = {
            required: '=ngRequired',
            model: '=ngModel',
            disabled: '=ngDisabled',
            readOnly: '=ngReadonly',
            change: '&?ngChange',
            hasWarning: '<?ngWarning',
            blur: '&?ngBlur',
            adjustSize: '=',
            options: '=',
            info: '@',
            displayText: '@', // What should be shown in the drop down
            tracker: '@', // Something special about duplication?
            value: '@', // What will be saved in ng-model
            label: '@',
            spinner: '@',
            inputClass: '<',
            filter: '@',
            searchFunction: '&?',
            searchFunctionDisabled: '=',
            guidanceIconLocation: '<',
            guidanceIconFunc: '<',
            uniqueId: '@',
        };
        constructor(
            private readonly $templateCache: ng.ITemplateCacheService,
            private readonly $compile: ng.ICompileService,
            private readonly $timeout: ng.ITimeoutService,
            private readonly idService: Shared.Framework.Service.IIdService
        ) {}

        public link: ng.IDirectiveLinkFn = (
            scope: IInputSelectScope<TModel>,
            element: ng.IAugmentedJQuery,
            attrs,
            formCtrl: ng.IFormController
        ) => {
            scope.checkDisabledLabels = () => {
                if (!scope.disabled) {
                    return;
                }

                this.checkDisabledLabels(element);
            };

            scope.uniqueName = this.idService.generateId();
            scope.guidanceIconLocations = RemeCare.Shared.Contract.Code.GuidanceIconLocation;

            let optionsString = 'option';
            if (scope.value) {
                optionsString += `.${scope.value}`;
            }
            if (scope.displayText) {
                optionsString += ` as option.${scope.displayText}`;
            }
            optionsString += ' for option in options';
            if (scope.filter) {
                optionsString += ' | filter: ' + scope.filter;
            }

            if (scope.tracker) {
                optionsString += ` track by option.${scope.tracker}`;
            }
            let template: string = this.$templateCache.get<string>(this.template());
            template = template.replace('$$options$$', optionsString);
            element.html(template).show();
            this.$compile(element.contents())(scope);

            scope.formCtrl = formCtrl;

            scope.jsonModel = {
                model: scope.model,
            };

            scope.$watch(
                (s: IInputSelectScope<TModel>) => s.model,
                (newValue, oldValue) => {
                    if (newValue !== oldValue && newValue !== scope.jsonModel.model) {
                        scope.jsonModel.model = newValue;
                    }
                }
            );

            scope.$watch(
                (s: IInputSelectScope<TModel>) => s.jsonModel.model,
                (newValue, oldValue) => {
                    if (newValue !== oldValue && newValue !== scope.model) {
                        scope.model = newValue;
                    }
                }
            );

            scope.selectionChanged = () => {
                this.$timeout(() => {
                    scope.change && scope.change();
                });
            };
        };

        public abstract template(): string;

        private checkDisabledLabels(element: ng.IAugmentedJQuery): void {
            const result = element.find('li');
            if (result.length > 0) {
                result.addClass('disabled');
                for (let i = 0; i < result.length; i++) {
                    // Replace existing <a> element with new to remove all event/click handlers
                    result[i].innerHTML = '<a>' + result[i].children[0].innerHTML + '</a>';
                }
            }
        }
    }

    class InputSelectDirective<TModel> extends InputSelectBase<TModel> {
        public template(): string {
            return 'views/formElements/inputSelect.html';
        }
    }

    remeCareSharedModule.directive(
        'rcInputSelect',
        ($templateCache, $compile, $timeout, idService) =>
            new InputSelectDirective($templateCache, $compile, $timeout, idService)
    );

    class InputMultiselectDirective<TModel> extends InputSelectBase<TModel> {
        public template(): string {
            return 'views/formElements/inputMultiselect.html';
        }
    }

    remeCareSharedModule.directive(
        'rcInputMultiselect',
        ($templateCache, $compile, $timeout, idService) =>
            new InputMultiselectDirective($templateCache, $compile, $timeout, idService)
    );
}
