namespace RemeCare.Shared.Framework.Service {

    export interface IUserSettings {
        sendLogs: boolean;
    }

    export class SettingsService {

        private static userSettingsKey = 'userSettings';

        constructor(
            private readonly storageSvc: StorageService
        ) {}

        public getSettings(): IUserSettings {
            return this.storageSvc.get<IUserSettings>(SettingsService.userSettingsKey) || {} as IUserSettings;
        }

        public saveSettings(settings: IUserSettings): void {
            this.storageSvc.store(SettingsService.userSettingsKey, settings);
        }
    }

    remeCareSharedModule.service('settingsSvc', SettingsService);
}