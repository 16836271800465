module RemeCare.Shared.Framework.Model {

    export class PossibleInputDeviceOptions {
        DeviceId: number;
        DeviceName: string;
        Characteristics: Array<PossibleInputCharacteristicOptions>;
    }

    export class PossibleInputCharacteristicOptions {
        CharacteristicId: number;
        CharacteristicName: string
    }
}