module RemeCare.Shared.Framework.Periodicity {
    import IntraDayTiming = Framework.Model.IntraDayTiming;
    import TargetPhysicalQuantity = Framework.Model.TargetPhysicalQuantity;
    import TargetDose = Framework.Model.TargetDose;
    import TargetValue = Framework.Model.TargetValue;
    import TargetPhysicalQuantityRange = Framework.Model.TargetPhysicalQuantityRange;

    interface IIntraDayTimingScope extends Framework.IBaseScope, ng.ui.bootstrap.IModalScope {
        readOnly: boolean;
        intraDayTiming: IntraDayTiming;
        form: ng.IFormController;
        targetValuesGrid: Framework.Grid.Grid<TargetPhysicalQuantity>;
        targetDose: TargetDose;
        targetPhysicalQuantities: Array<TargetPhysicalQuantity>;
        administrationTimings: Array<Shared.Contract.IEnumTranslation>;
        unit: string;
        forMedication: boolean;
        limitedConfig: boolean;

        confirm(): void;
    }

    class IntraDayTimingController extends Framework.ControllerBase<IIntraDayTimingScope> {

        private targetValues: Array<TargetValue>;

        constructor(
            protected $scope: IIntraDayTimingScope,
            protected $translate: ng.translate.ITranslateService,
            protected toaster: Shared.Framework.Toaster,
            private readonly gridBuilderSvc: Framework.Grid.GridBuilderFactory,
            private readonly modalBuilderFactory: Framework.Helper.ModalBuilderFactory,
            private readonly masterdataSvc: Framework.MasterdataService) {
            super($scope, $translate, toaster);

            $scope.confirm = () => this.confirm();
        }

        public async $onInit() {
            let targetValues = this.$scope.intraDayTiming.targetValues;
            const targetDoses = <Array<TargetDose>>_(targetValues)
                .filter(t => t instanceof TargetDose);
            if (targetDoses.length > 0) {
                this.$scope.targetDose = targetDoses[0];
            }
            const targetPhysicalQuantities = <Array<TargetPhysicalQuantity>>_(targetValues)
                .filter(t => t instanceof TargetPhysicalQuantity || t instanceof TargetPhysicalQuantityRange);
            this.$scope.targetPhysicalQuantities = targetPhysicalQuantities;
            targetValues = _(targetValues)
                .filter(t => !_(<Array<TargetValue>>targetDoses).contains(t)
                    && !_(<Array<TargetValue>>targetPhysicalQuantities).contains(t));
            this.targetValues = targetValues;
            this.buildTargetValuesGrid();
            try {
                this.$scope.administrationTimings = await this.masterdataSvc.getAdministrationTimings();
             } catch (e) {
                this.toaster.error(e);
            }
        }

        private buildTargetValuesGrid(): void {
            const builder = this.gridBuilderSvc
                .createGridBuilder<TargetPhysicalQuantity>()
                .addColumn('contextSetting.TherapyActionPart.Characteristic', 'Configuration.Therapies.StructuralTherapyAction.Characteristic')
                .addColumn('getTarget()', 'Configuration.Therapies.StructuralTherapyAction.TargetValues')
                .addColumn('contextSetting.TherapyActionPart.Unit', 'Periodicity.Unit');

            if (!this.$scope.readOnly) {
                builder
                    .addEditButtonWithPromiseFunctionColumn(t => this.editTargetValue(t));
            }

            this.$scope.targetValuesGrid = builder.build();
            this.$scope.targetValuesGrid.setData(this.$scope.targetPhysicalQuantities);
        }

        private editTargetValue(targetValue: TargetPhysicalQuantity): Promise<TargetPhysicalQuantity> {
            return new Promise<TargetPhysicalQuantity>((resolve) => {
                this.modalBuilderFactory.createModalBuilder<TargetPhysicalQuantity>()
                    .setTemplateUrl('views/periodicity/targetPhysicalQuantity.html')
                    .setController('targetPhysicalQuantityCtrl')
                    .setScope({
                        targetValue: targetValue.copy()
                    })
                    .setResultCallBack(tv => resolve(tv))
                    .build();
            });
        }

        private confirm(): void {
            if (this.$scope.form.$invalid) {
                this.showValidationErrorMessage();
                return;
            }
            const targetValues = _(this.targetValues)
                .union(this.$scope.targetPhysicalQuantities);
            if (this.$scope.targetDose != null) {
                targetValues.push(this.$scope.targetDose);
            }
            this.$scope.intraDayTiming.targetValues = targetValues;

            this.$scope.$close(this.$scope.intraDayTiming);
        }
    }

    remeCareSharedModule.controller('intraDayTimingCtrl', IntraDayTimingController);
}