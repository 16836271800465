namespace RemeCare.Shared.Framework {
    import PartyType = Shared.Contract.Code.PartyType;
    import EntityTranslation = Shared.Contract.IEntityTranslation;
    import EnumTranslation = Shared.Contract.IEnumTranslation;
    import EnumCodeTranslation = Shared.Contract.IEnumCodeTranslation;
    import IFileType = Shared.Contract.Read.IFileType;

    export class ReferenceDataTypes {
        public static actionAssignmentType = 'ActionAssignmentType';
        public static actionType = 'ActionType';
        public static guidanceStepType = 'GuidanceStepType';
        public static bleParameter = 'BLEParameter';
        public static bleService = 'BLEService';
        public static carePlanActionStatus = 'CarePlanActionStatus';
        public static contactPersonType = 'ContactPersonType';
        public static contactPointType = 'ContactPointType';
        public static contactPointUsageType = 'ContactPointUsageType';
        public static informationObjectType = 'InformationObjectType';
        public static patientFileDocumentSource = 'PatientFileDocumentSource';
        public static administrationMethod = 'AdministrationMethod';
        public static solventToAddUnit = 'SolventToAddUnit';
        public static durationUnit = 'DurationUnit';
        public static speedUnit = 'SpeedUnit';
        public static prescribedAdministrationMeans = 'PrescribedAdministrationMeans';
        public static partyRoleTypes = 'PartyRoleTypes';
        public static healthCareProfessionalTypes = 'HealthCareProfessionalTypes';
        public static partWidth = 'PartWidth';
        public static country = 'Country';
        public static requestAccessTypes = 'RequestAccessType';
        public static PatientInformedConsentHistoryType = 'PatientInformedConsentHistoryType';
        public static planningDateTimeType = 'PlanningDateTimeType';
        public static communicationTypePreference = 'CommunicationTypePreference';
        public static inputDevice = 'InputDevice';
        public static inputDeviceCharacteristic = 'InputDeviceCharacteristic';
    }

    export class MasterdataService extends Framework.ApiServiceBase {
        public static $inject = Shared.Framework.ApiServiceBase.injectionParameters;

        public getReferenceDataAsync(enumType: string): Promise<Contract.IEnumTranslation[]> {
            switch (enumType) {
                case ReferenceDataTypes.contactPointType:
                    return this.getContactPointTypesAsync();
                case ReferenceDataTypes.contactPointUsageType:
                    return this.getContactPointUsageTypesAsync();
                case ReferenceDataTypes.administrationMethod:
                    return this.getAdministrationMethodsAsync();
                case ReferenceDataTypes.solventToAddUnit:
                    return this.getSolventToAddUnitsAsync();
                case ReferenceDataTypes.durationUnit:
                    return this.getDurationUnitsAsync();
                case ReferenceDataTypes.speedUnit:
                    return this.getSpeedUnitsAsync();
                case ReferenceDataTypes.prescribedAdministrationMeans:
                    return this.getPrescribedAdministrationMeansAsync();
                case ReferenceDataTypes.partyRoleTypes:
                    return this.getPartyRoleTypesAsync();
                case ReferenceDataTypes.healthCareProfessionalTypes:
                    return this.getHealthCareProfessionalTypesAsync();
                case ReferenceDataTypes.country:
                    return this.getCountriesAsync();
                default:
                    return this.getPromise(`ReferenceData/${enumType}`, null, 'referenceData', true);
            }
        }

        /** @deprecated */
        public getCodeSetItems(
            codeSetType: Contract.Guid,
            pointInTime?: string
        ): ng.IHttpPromise<Contract.IChoiceListOption[]> {
            let url = `ChoiceLists/${codeSetType}/ChoiceListOptions`;
            if (pointInTime) {
                url += `/${pointInTime}`;
            }
            return this.get(url, null, null, true);
        }

        public getCodeSetItemsAsync(
            codeSetType: Contract.Guid,
            pointInTime?: string
        ): Promise<Contract.IChoiceListOption[]> {
            let url = `ChoiceLists/${codeSetType}/ChoiceListOptions`;
            if (pointInTime) {
                url += `/${pointInTime}`;
            }
            return this.getPromise(url, null, null, true);
        }

        public getCommLanguagesAsync(): Promise<EnumCodeTranslation[]> {
            return this.getPromise('Languages/Comm', null, 'languages.comm', true);
        }

        /** @deprecated */
        public getCommLanguages(): ng.IHttpPromise<EnumCodeTranslation[]> {
            return this.get('Languages/Comm', null, 'languages.comm', true);
        }

        /** @deprecated */
        public getContactPersonTypes(): ng.IHttpPromise<EnumTranslation[]> {
            return this.get('ContactPersonType', null, null, true);
        }

        public getContactPersonTypesAsync(): Promise<EnumTranslation[]> {
            return this.getPromise('ContactPersonType', null, null, true);
        }

        /** @deprecated */
        public getContactPointTypes(): ng.IHttpPromise<EnumTranslation[]> {
            return this.get('ContactPointTypes', null, null, true);
        }

        public getContactPointTypesAsync(): Promise<EnumTranslation[]> {
            return this.getPromise('ContactPointTypes', null, null, true);
        }

        /** @deprecated */
        public getContactPointUsageTypes(
            partyType?: PartyType,
            contactPointType?: number
        ): ng.IHttpPromise<EnumTranslation[]> {
            const url =
                !partyType && !contactPointType
                    ? 'ContactPointUsageTypes'
                    : `ContactPointUsageType/${partyType}/${contactPointType}`;
            return this.get(url, null, null, true);
        }

        public getContactPointUsageTypesAsync(
            partyType?: PartyType,
            contactPointType?: number
        ): Promise<EnumTranslation[]> {
            const url =
                !partyType && !contactPointType
                    ? 'ContactPointUsageTypes'
                    : `ContactPointUsageType/${partyType}/${contactPointType}`;
            return this.getPromise(url, null, null, true);
        }

        /** @deprecated */
        public getCountries(): ng.IHttpPromise<EnumCodeTranslation[]> {
            const url = 'Country';
            return this.get(url, null, 'countries', true);
        }

        public getCountriesAsync(): Promise<EnumCodeTranslation[]> {
            const url = 'Country';
            return this.getPromise(url, null, 'countries', true);
        }

        public getActionCategoriesAsync(): Promise<Contract.Read.IActionCategory[]> {
            const url = 'ActionCategories';
            return this.getPromise(url, null, 'actionCategories', true);
        }

        public getActionCategoriesForReportingAsync(
            includeInactive: boolean
        ): Promise<Contract.Read.IActionCategory[]> {
            let url = 'ActionCategories?IncludeForReporting=true';
            if (!includeInactive) {
                url += '&IsActive=true';
            }
            return this.getPromise(url, null, 'actionCategories', true);
        }

        /** @deprecated */
        public getGenders(): ng.IHttpPromise<EnumTranslation[]> {
            const url = 'Gender';
            return this.get(url, null, 'genders', true);
        }

        public getGendersAsync(): Promise<EnumTranslation[]> {
            const url = 'Gender';
            return this.getPromise(url, null, 'genders', true);
        }

        public getHealthInsuranceFunds(): ng.IHttpPromise<Contract.Read.IHealthInsuranceFund[]> {
            const url = 'HealthInsuranceFund';
            return this.get(url, null, null, true);
        }

        /** @deprecated */
        public getLanguages(): ng.IHttpPromise<EnumCodeTranslation[]> {
            const url = 'Languages';
            return this.get(url, null, 'languages', true);
        }

        public getLanguagesAsync(): Promise<EnumCodeTranslation[]> {
            const url = 'Languages';
            return this.getPromise(url, null, 'languages', true);
        }

        public getInterfaceLanguagesAsync(): Promise<EnumCodeTranslation[]> {
            return this.getPromise('Languages/Interface', null, null, true);
        }

        /** @deprecated */
        public getInterfaceLanguages(): ng.IHttpPromise<EnumCodeTranslation[]> {
            return this.get('Languages/Interface', null, null, true);
        }

        /** @deprecated */
        public getNationalities(): ng.IHttpPromise<EnumCodeTranslation[]> {
            const url = 'Nationality';
            return this.get(url, null, null, true);
        }

        public getNationalitiesAsync(): Promise<EnumCodeTranslation[]> {
            const url = 'Nationality';
            return this.getPromise(url, null, null, true);
        }

        /** @deprecated */
        public getTitles(): ng.IHttpPromise<EnumTranslation[]> {
            const url = 'Title';
            return this.get(url, null, null, true);
        }

        public getTitlesAsync(): Promise<EnumTranslation[]> {
            const url = 'Title';
            return this.getPromise(url, null, null, true);
        }

        /** @deprecated */
        public getEducations(): ng.IHttpPromise<EnumTranslation[]> {
            const url = 'Education';
            return this.get(url, null, null, true);
        }

        public getEducationsAsync(): Promise<EnumTranslation[]> {
            const url = 'Education';
            return this.getPromise(url, null, null, true);
        }

        /** @deprecated */
        public getMaritalStatusses(): ng.IHttpPromise<EnumTranslation[]> {
            const url = 'MaritalStatus';
            return this.get(url, null, null, true);
        }

        public getMaritalStatussesAsync(): Promise<EnumTranslation[]> {
            const url = 'MaritalStatus';
            return this.getPromise(url, null, null, true);
        }

        /** @deprecated */
        public getTechnologicalAffinities(): ng.IHttpPromise<EnumTranslation[]> {
            const url = 'TechnologicalAffinity';
            return this.get(url, null, null, true);
        }

        public getTechnologicalAffinitiesAsync(): Promise<EnumTranslation[]> {
            const url = 'TechnologicalAffinity';
            return this.getPromise(url, null, null, true);
        }

        public getCareTypes(): ng.IHttpPromise<EnumTranslation[]> {
            const url = 'CareTypes';
            return this.get(url, null, null, true);
        }

        public getSimplifiedRecurrences(): ng.IHttpPromise<EnumTranslation[]> {
            const url = 'SimplifiedRecurrences';
            return this.get(url, null, null, true);
        }

        public getConsultationCareProviderTypesAsync(): Promise<EnumTranslation[]> {
            const url = 'ConsultationCareProviderTypes';
            return this.getPromise(url, null, null, true);
        }

        public getUnitsAsync(includeInactive?: boolean): Promise<EntityTranslation[]> {
            const url = 'Units';
            const query = includeInactive == null ? null : { includeInactive };
            return this.getPromise(url, query, null, true);
        }

        /** @deprecated */
        public getObservableEntities(ids?: Contract.Guid[]): ng.IHttpPromise<EntityTranslation[]> {
            const query = ids ? { ids, pageSize: ids.length } : null;
            return this.get('ObservableEntities', query, null, false, (r) => r.Items);
        }

        public getObservableEntitiesAsync(ids?: Contract.Guid[]): Promise<EntityTranslation[]> {
            if (!ids || !ids.length) {
                return this.getPromise('ObservableEntities', null, null, false, (r) => r.Items);
            }

            return this.getInParts(
                'ObservableEntities',
                ids,
                50,
                (set) => ({ ids: set, pageSize: 50 }),
                null,
                false,
                (r) => r.Items
            );
        }

        public getCodeSetTypesAsync(): Promise<EntityTranslation[]> {
            return this.getPromise('CodeSetTypes', null, null, false, (result) => result.Items);
        }

        public findCodeSetTypes(ids: Contract.Guid[]): Promise<Contract.Read.ICodeSetTypeSearch[]> {
            const query = {
                codeSetTypeIds: ids,
                pageSize: ids.length,
                needsConfigurationInfo: false,
            };

            return this.getInParts(
                'MasterDataConfiguration/CodeSetTypes',
                ids,
                50,
                (set) => ({ codeSetTypeIds: set, pageSize: 50, needsConfigurationInfo: false }),
                null,
                false,
                (result) => result.Items
            );
        }

        public getCodeSetTypeUsage(
            codeSetTypeId: Contract.Guid,
            careRequestPartLocalId: Contract.Guid
        ): Promise<Contract.Read.ICodeSetTypeUsage> {
            const query = {
                codeSetTypeId: codeSetTypeId,
                CareRequestPartLocalId: careRequestPartLocalId,
            };
            return this.getPromise('MasterDataConfiguration/CodeSetTypeUsage', query);
        }

        /** @deprecated */
        public getCharacteristicUnits(): ng.IHttpPromise<Contract.Read.ICharacteristic[]> {
            return this.get('CharacteristicUnits', null, null, false);
        }

        public getCharacteristicUnitsAsync(): Promise<Contract.Read.ICharacteristic[]> {
            return this.getPromise('CharacteristicUnits', null, null, false);
        }

        public getCharacteristicsAsync(ids: Contract.Guid[], showInactive = false): Promise<EntityTranslation[]> {
            const query = ids ? { ids, pageSize: ids.length, showInactive } : null;
            return this.getPromise('Characteristics', query, null, null, (r) => r.Items);
        }

        public getUIControlTypesAsync(): Promise<EnumTranslation[]> {
            return this.getPromise('uiControlTypes', null, null, true);
        }

        /** @deprecated */
        public getTherapyActionStatuses(): ng.IHttpPromise<EnumTranslation[]> {
            return this.get('TherapyActionStatuses');
        }

        public getTherapyActionStatusesAsync(): Promise<EnumTranslation[]> {
            return this.getPromise('TherapyActionStatuses');
        }

        public getGuidanceStatusesAsync(): Promise<EnumTranslation[]> {
            return this.getPromise('GuidanceStatuses');
        }

        /** @deprecated */
        public getDurationUnits(includeSmallUnits?: boolean): ng.IHttpPromise<EnumTranslation[]> {
            return this.get('DurationUnits', { includeSmallUnits }, null, true);
        }

        public getDurationUnitsAsync(includeSmallUnits?: boolean): Promise<EnumTranslation[]> {
            return this.getPromise('DurationUnits', { includeSmallUnits }, null, true);
        }

        public getDiseaseTypesAsync(): Promise<EnumTranslation[]> {
            return this.getPromise('DiseaseTypes', null, null, true);
        }

        public getCarePlanStatusesAsync(): Promise<EnumTranslation[]> {
            return this.getPromise('CarePlanStatuses', null, 'carePlanStatuses', true);
        }

        public getTherapyActionStartTypes(): ng.IHttpPromise<EnumTranslation[]> {
            return this.get('TherapyActionStartTypes', null, null, true);
        }

        public getIntendedValues(): Promise<EnumTranslation[]> {
            return this.getPromise('IntendedValues', null, null, true);
        }

        public getDateTypes(): Promise<EnumTranslation[]> {
            return this.getPromise('DateTypes', null, null, true);
        }

        public getInformationObjectTypesAsync(): Promise<EnumTranslation[]> {
            return this.getPromise('InformationObjectTypes', null, null, true);
        }

        public getAdministrationTimings(): Promise<EnumTranslation[]> {
            return this.getPromise('AdministrationTimings', null, null, true);
        }

        /** @deprecated */
        public getPartyRoleTypes(partyType?: Contract.Code.PartyType): ng.IHttpPromise<EnumTranslation[]> {
            if (partyType != null) {
                return this.get('PartyRoleType/' + partyType, null, 'partyRoleTypes', true);
            } else {
                return this.get('PartyRoleType', null, 'partyRoleTypes', true);
            }
        }
        public getPartyRoleTypesAsync(partyType?: Contract.Code.PartyType): Promise<EnumTranslation[]> {
            if (partyType != null) {
                return this.getPromise('PartyRoleType/' + partyType, null, 'partyRoleTypes', true);
            } else {
                return this.getPromise('PartyRoleType', null, 'partyRoleTypes', true);
            }
        }

        public getHealthCareProfessionalTypesAsync(): Promise<EnumTranslation[]> {
            return this.getPromise('HealthCareProfessionalTypes', null, null, true);
        }

        public getFileTypes(activeOnly?: boolean): Promise<IFileType[]> {
            const query = { activeOnly };
            return this.getPromise('FileTypes', query, null, true);
        }

        public getTherapyActionRelationTypesAsync(): Promise<EnumTranslation[]> {
            return this.getPromise('TherapyActionRelationTypes', null, null, true);
        }

        public getCareRequestStepTypesAsync(): Promise<EnumTranslation[]> {
            return this.getPromise('CareRequestStepTypes', null, null, true);
        }

        public getCareRequestPartTypes(): Promise<EnumTranslation[]> {
            return this.getPromise('CareRequestPartTypes', null, null, true);
        }

        public getCareRequestUIControlTypes(): ng.IHttpPromise<Contract.ICareRequestUIControlType[]> {
            return this.get('CareRequestUIControlTypes', null, null, true);
        }

        public getModalityTypes(): ng.IHttpPromise<EnumTranslation[]> {
            return this.get('ModalityTypes', null, null, true);
        }

        public getAlarmTypesAsync(): Promise<EnumTranslation[]> {
            return this.getPromise('AlarmTypes', null, null, true);
        }

        public getAlarmLevelsAsync(): Promise<EnumTranslation[]> {
            return this.getPromise('AlarmLevels', null, null, true);
        }

        public getAlarmStatusesAsync(): Promise<EnumTranslation[]> {
            return this.getPromise('AlarmStatuses', null, null, true);
        }

        public getTherapyStatuses(): ng.IHttpPromise<EnumTranslation[]> {
            return this.get('TherapyStatuses', null, null, true);
        }

        public getInformationObjectStatuses(): Promise<EnumTranslation[]> {
            return this.getPromise('InformationObjectStatuses', null, null, true);
        }

        public getContactAttributes(partyType?: number): Promise<EnumTranslation[]> {
            const url = `ContactAttributes/${partyType}`;
            return this.getPromise(url, null, 'contactAttributes', true);
        }

        public getDocumentCategoriesAsync(): Promise<EntityTranslation[]> {
            return this.getPromise('DocumentCategories', null, null, true);
        }

        public getObjectiveTypesAsync(): Promise<EnumTranslation[]> {
            return this.getPromise('ObjectiveTypes', null, null, true);
        }

        /** @deprecated */
        public getLineTypes(): ng.IHttpPromise<EnumTranslation[]> {
            return this.get('LineTypes', null, null, true);
        }

        public getLineTypesAsync(): Promise<EnumTranslation[]> {
            return this.getPromise('LineTypes', null, null, true);
        }

        /** @deprecated */
        public getMonitoringPartDataSourceTypes(): ng.IHttpPromise<EnumTranslation[]> {
            return this.get('MonitoringPartDataSourceTypes', null, null, true);
        }

        public getMonitoringPartDataSourceTypesAsync(): Promise<EnumTranslation[]> {
            return this.getPromise('MonitoringPartDataSourceTypes', null, null, true);
        }

        /** @deprecated */
        public getMonitoringPartTypes(): ng.IHttpPromise<EnumTranslation[]> {
            return this.get('MonitoringPartTypes', null, null, true);
        }

        public getMonitoringPartTypesAsync(): Promise<EnumTranslation[]> {
            return this.getPromise('MonitoringPartTypes', null, null, true);
        }

        /** @deprecated */
        public getPartWidths(): ng.IHttpPromise<EnumTranslation[]> {
            return this.get('PartWidths', null, null, true);
        }

        public getPartWidthsAsync(): Promise<EnumTranslation[]> {
            return this.getPromise('PartWidths', null, null, true);
        }

        public getCommunicationTypePreferenceAsync(): Promise<EnumTranslation[]> {
            return this.getPromise('CommunicationTypePreference', null, null, true);
        }

        public getCarePlanActionTypes(): Promise<EnumTranslation[]> {
            return this.getPromise('CarePlanActionTypes', null, null, true);
        }

        public getGuidanceStepTypes(): Promise<EnumTranslation[]> {
            return this.getPromise('GuidanceStepTypes', null, null, true);
        }

        public getRuleThresholdsAsync(
            therapyId: Shared.Contract.Guid,
            ids?: Shared.Contract.Guid[]
        ): Promise<Shared.Contract.Read.IRuleThresholdDefinition[]> {
            const url = `TherapyConfiguration/${therapyId}/RuleThresholds`;
            return this.getPromise(url, { ids });
        }

        public getObjectivesAsync(
            therapyId: Shared.Contract.Guid,
            ids?: Shared.Contract.Guid[],
            objectiveTypes?: Shared.Contract.Code.ObjectiveType[]
        ): Promise<Shared.Contract.Read.IObjectiveDefinition[]> {
            const url = `TherapyConfiguration/${therapyId}/Objectives`;
            return this.getPromise(url, { ids, objectiveTypes });
        }

        public getDateFilterTypesAsync(): Promise<EnumTranslation[]> {
            return this.getPromise('DateFilterTypes', null, null, true);
        }

        public getCarePlanActionStatuses(): Promise<EnumTranslation[]> {
            return this.getPromise('CarePlanActionStatus', null, null, true);
        }

        public getApplicationProfilesAsync(): Promise<EnumTranslation[]> {
            return this.getPromise('Security/ApplicationProfiles', null, 'security.applicationProfiles', true);
        }

        public getStructuralTherapyActionAttributes(): Promise<EnumTranslation[]> {
            return this.getPromise('StructuralTherapyActionAttributes', null, null, true);
        }

        public findIngredientsAsync(
            ingredientIds: Shared.Contract.Guid[]
        ): Promise<Shared.Contract.ISearchResult<EntityTranslation>> {
            return this.getPromise('Ingredients', {
                IngredientIds: ingredientIds,
                PageSize: ingredientIds.length,
            });
        }

        public findProductUnitsAsync(unitIds: Shared.Contract.Guid[]): Promise<EntityTranslation[]> {
            return this.getPromise('ProductUnits', { UnitIds: unitIds });
        }

        public findMedicationTypes(): Promise<EnumTranslation[]> {
            return this.getPromise('MedicationTypes', null, null, true);
        }

        public findCareRequestFilterTypesAsync(): Promise<EnumTranslation[]> {
            return this.getPromise('CareRequestFilterTypes', null, 'careRequestFilterTypes', true);
        }

        public getTranslation(culture: string, key: string): ng.IHttpPromise<{ [key: string]: string }> {
            return this.get(`Localization/${culture}/${key}`, null, null, true);
        }

        public getSolventToAddUnitsAsync(): Promise<EnumTranslation[]> {
            const url = 'SolventToAddUnit';
            return this.getPromise(url, null, 'solventToAddUnits', true);
        }

        public getAdministrationMethodsAsync(): Promise<EnumTranslation[]> {
            const url = 'AdministrationMethod';
            return this.getPromise(url, null, 'administrationMethods', true);
        }

        public getSpeedUnitsAsync(): Promise<EnumTranslation[]> {
            const url = 'SpeedUnit';
            return this.getPromise(url, null, 'speedUnits', true);
        }

        public getPrescribedAdministrationMeansAsync(): Promise<EnumTranslation[]> {
            const url = 'PrescribedAdministrationMeans';
            return this.getPromise(url, null, 'PrescribedAdministrationMeans', true);
        }

        /** @deprecated */
        public getMonitoringPartGroups(): ng.IPromise<EnumTranslation[]> {
            const url = 'MonitoringPartGroups';
            return this.get<EnumTranslation[]>(url, null, 'monitoringPartGroups', true)
                .then((httpResult) => {
                    return httpResult.data;
                })
                .catch((httpError) => {
                    return this.$q.reject(httpError.data);
                });
        }

        public getMonitoringPartGroupsAsync(): Promise<EnumTranslation[]> {
            const url = 'MonitoringPartGroups';
            return this.getPromise<EnumTranslation[]>(url, null, 'monitoringPartGroups', true);
        }
    }

    remeCareSharedModule.service('masterdataSvc', MasterdataService);
}
