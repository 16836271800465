module RemeCare.Shared.Framework.Model {
    import IInformedConsentHistoryReadModel = Shared.Contract.Read.IInformedConsentHistoryReadModel;

    export class InformedConsentHistoryPart {
        informedConsentTitle: string;
        version: string;
        validFrom: Date;
        patientInformedConsentStateCode: Shared.Contract.Code.PatientInformedConsentStatusCode;
        stateSetByPartyRole: string;
        remark: string;
        validUntil: Date;
        revokedByPartyRole: string;
        revokedRemark: string;

        constructor(informedConsentHistory: IInformedConsentHistoryReadModel) {
            this.informedConsentTitle = informedConsentHistory.InformedConsentTitle;
            this.version = informedConsentHistory.Version;
            this.validFrom = Shared.DateHelper.serverDateStringToDateTime(informedConsentHistory.ValidFrom);
            this.patientInformedConsentStateCode = informedConsentHistory.PatientInformedConsentStateCode;
            this.stateSetByPartyRole = informedConsentHistory.StateSetByPartyRole;
            this.remark = informedConsentHistory.Remark;
            const validUntil = Shared.DateHelper.serverDateStringToDateTime(informedConsentHistory.ValidUntil);
            if (validUntil && moment(validUntil).isBefore(moment())) {
                this.validUntil = validUntil;
                this.revokedByPartyRole = informedConsentHistory.RevokedByPartyRole;
                this.revokedRemark = informedConsentHistory.RevokedRemark;
            }
        }
    }
}
